import Service from "./service";

const DeviceOnBoardServices = {
  getGridViewForDevice: function (resource) {
    return Service.post('/deviceOnBoard/listDevice',resource,null)
  },
  saveDeviceDetails: function (data) {
    return Service.post('/deviceOnBoard/createDevice',data,null)
  },
  deleteDeviceById: (id) => {
    return Service.post('/deviceOnBoard/deleteDevice',{id})
  },
  editOrgById: (deviceId) =>{
    return Service.get('/deviceOnBoard/getDevice',{deviceId})
  },
  getDeviceView: (deviceId) =>{
    return Service.get('/deviceOnBoard/getDeviceView',{deviceId})
  },
  validateDevice: function (data) {
    return Service.post('/deviceOnBoard/validateExcel',
      data,null,
      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  uploadDevice: function (data) {
    return Service.post('/deviceOnBoard/processExcel',
      data,null,
      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  updateDeviceDetails: function (data) {
    return Service.post('/deviceOnBoard/updateDevice',data,null)
  },
  getLicenseKeyList:function (data){
    return Service.post('/deviceOnBoard/getLicenseKey',data,null)
  },
  getlistofPlansList:function(data){
    return Service.post('/vehicle/getlistofPlans',data,null)
  },
  getlistofDevicePlans:function(data){
    return Service.post('/deviceOnBoard/getDevicePlan',data,null)
  },
  getlistofMFGSiteCode:function(data){
    return Service.post('/deviceOnBoard/getMFGSiteCode',data,null)
  },
  getlistofPartNumberList:function(data){
    return Service.post('/deviceOnBoard/getPartNumber',data,null)
  },
  getDeviceModelNameList:function(data){
    return Service.post('/deviceOnBoard/getDeviceModelName',data,null)
  },
  getSimPlanStatusList:function(data){
    return Service.post('/simOnBoard/getSimPlanStatus',data,null)
  },
  getDeviceStatusList:function(data){
    return Service.post('/deviceOnBoard/getDeviceStatus',data,null)
  },
  getDeviceSimNumberLists:function(data){
    return Service.post('/deviceOnBoard/getSIMNumberList',data,null)
  },
  getDeviceSimDetails:function(data){
    return Service.post('/deviceOnBoard/getSimDetails',data,null)
  }, 
  getDeviceListXLS:function(request){
    // return Service.post('/device/deviceListXLS',data,null)
    return Service.get('/deviceOnBoard/deviceListOnboardXLS',{request})  
  },
  getDeviceListPDF:function(request){
    return Service.get('/deviceOnBoard/deviceListPDF',{request})  
  },
  getDeviceHistory: function (resource) {
    return Service.post('/deviceOnBoard/listDeviceHistory',resource,null)
  },
};

export default DeviceOnBoardServices;
