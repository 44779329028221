import React, { useMemo, useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";

import { DataTable } from "../shared/data_table/DataTable";
import {
  OutlinedInput,
  Divider,
  InputAdornment,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import DropDown from "../shared/DropDown";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker, IconButton } from "rsuite";
import { format } from "date-fns";
import MouseOverPopover from "../shared/PopOver";
import CanFeedDropDown from "./CanFeedDropdown";
import logServices from "../../services/logService";

import LogServices from "../../services/logService";
import {
  StyledCardShadow,
  StyledCalandar,
  StyledCalandarMenu,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledFiterButton,
  StyledPaperShadow,
} from "../../theme/StyledEle";
import { CardContent, CardHeader } from "@mui/material";
import { addDays, subDays } from "date-fns";
import { FaCalendar } from "react-icons/fa";
import moment from "moment";
import { useFeedLogListMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const globalJson1 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;
var globalUserName = "";
var globalOrgName = "";

if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
) {
} else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
  globalOrgName = globalJson.orgName;
  globalUserName = globalJson.userName;
}
// "Note: RAW feeds MUST have VALID Header. This is for security reasons. Feeds with Invalid Header will be moved to RAW REJECTED. All other feeds will be stored and displayed here."
// "From RAW, feeds are validated and then moved to STAGING if validation is successful.";

const text = "Device Raw Feed";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};
const status = ["All", "Moving", "Idle", "Stop", "Charging"];

// children: <DeviceRawFeed deviceRawFeed={deviceRawFeed} callbackLoadFn={callbackLoadFn} devicerawnewPage={devicerawnewPage} onChangeXcl={onChangeXcl} devicerawrowsPerPage={devicerawrowsPerPage} devicerawtotalPages={devicerawtotalPages}  devicerawtotalElements={devicerawtotalElements}  callbackChangePage={callbackChangePage} handleSearch={handleSearch}/>,
// const RawFeed = ({rawFeedData, tabValue, handleSearch, callbackChangePage, callbackLoadFn, onChangeXcl, totalElements, totalPages, rowsPerPage, newPage}) => {

const DeviceRawFeed = ({ data, payload, isLoadingOut }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  useEffect(async () => {
    auditPayload.message = "Logs > FeedLog > Device Raw Feed";
    auditPayload.action = "View";
    auditPayload.featureName = "FeedLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Received Date",
        accessor: "gpsDate",
        width:150,
      },
      {
        Header: "GpsFeed",
        accessor: "gpsFeed",
        width:800,
      },
      {
        Header: "Description",
        accessor: "message",
        width:200,
      },
    ],
    []
  );

  const [getDeviceFeedList, { data: DeviceFeedListdata, isSuccess, isLoading }] =
    useFeedLogListMutation();

  useEffect(() => {

    if (data !== undefined) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      } else {
        setResponseData([]);
        setTotalElements(0);
        setTotalPages(0);
      }
    }
  }, [data]);

  const onChangeXcl = async () => {
    let xlsfeed = "deviceraw";
    try {
      payload.feedStage = xlsfeed;
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FeedLog/FeedlogXLS.csv?vehicleId=" +
        payload.vehicleId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&imei=" +
        payload.imei +
        "&resellerId=" +
        payload.resellerId +
        "&xlsfeed=" +
        xlsfeed +
        "&url=" +
        payload.url;

      window.open(url);

      auditPayload.message = "Logs > FeedLog > Device Raw Feed > Export >Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "FeedLog";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getDeviceFeedList(payload);
    } else if (payload.search.length === 0) {
      getDeviceFeedList(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getDeviceFeedList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getDeviceFeedList(payload);
    }
  };


  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (DeviceFeedListdata.data !== null) {
        setResponseData(DeviceFeedListdata.data.content);

        setTotalElements(DeviceFeedListdata.data.totalElements);
        setTotalPages(DeviceFeedListdata.data.totalPages);
      }
    }
  }, [isSuccess]);

  // const [responseData, setResponseData] = useState([]);
  // const [statusCon, setStatus] = useState(status[0]);
  // const [vehiclesImei, setVehiclesImei] = useState([]);
  // const [seltdImei, setSeltdImei] = useState("");
  // const [loadStatus, setLoadStatus] = useState(true);
  // const [orgListJSON, setOrgListJSON] = useState([]);
  // const [loading, setLoading] = useState("");
  // // const[searchval,setSearchval]=useState("");

  // const theme = useTheme();

  // const [selCreatedDate, setSelCreatedDate] = useState(0);
  // const [fromDate, setFromDate] = useState(
  //   format(
  //     new Date(format(new Date(), "yyyy-MM-dd") + " 00:00:00"),
  //     "yyyy-MM-dd HH:mm:ss"
  //   )
  // );
  // const [toDate, setToDate] = useState(
  //   format(new Date(), "yyyy-MM-dd HH:mm:ss")
  // );
  // const { afterToday } = DateRangePicker;
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [customCal, setCustomCal] = useState(false);
  // const open = Boolean(anchorEl);

  // //const [date, setDate] = useState();
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  //   setCustomCal(false);
  // };

  // //Today
  // const getToday = () => {
  //   setFromDate(format(new Date(), "yyyy-MM-dd") + " 00:00:00");
  //   setToDate(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
  //   handleClose();
  //   setSelCreatedDate(1);
  // };

  // const getYesderday = () => {
  //   setFromDate(format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00");
  //   setToDate(format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59");
  //   handleClose();
  //   setSelCreatedDate(2);
  // };
  // const getLast7day = () => {
  //   setFromDate(format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00");
  //   setToDate(format(subDays(new Date(), 1), "yyyy-MM-dd") + " 23:59:59");
  //   handleClose();
  //   setSelCreatedDate(3);
  // };
  // const customRage = () => {
  //   //setDate('Custom');
  //   handleClose();
  //   setCustomCal(true);
  //   setSelCreatedDate(4);
  // };
  // useEffect(() => {
  //   getToday();
  // }, []);

  // const selectDropdown = {
  //   "&.MuiInputBase-root": { width: "320px" },
  // };
  // var loadrequest = [];

  // var selResellerId = null;
  // var selRegionId = null;
  // var selDealerId = null;
  // var selCustomerId = null;
  // var selFleetId = null;
  // var selVehicleId = null;
  // var selimei = null;

  // // useEffect(async () => {
  // //   const imeiPayload = {
  // //     resellerId: globalResellerId,
  // //     //status: 1,
  // //   };
  // //   const { data: resellerResponseNew } =
  // //     await LogServices.DeviceDropdownWithPagination(imeiPayload);
  // //   const { data1: orgnListJSON } = resellerResponseNew;

  // //   setOrgListJSON(orgnListJSON);
  // //   selimei = orgnListJSON[0].key;
  // //   //setSeltdImei(orgnListJSON[0].key);

  // //  // fetchRawFeedDetails(selimei);
  // // }, []);

  // const handleIMEISearch = () => {};
  // const handleIMEISelect = (imei) => {
  //   setSeltdImei(imei);
  // };
  // // {
  // //   "vehicleId": 26202,
  // //   "startDate": "2023-02-01 05:30:00",
  // //   "endDate": "2023-02-01 23:59:59",
  // //   "orderdir": "desc",
  // //   "search": "",
  // //   "imei": "091738747854563",
  // //   "page": 0,
  // //   "size": 10,
  // //   "url": 4,
  // //   "orgId": 109925
  // // // }

  // useEffect(async () => {

  //   var request = {};
  //   request.message = "Logs > FeedLog > Device Raw Feed";
  //   request.action = "View";
  //   request.featureName = "FeedLog";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName= globalUserName;

  //   const responseData = await logServices.saveAuditLogDetails(request);

  // }, []);

  // const callbackLoadFn = async (
  //   selectedOrgType,
  //   selectedResellerId,
  //   selectedCustomerId,
  //   selectedFleetId,
  //   selectedLastOrgId,
  //   selectedVehicleId,
  //   selectedImei
  // ) => {
  //   // console.log(selectedOrgType,selectedResellerId,selectedCustomerId,selectedFleetId,selectedLastOrgId,selectedVehicleId,selectedImei);
  //   loadrequest = assignedval;
  //   loadrequest.vehicleId = selectedVehicleId;

  //   const date = format(new Date(), "yyyy-MM-dd") + " 00:00:00";
  //   const date1 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  //   loadrequest.startDate = date;
  //   loadrequest.endDate = date1;
  //   loadrequest.orderdir = "desc";
  //   loadrequest.search = "";
  //   loadrequest.page = newPage;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.imei = selectedImei;
  //   loadrequest.url = 4;
  //   setAssignedval(loadrequest);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);

  //   fetchRawFeedDetails();
  // };

  // const handleChange = (event) => {
  //   setStatus(event.target.value);
  // };

  // const [newPage, setNewPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  // const [totalElements, setTotalElements] = useState(5);
  // const [totalPages, setTotalPages] = useState(1);
  // const [selectedDropdownAry, setSelectedDropdownAry] = useState([]);
  // const [assignedval, setAssignedval] = useState({});
  // const [searchval, setSearchval] = useState("");

  // {
  //   "vehicleId": 0,
  //   "startDate": "2023-02-01 05:30:00",
  //   "endDate": "2023-02-01 23:59:59",
  //   "orderdir": "desc",
  //   "search": "",
  //   "imei": "0",
  //   "page": 0,
  //   "size": 10,
  //   "url": 4,
  //   "orgId": 109925
  // }

  // const onSubmit=async (data)=>{
  //   console.log(data);
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //  // setStartDate(fromDate);
  //   //setEndDate(toDate);
  //   loadrequest=assignedval;
  //   //loadrequest.regionId= data.region;
  //   loadrequest.resellerId = data.reseller;
  //   //loadrequest.resellerId = 5;
  //   loadrequest.dealerId= data.dealer;
  //   // loadrequest.customerId = data.customer;
  //   // loadrequest.dealerId= data.dealer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   //loadrequest.vehicleId = data.vehicle;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.orderdir = "desc";
  //   loadrequest.search = searchval;
  //   console.log('check'+loadrequest);
  //   setAssignedval(loadrequest);
  //   const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //   setResponseData(responseData.data.content);
  // }

  // const handleSearch = async(searchText) => {
  //   setSearchVal(searchText);
  //   loadrequest=assignedval;
  //   loadrequest.search = searchText;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.IdleReport(loadrequest)).data;
  //   setResponseData(responseData.data.content);

  // }

  // {
  //   "vehicleId": 0,
  //   "startDate": "2023-02-02 04:56:22",
  //   "endDate": "2023-02-22 04:56:22",
  //   "orderdir": "desc",
  //   "search": "",
  //   "imei": "353607080474880",
  //   "url": 5,
  //   "page": 0,
  //   "size": 10,
  //   "orgId": 109925
  // }
  // const fetchRawFeedDetails = async (selimei) => {
  //   loadrequest = assignedval;
  //   //loadrequest.search=searchText === undefined?"":searchText;
  //   console.log(selimei);
  //   loadrequest.vehicleId = 0;

  //   const date = format(new Date(), "yyyy-MM-dd") + " 00:00:00";
  //   const date1 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  //   loadrequest.startDate = date;
  //   loadrequest.endDate = date1;
  //   loadrequest.orderdir = "desc";
  //   loadrequest.search = "";
  //   loadrequest.page = newPage;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.imei = selimei;
  //   loadrequest.url = 4;
  //   loadrequest.orgId = globalOrgId;
  //   loadrequest.resellerId = globalResellerId;

  //   // if(columnName===undefined){

  //   //   loadrequest.columnName = "MESSAGE";

  //   // }else{
  //   //   loadrequest.columnName = columnName;
  //   // }

  //   const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const handleSearch = async (searchText) => {
  //   setSearchval(searchText);

  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   onSubmit();
  //   // setAssignedval(loadrequest);
  //   // const responseData = (await ReportServices.FeedLog(loadrequest)).data;
  //   // setResponseData(responseData.data.content);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);

  //   loadrequest = assignedval;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };
  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;
  //   loadrequest.feedStage = "deviceraw";

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "FeedLog/FeedlogXLS.xls?vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&search=" +
  //       loadrequest.search +
  //       "&imei=" +
  //       loadrequest.imei +
  //       "&resellerId=" +
  //       loadrequest.resellerId +
  //       "&feedStage=" +
  //       loadrequest.feedStage +
  //       "&url=" +
  //       loadrequest.url;

  //     window.open(url);
  //   } catch (error) {}
  // };
  // // const onChangePdf=async ()=>{

  // //   loadrequest=assignedval;

  // //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);
  //   try {

  //     var url=process.env.REACT_APP_API_BASE_URL+"ManualCanDataReport/vehicledataPDF.pdf?startDate="+loadrequest.startDate+"&endDate="+loadrequest.endDate+"&orderDir="+loadrequest.orderDir+"&orgId="+loadrequest.orgId+
  //    "&vehicleId="+loadrequest.vehicleId;

  //      window.open(url);

  //   } catch (error) {}
  // }
  // const onSubmit = async () => {
  //   loadrequest = assignedval;
  //   //console.log(e);
  //   // {
  //   //   "vehicleId": 0,
  //   //   "startDate": "2023-02-01 05:30:00",
  //   //   "endDate": "2023-02-01 23:59:59",
  //   "orderdir": "desc",
  //   "search": "",
  //   "page": 0,
  //   "size": 10,
  //   "url": 4,
  //   "orgId": 109898
  // }

  //   var request = {};
  //   request.size = loadrequest.size;
  //   request.page = loadrequest.page;
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.orderdir = loadrequest.orderdir;
  //   request.search = loadrequest.search;
  //   request.vehicleId = loadrequest.vehicleId;
  //   request.url = loadrequest.url;
  //   request.orgId = globalOrgId;
  //   request.resellerId = globalResellerId;
  //  // setSeltdImei(loadrequest.imei)
  //   request.imei = seltdImei;

  //   setAssignedval(request);
  //   const responseData = (await LogServices.FeedLog(request)).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  //   window.open(url);

  // const clickVal = (e) => {
  //   setFromDate(format(e[0], "yyyy-MM-dd HH:mm:ss"));
  //   setToDate(format(e[1], "yyyy-MM-dd HH:mm:ss"));
  //   handleClose();
  // };

  return (
    <>
      <MouseOverPopover text={text} title={"Device Raw Feed"} />
      <Box>
        {/* <CanFeedDropDown
          onSubmit={onSubmit}
          isshowimei={false}
          callbackLoadFn={callbackLoadFn}
        /> */}

        <StyledCardShadow>
          <CardHeader
            title={<StyledTableTitle>Device Raw Feed</StyledTableTitle>}
          />
          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={isLoading || isLoadingOut}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </>
  );
};

export default DeviceRawFeed;
