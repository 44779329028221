import { Alert, Snackbar } from '@mui/material'
import { Typography } from 'antd'
import React, { useState } from 'react'

function StyledSnackBar(props) {
    const {message, status} = props;
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(true);
    }
  return (
    <Snackbar
        autoHideDuration={4000}
        open={true}
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        onClose={handleClose}
    >
            <Alert severity={status === 'Success' || status === 0 ? 'success' : 'error'}>
                <Typography>{message}</Typography>
            </Alert>
    </Snackbar>
  )
}

export default StyledSnackBar