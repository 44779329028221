import { Box } from '@mui/material'
import React,{useState,useEffect} from 'react'
import StyledTabs from '../shared/StyledTabs'
import CreateSim from './CreateSim'
import ListSim from './ListSim'
import {Breadcrumb} from '../shared/Breadcrumbs'
import SimUpload from './SimUpload'
import RecentActivity from './RecentActivity'
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch, useSelector } from "react-redux";
import FormDialog from '../../Utils/FormDialog'
import SimView from './SimView';
import { StyledPageLayout } from "../../theme/StyledEle";
import { useLocation, useNavigate } from 'react-router-dom'
const SimOnBoard = () => {

  const [simToEdit, setsimToEdit] = React.useState(null)  
 
  const [menuUser, setMenuUser] = useState();
  const [tabShow, setTabShow] = React.useState("1");
  const [create, setCreate] = useState("Create SIM");
  const [id, setId] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [tempcommonFilter, setTempcommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const handleTabChange = (event, newValue) => {
    setsimToEdit()
    setTabValue(newValue);
    setCreate("Create SIM");

  };

  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "simOnBoard"
              ) {
                setMenuUser(
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                );
                
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                }
             
                setTempcommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
     
    var tabshowval = "1";
    var displaydefaulttab = 3;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false &&   dataStr.view === true
    ) {
      setTabShow("0");
      tabshowval = "0";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true &&   dataStr.view === true
     ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 3;
    }

    return displaydefaulttab;
   
    }
  };
  const [tabValue, setTabValue] = useState(() => callfun());

  const handleSimEdit  = (simEditedId) => {
    setTabValue(2)
    setsimToEdit(simEditedId)
    setCreate("Edit SIM");

  }

  const handleSimView = (simViewId) => {
    location.state={'id':simViewId.id}
    navigate('#view');  
    setId(simViewId.id);
  };

  const handleCancel = () => {
    setTabValue(3)
    setCreate("Create SIM");

  }

  const TABS = [
    {
      label: 'Import SIM Details',
      children: <SimUpload onCancel={handleCancel}/> 
    },
    {
      label: 'Recent Activity',
      children: <RecentActivity/> 
    },
    {
      label: create,
      children: <CreateSim simToEdit={simToEdit} onCancel={handleCancel}/>
    },
    {
      label: 'SIM List',
      children: <ListSim    onEditSim={handleSimEdit} menuUser={menuUser}  onViewSim={handleSimView}/> 
    }
   
  ]
  const TABS3 = [
   
    {
      label: 'SIM List',
      children: <ListSim    onEditSim={handleSimEdit} menuUser={menuUser} tabValue={tabValue} onViewSim={handleSimView} /> 
    }
   
  ]
  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);
  
  return (
    <StyledPageLayout>
    <Box  sx={{padding: '0px 15px'}}>
     <Breadcrumb mainDiv='Admin' subDiv='SIM'></Breadcrumb>
     {tabShow === "1" && (
        <StyledTabs
          tabs={TABS}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
     {tabShow === "0" && (
      <StyledTabs tabs={TABS3} handleChange={handleTabChange} value={tabValue}/>
     )}
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </Box>
     {/* Edit Popup */}
     <FormDialog >
            <SimView  ids={id}/>
        </FormDialog>
    </StyledPageLayout>
  )
}

export default SimOnBoard