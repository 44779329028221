import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  OutlinedInput,
  Typography,
  Icon,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import NameDialog from "./ps-name";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import userService from "../../services/userService";
import OtpInput from "react-otp-input";
import StyledModel from "../../Utils/StyledModel";
import {
  useCheckCodeExistsMutation,
  useCheckOtpExistsMutation,
  useSaveOtpCodeMutation,
  useSaveVerficationCodeMutation,
} from "../../store/slice/ProfileSlice/ProfileSlice";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch, useSelector } from "react-redux";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { MdVerified } from "react-icons/md";
import { useForm } from "react-hook-form";
import { DialogContent } from "@mui/material";
import {useAuditLogMutation} from "../../store/slice/ApiSlice";
import { SoftButton, StyledFormControl, StyledFormControlLabel, StyledTextField } from "../../theme/StyledEle";
import { useLocation, useNavigate } from "react-router-dom";

export default function ProfileDetails({ onEditUser }) {
  const [open, setOpen] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [response, setResponse] = useState({});

  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalUserName = "";
  var globalOrgName = "";
  var globalEmail = "";
  var globalMobile = "";
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globalOrgName = globalJson.orgName;
    globalUserName = globalJson.userName;
    globalEmail = globalJson.email;
    globalMobile = globalJson.mobile;
  }


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId,
    orgName: globalOrgName,
    userName: globalUserName,
  };
const navigate = useNavigate();
const location = useLocation();
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    setValue:setValue2,
    getValues:getValues2,
  reset:reset2,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    fetchUserDetails()
  }, []);

  const fetchUserDetails = async () => {

    const responseData = (await userService.editUserById(globaluserId)).data.data;
    setName(responseData.firstName + " " + responseData.lastName);
    setEmail(responseData.email);
    setPhone(responseData.mobile);
    setResponse(responseData);
    auditPayload.message = "Profile Settings > Profile";
    auditPayload.action = "Click";
    auditPayload.featureName = "Profile Settings";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);

    auditPayload.message = "Profile Settings > Profile > Name > Edit";
    auditPayload.action = "Click";
    auditPayload.featureName = "Profile Settings";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  };
  const handleClickOpenEmail = () => {
    setOpenEmail(true);
    reset({email:email})
    auditPayload.message = "Profile Settings > Profile > Email > Edit";
    auditPayload.action = "Click";
    auditPayload.featureName = "Profile Settings";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  };
  const handleClickOpenPhone = () => {
    setOpenPhone(true);
    reset2({mobile:phone})
    auditPayload.message = "Profile Settings > Profile > Phone > Edit";
    auditPayload.action = "Click";
    auditPayload.featureName = "Profile Settings";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  };

  const onEdit = (itemEdited) => {
    onEditUser(itemEdited.id);
  };

  const [otp, setOtp] = useState("");
  const [status, setStatus] = useState("");
  const [openOtp, setOpenOtp] = useState(false);
  const dispatch = useDispatch();
  const handleChangeOtp = (newValue) => {
    setOtp(newValue);
  };
  const [
    saveNotiCode,
    {
      data: notifyCodeData,
      isLoading: notifyIsLoading,
      isSuccess: notifyIsSuccess,
    },
  ] = useSaveVerficationCodeMutation();
  const [
    saveOtpCode,
    { data: otpCodeData, isLoading: otpIsLoading, isSuccess: otpIsSuccess },
  ] = useSaveOtpCodeMutation();
  const [
    checkCode,
    {
      data: checkCodeData,
      isLoading: checkCodeIsLoading,
      isSuccess: checkCodeIsSuccess,
    },
  ] = useCheckCodeExistsMutation();
  const [
    checkOtp,
    {
      data: checkOtpData,
      isLoading: checkOtpIsLoading,
      isSuccess: checkOtpIsSuccess,
    },
  ] = useCheckOtpExistsMutation();

  var commonPayload = {};
  commonPayload.id = globaluserId;
  commonPayload.orgId = globalOrgId;

  const handleEmailVerify = async () => {
    setStatus("email");
    commonPayload.email = email;
    const response = await saveNotiCode(commonPayload);
    dispatch(
      snackService([
        response?.data?.resultMessage,
        response?.data?.resultText,
        true,
      ])
    );
    if (response?.data?.resultCode === 0) {
      setOpenOtp(true);
      navigate('#email');
    }
  };
  const handlePhoneVerify = async () => {
    setStatus("phone");
    commonPayload.mobile = phone;
    const response = await saveOtpCode(commonPayload);
    dispatch(
      snackService([
        response?.data?.resultMessage,
        response?.data?.resultText,
        true,
      ])
    );
    if (response?.data?.resultCode === 0) {
      setOpenOtp(true);
      navigate('#phone');
    }
  };

  const sendotp = async () => {
    if (status === "email") {
      commonPayload.emailVerificationCode = otp;
      commonPayload.email = getValues("email") !== undefined  ?getValues("email"):email;
      const response = await checkCode(commonPayload);
      dispatch(
        snackService([
          response?.data?.resultMessage,
          response?.data?.resultText,
          true,
        ])
      );
      setOpenOtp(false);
    } else if (status === "phone") {
      commonPayload.phoneVerificationCode = otp;
      commonPayload.mobile = getValues2("mobile") !== undefined ?getValues2("mobile"):phone;
      const responseData = await checkOtp(commonPayload);
      dispatch(
        snackService([
          responseData?.data?.resultMessage,
          responseData?.data?.resultText,
          true,
        ])
      );
      setOpenOtp(false);
      
    }
    setOtp("");
    navigate(location.pathname);
    fetchUserDetails()
  };

  const onSubmitEmail = async() => {
    setOpenEmail(false);
    setStatus("email");
    var request = {};
    request.id = globaluserId;
    request.userName = globalUserName;
    request.orgId = globalOrgId;
    request.email = getValues("email");
    const responseData = await userService.updateEmail(request);
    dispatch(
      snackService([
        responseData?.data?.resultMessage,
        responseData?.data?.resultText,
        true,
      ])
    );
    if (responseData?.data?.resultCode === 0) {
      setOpenOtp(true);

      navigate('#email');
    }
  };


  const handleCloseEmail = () => {
      setOpenEmail(false);
  };


  const onSubmitPhn = async(e) => {
    setOpenPhone(false);
    setStatus("phone");
    var request ={};
    request.id = globaluserId;
    request.userName = globalUserName;
    request.orgId = globalOrgId;
    request.mobile = getValues2("mobile");
    const responseData = await userService.updatePhoneNo(request);
    dispatch(
      snackService([
        responseData?.data?.resultMessage,
        responseData?.data?.resultText,
        true,
      ])
    );
    if (responseData?.data?.resultCode === 0) {
      setOpenOtp(true);
      navigate('#phone');
    }
  };

  const handleClosePhone = () => {
      setOpenPhone(false);
  };
  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

  useEffect(() => {
    if(location.hash === ''){
      setOpenOtp(false);
    }
},[location.hash])

  return (
    <>
      {openOtp && (
        <StyledModel data={openOtp} deleteItem={sendotp} otp={true}>
          <Box
            sx={{
              px: 2,
              width: "100%",
              "& .input-otp-wapper": {
                justifyContent: "center",
                my: 5,
                "& input": {
                  width: "40px !important",
                  height: "40px",
                  mx: 1,
                },
              },
            }}
          >
            <OtpInput
              containerStyle={"input-otp-wapper"}
              value={otp}
              onChange={handleChangeOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus={true}
            />
          </Box>
        </StyledModel>
      )}
      <Box component="form" noValidate autoComplete="off">
        <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
          About Me
        </Typography>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                id="name"
                name="name"
                value={name}
                onChange={handleChange}
                label="Name"
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      variant="outlined"
                      onClick={handleClickOpen}
                      color="secondary"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>

                    <NameDialog
                      open={open}
                      setOpen={setOpen}
                      onEdit={onEdit}
                      setName={setName}
                      name={name}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                id="email"
                name="email"
                label="Email"
                disabled
                value={email}
                onChange={handleChangeEmail}
                endAdornment={
                  <InputAdornment position="end">
                    <ButtonGroup
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      {response?.emailVerificationStatus === 1 ? (
                        <Icon color="success" sx={{ mx: 1 }}>
                          {<MdVerified />}
                        </Icon>
                      ) : (
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={handleEmailVerify}
                          disabled={email !== '' && email !== null?false:true}
                        >
                          Verify Email
                        </Button>
                      )}
                      <Button
                        onClick={handleClickOpenEmail}
                        color="secondary"
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </ButtonGroup>
                    <Dialog
      open={openEmail}
      onClose={handleCloseEmail}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Edit Your Email Address</DialogTitle>

      <Box component='form' onSubmit={handleSubmit(onSubmitEmail)}>
        <DialogContent>
          <Typography
            variant="h6"
            color="primary"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            Verification code will be sent to your email address
          </Typography>
          <StyledFormControl fullWidth>
            <StyledFormControlLabel required>Email</StyledFormControlLabel>
            <StyledTextField
              error={errors.email && true}
              size="small"
              fullWidth
              {...register("email", {
                required: {
                  value: true,
                  message: "Email required",
                },
                validate: {
                  matchPattren: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(
                      v
                    ) || "Email address must be a valid address",
                },
              })}
              onChange={(e) => setValue("email", e.target.value)}
              helperText={errors.email && errors.email.message}
            />
          </StyledFormControl>
        </DialogContent>

        <DialogActions>
          <SoftButton 
          type="submit" 
          //onClick={onSubmitEmail}
          variant="contained"
            color="success"
            className="success"
            size="small"
          >
            Submit
          </SoftButton>
        </DialogActions>
      </Box>
     
    </Dialog>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>Mobile</InputLabel>
              <OutlinedInput
                id="mobile"
                name="mobile"
                disabled
                label="Mobile"
                value={phone}
                onChange={handleChangePhone}
                endAdornment={
                  <InputAdornment position="end">
                    <ButtonGroup
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      {response?.mobileVerificationStatus === 1 ? (
                        <Icon color="success" sx={{ mx: 1 }}>
                          {<MdVerified />}
                        </Icon>
                      ) : (
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={handlePhoneVerify}
                          disabled={phone !== '' && phone !== null?false:true}
                        >
                          Verify number
                        </Button>
                      )}
                      <Button
                        onClick={handleClickOpenPhone}
                        color="secondary"
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </ButtonGroup>
                    <Dialog
      open={openPhone}
      onClose={handleClosePhone}
      maxWidth="sm"
      fullWidth
    >
      <Box component="form" onSubmit={handleSubmit2(onSubmitPhn)}>
        <DialogTitle>Edit Your Phone Number</DialogTitle>
        <DialogContent>
          <Typography
            variant="h6"
            color="primary"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            Verification code will be sent to your Phone Number
          </Typography>
          <StyledFormControl fullWidth>
            <StyledFormControlLabel required>
              Phone Number
            </StyledFormControlLabel>
            <StyledTextField
              error={errors2.mobile && true}
              size="small"
              fullWidth
              {...register2("mobile", {
                required: {
                  value: true,
                  message: "Mobile Number required",
                },
              })}
              onChange={(e) => setValue2("mobile", e.target.value)}
              helperText={errors2.mobile && errors2.mobile.message}
            />
          </StyledFormControl>
        </DialogContent>
        <DialogActions>
          <SoftButton
            type="submit"
            variant="contained"
            color="success"
            className="success"
            size="small"
          >
            Submit
          </SoftButton>
        </DialogActions>
      </Box>
    </Dialog>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        {isSuccess.isSuccess && (
          <StyledSnackBar
            message={isSuccess.message}
            status={isSuccess.status}
          />
        )}
        
      </Box>
    </>

  );
}
