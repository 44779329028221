import React, { useEffect, useRef, useState } from 'react';
import { Box, Fade, IconButton, Stack, Tooltip,Autocomplete,TextField, Typography, useTheme, Avatar, styled, Input, Button,Select, MenuItem, Backdrop } from '@mui/material';
import { HiEye } from 'react-icons/hi';
import { useVehicleUploaddataMutation ,useVehicleimageMutation} from '../../store/slice/ApiSlice'
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import { useGetDropdownListMutation } from "../../store/slice/ApiSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { GridLoader } from 'react-spinners';
export const VehicleSettings = () => {
    const theme = useTheme();
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [selectedReseller, setSelectedReseller] = useState("");
    let resellerId = userData.resellerId;
    let roleId = userData?.roleId;
    let orgType = userData?.userOrgType;
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const VisuallyHiddenInput = styled(Input)({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
        zIndex: 999,
    });
    const [images, setImages] = useState({
        moving: null,
        stop: null,
        offline: null,
        charging: null,
        breakdown: null,
        accident: null,
        all:null
    });

    const [imgData,setimgData]=useState([])
    const [showFileTypeMessage, setShowFileTypeMessage] = useState(false);
    const handleImageUpload = (event, type) => {
        const file = event.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            const reader = new FileReader();
            reader.onload = () => {
                setImages({ ...images, [type]: reader.result });
            };
          
            reader.readAsDataURL(file);
        }else {
            // Display a message indicating that only JPEG and PNG images are allowed
            setShowFileTypeMessage(true);
            setTimeout(() => {
                setShowFileTypeMessage(false);
            }, 500); // Hide the message after 30 seconds
        }
    };
    const [ getVehicleUploadData, {data:VehicleUploadData,isSuccess:DataSuccess}] = useVehicleUploaddataMutation();
    const handleSubmit = async () => {
        let selectedResellerId;
        if (orgType === 8) {
            selectedResellerId = selectedReseller;
        } 
        const payload = {
            resellerId: selectedResellerId,
            allImage:images.all,
            movingImage: images.moving,
            stopImage: images.stop,
            offlineImage: images.offline,
            chargingImage: images.charging,
            breakdownImage: images.breakdown,
            accidentImage: images.accident,
        };
     
        const res = await getVehicleUploadData(payload);
        setDataStatusConfirm(res.data.resultText);
    };
    
    const resetEverything = async () => {
       
        let selectedResellerId;
        if (orgType === 8) {
            selectedResellerId = selectedReseller;
        } 
    
        const payload = {
            resellerId: selectedResellerId,
            allImage:null,
            movingImage: null,
            stopImage: null,
            offlineImage: null,
            chargingImage: null,
            breakdownImage: null,
            accidentImage: null,
        };
        setDataStatusConfirm("Success");
        const res = await getVehicleUploadData(payload);
        if(res.data.resultText === 'Success'){
            setShowSubmit(true);
            setimgData([]);
            setImages({
                moving: null,
                stop: null,
                offline: null,
                charging: null,
                breakdown: null,
                accident: null,
                all:null
            })
        }
    }


    const [ getVehicleImg, {data:vehicleImg,isSuccess:imgDataSuccess, isLoading:imageDataLoading}] = useVehicleimageMutation();

    useEffect(() => {
        if(selectedReseller){
        const vehicleImage = {
        resellerId:selectedReseller,
    };
        getVehicleImg(vehicleImage);
    }
    },[selectedReseller])

    const [showSubmit, setShowSubmit] = useState(true);
    useEffect(()=>{
        if(imgDataSuccess){
            setimgData([]);
            setShowSubmit(true);
            if(vehicleImg.data!==undefined){
                setimgData(vehicleImg?.data);
                let lisObj = Object.entries(vehicleImg?.data);
                lisObj.forEach((key, index) => {
                    if(key[0] !== 'id' && key[0] !== 'resellerId' && key[1] !== '-' && key[1] !== null){
                        setImages(prevState => ({...prevState, [key[0]]:`https://`+key[1]}));
                        setShowSubmit(false);
                    }else{
                        setImages(prevState => ({...prevState, [key[0]]:null}))
                    }
                })
            }
           
        }
    },[imgDataSuccess])


    const dispatch = useDispatch();
    useEffect(() => {
      if (imgDataSuccess) {
        dispatch(
          snackService([
            vehicleImg?.resultMessage,
            vehicleImg?.resultText,
            imgDataSuccess,
          ])
        );
    
      }
    }, [imgDataSuccess]);

    const [getOrgList] = useGetDropdownListMutation();
    const [resellers, setResellers] = useState([]);
    
    useEffect(() => {
        if(orgType === 8){
          loadOrg();
        }
        
      }, []);

    const loadOrg = async() =>{
        const resllrPyld = {
          value: "reseller",
          roleId: roleId,
          searchText: "",
          orgType: "2",
          dealerId: 0,
          id: resellerId ,
        };
        const { data: resllrResp } = await getOrgList(resllrPyld);
        const { content: orgnLsstJsn } = resllrResp.data;
        setResellers(orgnLsstJsn);
      }



     
      const handleResellerChange = (event) => {
          setSelectedReseller(event.target.value);
      };

    return (
        <Fade
            in
            timeout={{ enter: theme.transitions.duration.complex, exit: theme.transitions.duration.leavingScreen, }}
        >   
            
            <Box sx={{ width: '100%' }}>
            <Backdrop open={imageDataLoading} sx={{zIndex:9999}}>
                <GridLoader color={theme.palette.primary.main} />
            </Backdrop>
                <Typography variant="h6" gutterBottom>Vehicle Settings</Typography>
                <Stack spacing={2}>
                    <Box>
                        <Typography sx={{ my: 2 }}>Map Vehicle Icon</Typography>
                        {orgType === 8 &&
                            <Select
                                value={selectedReseller}
                                onChange={handleResellerChange}
                                displayEmpty
                                fullWidth
                                sx={{ width:"400px"}}
                            >
                                <MenuItem value="" disabled>
                                    Select a Reseller
                                </MenuItem>
                                {resellers.map((reseller) => (
                                    <MenuItem key={reseller.key} value={reseller.key}>
                                        {reseller.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        <Stack direction='row' spacing={5} sx={{width:'100%'}}>
                            <Box sx={{ display: "grid", gridTemplateColumns:'auto auto auto auto', width:'100%'}}>
                            <Box sx={{ margin: "20px" }}>
                                    <Typography variant='body2' gutterBottom sx={{ textAlign: 'center' }}>All</Typography>
                                    <Tooltip title='Click to Upload' arrow>
                                        <Box
                                            component="label"
                                            sx={{
                                                "&:hover .overlay": { opacity: 0.3 },
                                                position: "relative",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "auto",
                                                width: "fit-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(e) => handleImageUpload(e, 'all')}
                                                accept='image/png,image/jpeg'
                                            />
                                            
                                            <Avatar src={images.all !== null && images.all !== '-' ? images.all : 'https://cdn-icons-png.flaticon.com/512/399/399281.png'} sx={{ width: 80, height: 80, margin: "auto", mb: 1 }} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box sx={{ margin: "20px" }}>
                                    <Typography variant='body2' gutterBottom sx={{ textAlign: 'center' }}>Moving</Typography>
                                    <Tooltip title='Click to Upload' arrow>
                                        <Box
                                            component="label"
                                            sx={{
                                                "&:hover .overlay": { opacity: 0.3 },
                                                position: "relative",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "auto",
                                                width: "fit-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(e) => handleImageUpload(e, 'moving')}
                                                accept='image/png,image/jpeg'
                                            />
                                            
                                            <Avatar src={images.moving !== null && images.moving !== '-' ? images.moving : 'https://cdn-icons-png.flaticon.com/512/399/399281.png'} sx={{ width: 80, height: 80, margin: "auto", mb: 1 }} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box sx={{ margin: "20px" }}>
                                    <Typography variant='body2' gutterBottom sx={{ textAlign: 'center' }}>Stop</Typography>
                                    <Tooltip title='Click to Upload' arrow>
                                        <Box
                                            component="label"
                                            sx={{
                                                "&:hover .overlay": { opacity: 0.3 },
                                                position: "relative",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "auto",
                                                width: "fit-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(e) => handleImageUpload(e, 'stop')}
                                                accept='image/png,image/jpeg'
                                            />

                                            <Avatar src={images.stop !== null && images.stop !== '-' ? images.stop : 'https://cdn-icons-png.flaticon.com/512/399/399281.png'} sx={{ width: 80, height: 80, margin: "auto", mb: 1 }} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box sx={{ margin: "20px" }}>
                                    <Typography variant='body2' gutterBottom sx={{ textAlign: 'center' }}>Charging</Typography>
                                    <Tooltip title='Click to Upload' arrow>
                                        <Box
                                            component="label"
                                            sx={{
                                                "&:hover .overlay": { opacity: 0.3 },
                                                position: "relative",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "auto",
                                                width: "fit-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(e) => handleImageUpload(e, 'charging')}
                                                accept='image/png,image/jpeg'
                                            />
                                            

                                            <Avatar src={images.charging !== null && images.charging !== '-' ? images.charging : 'https://cdn-icons-png.flaticon.com/512/399/399281.png'} sx={{ width: 80, height: 80, margin: "auto", mb: 1 }} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box sx={{ margin: "20px" }}>
                                    <Typography variant='body2' gutterBottom sx={{ textAlign: 'center' }}>Offline</Typography>
                                    <Tooltip title='Click to Upload' arrow>
                                        <Box
                                            component="label"
                                            sx={{
                                                "&:hover .overlay": { opacity: 0.3 },
                                                position: "relative",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "auto",
                                                width: "fit-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(e) => handleImageUpload(e, 'offline')}
                                                accept='image/png,image/jpeg'
                                            />
   
                                            <Avatar src={images.offline !== null && images.offline !== '-' ? images.offline : 'https://cdn-icons-png.flaticon.com/512/399/399281.png'} sx={{ width: 80, height: 80, margin: "auto", mb: 1 }} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box sx={{ margin: "20px" }}>
                                    <Typography variant='body2' gutterBottom sx={{ textAlign: 'center' }}>Accident</Typography>
                                    <Tooltip title='Click to Upload' arrow>
                                        <Box
                                            component="label"
                                            sx={{
                                                "&:hover .overlay": { opacity: 0.3 },
                                                position: "relative",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "auto",
                                                width: "fit-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(e) => handleImageUpload(e, 'accident')}
                                                accept='image/png,image/jpeg'
                                            />
                                            
                                            <Avatar src={images.accident !== null && images.accident !== '-' ? images.accident : 'https://cdn-icons-png.flaticon.com/512/399/399281.png'} sx={{ width: 80, height: 80, margin: "auto", mb: 1 }} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box sx={{ margin: "20px" }}>
                                    <Typography variant='body2' gutterBottom sx={{ textAlign: 'center' }}>BreakDown</Typography>
                                    <Tooltip title='Click to Upload' arrow>
                                        <Box
                                            component="label"
                                            sx={{
                                                "&:hover .overlay": { opacity: 0.3 },
                                                position: "relative",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "auto",
                                                width: "fit-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(e) => handleImageUpload(e, 'breakdown')}
                                                accept='image/png,image/jpeg'
                                            />
                                            
                                            <Avatar src={images.breakdown !== null && images.breakdown !== '-' ? images.breakdown : 'https://cdn-icons-png.flaticon.com/512/399/399281.png'} sx={{ width: 80, height: 80, margin: "auto", mb: 1 }} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                </Box>         
                        </Stack>
                        <Box sx={{ textAlign: 'center' }}>
                            <div style={{ display: showFileTypeMessage ? 'block' : 'none',color:"red" }}>
                                Only JPEG and PNG images are allowed<span style={{ color: 'red' }}>*</span>
                            </div>
                        </Box>

                        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} alignItems={'center'}>
                            <Button
                                type="button"
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={resetEverything}
                                disabled={selectedReseller === "" || images.all === null && images.moving === null && images.stop === null && images.charging === null && images.offline === null && images.accident === null  && images.breakdown === null ? true : false}
                            >
                                Remove All
                            </Button>
                            {
                                showSubmit &&
                                    <Button
                                    type="button"
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    onClick={handleSubmit}
                                    disabled={selectedReseller === "" || images.all === null && images.moving === null && images.stop === null && images.charging === null && images.offline === null && images.accident === null  && images.breakdown === null}
                                >
                                    Submit
                                </Button>
                            }
                            
                        </Stack>
                    </Box>
                </Stack>
                {DataSuccess && (
        <StyledSnackBar message={"Vechicle Uploaded successfully"}  status={dataStatusConfirm}  />
      )}
            </Box>
           
        </Fade>
        
    )
}

export default VehicleSettings;


