import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DropDown from "../shared/DropDown";
import { useTheme } from "@mui/material";
import { TextField, Typography } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-container": {
    alignItems: "flex-start",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} size="small">
        Assign Driver
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth='sm'
        fullWidth
        sx={{boxShadow:"none"}}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}
        >
          Assign Drivers To Vehicles
        </DialogTitle>
        <DialogContent>
        <Typography variant="body2" color='grey' gutterBottom sx={{mb:2}}>
        To subscribe to this website, please enter your email address here. We will send updates occasionally.
        </Typography>
        <TextField size="small" fullWidth variant="standard" label='Search Drivers'/>
          {/* <DropDown /> */}
        </DialogContent>
        <DialogActions>
          {/* <Button
            variant="contained"
            size="small"
            autoFocus
            onClick={handleClose}
            // sx={buttonStyle}
            
          >
            Save
          </Button> */}
          <Button color="success">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
