
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, alpha, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { TbRoute } from 'react-icons/tb';
import NoDataFound from '../../../Utils/NoDataFound';
import { useGetRouteTripSummaryHistoryListMutation, useGetRouteTripSummaryListMutation } from '../../../store/slice/RouteTripSlice/RouteTripSlice';
import { useSelector } from 'react-redux';
import TripList from './TripList';

function RouteAccordian(props) {
    const { routeListItem, map } = props;
    const theme = useTheme();
    const routeRef = useRef();
    const tripRef = useRef();
    const [tripOpen, setTripOpen] = useState(false);
    const [selectedTrip, setSelctedTrip] = useState(1); // Trip ID;
    const selectedHandleTrip = (value) => {
        setSelctedTrip(value)
    }
    const [getManageTrip, {data:manageTripData, isLoading:manageTripLoading, isSuccess:manageTripSuccess}] = useGetRouteTripSummaryHistoryListMutation();
    const [tripList, settripList] = useState(null);
    const [payload, setPayload] = useState(null);
    const date = useSelector((state) => state.controls.treeview.date);
    const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);
    let GetTrippayload = {
        endDate:date[1],
        startDate:date[0],
        vehicleId:selectedVehicle,
        page:0,
        size:25,
        searchText:'',
        customerId:'110384',
        orgId:'110385'  
    }
    
    const getRouteTrip = async (openState, RouteId) => {
        setTripOpen(openState);
        if(openState){
            GetTrippayload.routeId = RouteId;
            setPayload(GetTrippayload);
            const trips = await getManageTrip(GetTrippayload);
            settripList(trips?.data?.data?.content);
        }
    }


    useEffect(() => {
        if(date !== null && date !== undefined && date.length > 0 && tripList !== null && tripList !== undefined){
            getRouteTrip();
        }
    },[date])


  return (
    <ListItem dense sx={{flexDirection:'column', p:0, background:alpha(theme.palette.info.main, .05), borderRadius:'5px'}}>
            <ListItemButton onClick={() => getRouteTrip(!tripOpen, routeListItem.routeId)} dense sx={{width:'100%', position:'sticky', top:'0px', zIndex:999, background:theme.palette.success.main, boxShadow:'0px 3px 10px rgba(0,0,0, .1)'}} id={routeListItem.routeId}>
                <ListItemIcon sx={{minWidth:'auto', mr:1}}><TbRoute /></ListItemIcon>
                <ListItemText 
                    primary={<Typography color={'success.contrastText'}>{routeListItem.routeName}</Typography>}
                />
            </ListItemButton>
            <Collapse in={tripOpen} sx={{width:'100%', background:theme.palette.background.paper,}}>
                {tripList !== null && tripList?.length > 0 ? 
                tripList?.map((item, index) => <TripList index={index} key={index} item={item} selectedVehicle={selectedVehicle} map={map} />)
                : <NoDataFound description={'No trips found'}/> }
            </Collapse>
    </ListItem> 
  )
}

export default RouteAccordian