import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Typography,
  Tooltip,
  Toolbar,
  CardMedia,
  Stack,
  Box,
  Divider,
  ListItem,
  ListItemText,
  FormControlLabel,
  useTheme,
  Tabs,
  Tab,
  Switch,
  alpha,
  Button,
} from "@mui/material";
import { useSticky } from "react-table-sticky";
import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useBlockLayout,
  usePagination,
  useRowSelect,
  useResizeColumns,
  useFlexLayout
} from "react-table";
import { makeStyles } from "@mui/styles";
import { TbArrowsMoveHorizontal, TbEdit } from "react-icons/tb";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

import SearchBox from "../SearchBox";
import Checkbox from "@mui/material/Checkbox";
//import { visuallyHidden } from "@mui/utils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
//import SortIcon from "@mui/icons-material/Sort";
import SplitButton from "../ExportButton";
import "./addstyle.css";
import PropTypes from "prop-types";
//import { SELECTION_COLUMN } from "antd/lib/table/hooks/useSelection";
import {
  StyledTableHead,
  HeaderTableCell,
  BodyTableCell,
  MuiTablePagination,
  StyledTableRow,
  StyledTableContainer,
} from "./StyledEle";
// import { styled } from "@mui/system";
import nodatafound from "../../../assets/images/nodata.png";
import {
  Exportoptions,
  FilterMenu,
  StyledExportButtons,
  StyledPaperShadow,
  StyledSearchBarFilter,
  StyledSwitch,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../theme/StyledEle";
import { TbTableAlias } from "react-icons/tb";
import { FormControl } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { TableFiters } from "./TableWidget";
import Scrollbars from "react-custom-scrollbars-2";
import TablePaginationActions from "./TablePaginationActions";
import ScaleLoader from "react-spinners/ScaleLoader";
import { FaShareSquare } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import { MdOutlineRefresh } from "react-icons/md";
import { useSelector } from "react-redux";
import ImeiSwap from "../../IMEI-SWAP";
import { Empty } from "antd";

// function getComparator(order, orderBy) {
//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function descendingComparator(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

export const DataTable = ({
  isLoading,
  includeSearchBox = false,
  includeExportButton = true,
  includePagination = true,
  handleChange,
  handleExportClick,
  columns,
  data,
  initialState,
  styles,
  numberOfStickyColumns,
  onEdit,
  onDelete,
  onCheckbox,
  callbackChangePage,
  page = 0,
  rowsPerPage = 5,
  totalElements,
  totalPages = 1,
  callbck,
  commonFilter = {
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
    resendActivation: false,
  },
  onChangeXcl,
  onChangePdf,
  loading,
  specificPage,
  onView,
  checkbox = false,
  selectRow,
  onResendActivation,
  reload=false,
  handlerefreshTable,
  exportWithAll = true,
  payload = null,
  includeChangeImeiButton = false,
  onDeviceChange
}) => {


  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;
  let roleId = userData.roleId;

  const numberOfRows = 5;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0]?.accessor);

  const [isSorted, setIsSorted] = useState(true);
  const [columncell, setColumn] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
   
    //setPage(newPage)
    page = newPage;
    callbackChangePage(newPage, rowsPerPage);
  };

 

  const handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(+event.target.value);
    //setPage(page);
    callbackChangePage(page, +event.target.value);
    //page = page;
  };

  if (numberOfStickyColumns) {
    for (let i = 0; i < numberOfStickyColumns; i++) {
      columns[i].sticky = "left";
    }
  }


  const useStyles = makeStyles({
    table: {
      "[data-sticky-last-left-td]": {
        boxShadow: "2px 0px 3px #ccc",
      },
      "[data-sticky-td]": {
        position: "sticky",
        background: "#fff",
        width: "fit-content !important",
      },
      "[data-sticky-last-left-td]": {
        boxShadow: "2px 0px 3px #ccc",
      },
      overflowX: "scroll",
    },
    //selectDropdown: { color: "#fff", backgroundColor: "#121858" },
  });

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 800,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    allColumns,
    getToggleHideAllColumnsProps,
    prepareRow,
    pageCount,
    nextPage,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState,
      //:{
      //   hiddenColumns:columns.map(column => {
      //     if(column.show === false) return column.accessor || column.id;
      //   })
      // },
      useBlockLayout,
      defaultColumn
    },
    useSticky,
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
    //Create Check box
    useRowSelect,

    (hooks) => {
      checkbox &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            disableResizing: true,
            width:60,
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => {
              return (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original?.simNumber}/>
            )},
          },
          ...columns,
        ]);
    }
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, disabled, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <Checkbox
            color="secondary"
            ref={resolvedRef}
            {...rest}
            size="small"
            disabled={disabled !== null && disabled !== '0' ? false : true}
          />
        </>
      );
    }
  );

  useEffect(() => {
    {
      checkbox && selectRow(selectedFlatRows, selectedRowIds);
    }
  }, [selectedRowIds]);

  const handleClick = (cell, event, e) => {
    if (event.toLowerCase() === "view") {
      if (commonFilter.view === true) {
        onView(cell.row.original);
      }
    }

    if (event === "resendActivation") {
      if (commonFilter.resendActivation === true) {
        onResendActivation(cell.row.original);
      }
    }

    if (event.toLowerCase() === "edit") {
      if (commonFilter.edit === true) {
        onEdit(cell.row.original);
      }
    } else if (event.toLowerCase() === "delete") {
      if (commonFilter.delete === true) {
        onDelete(cell.row.original);
      }
    } else if (event.toLowerCase() === "checkbox") {
      if (e.target.checked === true) {
        onCheckbox(cell.row.original);
      }
    }
  };
  /*
  const firstPageRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  */
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickFIlter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const [tableTab, setTaleTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTaleTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const buttonsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
  let [exportBtn, setExportBtn] = useState(false);
  useEffect(() => {
    if(!exportWithAll && payload !== null && payload !== undefined){
      if((payload.customerId !== '-1' && payload.customerId !== undefined) || (payload.custId !== '-1' && payload.custId !== undefined ) ){
          setExportBtn(true);
      }else{
        setExportBtn(false);
      }
    }
    if(exportWithAll){
      setExportBtn(true);
    }
  },[payload])



  return (
    <Box sx={{width:'100%'}}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mb: 2 }}
        spacing={2}
        justifyContent="space-between"
      >
        <Box>
          <StyledSearchBarFilter
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            <Box>
              <Tooltip title="Filter" arrow>
                <IconButton
                  size="small"
                  id="table-Hide-columns"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickFIlter}
                >
                  <TbTableAlias />
                </IconButton>
              </Tooltip>
              <FilterMenu
                id="table-Hide-columns"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseFilter}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                elevation={0}
                sx={{ minWidth: "500px" }}
              >
                <Scrollbars autoHeight>
                  <ListItem
                    dense={true}
                    sx={{
                      borderBottom: `.5px solid ${theme.palette.divider}`,
                    }}
                  >
                    <ListItemText id="switch-list-label-wifi" primary="All" />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <StyledSwitch {...getToggleHideAllColumnsProps()} />
                        }
                      />
                    </FormControl>
                  </ListItem>
                  {allColumns.map((column, index) => {
                    return (
                      column.show !== false &&
                      <ListItem
                        dense={true}
                        key={index}
                        sx={{
                          borderBottom: `.5px solid ${theme.palette.divider}`,
                        }}
                      >
                        <ListItemText
                          id="switch-list-label-options"
                          primary={typeof(column.Header) !== 'function' ? column.Header : column.id}
                        />
                        {/* <Switch edge="end" inputProps={{ 'aria-labelledby': 'switch-list-label-wifi'}}
                            value={headerLabel.Header}
                            checked={hideColName.filter((item) => item === headerLabel.Header && true)}
                            onChange={(e) => getHideColName(e.target.checked)}
                            /> */}
                        <FormControl>
                          <FormControlLabel
                            control={
                              <StyledSwitch
                                {...column.getToggleHiddenProps()}
                              />
                            }
                          />
                        </FormControl>
                      </ListItem>
                    );
                  })}
                </Scrollbars>
              </FilterMenu>
            </Box>
            {
              reload &&
                <Box>
                  <Tooltip title="Refresh" arrow>
                    <IconButton 
                      size="small"
                      id="refresh"
                      onClick={handlerefreshTable}
                    >
                      <MdOutlineRefresh />
                    </IconButton>
                  </Tooltip>  
                </Box>
            }
          
            {includeSearchBox && <SearchBox onSearch={handleChange} />}
          </StyledSearchBarFilter>
        </Box>
        <Stack  
        direction="row"
        spacing={1}
        >
        {
          includeChangeImeiButton && (
<ImeiSwap onDeviceChange={onDeviceChange}/>
          )}

        {data.length > 0  && includeExportButton && exportBtn && (
          <Exportoptions onChangeXcl={onChangeXcl} onChangePdf={onChangePdf} />
        )}
        {/* {data.length > 0  && buttonsOpt !== null && (
          <Exportoptions onChangeXcl={onChangeXcl} onChangePdf={onChangePdf} exportOpt={buttonsOpt}/>
        )} */}
</Stack>
      </Stack>

      <StyledTableContainer>
        <Table
          {...getTableProps()}
          sx={{ overflowX: "scroll", borderCollapse: "separate" }}
        >
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <HeaderTableCell
                    {...column.getHeaderProps()}
                    key={index}
                  >   
                      {
                        <Tooltip placement="top" arrow sx={{zIndex:1}} title={column.Header.props !== undefined ? column.Header.props.header : column.Header} enterDelay={1000} enterNextDelay={500}>
                        <Typography>{column.render("Header")}</Typography>
                        </Tooltip>
                      }
                      
                    
                    {
                      column.canResize && (
                          <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                          >
                            <TbArrowsMoveHorizontal />
                          </div>
                      )
                    }
                    
                      {/* </span> */}
                  </HeaderTableCell>
                  )
                })}
              </TableRow>
            ))}
          </StyledTableHead>

          <TableBody {...getTableBodyProps()}>
            {isLoading ? (
              <BodyTableCell colSpan={2} sx={{ textAlign: "left" }}>
                <Box
                  sx={{
                    height: "100px",
                    display: "flex",
                    width: "250px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ScaleLoader color={theme.palette.secondary.main} />
                </Box>
              </BodyTableCell>
            ) : rows.length > 0 && data.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <StyledTableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === "Action") {
                        return (
                          <BodyTableCell {...cell.getCellProps()}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              sx={{ textAlign: "center", alignItems: "center" }}
                            >
                              {cell.value !== null &&
                                cell.value.map((v, index) => {
                                  return (
                                    <Tooltip
                                      title={
                                        v.toLowerCase() === "view"
                                          ? "View"
                                          : v.toLowerCase() === "edit"
                                          ? "Edit"
                                          : v === "resendActivation"
                                          ? "Resend Activation"
                                          : "Delete"
                                      }
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        sx={{
                                          display:
                                            v.toLowerCase() === "view" &&
                                            commonFilter.view === true
                                              ? "flex"
                                              : v.toLowerCase() === "edit" &&
                                                commonFilter.edit === true
                                              ? "flex"
                                              : v.toLowerCase() === "delete" &&
                                                commonFilter.delete === true
                                              ? "flex"
                                              : v === "resendActivation" &&
                                                commonFilter.resendActivation ===
                                                  true
                                              ? "flex"
                                              : "none",
                                          background:
                                            v.toLowerCase() === "view"
                                              ? alpha(
                                                  theme.palette.primary.main,
                                                  0.1
                                                )
                                              : v.toLowerCase() === "edit"
                                              ? alpha(
                                                  theme.palette.info.main,
                                                  0.1
                                                )
                                              : v === "resendActivation"
                                              ? alpha(
                                                  theme.palette.secondary.main,
                                                  0.1
                                                )
                                              : alpha(
                                                  theme.palette.error.main,
                                                  0.1
                                                ),
                                          color:
                                            v.toLowerCase() === "view"
                                              ? alpha(
                                                  theme.palette.primary.main,
                                                  1
                                                )
                                              : v.toLowerCase() === "edit"
                                              ? alpha(
                                                  theme.palette.info.main,
                                                  1
                                                )
                                              : v === "resendActivation"
                                              ? alpha(
                                                  theme.palette.secondary.main,
                                                  1
                                                )
                                              : alpha(
                                                  theme.palette.error.main,
                                                  1
                                                ),
                                          borderRadius: "5px",
                                        }}
                                        onClick={(e) => handleClick(cell, v, e)}
                                      >
                                        {v.toLowerCase() === "edit" &&
                                          commonFilter.edit === true && (
                                            <TbEdit />
                                          )}
                                        {v.toLowerCase() === "view" &&
                                          commonFilter.view === true && (
                                            <AiOutlineEye />
                                          )}
                                        {v.toLowerCase() === "delete" &&
                                          commonFilter.delete === true && (
                                            <AiOutlineDelete />
                                          )}
                                        {v === "resendActivation" &&
                                          commonFilter.resendActivation ===
                                            true && <BiMailSend />}
                                      </IconButton>
                                    </Tooltip>
                                  );
                                })}
                            </Stack>
                          </BodyTableCell>
                        );
                      } else if (cell.column.Header === "") {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{ textAlign: "center", display: "flex" }}
                          >
                            {cell.value === "checkBox" && (
                              <Checkbox
                                onChange={(e) =>
                                  handleClick(cell, cell.value, e)
                                }
                                style={{ fontSize: "15px" }}

                              />
                            )}
                          </BodyTableCell>
                        );
                      } else if (
                        cell.column.Header === "Active/InActive" &&
                        specificPage === "EV Fault Code"
                      ) {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{ textAlign: "center" }}
                          >
                            {cell.value === "InActive" ? (
                              <FiberManualRecordIcon />
                            ) : (
                              <FiberManualRecordIcon style={{ color: "red" }} />
                            )}
                          </BodyTableCell>
                        );
                      } else {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{
                              textAlign: "left",
                              color:
                                specificPage === "EV Fault Code"
                                  ? "red"
                                  : "black",
                            }}
                          >
                            {cell.render("Cell")}
                          </BodyTableCell>
                        );
                      }
                    })}
                  </StyledTableRow>
                );

                // : (
                //   <Box sx={{ pt: 0.5 }}>
                //     <Skeleton />
                //     <Skeleton />
                //   </Box>
                // );
              })
            ) : (
              <TableRow>
                <BodyTableCell colSpan={9} sx={{ textAlign: "center" }}>
                <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column', ml:4}}>
                   {/*  <CardMedia
                    component="img"
                    src={nodatafound}
                    sx={{
                      width: { xl: "120px", lg: "120px", md: "80px" },
                    }}
                  />
                  <Typography>No Data Found</Typography> */}
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Typography variant="body2">No Data Found</Typography>}/>
                  </Box>
                </BodyTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {includePagination && page >= 0 && rows.length > 0 && (
        <MuiTablePagination
          classes={{
            root: classes.color,
            menuItem: classes.menuItem,
          }}
          backIconButtonProps={{ className: classes.leftIconButton }}
          nextIconButtonProps={{ className: classes.rightIconButton }}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            MenuProps: { classes: { paper: classes.selectDropdown } },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100, 200]}
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={ totalElements < rowsPerPage * page  ? 0 : page }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Box>
  );
};
DataTable.propTypes = {
  loading: PropTypes.bool,
};
