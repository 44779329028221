import Service from "./service";

const reportServices = {
    

  overSpeedReport: function (resource) {
    return Service.post(
      "/OverSpeedReport/getOverSpeedReportGridView",
      resource,
      null
    );
  },

  ManualCanDataReport: function (resource) {
    return Service.post(
      "/ManualCanDataReport/getvehicleDataReportForWizard",
      resource,
      null
    );
  },

  ManualCanDataReportKG: function (resource) {
    return Service.post(
      "/ManualCanDataReport/getvehicleDataReportForKg2",
      resource,
      null
    );
  },
  ManualCanDataReportMontra: function (resource) {
    return Service.post(
      "/ManualCanDataReport/getvehicleDataReportForMontra",
      resource,
      null
    );
  },
  
  ChargingReport: function (resource) {
    return Service.post(
      "/ChargingReport/getChargingReport",
      resource,
      null
    );
  },
  // EVDataReport: function (resource) {
  //   return Service.post(
  //     "/EVDataReport/gridView",
  //     resource,
  //     null
  //   );
  // },
  GeoFenceReport: function (resource) {
    return Service.post("/GeoFenceReport/getGeoFenceReport", resource, null);
  },
  VehicleSummaryReport: function (resource) {
    return Service.post("/VehicleSummaryReport/gridView", resource, null);
  },

  FaultCodeReport: function (resource) {
    return Service.post(
      "/FaultCodeReport/getFaultCodeReport",
      resource,
      null
    );
  },
  travelDetailReport: function (resource) {
    return Service.post(
      "/TravelDetailReport/getTraveDetailReport",
      resource,
      null
    );
  },
  tripRangeReport: function (resource) {
    return Service.post("/RangeTripReport/gridView", resource, null);
  },

  tyrePressureReport: function (resource) {
    return Service.post(
      "/TyrePressureMonitoringSystemReport/gridView",
      resource,
      null
    );
  },
  IdleReport: function (resource) {
    return Service.post("/IdleReport/getIdleReportGridView", resource, null);
  },
  AVLDataReport: function (resource) {
    return Service.post("/AVLDataReport/gridView", resource, null);
  },
  stoppageReport: function (resource) {
    return Service.post(
      "/StoppageReport/getStoppageReportGridView",
      resource,
      null
    );
  },
  EVDataReport: function (resource) {
    return Service.post("/EVDataReport/gridView", resource, null);
  },
  TripReport: function (resource) {
    return Service.post("/TripReport/gridView", resource, null);
  },
  getstoppageReportXLS: function (request) {
    return Service.get("/StoppageReport/stoppageReportXLS", { request });
  },
  getstoppageReportPDF: function (request) {
    return Service.get("/StoppageReport/stoppageReportPDF", { request });
  },
  getEVDataReportXLS: function (request) {
    return Service.get("/EVDataReport/evDataXLS", { request });
  },
  getAVLDataReport: function (request) {
    return Service.get("/AVLDataReport/avlDataXLS", { request });
  },
  getTravelDetailReport: function (request) {
    return Service.get("/TravelDetailReport/travelDetailXLS", { request });
  },
  getTravelDetailReport: function (request) {
    return Service.get("/TravelDetailReport/travelDetailXLS", { request });
  },
  getTyrepressureXLS: function (request) {
    return Service.get("/TyrePressureMonitoringSystemReport/tyrePressureXLS", {
      request,
    });
  },
  getOverspeedXLS: function (request) {
    return Service.get("/OverSpeedReport/overSpeedXLS", { request });
  },
  getIdlereportXLS: function (request) {
    return Service.get("/IdleReport/idleReportXLS", { request });
  },
  getTripreportXLS: function (request) {
    return Service.get("/TripReport/tripReportXLS", { request });
  },
  getRangetripXLS: function (request) {
    return Service.get("/RangeTripReport/rangeTripXLS", { request });
  },
  getOverspeedPDF: function (request) {
    return Service.get("/OverSpeedReport/overSpeedPDF", { request });
  },
  getGeocodeAddress: function (request) {
    return Service.post("/GeoFence/getGeocodeAddress", request, null);
  },
  fleetSummaryVehicleWise: function (request) {
    return Service.post("/FleetSummaryReportVehicleWise/getFleetSummaryVehicleWiseGridView", request, null);
  },
  FleetSummaryReportDayWise: function (request) {
    return Service.post("/FleetSummaryReportDayWise/getFleetDayReportGridView", request, null);
  },
  


  // TripreportXLS: function (resource) {
  //   return Service.post("/TripReport/tripReportXLS", resource, null);
  // },
};

export default reportServices;
