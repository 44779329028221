import {  AccordionDetails, AccordionSummary, Box, FormLabel, Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {  StyledAccordion, StyledFormControl, StyledPaperShadow } from '../../theme/StyledEle'
import { MdOutlineExpandCircleDown } from 'react-icons/md'
import { useLocation } from 'react-router-dom';
import SimOnBoardServices from '../../services/simOnBoardService';
function SimView(props) {
    const theme = useTheme();
    const location = useLocation();
  
    const [editData, setEditData] = useState(null)
    const { data, ids } = props
    
   

    useEffect(async() => {
        const responseData = (await SimOnBoardServices.simView(ids)).data;
            setEditData(responseData);    
    },[])

  return (
    <Box>
        <StyledPaperShadow sx={{mt:2, overflow:'hidden'}}>
            <Grid container spacing={2} sx={{ maxHeight:'calc(100vh - 45vh)', overflowY:'scroll', height:'35%', minHeight:'calc(100vh - 50vh)'}}>
                <Grid item lg={8}>   
                    <StyledAccordion disableGutters elevation={0} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdOutlineExpandCircleDown />}
                        >
                            SIM Details
                        </AccordionSummary>
                        <AccordionDetails>
                        <Box>
                        <Grid container rowSpacing={2}>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>SIM Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.simNumber !== null ? editData?.formData?.simNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>SIM Status</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.simStatusName !== null ? editData?.formData?.simStatusName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Mobile Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.mobileNumber !== null ? editData?.formData?.mobileNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Provider Name</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.simProviderName !== null ? editData?.formData?.simProviderName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Plan Name</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.simPlanName !== null ? editData?.formData?.simPlanName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                        </Grid>
                    </Box>
                        </AccordionDetails>
                    </StyledAccordion>
                </Grid>
            </Grid>
        </StyledPaperShadow>
    </Box>
  )
}

export default SimView