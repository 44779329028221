import { Alert, Box, Collapse, Divider, Fab, FormLabel, Grid, Icon, Pagination, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { StyledFormControl, StyledTimeline } from '../../theme/StyledEle';
import { useOrgDetMutation } from '../../store/slice/ApiSlice';
import InnerTab from '../../Utils/InnerTab';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { useGetOrgActivityMutation } from '../../store/slice/AdminSlice/AdminSlice';
import { MdOutlineCheck, MdAdd, MdClose  } from "react-icons/md";
import moment from 'moment';
import { ScaleLoader, ScaleLoder } from 'react-spinners'

function ViewOrganization({data}) {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
    const theme = useTheme();
    const [ getOrganization, { data: getOrgData, isLoading: orgDataLoading, isSuccess: orgDataSuccess }] = useOrgDetMutation();
    const [ getOrgActivity, { data: activityData, isLoading: activityLoading, isSuccess: activitySuccess }] = useGetOrgActivityMutation();
    const [orgData, setOrgData] = useState(null);
    const [orgActivity, setOrgActivity] = useState(null);
    const [plan, setPlan] = useState(null);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(5);

    let activityPayload = {
        "selectedOrgId": data?.id,
        "page": page,
        "size": length,
        "orgId": orgId
    }

    const getActivity = async () => {
        const activity = await getOrgActivity(activityPayload);
        setOrgActivity(activity.data.data);
    }
    

    useEffect(async() => {
        if(data !== null && data !== undefined){
           // const organization = await getOrganization({orgId:data.id});
            setOrgData(data);
            getActivity();
        }
    },[])

    //Tabs
    const tabList = [
        {
            id:1,
            name:'Details',
            icon:'',
            show:true,
        },
        {
            id:2,
            name:'Activity',
            icon:'',
            show:true,
        }
    ]
    const [tabValue, setTabValue] = useState(1);
    const getTabValue = (value) => {
        setTabValue(value);
    }


    //Call  
    const pageChange = (event, value) => {
        setPage(value - 1);
        let page = value;
        activityPayload.page = page - 1;
        getActivity();
    }


  return (
        orgData !== null && orgData !== undefined && 
        <Box sx={{height:'100%', maxHeight:'75vh', overflowY:'auto'}}>
        <Box>
            <InnerTab tabList={tabList} getTabValue={getTabValue}/>
        </Box>
            {
                tabValue === 1 && 
                <Box sx={{p:2, background:theme.palette.background.paper}}>
            <Grid container spacing={2}>
            <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Organization Type</FormLabel>
                <Typography>{orgData.orgTypeName !== null && orgData.orgTypeName !== '' ? orgData.orgTypeName : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Organization Name</FormLabel>
                <Typography>{orgData.orgName !== null && orgData.orgName !== '' ? orgData.orgName : '-'}</Typography>
            </StyledFormControl>
        </Grid>

        {orgData.orgTypeName === "Dealer" &&
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Region</FormLabel>
                <Typography>{orgData.region !== null && orgData.region !== '' ? orgData.region : '-'}</Typography>
            </StyledFormControl>
        </Grid>
}
{orgData.orgTypeName === "Customer" &&
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Dealer</FormLabel>
                <Typography>{orgData.dealerName !== null && orgData.dealerName !== '' ? orgData.dealerName : '-'}</Typography>
            </StyledFormControl>
        </Grid>
}
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Address</FormLabel>
                <Typography>{orgData.cityName !== null && orgData.cityName !== '' ? orgData.cityName: '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>State</FormLabel>
                <Typography>{orgData.stateName !== null && orgData.stateName !== '' ? orgData.stateName: '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Mobile</FormLabel>
                <Typography>{orgData.orgPhoneNumber !== null && orgData.orgPhoneNumber !== '' ? orgData.orgPhoneNumber: '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Email Id</FormLabel>
                <Typography>{orgData.orgBizEmail !== null && orgData.orgBizEmail !== '' ? orgData.orgBizEmail: '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Pin code</FormLabel>
                <Typography>{orgData.orgZip !== null && orgData.orgZip !== '' ? orgData.orgZip: '-'}</Typography>
            </StyledFormControl>
        </Grid>
    </Grid>
                </Box>
            }
            {
                tabValue === 2 && 
                <Box sx={{background:theme.palette.background.paper}}>
                    <Box sx={{background:theme.palette.background.paper, px:2, py:1, position:'sticky', top:'0px', zIndex:9, borderBottom:`.5px solid ${theme.palette.divider}`}}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Typography variant='body2' color={'text.secondary'}>Organization - <Typography sx={{fontWeight:600}} component={'span'}>{orgData.name}</Typography></Typography>
                            <Pagination count={activityData !== undefined ? Math.round(activityData?.count / length) === 0 ?  1 : Math.round(activityData?.count / length)  : 0} variant='outlined' shape='rounded' page={page + 1} sx={{position:'sticky', top:'0px'}} size='small' onChange={pageChange}/>
                        </Stack>
                    </Box>
                    <Box>
                        <Collapse in={activityLoading}>
                            <Alert severity='success' icon={false} variant='filled' sx={{borderRadius:'0px', justifyContent:'center'}}>
                                <ScaleLoader color="#fff" height={15}/>
                            </Alert>
                        </Collapse>
                    </Box>
                    <StyledTimeline>
                        {
                           orgActivity !== null && orgActivity.length > 0 ? orgActivity.map((item) => {
                            return(
                                <>
                                <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant='body2'>{moment(item.key).format('Do-MMM-YYYY')}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                        <Stack sx={{mt:2}}>
                                            {
                                                item?.obj?.map((item) => {
                                                        let color = (item.action === "Create" || item.action === "create") ? theme.palette.success.main : item.action === "Update" ? theme.palette.info.main : theme.palette.error.main
                                                        let icon = (item.action === "Create" || item.action === "create") ? <MdAdd /> : item.action === "Update" ? <MdOutlineCheck />
                                                        : <MdClose />
                                                    return(
                                                        <Stack direction={'column'}>
                                                            <Icon sx={{background:color}}>{icon}</Icon>
                                                            <Stack direction={'column'}>
                                                                <Typography variant='body1' gutterBottom>{item.action}</Typography>
                                                                <Typography variant='caption'>{item.createdDate}</Typography>
                                                            </Stack>
                                                            <Typography variant='body2'>{item.description}</Typography>
                                                            <Divider sx={{my:1}}/>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Stack>
                                        
                                </TimelineContent>
                                </TimelineItem>
                                </>
                            )
                           }) : <Box sx={{px:3, py:1, background:theme.palette.grey[100]}}>
                                    <Typography variant='subtitle1'>No Activity</Typography>
                                </Box>
                        }
                    </StyledTimeline>
                </Box>
            }
        </Box>
  )
}

export default ViewOrganization