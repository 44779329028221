import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";

import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MouseOverPopover from "../shared/PopOver";

const text = "";

const SinocastelData = () => {
  const [responseData, setResponseData] = useState([]);

  //   useEffect(async () => {
  //     const responseData = (await ReportServices.getOverSpeed()).data.data;
  //     setResponseData(responseData);
  //   }, []);

  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb mainDiv="Reports" subDiv="Sinocastel Data" includePopup={true}></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>
          <MouseOverPopover text={text} title={"Sinocastel Data"} />
          <Typography sx={{ fontSize: "12px" }}>
            <b>Note</b>:Sinocastel Data shows data about the vehicle for each
            minute for a selected time period.
          </Typography>

          <Divider sx={{ borderBottomWidth: 2, mt: 1 }} />
          <Paper sx={{ backgroundColor: "#eeeeee", marginTop: 2 }}>
            <ReportDropDown />
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button>
          </Paper>
        </Paper>
      </Box>
    </Box>
  );
};

export default SinocastelData;
