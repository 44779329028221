import React, { useEffect, useRef, useState } from "react";
import {
  TreeViewAccodionBox,
  TreeViewListItemBtn,
} from "../Treeview/TreeviewStyledEle";
import {
  HiMiniFolderMinus,
  HiMiniFolderPlus,
  HiMiniUserGroup,
} from "react-icons/hi2";
import {
  Box,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Customer from "./Customer";
import {
  dropVehicles,
  isLoadingState,
  refresh,
  selectedVehicleData,
  selectedVehicleTrailsData,
  treeViewRollId,
  vehicleCountSlice,
} from "../../../store/slice/ControlsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetRegionListMutation,
  useGetVehicleByResellerMutation,
  useGetVehicleCountMutation,
  useAuditLogMutation
} from "../../../store/slice/ApiSlice";
import { ScaleLoader } from "react-spinners";

export default function Dealer(props) {
  const {
    dealers,
    userData,
    buttonIndex,
    index,
    openDealerId,
    openCustomerId,
    openfleetId,
    regionName
  } = props;
  const [open, setOpen] = useState(false);
  const DealerRef = useRef(dealers);
  const theme = useTheme();
  //dispatch
  const dispatch = useDispatch();

  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };
  //Payload Start
  //Dealer Payload
  let getCustomerPayload = {
    resellerId: userData.resellerId,
    dealerId: DealerRef.current.id,
    orgId: userData.resellerId,
    page: 0,
    size: 10,
    statusFlag: 11,
    value: "customer",
    vehmodel: "All",
    search: "",
  };
  //getvehicle Count
  let commonCountPayload = {
    resellerId: userData.resellerId,
  };
  //Get vehicle
  let commonPayload = {
    resellerId: userData.resellerId,
    dealerId: DealerRef.current.id,
    customerId: 0,
    orgId: userData.resellerId,
    checkboxStatus: false,
    page: 0,
    size: 1000,
    region: 0,
    vehModel: "All",
    searchText: "",
    zoneId: 0,
  };
  //Payload End

  //API Slice
  const [getCustomer, { data, isLoading, isSuccess }] =
    useGetRegionListMutation();

  //Vehicle Count & Vehicle
  const [
    getVehicleCount,
    {
      data: vehicleCountData,
      isLoading: countLoading,
      isSuccess: countSuccess,
    },
  ] = useGetVehicleCountMutation();
  const [
    getVehicleByReseller,
    {
      data: getVehicleData,
      isLoading: getVehicleLoading,
      isSuccess: getVehicleSuccess,
    },
  ] = useGetVehicleByResellerMutation();

  const selectedVehicle = useSelector(
    (state) => state.controls.selectedVehicle
  );

  //Set Customer Data List
  let [customerList, setCustomerList] = useState(null);
  useEffect(() => {
    if (isSuccess) {
      setCustomerList(data);
    }
  }, [isSuccess]);

  //Click outsite btn get Count and Load marker
  const getCount = () => {
    if(userData.resellerId !== 110224){
      commonCountPayload.selectId = DealerRef.current.id; 
      commonCountPayload.selectType = "dealer";
      dispatch(treeViewRollId([DealerRef.current.id, "dealer"]))
        getVehicleCount(commonCountPayload);
    }else{
      commonCountPayload.selectId = ""; 
      commonCountPayload.selectType = "";
      dispatch(treeViewRollId([null, null]))
      getVehicleCount(commonCountPayload);
    }
    getVehicleByReseller(commonPayload);
    dispatch(isLoadingState(true));
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
      dispatch(refresh([false, false]));
    }
  };

  //set Vehicle Count
  useEffect(() => {
    if (countSuccess === true || getVehicleSuccess === true) {
      dispatch(vehicleCountSlice(vehicleCountData));
      dispatch(dropVehicles(getVehicleData));
      dispatch(isLoadingState(false));
    }
  }, [countSuccess, getVehicleSuccess]);

  //collabse open
  const collabseOpen = () => {
    setOpen(!open);
    //if(customerList === null){
    getCustomer(getCustomerPayload);
    //}
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
      dispatch(refresh([false, false]));
    }

      
    auditPayload.message = "Tracking > Map > Treeview > "+userData.orgName+" > "+regionName+" > "+dealers.name;
    auditPayload.action = "Select";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
   // auditLog(auditPayload);
  };
  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );
  const isLoadingStates = useSelector((state) => state.controls.isloading);
  useEffect(() => {
    const dealyDebounce = setTimeout(() => {
      if (
        dealers !== null &&
        isSearchKeyword !== null &&
        isSearchKeyword.length >= 3
      ) {
        if (index === 0) {
          setOpen(true);
          getCustomerPayload.search = isSearchKeyword;
          getCustomer(getCustomerPayload);
        }
      }
    }, 1000);
    return () => clearTimeout(dealyDebounce);
  }, [isSearchKeyword]);

  //Onload Expand
  useEffect(() => {
    if (openDealerId === dealers.id && (isSearchKeyword === null || isSearchKeyword === "")) {
      setOpen(true);
      getCustomer(getCustomerPayload);
    }
  }, []);

  return (
    <TreeViewAccodionBox
      rolles={"dealer"}
      isOpen={open}
      lineOffset={54}
      key={index}
    >
      <TreeViewListItemBtn
        primary={"dealer"}
        secondaryAction={
          <IconButton onClick={collabseOpen}>
            {open ? <HiMiniFolderMinus /> : <HiMiniFolderPlus />}
          </IconButton>
        }
        id={dealers.id}
        ref={DealerRef}
        data-region-name={dealers.name}
        data-vehicle-count={dealers.vehicleCount}
        data-role-name={"dealer"}
        onClick={getCount}
        buttonIndex={buttonIndex + 20}
        key={index}
      >
        <ListItemIcon>
          <HiMiniUserGroup />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={0.5}>
              <Typography>{dealers.name}</Typography>
              <Typography>{`(${dealers.vehicleCount})`}</Typography>
            </Stack>
          }
        />
      </TreeViewListItemBtn>
      <Collapse in={open}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
            <ScaleLoader color={theme.palette.secondary.main} height={20} />
          </Box>
        ) : (
          customerList?.treeviewList.map((custmerItem, index) => (
            <Customer
              customers={custmerItem}
              key={index}
              index={index}
              userData={userData}
              dealerId={dealers.id}
              buttonIndex={buttonIndex}
              openCustomerId={openCustomerId}
              openfleetId={openfleetId}
              regionName={regionName}
              dealerName={dealers.name}
            />
          ))
        )}
      </Collapse>
    </TreeViewAccodionBox>
  );
}

export const DealerWise = (props) => {
  const { userData, onLoading, customerList } = props;
  const theme = useTheme();
  return (
    <TreeViewAccodionBox>
      {onLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
          <ScaleLoader color={theme.palette.secondary.main} height={20} />
        </Box>
      ) : (
        customerList?.treeviewList.map((custmerItem, index) => (
          <Customer
            customers={custmerItem}
            userData={userData}
            dealerId={userData.orgId}
          />
        ))
      )}
    </TreeViewAccodionBox>
  );
};
