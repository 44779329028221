import { dashboardApi } from "../ApiSlice";

const AuthSlice = dashboardApi.injectEndpoints({
    endpoints:(builder) => ({
        validateUSer:builder.mutation({
            query:(body) => ({
                url:"/checkUserForLogin",
                method: "POST",
                body: body,
            })
        }),
        authenticate:builder.mutation({
            query:(body) => ({
                url:"/authenticateWithReseller",
                method: "POST",
                body: body,
            })
        }),
        //user/getResellerUserDetail
        getUserdetails:builder.mutation({
            query:(body) => ({
                url:"/user/getResellerUserDetail",
                method: "POST",
                //body: body,
            })
        }),
        //userMenu
        getMenus:builder.mutation({
            query:(body) => ({
                url:"login/userMenu",
                method: "POST",
                body: body,
            })
        }),
        //Switch Account switchResellerAuthenticate
        switchAccount:builder.mutation({
            query:(body) => ({
                url:"/switchResellerAuthenticate",
                method: "POST",
                body: body,
            })
        }),
    })
})

export const { useValidateUSerMutation, useAuthenticateMutation, useGetUserdetailsMutation, useGetMenusMutation, useSwitchAccountMutation } = AuthSlice