import React, { useState, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Typography, Link, CardMedia, Stack, Tooltip, Chip } from "@mui/material";
import { Paper } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import customerKYCServices from "../../services/customerKYCService";
import { styled } from "@mui/material/styles";
import DraggableDialog from "../shared/DailogDrag";
import { FileUploadBox } from "../../theme/StyledEle";
import uploadcsv from '../../assets/images/uploadcsv.png'
// import Test from "./Test.xls";

function KYCUpload({ onCancel }) {
  const [file, setFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);

  const columns = useMemo(() => [
    {
      Header: "GST IN",
      accessor: "gstin",
    },
    {
      Header: "DEALER ID",
      accessor: "dealerId",
    },
  ]);

  const ButtonAlign = styled(Button)(() => ({
    textTransform: "none",
    backgroundColor: "#374850",
    color: "white",
    fontSize: "12px",
    marginRight: "5px",
    padding: "10px 16px",
    "&:hover": {
      backgroundColor: "orange",
    },
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    // const response = await dealerServices.uploadDealerList(payload);
    // var obj1 = {};

    // obj1.dialogButtonText = "Upload Status";
    // obj1.submitButtonText = "";
    // obj1.closeButtonText = "Close";
    // obj1.dialogText =
    //   response.data === "Success" ? "Uploaded Successfully" : "Upload Failed";

    // setDataStatus(true);
    // setDialogContent(obj1);
    // setDataStatusConfirm(false);
    // setOpen(true);
    // if (responseData.status === 200) {
    //   setTimeout(() => onCancel(), 2000);
    // }
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    // const responseData = await dealerServices.validateDealerList(payload);
    // setResponseData(responseData.data.result);
    setDataTable(true);
  };
  const FileRef = useRef();
  const handleOnChange = (e) => {
    setFile([...file, e.target.files[0]]);
    setIsFilePicked(true);
  };

  return (
    <>
      <Box maxWidth='md' sx={{m:'auto'}}>
        <form onSubmit={handleSubmit}>
          {/* <Link
            // href={Test}
            download
            display="flex"
            justifyContent="right"
            style={{ color: "green" }}
          >
            Click here to download the template for KYC Creation data upload
          </Link> */}
          <FileUploadBox>
              <input
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleOnChange}
                  className="fileupload"
                  ref={FileRef}
                />
                <CardMedia
                  component='img'
                  src={uploadcsv}
                  sx={{width:'60px', height:'60px', my:2}}
                />
                <Typography sx={{ml:2}} variant="h6" color='primary'>Select a XLS file to upload</Typography>
                {isFilePicked && <Chip sx={{mt:1}} label={FileRef.current.files[0].name} color="warning"/>}
          </FileUploadBox>
          <Stack direction='row' sx={{mt:2}} spacing={2} justifyContent='center'>
            <Tooltip title='Click here to download the template for KYC Creation' arrow>
            <Button
                variant="outlined"
                size="small"
              >
               Download Templete
              </Button>
            </Tooltip>
            <Button
                variant="contained"
                onClick={handleValidate}
                size="small"
              >
                Validate
              </Button>
              {dataTable === true && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color="success"
                >
                  Upload
                </Button>
              )}
          </Stack>
        </form>
      </Box>
      <Box sx={{my:2}}>
        {dataTable === true && (
          <DataTable
            includeSearchBox={false}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "50vw" }}
          />
        )}
      </Box>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </>
  );
}

export default KYCUpload;
