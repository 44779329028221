import React from "react";
import {
  Accordion,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  FormLabel,
  InputBase,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  Select,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  Backdrop,
  ListItem,
  AppBar,
  Drawer,
  Tabs,
  Tab,
  Alert,
  Badge,
  Tooltip,
  IconButton,
  Icon,
} from "@mui/material";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";
import { HiFolderDownload } from "react-icons/hi";
import { Timeline, TimelineContent } from "@mui/lab";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { useLocation } from "react-router";
import { ExportBtns } from "../Utils/Constans";

//use FUll height layout for maps Height100vh

export const FullHeightBoxLayout = styled(Box)(({ theme }) => ({
  height: "100%",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
}));

export const StyledMainLayout = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const StyledPageLayout = styled(Box)(({ theme }) => ({
  padding: "20px 20px",
}));

//App bar
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  //boxShadow:'0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1)',
  color: "#000",
  background:
    theme.palette.mode === "light"
      ? alpha(theme.palette.background.paper, 1)
      : '#000',
  zIndex: theme.zIndex.drawer + 1,
  backdropFilter: "blur(5px)",
}));

//Sider bar Start
export const StyledSideBarDrawer = styled(Drawer)(({ theme, sidebarColor }) => ({
  width: theme.sidebar.width,
  overflow: "hidden",
  height: "100vh",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s !important",
  "& .MuiDrawer-paper": {
    background:
      theme.palette.mode === "light"
        ? theme.palette.primary.dark
        : theme.sidebar.background,
    borderStyle: "none",
    transition:
      "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s !important",
  },
}));

export const StyledSidebarListBtn = styled(ListItemButton)(({ theme, sidebarColor }) => ({
  borderRadius: 5,
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[500]
      : theme.palette.grey[400],
  [theme.breakpoints.not("xl")]: {
    padding: "5px 10px",
  },
  "&.mainListItem, &.logoutButton": {
    borderRadius: "50px 0px 0px 50px",
  },
  "& .MuiListItemIcon-root": {
    color: theme.palette.mode === "light" ? "#fff" : theme.palette.grey[500],
  },

  "& .MuiTypography-root, & .arrowIcon": {
    fontSize:'13.5px',
    fontWeight:500,
    color: theme.palette.mode === "light" ? "#fff" : theme.palette.grey[500],
    zIndex: "5",
    [theme.breakpoints.not("xl")]: {
      fontSize: "10px",
    },
  },
  '& .arrowIcon':{
    fontSize:'18.5px',
  },
  "&.mainListItem:hover": {
    background:
      theme.palette.mode === "light"
        ? theme.palette.action.hover
        : theme.palette.background.selected,
  },
  "&.Mui-selected": {
    background: theme.palette.background.default,
    color: "#fff !important",
    position: "relative",
    borderRadius: "50px 0px 0px 50px",
    "& .topCurve": {
      position: "absolute",
      bottom: "0px",
      right: "0px",
      "&::before, &::after": {
        content: '""',
        width: "25px",
        height: "25px",
        position: "absolute",
        backgroundSize: "100%",
        objectFit: "contain",
        zIndex: "-1",
        transition: ".5s",
      },
      "&::before": {
        right: "0px",
        top: "0px",
        pointerEvents: "none",
        background: theme.palette.background.default,
      },
      "&::after": {
        right: "0px",
        top: "0px",
        pointerEvents: "none",
        borderRadius: "50px",
        background:
          theme.palette.mode === "light" ? sidebarColor : '#000',
        width: "50px",
        height: "50px",
      },
    },
    "& .bottomCurve": {
      position: "absolute",
      top: "0px",
      right: "0px",
      "&::before, &::after": {
        content: '""',
        width: "25px",
        height: "25px",
        position: "absolute",
        backgroundSize: "100%",
        objectFit: "contain",
        zIndex: "-1",
        right: "-16px",
      },
      "&::before": {
        right: "0px",
        top: "-25px",
        pointerEvents: "none",
        background: theme.palette.background.default,
      },
      "&::after": {
        top: "-50px",
        right: "0px",
        pointerEvents: "none",
        borderRadius: "50px",
        background:theme.palette.mode === "light" ? sidebarColor : '#000',
        width: "50px",
        height: "50px",
      },
    },

    "& .MuiListItemIcon-root": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.primary.dark
          : theme.palette.common.white,
    },
    "& .MuiTypography-root, & .arrowIcon": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.primary.dark
          : theme.palette.common.white,
    },
    "&:hover": {
      background:
        theme.palette.mode === "light"
          ? theme.palette.background.default
          : theme.palette.background.default,
    },
  },

  "&.settings, &#listBtn": {
    color: theme.palette.text.primary,
    borderRadius: "5px",
    "& .MuiTypography-root": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.text.primary
          : theme.palette.grey[400],
    },
    "&.Mui-selected": {
      background: theme.palette.primary.light,
      "& .MuiListItemIcon-root": {
        color:
          theme.palette.mode === "light"
            ? `${theme.palette.primary.contrastText}`
            : `${theme.palette.text.primary} !important`,
      },
      "& .MuiTypography-root": {
        color:
          theme.palette.mode === "light"
            ? `${theme.palette.primary.contrastText}`
            : `${theme.palette.text.primary} !important`,
      },
    },
    "&#listBtn": {
      padding: "10px 5px",
      "& .MuiCheckbox-root": {
        padding: "0px 10px 0px 0px",
      },
    },
    "&.assignBtn": {
      "&.Mui-selected": {
        background: "transparent !important",
        color: `${theme.palette.text.primary} !important`,
        "& .MuiTypography-root": {
          color: ` ${theme.palette.text.primary} !important`,
        },
      },
      background: "transparent !important",
      color: `${theme.palette.text.primary} !important`,
    },
  },

  "& .MuiListItemIcon-root": {
    minWidth: "auto !important",
    marginRight: "10px",
    fontSize: "20px",
    [theme.breakpoints.not("xl")]: {
      fontSize: "14px",
      marginRight: "0px",
    },
  },
  //Treeview
  "&.treeViewOptions": {
    padding: "0px !important",
  },
}));

export const StyledListItemAcord = styled(ListItem)(({theme}) => ({
  '&.zoneButtonRoot':{
    padding:'0px',
    borderRadius:'5px',
    '& .MuiButtonBase-root':{
      padding:'0px 0px',
      '& .MuiListItemIcon-root':{
        minWidth:'0px',
        marginRight:'15px',
        fontSize:'20px',
      }
    }
  },
  '&.Mui-selected':{
      background:alpha(theme.palette.secondary.main, .2),
      '.MuiTypography-root, .MuiListItemIcon-root':{
        color:theme.palette.secondary.main,
      }
  }
}))

export const StyledListItem = styled(ListItem)(({theme}) => ({
    '&.zoneButtonRoot':{
      padding:'0px',
      borderRadius:'5px',
      '& .MuiButtonBase-root':{
        padding:'5px 10px',
        '& .MuiListItemIcon-root':{
          minWidth:'0px',
          marginRight:'15px',
          fontSize:'20px',
        }
      }
    },
    '&.Mui-selected':{
        background:alpha(theme.palette.secondary.main, .2),
        '.MuiTypography-root, .MuiListItemIcon-root':{
          color:theme.palette.secondary.main,
        }
    }
}))

export const StyledHeaderMenuBtn = styled(ListItemButton)(({ theme }) => ({
  textDecoration: "none !important",
  color: `${theme.palette.text.primary} !important`,
  borderRadius:'10px',
  "&.active": {
    background: theme.palette.action.selected,
  },
  "&:hover": {
    color: theme.palette.text.primary,
  },
}));

export const StyledSideBarBox = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "10px 0px 0px 5px",
  "& .MuiCollapse-root": {
    padding: "0px 5px 0px 0px",
  },
}));

export const SidebarListItem = styled(ListItem)(({ theme }) => ({
  display: "block",
  "& .MuiListItemSecondaryAction-root": {
    right: "15px",
    fontSize: "20px",
  },
  "&.active": {
    background: theme.palette.background.default,
  },
  "& .submenu a": {
    fontWeight: 400,
    paddingLeft: "45px",
    [theme.breakpoints.not("xl")]: {
      paddingLeft: "25px",
    },
    "&:before": {
      position: "absolute",
      width: "15px",
      height: "1px",
      background:
        theme.palette.mode === "light"
          ? theme.palette.secondary.light
          : theme.palette.grey[500],
      content: '""',
      left: "22px",
      [theme.breakpoints.down("xl")]: {
        width: "8px",
      },
    },
  },
  "& .submenu a.active .MuiTypography-root": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.common.white,
  },
  "& .submenu a.active": {
    //background:theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 1) : theme.palette.action.selected,
    color:
      theme.palette.mode === "light"
        ? theme.palette.text.primary
        : theme.palette.text.primary,
  },
  "& .submenu a .MuiTypography-root": {
    fontWeight: 400,
    color:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[400],
  },
  "& .submenu a .MuiListItemIcon-root ": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[400],
  },
  "&.inActive": {
    background: theme.palette.error.main,
  },
  "& a": {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      backgroundImage:
        theme.palette.mode === "light"
          ? `linear-gradient(90deg, ${alpha(
              theme.palette.primary.main,
              1
            )} , transparent)`
          : `linear-gradient(90deg, ${alpha(
              theme.palette.action.hover,
              0.1
            )} , transparent)`,
      borderRadius: "50px",
    },
  },
  "& a.active": {
    position: "relative",
    zIndex: 999,
    "&::after": {
      content: "''",
      width: "100%",
      height: "100%",
      position: "absolute",
      left: "0px",
      top: "0px",
      backgroundImage:
        theme.palette.mode === "light"
          ? `linear-gradient(90deg, ${alpha(
              theme.palette.primary.main,
              1
            )} , transparent)`
          : `linear-gradient(90deg, ${alpha(
              theme.palette.action.hover,
              0.1
            )} , transparent)`,
      borderRadius: "50px",
      zIndex: -1,
      //boxShadow:'0px 0px 5px rgba(0,0,0,0.5)'
    },
  },
  "& .sidebarCollabse": {
    position: "relative",
    transition: "height 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s !important",
    "&:before": {
      position: "absolute",
      width: "1px",
      height: "100%",
      background:
        theme.palette.mode === "light"
          ? theme.palette.secondary.light
          : theme.palette.grey[500],
      content: '""',
      left: "22px",
      zIndex: 9999,
    },
  },
}));
export const StyledSidebarMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "0px 0px 0px 10px",
    marginLeft: "10px",
    background:
      theme.palette.mode === "light"
        ? theme.palette.background.default
        : theme.palette.background.default,
    zIndex: 999,
    maxWidth: "220px",
    width: "100%",
    overflowX: "visible",
    border: `.5px solid ${theme.palette.divider}`,
    // '&::before':{
    //   width:'20px',
    //   height:'20px',
    //   background:
    //   theme.palette.mode === "light"
    // ? theme.palette.error.main
    // : theme.palette.background.default,
    // position:'absolute',
    // top:'15px',
    // left:'0px',
    // content:'""',
    // transform:'rotate(45deg)',

    // }
  },
  "&.treeViewMenuOptions": {
    "& .MuiPaper-root": {
      padding: "0px 0px",
      background:
        theme.palette.mode === "light"
          ? theme.palette.background.paper
          : theme.palette.background.default,
      zIndex: 999,
    },
    ".treeViewMenuList": {
      "& .MuiListItemButton-root": {
        padding: "0px 10px",
      },
    },
  },
}));

export const StyledSidebarSubLinkBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: "flex",
  lineHeight: 2,
  background:
    theme.palette.mode === "light"
      ? theme.palette.primary.dark
      : theme.palette.background.default,
  ":hover": {
    background:
      theme.palette.mode === "light"
        ? theme.palette.action.focus
        : theme.palette.background.default,
  },
  alignItems: "center",
  borderRadius: "0px",
  "& a": {
    textDecoration: "none !important",
    padding: "5px 25px",
    width: "100%",
    borderRadius: "0px",
    cursor: "pointer",
    background: alpha(theme.palette.primary.dark, 0.9),
    borderBottom: `.5px solid ${theme.palette.divider}`,
    "& .MuiTypography-root": {
      color: theme.palette.primary.contrastText,
      fontWeight: 500,
    },
    "&.active .MuiTypography-root": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.secondary.main
          : theme.palette.secondary.main,
    },
    ":hover": {
      background: alpha(theme.palette.primary.main, 0.9),
      borderRadius: "0px",
    },
    "&.active": {
      backgroundImage: "none",
      background:
        theme.palette.mode === "light"
          ? alpha(theme.palette.primary.main, 0.5)
          : alpha(theme.palette.secondary.main, 0.1),
      "&::after": {
        backgroundImage: "none",
        borderRadius: 0,
      },
    },
    // '&::before':{
    //   width:'5px',
    //   height:'1px',
    //   background:'#000',
    //   content:'""',
    //   left:'10px',
    //   position:'absolute'
    // }
  },
}));
/// Accodion Sidebar
export const StyledSidebarAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiPaper-root": {
    background: "transparent",
    boxShadow: "none",
  },
}));
//Sider bar End

//Breadcrumb
export const StyledBreadcrumb = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "8px",
  ".MuiTypography-root.MuiTypography-body1": {
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.not("xl")]: {
    h6: {
      fontSize: ".8rem",
    },
  },
}));

export const StyledContentArea = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  //background: theme.palette.mode === 'light' ? "#eaf3ff" : "#0a1929",
  overflowY: "hidden",
}));

export const StyledFormButtonsRow = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  width: "100%",
}));

export const SoftButton = styled(Button)(({ theme }) => ({
  borderRadius:'5px',
  '&.wait':{
    cursor:'wait'
  },
  "&.primary": {
    background: alpha(theme.palette.primary.main, 0.8),
    color: theme.palette.primary.contrastText,
  },
  "&.secondary": {
    background: alpha(theme.palette.secondary.main, 0.8),
    color: theme.palette.secondary.contrastText,
  },
  "&.info": {
    background: alpha(theme.palette.info.main, 0.8),
    color: theme.palette.info.contrastText,
  },
  "&.warning": {
    background: alpha(theme.palette.warning.main, 0.8),
    color: theme.palette.warning.contrastText,
  },
  "&.success": {
    background: alpha(theme.palette.success.main, 0.8),
    color: theme.palette.success.contrastText,
  },
  "&.error": {
    background: alpha(theme.palette.error.main, 0.8),
    color: theme.palette.error.contrastText,
  },
  '&.success-soft':{
    background: alpha(theme.palette.success.main, 0.16),
    color: theme.palette.success.main,
  },
  '&.warning-soft':{
    background: alpha(theme.palette.warning.main, 0.16),
    color: theme.palette.warning.main,
  },
  '&.info-soft':{
    background: alpha(theme.palette.info.main, 0.16),
    color: theme.palette.info.main,
  },
  '&.error-soft':{
    background: alpha(theme.palette.error.main, 0.16),
    color: theme.palette.error.main,
  },
  "&.map-btn": {
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
  },
  pointerEvents: "all",
}));

export const StyledStackTableRow = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  my: 2,
}));

export const StyedFormTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  //color: theme.palette.primary.main,
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "10px",
}));

export const StyledFiterButton = styled(Button)(({ theme }) => ({
  margin: "8px",
  marginBottom: "4px",
}));

//Filter DropDown Styled
export const StyledFilterDropDown = styled(Select)(({ theme }) => ({
  fontWeight: 500,
  width: "100%",
  borderStyle: "none",
  fontSize: "14px",
  "& .MuiSelect-select": {
    padding: "7px 5px",
  },
  "& .MuiPaper-root": {
    maxHeight: "120px",
    width: 160,
    overflowX: "scroll",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "12px",
  },
  "&.newdropdown fieldset": {
    borderStyle: "none",
  },
  "& fieldset": {
    borderStyle: "none",
  },
}));

//FormIconStack
export const FormIconStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  borderWidth: 0.5,
  borderColor: theme.palette.divider,
  borderStyle: "solid",
  paddingLeft: "8px",
  borderRadius: "2px",
}));


//Input Elements
//StyledInputBase
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  fontWeight: 400,
  padding: "1px 0px",
  "input::plceholder": {
    fontWeight: 400,
  },
}));
//TextFiled
export const StyledTextField = styled(TextField)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  '& .MuiOutlinedInput-notchedOutline ':{
    borderRadius:'10px',
  },
  "& .Mui-disabled": {
    background: theme.palette.action.hover,
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "10px",
  },
  "&.dashboard-datePicker input": {
    fontSize: "14px",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: "10px",
    },
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "-24px",
    right: "0px",
    margin: "0",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    borderRadius:'10px',
    boxShadow: `0px 0px 0px 3px ${
      theme.palette.mode === "light"
        ? alpha(theme.palette.primary.main, 0.2)
        : "rgba(255, 255, 255, 0.23)"
    } !important`,
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.error.main, 0.2)}`,
  },
  //${alpha(theme.palette.primary.main,0.5)}
  "& img": {
    width: "auto",
    objectFit: "contain",
    background: theme.palette.grey[200],
    marginRight: "15px",
  },
  "&.captcha_img .MuiOutlinedInput-root": {
    padding: "0px !important",
  },
}));

export const StyledTextFieldRoute = styled(TextField)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  // '& MuiOutlinedInput-notchedOutline.Mui-error':{
  //   background: '#ff000014',
  // },
  "& .Mui-disabled": {
    background: theme.palette.action.hover,
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "10px",
  },
  "&.dashboard-datePicker input": {
    fontSize: "14px",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: "10px",
    },
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "-24px",
    right: "0px",
    margin: "0",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    boxShadow: `0px 0px 0px 3px ${
      theme.palette.mode === "light"
        ? alpha(theme.palette.primary.main, 0.2)
        : "rgba(255, 255, 255, 0.23)"
    } !important`,
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.error.main, 0.2)}`,
  },
  //${alpha(theme.palette.primary.main,0.5)}
  "& img": {
    width: "10px !important",
    objectFit: "contain",
    background: theme.palette.grey[200],
    marginRight: "6px",
  },
  "&.captcha_img .MuiOutlinedInput-root": {
    padding: "0px !important",
  },
}));

//Dropdown
export const StyledDropDown = styled(Select)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "-24px",
    right: "0px",
    margin: "0",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    boxShadow: `0px 0px 0px 3px ${
      theme.palette.mode === "light"
        ? alpha(theme.palette.primary.main, 0.2)
        : "rgba(255, 255, 255, 0.23)"
    } !important`,
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
    boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.error.main, 0.2)}`,
  },
  // '& .MuiOutlinedInput-notchedOutline.Mui-error':{
  //   background: '#ff000014',
  // },
  "& .MuiPaper-root": {
    maxHeight: "230px",
  },
}));

//Form Label
export const StyledFormControlLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: 3,
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[400],
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
  "&.Mui-focused": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.grey[800]
        : theme.palette.grey[400],
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: ".6rem",
  },
}));

//From control
export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "0px",
    right: "0px",
    margin: "0",
  },
  '& .MuiFormLabel-root':{
    color:theme.palette.text.secondary,
    fontWeight:400
  },
  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main,
  },
}));

//From control
export const StyledFilterFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  minWidth: "165px",
  maxWidth: "min-content",
  margin: 2,
  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main,
  },
  "&.dateRangePicker": {
    "& .MuiInputBase-input": {
      fontWeight: 500,
    },
    "& .dateRangePicker_box": {
      display: "flex",
    },
    "& .CustomDateRangePicker": {
      opacity: 0,
      position: "absolute",
    },
  },
}));

//Menu Item
export const StyledFilterMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  "&.Mui-selected": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "12px",
  },
}));

//Search
export const StyledFilterSearch = styled(TextField)(({ theme }) => ({
  padding: "5px 5px !important",
  position: "sticky",
  top: "0px",
  background: theme.palette.background.paper,
  zIndex: 999,
  borderBottom: `.5px solid ${theme.palette.divider}`,
  "& .MuiInputBase-root": {
    borderRadius: "10px",
  },
}));

//Switch
export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

//Paper Shadow
export const StyledPaperShadow = styled(Paper)(({ theme }) => ({
  boxShadow:
    theme.palette.mode === "light"
      ? "0 4px 25px #a8b4d01a !important"
      : "0 3px 3rem #0000000b",
  border:
    theme.palette.mode === "light"
      ? "1px solid #e5e5e5"
      : "1px solid #bddcfc21",
  padding: "10px",
  display: "flex",
  alignItems: "end",
  borderRadius: "5px",
  "&.loginWrapper": {
    background: alpha(theme.palette.background.paper, 0.8),
    display: "flex",
    alignItems: "baseline",
  },
}));

export const StyledMapPaper = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.default, 0.8),
  position: "fixed",
  width: "-webkit-fill-available",
  borderRadius: "0px",
  borderBottom: `.5px solid ${theme.palette.divider}`,
  backdropFilter: "blur(2px)",
}));

//Card
export const StyledCardShadow = styled(Card)(({ theme }) => ({
  boxShadow:
    theme.palette.mode === "light"
      ? "0 4px 25px #a8b4d01a !important"
      : "0 3px 3rem #0000000b",
  border:
    theme.palette.mode === "light"
      ? "1px solid #e5e5e5"
      : "1px solid #bddcfc21",
  borderRadius: "5px",
  //border:theme.palette.divider,
  //background:'#fff'
}));

export const StyledDropDownPaper = styled(Paper)(({ theme }) => ({
  padding: "10px",
  display: "flex",
  alignItems: "self-end",
  boxShadow: "0px 0px 8px #dbe0e8;",
  marginBottom: 15,
}));

export const StyledTableTitle = styled(Typography)(({ theme }) => ({
  fontSize: ".9rem",
  fontWeight: "500",
  //color: theme.palette.grey[700],
  display: "none",
}));

//Filter DropDown Styled End

// Rsuite Calendar
export const StyledCalandar = styled(Paper)(({ theme }) => ({
  background: theme.palette.paper,
  position: "relative",
  zIndex: "2",
  display: "flex",
  flexDirection: "column",
  padding: "2px",
  width: "fit-content",
  "& .customCal": {
    width: "350px",
    position: "absolute",
    top: "30px",
    pointerEvents: "none",
    opacity: 0,
  },
  "& .rs-btn-default": {
    padding: "5px !important",
    boxShadow: "none",
    "& input:focus": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  "& .MuiInputBase-root": {
    width: "165px",
    fontWeight: 400,
    [theme.breakpoints.down("xl")]: {
      fontSize: "12px",
      input: {
        padding: "7px 7px",
      },
    },
  },
  ".rs-btn-primary": {
    background: theme.palette.primary.dark,
  },
}));

export const StyledCalandarMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "165px",
  },
}));

// Fileupload
export const FileUploadBox = styled(Box)(({ theme }) => ({
  background:
    theme.palette.mode === "light" ? "#f8f8f8" : theme.palette.background.paper,
  borderRadius: 2,
  border: `0.5px solid ${theme.palette.info.main}`,
  borderStyle: "dashed",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
  padding: "15px 0px",
  "& .fileupload": {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  "& img": {
    width: "fit-content !important",
    height: "120px !important",
    objectFit:'contain'
  },
  "&.settingsUpload": {
    padding: "10px",
    height: "100%",
    borderStyle: "none",
    "& img": {
      width:'120px !important',
      height: "120px !important",
    },
    "& .favIcon": {
      width: "30px !important",
      height: "30px !important",
      position: "absolute",
      top: "35%",
      left: "46%",
      borderRadius: "5px",
    },
  },
}));

//Toogle Button start
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
 
    },
    "& .Mui-selected": {
      background: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
    "& .MuiToggleButton-root": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.primary.main
          : theme.palette.common.white,
      pointerEvents: "all",
           padding:'8px !important'
    },
    //Family Toogle style
    "&.fontFamilyToggle": {
      "& .MuiToggleButton-root": {
        padding: "5px 10px",
        color: theme.palette.text.primary,
        width: "100%",
        "& h6": {
          textTransform: "none",
        },
        "& p": {
          margin: "0",
        },
        "&:nth-of-child(1) h6, &:nth-of-child(1) p": {
          fontFamily: "'Rubik', sans-serif",
        },
        "&:nth-of-child(2) h6, &:nth-of-child(2) p": {
          fontFamily: "'Roboto', sans-serif",
        },
        "&:nth-of-child(3) h6, &:nth-of-child(3) p": {
          fontFamily: "'Montserrat', sans-serif",
        },
        "&:nth-of-child(3) h6, &:nth-of-child(3) p": {
          fontFamily: "'Poppins', sans-serif",
        },
      },
    },
    "&.playbackToggleGroup": {},
    "&.map-zoom-controls": {
      padding: "5px 5px",
      "& button": {
        padding: "5px 10px",
        background: theme.palette.background.paper,
        margin: "0px 5px",
        borderRadius: "5px !important",
        border: `.5px solid ${theme.palette.divider} !important`,
      },
    },
    "&.new-zoom-level-group": {
      background: theme.palette.background.paper,
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
      borderRadius: "10px",
      overflow: "hidden",
      "& .MuiToggleButton-root": {
        background: theme.palette.background.paper,
        margin: "0px 0px",
        lineHeight: "normal",
        padding: "10px",
        borderWidth: "0px 1px 0px 0px",
      },
    },
  })
);

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: "red",
  "&.playbackToggleBtn": {
    padding: "0px 10px",
    margin: "0px 5px",
    border: `.5px solid ${theme.palette.divider} !important`,
    borderRadius: "5px !important",
  },
}));
//Toogle Button end

//Styled Tabs start
export const StyledTabBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  borderWidth: "0px 0px .2px 0px",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  margin: "15px 0px",
}));

export const StyledTabsGroup = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    flexDirection: "row-reverse",
  },
  "&.verticalTab": {
    "& .MuiTabs-flexContainer": {
      flexDirection: "column",
    },
    ".MuiTab-root": {
      borderRadius: "10px",
      width: "100%",
      textAlign: "left",
      alignItems: "flex-start",
      minHeight: "0px",
      padding: "10px 20px",
    },
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  fontSize: 14,
  color: theme.text.disabled,
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  transition:'.5s',
  "&.Mui-selected": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.primary.main
        : theme.palette.common.white,
    background:
      theme.palette.mode === "light"
        ? theme.palette.background.paper
        : theme.selected.background,
    border:`.5px solid ${theme.palette.divider}`,
    boxShadow:
    theme.palette.mode === "light"
      ? "1px 4px 7px #ddd !important"
      : "0 3px 3rem #0000000b",
  },
}));

export const StyledTableBox = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  margin: "20px 0px",
}));

export const StyledMapControlButtons = styled(Button)(({ theme }) => ({
  border: `.5px solid ${theme.palette.divider}`,
  padding: "0px 10px",
  color: theme.palette.text.primary,
  background: theme.palette.action.hover,
  margin: "0px 3px",
  minWidth: "auto",
  fontSize: "22px",
  "&:hover": {
    background: theme.palette.action.hover,
  },
  "&.Mui-selected": {
    background: alpha(theme.palette.secondary.main, 0.32),
    color: theme.palette.secondary.main,
  },
}));

export const StyledBottomTableRow = styled(TableRow)(({ theme }) => ({}));

export const StyledBottomTableCell = styled(TableCell)(({ theme }) => ({
  padding: "4px 10px",
  border: 0,
  width: "50%",
  fontWeight: "500",
  "&.bottomTitle": {
    fontWeight: "400",
    fontSize: "14px",
    width: "20%",
  },
  "&.bottomSubtitle": {
    fontSize: "14px",
  },
}));

//Timeline Chip left
export const StyledTimeLineChipLeft = styled(Chip)(({ theme }) => ({
  borderRadius: "15px 15px 0px 15px",
}));

export const StyledTimeLineContentRight = styled(TimelineContent)(
  ({ theme }) => ({
    "& .MuiPaper-root:nth-of-child": {
      borderRadius: "0px 15px 25px 15px",
    },
  })
);

export const StyledTimeLineCardRight = styled(Card)(({ theme }) => ({
  border: "1px solid #ccc",
  borderRadius: "15px",
  margin: "10px 0px",
  padding: "15px",
}));

// Styled Accordion
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderBottom: `0px solid ${theme.palette.divider} !important`,
  margin: "0px",
  boxShadow: "none !important",
  borderRadius:'10px !important',
  overflow:"hidden !important",
  '& .MuiAccordionSummary-root':{
    overflow:'scroll',
    height:'100%',
    background:'red',
  },
  '& .MuiAccordionSummary-root.Mui-expanded':{
    background:`${theme.palette.background.paper} !important`,
    margin: "0px 0px !important",
  },
  "&.Mui-expanded": {
    margin: "0px 0px !important",
  },
  "&:not(:last-child)": {
    borderBottom: 1,
  },
  "&:before": {
    display: "none",
  },
  "& .Mui-expanded": {
    margin: "12px 0px !important",
    minHeight: "auto !important",
  },
  "& .MuiAccordionSummary-root": {
    margin: "0px 0px !important",
    flexDirection: "row-reverse", 
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginRight: "10px !important",
    },
    "&.Mui-expanded": {
      background: theme.palette.action.hover,
      color: theme.palette.primary.main,
    },
  },
  '&.fixedHeigth .MuiAccordionDetails-root':{
     maxHeight: "200px",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0px 5px !important",
    minHeight: "auto",
    //maxHeight: "200px",
    overflowY: "scroll",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "5px",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[300],
    },
  },
  "&.themeAccordian": {
    margin: "0px 0px",
    width: "100%",
    borderBottom: `0px solid ${theme.palette.divider} !important`,
    borderRadius: "5px",
    "&:last-child": {
      borderBottom: `0px solid ${theme.palette.divider} !important`,
    },
    "& .MuiAccordionDetails-root": {
      padding: "5px 5px !important",
      "&::-webkit-scrollbar": {
        width: "5px",
        borderRadius: "50px",
      },
      "&::-webkit-scrollbar-track": {
        background: theme.palette.grey[100],
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.grey[300],
      },
    },
    "& .MuiAccordionSummary-root": {
      borderRadius: "10px",
      background: "transparent",
      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: "0px !important",
      },
    },
    "& .MuiAccordionDetails-root": {
      borderTop: "0px solid !important",
    },
  },

  '& .MuiList-root':{
    '.MuiListItemIcon-root':{
      minWidth:'10px',
    },
    '.MuiCheckbox-root':{
      padding:'5px'
    },
    '.MuiInputBase-input':{
        fontWeight:400,

    }
  },
  [theme.breakpoints.down("xl")]: {
    '& .MuiAccordionSummary-root':{
      minHeight:'30px',
        '& .Mui-expanded':{
          margin:'5px'
        },
      '.MuiAccordionSummary-content, MuiAccordionSummary-expandIconWrapper':{
        fontSize:'11px',
        margin:'5px 0px !important',
      },
      'MuiListItemIcon-root':{
          minWidth:"auto",
          '& .MuiCheckbox-root':{
            padding:'5px',
          }
      }
    },
    '& .MuiAccordionDetails-root':{
      padding:"0px 5px !important",
        '& input':{
          fontWeight:400,
          padding:'5px'
        }
    }
  },

}));

// Styled Theme Radio
export const StyledThemeRadio = styled(Radio)(({ theme }) => ({
  "&.MuiRadio-root": {},
  "&.Mui-checked": {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: "1px",
  },
  "& span": {
    opacity: 0,
  },
  "&.imageRadioBtn": {
    width: "60px",
    height: "120px",
    borderRadius: "5px !important",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  "&.themeMode": {
    width: "120px",
    height: "80px",
    borderRadius: "5px !important",
    margin: "0px 5px",
  },
}));

export const StyledThemeBtn = styled(Box)(({ theme }) => ({
  width: "80px",
  height: "50px",
  borderRadius: "5px",
}));

export const StyledColorPickerHolder = styled(Box)(({ theme }) => ({
  "& .circle-picker ": {
    width: "100% !important",
    padding: "15px",
  },
  border: `1px solid ${theme.palette.divider}`,
  margin: "10px 0px",
  borderRadius: "10px",
}));

//Color pikerstyle
export const StyledColorPicker = styled(Box)(({ theme }) => ({
  "& .twitter-picker": {
    boxShadow: "none !important",
    background: `${theme.palette.background.paper} !important`,
  },
}));

export const StyledColorPickerBox = styled(Box)(({ theme }) => ({
  width: "50px",
  height: "30px",
  borderRadius: "5px",
  margin: "10px 0px",
  border: `1px solid ${theme.palette.divider}`,
}));

//Theme Sidebar Image Btn
export const StyledSideBarImageBtn = styled(Radio)(({ theme }) => ({
  width: "60px",
  height: "120px",
  background: "#000",
  borderRadius: "5px",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
}));

// DataTable SearchBar include Filter Icon
export const StyledSearchBarFilter = styled(Stack)(({ theme }) => ({
  display: "flex",
  border: `.8px solid ${theme.palette.divider}`,
  padding: "4px 8px",
  alignItems: "center",
  borderRadius: "5px",
}));

export const SearchTextBox = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    width: "250px",
    padding: "2px 10px !important",
    marginRight: "5px",
    fontWeight: 400,
  },
}));

export const FilterMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 220,
    minHeight: "auto",
    maxHeight: 220,
    overflowY: "hidden",
    boxShadow: `0px 0px 5px ${theme.palette.divider}`,
    borderRadius: "15px",
  },
}));

///Map Styled
export const InfoBoxStyled = styled(Box)(({ theme }) => ({
  padding: "15px 10px",
  background: theme.palette.grey[800],
  color: "#fff",
  width: "350px",
  ".MuiTypography-body1": {
    fontWeight: 400,
    marginBottom: "5px",
  },
}));

//Map Controls
export const ControlsBox = styled(Stack)(({ theme }) => ({
  "&.zoom-control-wrapper": {
    position: "absolute",
    zIndex:9999,
    top: "10px",
    right: "20px",
    zIndex:999,
    [theme.breakpoints.down("xl")]: {
      top: "10px",
    },
    "& .map-btn": {
      width: "35px",
      height: "35px",
      borderRadius: "0px",
      minWidth: "auto",
      fontWeight: 700,
      fontSize: "22px",
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
      boxShadow: "0px 0px 5px rgba(0,0,0,.5)",
      borderRadius: "5px",
      margin: "3px 0px",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
      [theme.breakpoints.down("xl")]: {
        margin: "0px 0px",
        transform: "scale(1)",
        fontSize: "22px !important",
      },
      "&.Mui-disabled": {
        background: theme.palette.grey[300],
      },
    },
  },
}));

export const StyledExportButtons = styled(Button)(({ theme }) => ({}));

export const Exportoptions = ({ onChangeXcl, onChangePdf, commonFilter, exportOpt, disabledExport }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const exportOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();

  const exportAction = (role) => {
      if(role === 'excel'){
        onChangeXcl();
      }else if(role === 'pdf'){
        onChangePdf();
      }
  }

  return (
    <>
      <SoftButton
        className={ disabledExport ? '' : "secondary"}
        startIcon={<HiFolderDownload />}
        id="export-button"
        aria-controls={exportOpen ? "export-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={exportOpen ? "true" : undefined}
        onClick={handleClick}
        disableElevation
        color="secondary"
        size="small"
        disabled={disabledExport}
      >
        Export
      </SoftButton>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={exportOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          "aria-labelledby": "export-button",
        }}
        PaperProps={{
            sx:{
              maxWidth:'150px',
              width:'100%',
            }
        }}
        elevation={0}
      >
        {/* {commonFilter.pdf === true && ( */}
        
        {/* {
          ExportBtns.map((item) => {
              if(exportOpt.includes(item.valaue)){
                  return(
                  <MenuItem onClick={() => exportAction(item.role)} dense={true}>
                  <ListItemIcon color="error">
                    <Icon color={item.color} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {item.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
                )
              }
          })
        } */}
        
        <MenuItem onClick={onChangeXcl} dense={true}>
          <ListItemIcon>
            <RiFileExcel2Fill />
          </ListItemIcon>
          <ListItemText>Excel</ListItemText>
        </MenuItem>


        {/* )} */}
        {/* {commonFilter.excel === true && ( */}

        {/* {
            location.pathname !== '/VehicleData' && location.pathname !==  '/VehicleSummary' && location.pathname !==  '/monthlySummary' && location.pathname !==  '/FaultCode' && location.pathname !==  '/GeoFenceReport' && location.pathname !==  '/stoppage' && location.pathname !==  '/overSpeed' && location.pathname !==  '/ChargingReport' && location.pathname !==  '/evDataReport' && location.pathname !==  '/tripReport' && 
            <MenuItem onClick={onChangePdf}>
          <ListItemIcon>
            <BsFileEarmarkPdfFill />
          </ListItemIcon>
            <ListItemText>PDF</ListItemText>
            </MenuItem> 
          } */}

        {/* )} */}
      </Menu>
    </>
  );
};


export const ExportCsvoptions = ({ onChangeXcl, onChangePdf, commonFilter }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const exportOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  return (
    <>
    <div>
      <SoftButton
        className="primary"
        startIcon={<HiFolderDownload />}
        id="export-button"
        aria-controls={exportOpen ? "export-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={exportOpen ? "true" : undefined}
        onClick={onChangeXcl}
        disableElevation
        color="primary"
        size="small"
      >
        CSV
      </SoftButton>
      </div>
      {/* <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={exportOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "export-button",
        }}
        elevation={0}
      >
        <MenuItem onClick={onChangeXcl} dense={true}>
          <ListItemIcon>
            <RiFileExcel2Fill />
          </ListItemIcon>
          <ListItemText>CSV</ListItemText>
        </MenuItem>
        <MenuItem onClick={onChangePdf}  dense={true}>
          <ListItemIcon>
            <BsFileEarmarkPdfFill />
          </ListItemIcon>
            <ListItemText>PDF</ListItemText>
        </MenuItem> 
      </Menu> */}
    </>
  );
};


export const ExportPdfoptions = ({ onChangeXcl, onChangePdf, commonFilter }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const exportOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  return (
    <>
    <div>
      <SoftButton
        className="secondary"
        startIcon={<HiFolderDownload />}
        id="export-button"
        aria-controls={exportOpen ? "export-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={exportOpen ? "true" : undefined}
        onClick={onChangePdf}
        disableElevation
        color="secondary"
        size="small"
      >
        PDF
      </SoftButton>
      </div>     
    </>
  );
};

//Alert
export const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: "10px",
  padding: "0px 16px",
  background: theme.palette.background.paper,
  borderRadius: "5px",
  fontSize: "14px",
  boxShadow:
    theme.palette.mode === "light"
      ? "0px 0px 1rem rgba(0,0,0,.1)"
      : "0 3px 3rem #0000000b",
  border:
    theme.palette.mode === "light" ? "1.5px solid #fff" : "1px solid #bddcfc21",
}));

//Theme Editor
//StyledMapIcon
export const StyledMapIcon = styled(Box)(({ theme }) => ({
  //background:theme.palette.success.main,
  borderRadius: "50px",
  height: "60px",
  width: "60px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));

//Playback
export const CustomTableBox = styled(Box)(({ theme }) => ({
  "& .stack-table-root": {
    justifyContent: "space-between",
    padding: "5px 15px",
    background: theme.palette.grey[200],
    borderRadius: "5px 5px 0px 0px",
    "& .MuiTypography-root": {
      fontWeight: 400,
      width: "100%",
    },
  },
  "& .stack-table-root-data": {
    justifyContent: "space-between",
    padding: "5px 15px",
    borderRadius: "5px 5px 0px 0px",
    "& .MuiTypography-root": {
      fontWeight: 400,
      width: "100%",
    },
    "&:nth-of-child(odd)": {
      background: theme.palette.grey[100],
    },
  },
}));

//map Statusbox
export const StatusBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "50%",
  right: "0px",
  background: alpha(theme.palette.background.paper, 0.9),
  width: "90px",
  height: "auto",
  transform: "translateY(-50%)",
  borderRadius: "12px 0px 0px 12px",
  backdropFilter: "blur(5px)",
  transition: ".5s",
  "& .stack-vehicle-status-root": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 0px",
    "& .MuiTypography-body2": {
      fontSize: "12px",
    },
    "& .MuiBadge-badge": {
      top: "12px",
      border: `1px solid ${theme.palette.background.paper}`,
    },
  },
  "& .vehicle-status-fab": {
    position: "absolute",
    top: "50%",
    left: "-15px",
    transform: "translate(-3px, -50%)",
    width: "35px",
    height: "30px",
    boxShadow: "none",
    border: `3px solid ${theme.palette.background.paper}`,
  },
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    fontWeight: 500,
    fontSize: "12px",
    top: "5px !important",
    transition: ".3s all",
    "&:hover": {
      fontSize: "12px",
    },
  },
}));

//new Bottom bar
export const StyledBottomBar = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "-180px",
   width: '-moz-available',          /* WebKit-based browsers will ignore this. */
  width: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
  width: 'fill-available',
  overflow: "initial",
  borderRadius: "15px 15px 0 0",
  height: "160px",
  [theme.breakpoints.down("lg")]: {
    height: "160px",
  },

  background: alpha(theme.palette.background.paper, 0.7),
  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
  backdropFilter: "blur(5px)",
  transition: ".5s",
  zIndex:99,
  "& .MuiPaper-root": {
    height: "100%",
    padding: "15px",
    background: "transparent",
  },
  "& .MuiFab-root": {
    background: alpha(theme.palette.secondary.main, 1),
    boxShadow: "none",
    border: `1px solid ${theme.palette.grey[300]}`,
    display: "block",
    position: "absolute",
    top: "-25px",
    left: "50%",
    color: "#fff",
    transition: ".5s",
  },
  
}));

export const VehicleStatusBar = styled(Box)(({ theme }) => ({
  background: "#000",
  position: "fixed",
  width: "fit-content",
  bottom: "25px",
  left:'60%',
  zIndex:9999,
  padding: "0px 12px",
  transform: "translateX(-50%)",
  background: alpha(theme.palette.background.paper, 0.9),
  boxShadow:'0px 5px 12px rgba(0,0,0,.15)',
  backdropFilter: "blur(8px)",
  paddingBottom: "5px",
  borderRadius: "15px",
  transition: ".3s ease-in-out",
  [theme.breakpoints.down("xl")]: {
    bottom: "0px",
    transform: "scale(.7)  translateX(-50%)",
    //transformOrigin: "bottom",
  },
  "& .vehicle-status-root": {
    background: "transparent",
    "& .vehicle-status-box": {
      width: "auto",
      height: "fit-content",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& .MuiTypography-root": {
        fontWeight: 400,
      },
    },
  },
  //MuiTabs-root
  '& .MuiTabs-root':{

     //MuiTabs-indicator
     '& .MuiTabs-indicator':{
        top:'0px',
     },

     '& .MuiTab-root':{
      padding:'7px 0px 0px 0px !important',
      minWidth:'65px',
      margin:'0px 5px',
      textTransform:'capitalize',
      marginTop:'8px',
      fontSize:'12px',
      color:theme.palette.text.primary,
     
      //MuiAvatar-root
      '& .MuiAvatar-root':{
        width:'32px',
        height:'32px'
      }
    }

  },
  //MuiTab-root
 
}));


//Category ListBar
export const CategoryListbar = styled(Box)(({ theme }) => ({
  background: "#000",
  position: "absolute",
  left:'23px',
  width: "390px",
  top: "35%",
  height:"100%",
  maxHeight:'500px',
  transform: "translate(0%, -40%)",
  background: alpha(theme.palette.background.paper, .9),
  overflow:'hidden',
  //backdropFilter: "blur(3px)",
  //paddingBottom: "6px",
  borderRadius: "15px",
  transition: ".3s ease-in-out",
  zIndex:99,
  boxShadow:'0px 0px 5px rgba(0,0,0,.2)',
  [theme.breakpoints.down("xl")]: {
    transform: "scale(.7)  translate(0px, -50%)",
    transformOrigin: "left",
    top: "33%",
  },
}));

//Sub Category ListBar
export const SubCategoryListbar = styled(Box)(({ theme }) => ({
  //background: alpha(theme.palette.background.paper, 0.8),
  background:"#ffff",
  //backdropFilter: "blur(3px)",
  borderRadius: "5px 0px 0px 5px",
  transition: ".3s ease-in-out",
  [theme.breakpoints.down("xl")]: {
    transform: "scale(.8)  translate(0px, -50%)",
    transformOrigin: "left",
  },
  [theme.breakpoints.down("lg")]: {
    transform: "scale(.7)  translate(0px, -50%)",
    transformOrigin: "left",
  },
}));

//Route ListBar
export const RouteListbar = styled(Box)(({ theme }) => ({
  background: "#000",
  position: "fixed",
  right:'0px',
  width: "350px",
  top: "50%",
  padding: "5px",
  transform: "translate(0%, -40%)",
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(8px)",
  paddingBottom: "6px",
  borderRadius: "15px 0px 0px 15px",
  transition: ".3s ease-in-out",
  zIndex:99,
  boxShadow:'0px 0px 5px rgba(0,0,0,.2)',
  [theme.breakpoints.down("xl")]: {
    transform: "scale(.8)  translate(0px, -50%)",
    transformOrigin: "right",
  },
}));

export const MapTopControls = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: '-moz-available',          /* WebKit-based browsers will ignore this. */
  width: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
  width: 'fill-available',
  padding: "15px 25px",
  background: alpha(theme.palette.background.paper, 0),
  pointerEvents: "none",
  zIndex:999,
  //backdropFilter: "blur(6px)",
  //boxShadow: "0px 2px 8px rgba(0,0,0,.1)",
  //borderBottom: `.5px solid ${theme.palette.background.paper}`,
  "& .MuiPaper-root": {
    background: "transparent",
  },
}));


export const StyledTooltip = styled(Tooltip)(({theme}) => ({
  '& .MuiTooltip-popper':{
      background:'red',
  }
}))

export const StyledIconButton = styled(IconButton)(({theme}) => ({
    borderRadius:'5px',
    '&.info-soft':{
        background:alpha(theme.palette.info.main, .18)
    },
    '&.success-soft':{
      background:alpha(theme.palette.success.main, .18)
    },
    '&.error-soft':{
      background:alpha(theme.palette.error.main, .18)
    }
}))

export const StyledCommonListItem = styled(ListItem)(({theme}) => ({
  borderRadius:'10px',
  padding:'5px 0px',
  fontWeight:500,
  '& .MuiListItemIcon-root':{
    minWidth:'auto',
    marginRight:'15px',
    fontSize:'18px',
  },
  '& .MuiButtonBase-root':{
    '&.Mui-selected':{
      color:theme.palette.primary.main
    }
  }
}))


///Heremap Styled
export const StyledHereInfo = styled(Box)(({theme}) => ({

  '& .hover-details':{
    background:theme.palette.background.paper,
    position:'absolute',
    bottom:'-20px',
    left:'50%',
    borderRadius:'5px',
    transform:'translateX(-47%)',
    width:'350px',
    height:'auto',
      '&:before':{
        width:'20px',
        height:'20px',
        background:theme.palette.background.paper,
        position:'absolute',
        bottom:'-10px',
        left:'50%',
        transform:'translateX(-50%) rotate(-45deg)',
        content:"''"
      },
      '& .header':{
        padding:'8px 12px'
      },
      '& .body':{
        padding:'0px 12px'
      }
  }
}))

//Tabs Item


export const StyledTabItem = styled(Tab)(({theme}) => ({
    padding:'10px 15px',
    minHeight:'auto',
    textTransform:'capitalize',
    '&.Mui-selected':{
      color:theme.palette.secondary.main
    }
}))


//TimeLine

export const StyledTimeline = styled(Timeline)(({theme}) => ({
  padding:'0px',
  '& .MuiTimelineItem-root':{
    minHeight:'55px',
    padding:'5px 16px',
    '& .MuiTimelineOppositeContent-root':{
      flex:'0',
      padding:'0px 5px',
      '& .MuiTypography-root':{
        textWrap:'nowrap',
      }
      //padding:'4px 6px'
    },
    '& .MuiTimelineContent-root':{
      padding:'4px 5px',
        '& .material-icons':{
          color:theme.palette.info.contrastText,
          transform:'translate(-30px, 1px)',
          borderRadius:'50px',
          position:'absolute',
          display:'flex',
          alignItems:'center',
          fontSize:'20px',
          padding:'3px'
        }
      }
    },
    '& .MuiTimelineSeparator-root':{
      margin:'5px 10px 0px 10px',
      '& .MuiTimelineDot-root':{
        margin:0,
        boxShadow:'none',
        border:`.5px solid ${theme.palette.primary.contrastText}`,
        padding:'5px',
        background:theme.palette.secondary.main,
    },
    '& .MuiTimelineConnector-root':{
      background:theme.palette.grey[200],
    }
  }
}))
