import React, { useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { StyledPageLayout } from "../../theme/StyledEle";
import { useLocation, useNavigate } from "react-router-dom";
import InstrumentationReport from "./InstrumentationReport";
import FeedSummary from "./FeedSummary";

const Instrumentation = () => {
  const [tabValue, setTabValue] = useState(1);
  const [subName, setSubName] = useState("Instrumentation")
  const navigate = useNavigate();
  const location = useLocation();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if(newValue === 1){
      setSubName("Instrumentation")
    }else{
      setSubName("Feed Summary")
    }
  };


  const TABS = [
    {
      label: "Feed Summary",
      children: <FeedSummary />,
    },
    {
      label: "Instrumentation",
      children: <InstrumentationReport/>,
    },
  ];

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Instrumentation" subDiv={subName} includePopup={true}></Breadcrumb>
      <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} />
    </StyledPageLayout>
  );
};

export default Instrumentation;
