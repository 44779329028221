import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import {
  FormIconStack,
  StyledFilterDropDown,
  StyledFilterFormControl,
  StyledFilterMenuItem,
  StyledFilterSearch,
  StyledFormControlLabel,
} from "../../theme/StyledEle";
import {
  Box,
  CircularProgress,
  InputBase,
  ListSubheader,
  Stack,
  SvgIcon,
  styled,
  useTheme,
} from "@mui/material";
import {
  AlertType,
  Alerts,
  Calender,
  Customer,
  CustomerAssigned,
  Dealer,
  Fleet,
  IMEI,
  LastConnect,
  Org,
  Region,
  Vehicle,
  Status,
  Firmware,
} from "../../constants/DualIcons";
function SingleDropdown(props) {
  const {
    label,
    listItems,
    handleChange,
    value,
    isSearch,
    showAll,
    customAllValue = "-1",
    selectType = "refVal",
    fullwidth=false,
    disabled = false,
    getInnerText,
    isLoading = false
  } = props;
  const menuItemRef = listItems?.content?.map(() => createRef());
  const [searchValue, setSearchValue] = useState(null);
  const [sel, setSel] = useState(null);
  let dropRef = useRef();
  const selectedTarget = (event) => {
    const { value } = event.target;
    handleChange(value, searchValue);
  };

  useEffect(() => {
    var dropVal=dropRef.current.outerText;
    if(getInnerText!==undefined){
      getInnerText(dropVal.replace(/(\r\n|\n|\r)/gm, ""),label);
    }
  },[value])


  //Search text
  const onSearch = (event, name) => {
    const { value } = event.target;
    if (isSearch !== false) {
      isSearch(event.target.value, name);
    }
    setSearchValue(event.target.value);
  };

  //Styled
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      elevation: 0,
      sx: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        overflowX: "hidden",
        padding: "0",
        borderRadius: "15px",
        boxShadow: "0px 2px 8px rgba(0,0,0,0.32)",
        marginTop: "10 px",
        //minWidth:'150px',
        maxWidth: "220px",
        width: "100%",
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          background: theme.palette.grey[50],
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.palette.grey[300],
          borderRadius:'50px',
          cursor:'n-resize'
        },
        pointerEvents:'all'
      },
    },
  };

  const BaseInput = styled(InputBase)(({ theme }) => ({}));
  return (
    <StyledFilterFormControl sx={{maxWidth:fullwidth ? '100%' : 'min-content'}}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{width:'100%'}}>
          <StyledFormControlLabel>{label}</StyledFormControlLabel>
          {
            isLoading && <CircularProgress sx={{width:'15px !important', height:'15px !important'}} color="secondary"/>
          }
        </Stack>
        
        <FormIconStack direction={'row'} spacing={.5} alignItems={'center'}>
        <SvgIcon sx={{fontSize:'20px', color:theme.palette.mode === 'light' ?  theme.palette.secondary.main : theme.palette.grey[300]}}>
          {
            label === "Customer" || label === "User List" || label === "User"  ?  <Customer /> : label === "Vehicle" ? <Vehicle/> : label === 'Fleet' ? <Fleet/> : label === 'Region' ? <Region/> : label === "Dealer" ? <Dealer /> : label === "Severity" ? <Alerts /> : label === "Organization" || label === "Organization Type" || label ===  'User Organization' ? <Org /> : label === "IMEI" || label === "Device Model" ? <IMEI /> : label === "Device status" || label === "Sim status" || label === "Status" || label === "Access"  ? <Status /> : label === "Created Date" ? <Calender /> : label === "Last Connected" ? <LastConnect /> : label === "Alert Type" || label === 'Alert Mode' ? <AlertType/> : label === "Customer Assigned" ? <CustomerAssigned/> : label === "Firmware Version" ? <Firmware /> : ''
          }
        </SvgIcon>  
        <StyledFilterDropDown
          size="small"
          onChange={(event) => selectedTarget(event)}
          value={value}
          MenuProps={{
            ...MenuProps,
            MenuListProps:{
              autoFocus:false,
              autoFocusItem:false
            }
          }}
          className="newdropdown"
          onClose={() => setSearchValue("")}
          disabled={disabled}
          ref={dropRef}
          sx={{
            fontWeight: value === 404 ? 300 : 500,
            color: value === 404 ? theme.palette.text.disabled : theme.palette.text.primary,
          }}
        >
           {isSearch !== false && (
           <ListSubheader sx={{px:0}}>
            <StyledFilterSearch
              size="small"
              onChange={(event) => onSearch(event, label)}
              onKeyDown={(e) => e.stopPropagation()}
              value={searchValue}
              sx={{pointerEvents:'none', '& input':{pointerEvents:'all'} }}
              autoFocus
            />
           </ListSubheader>
            )}
          {showAll && (
            <StyledFilterMenuItem key={0} value={customAllValue}>
              All
            </StyledFilterMenuItem>
          )}
          {listItems !== null &&
            listItems?.content?.length > 0 ?
            listItems?.content.map((item, index) => (
              <StyledFilterMenuItem
                key={index + 1}
                id={item.key}
                value={
                  label === "IMEI" ? 
                  item.refVal !== null 
                  && item.refVal
                    : label === "Sim status"
                    ? item.value
                    : item.key
                }
                ref={menuItemRef[item.key]}
              >
                {label === "IMEI"
                  ? item?.refVal !== null && item.refVal
                  : label === "Sim status"
                  ? item.title
                  : item.value}
              </StyledFilterMenuItem>
            )) : <StyledFilterMenuItem value={404} disabled><em>{isLoading ? 'Please wait...' : `No ${label} Found!`}</em></StyledFilterMenuItem>}
        </StyledFilterDropDown>
      </FormIconStack>
    </StyledFilterFormControl>
  );
}

export default SingleDropdown;
