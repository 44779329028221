import {AccordionDetails, AccordionSummary, Box, CardMedia, Divider, FormLabel, Grid, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SoftButton, StyledAccordion, StyledFormControl, StyledPaperShadow, StyledTextField } from '../../theme/StyledEle'
import { MdOutlineExpandCircleDown } from 'react-icons/md'
import { useGetKycDetailsQuery, useUpdateKycMutation } from '../../store/slice/LogsSlice/LogsSlice';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { snackService } from '../../store/slice/ControlsSlice';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

function KYCEdit(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { ids,reload,payload } = props;
    const [stateId, setStateId] = useState('Approved');
    const [reason, setReason]  =useState(null);
    const [editData, setEditData] = useState(null)
    const handleState = (event, stateId) => {
        if(stateId.props.value === 'Approved'){
            setReason(null);
        }
        setStateId(stateId.props.value)
    }

    const handleReload = () =>{
        navigate(location.pathname);
        reload(payload)
    }

    const {data:getKycDetails, isLoading:getKycdetailsLoading, isSuccess:getKycdetailsSuccess} = useGetKycDetailsQuery(ids);
    const [ kycUpdate, {data:updateData, isLoading, isSuccess} ] = useUpdateKycMutation();
    const onUpdate = async() => {
        let Payload = {
            id : editData?.formData?.id,
            vin: editData?.formData?.formVin,
            formCustomerName:editData?.formData?.formCustomerName,
            formCustomerEmail:editData?.formData?.formCustomerEmail,
            formCustomerMobile1:editData?.formData?.formCustomerMobile1,
            customerId:'',
            gstInNew:editData?.formData?.gstInNew,
            state:stateId,
            
        }
        const responseData = await kycUpdate(Payload);
        dispatch(snackService([responseData.data.resultMessage, responseData.data.resultCode, true]));
        if(responseData.data.resultCode === 0){
          setTimeout(() =>handleReload(), 2000);
        }
    }


    useEffect(() => {
        if(getKycdetailsSuccess){
            setEditData(getKycDetails);
        }
    },[getKycdetailsSuccess])

  return (
    <Box>
        <StyledPaperShadow>
                <Stack direction={'row'} alignItems={'end'} spacing={2}>
                    <StyledFormControl>
                        <Typography gutterBottom>Current Status</Typography>
                        <Select size='small' value={stateId} onChange={handleState}>
                            <MenuItem value={'Approved'}>Approve</MenuItem>
                            <MenuItem value={'Rejected'}>Rejected</MenuItem>
                        </Select>
                    </StyledFormControl>
                    {
                        stateId === 'Rejected' &&
                        <StyledFormControl>
                        <Typography gutterBottom>Reason</Typography>
                        <StyledTextField placeholder='Enter Reason' value={reason} onChange={(e) => setReason(e.target.value)} size='small'/>
                        </StyledFormControl>
                    }
                   
                    <Stack>
                        <SoftButton variant='contained' disabled={editData?.formData?.state === 'Pending' ? false : true} className='success' onClick={onUpdate}  size="small" type="submit" sx={{cursor:isLoading ? 'wait':'pointer'}}>
                            {
                                isLoading ? <ScaleLoader color="#fff"  height={20}/> : 'Update'
                            }
                        </SoftButton>
                    </Stack>
                </Stack>
        </StyledPaperShadow>

        <StyledPaperShadow sx={{mt:2, overflow:'hidden'}}>
            <Grid container spacing={2} sx={{ maxHeight:'calc(100vh - 45vh)', overflowY:'scroll', height:'35%', minHeight:'calc(100vh - 50vh)'}}>
                <Grid item lg={8}>
                <StyledAccordion disableGutters elevation={0} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdOutlineExpandCircleDown />}
                        >
                            Vehicle Details
                        </AccordionSummary>
                        <AccordionDetails>
                        <Box>
                        <Grid container rowSpacing={2}>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>VIN Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formVin !== null ? editData?.formData?.formVin : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Engine Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formEngineNumber !== null ? editData?.formData?.formEngineNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Year Of MFG</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formYearOfMG !== null ? editData?.formData?.formYearOfMG : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Vehicle Make</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formVehicleMake !== null ? editData?.formData?.formVehicleMake : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Vehicle Model</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formVehicleModel !== null ? editData?.formData?.formVehicleModel : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                        </Grid>
                    </Box>
                        </AccordionDetails>
                    </StyledAccordion>
                    
                    <StyledAccordion disableGutters elevation={0} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdOutlineExpandCircleDown />}
                        >
                            Customer Details
                        </AccordionSummary>
                        <AccordionDetails>
                        <Box>
                        <Grid container rowSpacing={2}>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Name</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formCustomerName !== null ? editData?.formData?.formCustomerName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Email</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formCustomerEmail !== null ? editData?.formData?.formCustomerEmail : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Mobile</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formCustomerMobile1 !== null ? editData?.formData?.formCustomerMobile1 : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Retail Invoice Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formRetailInvoiceNumber !== null ? editData?.formData?.formRetailInvoiceNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Aadhaar Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.aadharNumber !== null ? editData?.formData?.aadharNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>License Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.licenseNumber !== null ? editData?.formData?.licenseNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Address</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formCustomerAddress !== null ? editData?.formData?.formCustomerAddress : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            {/* <Grid item lg={3}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>KYC Proof 1</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{getkycdetails?.formData?.formVehicleModel !== null ? getkycdetails?.formData?.formVehicleModel : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={3}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>KYC Proof 2 (Optional):</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{getkycdetails?.formData?.formVehicleModel !== null ? getkycdetails?.formData?.formVehicleModel : '-'}</Typography>
                                </StyledFormControl>
                            </Grid> */}
                        </Grid>
                    </Box>
                        </AccordionDetails>
                    </StyledAccordion>

                    <StyledAccordion disableGutters elevation={0} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdOutlineExpandCircleDown />}
                        >
                            Dealer Details
                        </AccordionSummary>
                        <AccordionDetails>
                        <Box>
                        <Grid container rowSpacing={2}>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Code</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formDealerCode !== null ? editData?.formData?.formDealerCode : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>GST IN</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.gstInNew !== null ? editData?.formData?.gstInNew : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Name</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formDealerName !== null ? editData?.formData?.formDealerName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Address</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formCustomerAddress !== null ? editData?.formData?.formCustomerAddress : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Town</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formDealerTown !== null ? editData?.formData?.formDealerTown : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Contact Name</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formCustomerAddress !== null ? editData?.formData?.formCustomerAddress : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Mobile</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formDealerContactNumber !== null ? editData?.formData?.formDealerContactNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Email</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.formDealerContactName !== null ? editData?.formData?.formDealerContactName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                        </Grid>
                    </Box>
                        </AccordionDetails>
                    </StyledAccordion>
                </Grid>
                <Grid item lg={4}>
                    <Stack sx={{display:'grid', gridTemplateColumns:'auto auto'}}>
                        <Box>
                            <FormLabel>Profile Image</FormLabel>
                            <CardMedia component={'img'} src={editData?.formData?.profileImg !== '' ? editData?.formData?.profileImg : 'https://cdn-icons-png.flaticon.com/512/8191/8191581.png' } sx={{width:'80%'}}/>
                        </Box>
                        <Box>
                            <FormLabel>License Front</FormLabel>
                            <CardMedia component={'img'} src={editData?.formData?.licenseFront !== '' ? editData?.formData?.licenseFront : 'https://cdn-icons-png.flaticon.com/512/8191/8191581.png'} sx={{width:'80%'}}/>
                        </Box>
                        <Box>
                            <FormLabel>License Back</FormLabel>
                            <CardMedia component={'img'} src={editData?.formData?.licenseBack !== '' ? editData?.formData?.licenseBack : 'https://cdn-icons-png.flaticon.com/512/8191/8191581.png'} sx={{width:'80%'}}/>
                        </Box>
                        <Box>
                            <FormLabel>Aadhar Front</FormLabel>
                            <CardMedia component={'img'} src={editData?.formData?.formProofOfAddress !== '' ? editData?.formData?.formProofOfAddress : 'https://cdn-icons-png.flaticon.com/512/8191/8191581.png'} sx={{width:'80%'}}/>
                        </Box>
                        <Box>
                            <FormLabel>Aadhar Back</FormLabel>
                            <CardMedia component={'img'} src={editData?.formData?.formProofOfIdentity !== '' ? editData?.formData?.formProofOfIdentity : 'https://cdn-icons-png.flaticon.com/512/8191/8191581.png'} sx={{width:'80%'}}/>
                        </Box>
                        <Box>
                            <FormLabel>Invoice Image</FormLabel>
                            <CardMedia component={'img'} src={editData?.formData?.invoiceImg !== '' ? editData?.formData?.invoiceImg : 'https://cdn-icons-png.flaticon.com/512/8191/8191581.png'} sx={{width:'80%'}}/>
                        </Box>

                    </Stack>
                </Grid>
            </Grid>
        </StyledPaperShadow>
    </Box>
  )
}

export default KYCEdit