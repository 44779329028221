import { Box, Fab, Grid, Icon, Skeleton, Stack, Typography, alpha, useTheme } from '@mui/material';
import { InfoWindow, Marker } from '@react-google-maps/api'
import React, { createRef, useEffect, useState } from 'react'
import { FaPlugCircleBolt, FaSquareParking } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { StyledInfoWindow } from '../Treeview/TreeviewStyledEle';
import reportServices from '../../../services/reportServices';
import { Charging, Stoppage, ChargingReg } from '../../Constans';
import { AiFillClockCircle, AiFillThunderbolt } from "react-icons/ai";

export const ChargingMarker = ({data, type}) => {
    //const chargingMarker = useSelector((state) => state.controls.otherMarkers.charging);
    let theme = useTheme();
    //const markerRef = data !== null && data?.vehicles.map(() => createRef());
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [open, setOpen] = useState(false);
    const markerRef = data !== null && data?.map(() => createRef());
    const [selectMarker, setSelectMarker] = useState(null);
    //Infowindow Close Event
    const onClose = () => {
        setOpen(false);
        setSelectMarker(null);
    }

    const getMarkerData = (data) => {
        setOpen(!open);
        setSelectMarker(data);
        //getAddress(data);
    }
      let [address, setAddress] = useState(null);
    
      const getAddress = (data) => {
        var requestN = {};
        requestN.latLng = type === 'charge' ? selectMarker?.location : selectMarker?.gpsLocation;
        return reportServices.getGeocodeAddress(requestN)
          .then((resN) => {
            setAddress(resN.data);
        })
        .catch((err1) => {
        console.log(err1);
        });
      }


      useEffect(() => {
        if(selectMarker !== null){
            getAddress();
        }
      },[selectMarker])
      



    return (
        <>
        {data !== null && data?.map((item, index) => {
            return (
                <>
                <Marker
                    id={item.vehicleno}
                    position={{
                        lat:parseFloat(type === 'charge' ? item?.location.split(',')[0] : item?.gpsLocation.split(',')[0]), 
                        lng:parseFloat(type === 'charge' ? item?.location.split(',')[1] : item?.gpsLocation.split(',')[1])
                    }}
                    ref={markerRef[index]}
                    icon={type === 'charge' ? item?.chargingType !== 'Manual' ? ChargingReg : Charging : Stoppage}
                    clickable
                    onClick={() => getMarkerData(item)}
                    options={{
                        zIndex:99999
                    }}
                >
                </Marker>
                   
                </>
            )
        })
        }
        {
            open && 
            <InfoWindow
            position={{
                lat:parseFloat(type === 'charge' ? selectMarker?.location.split(',')[0] : selectMarker?.gpsLocation.split(',')[0]),
                lng:parseFloat(type === 'charge' ? selectMarker?.location.split(',')[1] : selectMarker?.gpsLocation.split(',')[1])
            }}
            onCloseClick={onClose}
            options={{pixelOffset: new window.google.maps.Size(0,-80)}}
            //ref={infoWindowRef}
        >
                <StyledInfoWindow>
                <Box 
                className='infowindowHHeader'
                sx={{ background: type === 'charge' ? alpha(theme.palette.warning.main, .9) : alpha(theme.palette.error.main, .9) ,width:'100%'}}
                > 
                <Stack spacing={2} direction={'row'} alignItems={'baseline'}>
                <Box>
                <Typography variant='caption'>Vehicle No </Typography>
                <Typography variant='subtitle2'>{selectMarker?.vehicleno}</Typography>
                </Box>
                <Fab disableTouchRipple size='small' 
                    sx={{background:type === 'charge' ? alpha(theme.palette.warning.main, .9) : alpha(theme.palette.error.main, .9)}} 
                >
                <Icon>
                    {
                        type === 'charge' ? selectMarker?.chargingType !== 'Manual' ? <AiFillThunderbolt /> : <FaPlugCircleBolt />  : <AiFillClockCircle /> 
                    }
                </Icon>
                </Fab>
                </Stack>
                </Box>
                <Box sx={{padding:'5px 15px', width:'100%'}}>
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{width:'100%'}}>
                <Box sx={{width:'100%'}}> 
                <Stack direction={'column'} spacing={.5}>
                    <Stack direction={'row'} spacing={2} >
                        <Box>
                            <Typography variant='caption'>Start Time </Typography>
                            <Typography variant='subtitle2'>{selectMarker?.startTime}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='caption'>End Time </Typography>
                            <Typography variant='subtitle2'>{selectMarker?.endTime}</Typography>
                        </Box>
                    </Stack>
                <Box>
                {
                    type === 'charge' ? 
                    <Grid container spacing={1} sx={{maxHeight:'100px', overflowY:'auto'}}>
                    <Grid item lg={4}>
                        <Box>
                            <Typography variant='caption'>Charing Type</Typography>
                            <Typography variant='subtitle2'>{type === 'charge' ? selectMarker?.chargingType : selectMarker?.stopminsinhrs }</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box>
                            <Typography variant='caption'>Total Charging Mins</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.chargingmins}</Typography>
                        </Box>
                    </Grid>
                    {/* <Grid item lg={4}>
                        <Box>
                            <Typography variant='caption'>Cost utility</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.costUtility}</Typography>
                        </Box>
                    </Grid> */}
                    <Grid item lg={4}>
                        <Box>
                            <Typography variant='caption'>Start SOC</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.sstartsoc}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box>
                            <Typography variant='caption'>End SOC</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.sendsoc}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box>
                            <Typography variant='caption'>Gain SOC</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.gainSoc}</Typography>
                        </Box>
                    </Grid>
                    </Grid> :

                    <Grid container spacing={1} sx={{maxHeight:'100px', overflowY:'auto'}}>
                        <Grid item lg={6}>
                            <Box>
                            <Typography variant='caption'>Stoppage (hh:mm)</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.stopminsinhrs}</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={6}>
                            <Box>
                            <Typography variant='caption'>Zone Name</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.zoneName}</Typography>
                            </Box>
                        </Grid>
                        {/* <Grid item lg={4}>
                            <Box>
                            <Typography variant='caption'>Start SOC</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.startsoc}</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4}>
                            <Box>
                            <Typography variant='caption'>End SOC</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.endsoc}</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4}>
                            <Box>
                            <Typography variant='caption'>SOC Consumed</Typography>
                            <Typography variant='subtitle2'>{selectMarker?.socConsumed}</Typography>
                            </Box>
                        </Grid> */}
                    </Grid>
                }
               

                </Box>
                <Box>
                <Typography variant='caption'>Address</Typography>
                {
                address !== null ? 
                <Typography variant='subtitle2'> {address} </Typography> :
                <Skeleton variant='subtitle2'/>
                }
                </Box>
                </Stack>
                </Box>
                </Stack>
                </Box>
                </StyledInfoWindow>
        </InfoWindow>
        }
        
        </>
    )
}