import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MouseOverPopover from "../shared/PopOver";
import exporticon from "../../assets/images/exportexcel.png";
import pdficon from "../../assets/images/pdficon.png";

const text = "";

const AvlDataReport = () => {
  const [responseData, setResponseData] = useState([]);
  const [open, setOpen] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    await fetchAVLDataDetails();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      {
        Header: "Date",
        accessor: "startDate",
      },
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "GPS Location",
        accessor: "gpsLocation",
      },
      {
        Header: "Angle",
        accessor: "lac",
      },
      {
        Header: "Total Odometer(16)",
        accessor: "odometer",
      },
      {
        Header: "Fuel Used GPS(12)",
        accessor: "stateOfCharge",
      },
      {
        Header: "Ignition(239)",
        accessor: "ignition",
      },
      {
        Header: "Speed(24)",
        accessor: "speed",
      },
      {
        Header: "GSM Signal(21)",
        accessor: "gsmSignal",
      },
      {
        Header: "External Voltage(66)",
        accessor: "chargeStatus",
      },
      {
        Header: "Battery Voltage(67)",
        accessor: "intBatVoltPercentage",
      },
      {
        Header: "Battery Current(68)",
        accessor: "ac",
      },
      {
        Header: "GSM Cell ID(205)",
        accessor: "cellId",
      },
      {
        Header: "GSM Area Code(206)",
        accessor: "mobileCountryCode",
      },
      {
        Header: "Digital Input1 (1)",
        accessor: "digitalInput1",
      },
      {
        Header: "Digital Input2 (2)",
        accessor: "digitalInput2",
      },
      {
        Header: "Digital Input3 (3)",
        accessor: "digitalInput3",
      },
      {
        Header: "SD Status(10)",
        accessor: "sdStatus",
      },
      {
        Header: "GNSS Status(69)",
        accessor: "gnssStatus",
      },
      {
        Header: "Data Mode(80)",
        accessor: "dataMode",
      },
      {
        Header: "Battery Level(113)",
        accessor: "batteryLevel",
      },
      {
        Header: "Charger Connected(116)",
        accessor: "chargerConnected",
      },
      {
        Header: "Digital Output1(179)",
        accessor: "digitalOutput1",
      },
      {
        Header: "Digital Output2(180)",
        accessor: "digitalOutput2",
      },
      {
        Header: "Sleep Mode(200)",
        accessor: "sleepMode",
      },
      {
        Header: "Movement(240)",
        accessor: "movement",
      },
      {
        Header: "BT Status(262)",
        accessor: "btStatus",
      },
      {
        Header: "Digital Input4(263)",
        accessor: "digitalInput4",
      },
      {
        Header: "Instant Movement(303)",
        accessor: "instMovement",
      },
      {
        Header: "GND Sense(381)",
        accessor: "gndSense",
      },

      {
        Header: "DOUT OverCurrent 1(842)",
        accessor: "dout1OverCurrent",
      },
      {
        Header: "DOUT OverCurrent 2(843)",
        accessor: "dout2OverCurrent",
      },
      {
        Header: "BLE Lock Status(871)",
        accessor: "bleLockStatus",
      },
      {
        Header: "BLE Lock Batter(872)",
        accessor: "bleLockBatter",
      },
      {
        Header: "Analog Input 2(6)",
        accessor: "analogInput2",
      },
      {
        Header: "Analog Input 1(9)",
        accessor: "analogInput1",
      },
      {
        Header: "Fuel Rate GPS(13)",
        accessor: "fuelRateGps",
      },
      {
        Header: "Eco Score(15)",
        accessor: "ecoScore",
      },
      {
        Header: "Axis X(17)",
        accessor: "axisX",
      },
      {
        Header: "Axis Y(18)",
        accessor: "axisY",
      },
      {
        Header: "Axis Z(19)",
        accessor: "axisZ",
      },
      {
        Header: "X Accelerometer(in G Force)",
        accessor: "xaccelerometer",
      },
      {
        Header: "Y Accelerometer(in G Force)",
        accessor: "yaccelerometer",
      },
      {
        Header: "Z Accelerometer(in G Force)",
        accessor: "zaccelerometer",
      },
      {
        Header: "GNSS PDOP(181)",
        accessor: "gnssPdop",
      },
      {
        Header: "GNSS HDOP(182)",
        accessor: "gnssHdop",
      },
      {
        Header: "Dallas Temperature 1(72)",
        accessor: "dallasTemp1",
      },
      {
        Header: "Dallas Temperature 2(73)",
        accessor: "dallasTemp2",
      },
      {
        Header: "Dallas Temperature 3(74)",
        accessor: "dallasTemp3",
      },
      {
        Header: "Dallas Temperature 4(75)",
        accessor: "dallasTemp4",
      },

      {
        Header: "Trip Odometer(199)",
        accessor: "tripStatus",
      },
      {
        Header: "Active GSM Operator(241)",
        accessor: "activeGsmOprtr",
      },
      {
        Header: "Extended External Voltage(800)",
        accessor: "externalBatteryVolt",
      },
      {
        Header: "Extended Analog Input 1(839)",
        accessor: "extAnalogInput1",
      },
      {
        Header: "Extended Analog Input 1(840)",
        accessor: "extAnalogInput2",
      },
      {
        Header: "User ID(854)",
        accessor: "userId",
      },
      {
        Header: "ICCID(11, 14)",
        accessor: "iccd",
      },
      {
        Header: "Dallas Temperature ID 4(71)",
        accessor: "dallasTempId4",
      },

      {
        Header: "Dallas Temperature ID 1(76)",
        accessor: "dallasTempId1",
      },
      {
        Header: "Dallas Temperature ID 2(77)",
        accessor: "dallasTempId2",
      },
      {
        Header: "IButton(78)",
        accessor: "ibutton",
      },
      {
        Header: "Dallas Temperature ID 3(79)",
        accessor: "dallasTempId3",
      },
      {
        Header: "RFID(207)",
        accessor: "rfid",
      },
      {
        Header: "ISO6709 Coordinates(387)",
        accessor: "iso6709",
      },
      {
        Header: "RMU Battery Module(900)",
        accessor: "avl900",
      },
      {
        Header: "Cell Voltage Group 1(901)",
        accessor: "avl901",
      },
      {
        Header: "Cell Voltage Group 2(902)",
        accessor: "avl902",
      },
      {
        Header: "Cell Voltage Group 3(903)",
        accessor: "avl903",
      },
      {
        Header: "Cell Voltage Group 4(904)",
        accessor: "avl904",
      },
      {
        Header: "Cell Voltage Group 5(905)",
        accessor: "avl905",
      },
      {
        Header: "Cell Voltage Group 6(906)",
        accessor: "avl906",
      },
      {
        Header: "Cell Voltage Group 7(907)",
        accessor: "avl907",
      },
      {
        Header: "Max and Min Temperature(908)",
        accessor: "avl908",
      },
      {
        Header: "Temperature Sensor 1(909)",
        accessor: "avl909",
      },
      {
        Header: "Temperature Sensor 2(910)",
        accessor: "avl910",
      },
      {
        Header: "Temperature Sensor 3(911)",
        accessor: "avl911",
      },
      {
        Header: "Cell Balancing(912)",
        accessor: "avl912",
      },
      {
        Header: "BLE Temperature 1(25)",
        accessor: "value0",
      },
      {
        Header: "BLE Temperature 2(26)",
        accessor: "value1",
      },
      {
        Header: "BLE Temperature 3(27)",
        accessor: "value2",
      },
      {
        Header: "BLE Temperature 4(28)",
        accessor: "value3",
      },
      {
        Header: "Sensor ID(331)",
        accessor: "sensorId1",
      },
      {
        Header: "Tyre Pressure(331)",
        accessor: "tyrePressure1",
      },
      {
        Header: " Temperature (331)",
        accessor: "temperature1",
      },
      {
        Header: "Battery Voltage(331)",
        accessor: "batteryVoltage1",
      },
      {
        Header: "Sensor ID(332)",
        accessor: "sensorId2",
      },
      {
        Header: "Tyre Pressure(332)",
        accessor: "tyrePressure2",
      },
      {
        Header: " Temperature (332)",
        accessor: "temperature2",
      },
      {
        Header: "Battery Voltage(332)",
        accessor: "batteryVoltage2",
      },
      {
        Header: "Sensor ID(333)",
        accessor: "sensorId3",
      },
      {
        Header: "Tyre Pressure(333)",
        accessor: "tyrePressure3",
      },
      {
        Header: " Temperature (333)",
        accessor: "temperature3",
      },
      {
        Header: "Battery Voltage(333)",
        accessor: "batteryVoltage3",
      },
      {
        Header: "Sensor ID(334)",
        accessor: "sensorId4",
      },
      {
        Header: "Tyre Pressure(334)",
        accessor: "tyrePressure4",
      },
      {
        Header: " Temperature (334)",
        accessor: "temperature4",
      },
      {
        Header: "Battery Voltage(334)",
        accessor: "batteryVoltage4",
      },
      {
        Header: "DC Bus Voltage",
        accessor: "dcBusVolt",
      },
      {
        Header: "DC Bus Current",
        accessor: "dcBusCurrent",
      },
      {
        Header: "THROTLLE_PERC",
        accessor: "throttlePercent",
      },
      {
        Header: "Motor Error(in Volts)",
        accessor: "error",
      },
      {
        Header: "Brake_Status(4-3)",
        accessor: "brakeStatus",
      },
      {
        Header: "Speed Mode BIT 1-2",
        accessor: "speedMode",
      },
      {
        Header: "GEAR POSTION",
        accessor: "gearPosition",
      },
      {
        Header: "MOTOR RPM",
        accessor: "motorRPM",
      },
      {
        Header: "MOTOR Temperature",
        accessor: "motorTemp",
      },
      {
        Header: "CONTROLLER Temperature",
        accessor: "controllerTemp",
      },
      {
        Header: "KMPH",
        accessor: "kmph",
      },
    ],
    []
  );
  const fetchAVLDataDetails = async () => {
    var request = {};
    request.orgId = 1;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-10-05 00:00:00";
    request.endDate = "2022-10-05 23:59:59";
    request.sortBy = "desc";
    request.page = newPage;
    request.size = rowsPerPage;

    const responseData = (await ReportServices.AVLDataReport(request)).data;
    setResponseData(responseData.data.content);
  };

  const onChangeXcl = async () => {
    var request = {};
    request.orgId = 103334;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-10-02 00:00:00";
    request.endDate = "2022-10-02 23:59:59";
    request.sortBy = "desc";
    request.page = 0;
    request.size = 0;

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "AVLDataReport/avlDataXLS.xls?orgId=" +
        request.orgId +
        "&regionId=" +
        request.regionId +
        "&reselId=" +
        request.reselId +
        "&customerId=" +
        request.customerId +
        "&vehId=" +
        request.vehId +
        "&startDate=" +
        request.startDate +
        "&endDate=" +
        request.endDate +
        "&sortBy=" +
        request.sortBy +
        "&page=" +
        request.page +
        "&size=" +
        request.size;
      window.open(url);
    } catch (error) {}
  };
  const onChangePdf = async () => {
    var request = {};
    request.orgId = 103334;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-10-02 00:00:00";
    request.endDate = "2022-10-02 23:59:59";
    request.sortBy = "desc";
    request.page = 0;
    request.size = 0;

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "AVLDataReport/avlDataPDF.pdf?orgId=" +
        request.orgId +
        "&regionId=" +
        request.regionId +
        "&reselId=" +
        request.reselId +
        "&customerId=" +
        request.customerId +
        "&vehId=" +
        request.vehId +
        "&startDate=" +
        request.startDate +
        "&endDate=" +
        request.endDate +
        "&sortBy=" +
        request.sortBy +
        "&page=" +
        request.page +
        "&size=" +
        request.size;
      window.open(url);
    } catch (error) {}
  };

  const callbackChangePage = () => {};

  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb mainDiv="Reports" subDiv="AVL Data Report" includePopup={true}></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>
          <MouseOverPopover text={text} title={"AVL Data Report"} />
          <Typography sx={{ fontSize: "12px" }}>
            <b>Note</b>:EV Data Report shows data about the machine for each
            minute for a selected time period.
          </Typography>

          <Divider sx={{ borderBottomWidth: 2, mt: 1 }} />
          <Paper sx={{ backgroundColor: "#fff", marginTop: 2 }}>
            <ReportDropDown />
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button>
          </Paper>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
            }}
          >
            <div>
              <img
                alt="excel"
                style={{
                  marginLeft: 950,
                  width: 25,
                  marginTop: 10,
                  marginBottom: 20,
                }}
                onClick={onChangeXcl}
                title="Export Excel"
                src={exporticon}
              />
              <img
                alt="pdf"
                style={{
                  width: 25,
                  marginLeft: 10,
                  marginBottom: 20,
                  marginTop: 10,
                }}
                onClick={onChangePdf}
                title="Download PDF"
                src={pdficon}
              />
            </div>
            <DataTable
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={callbackChangePage}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AvlDataReport;
