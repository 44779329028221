import { Box, CardMedia, Typography, Icon } from '@mui/material';
import React, { useEffect, useState } from 'react'
import  offline  from '../assets/images/ofline.svg'
import { GiWifiRouter } from 'react-icons/gi'
export const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(true);

    const updateNetworkStatus = () => {
        setIsOnline(navigator.onLine);
    }

    useEffect(() => {
        window.addEventListener('load', updateNetworkStatus);
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        return () => {
            window.removeEventListener('load', updateNetworkStatus);
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        }
    }, [navigator.onLine])

    return {isOnline}
}

export default function NetworkStatus() {
  return (
    <Box sx={{width:'100%', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Box sx={{textAlign:'center', display:'flex', justifyContent:'center',flexDirection:'column', alignItems:'center'}}>
            <Icon sx={{fontSize:'100px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <GiWifiRouter />
            </Icon>
            <Typography variant='h4' sx={{textAlign:'center', fontWeight:700}} gutterBottom>No Internet</Typography>
            <Typography variant='h6' sx={{textAlign:'center', color:'#636363'}} >Check the network connection!</Typography>
        </Box>
    </Box>
  )
}

