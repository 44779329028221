import React, { useState, useEffect, useMemo, useRef, createRef } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import logServices from "../../services/logService";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import {
  useAuditLogMutation,
  useStopPageMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";



const Stoppage = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const text =
  `This report shows various instances of when the vehicle was in stoppage beyond ${resellerId===110224?10:30} minutes between the first trip and last trip on a given day.`;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > Stoppage";
    auditPayload.action = "View";
    auditPayload.featureName = "Stoppage";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  // const showAddress = async (e) => {

  //   e.preventDefault();

  //   var request = {};
  //   request.latLng = e.target.value;
  //   rowid = e.target.id;
  //   if (isshowAddress === true) {
  //     const responseData = (await ReportServices.getGeocodeAddress(request))
  //       .data;
  //     address = responseData;
  //     // setAddress(responseData);
  //   }

  //   if (!isshowAddress) {
  //     isshowAddress = true;
  //     setLatStatus("LatLong")
  //   } else {
  //     isshowAddress = false;
  //     setLatStatus("Address")

  //   }
  //   setIsshowAddress(isshowAddress);
  // };

  const columns = React.useMemo(() => {
    const commonColumns = [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:120,
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        width:120,
      },
      {
        Header: "Zone Category",
        accessor: "zoneCategory",
      },
      {
        Header: "GPS Location (Click to view location in map)",
        accessor: "gpsLocation",
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.gpsLocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.gpsLocation}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.gpsLocation}
                >
                  {row.original.gpsLocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.gpsLocation}
                  key={row.original.gpsLocation}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        width:120,
      },
      {
        Header: "End Time",
        accessor: "endTime",
        width:120,
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "stopminsinhrs",
        width:120,
      },

     
    ];
  
    if (resellerId === 110224) {
      const additionalColumns = [
        {
          Header: "SOC Difference (%)",
          accessor: "socConsumed",
          width:120,
        },
        {
          Header: "Engery Consumed (kWh)",
          accessor: "energyConsumedMontra",
          width:120,
        }
      ];
      commonColumns.splice(2,0,       
        {
          Header: "GeoZone",
          accessor: "zoneName",
          width:120,
        }
      );
      return [...commonColumns, ...additionalColumns];
    }
  
    return commonColumns;
  }, [resellerId]);

  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
 
    arr.push("customerName");
    arr.push("zoneCategory");
    initialState1.hiddenColumns = arr;
  }
  // var loadrequest = {};
  // const setloadvalues = () => {
  //   switch (globalOrgType) {
  //     case 8: // login as superadmin and reseller
  //     case 2:

  //     loadrequest.regionId = globalResellerId===113802? 1:-1;
  //     loadrequest.resellerId = globalOrgId;
  //     loadrequest.dealerId = globalResellerId===113802? 113806:-1;
  //     loadrequest.customerId = -1;
  //     loadrequest.orgId = -1; // fleet
  //     loadrequest.vehicleId = -1;
  //       break;
  //     // case 2: // login as reseller
  //     //     loadrequest.regionId= -1;
  //     //     loadrequest.reselId = globalOrgId;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.customerId = -1;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.orgId = -1; // fleet
  //     //     loadrequest.vehicleId = -1;
  //     //      break;

  //     case 5: // login as Dealer
  //       loadrequest.regionId = 0;
  //       loadrequest.dealerId = globalOrgId;
  //       loadrequest.customerId = -1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 3: // login as customer
  //       loadrequest.regionId = 0;
  //       loadrequest.customerId = globalOrgId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 1: // login as fleet
  //       loadrequest.regionId = 0;
  //       loadrequest.customerId = globalCustomerId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = globalOrgId; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //   }
  // };

  // const fetchStoppageDetails = async () => {
  //   setloadvalues();

  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   const fromDate =
  //     format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";

  //   loadrequest.search = "";
  //   loadrequest.startDate = fromDate;
  //   loadrequest.endDate = toDate;

  //   loadrequest.sortBy = "desc";

  //   setAssignedval(loadrequest);

  //   const responseData = (await ReportServices.stoppageReport(loadrequest))
  //     .data;

  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };
  // const handleSearch = async (searchText) => {
  //   setSearchval(searchText);
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.stoppageReport(loadrequest))
  //     .data;

  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  // };
  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "StoppageReport/stoppageReportXLS.xls?regionId=" +
  //       loadrequest.regionId +
  //       "&resellerId=" +
  //       loadrequest.resellerId +
  //       "&size=" +
  //       loadrequest.size +
  //       "&page=" +
  //       loadrequest.page +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&sortBy=" +
  //       loadrequest.sortBy +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&customerId=" +
  //       loadrequest.customerId;

  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > XLS";
  //     request.action = "View";
  //     request.featureName = "Stoppage";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);

  //   } catch (error) {}
  // };
  // var loadrequest = {};

  // const handleSubmit = async (data) => {

  //   loadrequest = assignedval;
  //   loadrequest.regionId = data.region;
  //   loadrequest.resellerId = data.reseller;
  //   // loadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.customerId = data.customer;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   if (data.from_date !== "" && data.to_date !== "") {
  //     loadrequest.startDate = data.from_date;
  //     loadrequest.endDate = data.to_date;
  //   }

  //   loadrequest.sortBy = "desc";
  //   loadrequest.search = searchval;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.stoppageReport(loadrequest))
  //     .data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };
  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   // var request = {};
  //   // request.orgId = 1;
  //   // request.vehicleId = 25909;
  //   loadrequest = assignedval;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.stoppageReport(loadrequest))
  //     .data;

  //   // setTotalElements(5);
  //   // setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  // };

  // const onChangepdf = async () => {
  //   loadrequest = assignedval;
  //   // var request = {};
  //   // request.orgId = 1;
  //   // request.vehicleId = 25909;
  //   loadrequest.size = 1;
  //   loadrequest.page = 25;
  //   // request.startDate = "2022-12-19 00:00:00";
  //   // request.endDate = "2022-12-19 23:59:59";
  //   // request.sortBy = "desc";
  //   // request.threshold = 5;
  //   // request.startOdometer ="String";
  //   // request.endOdometer = "String";

  //   setAssignedval(loadrequest);

  //   try {
  //     // var url=process.env.REACT_APP_API_BASE_URL+"StoppageReport/stoppageReportPDF.pdf?orgId="+request.orgId+"&vehicleId="+request.vehicleId+"&size="+request.size+"&page="+request.page+"&startDate="+request.startDate+"&endDate="+request.endDate+"&sortBy="+request.sortBy+"&threshold="+request.threshold+"&startOdometer="+request.startOdometer+"&endOdometer="+request.endOdometer;

  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "StoppageReport/stoppageReportPDF.pdf?regionId=" +
  //       loadrequest.regionId +
  //       "&resellerId=" +
  //       loadrequest.resellerId +
  //       "&size=" +
  //       loadrequest.size +
  //       "&page=" +
  //       loadrequest.page +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&sortBy=" +
  //       loadrequest.sortBy +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&customerId=" +
  //       loadrequest.customerId;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > PDF";
  //     request.action = "View";
  //     request.featureName = "Stoppage";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);

  //   } catch (error) {}
  // };

  // Get Data from New Dropdown
  const [getOverSpeed, { data: oversppedData, isLoading, isSuccess }] =
    useStopPageMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    //Service Call
    setPayload(commonPayload);
    getOverSpeed(commonPayload);

    auditPayload.message = "Reports > Stoppage > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Stoppage";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "StoppageReport/stoppageReportXLS.xls?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&size=" +
        payload.size +
        "&page=" +
        payload.page +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&sortBy=" +
        payload.sortBy +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > Stop Page > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Stop Page";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "StoppageReport/stoppageReportPDF.pdf?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&size=" +
        payload.size +
        "&page=" +
        payload.page +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&sortBy=" +
        payload.sortBy +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > Stop Page > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Stop Page";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.rowsPerPage = 25;
    if (payload.search.length >= 3) {
      getOverSpeed(payload);
    } else if (payload.search.length === 0) {
      getOverSpeed(payload);
    }
  };


  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };


  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Stoppage"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Stoppage"} />

      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={7}
        /> */}
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = {resellerId === 110224 ? 31 : 7} 
        time = {true} allowedMonth = {resellerId === 110224 ? 6 : 3} last6Month={true} last31={resellerId === 110224 ? true : false} getDataLoading={isLoading}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              handleChange={handleSearch}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
              initialState={initialState1}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default Stoppage;
