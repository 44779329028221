import { Box, Tab, Tabs, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { StyledTabItem } from '../theme/StyledEle';

function InnerTab(props) {
   const { tabList, type='vertical', border=true, scrollable=false, onChange, value, iconOptions=null,getTabValue, } = props;
   const theme = useTheme();
//tabs  
    const [ tabValue, setTabValue] = useState(1);
    const tabHandle = (event, value) => {
        setTabValue(value);
        getTabValue(value);
    }
  return (
    <Box sx={{borderBottom: border ? `.5px solid ${theme.palette.divider}` : 'none', background:theme.palette.primary.contrastText}}>
        <Tabs sx={{minHeight:'auto'}} value={tabValue} scrollButtons={scrollable} onChange={tabHandle} indicatorColor='secondary'>
            {
                tabList.map(item =>  <StyledTabItem label={item.name} value={item.id}/>)
            }
        </Tabs>
    </Box>
  )
}

export default InnerTab