import Service from "./service";

const OTAServices = {
  getWriteCommandList: function (resource) {
    return Service.post("/OTA/getWriteCommands", resource, null);
  },
  getReadCommandList: function (resource) {
    return Service.post("/OTA/getReadCommands", resource, null);
  },
  saveCommands: function (data) {
    return Service.post("/OTA/saveCommands", data, null);
  },
  getOTALogList: function (data) {
    return Service.post("/OTA/getOTALog", data, null);
  },
  getFirmwareListData: function (resource) {
    return Service.post("/OTA/getFirmwareCommands", resource, null);
  },
};

export default OTAServices;
