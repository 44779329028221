import { dashboardApi } from "../ApiSlice";

const TimelineSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        //Timeline
        RouteTimeline:builder.mutation({
            query:(body)=> ({
                url: "/routeTrip/getVehicleTimelineForTrip",
                method: "POST",
                body: body,
                
            })
        }),
        })
})

export const { useRouteTimelineMutation } = TimelineSlice;