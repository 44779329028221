import {
    Alert,
    Box,
    Tabs,
    Typography
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import {
    StyledPageLayout,
    StyledTab,
    StyledTabBox,
} from "../../theme/StyledEle";
import { AnalyticsDashboards } from "../Constans";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { AnalyticsDashboardTabs } from "../../Utils/Constans";
  
  
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box >{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  
  export const AnalyticsDashboard = () => {
    const [showDashboard, setShowDashboard] = useState("-1");
    const [showCalendar, setShowCalendar] = useState(false);
    const [value, setValue] = React.useState(55);

    let userData = JSON.parse(localStorage.getItem("userData"));
    let RoleId = userData?.roleId;
    let orgType = userData?.userOrgType;
    let resellerId = userData?.resellerId;
    let orgId = userData?.orgId;
    let userId = userData?.id;
    let orgName= userData?.orgName;
    let userName= userData?.userName;
  
  
    const [auditLog] = useAuditLogMutation();
    let auditPayload = {
      //Audit Common Payload
      orgId: orgId,
      userId: userId, 
      orgName: orgName, 
      userName: userName,
    };
  
    useEffect(() => {
      if (showDashboard === 55) {
        setShowCalendar(false);
      }
    }, [showDashboard]);
  
    const handleChange = async (event, newValue) => {
      setValue(newValue);
      if (newValue === 56) {
        setShowCalendar(true);
        auditPayload.message = "Dashboard > Analytics Dashboard > Trip Summary";
      } else {
        setShowCalendar(false);
        auditPayload.message = "Dashboard > Analytics Dashboard >Last Connected";
      }
  
      auditPayload.action = "Select";
      auditPayload.featureName = "Dashboard";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    };
  
    useEffect(async () => {
      auditPayload.message = "Dashboard > Analytics Dashboard";
      auditPayload.action = "View";
      auditPayload.featureName = "Dashboard";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    }, []);
   
    //onsubmit
    let [formData, setFormData] = useState(null);
    const [date, setDate] = useState({ start: null, end: null });
    let [lastConnectUrl, setLastConnectUrl] = useState(null);
    let [vehicleSumUrl, setVehicleSumUrl] = useState(null);
    let [vehicle, setVehicle] = useState(null);
    let iframeReload = useRef();
    //Hide & Show Tabs
    const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
    const onSubmitForm = (data) => {
      setFormData(data);
      setDate({ start: data?.fromDate, end: data?.toDate });
      if(iframeReload?.current?.src !== undefined && iframeReload?.current !== null){
        iframeReload.current.src = iframeReload?.current?.src;
      }
  
    };
  
    //dashboard urls
    let [lastConnet, setLastConnect] = useState(null);
    let [vehicleSummaryd, setVehicleSummaryd] = useState(null);
    let [oem, setOem] = useState(null);
    const findDashboard = () => {
      let findUrls = AnalyticsDashboards?.filter( (item) => item?.resellerId === userData?.resellerId);
      setLastConnect(findUrls[0]?.dashboardName[0].url);
      setVehicleSummaryd(findUrls[0]?.dashboardName[1].url);
      setOem(findUrls[0]?.oem);
  
    };
  
    useEffect(() => {
        findDashboard();
    }, []);
  
  
  
    useEffect(() => {
      var regionId = "";
      var customerId = "";
      var dealerId = "";
      var fleetId = "";
      var vehicleId = "";
      let startTime = moment(date.start, "YYYY-MM-DD HH:mm:ss").valueOf();
      let endTime = moment(date.end, "YYYY-MM-DD HH:mm:ss").valueOf();
  
      if (formData !== null) {
        if (formData?.regionId !== "-1" && formData?.regionId !== 0 && orgType !== 5 && orgType !== 3 && orgType !== 1) {
          regionId = '"REGION_ID"=' + formData?.regionId + "and";
        } else {
          regionId = oem && orgType !== 1 ?  '"REGION_ID"!=5' + "and" : ""
        }
  
        if (formData?.dealerId !== "-1" && orgType !== 3 && orgType !== 1) {
          dealerId = '"DEALER_ID"=' + formData?.dealerId + "and";
        } else {
          dealerId = "";
        }
  
        if (formData?.customerId !== "-1" && orgType !== 1) {
          customerId = '"CUSTOMER_ID"=' + formData?.customerId + "and";
        } else {
          customerId = "";
        }
  
        if (formData?.fleetId !== "-1") {
          fleetId = '"org_id"=' + formData?.fleetId + "and";
        } else {
          fleetId = "";
        }
        if (formData?.vehicleId !== "-1") {
          vehicleId = formData?.vehicleId;
          setVehicle(formData?.vehicleId);
        } else {
          vehicleId = "";
          setVehicle(formData?.vehicleId);
          setValue(55);
        }
      }
      if(showDashboard !== '-1' && showDashboard !== null){
        setLastConnectUrl(
          lastConnet +
            "&var-vehicle_id=" +
            vehicleId +
            "&var-Start_date=" +
            moment().format("YYYY-MM-DD") +
            "&var-End_date=" +
            moment().format("YYYY-MM-DD") +
            "&kiosk"
        );
        setVehicleSumUrl(
            vehicleSummaryd +
            "&from=" +
            startTime +
            "&to=" +
            endTime +
            "&var-vehicle_id=" +
            vehicleId +
            "&var-Start_date=" +
            date.start +
            "&var-End_date=" +
            date.end +
            "&var-Date=" +
            date.start +
            "&kiosk"
        );
      }
     
    }, [formData, showDashboard]);
  
    useEffect(() => {
      if (vehicle !== "-1" && vehicle !== null && vehicle !== 404) {
        setShowDashboard(vehicle);
        if(tabsOpt !== null){
          setValue(tabsOpt[0])
        }
      } 
    }, [vehicle]);
  
    return (
      <StyledPageLayout>
        <Breadcrumb mainDiv="Dashboard" subDiv="Analytics Dashboard"></Breadcrumb>
        <FilterDropdown
          getData={onSubmitForm}
          last7={false}
          status={false}
          allowDays={1}
          showToday={false}
          showCalendar={showCalendar}
          allowedMonth={3}
          time={false}
          isShowAll={false}
          setToday={false}
        />
            <Box>
              {/* Tabs */}
              {
                tabsOpt !== null && vehicle !== 404 &&
                <StyledTabBox>
             <Tabs 
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                >
                  {
                    AnalyticsDashboardTabs?.map((item) => {
                      if(tabsOpt?.includes(item.valaue)){
                        return( <StyledTab value={item.valaue} label={item.label}/> )
                      } 
                    })
                  }
    
             </Tabs> 

                </StyledTabBox>
              }
             
              {/* Tabs Panel */}
              {
                tabsOpt !== null &&  vehicle !== 404 &&
                  <CustomTabPanel value={value} index={55}>
                    <Box sx={{ height: "100vh" }}>
                      <iframe
                        id="Contentframe"
                        className="iframe"
                        src={lastConnectUrl}
                        width="100%"
                        height="98%"
                        name="Right"
                        ref={iframeReload}
                      ></iframe>
                    </Box>
                  </CustomTabPanel>
              }
              {
                 tabsOpt !== null && vehicle !== 404 &&
                 <CustomTabPanel value={value} index={56}>
                 <Box sx={{ height: "100vh" }}>
                   <iframe
                     id="Contentframe"
                     className="iframe"
                     src={vehicleSumUrl}
                     width="100%"
                     height="98%"
                     ref={iframeReload}
                   ></iframe>
                 </Box>
               </CustomTabPanel>
              }
              {
                tabsOpt === null && <Box sx={{height:'100%', background:'#fff', padding:'25px', mt:4}}>
                  <Alert severity="error">
                      <Typography>Access Denied! please contact your admin.</Typography>
                  </Alert>
                </Box>
              }
                            {
                vehicle === 404 && <Box sx={{height:'100%', background:'#fff', padding:'25px', mt:4}}>
                  <Alert severity="error">
                      <Typography>No Vehicle Found.</Typography>
                  </Alert>
                </Box>
              }
            </Box>
      </StyledPageLayout>
    );
  };
  
  export default AnalyticsDashboard;
  