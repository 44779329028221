import { Breadcrumbs, Typography, Box, Stack, Chip, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { StyledBreadcrumb } from "../../theme/StyledEle";
import { FiArrowUpCircle } from "react-icons/fi";
import { HiArrowLongRight } from "react-icons/hi2";
import { togglePopup } from "../../store/slice/ControlsSlice";
import { useDispatch, useSelector } from "react-redux";
export const Breadcrumb = ({ mainDiv, subDiv, items, includePopup = false }) => {
  const theme = useTheme();
  //Popover hide
  const popupState = useSelector((state) => state.controls.customizer.popupIsHide)
  const dispatch = useDispatch();
  const hidePopup = () => {
    dispatch(togglePopup(!popupState));
  }
  if (items) {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        {items.map((item, index) => (
            {item}
        ))}
      </Breadcrumbs>
    );
  }


  return (
    <StyledBreadcrumb>
      <Stack direction="row" alignItems="center">
        <Breadcrumbs aria-label="breadcrumb" separator={<HiArrowLongRight />} >
          <Typography variant="body1">{mainDiv}</Typography>
          <Typography variant="body1">{subDiv}</Typography>
        </Breadcrumbs>
        {
          includePopup && <Tooltip title='Note' arrow><IconButton color="secondary" size="small" onClick={hidePopup} sx={{transform: popupState ? 'rotate(0deg)' : 'rotate(-180deg)', transition:'.3s'}}><FiArrowUpCircle/></IconButton></Tooltip>
        }
      </Stack>
    </StyledBreadcrumb>
  );
};
