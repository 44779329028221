export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_INIT = "AUTH_INIT";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const AUTH_CLEAN = "AUTH_CLEAN";
export const AUTH_CURRENT = "AUTH_CURRENT";
export const AUTH_SAVE = "AUTH_SAVE";
export const THEME_SETTING = "THEME_SETTING";
export const INIT_THEME_SETTING = "INIT_THEME_SETTING";

export const PACKAGES_SET = "PACKAGES_SET";
export const PACKAGES_INIT = "PACKAGES_INIT";
// Implement Country action type
export const COUNTRY_SET = "COUNTRY_SET";
export const COUNTRY_INIT = "COUNTRY_INIT";