import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import {
  SoftButton,
  StyledCardShadow,
  StyledPageLayout,
} from "../../theme/StyledEle";
import {
  useAuditLogMutation,
  useDeviceDataGridViewMutation,
  useVerifyDeviceBenchTestMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useDispatch, useSelector } from "react-redux";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import OTADropdown from "../OTA/OTADropdown";

const text =
  "Device Data Report gets the feed details of a device based on date selection.";

const DeviceDataReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [auditLog] = useAuditLogMutation();
  const theme = useTheme();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > DeviceData";
    auditPayload.action = "View";
    auditPayload.featureName = "DeviceData";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "GPS Location",
        accessor: "location",
      },
      {
        Header: "Battery Voltage (V)",
        accessor: "batteryVoltage1",
      },
      {
        Header: "Battery Current (A)",
        accessor: "batteryCurrent",
      },
      {
        Header: "Battery SOC(%)",
        accessor: "batterySoc",
      },
      // {
      //   Header: "Battery Energy(Wh)",
      //   accessor: "availableEnergy",
      // },
      // {
      //   Header: "ID",
      //   accessor: "id",
      // },
    ],
    []
  );

  // Get Data from New Dropdown
  const [
    getDeviceData,
    {
      data: deviceData,
      isLoading: deviceIsLoading,
      isSuccess: deviceIsSuccess,
    },
  ] = useDeviceDataGridViewMutation();
  const [
    verifyBench,
    {
      data: verifyBenchData,
      isLoading: verifyIsLoading,
      isSuccess: verifyIsSuccess,
    },
  ] = useVerifyDeviceBenchTestMutation();

  let commonPayload = {};
  let [payload, setPayload] = useState(null);
  const onSubmitForm = (data) => {
    commonPayload.resellerId = resellerId;
    commonPayload.orgId = orgId;
    commonPayload.imei = data.imei;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.status = data.benchStatus;
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.vehicleId = data.vehicle;
    //Service Call
    setPayload(commonPayload);
    getDeviceData(commonPayload);
  };
  // //Export PDF & Excel
  // const [payload, setPayload] = useState(null);
  // const onChangeXcl = async () => {
  //   try {
  //     var url =
  //     process.env.REACT_APP_API_BASE_URL +
  //     "ChargingReport/chargingreportXLS.xls?startDate=" +
  //     payload.startDate +
  //     "&endDate=" +
  //     payload.endDate +
  //     "&search=" +
  //     payload.search +
  //     "&orgId=" +
  //     payload.orgId +
  //     "&vehicleId=" +
  //     payload.vehicleId +
  //     "&reselId=" +
  //     payload.reselId +
  //     "&regionId=" +
  //     payload.regionId +
  //     "&dealerId=" +
  //     payload.dealerId +
  //     "&custId=" +
  //     payload.custId;
  //     window.open(url);

  //    auditPayload.message = 'Reports > OverSpeed > XLS';
  //    auditPayload.action = 'View';
  //    auditPayload.featureName = 'OverSpeed';
  //    auditPayload.customerName = 'All';
  //    auditPayload.fleetName = 'All';
  //    auditPayload.result = 'Success';
  //    auditPayload.access = 'Portal';
  //    auditPayload.severity = 'INFO';
  //    auditLog(auditPayload);

  //     fetch(url, {
  //       headers: {
  //         Authorization:
  //           "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
  //       },
  //     }) // FETCH BLOB FROM IT
  //       .then((response) => {
  //         response.blob();
  //       })
  //       .then((blob) => {})
  //       .catch((err) => {});
  //   } catch (error) {}
  // };
  // const onChangePDF = async () => {

  //   try {
  //     var url =
  //     process.env.REACT_APP_API_BASE_URL +
  //     "ChargingReport/ChargingreportPDF.pdf?startDate=" +
  //     payload.startDate +
  //     "&endDate=" +
  //     payload.endDate +
  //     "&search=" +
  //     payload.search +
  //     "&orgId=" +
  //     payload.orgId +
  //     "&vehicleId=" +
  //     payload.vehicleId +
  //     "&reselId=" +
  //     payload.reselId +
  //     "&regionId=" +
  //     payload.regionId +
  //     "&dealerId=" +
  //     payload.dealerId +
  //     "&custId=" +
  //     payload.custId;

  //     window.open(url);

  //     auditPayload.message = 'Reports > Charging > PDF';
  //     auditPayload.action = 'View';
  //     auditPayload.featureName = 'Charging';
  //     auditPayload.customerName = 'All';
  //     auditPayload.fleetName = 'All';
  //     auditPayload.result = 'Success';
  //     auditPayload.access = 'Portal';
  //     auditPayload.severity = 'INFO';
  //     auditLog(auditPayload);
  //   } catch (error) {}
  // };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getDeviceData(payload);
    } else if (payload.search.length === 0) {
      getDeviceData(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.length=rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.start = 0;
      getDeviceData(payload);
    }else{
      setNewPage(newPage);
      payload.start = newPage;
      getDeviceData(payload);
    }
  };

  useEffect(() => {
    if (deviceIsSuccess) {
      setResponseData([]);
      if (deviceData.data !== null) {
        setResponseData(deviceData.data.content);
        setTotalElements(deviceData.data.totalElements);
        setTotalPages(deviceData.data.totalPages);
      }
    }
  }, [deviceIsSuccess]);

  const handleVerify = () => {
    var payload = {};
    let idArry = [];
    let imeiArry = [];
    let dateArry = [];
    for (let i = 0; i < selectRows.length; i++) {
      idArry.push(selectRows[i].values.id);
      imeiArry.push(selectRows[i].values.imei);
      dateArry.push(selectRows[i].values.createdDate);
    }
    payload.id = idArry.toString();
    payload.imei = imeiArry.toString();
    payload.date = dateArry.toString();
    verifyBench(payload);
  };

  const [selectRows, setSeletedRows] = useState(null);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  //get row data from data table
  const getRow = (selectRow, rowId) => {
    setSeletedRows(selectRow);
  };

  useEffect(() => {
    if (verifyIsSuccess) {
      setMessage(verifyBenchData?.resultMessage);
      setStatus(verifyBenchData?.resultText);
      dispatch(
        snackService([
          verifyBenchData?.resultMessage,
          verifyBenchData?.resultText,
          verifyIsSuccess,
        ])
      );
    }
  }, [verifyIsSuccess]);
  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Device Data Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Device Data Report"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={7}
        /> */}
        <OTADropdown
          getData={onSubmitForm}
          imeiDrop={true}
          benchDrop={true}
          onLoadData={false}
          getDataLoading={deviceIsLoading} 
        />
        <StyledCardShadow sx={{ mt: 2 }}>
          {selectRows?.length > 0 && (
            <CardHeader
              title={
                <SoftButton
                  color="info"
                  className="info"
                  onClick={handleVerify}
                >
                  Verify
                </SoftButton>
              }
            />
          )}
          <CardContent sx={{ py: 1 }}>
            <DataTable
              isLoading={deviceIsLoading}
              columns={columns}
              data={responseData}
              includeExportButton={false}
              //   onChangeXcl={onChangeXcl}
              //   onChangePdf={onChangePDF}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              checkbox={true}
              selectRow={getRow}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </StyledPageLayout>
  );
};

export default DeviceDataReport;
