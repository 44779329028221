import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import {
  StyledFilterDropDown,
  StyledFilterFormControl,
  StyledFilterMenuItem,
  StyledFilterSearch,
  StyledFormControlLabel,
  StyledTextField,
} from "../../theme/StyledEle";
import {
  Box,
  FormControl,
  InputBase,
  ListSubheader,
  MenuItem,
  Stack,
  SvgIcon,
  styled,
  useTheme,
} from "@mui/material";
import {
  AlertType,
  Alerts,
  Calender,
  Customer,
  CustomerAssigned,
  Dealer,
  Fleet,
  IMEI,
  LastConnect,
  Org,
  Region,
  Vehicle,
  Status,
  Firmware,
} from "../../constants/DualIcons";
function SingleDropdownHelpDesk(props) {
  const {
    label,
    listItems,
    handleChange,
    value,
    isSearch,
    showAll,
    customAllValue = "-1",
    selectType = "refVal",
    fullwidth=false,
    disabled = false,
    getInnerText,
  } = props;
  const menuItemRef = listItems?.content?.map(() => createRef());
  const [searchValue, setSearchValue] = useState(null);
  const [sel, setSel] = useState(null);
  let dropRef = useRef();
  const selectedTarget = (event) => {
    const { value } = event.target;
    handleChange(value, searchValue);
  };

  useEffect(() => {
    var dropVal=dropRef.current.outerText;
    if(getInnerText!==undefined){
      getInnerText(dropVal.replace(/(\r\n|\n|\r)/gm, ""),label);
    }
  },[value])

  //Search text
  const onSearch = (event, name) => {
    const { value } = event.target;
    if (isSearch !== false) {
      isSearch(event.target.value, name);
    }
    setSearchValue(event.target.value);
  };

  //Styled
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
   
  };

  const BaseInput = styled(InputBase)(({ theme }) => ({}));


  return (
    <FormControl fullwidth sx={{width:"100%"}}>
        <StyledFormControlLabel>{label}</StyledFormControlLabel>
        {/* <SvgIcon sx={{fontSize:'20px', color:theme.palette.mode === 'light' ?  theme.palette.secondary.main : theme.palette.grey[300]}}> */}

        <StyledTextField fullwidth
          size="small"
          onChange={(event) => selectedTarget(event)}
          value={value}
          MenuProps={{
            ...MenuProps,
            MenuListProps:{
              autoFocus:false,
              autoFocusItem:false
            }
          }}
          className="newdropdown"
          onClose={() => setSearchValue("")}
          disabled={disabled}
          ref={dropRef}
          select
        >
           {isSearch !== false && (
           <ListSubheader sx={{px:0}}>
            <StyledFilterSearch
              size="small"
              onChange={(event) => onSearch(event, label)}
              onKeyDown={(e) => e.stopPropagation()}
              value={searchValue}
              sx={{pointerEvents:'none', '& input':{pointerEvents:'all'} }}
              autoFocus
            />
           </ListSubheader>
            )}
          {showAll && (
            <MenuItem key={0} value={customAllValue}>
              Select
            </MenuItem>
          )}

          {listItems !== null &&
            listItems?.content?.length > 0 &&
            listItems?.content.map((item, index) => (
              <StyledFilterMenuItem
                key={index + 1}
                id={item.key}
                value={
                  label === "IMEI" ? 
                  item.refVal !== null 
                  && item.refVal
                    : label === "Sim status"
                    ? item.value
                    : item.key
                }
                ref={menuItemRef[item.key]}
              >
                {label === "IMEI"
                  ? item?.refVal !== null && item.refVal
                  : label === "Sim status"
                  ? item.title
                  : item.value}
              </StyledFilterMenuItem>
            ))}
        </StyledTextField>
    </FormControl>
  );
}

export default SingleDropdownHelpDesk;
