import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../shared/data_table/DataTable";
import {
  Backdrop,
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import SimServices from "../../services/simServices";
import CommonDropDown from "./simDropdown";
import DraggableDialog from "../shared/DailogDrag";
import simServices from "../../services/simServices";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledTextField,
} from "../../theme/StyledEle";
import logServices from "../../services/logService";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import {
  useSimDeleteMutation,
  useSimListMutation,
  useSimActivateMutation,
  useSimSafeCustodyMutation,
  useSimOutOfSafeCustodyMutation,
  useSimDisconnectMutation,
  useResumeFromTempDisconnectMutation,
  useRefreshOrderMutation,
  useSaveWhitelistMutation,
  useAuditLogMutation,
} from "../../store/slice/ApiSlice";
import StyledModel from "../../Utils/StyledModel";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import {  useLocation,useNavigate } from "react-router";
import { InvisableLoader } from "../shared/TableLoaders";
import { BeatLoader } from "react-spinners";
import StyledSnackBar from "../../Utils/StyledSnackBar";




const ListSim = ({ onViewSim, onEditSim, menuUser }) => {

  const location = useLocation();
  let hashId = location.hash;
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [savedData, setSavedData] = useState({});
  const [loading, setLoading] = useState("");
  const [dialogContent, setDialogContent] = useState({});
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [loadStatus, setLoadStatus] = useState(true);
  const [arraySortOrder, setArraySortOrder] = useState({});
  const [selectedDropdownAry, setSelectedDropdownAry] = useState([]);
  const [exportRequest, setExportRequest] = useState([]);
  const [commonFilter, setCommonFilter] = useState({
    excel: false,
    pdf: false,
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const [deleteItem, setDeleteItem] = useState(null);

  const globalJson1 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;
if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
) {
} else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
}

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;


  const columns = useMemo(
    () => [
      // {
      //   id: 'select',
      //   Header: '',
      //   accessor: 'buttonCheckBox'
      // },
      {
        Header: "Region",
        accessor: "regionName",
        show:orgType !== 3 && orgType !== 5 && true
      },
      {
        Header: "Dealer",
        accessor: "dealerName",
        show:orgType !== 3 && orgType !== 5 && true
      },
      {
        Header: "Customer",
        accessor: "customerName",
        show:orgType !== 3 && true
      },
      {
        Header: "Sim Number",
        accessor: "simNumber",
        width:180,
      },
      // {
      //   Header: "SIM 1",
      // columns: [
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
      },
      {
        Header: "Provider Name",
        accessor: "providerName",
      },
      {
        Header: "Plan Name",
        accessor: "planName",
      },
      // ],
      // },
      // {
      //   Header: "SIM 2",
      //   columns: [
      //     {
      //       Header: "Mobile Number",
      //       accessor: "mobileNumber1",
      //     },
      //     {
      //       Header: "Provider Name",
      //       accessor: "simProviderId1",
      //     },
      //     {
      //       Header: "Plan Name",
      //       accessor: "simPlanId1",
      //     },
      //   ],
      // },
      {
        Header: "Status",
        accessor: "simStatusval",
      },
      {
        Header: "State",
        accessor: "simState",
      },
      {
        Header: "Assign To Device",
        accessor: "imei",
        //  accessor: "simTestActivationDate",
      },
      {
        Header: "Last Connected",
        accessor: "vehGPSdate",
      },
      {
        Header: "Vehicle Status",
        accessor: "gpsStatus",
      },
      {
        Header: "Created Date",
        accessor: "strCreatedDate",
      },
      {
        Header: "Plan ID1",
        accessor: "simPlanId1",
      },
      {
        Header: "Sim WhiteListed",
        accessor: "simWhiteListing",
      },
      {
        Header: "Sim WhiteListed No",
        accessor: "simWhiteListNo",
      },
      {
        Header: "Activated By",
        accessor: "activateBy",
      },
      {
        Header: "Activated Mode",
        accessor: "activationMode",
      },
      {
        Header: "Activated Date",
        accessor: "activatedDate",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
        width:120,
      },
    ],
    []
  );

  const onView = async (handleSimView) => {
    onViewSim(handleSimView);

    auditPayload.message = "Admin > Sim > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  const onEdit = async (handleSimEdit) => {
    onEditSim(handleSimEdit);

    auditPayload.message = "Admin > Sim > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };
  // const fetchOrgDetails = async (searchText, columnName, orderdir) => {
  //   var request = {};
  //   request.startDate = selectedDropdownAry.startDate;
  //   request.endDate = selectedDropdownAry.endDate;
  //   request.customerId = selectedDropdownAry.customerId;
  //   request.fleetId = selectedDropdownAry.fleetId;
  //   request.deviceId = "-1";
  //   request.statusId = selectedDropdownAry.statusId;
  //   request.search = searchText;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.dateOnOrbefore = selectedDropdownAry.dateOnOrbefore;
  //   request.resellerOrgId = selectedDropdownAry.resellerOrgId;
  //   request.resellerId = selectedDropdownAry.resellerId;
  //   request.vehicleId = "-1";
  //   request.dealerId = selectedDropdownAry.dealerId;
  //   request.regionId = selectedDropdownAry.regionId;
  //   if (columnName === undefined) {
  //     request.columnName = "PLAN_NAME";
  //   } else {
  //     request.columnName = columnName;
  //   }
  //   if (orderdir === undefined) {
  //     request.orderdir = "desc";
  //   } else {
  //     request.orderdir = orderdir;
  //   }
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.dateOnOrbefore = selectedDropdownAry.dateOnOrbefore;
  //   request.resellerOrgId = selectedDropdownAry.resellerOrgId;

  //   try {
  //     const responseData = (await SimServices.getSim(request)).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);

  //     var request = {};
  //     request.message = "Admin > Sim > GetSim";
  //     request.action = "View";
  //     request.featureName = "Sim";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;

  //     const responseData1 = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };

  // const handleSort = (e, sortColumn, sortVal) => {
  //   var obj1 = {};
  //   obj1 = { ...arraySortOrder };
  //   var obj = { sortColumn };
  //   var orderdir = "desc";
  //   var seltdsrtngcolumn = "";
  //   if (obj.sortColumn.id === "simNumber") {
  //     if (obj1.simNumber !== undefined && obj1.simNumber === "asc") {
  //       obj1.simNumber = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.simNumber = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "SIM_NUMBER";
  //   } else if (obj.sortColumn.id === "mobileNumber") {
  //     if (obj1.mobileNumber !== undefined && obj1.mobileNumber === "asc") {
  //       obj1.mobileNumber = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.mobileNumber = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "MOBILE_NUMBER";
  //   }
  //   setArraySortOrder(obj1);
  //   fetchOrgDetails("", seltdsrtngcolumn, orderdir);
  // };
  const onDelete = (itemDeleted) => {
    setDeleteItem(itemDeleted);
    navigate("#delete");

  };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   console.log(selectedDropdownAry.fromDate);
  //   var request = {};
  //   //request.size = "2";
  //   //request.size = rowsPerPage;
  //   request.startDate = selectedDropdownAry.startDate;
  //   request.endDate = selectedDropdownAry.endDate;
  //   //request.resellerId = "-1";
  //   request.customerId = selectedDropdownAry.customerId;
  //   request.fleetId = selectedDropdownAry.fleetId;
  //   request.deviceId = "-1";
  //   request.statusId = selectedDropdownAry.statusId;
  //   request.search = "";
  //   request.columnName = "created_date";
  //   request.orderdir = "desc";
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.dateOnOrbefore = selectedDropdownAry.dateOnOrbefore;
  //   request.resultCode = "0";
  //   request.resultText = "string";
  //   request.resellerOrgId = selectedDropdownAry.resellerOrgId;
  //   request.resellerId = selectedDropdownAry.resellerId;
  //   request.vehicleId = "-1";
  //   request.dealerId = selectedDropdownAry.dealerId;
  //   request.regionId = selectedDropdownAry.regionId;
  //   try {
  //     const responseData = (await SimServices.getSim(request)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  const [
    getSimList,
    { data: simlistData, isLoading: getSimLoading, isSuccess: getSimSuccess },
  ] = useSimListMutation();
  let commonPayload = {};
  let [payload, setPayload] = useState(null);
  // Onload and Submit
  const getData = (data) => {
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.customerId = data.customer;
    commonPayload.fleetId = data.fleet;
    commonPayload.deviceId = -1;
    commonPayload.statusId = data.simStatus;
    commonPayload.dateOnOrbefore = data.createDate;
    commonPayload.vehicleId = data.vehicle;
    commonPayload.dealerId = data.dealer;
    commonPayload.regionId = data.region;
    commonPayload.resellerOrgId = resellerId;
    commonPayload.columnName = "CREATED_DATE";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.orderdir = "desc";
    commonPayload.search = "";

    setPayload(commonPayload);
    getSimList(commonPayload);

    auditPayload.message = "Admin > Sim > List Sim > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+">"+data.simStatusName+" > "+data.selectedPeriod+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  useEffect(() => {
    if (getSimSuccess) {
      setResponseData([]);
      if (simlistData.data !== null) {
        setResponseData(simlistData.data.content);
        setTotalElements(simlistData.data.totalElements);
        setTotalPages(simlistData.data.totalPages);
        var dataStr = {};
        dataStr.edit = false;
        dataStr.delete = false;
        dataStr.view = false;
        dataStr.excel = false;
        dataStr.pdf = false;
        var strval;
        strval = menuUser.split(",");
        for (var i = 0; i < strval.length; i++) {
          if (parseInt(strval[i]) === 1) {
            dataStr.create = true;
          }
          if (parseInt(strval[i]) === 4) {
            dataStr.edit = true;
          }
          if (parseInt(strval[i]) === 2) {
            dataStr.view = true;
          }
          if (parseInt(strval[i]) === 3) {
            dataStr.delete = true;
          }
          if (parseInt(strval[i]) === 6) {
            dataStr.pdf = true;
          }
          if (parseInt(strval[i]) === 5) {
            dataStr.excel = true;
          }
        }
        setCommonFilter(dataStr);
      }
    }
  }, [getSimSuccess]);

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "sim/simListXLS.xls?resellerId=" +
        payload.resellerOrgId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&resellerOrgId=" +
        payload.resellerOrgId +
        "&customerId=" +
        payload.customerId +
        "&fleetId=" +
        payload.fleetId +
        "&vehicleId=" +
        payload.vehicleId +
        "&deviceId=" +
        payload.deviceId +
        "&statusId=" +
        payload.statusId +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&dateOnOrbefore=" +
        payload.dateOnOrbefore +
        " ";
      window.open(url);

      auditPayload.message = "Admin > Sim > List > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Sim";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "SimList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  const onChangePdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "sim/simListPDF.pdf?resellerId=" +
        payload.resellerOrgId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&resellerOrgId=" +
        payload.resellerOrgId +
        "&customerId=" +
        payload.customerId +
        "&fleetId=" +
        payload.fleetId +
        "&vehicleId=" +
        payload.vehicleId +
        "&deviceId=" +
        payload.deviceId +
        "&statusId=" +
        payload.statusId +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&dateOnOrbefore=" +
        payload.dateOnOrbefore +
        " ";
      window.open(url);

      auditPayload.message = "Admin > Sim > List > Export > PDF";
      auditPayload.action = "Download";
      auditPayload.featureName = "Sim";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "SimList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getSimList(payload);
  };
  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(25);
    payload.search = searchText;
    payload.page = 0;
    payload.size = 25;
    if (payload.search.length >= 3) {
      getSimList(payload);
    } else if (payload.search.length === 0) {
      getSimList(payload);
    }
  };
  const [
    delteItem,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useSimDeleteMutation();
  const deleteItems = () => {
    let payload = {
      id: deleteItem.id,
    };
    delteItem(payload);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );
      getSimList(payload);
    }
  }, [isDeleteSuccess]);

  //Sim Send & White List Function
  const [commondType, setCommonType] = useState(0);
  const [simCommond, setSimCommond] = useState(0);
  const [plan, setPlan] = useState(0);
  const [testPlan, setTestPlan] = useState(0);
  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);
  const [supportType, setSupportType] = useState("support");
  const [supportNo, setSupportNo] = useState("9790706090");

  const [openDel, setOpenDel] = useState(false);

  //get row data from data table
  const getRow = (selectRow, rowId) => {
    setSelectRowId(rowId);
    setSeletedRows(selectRow);
  };

  const [
    saveSimActivation,
    {
      data: simsaveactdata,
      isLoading: getSimActLoading,
      isSuccess: getSimActSuccess,
    },
  ] = useSimActivateMutation();

  const [
    saveSimSafe,
    {
      data: simsaveSafedata,
      isLoading: getSimSafeLoading,
      isSuccess: getSimSafeSuccess,
    },
  ] = useSimSafeCustodyMutation();

  const [
    saveSimOutOfSafe,
    {
      data: simsaveOutOfSafedata,
      isLoading: getSimOutOfSafeLoading,
      isSuccess: getSimOutOfSafeSuccess,
    },
  ] = useSimOutOfSafeCustodyMutation();

  const [
    saveSimDisconnect,
    {
      data: simsaveDisconnectdata,
      isLoading: getSimDisconnectLoading,
      isSuccess: getSimDisconnectSuccess,
    },
  ] = useSimDisconnectMutation();

  const [
    saveSimResumeFromTempDisconnect,
    {
      data: simsaveResumeFromTempDisconnectdata,
      isLoading: getSimResumeFromTempDisconnectLoading,
      isSuccess: getSimResumeFromTempDisconnectSuccess,
    },
  ] = useResumeFromTempDisconnectMutation();

  const [
    saveWhitelist,
    {
      data: saveWhitelistdata,
      isLoading: getWhitelistLoading,
      isSuccess: getWhitelistSuccess,
    },
  ] = useSaveWhitelistMutation();

  const handleSend = () => {
    let mobArry = [];
    let simArry = [];
    let imeiArry = [];
    for (let i = 0; i < selectRows.length; i++) {
      mobArry.push(selectRows[i].values.mobileNumber);
      simArry.push(selectRows[i].values.simNumber);
      imeiArry.push(selectRows[i].values.imei);
    }
    var payload = {};
    payload.deviceSimNo = simArry.toString();
    payload.deviceMobileNo = mobArry.toString();
    payload.devIMEI = imeiArry.toString();
    payload.planCode = plan;
    payload.testPlan = testPlan;
    payload.orgId = resellerId;
    payload.orgType = orgType;
    payload.userId = userId;
    if (simCommond === 0) {
      // simActivate
      saveSimActivation(payload);
    } else if (simCommond === 1) {
      // simSafeCustody
      saveSimSafe(payload);
    } else if (simCommond === 2) {
      // simOutOfSafeCustody
      saveSimOutOfSafe(payload);
    } else if (simCommond === 3) {
      // simDisconnect
      saveSimDisconnect(payload);
    } else if (simCommond === 4) {
      // resumeFromTempDisconnect
      saveSimResumeFromTempDisconnect(payload);
    }
    var obj1 = {};

    obj1.dialogText = "Sent Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm("Success");
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };
  const [whiteListItem, setWhiteListItem] = useState(false);

  const handleWhiteList = () => {
    setWhiteListItem(true);
  };

  const whiteListItems = () => {
    let payload = {
      simMobNum: selectRows[0]?.values.mobileNumber,
      userMobNum: "",
      supportMobileNum: supportNo,
      whiteListVal: supportType,
    };
    saveWhitelist(payload);
    var obj1 = {};

    obj1.dialogText = "Sent Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm("Success");
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  // //Success
  useEffect(() => {
    if (
      getSimSafeSuccess ||
      getSimActSuccess ||
      getSimOutOfSafeSuccess ||
      getSimDisconnectSuccess ||
      getSimResumeFromTempDisconnectSuccess ||
      getWhitelistSuccess
    ) {
      // dispatch(
      //   snackService([
      //     simCreateData?.resultMessage,
      //     simCreateData?.resultText,
      //     simCreateSuccess,
      //   ])
      // );
      getSimList(payload);
      setWhiteListItem(false);
    }
  }, [
    getSimSafeSuccess,
    getSimActSuccess,
    getSimOutOfSafeSuccess,
    getSimDisconnectSuccess,
    getSimResumeFromTempDisconnectSuccess,getWhitelistSuccess
  ]);

  return (
    <>
      <InvisableLoader
        isLoading={
          getSimActLoading ||
          getSimSafeLoading ||
          getSimOutOfSafeLoading ||
          getSimDisconnectLoading ||
          getSimResumeFromTempDisconnectLoading ||
          getWhitelistLoading
        }
      />
      <AdminDropdown
        getData={getData}
        regionDrop={true}
        dealerDrop={true}
        customerDrop={true}
        fleetDrop={true}
        vehicleDropD={true}
        simDrop={true}
        createdate={true}
        allowDays={30}
        allowedMonth={24}
        createDateStatus={false}
      />

      <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
        {/* <CardHeader title={<StyledTableTitle>Sim List</StyledTableTitle>} /> */}
        <Box sx={{ padding: "15px" }}>
          <Collapse
            in={selectRows?.length > 0}
            sx={{
              border: `.5px solid ${theme.palette.divider}`,
              borderRadius: "10px",
            }}
          >
            <Box sx={{ px: 2, py: 1, my: 0.5 }}>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"end"}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack
                  direction={"row"}
                  alignItems={"end"}
                  spacing={1.5}
                  sx={{ Width: "100%" }}
                >
                  <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                    <StyledFormControlLabel required>
                      Commond Type
                    </StyledFormControlLabel>
                    <StyledDropDown
                      size="small"
                      placeholder=""
                      value={commondType}
                      onChange={(event, newType) =>
                        setCommonType(event.target.value)
                      }
                    >
                      <MenuItem value={0}>Select</MenuItem>
                      <MenuItem value={1}>Airtel</MenuItem>
                    </StyledDropDown>
                  </StyledFormControl>
                  {commondType !== 0 && (
                    <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                      <StyledFormControlLabel required>
                        Sim Commond
                      </StyledFormControlLabel>
                      <StyledDropDown
                        size="small"
                        placeholder=""
                        value={simCommond}
                        onChange={(event, newCommond) =>
                          setSimCommond(event.target.value)
                        }
                      >
                        <MenuItem value={0}>Activate</MenuItem>
                        <MenuItem value={1}>Safe Custody</MenuItem>
                        <MenuItem value={2}>Out Of Safe Custody</MenuItem>
                        <MenuItem value={3}>Disconnection</MenuItem>
                        <MenuItem value={4}>Resume from TD</MenuItem>
                      </StyledDropDown>
                    </StyledFormControl>
                  )}
                  {commondType !== 0 && simCommond === 0 && (
                    <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                      <StyledFormControlLabel required>
                        Commercial Plan
                      </StyledFormControlLabel>
                      <StyledDropDown
                        size="small"
                        placeholder=""
                        value={plan}
                        onChange={(event, newPlan) =>
                          setPlan(event.target.value)
                        }
                      >
                        <MenuItem value={0}>None</MenuItem>
                        <MenuItem value={"PKG_28683"}>Plus</MenuItem>
                        <MenuItem value={"PKG_28684"}>Basic</MenuItem>
                      </StyledDropDown>
                    </StyledFormControl>
                  )}

                  {commondType !== 0 && simCommond === 0 && (
                    <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                      <StyledFormControlLabel required>
                        Test Plan
                      </StyledFormControlLabel>
                      <StyledDropDown
                        size="small"
                        placeholder=""
                        value={testPlan}
                        onChange={(event, newTestPlan) =>
                          setTestPlan(event.target.value)
                        }
                      >
                        <MenuItem value={0}>None</MenuItem>
                        <MenuItem value={"PKG_36396"}>Test</MenuItem>
                      </StyledDropDown>
                    </StyledFormControl>
                  )}

                  {commondType !== 0 && (
                    <StyledFormControl fullWidth>
                      <SoftButton
                        className="success"
                        color="success"
                        onClick={handleSend}
                      >
                        {(getSimActLoading ||
                          getSimSafeLoading ||
                          getSimOutOfSafeLoading ||
                          getSimDisconnectLoading ||
                          getSimResumeFromTempDisconnectLoading ||
                          getWhitelistLoading) && (
                          <CircularProgress
                            sx={{
                              width: "20px !important",
                              height: "20px !important",
                              mr: 1,
                            }}
                            color="success"
                          />
                        )}
                        Send
                      </SoftButton>
                    </StyledFormControl>
                  )}
                </Stack>
                <SoftButton
                  color="info"
                  className="info"
                  onClick={handleWhiteList}
                >
                  {(getSimActLoading ||
                    getSimSafeLoading ||
                    getSimOutOfSafeLoading ||
                    getSimDisconnectLoading ||
                    getSimResumeFromTempDisconnectLoading ||
                    getWhitelistLoading) && (
                    <CircularProgress
                      sx={{
                        width: "20px !important",
                        height: "20px !important",
                        mr: 1,
                      }}
                      color="info"
                    />
                  )}
                  White List
                </SoftButton>
              </Stack>
            </Box>
          </Collapse>
        </Box>
        <CardContent sx={{ py: 0 }}>
          <DataTable
            includeSearchBox={true}
            handleChange={handleSearch}
            onChangeXcl={onChangeXcl}
            onChangePdf={onChangePdf}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            //callbck={handleSort}
            totalPages={totalPages}
            commonFilter={commonFilter}
            isLoading={getSimLoading}
            checkbox={true}
            selectRow={getRow}
          />
        </CardContent>
      </StyledCardShadow>
      {deleteItem !== null && hashId === "#delete" && (
        <StyledModel data={deleteItem} deleteItem={deleteItems} open={openDel}>
          <Box sx={{ px: 2 }}>
            <Typography sx={{ textAlign: "center", my: 2 }}>
              Sim No: {deleteItem.simNumber}
            </Typography>
          </Box>
        </StyledModel>
      )}
      {whiteListItem && (
        <StyledModel
          data={whiteListItem}
          whiteList={true}
          deleteItem={whiteListItems}
        >
          <Box sx={{ px: 2 }}>
            <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
              <StyledFormControlLabel required>
                Select a Number to Whitelist
              </StyledFormControlLabel>
              <StyledDropDown
                size="small"
                placeholder=""
                value={supportType}
                onChange={(event, newType) =>
                  setSupportType(event.target.value)
                }
              >
                <MenuItem value={"support"}>Support Number</MenuItem>
              </StyledDropDown>
            </StyledFormControl>
            <StyledFormControl fullWidth>
              <StyledFormControlLabel required>
                Support Mobile
              </StyledFormControlLabel>
              <StyledTextField
                size="small"
                fullWidth
                disabled={true}
                value={supportNo}
                onChange={(e) => setSupportNo(e.target.value)}
              />
            </StyledFormControl>
          </Box>
        </StyledModel>
      )}
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
};

export default ListSim;
