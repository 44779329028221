import React from "react";
import { Box } from "@mui/system";
import { Typography, Paper, Divider, Grid, CardHeader, CardContent } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import DropDown from "../shared/DropDown";
import InsuranceCard from "./insurance";
import FitnessCard from "./fitness";
import MaintenanceCard from "./maintenance";
import ReportDropDown from "./ReportDropDown";
import { StyledCardShadow, StyledPageLayout, StyledPaperShadow, StyledTableTitle } from "../../theme/StyledEle";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


const ServiceAlert = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };
  React.useEffect(() => {
   
      auditPayload.message = "Alerts > Service Alerts";
      auditPayload.action = "View";
      auditPayload.featureName = "Alerts";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
  }, []);
  

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Alerts" subDiv="Service Alerts"></Breadcrumb>
      <ReportDropDown />
      <Grid container spacing={2} sx={{my:1}}>
          <Grid item lg={4} md={4} sm={12}>
          <StyledCardShadow >
        <CardHeader
          title={<StyledTableTitle>INSURANCE</StyledTableTitle>}
        />
        <CardContent sx={{py:0}}>
        <InsuranceCard />
        </CardContent>
      </StyledCardShadow>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
          <StyledCardShadow >
        <CardHeader
          title={<StyledTableTitle>FITNESS CERTIFICATE</StyledTableTitle>}
        />
        <CardContent sx={{py:0}}>
        <FitnessCard />
        </CardContent>
      </StyledCardShadow>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
          <StyledCardShadow >
        <CardHeader
          title={<StyledTableTitle>MAINTENANCE</StyledTableTitle>}
        />
        <CardContent sx={{py:0}}>
        <MaintenanceCard />
        </CardContent>
      </StyledCardShadow>
          </Grid>
      </Grid>
      
        {/* <Paper elevation={4} sx={{ padding: "1em" }}>
          <Grid
            container
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
            spacing={6}
          >
            <Grid item xs={12} md={4}>
              <Paper elevation={2}>
                <Typography sx={{ color: "white", backgroundColor: "#00b8d4" }}>
                  INSURANCE
                </Typography>
                <InsuranceCard />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={2}>
                <Typography sx={{ color: "white", backgroundColor: "#00b8d4" }}>
                  FITNESS CERTIFICATE
                </Typography>
                <FitnessCard />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={2}>
                <Typography sx={{ color: "white", backgroundColor: "#00b8d4" }}>
                  MAINTENANCE
                </Typography>
                <MaintenanceCard />
              </Paper>
            </Grid>
          </Grid>
        </Paper> */}
    </StyledPageLayout>
  );
};
export default ServiceAlert;
