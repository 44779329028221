import React, { useEffect } from 'react'
import Reseller from './Reseller'
import Dealer from './Dealer';
import Customer from './Customer';
import Fleet from './Fleet';
import { Box } from '@mui/material';

export const Tree = ({userData}) => {
  let userDataLocal = localStorage.getItem('userData');
  let UserType = userDataLocal.roleId;
  switch(UserType){
    case 2: //Organization Admin
    return <Reseller userData={userData}/>
  }
}

function TreeViewNewList({userData, fullHeight, isSearch}) {

  //Treeview Work based this logins
  /* 
  +----+-------------------------+--------+------+
  | ID | ROLE_DESC               | STATUS | TYPE |
  +----+-------------------------+--------+------+
  |  6 | Organization Admin      | Active |    2 |
  |  9 | Customer Admin          | Active |    3 |
  |  20 | Dealer Admin          | Active |    3 |
  */

  //Find Who is Login
  let userDataLocal = JSON.parse(localStorage.getItem('userData'));

  let UserType = userDataLocal?.userOrgType;
  // switch(UserType){
  //   case 6: //Organization Admin
  //   return <Reseller userData={userData}/>
  // }

  // return <Reseller userData={userData}/>
  return (
    // <>
    //   {(() => {
    //   switch(UserType) {
    //     case 6:
    //       return <Reseller userData={userData}/>
    //       //Login customer load fleetview
    //     case 9:
    //       return <Fleet isLogin={true} whoIam={'customer'}/>
    //     default:
    //       return null
    //   }
    // })()}

    // </>
    <Box sx={{maxHeight:fullHeight ? 'calc(100vh - 30vh)' : '350px', overflowY:'scroll',}}>
        <Reseller userData={userData} whoisLogin={UserType} isSearch={isSearch} />
    </Box>
  
  )

}

export default TreeViewNewList