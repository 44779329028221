export const ActionMockData = [
  {
    id: 0,
    value: "Select",
    key: 0,
  },
  {
    id: 1,
    value: "Vin IMEI",
    key: 1,
  },
  {
    id: 2,
    value: "Vin Dealer",
    key: 2,
  },
];
