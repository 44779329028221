import { React, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Divider,
  Stack,
} from "@mui/material/";
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useSaveAdminFilterMutation, useGetAdminFilterMutation } from "../../store/slice/Helpdesk/HelpDeskSlice";
import { SoftButton } from "../../theme/StyledEle";
import { ScaleLoader } from "react-spinners";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";
export default function Alert() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData?.orgId;
  let resellerId = userData?.resellerId;
  let userId = userData?.id;
 
  const [defaultView, setDefaultView] = useState(null);

  const [saveAdminFilter, { data: adminData, isLoading, isSuccess }] =
    useSaveAdminFilterMutation();
  const [
    getAdminFilter,
    {
      data: getAdminData,
      isLoading: getAdminLoadng,
      isSuccess: getAdminSuccess,
    },
  ] = useGetAdminFilterMutation();

  const dispatch = useDispatch();

  const onSumbit = () => {
    let payload = {
      orgId: orgId,
      resellerId: resellerId,
      userId: userId,
    viewType: defaultView!=null?defaultView:0,

    };

    if(defaultView!=null && defaultView!==0)
 {
  saveAdminFilter(payload);
 }   else{
  dispatch(
    snackService(["Please select any filter", 1, true])
  );
 }
 
  };

  useEffect(() => {
    if (isSuccess) {
      getAdminStatus();
      dispatch(
        snackService([
            adminData?.resultMessage,
            adminData?.resultCode,
          isSuccess,
        ])
      );
    }
  }, [isSuccess]);


  useEffect(async () => {
    getAdminStatus();
  }, []);


  const getAdminStatus = async () => {
    let payload = {
      userId: userId,
    };
    const responseData = await getAdminFilter(payload);

    if(responseData?.data?.viewType === 1){
       setDefaultView(1);
    }else if(responseData?.data?.viewType === 2){
        setDefaultView(2);
    }else if(responseData?.data?.viewType === 3){
        setDefaultView(3);
    } 
  };

  const manageFilter = (value) =>{
    if(defaultView!==value){
      setDefaultView(value);
    }else{
      setDefaultView(null);
    }
  }

  const reset = () => {
    setDefaultView(null);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
        HelpDesk View
      </Typography>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          bgcolor: "background.paper",
          padding: "0.8em",
          marginTop: 2,
        }}
      >
        <ListItem>
          <ListItemIcon>
            <FilterNoneIcon />
          </ListItemIcon>
          <ListItemText id="controlled" primary="All" />
          <Checkbox
            edge="end"
           onChange={()=>manageFilter(1)} 
            checked={defaultView === 1}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <FilterListIcon />
          </ListItemIcon>

          <ListItemText id="controlled" primary="Admin Only" />

          <Checkbox
            edge="end"  
           onChange={()=>manageFilter(2)} 
           checked={defaultView === 2}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <FilterListOffIcon />
          </ListItemIcon>
          <ListItemText id="controlled" primary="Me" />

          <Checkbox
            edge="end"
           onChange={()=>manageFilter(3)} 
           checked={defaultView === 3}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </ListItem>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <SoftButton
            color="error"
            className="error"
            size="small"
            onClick={reset}
          >
            Reset
          </SoftButton>
          <SoftButton
            variant="contained"
            color="primary"
            onClick={onSumbit}
            className="success"
            size="small"
            disabled={isLoading}
          >
            {isLoading ? <ScaleLoader height={12} color="#fff" /> : "Save"}
          </SoftButton>
        </Stack>
      </List>
    </Box>
  );
}
