import React from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Input,
  Card,
  CardHeader,
  Typography,
  ButtonGroup,
  Divider,
  CardContent,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  ORGANIZATION_DETAIL,
  ORGANIZATION_ADMIN_DETAIL,
} from "../../constants/accountFormFields";
import TextInput from "../shared/TextInput";
import RadioInput from "../shared/RadioInput";
import SelectInput from "../shared/SelectInput";
import organizationServices from "../../services/organizationServices";
import { useState, useEffect } from "react";
import profileBG from "../../assets/images/profilePattern.png";
import { date } from "yup";
import StyledSnackBar from "../../Utils/StyledSnackBar";

export default function AccountSettings() {
  const shouldIncludeAllOption = true;

  const getOptions = (options) => {
    const allOption = {
      title: "Select",
      value: "0",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };

  const [country, setCountry] = useState(getOptions([]));
  const [states, setStates] = useState(getOptions([]));
  const [cities, setCities] = useState(getOptions([]));
  const [timeZone, setTimeZone] = useState(getOptions([]));
  const [mobCode, setMobCode] = useState(getOptions([]));
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);

  const [org, setOrg] = useState({});

  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData?.orgId;
  let userId = userData?.id;

  useEffect(async () => {
    var jsonObj = {};
    jsonObj.orgId = orgId;

    const responseData = await organizationServices.getOrganization(jsonObj);
    setOrg(responseData.data.data);
    const { data: statedetails } = await organizationServices.loadState();
    setStates(getOptions(statedetails));
    const request = {
      id: responseData.data.data.stateId,
    };
    const { data: result } = await organizationServices.loadCity(request);
    setCities(getOptions(result));
    const { data: countrydetails } = await organizationServices.loadCountry();
    setCountry(getOptions(countrydetails));
    const { data: timezonedetails } = await organizationServices.loadTimeZone();
    setTimeZone(getOptions(timezonedetails));
    const { data: mobiledetails } = await organizationServices.loadMobCode();
    setMobCode(getOptions(mobiledetails));
  }, [setOrg]);

  const theme = useTheme();

  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    m: 1,
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Card sx={{ my: 2 }}>
        <CardHeader
          title={
            <Typography variant="h6" color="primary">
              {title}
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;
    updateOrganization(e, Object.fromEntries(new FormData(target)));
  };

  const updateOrganization = async (e, obj) => {
    e.preventDefault();
    obj.cityId = 3794;
    obj.stateId = 128;
    obj.countryId = 102;
    obj.timeZoneId = 67;
    var request = {};
    request.name = obj.name;
    request.orgId = org !== null ? org.id : 0;
    request.parentOrgId = org !== null ? org.parentOrgId : 0;
    const orgname = await organizationServices.chkOrgName(request);

    if (orgname.data.resultMessage === "Organization Name Already Exists") {
      var obj1 = {};

      obj1.dialogText = "Organization Name Already Exists";

      setDialogContent(obj1);
      setDataStatusConfirm("Failed");
      setOpen(true);

      if (orgname.status === 200) {
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      }
    } else {
      const responseData = await organizationServices.updateOrganization(obj);

      var obj1 = {};

      obj1.dialogText = "Updated Successfully";
      setDialogContent(obj1);
      setDataStatusConfirm("Success");
      setOpen(true);
      if (responseData.status === 200) {
        setOpen(false);
        setTimeout(() => handleCancelClick(), 2000);
      }
    }
  };

  const handleCancelClick = async (e) => {
    e.preventDefault();
    var jsonObj = {};
    jsonObj.orgId = orgId;

    const responseData = await organizationServices.getOrganization(jsonObj);
    setOrg(responseData.data.data);
  };

  const FormFields = ({ fields }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <TextInput
                  label={field.label}
                  name={field.name}
                  defaultValue={org !== null ? org[field.name] : ""}
                  disabledKey={
                    field.name === "email" || field.name === "mobile"
                      ? true
                      : false
                  }
                />
              </Grid>
            );
          } else if (field.type === "select") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <SelectInput
                  name={field.name}
                  label={field.label}
                  options={
                    field.name === "stateId"
                      ? states
                      : field.name === "cityId"
                      ? cities
                      : field.name === "timeZoneId"
                      ? timeZone
                      : field.name === "mobileCountryCode"
                      ? mobCode
                      : field.name === "countryId"
                      ? country
                      : field.options
                  }
                  defaultValue={
                    org !== null ? org[field.name] : field.options[1].value
                  }
                  disabledKey={
                    field.name === "mobileCountryCode" ? false : true
                  }
                />
              </Grid>
            );
          } else if (field.type === "radio") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <RadioInput name={field.name} options={field.options} />
              </Grid>
            );
          }
        })}
      </>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          height: "150px",
          background: `url(${profileBG})`,
          pt: 10,
          px: 15,
          position: "sticky",
          top: "0px",
          zIndex: 1,
        }}
      ></Box>
      <Box
        maxWidth="lg"
        sx={{ margin: "auto", mt: -10, zIndex: 999, position: "relative" }}
      >
        <Typography variant="h6" color="#fff" gutterBottom>
          Account Settings
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ boxShadow: "0px 0px 0px transparent", paddingBottom: "25px" }}
          elevation={0}
        >
          <FormLayout title={"Organization Detail"}>
            <FormFields fields={ORGANIZATION_DETAIL} />
          </FormLayout>
          <FormLayout title={"Organization Admin Detail"}>
            <FormFields fields={ORGANIZATION_ADMIN_DETAIL} />
          </FormLayout>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonGroup variant="contained">
              <Button type="submit">Update</Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </ButtonGroup>
          </Box>
          <input name="createdBy" value={org.createdBy} type="hidden"></input>
          <input name="email" value={org.email} type="hidden"></input>
          <input name="id" value={org.id} type="hidden"></input>
          <input name="orgType" value={org.orgType} type="hidden"></input>
          <input
            name="parentOrgId"
            value={org.parentOrgId}
            type="hidden"
          ></input>
          <input
            name="orgRegistrationNo"
            value={org.orgRegistrationNo}
            type="hidden"
          ></input>
          <input name="orgLogo" value={org.orgLogo} type="hidden"></input>
          <input
            name="parentOrgLogo"
            value={org.parentOrgLogo}
            type="hidden"
          ></input>
          <input name="regionId" value={org.region} type="hidden"></input>
          <input
            name="orgContactName"
            value={org.orgContactName}
            type="hidden"
          ></input>
          <input
            name="vehicleNoDisplay"
            value={org.vehicleNoDisplay}
            type="hidden"
          ></input>
          <input
            name="orgLocation"
            value={org.orgLocation}
            type="hidden"
          ></input>
          <input
            name="mapDisplayOption"
            value={org.mapDisplayOption}
            type="hidden"
          ></input>
          <input
            name="addressDisplayOption"
            value={org.addressDisplayOption}
            type="hidden"
          ></input>
          <input
            name="reverseGeoCodeOption"
            value={org.reverseGeoCodeOption}
            type="hidden"
          ></input>
          <input
            name="vehicleType"
            value={org.vehicleType}
            type="hidden"
          ></input>
          <input name="orgSetId" value={org.orgSetId} type="hidden"></input>
          <input name="modifiedBy" value={userId} type="hidden"></input>
          <input name="modifiedDate" value={new date()} type="hidden"></input>
          <input name="userName" value={org.userName} type="hidden"></input>
          <input name="mobile" value={org.mobile} type="hidden"></input>
        </Box>
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
}
