import { Box, CircularProgress, Grid, IconButton, Paper, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material'
import { theme } from 'antd';
import React from 'react'
import { MdArrowForward } from 'react-icons/md';
import { RiDashboard2Fill, RiDashboard3Line } from 'react-icons/ri';

function StatusCard(props) {
    const { data,showSummary, selectedVehicle, vehicleSummary  } = props;
    const theme = useTheme();
  return (
    <Paper elevation={0} sx={{
        margin:'8px 0px',
        position:'relative',
        boxShadow:`${data?.vehicleId === selectedVehicle ? '0px 2px 5px rgba(0,0,0,.1) !important' : '0px 0px 25px rgba(0,0,0,.12) !important'} `,
        cursor:'pointer',
        transition:'.8s',
        border:`1px solid ${data?.vehicleId === selectedVehicle ? data?.vehicleStatus === 'Stop' || data?.vehicleStatus === '0' ? alpha(theme.palette.error.main, .9) : 
        data?.vehicleStatus === 'Charging' || data?.vehicleStatus === '6'? alpha(theme.palette.warning.main, .9) : 
        data?.vehicleStatus === 'Offline' || data?.vehicleStatus === '4'? alpha(theme.palette.grey[700], .9) : data?.vehicleStatus === 'Moving' || data?.vehicleStatus === '1'? alpha(theme.palette.success.main, .9) : alpha(theme.palette.grey[700], .9) : 'white'}`,
        '&:hover':{
            boxShadow:'0px 0px 25px rgba(0,0,0,.12) !important',
            '&:before':{
                width:'5px',
            }
        },
        '&:before':{
            position:'absolute',
            top:'50%',
            left:'-1px',
            content:"''",
            width:'4px',
            height:'calc(100% - 60px)',
            transform:'translateY(-50%)',
            background: data?.vehicleStatus === 'Stop' || data?.vehicleStatus === '0' ? alpha(theme.palette.error.main, .9) : 
                data?.vehicleStatus === 'Charging' || data?.vehicleStatus === '6'? alpha(theme.palette.warning.main, .9) : 
                data?.vehicleStatus === 'Offline' || data?.vehicleStatus === '4'? alpha(theme.palette.grey[700], .9) : data?.vehicleStatus === 'Moving' || data?.vehicleStatus === '1'? alpha(theme.palette.success.main, .9) : alpha(theme.palette.grey[700], .9),
            borderRadius:'0px 10px 10px 0px',
            transition:'.2s'
        }
    }}
    
    >
        <Box sx={{padding:'10px 10px 0px 10px',}}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                <Box onClick={() => showSummary(data?.vehicleId, data.orgId)} sx={{flexGrow:1}}> 
                    <Typography variant='subtitle2' sx={{fontWeight:600}}>{data?.vehicleNo}</Typography>
                    <Typography variant='body2' sx={{fontSize:'14px'}} color={'grey.600'}>{data?.customerName}</Typography>
                </Box>
                <Box>
                    {
                        data?.vehicleId === selectedVehicle && 
                        <Tooltip title={'View Summary'} className='summary_icon'>
                            <IconButton size='small' arrow color='info' onClick={(event) => vehicleSummary(event)}>
                                <MdArrowForward /> 
                            </IconButton>
                        </Tooltip>
                    }
                    
                </Box>
            </Stack>
        </Box>
        <Box sx={{mt:.5, padding:'0px 15px 10px 15px' ,}} onClick={() => showSummary(data?.vehicleId, data.orgId)}>
            <Grid container spacing={1}>
                <Grid item lg={5}>
                    <Typography  variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>Last Connected</Typography>
                    <Typography variant='subtitle2'>{data?.lastConnected}</Typography>
                </Grid>
                <Grid item lg={5}>
                    <Typography  variant='body2' color={'text.secondary'} sx={{fontSize:'12px'}}>IMEI</Typography>
                    <Typography variant='subtitle2'>{data?.imei}</Typography>
                </Grid>
                <Grid item lg={2}>
                    <Box sx={{position:'relative', display:'inline-flex'}}>
                        <CircularProgress variant='determinate' {...props} value={parseInt(data.soc)} 
                        color={
                            parseInt(data.soc) <= 20 ? 'error' : parseInt(data.soc) <= 60 ? 'warning' : 'success'
                        }
                        />
                        <Box sx={{position:'absolute', top:'0px', left:'0px', right:'0px', display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
                            <Tooltip title={'SOC'} arrow>
                            <Typography variant='caption' component={'div'} sx={{fontWeight:'500', fontSize:'10px'}}>
                                {data.soc}%
                            </Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Paper>
  )
}

export default StatusCard