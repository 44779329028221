import React, { useEffect, useState } from 'react'
import { RouteListbar, StyledSwitch, SubCategoryListbar } from '../../../theme/StyledEle'
import { Checkbox, Fab, Icon, List, ListItem, ListItemIcon, ListItemText, ToggleButton, Paper, Button, Box, Stack, Tooltip, Typography, Divider, CardHeader, CardContent, Grid, Card, alpha, DialogActions,Dialog, DialogTitle, DialogContent,IconButton, Menu} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import { useGetEditRouteTripMutation, useGetRouteListForMapMutation } from '../../../store/slice/RouteTripSlice/RouteTripSlice';
import { routeTrips } from '../../../store/slice/ControlsSlice';
import { PiCaretLeftBold } from 'react-icons/pi';
import { FaRoute } from "react-icons/fa6";
import Scrollbars from "react-custom-scrollbars-2";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import reportServices from "../../../services/reportServices";
import {
  useGetVehicleDetailsMutation,
} from "../../../store/slice/ApiSlice";
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  ExportCsvoptions,ExportPdfoptions
} from "../../../theme/StyledEle";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuditLogMutation } from "../../../store/slice/ApiSlice";
import html2pdf from 'html2pdf.js';

function SubCategory(props) {
  const { showTrails, handleTrails, showCatgryVehicleStatus,categoryOrgId,categoryVehicleId, open, anchorEl } = props;
  const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
  const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);
  const [isToday, setIsToday] = useState(true);
  const date = useSelector((state) => state.controls.treeview.date);
  const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);
  let [address, setAddress] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [value, setValue] = React.useState("1");
  const [auditLog] = useAuditLogMutation();
  const [openDialog, setOpenDailag] = useState(false)
  const [openpopup, setOpenpPopup] = useState(false)
  //Route call
  const [getrouteList, { data: routeList, isLoading: routeListLoading, isSuccess: routeListSuccess }] = useGetRouteListForMapMutation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const GetRouetList = async () => {
    let getRoutePayload = {
      'vehicleId': selectedVehicle
    }
    const RouteTrips = await getrouteList(getRoutePayload);
  }
  const [tempVehicleTrailCommonData,setTempVehicleTrailCommonData]=useState(vehicleTrails?.commonData);
  const [
    getVehicleDetails,
    {
      data: getDetails,
      isLoading: getDetailsLoading,
      isSuccess: getDetailsSuccess,
    },
  ] = useGetVehicleDetailsMutation();

  useEffect(() => {
    if (date !== null) {
      if(date[0] === moment().format("YYYY-MM-DD 00:00:00") &&
        moment(date[1]).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ) {      
        setTempVehicleTrailCommonData(vehicleTrails?.commonData);
        setIsToday(true);        
      } else {
        setIsToday(false);
      }
    }
  }, [date]);


  const getAddress = () => {
    setAddress(null);
    var requestN = {};
    requestN.latLng =
      vehicleTrails?.commonData[0]?.lat + "," + vehicleTrails?.commonData[0]?.lng;
    return reportServices
      .getGeocodeAddress(requestN)
      .then((resN) => {
        setAddress(resN.data);
      })
      .catch((err1) => {
        console.log(err1);
      });
  };

  useEffect(() => {
    if (vehicleTrails !== null) {
      getAddress();
    }
  }, [vehicleTrails]);


  useEffect(() => {
    if (selectedVehicle !== null && selectedVehicle !== undefined) {
      GetRouetList();
    }
  }, [selectedVehicle]);

  const [getSingleRouteTrip] = useGetEditRouteTripMutation();
  const [routeId, setRouteId] = useState(null);

  const getRouteSelect = (id) => {
    if (id === routeId) {
      setRouteId(null);
      dispatch(routeTrips(null));
    } else {
      setRouteId(id);
      getRouteTrips(id);
    }
  }


  useEffect(() => {
    setRouteId(null); // Change date or Trails time reset check box
  }, [date, trailsTime, selectedVehicle])

  const getRouteTrips = async (id) => {
    const singleRouteData = await getSingleRouteTrip({ 'routeId': id });
    dispatch(routeTrips(singleRouteData.data.data));
  }
  const [show, setShow] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabHeaderStyle = {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    color: theme.text.disabled,
    borderRadius: 1,
    //margin: "0px 10px",
    "&.Mui-selected": {
      color: theme.palette.text.primary.main,
      background: theme.palette.action.selected,
    },
  };

  const onChangeXcl = async () => {
      try {
       var url =
       process.env.REACT_APP_API_BASE_URL +
       "ManualCanDataReport/categoryVehicledataForMontra.csv?startDate=" +
       date[0] +
       "&endDate=" +
       date[1] +
       "&orderDir=" +
       'desc' +
       "&orgId=" +
       categoryOrgId +
       "&status="+"9"+
      // vehicleTrails?.commonData[0]?.propVehicleStatus +
       "&resellerId=" +userData.resellerId+
       "&vehicleId=" +
       categoryVehicleId;
       window.open(url);
       let auditPayload={};
       auditPayload.message = 'MapView > VehicleSummary > CSV';
       auditPayload.action = 'View';
       auditPayload.featureName = 'VehicleSummary';
       auditPayload.customerName = 'All';
       auditPayload.fleetName = 'All';
       auditPayload.result = 'Success';
       auditPayload.access = 'Portal';
       auditPayload.severity = 'INFO';
       
       auditLog(auditPayload);
  
        fetch(url, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          },
        }) // FETCH BLOB FROM IT
          .then((response) => {
            response.blob();
          })
          .then((blob) => {})
          .catch((err) => {});
          
      } catch (error) {}
    };
    
  const onChangePdf = async () => {
    handleOpenPopup();
    try {
      
    } catch (error) { }
  };

  const downloadPDF=()=> {
    const element = document.getElementById('colorComponent');
    html2pdf().from(element).save();
  }

  const handleCloseDialog = () => {
    setOpenDailag(false)
  }
  const handleOpenDialog = () => {
    setOpenDailag(!openDialog)
  }
  const handleClosePopup = () => {
    setOpenpPopup(false)
  }
  const handleOpenPopup = () => {
    setOpenpPopup(true)
  }
  
  return (
      <SubCategoryListbar>
        <Box>
          {/* <div style={{ position: "absolute", right: "12px", zIndex: "99", cursor: "pointer" }}>
            <span onClick={() => { props.closeSubCategoryfn() }} ><CloseIcon /></span>
          </div> */}
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              aria-label="Tabs"
              sx={{
                my: 0.4,
                px: 0.2,
                borderBottom: `.8px solid ${theme.palette.divider}`,
                ".MuiTabs-indicator": {
                  display: "none",
                }
              }}
            >
              <Tab label="Vehicle Details" value="1" sx={tabHeaderStyle}>
              </Tab>
              <Tab label="Vehicle Summary" value="2" sx={tabHeaderStyle} />
            </TabList>

            <TabPanel value="1" sx={{ height: "calc(100vh - 20vh)", p: 0 }}>
              <Scrollbars autoHeight
                autoHeightMin={0}
                autoHeightMax={640}  >
                <Card sx={{ marginBottom: "7px", padding: "5px" }} style={{ margin: "0px" }} >
                  <CardHeader sx={{ marginLeft: "10px", padding: "0px" }}></CardHeader>
                  <CardContent sx={{ marginLeft: "10px", marginTop: "8px", padding: "0px" }} style={{ paddingBottom: "6px", padding: "0px" }}>
                    
                    {isToday===true &&
                    <>
                    <Grid container lg={12} sx={{ fontSize: "20px" }} style={{
                      margin: '0',
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          VIN
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          Last connect Time
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.vinNumber !== null
                            ?vehicleTrails?.commonData[0]?.vinNumber:(vehicleTrails?.commonData[0]?.vin!=null?vehicleTrails?.commonData[0]?.vin:"-")
                            }
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography > 
                          {vehicleTrails !== null
                            ? vehicleTrails?.commonData[0]?.lastDate !== null &&
                            vehicleTrails?.commonData[0]?.lastDate
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Status
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography pt={0.7}>
                          Speed (km/h)
                        </Typography >
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          {vehicleTrails?.commonData[0]
                            ?.propVehicleStatus!==null?vehicleTrails?.commonData[0]
                            ?.propVehicleStatus:"-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.speed !== null
                            ? vehicleTrails?.commonData[0]?.speed
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Odometer (km)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>

                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.odometerReading !==
                            null
                            ? vehicleTrails?.commonData[0]?.odometerReading
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>

                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>

                      <Grid item xs={12} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Address
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ textAlign: "left" }}>
                        <Typography >
                            {
                            <a
                              target="_blank"
                              className="latlink"
                              href={`https://www.google.com/maps/search/?api=1&query=${vehicleTrails?.commonData[0]?.lat +
                                "," +
                                vehicleTrails?.commonData[0]?.lng
                                }`}
                            >
                              {vehicleTrails?.commonData[0]?.lng!==null?address:"-"}
                            </a>
                          }
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={12} sx={{ textAlign: "left" }}>
                        <Typography pt={1.4}>
                          Battery Details
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Battery SOC (%)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography pt={0.7}>
                          Battery Voltage
                        </Typography >
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.soc !== null
                            ? vehicleTrails?.commonData[0]?.soc
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.batteryvolt !== null
                            ? vehicleTrails?.commonData[0]?.batteryvolt
                            : ""}
                        </Typography>
                      </Grid>

                    </Grid>

                    <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b',
                      paddingBottom: "3px"
                    }}>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Charging Status
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Battery Temperature (ºC)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.chargecnntcd !==
                            null
                            ? vehicleTrails?.commonData[0]?.chargecnntcd ===
                              "0"
                              ? "No"
                              : "Yes"
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.batterytemp !== null
                            ? vehicleTrails?.commonData[0]?.batterytemp
                            : ""}
                        </Typography>
                      </Grid>

                    </Grid>
                    </>

                  }


                   {isToday!==true &&
             <>
             <Grid container lg={12} sx={{ fontSize: "20px" }} style={{
                      margin: '0',
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          VIN
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          Last connect Time
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.vinNumber !== null
                            ?vehicleTrails?.commonData[0]?.vinNumber:(vehicleTrails?.commonData[0]?.vin!=null?vehicleTrails?.commonData[0]?.vin:"-")
                            }
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >                        
                        {tempVehicleTrailCommonData !== null
                            ? tempVehicleTrailCommonData[0]?.lastDate !== null &&
                            tempVehicleTrailCommonData[0]?.lastDate
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
             <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
               fontFamily: 'Noto Sans SC,sans-serif',
               fontWeight: '500',
               color: '#28282b'
             }}>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>
                   Status
                 </Typography>
               </Grid>
               <Grid item xs={6}>
                 <Typography pt={0.7}>
                   Speed (km/h)
                 </Typography >
               </Grid>

               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography >
                   {tempVehicleTrailCommonData[0]
                     ?.propVehicleStatus!==null?tempVehicleTrailCommonData[0]
                     ?.propVehicleStatus:"-"}
                 </Typography>
               </Grid>
               <Grid item xs={6}>
                 <Typography >
                   {tempVehicleTrailCommonData[0]?.speed !== null
                     ?tempVehicleTrailCommonData[0]?.speed
                     : "-"}
                 </Typography>
               </Grid>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>
                   Odometer (km)
                 </Typography>
               </Grid>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>

                 </Typography>
               </Grid>
               <Grid item xs={6}>
                 <Typography >
                   {tempVehicleTrailCommonData[0]?.odometerReading !==
                     null
                     ? tempVehicleTrailCommonData[0]?.odometerReading
                     : "-"}
                 </Typography>
               </Grid>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>

                 </Typography>
               </Grid>
             </Grid>

             <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
               fontFamily: 'Noto Sans SC,sans-serif',
               fontWeight: '500',
               color: '#28282b'
             }}>

               <Grid item xs={12} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>
                   Address
                 </Typography>
               </Grid>
               <Grid item xs={12} sx={{ textAlign: "left" }}>
                 <Typography >
                     {
                     <a
                       target="_blank"
                       className="latlink"
                       href={`https://www.google.com/maps/search/?api=1&query=${vehicleTrails?.commonData[0]?.lat +
                         "," +
                         tempVehicleTrailCommonData[0]?.lng
                         }`}
                     >
                       {tempVehicleTrailCommonData[0]?.lng!==null?address:"-"}
                     </a>
                   }
                 </Typography>
               </Grid>
             </Grid>

             <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
               fontFamily: 'Noto Sans SC,sans-serif',
               fontWeight: '500',
               color: '#28282b'
             }}>
               <Grid item xs={12} sx={{ textAlign: "left" }}>
                 <Typography pt={1.4}>
                   Battery Details
                 </Typography>
               </Grid>
             </Grid>
             <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
               fontFamily: 'Noto Sans SC,sans-serif',
               fontWeight: '500',
               color: '#28282b'
             }}>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>
                   Battery SOC (%)
                 </Typography>
               </Grid>
               <Grid item xs={6}>
                 <Typography pt={0.7}>
                   Battery Voltage
                 </Typography >
               </Grid>

               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography >
                   {tempVehicleTrailCommonData[0]?.soc !== null
                     ? tempVehicleTrailCommonData[0]?.soc
                     : ""}
                 </Typography>
               </Grid>
               <Grid item xs={6}>
                 <Typography >
                   {tempVehicleTrailCommonData[0]?.batteryvolt !== null
                     ? tempVehicleTrailCommonData[0]?.batteryvolt
                     : ""}
                 </Typography>
               </Grid>

             </Grid>

             <Grid container lg={12} sx={{ fontSize: "20px", }} style={{
               fontFamily: 'Noto Sans SC,sans-serif',
               fontWeight: '500',
               color: '#28282b',
               paddingBottom: "3px"
             }}>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>
                   Charging Status
                 </Typography>
               </Grid>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography pt={0.7}>
                   Battery Temperature (ºC)
                 </Typography>
               </Grid>
               <Grid item xs={6}>
                 <Typography >
                   {tempVehicleTrailCommonData[0]?.chargecnntcd !==
                     null
                     ? tempVehicleTrailCommonData[0]?.chargecnntcd ===
                       "0"
                       ? "No"
                       : "Yes"
                     : ""}
                 </Typography>
               </Grid>
               <Grid item xs={6} sx={{ textAlign: "left" }}>
                 <Typography >
                   {tempVehicleTrailCommonData[0]?.batterytemp !== null
                     ? tempVehicleTrailCommonData[0]?.batterytemp
                     : ""}
                 </Typography>
               </Grid>

             </Grid>
             </>
                }
                  </CardContent>
                </Card>

              </Scrollbars>
            </TabPanel>
            <TabPanel value="2" sx={{ height: "calc(100vh - 23vh)", p: 0 }}>
              <Scrollbars autoHeight
                autoHeightMin={0}
                autoHeightMax={590}>
                 <Grid container lg={12} sx={{ fontSize: "20px" }} style={{
                      margin: '0',
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b',
                      p:1
                    }}>
                      <Grid item xs={7} sx={{ textAlign: "left" }} >
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "center"}}>
                        <Typography sx={{paddingTop:"5px",marginRight:"3px" }}>
                        <ExportCsvoptions onChangeXcl={onChangeXcl} onChangePdf={onChangePdf} />
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ textAlign: "center"}}>
                        <Typography sx={{paddingTop:"5px",marginRight:"5px" }}>                    
                        <ExportPdfoptions onChangeXcl={onChangeXcl} onChangePdf={onChangePdf} />
                        </Typography>
                      </Grid>
                    </Grid> 
                <Accordion defaultExpanded="true" style={{marginTop:"6px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{maxHeight: '32px',minHeight:'10px'}}
          style={{maxHeight: '32px',minHeight:'10px'}}
          >
          <Typography variant='h6'>Vehicle Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <Grid container lg={12} sx={{ fontSize: "20px" }} style={{
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          Start Date
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          End Date
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography >
                          { vehicleTrails?.commonData[0]?.startDate !== null
                            ? vehicleTrails?.commonData[0]?.startDate
                            : "-" }
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {vehicleTrails?.commonData[0]?.endDate !== null
                            ? vehicleTrails?.commonData[0]?.endDate
                            : "-" }
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Start Location
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          End Location
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          
                          <a
                            target="_blank"
                            className="latlink"
                            href={`https://www.google.com/maps/search/?api=1&query=${vehicleTrails?.vehicleTrails[0]?.lat},${vehicleTrails?.vehicleTrails[0]?.lng}`}
                          >
                            {vehicleTrails?.vehicleTrails[0]?.lat} {(vehicleTrails?.vehicleTrails[0]?.lat!==undefined && vehicleTrails?.vehicleTrails[0]?.lat!==undefined  && vehicleTrails?.vehicleTrails[0]?.lat!==null)?",":"-"}
                            {vehicleTrails?.vehicleTrails[0]?.lng}
                          </a>
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          <a
                            target="_blank"
                            className="latlink"
                            href={`https://www.google.com/maps/search/?api=1&query=${vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lat
                              },${vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lng
                              }`}
                          >
                            {
                              vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lat
                            }
                            {((vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lat)!==undefined && vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lat!==null)?",":"-"}
                            {
                              vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lng
                            }
                          </a>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                        Distance Traveled (km)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Average Speed (km/h)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>  
                        {(vehicleTrails?.summaryData[0]?.distanceTraveled !== null && vehicleTrails?.summaryData[0]?.distanceTraveled !== undefined)
                            ? vehicleTrails?.summaryData[0]?.distanceTraveled
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.avgSpeed !== null && vehicleTrails?.summaryData[0]?.avgSpeed !== undefined)
                            ? vehicleTrails?.summaryData[0]?.avgSpeed
                            : "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                        Max Speed (km/h)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Move Hours
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>  
                        {(vehicleTrails?.summaryData[0]?.maxSpeed !== null && vehicleTrails?.summaryData[0]?.maxSpeed !== undefined)
                            ? vehicleTrails?.summaryData[0]?.maxSpeed
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.moveMins !== null && vehicleTrails?.summaryData[0]?.moveMins !== undefined)
                            ? vehicleTrails?.summaryData[0].moveMins
                            : "-"}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                        Stop Hours
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                        Offline Hrs  
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography >
                        {(vehicleTrails?.summaryData[0]?.stopHours !== null && vehicleTrails?.summaryData[0]?.stopHours !== undefined)
                            ? vehicleTrails?.summaryData[0]?.stopHours
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                        {(vehicleTrails?.summaryData[0]?.offlineHours !== null && vehicleTrails?.summaryData[0]?.stopHours !== undefined)
                            ? vehicleTrails?.summaryData[0]?.offlineHours
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                        Start Odometer (km)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          End Odometer (km)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                        {(vehicleTrails?.summaryData[0]?.startOdometer !== null && vehicleTrails?.summaryData[0]?.startOdometer !== undefined)
                            ? vehicleTrails?.summaryData[0]?.startOdometer
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.endOdometer !== null && vehicleTrails?.summaryData[0]?.endOdometer !== undefined)
                            ? vehicleTrails?.summaryData[0]?.endOdometer
                            : "-"}
                        </Typography>
                      </Grid>

                    </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded="true">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{maxHeight: '30px',minHeight:'10px'}}
          style={{maxHeight: '30px',minHeight:'10px'}}
          >
          <Typography  variant='h6'>Utilization Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container lg={12} sx={{ fontSize: "20px" }} style={{
                      margin: '0',
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          Energy Consumed
                          </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                        Energy Efficiency
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                        {(vehicleTrails?.summaryData[0]?.energyConsumed !== null && vehicleTrails?.summaryData[0]?.energyConsumed !== undefined)
                            ? vehicleTrails?.summaryData[0]?.energyConsumed
                            : "-"}                        
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.batteryEfficiency !== null && vehicleTrails?.summaryData[0]?.batteryEfficiency !== undefined )
                            ? vehicleTrails?.summaryData[0]?.batteryEfficiency
                            : "-"}  
                        </Typography>
                      </Grid>
                                        
                      </Grid>


        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded="true">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
             sx={{maxHeight: '30px',minHeight:'10px'}}
          style={{maxHeight: '30px',minHeight:'10px'}}
        >
          <Typography  variant='h6'>Charging Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container lg={12} sx={{ fontSize: "20px" }} style={{
                      margin: '0',
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                  { (vehicleTrails?.summaryData[0]?.totalChargingCountPerDay === null || vehicleTrails?.summaryData[0]?.totalChargingCountPerDay === 0 || vehicleTrails?.summaryData[0]?.totalChargingCountPerDay === undefined)  && 
                  <>
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                                          <Typography >
                                            No Charge
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: "left" }}>
                                          <Typography >
                                            </Typography>
                                        </Grid>
                                        </>
                      }
            { (vehicleTrails?.summaryData[0]?.totalChargingCountPerDay !== null && vehicleTrails?.summaryData[0]?.totalChargingCountPerDay !== 0 && vehicleTrails?.summaryData[0]?.totalChargingCountPerDay !== undefined)  && 
                  <>                 
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                          Count
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                          Duration
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {(vehicleTrails?.summaryData[0]?.totalChargingCountPerDay !== null && vehicleTrails?.summaryData[0]?.totalChargingCountPerDay !== undefined)
                            ? vehicleTrails?.summaryData[0]?.totalChargingCountPerDay
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {(vehicleTrails?.summaryData[0]?.calculatedChargingMins !== null && vehicleTrails?.summaryData[0]?.calculatedChargingMins !== undefined)
                            ? vehicleTrails?.summaryData[0]?.calculatedChargingMins
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                           Start soc (%)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                           End soc (%)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.startSoc !== null && vehicleTrails?.summaryData[0]?.startSoc !== undefined)
                            ? vehicleTrails?.summaryData[0]?.startSoc
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.endSoc !== null && vehicleTrails?.summaryData[0]?.startSoc !== undefined)
                            ? vehicleTrails?.summaryData[0]?.endSoc
                            : "-"}
                        </Typography>
                      </Grid>
                      </> }
                     </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded="true">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{maxHeight: '30px',minHeight:'10px'}}
          style={{maxHeight: '30px',minHeight:'10px'}}
          >
          <Typography  variant='h6'>Driver Behaviour</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container lg={12} sx={{ fontSize: "20px" }} style={{
                      margin: '0',
                      fontFamily: 'Noto Sans SC,sans-serif',
                      fontWeight: '500',
                      color: '#28282b'
                    }}>
                      <Grid item xs={12} sx={{ textAlign: "left" }}>
                        <Typography style={{fontSize:"14px"}}>
                          Over Speeding
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                           Count
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                           Duration (hh:mm)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                        {(vehicleTrails?.summaryData[0]?.overSpeedCount !== null && vehicleTrails?.summaryData[0]?.overSpeedCount !== undefined)
                            ? vehicleTrails?.summaryData[0]?.overSpeedCount
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.overSpeedDuration !== null && vehicleTrails?.summaryData[0]?.overSpeedDuration !== undefined)
                            ? vehicleTrails?.summaryData[0]?.overSpeedDuration
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                           Max speed
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                          
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography>
                        {(vehicleTrails?.summaryData[0]?.maxSpeedMiles !== null && vehicleTrails?.summaryData[0]?.maxSpeedMiles !== undefined) 
                            ? vehicleTrails?.summaryData[0]?.maxSpeedMiles
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                      
                        </Typography>
                      </Grid>                      
                      <Grid item xs={12} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7} style={{fontSize:"14px"}}>
                          Harsh Usage
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Accelerating
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Braking
                        </Typography>
                      </Grid>
                    <Grid item xs={6}>
                        <Typography >
                          {(vehicleTrails?.summaryData[0]?.harshAccelerationCount !== null && vehicleTrails?.summaryData[0]?.harshAccelerationCount !== undefined)
                            ? vehicleTrails?.summaryData[0]?.harshAccelerationCount
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography >
                          {(vehicleTrails?.summaryData[0]?.harshbrakeCount !== null && vehicleTrails?.summaryData[0]?.harshbrakeCount !== undefined)
                            ? vehicleTrails?.summaryData[0]?.harshbrakeCount
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                          Cornering
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography pt={0.7}>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                        {(vehicleTrails?.summaryData[0]?.harshAlertCount !== null && vehicleTrails?.summaryData[0]?.harshAlertCount !== undefined)
                            ? vehicleTrails?.summaryData[0]?.harshAlertCount
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <Typography >
                        </Typography>
                      </Grid>
                    </Grid>
              </AccordionDetails>
            </Accordion>
             </Scrollbars>
            </TabPanel>
          </TabContext>
        </Box>
        <Dialog open={openpopup} >
            <DialogTitle sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
            <div>
            <Typography>Map Trip View</Typography> </div>
            <div>
            <IconButton sx={{ borderRadius: "5px", border: "1px solid #dbdbdb" }} disabled={vehicleTrails === null} onClick={handleClosePopup} >
            <IoMdClose fontSize="small"/>
            </IconButton>
            </div>
            </DialogTitle>
            <DialogContent style={{width:"580px"}} id='colorComponent'>
            
            <h4 style={{backgroundColor:"#e7e7e7",marginTop:"10px"}}>Trip Details</h4>

            <Grid>
            <div id="map" style={{ width: "100%", height: "100%" }}>
            <iframe title="Google Maps" width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248741.36628563556!2d-0.24167937121824613!3d51.5285582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604c46bfad20d%3A0x281d84d13269e1a5!2sLondon%2C%20UK!5e0!3m2!1sen!2suk!4v1644330083691!5m2!1sen!2suk"></iframe>
            </div></Grid> 
            <Grid>
            <Box>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Start Date</Typography>
            <Typography variant='body2'>{vehicleTrails?.commonData[0]?.startDate !== null
                            ? vehicleTrails?.commonData[0]?.startDate
                            : "-"}</Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>End Date</Typography>
            <Typography variant='body2'>{vehicleTrails?.commonData[0]?.endDate !== null
                            ? vehicleTrails?.commonData[0]?.endDate
                            : "-"}</Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Distance Covered</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.distanceTraveled !== null
                            ? vehicleTrails?.summaryData[0]?.distanceTraveled
                            : "-"} </Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Total Duration</Typography>
            <Typography variant='body2'> {vehicleTrails?.summaryData[0]?.duration !== null
                            ? vehicleTrails?.summaryData[0]?.duration
                            : "-"}</Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Maximum Speed</Typography>
            <Typography variant='body2'> {vehicleTrails?.summaryData[0]?.maxSpeed !== null
                            ? vehicleTrails?.summaryData[0]?.maxSpeed
                            : "-"} </Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Start Location</Typography>
            <Typography variant='body2'>{vehicleTrails?.vehicleTrails[0]?.lat},
                            {vehicleTrails?.vehicleTrails[0]?.lng}</Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>End Location</Typography>
            <Typography variant='body2'>{
                              vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lat
                            }
                            ,
                            {
                              vehicleTrails?.vehicleTrails[
                                vehicleTrails?.vehicleTrails?.length - 1
                              ]?.lng
                            }</Typography>
            </div>
            </Box>
            </Grid>
            <Grid>
            <Box>
            <h4 style={{backgroundColor:"#e7e7e7",marginTop:"10px"}}>Battery Details</h4>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Start SOC (%)</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.startSoc !== null
                            ? vehicleTrails?.summaryData[0]?.startSoc
                            : "-"}</Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>End SOC (%)</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.endSoc !== null
                            ? vehicleTrails?.summaryData[0]?.endSoc
                            : "-"}</Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Charge Instances</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.chargeCount !== null
                            ? vehicleTrails?.summaryData[0]?.chargeCount
                            : "-"}</Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Engery Efficiency</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.batteryEfficiency !== null
                            ? vehicleTrails?.summaryData[0]?.batteryEfficiency
                            : "-"} </Typography>
            </div>            
            </Box>
            </Grid>
            <Grid>
            <Box>
            <h4 style={{backgroundColor:"#e7e7e7",marginTop:"10px"}}>Driver Behaviour</h4>
            <Grid container style={{display:"flex",flexDirection:"column"}}>
            <Grid item sx={4}>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Harsh Acceleration</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.harshAccelerationCount !== null
                            ? vehicleTrails?.summaryData[0]?.harshAccelerationCount
                            : "-"}</Typography>
            </div>
            </Grid>
            <Grid item sx={4}>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Harsh Braking</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.harshbrakeCount !== null
                            ? vehicleTrails?.summaryData[0]?.harshbrakeCount
                            : "-"} </Typography>
            </div>
            </Grid>
            <Grid item sx={4}>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>Harsh Cornering</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.harshAlertCount !== null
                            ? vehicleTrails?.summaryData[0]?.harshAlertCount
                            : "-"}</Typography>
            </div>
            </Grid>
            <Grid item sx={4}>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>OverSpeed Distance</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.overSpeedDistance !== null
                            ? vehicleTrails?.summaryData[0]?.overSpeedDistance
                            : "-"}</Typography>
            </div>
            </Grid>
            <Grid item sx={4}>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>OverSpeed Count</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.overSpeedCount !== null
                            ? vehicleTrails?.summaryData[0]?.overSpeedCount
                            : "-"}</Typography>
            </div>
            </Grid>
            <Grid item sx={4}>
            <div style={{display:"flex",flexDirection:"row",paddingTop:"5px",paddingBottom:"5px"}}>
            <Typography sx={{width:"140px"}} variant='body2'>OverSpeed Duration</Typography>
            <Typography variant='body2'>{vehicleTrails?.summaryData[0]?.overSpeedDuration !== null
                            ? vehicleTrails?.summaryData[0]?.overSpeedDuration
                            : "-"} </Typography>
            </div>
            </Grid>
            </Grid>
            </Box>
            </Grid>            
            </DialogContent>
            <DialogActions>
            <Button onClick={downloadPDF} style={{backgroundColor:"#dfdfdf"}} variant='standard'>download as pdf</Button>
            </DialogActions>
        </Dialog>
            
      </SubCategoryListbar> 
  )
}

export default SubCategory