import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Chip } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import VDRDropDown from "./VDRDropDown";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { Button } from "@mui/material";
import logServices from "../../services/logService";



import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useVehicleData2KGMutation } from "../../store/slice/ReportSlice/ReportSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";



  



const ManualCanDataReportKG = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const theme = useTheme();
  
  const [auditLog]= useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId:orgId,
    userId:userId, //User ID
    orgName:orgName, //Org Name
    userName:userName,
  }

  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState("");

  const text = "Note:This Report gets the details of battery in a Fleet based on date selection.";


  useEffect(async () => {
    auditPayload.message = 'Reports > VehicleData';
    auditPayload.action = 'View';
    auditPayload.featureName = 'VehicleData';
    auditPayload.customerName = 'All';
    auditPayload.fleetName = "All";
    auditPayload.result = 'Success';
    auditPayload.access = 'Portal';
    auditPayload.severity = 'INFO';
    auditLog(auditPayload);
  
  }, []);







//   const callbackLoadFn = async (
//       selResellerId,
//       selRegionId,
//       selDealerId,
//       selCustomerId,
//       selFleetId,
//       selVehicleId,
//       selimei
//   ) => {
//     loadrequest = assignedval;
//     loadrequest.regionId = selRegionId;
//     loadrequest.resellerId = selResellerId;
//     //loadrequest.resellerId = 5;
//     loadrequest.dealerId = selDealerId;
//     loadrequest.customerId = selCustomerId;
//     loadrequest.orgId = selFleetId; // fleet
//     loadrequest.vehicleId = selVehicleId;
//     const fromDate =
//       format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
//     const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";
//     loadrequest.startDate = fromDate;
//     loadrequest.endDate = toDate;
//     loadrequest.orderDir = "";
//     loadrequest.search = "";
//     loadrequest.start = newPage;
//     loadrequest.length = rowsPerPage;
//     loadrequest.status = "9";


   

//     setAssignedval(loadrequest);
   
//     if(loadrequest.vehicleId!=null && loadrequest.vehicleId!=-1 && loadrequest.vehicleId!=0){
//     const responseData = (await ReportServices.ManualCanDataReportKG(loadrequest))
//       .data;
//     setResponseData([]);
// console.log(responseData);
//     setResponseData(responseData.data.content);
//     setTotalElements(5);
//     setTotalPages(0);
//     setTotalElements(responseData.data.totalElements);
//     setTotalPages(responseData.data.totalPages);
//     // fetchVehicleDataDetails();
//     }else {
//       setLoading("Success");
//       setTotalElements(0);
//       setTotalPages(0)
//     }
//   };



  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },
      {
        Header: "Vin Number",
        accessor: "vinNo",
      },
      {
        Header: "Vehicle Status",
        accessor: "gpsMoveStatus",
        Cell:({row}) => {
          // console.log('row', row.original.strgpsMoveStatus);
          if(row.original.gpsMoveStatus !== '-' ){
              return <Chip label={row.original.gpsMoveStatus} size="small" color={row.original.gpsMoveStatus === 'Moving' ? 'success' : row.original.gpsMoveStatus === 'Charging' ? 'warning' : 'error'}/>
          }else{
              return row.original.gpsMoveStatus
          }
        }
      },
      {
        Header: "GPS	Location",
        accessor: "location",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async(e) => {
            if(refAddres.current.innerText === ''){
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (await ReportServices.getGeocodeAddress(request)).data;
              refAddres.current.innerText = responseData;
              if(responseData !== ''){
                refLink.current.style.display = 'none'
                refAddres.current.style.display = 'block'
                toggleAddressBtn.current.style.display = 'block'
                getAddressBtn.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
          }
        
          //Address toggle
          const ToggleAdderss = () => {
            if( refAddres.current.style.display === 'block' ){
              refAddres.current.style.display = 'none'
              refLink.current.style.display = 'block'
              toggleAddressBtn.current.innerText='Address'
            }else{
              refAddres.current.style.display = 'block'
              refLink.current.style.display = 'none'
              toggleAddressBtn.current.innerText='LatLng'
            }
          }
          return(
            <Box sx={{display:'flex', alignItems:'center'}} >
            <Box className='addresslink' id={row.original.location} sx={{display:'flex', alignItems:'center'}}>
                <Button onClick={(e) => getAddress(e)} value={row.original.location} ref={getAddressBtn}>Address</Button>
                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="latlink"  ref={refLink} id={row.original.location}>{row.original.location}</a>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="address" ref={refAddres} id={row.original.location} key={row.original.location}></a>
            </Box>
        </Box>
          )
        },
      },

      {
        Header: "GPS Odometer (km)",
        accessor: "odometer",
      },
      {
        Header: "GPS Speed (km/h)",
        accessor: "speed",
      },
      {
        Header: "CAN Odometer(km)",
        accessor: "canOdometer",
      },
      {
        Header: "Trip Odometer (km)",
        accessor: "motorOdometer",
      },
      {
        Header: "CAN Speed (kmph)",
        accessor: "motorSpeedKmhr",
      },
      {
        Header: "Battery  Voltage (V)",
        accessor: "batteryVoltage",
      },
      {
        Header: "Battery Current(A)",
        accessor: "batteryCurrent",
      },
      {
        Header: "Battery SOC(%)",
        accessor: "batterySoc",
      },
      {
        Header: "Battery Capacity - Remaining",
        accessor: "residualCapacity",
      },
      {
        Header: "Battery Capacity - Full",
        accessor: "fullCapacity",
      },
      {
        Header: "Battery Energy(wh)",
        accessor: "availableEnergy",
      },
      {
        Header: "Battery Energy SOC(wh)",
        accessor: "availableEnergySOC",
      },
      {
        Header: "Battery Cycle Count",
        accessor: "chargeCycle",
      },
      {
        Header: "Battery Status",
        accessor: "chargeStatus",
      },
      {
        Header: "Battery String",
        accessor: "batteryString",
      },
      
      {
        Header: "NTC Probes",
        accessor: "totalNoOfCell",
      },
      {
        Header: " Battery Monomer Maximum Voltage (V)",
        accessor: "maxParallelVoltage",
      },
      {
        Header: "Battery Monomer Maximum Voltage Cell",
        accessor: "maxCellNoVoltage",
      },
      {
        Header: "Battery Monomer Minimum Voltage (V)",
        accessor: "minParallelVoltage",
      },
      {
        Header: "Battery Monomer Minimum Voltage Cell",
        accessor: "minCellNoVoltage",
      },
      {
        Header: "Battery Monomer Maximum Temperature (ºC)",
        accessor: "maxParallelTemperature",
      },
      {
        Header: "Battery Monomer Maximum Temperature Cell",
        accessor: "maxCellNoTemperature",
      },
      {
        Header: "Battery Monomer Minimum Temperature(ºC)",
        accessor: "minParallelTemperature",
      },

      {
        Header: " Battery Monomer Minimum Temperature Cell",
        accessor: "minCellNoTemperature",
      },
      {
        Header: "Charging MOS status",
        accessor: "chargeMosStatus",
      },
      {
        Header: "Discharging MOS status",
        accessor: "disChargeMosStatus",
      },
      {
        Header: "Battery Fault",
        accessor: "batterryFault",
      },
     
      {
        Header: "Cell Voltage 1 (V)",
        accessor: "cellVoltage1",
      },
      {
        Header: "Cell Voltage 2 (V)",
        accessor: "cellVoltage2",
      },
      {
        Header: "Cell Voltage 3 (V)",
        accessor: "cellVoltage3",
      },
      {
        Header: "Cell Voltage 4 (V)",
        accessor: "cellVoltage4",
      },
      {
        Header: "Cell Voltage 5 (V)",
        accessor: "cellVoltage5",
      },
      {
        Header: "Cell Voltage 6 (V)",
        accessor: "cellVoltage6",
      },
      {
        Header: "Cell Voltage 7 (V)",
        accessor: "cellVoltage7",
      },
      {
        Header: "Cell Voltage 8 (V)",
        accessor: "cellVoltage8",
      },
      {
        Header: "Cell Voltage 9 (V)",
        accessor: "cellVoltage9",
      },
      {
        Header: "Cell Voltage 10 (V)",
        accessor: "cellVoltage10",
      },
      {
        Header: "Cell Voltage 11 (V)",
        accessor: "cellVoltage11",
      },
      {
        Header: "Cell Voltage 12 (V)",
        accessor: "cellVoltage12",
      },
      {
        Header: "Cell Voltage 13 (V)",
        accessor: "cellVoltage13",
      },
      {
        Header: "Cell Voltage 14 (V)",
        accessor: "cellVoltage14",
      },
      {
        Header: "Cell Voltage 15 (V)",
        accessor: "cellVoltage15",
      },
      {
        Header: "Cell Voltage 16 (V)",
        accessor: "cellVoltage16",
      },
      {
        Header: "Cell Voltage 17 (V)",
        accessor: "cellVoltage17",
      },
      {
        Header: "Cell Voltage 18 (V)",
        accessor: "cellVoltage18",
      },
      {
        Header: "Cell Voltage 19 (V)",
        accessor: "cellVoltage19",
      },
      {
        Header: "Cell Voltage 20 (V)",
        accessor: "cellVoltage20",
      },
      {
        Header: "Cell Voltage 21 (V)",
        accessor: "cellVoltage21",
      },
      {
        Header: "Cell Voltage 22 (V)",
        accessor: "cellVoltage22",
      },
      {
        Header: "Cell Voltage 23 (V)",
        accessor: "cellVoltage23",
      },
      {
        Header: "Cell Voltage 24 (V)",
        accessor: "cellVoltage24",
      },
      {
        Header: "NTC Probe 1 (ºC)",
        accessor: "temperature1",
      },
      {
        Header: "NTC Probe 2 (ºC)",
        accessor: "temperature2",
      },
      {
        Header: "NTC Probe 3 (ºC)",
        accessor: "temperature3",
      },
      {
        Header: "NTC Probe 4 (ºC)",
        accessor: "temperature4",
      },
      {
        Header: "NTC Probe 5 (ºC)",
        accessor: "temperature5",
      },
      {
        Header: "Motor Voltage-DC (V)",
        accessor: "motorVoltage",
      },
      {
        Header: "Motor Current (A)",
        accessor: "motorCurrent",
      },
      {
        Header: "Motor Phase Current",
        accessor: "motorPhaseCurrent",
      },
     
      {
        Header: "Motor Temperature (ºC)",
        accessor: "motorTemperature",
      },
      {
        Header: "Controller Temperature (ºC)",
        accessor: "controllerTemperature",
      },
      {
        Header: "Motor RPM",
        accessor: "motorRPM",
      },

      {
        Header: "Motor Direction",
        accessor: "motorDirection",
      },
      {
        Header: "Gear Position",
        accessor: "gears",
      },
       {
        Header: "Gears Display",
        accessor: "gearsDisplay",
      },
       {
        Header: "Brake Status",
        accessor: "brakeStatus",
      },
       {
        Header: "Throttle Command (%)",
        accessor: "throttlePercentage",
      },
      {
        Header: "Motor Fault",
        accessor: "motorFault",
      },
      {
        Header: "Internal Battery Voltage",
        accessor: "internalBatteryVoltage",
      },
      {
        Header: "Internal Battery (%)",
        accessor: "internalBatteryPercentage",
      },
      {
        Header: "Ignition",
        accessor: "ign",
      },
     
    ],
    []
  );

  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedval, setAssignedval] = useState({});
  const [searchval, setSearchval] = useState("");

  // const onSubmit = async (data) => {
  //   setLoading("");
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.regionId = data.region;
  //   loadrequest.resellerId = data.reseller;
  //   //loadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.customerId = data.customer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;
  //   loadrequest.start = newPage;
  //   loadrequest.length = rowsPerPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.orderDir = "desc";
  //   loadrequest.search = searchval;
  //   loadrequest.status = data.status;
    
    
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.ManualCanDataReportKG(loadrequest))
  //     .data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   //console.log(+"canOdometer");
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };
  
  // const fetchVehicleDataDetails = async (searchText, columnName) => {
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   if (columnName === undefined) {
  //     loadrequest.columnName = "MESSAGE";
  //   } else {
  //     loadrequest.columnName = columnName;
  //   }
  //   const responseData = (await ReportServices.ManualCanDataReportKG(loadrequest))
  //     .data;
  //   setResponseData([]);

  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const handleSearch = (searchText) => {
  //   fetchVehicleDataDetails(searchText);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.start = newPage;
  //   loadrequest.length = rowsPerPage;
  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     const responseData = (
  //       await ReportServices.ManualCanDataReportKG(loadrequest)
  //     ).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };
  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);
  //   console.log(loadrequest);
  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "ManualCanDataReport/vehicleDataReportForKg2.xls?startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&orderDir=" +
  //       loadrequest.orderDir +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&status=" +
  //       loadrequest.status +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId;

  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > XLS";
  //     request.action = "View";
  //     request.featureName = "VDR Report";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;
  
  //     const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };
  


    
  const [ vehicleData2KG, { data:kgVehicleData, isLoading, isSuccess }] = useVehicleData2KGMutation();
  const [payload, setPayload] = useState();
  let commonPayload = {
    'resellerId': resellerId,
  }
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(50);
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = 'desc';
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage; 
    commonPayload.status = data.vehicleStatusId;
    commonPayload.search = '';
    setPayload(commonPayload);
    vehicleData2KG(commonPayload);

    auditPayload.message = "Reports > Vehicle Data > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.statusName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Data Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }

  useEffect(() => {
    if(isSuccess){
      setResponseData([]);
      if(kgVehicleData.data !== null){
        setResponseData(kgVehicleData.data?.content);
        setTotalElements(kgVehicleData.data?.totalElements);
        setTotalPages(kgVehicleData.data?.totalPages);
      }
    }
  },[isSuccess]);

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    payload.size = 0;
    payload.rowsPerPage = 50;
    setNewPage(0);
    setRowsPerPage(50);
    if(payload.search.length >= 3){
      vehicleData2KG(payload);
    }else if(payload.search.length === 0){
      vehicleData2KG(payload);
    }
  };

//Onchange PDF
const onChangePdf = async () => {
  try {
    var url =
      process.env.REACT_APP_API_BASE_URL +
      "ManualCanDataReport/vehicledataPDF.pdf?startDate=" +
      payload.startDate +
      "&endDate=" +
      payload.endDate +
      "&orderDir=" +
      payload.orderDir +
      "&orgId=" +
      payload.orgId +
      "&status=" +
      payload.status +
      "&vehicleId=" +
      payload.vehicleId;
      window.open(url);

      auditPayload.message = "Reports > Vehicle Data > Export > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "VDR Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    

  } catch (error) {
    console.log('error', error);
  }
};

//Onchange XLS
const onChangeXcl = async () => {
  try {
      var url =
      process.env.REACT_APP_API_BASE_URL +
      "ManualCanDataReport/vehicleDataReportForKg2.xls?startDate=" +
      payload.startDate +
      "&endDate=" +
      payload.endDate +
      "&orderDir=" +
      payload.orderDir +
      "&orgId=" +
      payload.orgId +
      "&status=" +
      payload.status +
      "&resellerId=" +
      payload.resellerId +
      "&vehicleId=" +
      payload.vehicleId;
// console.log(url);
    window.open(url);
    auditPayload.message = "Reports > Vehicle Data > Export > Excel";
    auditPayload.action = "View";
    auditPayload.featureName = "VDR Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);

  } catch (error) {
    console.log('error', error)
  }
};

 //Page Change
 const pageChange = (newPage, rowsPerPage) => {
  setNewPage(newPage);
  setRowsPerPage(rowsPerPage);
  payload.page = newPage;
  payload.size = rowsPerPage;
  payload.start=newPage;
  payload.length=rowsPerPage;
  vehicleData2KG(payload);
} 

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Vehicle Data Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Data Report"} />
      <Box>
        {/* <VDRDropDown
          onSubmit={onSubmit}
          callbackLoadFn={callbackLoadFn}
          isStatus={true}
          displayDate={false}
          allowDays={0}
        /> */}

        <FilterDropdown getData={onSubmitForm} isVehicleData={true} isShowAll={false} allowDays={0} last7={false}  setToday={true} />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              isLoading={isLoading}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePdf}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "89vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              //callbck={handleSort}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default ManualCanDataReportKG;
