import React, { useState, useEffect, useMemo } from "react";
import dropdownServices from "../../services/dropdownServices";
import { Paper } from "@mui/material";
import DropDown from "../shared/DropDown";
import { useTheme } from "@mui/styles";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";

const CommonDropDown = ({ handleSmt, callbackLoadFn }) => {
  const theme = useTheme();
  const dropdownStyle = {
    //boxShadow: theme.palette.shadow.boxShadow,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "10px",
  };
  var selectedResellerId = null;
  var selectedCustomerId = null;
  var selectedFleetId = null;
  var selectedRoleId = null;
  var dealerVal=0;
  var regionVal=0;

  const [resellers, setResellers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [regions, setRegions] = useState([]);
  const [dealers, setDealers] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const shouldIncludeAllOption = true;
  const [orgTypeRes, setOrgTypeRes] = useState([]);
  const [orgListJSON, setOrgListJSON] = useState([]);

  const [seltdOrgTypeId, setSeltdOrgTypeId] = useState("0");
  const [seltdResellerId, setSeltdResellerId] = useState("0");
  const [seltdCustomerId, setSeltdCustomerId] = useState("0");
  const [seltdFleetId, setSeltdFleetId] = useState("0");
  const [seltdDealerId, setSeltdDealerId] = useState("0");
  const [seltdRegionId, setSeltdRegionId] = useState("-1");
  const [seltdRoleId, setSeltdRoleId] = useState("0");

  const [seltdLastOrgId, setSeltdLastOrgId] = useState("0");

  const [isOrgType, setIsOrgType] = useState(true);
  const [isReseller, setIsReseller] = useState(true);
  const [isCustomer, setIsCustomer] = useState(true);
  const [isDealer, setIsDealer] = useState(true);
  const [isFleet, setIsFleet] = useState(true);
  const [isAgency, setIsAgency] = useState(true);

  const getResellerId = (listOfResellers) => {
    return listOfResellers[0].key;
  };
  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "All",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };
  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalDisplayView = 0;


  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globalDisplayView = globalJson.displayView;

  }

  useEffect(async () => {
     if (2 === parseInt(globalOrgType)){

      setIsOrgType(false);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
  
    
      const resellerPayload = {
        value: "reseller",
        orgType: 2,
        dealerId: 0,
        roleId: globalJson !== null ? globalJson.roleId : 1,
        id: globalJson !== null ? globalJson.resellerId : 1,
        searchText: "",
      };
      const { data: resellerResponseNew } = await dropdownServices.getResellers(
        resellerPayload
      );
      const { content: orgnListJSON } = resellerResponseNew.data;
  
      setOrgListJSON(orgnListJSON);
      selectedResellerId = orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1";
      setSeltdResellerId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1");
  
      const regionListPayload = {
        value: "region",
      };
      const { data: regionResponseBody } = await dropdownServices.getResellers(regionListPayload);
      const { content: regionList } = regionResponseBody.data;
      const _regions = getOptions(regionList);
      setRegions(_regions);
      setSeltdRegionId(_regions[0]?.key);
  
      const dealerListPayload = {
        value: "dealer",
        resellerId:globalResellerId,
        searchText: "",
        regionId: _regions[0]?.key,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(dealerListPayload);
      const { content: dealerList } = dealerListResponse.data;
  
      const _dealers = getOptions(dealerList);
      setDealers(_dealers);
      setSeltdDealerId(_dealers[0].key);
  
      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "",
        dealerId: globalDisplayView===true?_dealers[0].key:0,
        searchText: "",
      };
      const { data: customerListResponse } = await dropdownServices.getResellers(
        customersListPayload
      );
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
      const _customers=getOptions(customersList);
      setCustomers(_customers);
      selectedCustomerId = _customers.length > 0 ? _customers[0].key : "-1";
      setSeltdCustomerId(_customers.length > 0 ? _customers[0].key : "-1");
  
      const fleetListPayload = {
        value: "fleet",
        customerId: _customers.length > 0 ? _customers[0].key : "",
        dealerId: 0,
        searchText: "",
      };
  
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const _fleet=getOptions(fleetListResponse);
      setFleet(_fleet);
      selectedFleetId =_fleet.length > 0 ? _fleet[0].key : "-1";
      setSeltdFleetId(_fleet.length > 0 ? _fleet[0].key : "-1");
      }
    else if (8 === parseInt(globalOrgType) && 1 === parseInt(globalRoleId)) {

    const orgTypePayload = {
      value: "orgType",
      resellerId: 0,
      dealerId: 0,
      searchText: "",
    };
    const { data: orgTypeResponseNew } = await dropdownServices.getResellers(
      orgTypePayload
    );
    const { content: orgTypeResponse } = orgTypeResponseNew.data;
    setOrgTypeRes(orgTypeResponse);
    setSeltdOrgTypeId(orgTypeResponse.length > 0 ? orgTypeResponse[0].key : "");
    let objType = orgTypeResponse.length > 0 ? orgTypeResponse[0].key : "0";

    setIsOrgType(true);
    setIsReseller(true);
    setIsCustomer(true);
    setIsFleet(true);
    setIsDealer(false);
    setIsAgency(false);

    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;

    const resellerPayload = {
      value: "reseller",
      orgType: 2,
      resellerId: globalResellerId,
      dealerId: 0,
      roleId: globalJson !== null ? globalJson.roleId : 1,
      id: globalJson !== null ? globalJson.resellerId : 1,
      searchText: "",
    };
    const { data: resellerResponseNew } = await dropdownServices.getResellers(resellerPayload);
    const { content: orgnListJSON } = resellerResponseNew.data;
    const _reseller = getOptions(orgnListJSON);
    setOrgListJSON(_reseller);
    selectedResellerId = _reseller.length > 0 ? _reseller[0].key : "-1";
    setSeltdResellerId(_reseller.length > 0 ? _reseller[0].key : "-1");

    const regionListPayload = {
      value: "region",
    };
    const { data: regionResponseBody } = await dropdownServices.getResellers(regionListPayload);
    const { content: regionList } = regionResponseBody.data;
    const _regions = getOptions(regionList);
    setRegions(_regions);
    setSeltdRegionId(_regions[0]?.key);

    const dealerListPayload = {
      value: "dealer",
      resellerId:globalResellerId,
      searchText: "",
      regionId: _regions[0]?.key,
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(dealerListPayload);
    const { content: dealerList } = dealerListResponse.data;

    const _dealers = getOptions(dealerList);
    setDealers(_dealers);
    setSeltdDealerId(_dealers[0].key);

    const customersListPayload = {
      value: "customer",
      resellerId:selectedResellerId,
      dealerId: globalDisplayView===true?_dealers[0].key:0,
      searchText: "",
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(customersList);
    const _customers=getOptions(customersList);
    setCustomers(_customers);
    selectedCustomerId = _customers.length > 0 ? _customers[0].key : "-1";
    setSeltdCustomerId(_customers.length > 0 ? _customers[0].key : "-1");

    const fleetListPayload = {
      value: "fleet",
      customerId: _customers.length > 0 ? _customers[0].key : "",
      dealerId: 0,
      searchText: "",
    };

    const { data: fleetListResp } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetListResponse } = fleetListResp.data;
    const _fleet=getOptions(fleetListResponse);
    setFleet(_fleet);
    selectedFleetId =_fleet.length > 0 ? _fleet[0].key : "-1";
    setSeltdFleetId(_fleet.length > 0 ? _fleet[0].key : "-1");
    }
    callbackLoadFn(
      selectedFleetId,
      selectedResellerId
    );
  }, []);

  const handleOrgTypeSelect = async (orgType) => {
    if (parseInt(orgType) === 2) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
    } else if (parseInt(orgType) === 3) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
    } else if (parseInt(orgType) === 4) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(true);
    } else if (parseInt(orgType) === 5) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(true);
      setIsAgency(false);
    } else {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
    }
    setSeltdOrgTypeId(orgType);
    selectedResellerId = orgType;
    const resellerPayload = {
      value: "reseller",
      orgType: 2,
      resellerId: globalJson1.resellerId, //globalResellerId,
      dealerId: 0,
      roleId: globalRoleId,
      id: globalJson1.resellerId, //globalResellerId
      searchText: "",
    };
    const { data: resellerResponseNew } = await dropdownServices.getResellers(resellerPayload);
    const { content: orgnListJSON } = resellerResponseNew.data;
    setOrgListJSON(orgnListJSON);
    setSeltdResellerId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1");

    if (parseInt(orgType) === 2) {
      setSeltdLastOrgId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1");
    } else if (parseInt(orgType) === 1) {
      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1",
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
      selectedCustomerId =
        customersList.length > 0 ? customersList[0].key : "-1";
      setSeltdCustomerId(
        customersList.length > 0 ? customersList[0].key : "-1"
      );

      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      setFleet(fleetListResponse);
      selectedFleetId =
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1";
      setSeltdFleetId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );
      setSeltdLastOrgId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );
    } else {

      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "",
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      selectedCustomerId =
        customersList.length > 0 ? customersList[0].key : "-1";

      if (customersList.length === 0) {
        const _customers = getOptions(customersList);
        setCustomers(_customers);
      } else {
        setCustomers(customersList);
      }
      setSeltdLastOrgId(customersList.length > 0 ? customersList[0].key : "-1");
      setSeltdCustomerId(
        await (customersList.length > 0 ? customersList[0].key : "-1")
      );
    }
  };

  const handleResellerSelect = async (resellerId) => {
    if (seltdOrgTypeId === "1") {
      setSeltdResellerId(resellerId);
      const customersListPayload = {
        value: "customer",
        resellerId: resellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      if (customersList.length === 0) {
        const _customers = getOptions(customersList);
        setCustomers(_customers);
      } else {
        const _customers = getOptions(customersList);
        setCustomers(_customers);
      }

      selectedCustomerId =
        customersList.length > 0 ? customersList[0].key : "-1";
      setSeltdCustomerId(
        customersList.length > 0 ? customersList[0].key : "-1"
      );

      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;

      if (fleetListResponse.length === 0) {
        const fleets = getOptions(fleetListResponse);
        setFleet(fleets);
      } else {
        const fleets = getOptions(fleetListResponse);
        setFleet(fleets);
      }

      selectedFleetId =
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1";
      setSeltdFleetId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );

      const roleListPayload = {
        value: "role",
        fleetId: fleetListResponse.length > 0 ? fleetListResponse[0].key : "",
        dealerId: 0,
        searchText: "",
        orgType: seltdOrgTypeId,
      };
      const { data: roleListResp } = await dropdownServices.getResellers(
        roleListPayload
      );
      const { content: roleListResponse } = roleListResp.data;
      setSeltdLastOrgId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );
      setRoles(roleListResponse);
      setSeltdRoleId(roleListResponse[0].key);
      selectedRoleId = roleListResponse[0].key;
    } else if (seltdOrgTypeId === "2") {
      setSeltdResellerId(resellerId);
      setSeltdLastOrgId(resellerId);
      const roleListPayload = {
        value: "role",
        fleetId: resellerId,
        dealerId: 0,
        searchText: "",
        orgType: seltdOrgTypeId,
      };
      const { data: roleListResp } = await dropdownServices.getResellers(
        roleListPayload
      );
      const { content: roleListResponse } = roleListResp.data;
      setSeltdLastOrgId(resellerId);
      setRoles(roleListResponse);
      setSeltdRoleId(roleListResponse[0].key);
      selectedRoleId = roleListResponse[0].key;
    } else {
      setSeltdResellerId(resellerId);
      const customersListPayload = {
        value: "customer",
        resellerId: resellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const _customers = getOptions(customersList);
      setCustomers(customersList);
      selectedCustomerId =
        customersList.length > 0 ? customersList[0].key : "-1";
      setSeltdCustomerId(
        customersList.length > 0 ? customersList[0].key : "-1"
      );
      setSeltdLastOrgId(customersList[0].key);
      setSeltdResellerId(resellerId);

      setSeltdResellerId(resellerId);
      setSeltdLastOrgId(resellerId);
      const roleListPayload = {
        value: "role",
        fleetId: customersList.length > 0 ? customersList[0].key : "-1",
        dealerId: 0,
        searchText: "",
        orgType: seltdOrgTypeId,
      };
      const { data: roleListResp } = await dropdownServices.getResellers(
        roleListPayload
      );
      const { content: roleListResponse } = roleListResp.data;
      setSeltdLastOrgId(resellerId);
      setRoles(roleListResponse);
      setSeltdRoleId(roleListResponse[0].key);
      selectedRoleId = roleListResponse[0].key;
    }
  };

  const handleRegionSelect = async (regionId) => {
    setSeltdRegionId(regionId);

 if (globalOrgType === 8) {   
  if (regionId === "-1") {
    setDealers([]);
    setDealers(getOptions);
  } else {
    const dealerListPayload = {
      value: "dealer",
      // resellerId: userData.userOrgType === 8 ? seltdResellerId : globalResellerId,
      resellerId:seltdResellerId,
      searchText: "",
      regionId: regionId,
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;

    const _dealers = getOptions(dealerList);
    setDealers(_dealers);

    setSeltdRegionId(regionId);
    setSeltdDealerId(_dealers[0].key);
  }
    }
    else{
    if (regionId === "-1") {
      setDealers([]);
      setDealers(getOptions);
    } else {
      const dealerListPayload = {
        value: "dealer",
        resellerId:
          userData.userOrgType === 2 ? globalResellerId  : seltdResellerId,
        dealerId: 0,
        searchText: "",
        regionId: regionId,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;

      const _dealers = getOptions(dealerList);
      setDealers(_dealers);

      setSeltdRegionId(regionId);
      setSeltdDealerId(_dealers[0].key);
    }
  }
  };

  const handleDealerSelect = async (dealerId) => {
    setSeltdDealerId(dealerId);
    const customersListPayload = {
      value: "customer",
      orgType: "3",
      id: userData.userOrgType === 1 ? seltdResellerId : globalResellerId,
      resellerId:
        userData.userOrgType === 1 ? seltdResellerId : globalResellerId,
      dealerId: dealerId,
      searchText: "",
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    const customerAll = getOptions(customersList);
    setCustomers(customerAll);
    setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");
  };


  const handleCustomerSelect = async (customerId) => {
    selectedCustomerId = customerId;
    setSeltdCustomerId(customerId);

    if (seltdOrgTypeId === "1") {
      const fleetListPayload = {
        value: "fleet",
        customerId: customerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );

      const { content: fleetListResponse } = fleetListResp.data;

      if (fleetListResponse.length === 0) {
        const fleets = getOptions(fleetListResponse);
        setFleet(fleets);
      } else {
        const fleets = getOptions(fleetListResponse);
        setFleet(fleets);
      }
      selectedFleetId =
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1";
      setSeltdFleetId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );

      const roleListPayload = {
        value: "role",
        fleetId: fleetListResponse.length > 0 ? fleetListResponse[0].key : "",
        dealerId: 0,
        searchText: "",
        orgType: seltdOrgTypeId,
      };
      const { data: roleListResp } = await dropdownServices.getResellers(
        roleListPayload
      );
      const { content: roleListResponse } = roleListResp.data;
      setSeltdLastOrgId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );
      setRoles(roleListResponse);
      setSeltdRoleId(roleListResponse[0].key);
      selectedRoleId = roleListResponse[0].key;
    } else {
      selectedCustomerId = customerId;
      setSeltdCustomerId(customerId);
      setSeltdLastOrgId(customerId);
      const roleListPayload = {
        value: "role",
        fleetId: customerId,
        dealerId: 0,
        searchText: "",
        orgType: seltdOrgTypeId,
      };
      const { data: roleListResp } = await dropdownServices.getResellers(
        roleListPayload
      );
      const { content: roleListResponse } = roleListResp.data;
      setSeltdLastOrgId(customerId);
      setRoles(roleListResponse);
      setSeltdRoleId(roleListResponse[0].key);
      selectedRoleId = roleListResponse[0].key;
    }
  };

  const handleFleetSelect = async (fleetId) => {
    setSeltdLastOrgId(fleetId);
    const roleListPayload = {
      value: "role",
      fleetId: fleetId,
      dealerId: 0,
      searchText: "",
      orgType: seltdOrgTypeId,
    };
    const { data: roleListResp } = await dropdownServices.getResellers(
      roleListPayload
    );
    const { content: roleListResponse } = roleListResp.data;
    setSeltdLastOrgId(fleetId);
    setRoles(roleListResponse);
    setSeltdRoleId(roleListResponse[0].key);
    selectedRoleId = roleListResponse[0].key;
  };

  const handleSubmit = (e) => {
    if(globalDisplayView === true){
    handleSmt(
      seltdOrgTypeId,
      seltdResellerId,
      seltdCustomerId,
      seltdFleetId,
      seltdLastOrgId,
      seltdDealerId,
      seltdRegionId
    );
     }
    else{
      handleSmt(
        seltdOrgTypeId,
        seltdResellerId,
        seltdCustomerId,
        seltdFleetId,
        seltdLastOrgId,
        regionVal,
        dealerVal,
      );
    }
  };

  const handleOrgTypeSearch = async (searchText) => {
    const customersListPayload = {
      value: "customer",
      resellerId: selectedResellerId,
      dealerId: 0,
      searchText,
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(getOptions(customersList));
  };

  const handleCustomerSearch = async (searchText) => {
    const customersListPayload = {
      value: "customer",
      resellerId: selectedResellerId,
      dealerId: 0,
      searchText,
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(getOptions(customersList));
  };

  const handleFleetSearch = async (searchText) => {
    const fleetListPayload = {
      value: "fleet",
      resellerId: selectedResellerId,
      dealerId: 0,
      customerId: selectedCustomerId,
      searchText: searchText,
    };
    const { data: fleetListResponse } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetList } = fleetListResponse.data;
    setFleet(getOptions(fleetList));
  };

  const handleResellerSearch = async (searchText) => {
    const resellersListPayload = {
      value: "reseller",
      orgType: userData.userOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
      searchText,
    };

    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;
    setResellers(resellersList);
  };

  return (
    <form>
      <StyledPaperShadow>
        <DropDown
          label="Organization Type"
          options={orgTypeRes}
          onChange={handleOrgTypeSelect}
          optionsDisplayKey="value"
          onSearch={handleOrgTypeSearch}
          isShow={isOrgType}
          fieldName="orgType"
        />
        <DropDown
          label="Organization"
          options={orgListJSON}
          onChange={handleResellerSelect}
          optionsDisplayKey="value"
          onSearch={handleResellerSearch}
          isShow={isReseller}
          fieldName="Organization"
        />

         <DropDown
          label="Region"
          options={regions}
          onChange={handleRegionSelect}
          optionsDisplayKey="value"
          isShow={globalDisplayView === true ? true : false}
          // onSearch={handleRegionSearch}
          fieldName={"regionId"}
        />

        <DropDown
          label="Dealer"
          options={dealers}
          optionsDisplayKey="value"
          isShow={globalDisplayView === true ? true : false}
          onChange={handleDealerSelect}
          fieldName={"dealerId"}
        />

        <DropDown
          label="Customer"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={isCustomer}
          onSearch={handleCustomerSearch}
        />
        <DropDown
          label="Dealer"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={isDealer}
          onSearch={handleCustomerSearch}
        />
        <DropDown
          label="Fleet"
          options={fleet}
          onChange={handleFleetSelect}
          optionsDisplayKey="value"
          isShow={isFleet}
          onSearch={handleFleetSearch}
        />
        <DropDown
          label="Agency"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={isAgency}
          onSearch={handleCustomerSearch}
        />
        <StyledFiterButton
          type="button"
          onClick={handleSubmit}
          variant="contained"
        >
          Submit
        </StyledFiterButton>
      </StyledPaperShadow>
    </form>
  );
};

export default CommonDropDown;
