
import { Backdrop } from "@mui/material"

export const InvisableLoader= (props) => {
    const { isLoading } = props;
    return(
        <Backdrop open={isLoading} invisible={true} sx={{zIndex:9999}}>
            
        </Backdrop>
    )
}