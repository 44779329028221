import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import {
  StyledCardShadow,
  StyledTableTitle,
} from "../../theme/StyledEle";
import {
  CardHeader,
  CardContent,
  Typography
} from "@mui/material";
import { useFeedSummaryListMutation } from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import MouseOverPopover from "../shared/PopOver";

const text = "Size column shown is in GB. Note: ONTIME (current timestamp within 5mins from device GPS captured time)";

const FeedSummary = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData.orgId;


  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);

  const [
    getFeedSummaryList,
    {
      data: data,
      isLoading: loading,
      isSuccess: success,
    },
  ] = useFeedSummaryListMutation();


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "summarizedDate",
      },
      {
        Header: "Vehicles",
        accessor: "vehicleCount",
      },
      {
        Header: "Riding Vehicles",
        accessor: "ridingVehicleCount",
      },
      {
        Header: "Total Distance Traveled",
        accessor: "totalDistanceTravelled",
      },
      {
        Header: "Battery Consumed",
        accessor: "batteryConsumed",
      },
      {
        Header: "Total Trips",
        accessor: "totalTripCount",
      },
      {
        Header: "No Run Vehicles",
        accessor: "noRunVehicleCount",
      },
      {
        Header: "Stop Vehicles",
        accessor: "stopVehicleCount",
      },
      {
        Header: "Offline Vehicles",
        accessor: "offlineVehicleCount",
      },
      {
        Header: "TOTAL VALID",
        columns: [
          {
            Header: "count",
            accessor: "totalValidCount",
          },
          {
            Header: "size",
            accessor: "totalValidSize",
          }
        ],
      },
      // {
      //   Header: "TOTAL INVALID",
      //   columns: [
      //     {
      //       Header: "count",
      //       accessor: "totalInvalidCount",
      //     },
      //     {
      //       Header: "size",
      //       accessor: "totalInvalidSize",
      //     }
      //   ],
      // },
      {
        Header: "VALID",
        columns: [
          {
            Header: "LIVE",
            columns: [
              {
                Header: "ONTIME",
                columns: [
                  {
                    Header: "totalOnTimeCount",
                    accessor: "moveMinutes",
                  },
                  {
                    Header: "size",
                    accessor: "totalOnTimeSize",
                  }
                ],
              },
              {
                Header: "DELAY <1 DAY",
                columns: [
                  {
                    Header: "count",
                    accessor: "totaldlt24hrCount",
                  },
                  {
                    Header: "size",
                    accessor: "totaldlt24hrSize",
                  }
                ],
              },
              {
                Header: "DELAY >1 DAY",
                columns: [
                  {
                    Header: "count",
                    accessor: "totaldgt24hrCount",
                  },
                  {
                    Header: "size",
                    accessor: "totaldgt24hrSize",
                  }
                ],
              },
              {
                Header: "DELAY <7DAYS",
                columns: [
                  {
                    Header: "count",
                    accessor: "totaldlt7daysCount",
                  },
                  {
                    Header: "size",
                    accessor: "totaldlt7daysSize",
                  }
                ],
              },
              {
                Header: "DELAY >7DAYS",
                columns: [
                  {
                    Header: "count",
                    accessor: "totaldgt7daysCount",
                  },
                  {
                    Header: "size",
                    accessor: "totaldgt7daysSize",
                  }
                ],
              },
         
            ],
          }
        ],
  },
    ],
    []
  );


  useEffect(() => {
    if (success) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      }
    }
  }, [success]);

  let commonPayload = {};
  const onSubmitForm = (data) => {
   
    commonPayload.orgId = orgId;
    commonPayload.startDate = data.fromDate;
    commonPayload.page = 0;
    commonPayload.size = 25;
    //Service Call
    setPayload(commonPayload);
    getFeedSummaryList(commonPayload);
   
  };


    //Page Change
    const pageChange = (newPage, rowsPerPage) => {
      setNewPage(newPage);
      setRowsPerPage(rowsPerPage);
      payload.page = newPage;
      payload.size = rowsPerPage;
      getFeedSummaryList(payload);
    };


  return (
    <Box>
    <MouseOverPopover text={text} title={"Instrumentation"} />
    <FilterDropdown
        getData={onSubmitForm}
        status={false}
        monthPicker={true}
        last6Month={true}
        instrumentation={false}
      />
    <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
      <CardHeader title={<StyledTableTitle>Feed Summary</StyledTableTitle>} />
      <CardContent>
        <DataTable
          includeExportButton={false}
          columns={columns}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          data={responseData}
          rowsPerPage={rowsPerPage}
          callbackChangePage={pageChange}
          totalElements={totalElements}
          totalPages={totalPages}
          isLoading={loading}
          page={newPage}
        />
      </CardContent>
    </StyledCardShadow>
  </Box>
  );
};

export default FeedSummary;
