import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MouseOverPopover from "../shared/PopOver";
import exporticon from '../../assets/images/exporticon.png';


const TyrePressureReport = () => {
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const text =
  "Tyre pressure monitoring system Report gets the details of Two-Wheeler tyre info in a Fleet based on date selection.";

  useEffect(async () => {
    await fetchTyrePressureDetails();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "startDate",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      {
        Header: "Odometer",
        accessor: "odometer",
      },
      {
        Header: "Speed",
        accessor: "speed",
      },
      {
        Header: "Location",
        accessor: "startLoc",
      },
      {
        Header: "Battery Voltage(v)",
        accessor: "intBatVoltPercentage",
      },
      {
        Header: "Ambient Temperature(c)",
        accessor: "ambTemperature",
      },

      {
        Header: "Front Tyre",

        columns: [
          {
            Header: "Sensor Id",
            accessor: "sensorId1",
          },
          {
            Header: "Tyre Pressure(PSI)",
            accessor: "tyrePressure1",
          },
          {
            Header: "Temperature(c)",
            accessor: "temperature1",
          },
          {
            Header: "Battery Volt(v)",
            accessor: "batteryVoltage1",
          },
        ],
      },
      {
        Header: "Rear Tyre",

        columns: [
          {
            Header: "Sensor Id",
            accessor: "sensorId2",
          },
          {
            Header: "Tyre Pressure(PSI)",
            accessor: "tyrePressure2",
          },
          {
            Header: "Temperature(c)",
            accessor: "temperature2",
          },
          {
            Header: "Battery Volt(v)",
            accessor: "batteryVoltage2",
          },
        ],
      },
      {
        Header: "Tyre 3",

        columns: [
          {
            Header: "Sensor Id",
            accessor: "sensorId3",
          },
          {
            Header: "Tyre Pressure(PSI)",
            accessor: "tyrePressure3",
          },
          {
            Header: "Temperature(c)",
            accessor: "temperature3",
          },
          {
            Header: "Battery Volt(v)",
            accessor: "batteryVoltage3",
          },
        ],
      },
      {
        Header: "Tyre 4",

        columns: [
          {
            Header: "Sensor Id",
            accessor: "sensorId4",
          },
          {
            Header: "Tyre Pressure(PSI)",
            accessor: "tyrePressure4",
          },
          {
            Header: "Temperature(c)",
            accessor: "temperature4",
          },
          {
            Header: "Battery Volt(v)",
            accessor: "batteryVoltage4",
          },
        ],
      },
    ],
    []
  );
  const fetchTyrePressureDetails = async () => {
    var request = {};

    request.orgId = 0;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-09-26 00:00:00";
    request.endDate = "2022-09-26 23:59:59";
    request.sortBy = "desc";
    request.page = newPage;
    request.size = rowsPerPage;
    request.search = "";

    const responseData = (await ReportServices.tyrePressureReport(request))
      .data;
    setResponseData(responseData.data.content);
  };
  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    var request = {};

    var request = {};
    request.orgId = 0;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-09-26 00:00:00";
    request.endDate = "2022-09-26 23:59:59";
    request.sortBy = "string";
    request.page = 0;
    request.size = 0;
    request.search = "string";

  }

  const onChangeXcl=async ()=>{
   
    var request = {};
    request.orgId = 0;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-09-26 00:00:00";
    request.endDate = "2022-09-26 23:59:59";
    request.sortBy = "string";
    request.page = 0;
    request.size = 0;
    request.search = "string";

  
    try {

     var url=process.env.REACT_APP_API_BASE_URL+"TyrePressureMonitoringSystemReport/tyrePressureXLS.xls?orgId="+request.orgId+"&regionId="+request.regionId+"&reselId="+request.reselId+"&customerId="+request.customerId+"&vehId="+request.vehId+"&startDate="+request.startDate+"&endDate="+request.endDate+"&sortBy="+request.sortBy+"&page="+request.page+"&size="+request.size+"&search="+request.search+"";
     //console.log("url:::"+url);
     
     window.open(url); 
         
    } catch (error) {}  
  }

  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Tyre pressure monitoring system"
        includePopup={true}
      ></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>

          <MouseOverPopover
            text={text}
            title={"Tyre pressure monitoring system"}
          />
          <Typography sx={{ fontSize: "12px" }}>
            <b>Note</b>:Tyre pressure monitoring system Report gets the details
            of Two-Wheeler tyre info in a Fleet based on date selection.
          </Typography>

          <Divider sx={{ borderBottomWidth: 2, mt: 1 }} />
          <Paper sx={{ backgroundColor: "#eeeeee", marginTop: 2 }}>
            <ReportDropDown />
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button>
          </Paper>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
            }}
          >
            <img alt="excel" style={{marginLeft: 850,width: 25, marginTop: 30}}  title="Export Excel"  onClick={onChangeXcl} src={exporticon} /> 

            <DataTable
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={callbackChangePage}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default TyrePressureReport;
