import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  CardMedia,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Toolbar,
  Typography,
  alpha,
  Backdrop,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FaRegBell } from "react-icons/fa";
import { HiOutlineMenu, HiUser, HiCog, HiLogout } from "react-icons/hi";
import { BiCog, BiUser } from "react-icons/bi";
import { HiCog8Tooth } from "react-icons/hi2";
import { FiChevronDown } from "react-icons/fi";
import { AiOutlineLogout } from "react-icons/ai";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import GridLoader from "react-spinners/GridLoader";
import { useGetLogoApiMutation, useGetThemeMutation } from "../../../store/slice/ApiSlice";
import {
  chanageSiteIdentity,
  getAuth,
  logout,
  toggleMini,
  setTheme
} from "../../../store/slice/ControlsSlice";
import Controls from "../../../settings/Controls";
import logoPlaceHolder from "../../../assets/images/logoPlaceHolder.svg";
import Favicon from "react-favicon";
import { StyledAppBar, StyledHeaderMenuBtn } from "../../../theme/StyledEle";
import { NavLink } from "react-router-dom";
import Sessionout from "../../../components/login/Sessionout";
import { useIdleTimer } from 'react-idle-timer'
import { useGetUserImageMutation } from "../../../store/slice/ProfileSlice/ProfileSlice";
import MultipleAccount from "./MultipleAccount";
import { TbLogout2 } from "react-icons/tb";
import poweredBy from '../../../assets/images/NeshPoeweLogo_blck.svg';
import userServices from "../../../services/userServices";

function HeaderNew() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  // const menuList = JSON.parse(localStorage.getItem("menuList"));
  // const getName = () => {
  //   const filterName = menuList.map( item => item.sub)
  // }

  
  //Profile menu list

  //Redux
  const headerTheme = useSelector(
    (state) => state.controls.customizer.header.theme
  );
  const headerBg = useSelector(
    (state) => state.controls.customizer.header.primary
  );
  const headerText = useSelector(
    (state) => state.controls.customizer.header.secondary
  );


  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?.id;
  const resellerID = userData?.resellerId;
  let orgType = userData?.userOrgType

  const body = {
    resellerId: resellerID,
  };

  // //Get Logo API Via
  const [getLogoApi, { data, isLoading, isSuccess, isError }] =
    useGetLogoApiMutation();



    const getThemeService = async () => {
      let themePayload={
        resellerId: resellerID,
      }
      
      let themeRes = await getTheme(themePayload);
      
      if(themeRes !== undefined && themeRes.data !== undefined && themeRes.data !== null && themeRes.data.length >0 ){
          dispatch(setTheme(themeRes.data));
      }else{
        dispatch(setTheme(null));
      }
    } 


  useEffect(() => {
    if (null !== localStorage.getItem("userData")) {
      getLogoApi(body);
      getThemeService();
    }
  }, [resellerID]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(chanageSiteIdentity(data.formData));
    }
  }, [isLoading]);

  //Get Title, Logo URL, Fav URL
  const logo = useMemo(() => data, [data]);
  const [ getTheme, {data:getThemeData,isLoading:getThemeLoading, isSuccess:getThemeSucccess} ] = useGetThemeMutation();
  const logoURLs = useSelector((state) => state.controls.siteIdentity.logoURL);
  const favIconURLs = useSelector((state) => state.controls.siteIdentity.favIconURL);
  const title = useSelector((state) => state.controls.siteIdentity.title);
  const isLoadingStateSelect = useSelector((state) => state.controls.isloading);
  const settings = [
    {
      title: "Profile Settings",
      route: "profileSettings",
      icon: <BiUser />,
      show:true
    },
    {
      title: "Account Settings",
      route: "accountSettings",
      icon: <BiCog />,
      show:true
    },
    {
      title: "Theme Settings",
      route: "themeeditor",
      icon: <MdOutlineSpaceDashboard />,
      show:(orgType === 8 ||orgType===2) ? true : false
    },
    // {
    //   title: "Logout",
    //   route: "login",
    //   icon: <AiOutlineLogout />,
    // },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // profile setting open
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const openss = Boolean(anchorEl);
  ///menu item style
  const menuList = (theme) => ({
    mt: "5px",
    padding: "0px 25px",
    "& .MuiPaper-root": {
      right: "16px",
      left: "auto !important",
    },
  });

  //Menu close
  const handleCloseUserMenu = () => {
    setAnchorEl(false);
  };

  //Avator sting name
  function stringAvatar(names) {
    return {
      sx: {
        bgcolor: theme.palette.secondary.main,
        textTransform: "uppercase",
      },
      children: `${names.split(" ")[0][0]}`,
    };
  }

  // //Logout
  // const handleCloseUserMenu1 = async () => {
  //   window.localStorage.removeItem("userData");
  //   window.localStorage.removeItem("isRefreshToken");
  //   setAnchorElUser(null);
  //   localStorage.setItem("isLogin", false);
  //   window.localStorage.removeItem("menuList");
  //   var request = {};
  //   request.userId = userId;
  //   const response = await userServices.saveLogout(request);
  //   navigate(`/login`);
  // };

  const isLogin = JSON.parse(localStorage.getItem("userData"));
  const expaireToken = JSON.parse(localStorage.getItem("expjwToken"));
  const [controlsOpen, setControlsOpen] = useState(false);

  const OnClose = () => {
    setControlsOpen(false);
  };
  const ThemeControls = () => {
    setControlsOpen(true);
  };
  const authToken = JSON.parse(localStorage.getItem("jwToken"));
  const refToken = localStorage.getItem("rshjwToken");
  const refTokenKey = JSON.parse(localStorage.getItem("refreshTokenKey"));
  useEffect(() => {
    dispatch(getAuth([authToken, refToken]));

  },[authToken])
  const mini = useSelector((state) => state.controls.customizer.mini);
  const [miniVar, setMiniVar] = useState(mini);
  const setMiniVarient = () => {
    setMiniVar(!miniVar);
    dispatch(toggleMini(miniVar));
  };

  useEffect(() => {}, [miniVar]);   

  
  const profileImg = useSelector((state) => state.controls.customizer.userImg);
  

  const handleCloseUserMenu1 = async () => {
    window.localStorage.removeItem("menuList");
    window.localStorage.removeItem("isRefreshToken");

    var request = {};
    request.userId = userId;
    window.localStorage.removeItem("userData");
    const response = await userServices.saveLogout(request);
    if(response.status === 401){
      dispatch(logout());
      navigate(`/`);
    }
    window.localStorage.removeItem("jwToken");
    window.localStorage.removeItem("rshjwToken");
    window.localStorage.removeItem("skip");
    dispatch(logout());
    setAnchorEl(false);
    navigate(`/`);
  };

  return (
    isLogin && location.pathname !== "/userActivation" && location.pathname !== "/publictrack/Tracking" && (
      <Box>
        <Favicon url={`https://${favIconURLs}`} /> 
        <StyledAppBar
          position="fixed"
          elevation={0}
        >
          <Toolbar>
            <Stack sx={{maxWidth:`calc(${theme.sidebar.width} - 50px)`, width:'100%', overflow:'visible'}} direction={'row'} alignItems={'center'}>
                <IconButton
                  edge="start"
                  onClick={setMiniVarient}

                  sx={{
                    color:
                      headerTheme === "default"
                        ? theme.palette.text.secondary
                        : headerText,
                        fontSize:{xl:'1.5rem', lg:'1rem', md:'1rem'}
                  }}
                >
                  <HiOutlineMenu />
                </IconButton>
                <Box sx={{width:'100%', padding:'0px 10px'}}>
                    <CardMedia
                    component="img"
                    //src={neshLogo}
                    src={logoURLs !== "" && logoURLs !== 'null' ? `https://${logoURLs}` : logoPlaceHolder}
                    sx={{ 
                      width: "100%", 
                      height:'28px', objectFit:'contain'}}
                    />
                </Box>
            </Stack>
            <Box  sx={{ flexGrow: 1, }}/>

            
            {/* Right side */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Stack direction="row" alignItems="center" spacing={{xl:.6, lg:.5, md:.5, sm:.3}}>
                {/* Multiple Accounts */}
                <MultipleAccount />  

                {/* Profile */}
                <ListItem
                  onClick={handleClick}
                  secondaryAction={
                    <IconButton size="small" id="Profile-menu">
                      <FiChevronDown />
                    </IconButton>
                  }
                >
                  <ListItemAvatar sx={{minWidth:{xl:56, lg:35, md:35, sm:25, minWidth:'0px !important', marginRight:'10px'}}}>
                    <Avatar
                      alt="user-img"
                      src={profileImg}
                      sx={{ 
                        background: theme.palette.success.main,
                        width:{xl:'30px', lg:'28px', md:'25px', sm:'25px'},
                        height:{xl:'30px', lg:'28px', md:'25px', sm:'25px'},
                        fontSize:'18px',
                       }}

                    >
                      {JSON.parse(localStorage.getItem("userData"))?.firstName[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{fontSize:{xl:14, lg:10}}}>
                        {JSON.parse(localStorage.getItem("userData"))?.firstName}<span> </span>
                        {JSON.parse(localStorage.getItem("userData"))?.lastName}
                      </Typography>
                    }
                    sx={{
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                    }}
                  />
                </ListItem>
                
              </Stack>
            </Box>
          </Toolbar>
        </StyledAppBar>

        {/* Profile menu list */}
        <Menu
          anchorEl={anchorEl}
          open={openss}
          sx={menuList}
          elevation={1}
          onClose={handleCloseUserMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 0,
            
            sx: {
              overflow: 'visible',
              minWidth:'300px',
              padding:'0px 5px',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: '50%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
        >
          <ListItem>
            <ListItemText sx={{textAlign:'center'}}
              primary={JSON.parse(localStorage.getItem("userData")).userName}
              secondary={
                <Typography variant="caption">
                  {`${JSON.parse(localStorage.getItem("userData")).orgName} | ${
                    JSON.parse(localStorage.getItem("userData")).userRole
                  }`}
                </Typography>
              }
            />
          </ListItem>
          <Divider sx={{mb:.8}}/>

          {settings.map((setting, index) => setting.show === true && (
            <StyledHeaderMenuBtn
              key={index}
              component={NavLink}
              to={setting.route}
              dense
            >
              <ListItemIcon sx={{ minWidth: "40px", fontSize: "22px", color:theme.palette.text.primary}}>
                {setting.icon}
              </ListItemIcon>
              <ListItemText primary={setting.title} sx={{color:theme.palette.text.primary}}/>
            </StyledHeaderMenuBtn>
          ))}
          <Divider  sx={{my:.8}}/>
          <StyledHeaderMenuBtn
              dense
              onClick={handleCloseUserMenu1}
            >
              <ListItemIcon sx={{ minWidth: "40px", fontSize: "22px", color:theme.palette.text.primary }}>
                <TbLogout2 />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </StyledHeaderMenuBtn>
            <Divider  sx={{my:.8}}/>
            <Box sx={{px:2, mb:.5}}>
              <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="caption"> {process.env.REACT_APP_VERSION}</Typography>
                <Stack direction={'row'}>
                    <Typography variant="body2" sx={{fontSize:'10px'}}>powered by</Typography>
                    <CardMedia component={'img'} src={poweredBy} sx={{width:'60px', height:'auto', objectFit:'contain', ml:.5}}/>
                </Stack>
              </Stack>
            </Box>
          {/* <ListItemButton onClick={handleCloseUserMenu1}>
            <ListItemIcon sx={{ minWidth: "40px", fontSize: "25px" }}>
              <AiOutlineLogout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton> */}
        </Menu>
        <Controls openControls={controlsOpen} closeControls={OnClose} />
      </Box>
    )
  );
}

export default HeaderNew;
