import { Collapse, Grid, MenuItem } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { StyledDropDown, StyledFormControl, StyledFormControlLabel } from '../../theme/StyledEle'
import { useGetDropdownListMutation } from '../../store/slice/ApiSlice';
import { useLocation } from 'react-router-dom';

function HelpdeskSettingOrgDropdown(props) {
    const { categoryist, register, setValue, reset, watch, selectedOrgType, resellerId, selectedField, handleChangeDrodown, userList, getSetting, } = props;
    const location = useLocation();
    //onLoad
    const setOnloadData = (categoryist) => {
        //setValue('category', '-1');
        //setValue('orgType', '-1')
    }



    //Region List Loaded
    let regionPayload = {
        value: "region",
        searchText: "",
    };
    let DealorPayload = {
        resellerId: resellerId, // Set Login Dealer ID
        value: "dealer",
        dealerId: "0",
        searchText: ""
      };

      let customerPayload = {
        resellerId: resellerId, // Set Login Dealer ID
        value: "customer",
        dealerId: "0",
        searchText: ""
      };

    //Get Region List
    const [ getRegionList, { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess } ] = useGetDropdownListMutation();

    //Get Dealer List
    const [ getDealerList, { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess } ] = useGetDropdownListMutation();

    //Customer List
    
  const [ getCustomerList, { data: customerdata, isLoading: customerLoading, isSuccess: customerSuccess } ] = useGetDropdownListMutation();

    useEffect(() => {
        if(categoryist.length > 0){
            setOnloadData(categoryist);
        }else{
            setValue('category', '-1');
            setValue('orgType', '-1')
        }
    },[categoryist])

    //region Callback
    const getRegion = useCallback(async (orgValue) => {
        if(orgValue !== '2'){
            let regionData = await getRegionList(regionPayload);
            setValue('region', regionData?.data?.data?.content[0]?.key);
            getDealer(regionData?.data?.data?.content[0].key);
        }
    },[watch('orgType')])


    const getDealer = useCallback(async (regionId) => {
        DealorPayload.regionId = regionId;
        let dealerData = await getDealerList(DealorPayload);
        setValue('dealer', dealerData?.data?.data?.content[0]?.key);
        getCustomer(dealerData?.data?.data?.content[0]?.key);
    },[watch('region')])


    const getCustomer = useCallback(async (dealerId) => {
        customerPayload.dealerId = dealerId;
        let customerdata = await getCustomerList(customerPayload);
        setValue('customer', customerdata?.data?.data?.content[0]?.key);
    },[watch('dealer')])


    const orgListMock = [
        {
          "key": "2",
          "value": "Organization",
        },
        {
          "key": "5",
          "value": "Dealer",
        },
        {
            "key": "3",
            "value": "Customer",
        },
        
    ]


    
  return (

        <Grid item lg={12}>
            <Grid container spacing={2}>
                {
                    location.hash === '#edit_ticket' ? 
                    <Grid item lg={6}>
                    <StyledFormControl fullWidth>
                        <StyledFormControlLabel>Filed</StyledFormControlLabel>
                        <StyledDropDown
                            size="small"
                            defaultValue={'-1'}
                            value={watch('field') !== undefined ? watch('field') : '-1' }
                            {...register('field')}
                            onChange={(event) => {
                                setValue('field', event.target.value);
                                handleChangeDrodown('field', event.target.value);
                            }}
                            
                        >
                             <MenuItem value={'-1'}>Select</MenuItem>
                            {
                                selectedField?.length > 0 && 
                                selectedField.map((item, index) =>  <MenuItem value={item.key} key={index}>{item.value}</MenuItem>) 
                            }
                        </StyledDropDown>
                    </StyledFormControl>
                </Grid> :
                <Grid item lg={6}>
                <StyledFormControl fullWidth>
                    <StyledFormControlLabel>Category</StyledFormControlLabel>
                    <StyledDropDown
                        size="small"
                        defaultValue={'-1'}
                        value={watch('category') !== undefined ? watch('category') : '-1' }
                        {...register('category')}
                        onChange={(event) => {
                            setValue('category', event.target.value);
                            handleChangeDrodown('category', event.target.value);
                        }}
                        
                    >
                         <MenuItem value={'-1'} disabled><em>Select</em></MenuItem>
                        {
                            categoryist.length > 0 && 
                            categoryist.filter(item => item.key !== "KYC Approval" && item.key !== "KYC Rejection").map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                            // (location.hash !== '#status_changed' && location.hash !== '#deleted')  ? categoryist.map((item, index) =>  <MenuItem value={item.key} key={index}>{item.value}</MenuItem>) :
                            // categoryist.filter(item => item.key !== "KYC Approval" && item.key !== "KYC Rejection").map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                        }
                    </StyledDropDown>
                </StyledFormControl>
            </Grid>
                }
               
                <Grid item lg={6}>
                    <StyledFormControl fullWidth>
                        <StyledFormControlLabel>Organization Type</StyledFormControlLabel>
                        <StyledDropDown
                            size="small"
                            defaultValue={'-1'}
                            value={watch('orgType') !== undefined ? watch('orgType') : '-1'  }
                            {...register('orgType')}
                            onChange={(event) => {
                                setValue('orgType', event.target.value)
                                getRegion(event.target.value);
                                handleChangeDrodown('orgType', event.target.value);

                            }}
                        >
                            <MenuItem value={'-1'} disabled><em>Select</em></MenuItem>
                            {
                                //selectedOrgType?.length > 0 && selectedOrgType.map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                                orgListMock?.length > 0 && orgListMock.map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                            }
                        </StyledDropDown>
                    </StyledFormControl>
                </Grid>
                <Grid item lg={12}>
                    <Collapse in={watch('orgType') !== '2' && watch('orgType') !== null && watch('orgType') !== '-1' && watch('orgType') !== undefined  ? true : false}>
                            <Grid container spacing={1}>
                            {
                    watch('orgType') !== '2' &&  
                    <Grid item lg={4}>
                    <StyledFormControl fullWidth>
                        <StyledFormControlLabel>Region</StyledFormControlLabel>
                        <StyledDropDown
                            size="small"
                            defaultValue={watch('region')}
                            value={watch('region')}
                            {...register('region')}
                            onChange={(event) => {
                                setValue('region', event.target.value);
                                getDealer(event.target.value);
                                handleChangeDrodown('region', event.target.value);
                            }}
                        >
                            {
                                regionData?.data !== null && regionData?.data?.content.map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                            }
                        </StyledDropDown>
                        </StyledFormControl>
                    </Grid>
                }
                {
                     watch('orgType') !== '2' && 
                    <Grid item lg={4}>
                        <StyledFormControl fullWidth>
                            <StyledFormControlLabel>Dealer</StyledFormControlLabel>
                            <StyledDropDown
                                size="small"
                                defaultValue={watch('dealer')}
                                value={watch('dealer')}
                                {...register('dealer')}
                                onChange={(event) => {
                                    setValue('dealer', event.target.value);
                                    getCustomer(event.target.value)
                                    handleChangeDrodown('dealer', event.target.value);
                                }}
                            >
                                {
                                    dealerdata?.data !== null && dealerdata?.data?.content.map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                                }
                            </StyledDropDown>
                        </StyledFormControl>
                    </Grid>
                }
                {
                    watch('orgType') !== '2' && watch('orgType') !== '5' && 
                    <Grid item lg={4}>
                        <StyledFormControl fullWidth>
                            <StyledFormControlLabel>Customer</StyledFormControlLabel>
                            <StyledDropDown
                                size="small"
                                defaultValue={watch('customer')}
                                value={watch('customer')}
                                {...register('customer')}
                                onChange={(event) => {
                                    setValue('customer', event.target.value);
                                    handleChangeDrodown('customer', event.target.value);
                                }}
                            >
                                {
                                    customerdata?.data !== null && customerdata?.data?.content.map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                                }
                            </StyledDropDown>
                        </StyledFormControl>
                    </Grid>
                }
                            </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        </Grid>
  )
}

export default HelpdeskSettingOrgDropdown