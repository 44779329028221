import { Marker } from '@react-google-maps/api';
import React from 'react'

function GeoZoneMarkers(props) {
    const { data } =  props;
  
  return (
    data.map((item, index) => {
        let num = index + 1;
        let numStr = num.toString();

        return(
            <Marker 
            id={item.id}
            position={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
            label={{
              text:numStr,
              color:'#fff',
              fontWeight:'700'
            }}
            zIndex={999}
        />
        )
    })
  )
}

export default GeoZoneMarkers