import { FormControl, FormLabel, TextField } from "@mui/material";
import { React, useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { Alert } from "@mui/material";
import { StyledFormLabel } from "./StyledEle";
import { StyledFormControlLabel } from "../../theme/StyledEle";
const TextFieldInput = ({
  label,
  name,
  disabledKey,
  defaultValue,
  value,
  textInputCallBackFn,
  callbackfnStatus = false,
  message,
  requiredStatus = true,
  onChange
}) => {
  const theme = useTheme();
  const [defaultVal, setDefaultVal] = useState(defaultValue);
  const [text, setText] = useState("text");
  const labelStyle = {
   
  };
 

  const textFontStyle = {
    ".MuiInputBase-input": {
      fontSize: 14,
      fontWeight: 500,
    },
  };
  const textFontStyleDisabled = {
    ".MuiInputBase-input": {
      fontSize: 12,
      paddingBottom: "5px",
      paddingLeft: "7px",
      backgroundColor: "#eee",
    },
  };

  const callbackfn = (e) => {    
    setDefaultVal(e.target.value);
    textInputCallBackFn(e.target.value, name);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <InputWrapper>
      <FormControl fullWidth>
        {/* <ThemeProvider theme={asterisk}> */}
        {requiredStatus === true && (
          <StyledFormControlLabel required>{label}</StyledFormControlLabel>
        )}
        {requiredStatus === false && (
          <StyledFormControlLabel>{label}</StyledFormControlLabel>
        )}
        {/* </ThemeProvider> */}
        <TextField
          required={requiredStatus === true && true}
          error={text === "" ? true : false}
          name={name}
          //label={label}
          value={value}
          disabled={disabledKey === true ? true : false}
          defaultValue={defaultVal}          
          // onKeyUp={(e)=> {            
          //   if (e.key === "Tab" ) {              
          //     callbackfnStatus === true
          //     ? callbackfn(e)
          //     : setDefaultVal( e.target.value );

          //     }
          //   }
          // }
          onMouseOut={(e) => {
            callbackfnStatus === true
              ? callbackfn(e)
              : setDefaultVal(e.target.value);
          }}
          
          sx={textFontStyle}
          helperText={text === "" ? "Please Fill " + label : ""}
          onChange={onChange}
          size="small"
        />
      </FormControl>
    </InputWrapper>
  );
};
export default TextFieldInput;
