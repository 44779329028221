const CREATE_GEOZONE = [
  {
    type: "select",
    label: "Zone Type",
    name: "zoneType",
    options: [
      {
        id: 1,
        title: "Circle",
        value: "1",
      },
      {
        id: 2,
        title: "Polygon",
        value: "2",
      },
    ],
  },
  {
    type: "text",
    label: "Zone Name",
    name: "zoneName",
  },
  {
    type: "select",
    label: "Radius(m)",
    name: "zoneRadius",
    options: [
      {
        id: 1,
        title: "100",
        value: "100",
      },
      {
        id: 2,
        title: "200",
        value: "200",
      },
      {
        id: 3,
        title: "300",
        value: "300",
      },
      {
        id: 1,
        title: "400",
        value: "400",
      },
      {
        id: 1,
        title: "500",
        value: "500",
      },
    ],
  },
];

module.exports = {
  CREATE_GEOZONE,
};
