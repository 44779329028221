import React, { useState, useEffect } from "react";
import {Grid,Box,Typography,Stack, MenuItem, FormHelperText, InputAdornment, useTheme} from "@mui/material";
import { SoftButton, StyledDropDown, StyledFilterSearch, StyledFormControl, StyledFormControlLabel } from "../../theme/StyledEle";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { useUpdateOrgCustSettMutation, useUpdateUserCustSettMutation } from "../../store/slice/ProfileSlice/ProfileSlice";
import { PulseLoader } from "react-spinners";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";
import { useGetDropdownListMutation } from "../../store/slice/ApiSlice";

export default function ChangeCustomerSettings() {

  const theme = useTheme();
  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId;
  let roleId = userData?.roleId;
  let orgType = userData?.userOrgType;
  let orgId = userData?.orgId;
  let defaultRegion = userData?.defaultRegionId;
  let defaultDealer = userData?.defaultDealerId;
  let defaultCustomer = userData?.defaultCustomerId;

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues:{
      regionId:'0',
      dealerId:'0',
      customerId:'0',
    }
  });

   // All service -------------------------------------------------------------------
  const [getRegionList] = useGetDropdownListMutation();
  const [ getDealer] = useGetDropdownListMutation();
  const [getCustomer] = useGetDropdownListMutation();
  const [getOrgList] = useGetDropdownListMutation();
  const [userCustUpdate,{isLoading: userCustUpdateLoading}] = useUpdateUserCustSettMutation();
  const [orgCustUpdate,{isLoading: orgCustUpdateLoading},] = useUpdateOrgCustSettMutation();

  const [resellers, setResellers] = useState([]);
  const [region, setRegion] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [customer, setCustomer] = useState([]);
  const[reseller,setReseller] = useState(null);


  useEffect(() => {
    if(orgType === 8){
      loadOrg();
    }else if(orgType === 5){
      setValue('dealerId',orgId)
    } 

    if(defaultRegion !== 0 && resellerId !== null){
      getSelectedValue(defaultRegion,defaultDealer,defaultCustomer);
    }else{
      loadRegion();
    }
  }, []);

const loadOrg = async() =>{
  const resllrPyld = {
    value: "reseller",
    roleId: roleId,
    searchText: "",
    orgType: "2",
    dealerId: 0,
    id: resellerId ,
  };
  const { data: resllrResp } = await getOrgList(resllrPyld);
  const { content: orgnLsstJsn } = resllrResp.data;
  setResellers(orgnLsstJsn);
}
  const loadRegion = async (value) => {
    const request = {
      value: "region",
      searchText: "",
      resellerId:value,
    };
    const { data: result } = await getRegionList(request);
    setRegion(result.data.content);
  };

  const getDealerList = async (value) => {
    const dealerpayload = {
      value: "dealer",
      orgType: "2",
      resellerId:reseller == null?resellerId:reseller,
      dealerId: 0,
      regionId : value,
      searchText: "",
    };
    const { data: result } = await getDealer(dealerpayload);
    const { content: dealerLsstJsn } = result.data;
    setDealer(dealerLsstJsn);

    if(value !== defaultRegion){
    setValue('dealerId', '0');
    setValue('customerId', '0');
    }
};

  const getCustomerList = async (value) => {
      const customerPayload = {
        value: "customer",
        resellerId: reseller == null?resellerId:reseller ,
        dealerId: value,
        searchText: "",
      };
      const { data: customerListResponse } = await getCustomer(customerPayload);
      const { content: customerList } = customerListResponse.data;
      setCustomer(customerList);
      if(value !== defaultDealer){
       
        setValue('customerId', '0');
        }
  };

  const handleSearchDropdown = async (searchText, name) => {
    if (name === "dealer") {
      const dealerListPayload = {
        value: "dealer",
        resellerId: reseller == null?resellerId:reseller,
        searchText: searchText,
        regionId: -1,
      };
      const { data: dealerListResponse } = await getDealer(dealerListPayload);
      const { content: dealerList } = dealerListResponse.data;
      setDealer(dealerList);
    }
  };

  const getSelectedValue = (regId,dealerId,customerId) => {
    //load dropdown
      loadRegion();
      getDealerList(regId);
      getCustomerList(dealerId);
    //reflect dat
    setValue('regionId', regId);
    setValue('dealerId', dealerId);
    setValue('customerId', customerId);
  }

  const handleSave = async(data) =>{
    var commonPayload = {};
    commonPayload.resellerId=reseller == null?resellerId:reseller;
    commonPayload.regionId=data.regionId;
    commonPayload.dealerId=data.dealerId;
    commonPayload.customerId=data.customerId;
    if(orgType === 8){
      const response = await orgCustUpdate(commonPayload);
      dispatch(snackService([response?.data?.resultMessage, response?.data?.resultCode, true]));
      localStorage.setItem('userData',JSON.stringify({...userData,defaultRegionId:response?.data?.data?.regionId,defaultDealerId:response?.data?.data?.dealerId,defaultCustomerId:response?.data?.data?.customerId}))
      
    }else{

      const response = await userCustUpdate(commonPayload);
      dispatch(snackService([response?.data?.resultMessage, response?.data?.resultCode, true]));
      localStorage.setItem('userData',JSON.stringify({...userData,defaultRegionId:response?.data?.data?.regionId,defaultDealerId:response?.data?.data?.dealerId,defaultCustomerId:response?.data?.data?.customerId}))
      //getSelectedValue(response?.data?.data?.regionId,response?.data?.data?.dealerId,response?.data?.data?.customerId);
    }
  }


  return (
        <Box>
          <Typography variant="h6" gutterBottom sx={{mb:4}}>Customer Settings</Typography>
          <Box component='form' onSubmit={handleSubmit(handleSave)}>
            <Grid container spacing={2}>
              {
                orgType === 8 && 
                <Grid item lg={6}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Select Organization
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.resellerId && true}
                    {...register("resellerId", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("resellerId", e.target.value);loadRegion(e.target.value);setReseller(e.target.value)
                    }}
                    defaultValue={ "0" }
                    size="small"
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {resellers.length > 0 &&
                      resellers.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  <FormHelperText error={errors.resellerId && true}>
                    {errors.resellerId?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
              }
            {orgType !== 5 &&
            <Grid item lg={6}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Region
                    </StyledFormControlLabel>
                    <StyledDropDown
                      error={errors.regionId && true}
                      {...register("regionId", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                        validate: (fieldValue) => {
                          return fieldValue !== "0" || " Required Field";
                        },
                      })}
                      onChange={(e) => {
                        setValue("regionId", e.target.value);getDealerList(e.target.value)}
                      }
                      size="small"
                      value={watch('regionId')}
                    >
                      <MenuItem value="0">Select</MenuItem>
                      {region.length > 0 &&
                        region.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                    <FormHelperText error={errors.regionId && true}>
                      {errors.regionId?.message}
                    </FormHelperText>
                  </StyledFormControl>
                </Grid>
                }
                {orgType !== 5 &&
            <Grid item lg={6}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Dealer
                    </StyledFormControlLabel>
                    <StyledDropDown
                      error={errors.dealerId && true}
                      {...register("dealerId", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                        validate: (fieldValue) => {
                          return fieldValue !== "0" || " Required Field";
                        },
                      })}
                      onChange={(e) => {
                        getCustomerList(e.target.value);
                        setValue("dealerId", e.target.value);
                      }}
                      value={watch('dealerId')}
                      size="small"
                    >
                      <StyledFilterSearch
                        size="small"
                        name="dealer"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleSearchDropdown(e.target.value, e.target.name)
                        }
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      <MenuItem value="0">Select</MenuItem>
                      {dealer.length > 0 &&
                        dealer.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                    <FormHelperText error={errors.dealerId && true}>
                      {errors.dealerId?.message}
                    </FormHelperText>
                  </StyledFormControl>
                </Grid>
}
            <Grid item lg={6}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Customer
                    </StyledFormControlLabel>
                    <StyledDropDown
                      error={errors.customerId && true}
                      {...register("customerId", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                        validate: (fieldValue) => {
                          return fieldValue !== "0" || " Required Field";
                        },
                      })}
                      onChange={(e) => {
                        setValue("customerId", e.target.value);
                      }}
                      value={watch('customerId')}
                      size="small"
                    >
                      <StyledFilterSearch
                        size="small"
                        name="customer"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleSearchDropdown(e.target.value, e.target.name)
                        }
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      <MenuItem value="0">Select</MenuItem>
                      {customer.length > 0 &&
                        customer.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                    <FormHelperText error={errors.customerId && true}>
                      {errors.customerId?.message}
                    </FormHelperText>
                  </StyledFormControl>
                </Grid>
                </Grid>
                <Stack direction='row' justifyContent='flex-end'>
                <SoftButton
                  type="submit"
                  color="success"
                  className="success"
                  size="small"
                  sx={{mt:3}}
                  disableElevation
                >
                   {userCustUpdateLoading || orgCustUpdateLoading ? (<PulseLoader
                  color={theme.palette.success.contrastText}
                  size={6}
                />
              ) :
                  'Save'}
                </SoftButton>
                </Stack>
          </Box>

     
         </Box>
  );
}
