import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader } from "@mui/material";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { useKycProcessedListMutation } from "../../store/slice/AdminSlice/AdminSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import AlertDropdown from "../view-alerts/AlertsDropdown/AlertDropdown";



const ListDealerProcessing = ({ onEditDealer, onViewDealer }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [commonFilter, setCommonFilter] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    //await fetchKYCProcessedDetails();

    auditPayload.message = "Admin > Dealer > KYC Processing";
    auditPayload.action = "View";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Legal Name",
        accessor: "legalName",
      },
      {
        Header: "Trade Name",
        accessor: "tradeName",
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
      },
      {
        Header: "Email",
        accessor: "email",
        width:180,
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Country",
        accessor: "countryName",
      },

      {
        Header: "State",
        accessor: "stateName",
      },
      {
        Header: "City",
        accessor: "cityName",
      },
      {
        Header: "Pincode",
        accessor: "pinCode",
      },
      {
        Header: "Aadhar No",
        accessor: "aadharNo",
      },
      {
        Header: "PAN No",
        accessor: "panNo",
      },
      {
        Header: "GST IN",
        accessor: "gstIn",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Approved Time",
        accessor: "approvedDate",
      },
      {
        Header: "Dealer Id",
        accessor: "dealerId",
      },
      {
        Header: "Region",
        accessor: "region",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Action",
        accessor: "buttonEditView",
      },
    ],
    []
  );

  const onEdit = (itemEdited) => {
    onEditDealer(itemEdited);

    auditPayload.message = "Admin > Dealer > KYC Processing > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  
  
  const onView = (itemViewed) => {
    onViewDealer(itemViewed, 'view');

    auditPayload.message = "Admin > Dealer > KYC Processing > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  const [ getKycProcessed, {data, isLoading, isSuccess} ] = useKycProcessedListMutation();
  let commonPayload = {};
  const [payload, setPayload] = useState(null);
  const getData = async (data) => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "dealer"
              ) {
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                }
                setCommonFilter(dataStr);

                break;
              }
            }
          }
        }
      }
    }
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.search = "";
    commonPayload.resellerId = resellerId;
    commonPayload.columnName = "created_date";
    commonPayload.orderDir = "desc";
    commonPayload.startDate =data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.searchBy=data.searchBy;
    commonPayload.state=data.statusId;
    setPayload(commonPayload);
    getKycProcessed(commonPayload);

if(data.searchBy==="Date"){
  auditPayload.message = "Admin > Dealer > Dealer KYC Processing > "+data.searchBy+"("+data.fromDate+"-"+data.toDate+") > "+"Submit";
}else if(data.searchBy === "All"){
  auditPayload.message = "Admin > Dealer > Dealer KYC Processing > "+data.searchBy+" > "+"Submit";
}
    auditPayload.action = "Submit";
    auditPayload.featureName = "user";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "Dealer/dealerKycProcessedXLS.xls?resellerId=" +
        payload.resellerId +
        "&search=" +
        payload.search +
        "&columnName=" +
        payload.columnName +
        "&state=" +
        payload.state +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&searchBy=" +
        payload.searchBy +
        "&orderDir=" +
        payload.orderDir;
      window.open(url);

      auditPayload.message = "Reports > Dealer > KYC Processing > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Dealer";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      }
    }
  }, [isSuccess]);

  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getKycProcessed(payload);
    } else if (payload.search.length === 0) {
      getKycProcessed(payload);
    }
  };


      //Page Change
      const pageChange = (newPage, rowsPerPage) => {
        setNewPage(newPage);
        setRowsPerPage(rowsPerPage);
        payload.page = newPage;
        payload.size = rowsPerPage;
        getKycProcessed(payload);
      };

  return (
    <Box>
       <AlertDropdown getData={getData} isShowAll={false} /> 
      <StyledCardShadow>
        <CardHeader
          title={<StyledTableTitle>Dealer KYC Processing</StyledTableTitle>}
        />
        <CardContent sx={{ py: 0 }}>
          <DataTable
            isLoading={isLoading}
            includeSearchBox={true}
            handleChange={handleSearch}
            onEdit={onEdit}
            onView={onView}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            commonFilter={commonFilter}
            onChangeXcl={onChangeXcl}
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default ListDealerProcessing;
