import { Backdrop, Box, CardMedia, Grid, List, Snackbar, Stack, Typography, alpha, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Form from './Form'
import { SoftButton } from '../../theme/StyledEle'
import styled from 'styled-components'
import DelaerList from './DelaerList'
import Landing from './Landing'
import EnqueryForm from './EnqueryForm'
import Status from './Status'
import { useGetCityMutation, useGetDealerListMutation, useGetStateMutation, useSaveTestDriveMutation } from '../../store/slice/TestRideSlice/TestRideSlice';
import SingleDropdown from '../NewDropdown/SingleDropdown';
import placeholderImg from '../../assets/images/testRide.png'
import banner from '../../assets/images/testRidebanner.webp'
import { useLocation } from 'react-router-dom'
import { DotLoader, ScaleLoader } from 'react-spinners'
import rideImg from '../../assets/images/testRide/landingRider.svg'

function BookRide() {
    const theme = useTheme();
    const [pageTab,setPageTab] = useState(0);
    const [dealers, setDealers] = useState(null);

    const [state, setState] = useState(0);
    const [city, setCity] = useState(0);
    const [stateList, setStateList] = useState(null);
    const [cityList, setCityList] = useState(null);
    const [selectDealerId, setSelectEdDealerId] = useState({name:null, address:null});
    const [msg, setMsg] = useState('Please Select State & City');
    const [error, setError] = useState(false);
    const [errorMsg, seterrorMsg] = useState(null);

    const [ getState , { data:StateData, isLoading:stateLoading, isSuccess:stateSuccess} ]  = useGetStateMutation();
    const [ getCity , { data:CityData, isLoading:cityLoading, isSuccess:citySuccess} ]  = useGetCityMutation();
    const [ getDealerListByCity , { data:DealerData, isLoading:dealerLoading, isSuccess:dealerSuccess} ]  = useGetDealerListMutation();
    const [ saveTestDrive , { data:saveTestDate, isLoading:testDriveLoading, isSuccess:testDriveSuccess} ]  = useSaveTestDriveMutation();

    const location = useLocation();
    useEffect(() => {
        if(location.hash !== null){
            setApiKey(location.hash.split('#')[1]);
        }
    },[location]);
    

    

    const [apiKey, setApiKey] = useState(null);

    useEffect( async () => {
        const StateList = await getState();
        setStateList(StateList.data);
    },[])

    const handleTab = (tabValue) =>  {
        setPageTab(tabValue);
    }
    const handleState = async (event) => {
        setState(event.target.value);
        if(event.target.value !== 0){
            const cityList = await getCity({'stateId':event.target.value});
            setCityList(cityList.data);
            setCity(cityList.data[0].key);
        }else{
            setCity(0);
            setCityList(null);
        }
    }

    const handleCity = (event) => {
        setCity(event.target.value);
    }

    useEffect(() => {
        if(city !== 0){
            getDealerList();
            setSelectEdDealerId(null);
        }
    },[city])
    
    const getDealerList = async () => {
        const delaerList = await getDealerListByCity({'cityId':city, 'apiKey':apiKey});
        if(delaerList.data.length > 0){
            setMsg(null);
        }else{
            setMsg('Dealers not found');
        }
        setDealers(delaerList.data);
    }

    const handleSubmit = () => {
        if(selectDealerId.name !== null && selectDealerId.address !== null){
            setPageTab(2)
        

        }else{
            setError(true);
            seterrorMsg('Please Select Dealer');
        }
    }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
  };


    const getCheckBoxValue = (name, address) => {
        setSelectEdDealerId({name:name, address:address})
    }

  return (
    apiKey !== null ? 
    <Box sx={{width:'100%', height:'100vh', margin:'auto', background:'#fff'}}>
        {
            pageTab === 0 ? <Landing handleTab={handleTab}/> : 
            pageTab === 1 ? 
            <Box sx={{width:{lg:'555px', md:'100%', sm:'100%'}, height:'100vh', margin:'auto', background:'#fff', position:'relative'}}>
                <Box sx={{height:'100%', display:'grid', gridTemplateRows:'auto 1fr auto', position:'relative'}}>
                    <Box sx={{
                        background:`url(${banner})`, 
                        backgroundSize:'cover', 
                        height:'200px',
                        borderRadius:'0px 0px 35px 35px',
                        position:'relative',
                        '&:before':{
                        position:'absolute',
                        width:'100%',
                        height:'100%',
                        content:"''",
                        background:alpha(theme.palette.common.black, .2),
                        borderRadius:'0px 0px 35px 35px',
                        zIndex:1,
                        }
                        }}>
                        <Box sx={{zIndex:99, position:'relative', transform:'translateY(15px)'}}>
                        <Typography sx={{textAlign:'center', color:'#fff', fontWeight:500}} gutterBottom variant='h5'>BOOK YOUR TEST RIDE</Typography>
                        <Form stateValue={state} cityValue={city} stateList={stateList} cityList={cityList} handleCity={handleCity} handleState={handleState} />
                        </Box>
                    </Box>
                    <Box sx={{marginTop:'35px', overflow:'hidden', overflowY:'scroll', px:2}}>
                        {
                            dealers !== null && dealers.length > 0 ? 
                            <>
                            <Typography variant='h6' sx={{background:'#fff', position:'sticky', top:-2, zIndex:999, boxShadow:'0px 5px 8px rgba(0,0,0,0.02)'}}>Dealer List</Typography>
                            <List>
                                {
                                    !dealerLoading &&
                                    dealers !== null ? dealers?.map((dealerData, index) => <DelaerList data={dealerData} getId={getCheckBoxValue} id={selectDealerId} index={index}/>) : <Typography sx={{textAlign:'center', mt:2}}>{msg}</Typography> 
                                   
                                }
                            </List>
                            </>
                            :
                            <>
                            <CardMedia component={'img'} src={rideImg} sx={{width:"50%", margin:"auto",  mt:5,}}/>
                            <Typography variant='h6' sx={{textAlign:'center', mt:2}} color={'text.secondary'}>{msg}</Typography>
                            </>
                        }
                    </Box>
                    <Box sx={{px:2, py:1}}>
                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                            <SoftButton sx={{background:'#e8edf5', color:'#28282b'}} size='large' onClick={() => handleTab(0)}>
                                Back
                            </SoftButton>
                            <SoftButton variant='contained' sx={{background:'#008000', color:'#fff'}} size='large' onClick={handleSubmit} disabled={selectDealerId !== null && city !== 0 ? false : true}>
                                Book a slot
                            </SoftButton>
                           
                        </Stack>
                    </Box>
                </Box>
            </Box> : 
            pageTab === 2 ? <EnqueryForm handleTab={handleTab} selectDealer={selectDealerId} state={state} city={city} saveData={saveTestDrive} isLoading={testDriveLoading} apiKey={apiKey}/> : <Status />
        }
        <Snackbar
            open={error}
            autoHideDuration={3000}
            onClose={handleClose}
            message={errorMsg}
            //action={action}
            sx={{
                bottom: { xs: 90, sm: 0 }
            }}
        />
    </Box> :
    <Backdrop open={true}>
        <ScaleLoader height={25} color='#fff'/>
    </Backdrop>
  )
}

export default BookRide