import { Box, FormControlLabel, Radio, RadioGroup, ToggleButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledFilterFormControl, StyledFormControlLabel, StyledThemeRadio, StyledThemeRadioIcon, StyledToggleButtonGroup } from "../theme/StyledEle";
import { FaMoon, FaSun } from "react-icons/fa";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { toggleMode, toggleTheme, toggleFont } from "../store/slice/ControlsSlice";
import { useGetThemeListQuery } from "../store/slice/ApiSlice";
// Modes start
    export const ThemeModes = () => {
        //get themelist from service
        const resellerID = JSON.parse(localStorage.getItem("userData")).resellerId;
        const { data, isLoading }  = useGetThemeListQuery(resellerID);
        const [themevalue, setThemeValue] = useState([]);
        const getTheme = () => {
            if(!isLoading){
                setThemeValue(data?.logoData);
            }
        }
        useEffect(() => {
            getTheme();
        })
        
        //Day or Night
        const modes = useSelector((state) => state.controls.customizer.mode)
        const [themeMode, setThemeMode] = useState(modes);
        const dispatch = useDispatch();
        const handleTheme = (event, newThemeMode ) => {
            if(newThemeMode !== null){
                setThemeMode(newThemeMode);
            }
            dispatch(toggleMode(themeMode));
        };

        //Themes
        const themeView = useSelector((state) => state.controls.customizer.themeView)
        const [themes, setThemes] = useState(themeView);
        const handleThemeView = (event, newThemeView ) => {
            if(newThemeView !== null){
                setThemes(newThemeView);
            }
        };
        useEffect(() => {
            dispatch(toggleTheme(themes));
        },[themes])


        //Font Family
        const themeFont = useSelector((state) => state.controls.customizer.fontFamily)
        const [font, setFont] = useState(themeFont);
        const handleFontFamily = (event, newThemeFont ) => {
            if(newThemeFont !== null){
                setFont(newThemeFont);
            }
        };
        useEffect(() => {
            dispatch(toggleFont(font));
        },[font])

        //UI
       return(
        <Box>
            <Box>
            <Typography variant="body1" gutterBottom>Modes</Typography>
            <StyledToggleButtonGroup sx={{width:'100%'}} value={themeMode} exclusive onChange={handleTheme} aria-label="theme mode">
                <ToggleButton value='light' sx={{width:'100%'}}> <Stack direction='row' alignItems='center' spacing={2}><FaSun/> <Typography>Light</Typography></Stack></ToggleButton>
                <ToggleButton value='dark' sx={{width:'100%'}}> <Stack direction='row' alignItems='center' spacing={2}> <FaMoon/> <Typography>Dark</Typography> </Stack></ToggleButton>
            </StyledToggleButtonGroup>
            </Box>
            <Box sx={{my:2}}>
                <Typography variant="body1" gutterBottom>Themes</Typography>
                <StyledFilterFormControl sx={{maxWidth:'100%'}}>
                    <RadioGroup sx={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr 1fr 1fr'}} onChange={handleThemeView} value={themes}>
                        {/* <FormControlLabel value={themevalue?.name} control={<StyledThemeRadio sx={{backgroundImage:'linear-gradient(to right, #023e8a 50%, #023047 50%)'}} />} sx={{m:0}}/> */}
                        {
                            themevalue?.map((themeItem,index) => {
                                return(
                                    <FormControlLabel key={index} value={themeItem.name!== null ? themeItem.name : 'default'} control={<StyledThemeRadio sx={{backgroundImage:`linear-gradient(to right, ${themeItem.primary.dark} 50%, ${themeItem.secondary.main} 50%)`}} className="muiThemeRadio-root" />} sx={{m:0}} />
                                )
                            })
                        }
                        {/* <FormControlLabel value='theme1' control={<StyledThemeRadio sx={{backgroundImage:'linear-gradient(to right, #3fa34d 50%, #01497c 50%)'}} />} sx={{m:0}}/>
                        <FormControlLabel value='theme2' control={<StyledThemeRadio sx={{backgroundImage:'linear-gradient(to right, #355070 50%, #e56b6f 50%)'}} />} sx={{m:0}}/>
                        <FormControlLabel value='theme3' control={<StyledThemeRadio sx={{backgroundImage:'linear-gradient(to right, #a100f2 50%, #354fab 50%)'}} />} sx={{m:0}}/>
                        <FormControlLabel value='theme4' control={<StyledThemeRadio sx={{backgroundImage:'linear-gradient(to right, #ad744e 50%, #ffa86b 50%)'}} />} sx={{m:0}}/> */}
                    </RadioGroup>
                </StyledFilterFormControl>
            </Box>
            <Box sx={{my:2}}>
            <Typography variant="body1" gutterBottom>Font Family</Typography>
            <StyledToggleButtonGroup sx={{width:'100%'}} value={font} exclusive aria-label="theme mode" onChange={handleFontFamily} className="fontFamilyToggle">
            <ToggleButton value='Noto Sans SC'> <Stack direction='column' alignItems='center' spacing={2}><Typography variant="h6">Ag</Typography><Typography variant="body2">Noto Sans</Typography> </Stack></ToggleButton>
                <ToggleButton value='Sora'> <Stack direction='column' alignItems='center' spacing={2}><Typography variant="h6">Ag</Typography><Typography variant="body2">Sora</Typography></Stack></ToggleButton>
                {/* <ToggleButton value='Roboto'> <Stack direction='column' alignItems='center' spacing={2}> <Typography variant="h6">Ag</Typography><Typography variant="body2">Roboto</Typography> </Stack></ToggleButton> */}
                <ToggleButton value='Open Sans'> <Stack direction='column' alignItems='center' spacing={2}><Typography variant="h6">Ag</Typography><Typography variant="body2">Open Sans</Typography> </Stack></ToggleButton>
                {/* <ToggleButton value='Poppins'> <Stack direction='column' alignItems='center' spacing={2}><Typography variant="h6">Ag</Typography><Typography variant="body2">Poppins</Typography> </Stack></ToggleButton> */}
                <ToggleButton value='Space Grotesk'> <Stack direction='column' alignItems='center' spacing={2}><Typography variant="h6">Ag</Typography><Typography variant="body2">Space Grotesk</Typography> </Stack></ToggleButton>
            </StyledToggleButtonGroup>
            </Box>
        </Box>
       )
    }
// Modes End
