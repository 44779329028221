import React, { Fragment, useMemo } from 'react'
import { DataTable } from '../shared/data_table/DataTable';
import { Box } from '@mui/material';


function FirmwareList(props) {
    const {isLoading, data, totalElements, totalPages, newPage, rowsPerPage, pageChange, handleSearch, onDelete,commonFilter } = props;
    
    const columns = useMemo(
        () => [
          // {
          //   Header: "Id",
          //   accessor: "id",
          // },
          // {
          //   Header: "Company Id",
          //   accessor: "companyId",
          // },
          // {
          //   Header: "Description",
          //   accessor: "description",
          // },

          {
            Header: "Type",
            accessor: "type",
          },
          // {
          //   Header: "Created By",
          //   accessor: "createdBy",
          // },
          // {
          //   Header: "File Path",
          //   accessor: "filePath",
          //   Cell: ({ row, column, cell }) => {
          //     return (
          //       <Box sx={{ display: "flex", alignItems: "center" }}>
          //         <Box
          //           id={row.original.filePath}
          //           sx={{ display: "flex", alignItems: "center" }}
          //         >

          //           <a
          //             href={`https://${row.original.filePath}`}
          //             target="_blank"
          //             id={row.original.filePath}
          //           >
          //             {row.original.filePath}
          //           </a>

          //         </Box>
          //       </Box>
          //     );
          //   },
          // },
          // {
          //   Header: "File Size",
          //   accessor: "fileSize",
          // },
          {
            Header: "File Name",
            accessor: "fileName",
            Cell: ({ row, column, cell }) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    id={row.original.filePath}
                    sx={{ display: "flex", alignItems: "center" }}
                  >

                    <a
                      href={`https://${row.original.filePath}`}
                      target="_blank"
                      id={row.original.filePath}
                    >
                      {row.original.fileName}
                    </a>

                  </Box>
                </Box>
              );
            },
          },
          // {
          //   Header: "Updated By",
          //   accessor: "updatedBy",
          // },
          {
            Header: "Firmware Version",
            accessor: "firmwareVersion",
          },
          {
            Header: "Created Date",
            accessor: "createdDate",
          },
          {
            Header: "Action",
            accessor: "buttonDelete",
          },
          // {
          //   Header: "App Name",
          //   accessor: "appName",
          // }
        ],
    );

   



  return (
    <Fragment>
        <DataTable
            includeSearchBox={true}
            includeExportButton={false}
            columns={columns}
            data={data}
            isLoading={isLoading}
            totalElements={totalElements}
            totalPages={totalPages}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            handleChange={handleSearch}
            onDelete={onDelete}
            commonFilter={commonFilter}
        />
    </Fragment>
  )
}

export default FirmwareList