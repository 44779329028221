import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { FormLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StyledFormLabel } from "./StyledEle";
import { StyledFormControlLabel } from "../../theme/StyledEle";

const DatePicker = ({
  label,
  name,
  defaultValue,
  textDateCallBackFn,
  callbackfnStatus = false,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(defaultValue);

  const callbackfn = (newValue) => {
    setValue(
      newValue.getDate() +
        "/" +
        parseInt(newValue.getMonth() + 1) +
        "/" +
        newValue.getFullYear()
    );
    textDateCallBackFn(
      newValue.getDate() +
        "/" +
        parseInt(newValue.getMonth() + 1) +
        "/" +
        newValue.getFullYear(),
      name
    );
  };
  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };

  const handleChange = (newValue) => {
    setValue(
      newValue.getDate() +
        "/" +
        parseInt(newValue.getMonth() + 1) +
        "/" +
        newValue.getFullYear()
    );
    textDateCallBackFn(
      newValue.getDate() +
        "/" +
        parseInt(newValue.getMonth() + 1) +
        "/" +
        newValue.getFullYear(),
      name
    );
  };

  return (
    <InputWrapper>
      <FormControl>
        <StyledFormControlLabel>{label}</StyledFormControlLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                fullWidth
                size="small"
                {...params}
                name={name}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </InputWrapper>
  );
};
export default DatePicker;
