import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"
/*
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdle = ({ onIdle, idleTime = 1, getRemainingTime}) => {
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false)
    //const { logout } = useContext(AuthContext);
    const handleIdle = () => {
        setIdle(true);
    }
    const {idleTimer, getTabId, message}  = useIdleTimer(
    {
        timeout: idleTimeout,
        promptTimeout: idleTimeout / 2,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500,
        crossTab: true,
        syncTimers: 200,
    })
    
    return {
        isIdle,
        setIdle,
        idleTimer,
        getRemainingTime,
    }
}
export default useIdle;