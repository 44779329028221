import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import React from 'react'
import { SoftButton } from '../../theme/StyledEle'
import { useNavigate } from 'react-router-dom';
//import rideImg from '../../assets/images/testRide/landingRider.svg'
import rideImg from '../../assets/images/testRide/rides.svg'

function Landing({handleTab}) {
  let location = window.location.origin;

  const navigate = useNavigate();
  return (
    <Box sx={{width:{lg:'555px', md:'100%', sm:'100%'}, height:'100vh', margin:'auto', background:'linear-gradient(#f6fff8, #ffffff)', position:'relative', px:3, display:'flex', justifyContent:'center', flexDirection:'column'}}>
      {/* <CardMedia 
              component={'img'}
              src={rideImg}
              alt='ride_img'
              sx={{width:"60%", display:'flex', alignSelf:'center', marginBottom:'10px'}}
            /> */}
        <Box sx={{transform:'translateY(-75px)'}}>
            <Card elevation={0}>
              <CardContent sx={{textAlign:'center'}}>
                <Typography gutterBottom variant='h6'>
                        Please note that the given number is not a registered number with us. Check your number and try again.
                    </Typography>
                    <Typography gutterBottom variant='subtitle1'>
                        If the number is registered and you are unable to log in then please give us a call at
                    </Typography>
                    <Typography gutterBottom href='tel:1800120055500' variant='body1' component={'a'}
                    sx={{
                    fontSize:'1rem', 
                    padding:'5px 10px'
                    }}>
                            1800 120 055 500
                    </Typography>
              </CardContent>
            </Card>
            <Box sx={{px:3, textAlign:'center', mt:2}}>
                <Typography gutterBottom variant='body1' color={'grey'}>
                  Would you like to explore our products? We would love to show you our range of electric bikes. Our products are some of the best available in the market and we offer various styles and prices to suit your preferences.
                </Typography>
            </Box>
            <Box sx={{mt:2}}>
              <Stack direction={'column'} justifyContent={'space-between'} spacing={2} sx={{padding:'0px 25px'}}>
              <SoftButton variant='contained' sx={{background:'#008000', color:'#fff'}} size='large' onClick={() => handleTab(1)}>Continue</SoftButton>
                    <SoftButton size='large' variant='outlined' color='warning' component={'a'} href={`${location}/`}>Back to Login</SoftButton>
              </Stack>
            </Box>
        </Box>
        <CardMedia 
              component={'img'}
              src={rideImg}
              alt='ride_img'
              sx={{width:{lg:'555px', md:'100%', sm:'100%', xs:'100%'}, display:'flex', alignSelf:'center', marginBottom:'0px', position:'fixed', bottom:'0px'}}
            />
    </Box>
  )
}

export default Landing