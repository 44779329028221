import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/styles";
import ScaleLoader from "react-spinners/ScaleLoader";

export const BackDropLoader = () => {
  const theme = useTheme();
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "rgba(255,255,255,.25)",
      }}
      open={true}
    >
      {/* <CircularProgress color="primary" /> */}

      <ScaleLoader color={theme.palette.primary.main} />
    </Backdrop>
  );
};
