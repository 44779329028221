import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Box } from '@mui/material';
import zoneMarker from '../../assets/images/geoZoneMarker.png'; 
import { useTheme } from '@mui/material';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw/dist/leaflet.draw.js';


const LeafletGeozone = (props) => {
  const { zoneList,defaultZoom, selectZone,zoneRadius,mapType,createMarkerData,isEdit ,isCreate,zoneType,address,updateCreateMarkerData,drawingMode,updateCreatedPolygonData} = props;
  const mapRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [initialCoords, setInitialCoords] = useState([23.5120, 80.3290]);
  const theme = useTheme();
  const drawControlRef = useRef(null); 
  const drawnItemsRef = useRef(null); 

  useEffect(() => {
    if (defaultZoom !== undefined && defaultZoom !== null && defaultZoom >= 3 && defaultZoom <= 18) {
      setZoomLevel(defaultZoom);
    }
  }, [defaultZoom]);
  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map('map', {
        center: initialCoords,
        zoom: zoomLevel,
        zoomControl: false
      }).setView(initialCoords, zoomLevel);
      mapRef.current = map;
    } else {
      mapRef.current.setView(initialCoords, zoomLevel);
    }

    if (mapRef.current && mapRef.current.tileLayer) {
      mapRef.current.removeLayer(mapRef.current.tileLayer);
    }

    let tileLayerUrl = '';
    switch (mapType) {
      case 'roadmap':
        tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        break;
      case 'terrain':
        tileLayerUrl = 'https://tile.openstreetmap.de/{z}/{x}/{y}.png';
        break;
      case 'satellite':
        tileLayerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
        break;
      default:
        tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        break;
    }

    mapRef.current.tileLayer = L.tileLayer(tileLayerUrl, {}).addTo(mapRef.current);
  }, [initialCoords, zoomLevel, mapType]);
  

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.eachLayer(layer => {
        if (!layer._url) mapRef.current.removeLayer(layer);
      });

      const customIcon = L.icon({
        iconUrl: zoneMarker,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -32]
      });
  
      if (selectZone === null&& !isCreate) {
        // Render markers and circles for each zone in zoneList
        zoneList?.forEach(zone => {
          const { latitude, longitude, radius, zoneType, name, address, cordinates } = zone;
          if (zoneType === 'Circles') {
            const coordinates = zone.cordinates.split(',').map(parseFloat);
            const marker = L.marker([coordinates[0], coordinates[1]], { icon: customIcon }).addTo(mapRef.current);
            const popupContent = `
              <div style="min-width:330px">
                <div style="min-width:250px;padding:10px;background:#f76d25;display:flex;flex-direction:row;column-gap: 20px;margin-bottom:10px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                  <div style="min-width:"200px">
                    <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff"> Zone Name</div>
                    <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${name}</div>
                  </div>
                  <div >
                    <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff">Radius</div>
                    <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${radius}</div>
                  </div>
                </div>
                <div style="padding:5px 10px">
                  <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Address</div>
                  <div>${address}</div>
                </div>
              </div>
            `;
            marker.bindPopup(popupContent);
  
            L.circle([coordinates[0], coordinates[1]], {
              radius,
              color: theme.palette.secondary.main,
              fillOpacity: 0.4,
              strokeWeight: 0.8,
              strokeColor: theme.palette.secondary.main,
            }).addTo(mapRef.current);
          } else if (zoneType === 'Polygons') {
            if (cordinates) {
              try {
                const parsedCoordinates = JSON.parse(cordinates);
                const latLngArray = parsedCoordinates.map(coord => [coord.lat, coord.lng]);
  
                const polygon = L.polygon(latLngArray, {
                  color: theme.palette.secondary.main,
                  fillOpacity: 0.4,
                  strokeWeight: 0.8,
                  strokeColor: theme.palette.secondary.main,
                }).addTo(mapRef.current);
  
                const firstCoordinate = latLngArray[0];
                const marker = L.marker(firstCoordinate, { icon: customIcon }).addTo(mapRef.current);
  
                const popupContent = `
                  <div style="min-width:330px">
                    <div style="min-width:250px;padding:10px;background:#f76d25;display:flex;flex-direction:column;column-gap: 20px;margin-bottom:10px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                      <div style="min-width:"200px">
                        <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff"> Zone Name</div>
                        <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${name}</div>
                      </div>
                    </div>
                    <div style="padding:5px 10px">
                      <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Address</div>
                      <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;">${address}</div>
                    </div>
                  </div>
                `;
                marker.bindPopup(popupContent);
              } catch (error) {
                console.error('Error parsing coordinates JSON:', error);
              }
            }
          }
        });
      } else if (selectZone !== null && !isCreate && !isEdit) {
        // Render markers and shapes based on selectZone
        const { latitude, longitude, radius, zoneType, name, address, cordinates } = selectZone;
        if (zoneType === 'Circles') {
          const coordinates = cordinates.split(',').map(parseFloat);
          const marker = L.marker([coordinates[0], coordinates[1]], { icon: customIcon }).addTo(mapRef.current);
          const popupContent = `
            <div style="min-width:330px">
              <div style="min-width:250px;padding:10px;background:#f76d25;display:flex;flex-direction:row;column-gap: 20px;margin-bottom:10px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                <div style="min-width:"200px">
                  <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff"> Zone Name</div>
                  <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${name}</div>
                </div>
                <div >
                  <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff">Radius</div>
                  <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${radius}</div>
                </div>
              </div>
              <div style="padding:5px 10px">
                <div>Address</div>
                <div>${address}</div>
              </div>
            </div>
          `;
          marker.bindPopup(popupContent).openPopup();

          setInitialCoords([coordinates[0], coordinates[1]])
          setZoomLevel(14)

          L.circle([coordinates[0], coordinates[1]], {
            radius,
            color: theme.palette.secondary.main,
            fillOpacity: 0.4,
            strokeWeight: 0.8,
            strokeColor: theme.palette.secondary.main,
          }).addTo(mapRef.current);
        } else if (zoneType === 'Polygons') {
          if (cordinates) {
            try {
              const parsedCoordinates = JSON.parse(cordinates);
              const latLngArray = parsedCoordinates.map(coord => [coord.lat, coord.lng]);
              const polygon = L.polygon(latLngArray, {
                color: theme.palette.secondary.main,
                fillOpacity: 0.4,
                strokeWeight: 0.8,
                strokeColor: theme.palette.secondary.main,
              }).addTo(mapRef.current);

              setInitialCoords([latitude, longitude])
              setZoomLevel(14)

              const firstCoordinate = [latitude, longitude];
              const marker = L.marker(firstCoordinate, { icon: customIcon }).addTo(mapRef.current);

              const popupContent = `
                  <div style="min-width:330px">
                    <div style="min-width:250px;padding:10px;background:#f76d25;display:flex;flex-direction:column;column-gap: 20px;margin-bottom:10px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                      <div style="min-width:"200px">
                        <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff"> Zone Name</div>
                        <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${name}</div>
                      </div>
                    </div>
                    <div  style="padding:5px 10px">
                      <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Address</div>
                      <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;">${address}</div>
                    </div>
                  </div>
                `;
              marker.bindPopup(popupContent).openPopup();;
            } catch (error) {
              console.error('Error parsing coordinates JSON:', error);
            }
          }
        }
      } else if(selectZone !== null && !isCreate && isEdit){
        const { radius, zoneType, name, address, cordinates } = selectZone;
        if (zoneType === 'Circles') {
          const coordinates = cordinates.split(',').map(parseFloat);
          console.log("coordinates",coordinates)
          const marker = L.marker([coordinates[0], coordinates[1]], { icon: customIcon }).addTo(mapRef.current);
          const popupContent = `
            <div style="min-width:330px">
              <div style="min-width:250px;padding:10px;background:#f76d25;display:flex;flex-direction:row;column-gap: 20px;margin-bottom:10px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                <div style="min-width:"200px">
                  <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff"> Zone Name</div>
                  <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${name}</div>
                </div>
                <div >
                  <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff">Radius</div>
                  <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${radius}</div>
                </div>
              </div>
              <div style="padding:5px 10px">
                <div>Address</div>
                <div>${address}</div>
              </div>
            </div>
          `;
          marker.bindPopup(popupContent).openPopup();

          setInitialCoords([coordinates[0], coordinates[1]])
          setZoomLevel(14)

          L.circle([coordinates[0], coordinates[1]], {
            radius,
            color: theme.palette.secondary.main,
            fillOpacity: 0.4,
            strokeWeight: 0.8,
            strokeColor: theme.palette.secondary.main,
          }).addTo(mapRef.current);
        }
      }else if (selectZone === null && isCreate && createMarkerData?.lat !== null && zoneType === 'Circles') {
        const { lat, lng, address,  } = createMarkerData;

        const marker = L.marker([lat, lng], { icon: customIcon, draggable: true }).addTo(mapRef.current);
        const circle = L.circle([lat, lng], {
          radius:zoneRadius,
          color: theme.palette.secondary.main,
          fillOpacity: 0.4,
          strokeWeight: 0.8,
          strokeColor: theme.palette.secondary.main,
        }).addTo(mapRef.current);

        setInitialCoords([lat, lng]);
        setZoomLevel(14);

        marker.on('dragend', (event) => {
          const markerLatLng = event.target.getLatLng();
          const { lat: newLat, lng: newLng } = markerLatLng;
          updateCreateMarkerData(newLat, newLng);
        });
      }
      

    }
  }, [zoneList, selectZone,createMarkerData,zoneRadius,zoneType]);
  
  useEffect(() => {
    if (mapRef.current) {
      
      if ((zoneType === 'Polygons'&& isCreate) || (selectZone !== null&& zoneType === 'Polygons'&& !isCreate && isEdit)) {
        drawnItemsRef.current = new L.FeatureGroup(); 
        mapRef.current.addLayer(drawnItemsRef.current);

        drawControlRef.current = new L.Control.Draw({
          draw: {
            polygon: {
              allowIntersection: false,
              drawError: {
                color:"black",
                message: '<strong>Oh snap!</strong> You can\'t draw that!'
              },
              shapeOptions: {
                color: "black",
              }
            },
            polyline: false,
            rectangle: false,
            circle: false,
            marker: false
          },
          edit: {
            featureGroup: drawnItemsRef.current
          }
        });

        mapRef.current.addControl(drawControlRef.current);

        mapRef.current.on('draw:created', function (e) {
          const layer = e.layer;
          drawnItemsRef.current.addLayer(layer);
          const coordinates = layer.getLatLngs()[0].map(coord => ({ lat: coord.lat, lng: coord.lng }));
          updateCreatedPolygonData({
            "path": coordinates,
            "type": "polygon"
          })
        });
      } else {
        if (drawControlRef.current) { 
          mapRef.current.removeControl(drawControlRef.current);
        }
        if (drawnItemsRef.current) { 
          mapRef.current.removeLayer(drawnItemsRef.current);
        }
      }
    }
  }, [zoneType,isCreate,selectZone,isEdit]);

  
  useEffect(()=>{
    if(createMarkerData?.lat !== null){
      const { lat, lng, address,  } = createMarkerData;
      setInitialCoords([lat, lng]);
      setZoomLevel(14);
      }
  },[createMarkerData])

  return (
    <Box id="map" sx={{ width: '100%', height: '60vh' }}></Box>
  );
}

export default LeafletGeozone;