import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader } from "@mui/material";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { useKycFeedListMutation } from "../../store/slice/AdminSlice/AdminSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import AlertDropdown from "../view-alerts/AlertsDropdown/AlertDropdown";

const ListDealer = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
 
  useEffect(async () => {
    // await fetchKYCFeedDetails();

    auditPayload.message = "Admin > Dealer > KYC Request Log";
    auditPayload.action = "View";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Legal Name",
        accessor: "legalName",
      },
      {
        Header: "Trade Name",
        accessor: "tradeName",
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        width:180,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Address",
        accessor: "address",
        width:350,
      },
      {
        Header: "Country",
        accessor: "countryName",
      },

      {
        Header: "State",
        accessor: "stateName",
      },
      {
        Header: "City",
        accessor: "cityName",
      },
      {
        Header: "Pincode",
        accessor: "pinCode",
      },
      {
        Header: "Aadhar No",
        accessor: "aadharNo",
      },
      {
        Header: "PAN No",
        accessor: "panNo",
      },
      {
        Header: "GST IN",
        accessor: "gstIn",
      },
      {
        Header: "Dealer Id",
        accessor: "dealerId",
      },
      {
        Header: "Region",
        accessor: "region",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Reason",
        accessor: "errorMessage",
      }
    ],
    []
  );
  const [ getKycFeed, {data, isLoading, isSuccess} ] = useKycFeedListMutation();
  let commonPayload = {};
  const [payload, setPayload] = useState(null);
  const getData =(data) => {
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.search = "";
    commonPayload.resellerId = resellerId;
    commonPayload.columnName = "created_date";
    commonPayload.orderDir = "desc";
    commonPayload.startDate =data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.searchBy = data.searchBy
    setPayload(commonPayload);
    getKycFeed(commonPayload);
  };
  
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "Dealer/dealerKycFeedListXLS.xls?resellerId=" +
        payload.resellerId +
        "&search=" +
        payload.search +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir;
      window.open(url);

      auditPayload.message = "Reports > Dealer > KYC Feed Log > Export >Excel";
      auditPayload.action = "View";
      auditPayload.featureName = "Dealer";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      }
    }
  }, [isSuccess]);

  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getKycFeed(payload);
    } else if (payload.search.length === 0) {
      getKycFeed(payload);
    }
  };

    //Page Change
    const pageChange = (newPage, rowsPerPage) => {
      setNewPage(newPage);
      setRowsPerPage(rowsPerPage);
      payload.page = newPage;
      payload.size = rowsPerPage;
      getKycFeed(payload);
    };

  return (
    <Box>
       <AlertDropdown getData={getData} isShowAll={false} /> 
      <StyledCardShadow sx={{ mt: 1 }}>
        <CardHeader
          title={<StyledTableTitle>Dealer KYC Log</StyledTableTitle>}
        />
        <CardContent sx={{ py: 0 }}>
          <DataTable
            isLoading={isLoading}
            includeSearchBox={true}
            handleChange={handleSearch}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={2}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            onChangeXcl={onChangeXcl}
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default ListDealer;
