import { Box, Button, CardMedia, Divider, Select, FormLabel, Grid,Popover,Icon, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { StyledColorPicker, StyledDropDown, StyledFilterDropDown, StyledFilterFormControl, StyledFilterMenuItem, StyledTextField } from '../../theme/StyledEle'
import DropDown from "../../components/shared/DropDown";
import { StyledFormLabel } from '../../components/shared/StyledEle';
import Values from 'values.js';
import { TwitterPicker, SketchPicker } from "react-color";
import ThemeError from '../../assets/images/svgicons/ThemeError.svg'
import { useSaveThemeMutation, useGetThemeMutation } from '../../store/slice/ApiSlice';
import { useForm } from 'react-hook-form';
import dropdownServices from '../../services/dropdownServices';
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useDispatch, useSelector } from "react-redux";
import { IoMdColorFill } from "react-icons/io";
import { snackService } from '../../store/slice/ControlsSlice'
import {
    setTheme
} from "../../store/slice/ControlsSlice";
import { display } from '@mui/system';
function CreateTheme() {
    const theme = useTheme();
    const primaryRef = useRef();
    const [saveTheme, { isLoading: saveThemeLoading, isSuccess: saveThemeSucccess }] = useSaveThemeMutation();
    const [getTheme, { data: getThemeData, isLoading: getThemeLoading, isSuccess: getThemeSucccess }] = useGetThemeMutation();

    useEffect(() => {
        const payload = {
            resellerId: resellerId,
        };
        getTheme(payload)
    }, [])

    const dispatch = useDispatch();
    const [primaryColor, setPrimaryColor] = useState(new Values('#008000').all(15));
    const [secondaryColor, setSecondaryColor] = useState(new Values('#008000').all(15));
    const [treeViewColor, setTreeViewColor] = useState(new Values('#008000').all(15));
    const [sideBarColor, setSidebarColor] = useState(new Values('#008000').all(15));
    const [headerColor, setHeaderColor] = useState(new Values('#008000').all(15));
    const [selectedFont, setSelectedFont] = useState('Roboto');
    const [themeName, setThemeName] = useState(null);
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [selectedReseller, setSelectedReseller] = useState("");
    const [saveReseller, setSaveReseller] = useState("");
    const [open, setOpen] = useState(false);
    const setPrimaryColorPallete = (color) => {
        setPrimaryColor(color);

    }
    const setSecondaryColorPallete = (color) => {
        setSecondaryColor(color)
    }
    const setTreeViewColorPallete = (color) => {
        setTreeViewColor(color)
    }
    const setSideBarColorPallete = (color) => {
        setSidebarColor(color)
    }
    const setHeaderColorPallete = (color) => {
        setHeaderColor(color)
    }


    useEffect(() => {
        if (getThemeData !== undefined) {
            setPrimaryColorPallete(new Values(getThemeData[0]?.primary?.main).all(15));
            setSecondaryColorPallete(new Values(getThemeData[0]?.secondary?.main).all(15));
            setTreeViewColorPallete(new Values(getThemeData[0]?.treeView?.primary600).all(15));
            setSideBarColorPallete(new Values(getThemeData[0]?.sidebar?.main).all(15));
            setHeaderColorPallete(new Values(getThemeData[0]?.header?.main).all(15));
            setSelectedFont(getThemeData[0]?.fontFamily)
            setThemeName(getThemeData[0]?.themeName)
        }
    }, [getThemeData])

    const userData = JSON.parse(localStorage.getItem('userData'));

    let resellerId = userData?.resellerId;
    let orgType = userData?.userOrgType;


    const getThemeService = async (resellerValue) => {
        if(resellerValue){
        let themePayload = {
            resellerId: resellerValue,
        }

        let themeRes = await getTheme(themePayload);

        if (themeRes !== undefined && themeRes.data !== undefined && themeRes.data !== null) {
            dispatch(setTheme(themeRes.data));
        } else {
            dispatch(setTheme(null));
        }
    }
    }
    useEffect(() => {
        if (saveThemeSucccess) {
            getThemeService(saveReseller);
        }
    }, [saveThemeSucccess])

    const fontOptions = ['Inter', 'Noto Sans SC', 'Roboto', 'Rubik', 'Montserrat', 'Space Grotesk', 'Poppins'];

    const handleFontChange = (event) => {
        const newFont = event.target.value;
        setSelectedFont(newFont);
    }
    const handlePrimaryColor = (color, event) => {
        let Primarycolors = new Values(color.hex).all(15);
        setPrimaryColor(Primarycolors);
    }
    const handleSecondaryColor = (color, event) => {
        let Secondarycolors = new Values(color.hex).all(15)
        setSecondaryColor(Secondarycolors);
    }
    const handleTreeviewColor = (color, event) => {
        let treeViewColor = new Values(color.hex).all(15)
        setTreeViewColor(treeViewColor);
       
    }

    const handleSideBarColor = (color, event) => {
        let SideBarcolors = new Values(color.hex).all(15);
        setSidebarColor(SideBarcolors);
    }

    const handleHeaderColor = (color, event) => {
        let HeaderText = new Values(color.hex).all(15);
        setHeaderColor(HeaderText);
    }

    const { register, formState: { errors }, watch, handleSubmit } = useForm({ mode: 'all' })


    useEffect(() => {
    }, [watch])

    const onSubmit = (data) => {
        let selectedResellerId;
        if (orgType === 8) {
            selectedResellerId = selectedReseller;
        } else {
            selectedResellerId = resellerId;
        }

        let themeData = {
            "id": 0,
            "name": themeName,
            "themeName": themeName,
            "fontFamily": selectedFont,
            "primary": {
                "main": "#" + primaryColor[6].hex,
                "dark": "#" + primaryColor[10].hex,
                "light": "#" + primaryColor[2].hex,
                "contrastText": "#" + primaryColor[0].hex,
            },
            "secondary": {
                "main": "#" + secondaryColor[6].hex,
                "dark": "#" + secondaryColor[10].hex,
                "light": "#" + secondaryColor[2].hex,
                "contrastText": "#" + secondaryColor[0].hex,
            },
            "sidebar": {
                "main": "#" + sideBarColor[6].hex,
                "dark": "#" + sideBarColor[10].hex,
                "light": "#" + sideBarColor[2].hex,
                "contrastText": "#" + sideBarColor[0].hex,
            },
            "header": {
                "main": "#" + headerColor[6].hex,
                "dark": "#" + headerColor[10].hex,
                "light": "#" + headerColor[2].hex,
                "contrastText": "#" + headerColor[0].hex,
            },
            "treeView": {
                "primary700": "#" + treeViewColor[7].hex,
                "primary600": "#" + treeViewColor[6].hex,
                "primary500": "#" + treeViewColor[5].hex,
                "primary400": "#" + treeViewColor[4].hex,
                "primary300": "#" + treeViewColor[3].hex,
                "primary200": "#" + treeViewColor[2].hex,
            },
            "resellerId": selectedResellerId
        }
        setSaveReseller(selectedResellerId)
        saveTheme(themeData);
        setDataStatusConfirm("Success");
        setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }

    const [resellers, setResellers] = useState([])
    useEffect(async () => {
        const resellerPayload = {
            value: "reseller",
            orgType: "2",
            resellerId: 1,
            dealerId: 0,
            roleId: 1,
            id: 1,
            searchText: "",
        };
        const { data: orgTypeResponseNew } = await dropdownServices.getResellers(resellerPayload);
        setResellers(orgTypeResponseNew.data?.content);
    }, [])


    const handleResellerChange = (event) => {
        setSelectedReseller(event.target.value);
        getThemeService(event.target.value);

    };
    const [primaryAnchorEl, setPrimaryAnchorEl] = useState(null);
    const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
    const [treeViewAnchorEl, setTreeViewAnchorEl] = useState(null);
    const [sliderAnchorEl, setSliderAnchorEl] = useState(null);
    const [headerAnchorEl, setHeaderAnchorEl] = useState(null);
    const handlePrimaryClick = (event) => {
        setPrimaryAnchorEl(event.currentTarget);
      };
      const handlePrimaryClose = () => {
        setPrimaryAnchorEl(null);
      };
      const handleSecondaryClick = (event) => {
        setSecondaryAnchorEl(event.currentTarget);
      };
      const handleSecondaryClose = () => {
        setSecondaryAnchorEl(null);
      };
      const handleTreeViewClick = (event) => {
        setTreeViewAnchorEl(event.currentTarget);
      };
      const handleTreeViewClose = () => {
        setTreeViewAnchorEl(null);
      };
      const handleSliderClick = (event) => {
        setSliderAnchorEl(event.currentTarget);
      };
      const handleSliderClose = () => {
        setSliderAnchorEl(null);
      };
      const handleHeaderClick = (event) => {
        setHeaderAnchorEl(event.currentTarget);
      };
      const handleHeaderClose = () => {
        setHeaderAnchorEl(null);
      };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h6" gutterBottom>Create Theme</Typography>
            <Box sx={{ mt: 2 }} component={'form'} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    {orgType === 8 &&
                        <Grid item lg={6}>
                            <StyledFilterFormControl fullWidth sx={{ minWidth: '80%' }}>
                                <FormLabel required>Select Reseller</FormLabel>

                                <Select
                                    value={selectedReseller}
                                    onChange={handleResellerChange}
                                    displayEmpty
                                    fullWidth
                                    sx={{ width: "300px" }}
                                >
                                    <MenuItem value="" disabled>
                                        Select a Reseller
                                    </MenuItem>
                                    {resellers.map((reseller) => (
                                        <MenuItem key={reseller.key} value={reseller.key}>
                                            {reseller.value}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </StyledFilterFormControl>
                        </Grid>
                    }
                    <Grid item lg={12}>
                        <StyledFilterFormControl fullWidth sx={{ minWidth: '80%' }}>
                            <FormLabel required>Theme Name</FormLabel>
                            <StyledTextField size='small' sx={{ paddingTop: "8px" }} 
                                onChange={(e) => setThemeName(e.target.value)}
                                value={themeName}
                            // {...register('themeName')}
                            />
                        </StyledFilterFormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Box>

                            <Grid container spacing={2}>
                                <Grid item lg={6}>
                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Typography>Primary Color</Typography>
                                        <Typography variant='caption'>Click on the color It will be taken automatically</Typography>
                                    </Box>
                                    <Box>
                                        <Stack direction={'row'} onClick={handlePrimaryClick} sx={{borderRadius:'10px', overflow:'hidden', width:'fit-content', cursor:'pointer'}}>
                                            <Box sx={{height:'32px', width:'60px', background: `rgb(${primaryColor !== undefined && primaryColor[10]?.rgb.join(',')})`}}></Box>
                                            <Icon sx={{background:theme.palette.grey[100], display:'flex', alignItems:'center', height:'auto', width:'30px', fontSize:'20px', justifyContent:'center'}}>
                                                <IoMdColorFill/>
                                            </Icon>
                                        </Stack>
                                        
                                        </Box>
                                    <Popover
                                        id="primary-color-popover"
                                        open={Boolean(primaryAnchorEl)}
                                        anchorEl={primaryAnchorEl}
                                        onClose={handlePrimaryClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <StyledColorPicker className='colorPicker'>
                                        <SketchPicker onChangeComplete={handlePrimaryColor} color={primaryColor !== undefined && primaryColor[6]?.hex} triangle='hide' ref={primaryRef} />
                                    </StyledColorPicker> 
                                    </Popover>

                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Stack direction='row' spacing={3}>

                                            <Box>
                                                <Typography variant='body2'>Main</Typography>
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${primaryColor !== undefined && primaryColor[6]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={primaryColor !== undefined && primaryColor[6]?.rgb.join(',')} {...register('primaryMain', { required: 'true' })} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>Light</Typography>
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${primaryColor !== undefined && primaryColor !== undefined && primaryColor[2]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={primaryColor !== undefined && primaryColor[2]?.rgb.join(',')} {...register('primaryLight', { required: 'true' })} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>Dark</Typography>
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${primaryColor !== undefined && primaryColor[10]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={primaryColor !== undefined && primaryColor[10]?.rgb.join(',')} {...register('primaryDark', { required: 'true' })} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>Text</Typography>
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${primaryColor !== undefined && primaryColor[0]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={primaryColor !== undefined && primaryColor[0]?.rgb.join(',')} {...register('primaryText', { required: 'true' })} />
                                                </Box>
                                            </Box>


                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item lg={6}>
                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Typography>Secondary Color</Typography>
                                        <Typography variant='caption'>Click on the color It will be taken automatically</Typography>
                                    </Box>
                                    <Box>
                                    <Stack direction={'row'} onClick={handleSecondaryClick} sx={{borderRadius:'10px', overflow:'hidden', width:'fit-content', cursor:'pointer'}}>
                                            <Box sx={{height:'32px', width:'60px', background: `rgb(${secondaryColor !== undefined && secondaryColor[10]?.rgb.join(',')})`}}></Box>
                                            <Icon sx={{background:theme.palette.grey[100], display:'flex', alignItems:'center', height:'auto', width:'30px', fontSize:'20px', justifyContent:'center'}}>
                                                <IoMdColorFill/>
                                            </Icon>
                                        </Stack>
                                
                                        </Box>
                                    <Popover
                                      
                                        open={Boolean(secondaryAnchorEl)}
                                        anchorEl={secondaryAnchorEl}
                                        onClose={handleSecondaryClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <StyledColorPicker className='colorPicker'>
                                        <SketchPicker onChangeComplete={handleSecondaryColor} color={secondaryColor !== undefined && secondaryColor[6]?.hex} triangle='hide' />
                                    </StyledColorPicker> 
                                    </Popover>

                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Stack direction='row' spacing={3}>

                                            <Box>
                                                Main
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${secondaryColor !== undefined && secondaryColor[6]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={secondaryColor !== undefined && secondaryColor[6]?.rgb.join(',')} {...register('secondaryMain')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Light
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${secondaryColor !== undefined && secondaryColor[2]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={secondaryColor !== undefined && secondaryColor[2]?.rgb.join(',')} {...register('secondaryLight')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Dark
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${secondaryColor !== undefined && secondaryColor[10]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={secondaryColor !== undefined && secondaryColor[10]?.rgb.join(',')} {...register('secondaryDark')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Text
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${secondaryColor !== undefined && secondaryColor[0]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={secondaryColor !== undefined && secondaryColor[0]?.rgb.join(',')} {...register('secondaryText', { required: 'true' })} />
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                    </Grid>

                    <Grid item lg={12}>
                        <Box sx={{ mb: 2 }}>
                        <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <Typography>Treeview</Typography>
                            <Typography variant='caption'>Click on the color It will be taken automatically</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item lg={6}>
                                <Box>
                                <Stack direction={'row'} onClick={handleTreeViewClick} sx={{borderRadius:'10px', overflow:'hidden', width:'fit-content', cursor:'pointer'}}>
                                            <Box sx={{height:'32px', width:'60px', background: `rgb(${treeViewColor !== undefined && treeViewColor[10]?.rgb.join(',')})`}}></Box>
                                            <Icon sx={{background:theme.palette.grey[100], display:'flex', alignItems:'center', height:'auto', width:'30px', fontSize:'20px', justifyContent:'center'}}>
                                                <IoMdColorFill/>
                                            </Icon>
                                        </Stack>
                                       
                                        </Box>
                                    <Popover
                                        id="primary-color-popover"
                                        open={Boolean(treeViewAnchorEl)}
                                        anchorEl={treeViewAnchorEl}
                                        onClose={handleTreeViewClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <StyledColorPicker className='colorPicker'>
                                        <SketchPicker onChangeComplete={handleTreeviewColor} color={treeViewColor !== undefined && treeViewColor[6]?.hex} triangle='hide' ref={primaryRef} />
                                    </StyledColorPicker> 
                                    </Popover>
                                    <Stack >
                                        <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                    <Box>
                                            200
                                            <Box sx={{ width: '35px', height: '35px', background: `rgb(${treeViewColor !== undefined && treeViewColor[2]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                <input hidden value={treeViewColor !== undefined && treeViewColor[2]?.rgb.join(',')} {...register('treeview200', { required: 'true' })} />
                                            </Box>
                                        </Box>
                                        <Box>
                                            300
                                            <Box sx={{ width: '35px', height: '35px', background: `rgb(${treeViewColor !== undefined && treeViewColor[3]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                <input hidden value={treeViewColor[3]?.rgb.join(',')} {...register('treeview300', { required: 'true' })} />
                                            </Box>
                                        </Box>
                                        <Box>
                                            400
                                            <Box sx={{ width: '35px', height: '35px', background: `rgb(${treeViewColor !== undefined && treeViewColor[4]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                <input hidden value={treeViewColor !== undefined && treeViewColor[4]?.rgb.join(',')} {...register('treeview400', { required: 'true' })} />
                                            </Box>
                                        </Box>
                                        <Box>
                                            500
                                            <Box sx={{ width: '35px', height: '35px', background: `rgb(${treeViewColor !== undefined && treeViewColor[5]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                <input hidden value={treeViewColor !== undefined && treeViewColor[5]?.rgb.join(',')} {...register('treeview500', { required: 'true' })} />
                                            </Box>
                                        </Box>
                                        <Box>
                                            600
                                            <Box sx={{ width: '35px', height: '35px', background: `rgb(${treeViewColor !== undefined && treeViewColor[6]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                <input hidden value={treeViewColor !== undefined && treeViewColor[6]?.rgb.join(',')} {...register('treeview600', { required: 'true' })} />
                                            </Box>
                                        </Box>
                                        <Box>
                                            700
                                            <Box sx={{ width: '35px', height: '35px', background: `rgb(${treeViewColor !== undefined && treeViewColor[7]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                <input hidden value={treeViewColor !== undefined && treeViewColor[7]?.rgb.join(',')} {...register('treeview700', { required: 'true' })} />
                                            </Box>
                                        </Box>
                                        </Box>
                                    </Stack>
                                </Grid>
                               
                            </Grid>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item lg={12}>
                        <Box>

                            <Grid container spacing={2}>
                                <Grid item lg={6}>
                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Typography>sidebar Color</Typography>
                                        <Typography variant='caption'>Click on the color It will be taken automatically</Typography>
                                    </Box>
                                    <Box>
                                    <Stack direction={'row'} onClick={handleSliderClick} sx={{borderRadius:'10px', overflow:'hidden', width:'fit-content', cursor:'pointer'}}>
                                            <Box sx={{height:'32px', width:'60px', background: `rgb(${sideBarColor !== undefined && sideBarColor[10]?.rgb.join(',')})`}}></Box>
                                            <Icon sx={{background:theme.palette.grey[100], display:'flex', alignItems:'center', height:'auto', width:'30px', fontSize:'20px', justifyContent:'center'}}>
                                                <IoMdColorFill/>
                                            </Icon>
                                        </Stack>
                                        
                                        </Box>
                                    <Popover
                                        id="primary-color-popover"
                                        open={Boolean(sliderAnchorEl)}
                                        anchorEl={sliderAnchorEl}
                                        onClose={handleSliderClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <StyledColorPicker className='colorPicker'>
                                        <SketchPicker onChangeComplete={handleSideBarColor} color={sideBarColor !== undefined && sideBarColor[6]?.hex} triangle='hide' ref={primaryRef} />
                                    </StyledColorPicker> 
                                    </Popover>
                                   
                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Stack direction='row' spacing={3}>

                                            <Box>
                                                Main
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${sideBarColor !== undefined && sideBarColor[6]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={sideBarColor !== undefined && sideBarColor[6]?.rgb.join(',')} {...register('sidebarMain')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Light
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${sideBarColor !== undefined && sideBarColor[2]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={sideBarColor !== undefined && sideBarColor[2]?.rgb.join(',')} {...register('sidebarLight')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Dark
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${sideBarColor !== undefined && sideBarColor[10]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={sideBarColor !== undefined && sideBarColor[10]?.rgb.join(',')} {...register('sidebarDark')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Text
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${sideBarColor !== undefined && sideBarColor[0]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={sideBarColor !== undefined && sideBarColor[0]?.rgb.join(',')} {...register('secondaryText', { required: 'true' })} />
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item lg={6}>
                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Typography> Header Color</Typography>
                                        <Typography variant='caption'>Click on the color It will be taken automatically</Typography>
                                    </Box>
                                    <Box>
                                    <Stack direction={'row'} onClick={handleHeaderClick} sx={{borderRadius:'10px', overflow:'hidden', width:'fit-content', cursor:'pointer'}}>
                                            <Box sx={{height:'32px', width:'60px', background: `rgb(${headerColor !== undefined && headerColor[10]?.rgb.join(',')})`}}></Box>
                                            <Icon sx={{background:theme.palette.grey[100], display:'flex', alignItems:'center', height:'auto', width:'30px', fontSize:'20px', justifyContent:'center'}}>
                                                <IoMdColorFill/>
                                            </Icon>
                                        </Stack>
                                       
                                        </Box>
                                    <Popover
                                        id="primary-color-popover"
                                        open={Boolean(headerAnchorEl)}
                                        anchorEl={headerAnchorEl}
                                        onClose={handleHeaderClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <StyledColorPicker className='colorPicker'>
                                        <SketchPicker onChangeComplete={handleHeaderColor} color={headerColor !== undefined && headerColor[6]?.hex} triangle='hide' ref={primaryRef} />
                                    </StyledColorPicker> 
                                    </Popover>
                                  
                                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Stack direction='row' spacing={3}>
                                           
                                            <Box>
                                                Main
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${headerColor !== undefined && headerColor[6]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={headerColor[6]?.rgb.join(',')} {...register('headerMain')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Light
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${headerColor !== undefined && headerColor[2]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={headerColor !== undefined && headerColor[2]?.rgb.join(',')} {...register('headerLight')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Dark
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${headerColor !== undefined && headerColor[10]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={headerColor !== undefined && headerColor[10]?.rgb.join(',')} {...register('headerDark')} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                Text
                                                <Box sx={{ width: '35px', height: '35px', background: `rgb(${headerColor !== undefined && headerColor[0]?.rgb.join(',')})`, borderRadius: '5px', mt: 1, border: `.5px solid ${theme.palette.divider}` }}>
                                                    <input hidden value={headerColor !== undefined && headerColor[0]?.rgb.join(',')} {...register('headerText', { required: 'true' })} />
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                    </Grid>

                    <Grid item lg={12}>
                        <Box sx={{ marginBottom: "20px" }}>
                            <Typography>Font Family</Typography>
                            <Typography variant='caption'>Select the style from the dropdown will be taken automatically</Typography>
                            <Grid container spacing={2}>
                                <Grid item lg={6}>
                                    <Select
                                        value={selectedFont}
                                        onChange={handleFontChange}
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        style={{ marginTop: 8 }} 
                                    >
                                        {fontOptions.map((font, index) => (
                                            <MenuItem key={index} value={font}>
                                                {font}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                    </Grid>
                </Grid>
                <Stack direction='row' sx={{ mt: 2, justifyContent: 'flex-end' }}>
                    <Button variant='contained' size='small' color='primary' type='submit' disableElevation>Save theme</Button>
                </Stack>
            </Box>
            {open && (
                <StyledSnackBar message={"Theme Uploaded successfully"}  open={true} status={dataStatusConfirm} />
            )}
        </Box>
    )
}

export default CreateTheme
