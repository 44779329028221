import Service from "./service";

const customerKYCServices = {
  getGridViewForKYCFeed: function (resource) {
    return Service.post("KYCLog/getKYCVINList", resource, null);
  },
  saveKycDetails: function (data) {
    return Service.post("/KYCLog/saveKYCLog", data, null);
  },
  getGridViewForProcessedFeed: function (resource) {
    return Service.post("/KYCLog/getKYCVINDetailList", resource, null);
  },
  getGridViewForVinDetails: function (resource) {
    return Service.post("/KYCLog/getVinKYCLogGridData", resource, null);
  },
  validateKycList: function (data) {
    return Service.post(
      "/KYCLog/validateKYCExcel",
      data,
      null,

      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  //   uploadKycList: function (data) {
  //     return Service.post(
  //       "/Dealer/dealerProcessExcel",
  //       data,
  //       null,

  //       { "Content-Type": "multipart/form-data", accept: "*/*" }
  //     );
  //   },
  editKycById: function (kycFeedId) {
    return Service.get("/KYCLog/getKYCObject", { kycFeedId });
  },
  updateKYCApproval: function (data) {
    return Service.post("/KYCLog/updateKYCObject", data, null);
  },
  getGstIn: function (data) {
    return Service.post("/vehicle/getGSTIN", data, null);
  },
};

export default customerKYCServices;
