import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { BsFillBuildingFill } from 'react-icons/bs';
import { HiBuildingStorefront } from 'react-icons/hi2';
import { TbGridDots } from 'react-icons/tb'
import { useGetMenusMutation, useGetUserdetailsMutation, useSwitchAccountMutation } from '../../../store/slice/NewAuthSlice/AuthSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuth, snackService } from '../../../store/slice/ControlsSlice';

function MultipleAccount() {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let userData = JSON.parse(localStorage.getItem('userData'));
    let accountList = JSON.parse(localStorage.getItem('resellerList'));

    const [ switchAcc, {data:switchAccData, isLoading:switchAccLoading, isSuccess:switchAccSuccess} ] = useSwitchAccountMutation();
    const [ getNewMenu, {data:menuListData, isLoading:menuListLoading, isSuccess:menuListSuccess} ] = useGetMenusMutation();
    const [ getUserDetails, {data:getUserDetailsData, isLoading:getUserDetailsLoading, isSuccess:getUserDetailsSuccess} ] = useGetUserdetailsMutation();
    //Swirch Account
    const handleSwitchAccount = async (newresellerId) => {
      let payload = {
        "userName": userData?.userName,
        "password": "",
        "resellerId": newresellerId
      }
      const switchAccount = await switchAcc(payload);
      if(switchAccount.data !== undefined){
        setLocalStorage(switchAccount.data.accessToken, switchAccount.data.refreshToken);
        dispatch(getAuth([switchAccount.data.accessToken, switchAccount.data.refreshToken]));
        dispatch(snackService(["Your account has been changed", 0, true]));
        setTimeout(() => {
            getUserInfo();
        },0)
        //setStep(2);
    }else{
      dispatch(snackService(["Not Able to Switch your Account", 1, true]));
    }
    }

    //set LocalStorage
    const setLocalStorage = (authToken, refToken) => {
      window.localStorage.setItem('jwToken',JSON.stringify(authToken));
      window.localStorage.setItem('rshjwToken',refToken);
      window.localStorage.setItem('expjwToken',null);
  }


    //setLocalStorage
    const getUserInfo = async () => {
      const getUserDetail = await getUserDetails();
      localStorage.setItem("userData", JSON.stringify(getUserDetail.data.data));
      getMenus(getUserDetail.data.data);
  }


    //getMenu
    const getMenus = async (data) => {
      let payload = {
        "resellerId": data.resellerId,
        "orgId": data.orgId,
        "page": 0,
        "size": 100,
        "searchText": "",
        "id": 0,
        "roleId":  data.roleId,
        "orgType": data.userOrgType
    }
        const menusList = await getNewMenu(payload);
        window.localStorage.removeItem("menuList");
        window.localStorage.setItem("menuList", null);
        window.localStorage.setItem("menuList",JSON.stringify(menusList.data.menus));
        //window.localStorage.setItem("accountList",JSON.stringify(validateUserData.data));
        navigate(menusList.data.menus[0]?.sub[0]?.route !== undefined ? `/${menusList.data.menus[0]?.sub[0]?.route}` : `/${menusList.data.menus[1]?.sub[1]?.route}`);
        //window.location.reload();
    }

  return (
    <>
    {
     accountList !== null && accountList.length > 1 &&
      <Tooltip title={'Accounts'}>
        <IconButton
          id="account-button"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color='secondary'
        >
          <HiBuildingStorefront />
        </IconButton>
      </Tooltip>
    }
  
    <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-button',
        }}
        PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              //filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 2,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: '15%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
      >
        {
           accountList !== null && accountList?.map((item, index) => 
            <ListItem dense sx={{p:0}} key={index}>
                <ListItemButton dense sx={{px:2, py:.8}} selected={parseInt(item.key) === userData?.resellerId ? true : false} onClick={() => handleSwitchAccount(item.key)}>
                    <ListItemIcon sx={{minWidth:'auto', mr:1}}>
                        <BsFillBuildingFill />
                    </ListItemIcon>
                    <ListItemText 
                        primary={<Typography variant='body2'>{item.title}</Typography>}
                    />
                </ListItemButton>
            </ListItem>
            )
        }
      </Menu>
    </>
  )
}

export default MultipleAccount