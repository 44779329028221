import * as React from "react";
import { useState } from "react";
import {
  Grid,
  ListItemText,
  Typography,
  useTheme,
  ListItem,
  ToggleButton,
  Stack
} from "@mui/material";
import {
  StyledPageLayout,
  StyledPaperShadow,
  StyledAccordion,
  StyledSwitch,
  StyledToggleButtonGroup,
  SoftButton
} from "../../theme/StyledEle";
import { Box, alpha } from "@mui/system";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import MouseOverPopover from "../shared/PopOver";

import { useUpdateAlertsMutation} from "../../store/slice/OrgSettingSlice/OrgSettingSlice";

import {useGetAlertListMutation, useUpdateGeoZoneCountMutation, useUpdateHarshSettingsMutation, useGetAlertStatusListMutation, useGetAllAlertListMutation} from "../../store/slice/OrgSettingSlice/OrgSettingSlice"

const text ="Please select the ADD or REMOVE button";


const globalJson1 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;
if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
) {
} else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
}

const AlertList = ({datas, adminPayload}) => {

  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(true);
  const [panel3, setPanel3] = useState(true);
  const [radioVal, setRadioVal] = useState("");


  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
    flexDirection: "row-forward",
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));


/* 
    var statusArray=[];
    for(let i=0; i < statusData.length; i++){
      var statusValue={};
      statusValue.status=statusData[i].status;
      statusValue.statusValue=statusData[i].value;
      statusArray.push(statusValue);
    }

    let StatusRef = statusArray.map(() => React.createRef())
    let merge = [...datas, ...statusArray];
   */ 
   
  const alertArray=[];
  const statusArray=[];

  const handleSwitchChange = (e, val, key, alertType) => {
    if(e.target.checked === true){
      alertArray.push(val);
      var statusValue={};
      statusValue.key=key;
      statusValue.value=val;
      statusValue.alertType=alertType;
      statusArray.push(statusValue);
    }
    else{
      var index = alertArray.indexOf(e.target.name);
      if (index !== -1) {
        alertArray.splice(index, 1);
  
      }
      statusArray.splice(statusArray.findIndex(i => i.value === e.target.name),1);
    }
   }

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let roleId = userData.roleId;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;



  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.controls.alert);

  React.useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

 
  const [ updateAlerts, {data:updateAlert, isLoading:updateAlertLoading, isSuccess:updateAlertSuccess}] = useUpdateAlertsMutation();

  const [ getAllAlertList,  {data: alertData,isLoading: alertDataLoading,isSuccess: alertDataSuccess,isError: alertDataError,},] = useGetAlertListMutation();



  const theme = useTheme();
 
  const panelChange =(e,name)=>{
  }

  const handleRadioChange = async (e, newValue) => {
    if (newValue !== null) {
      setRadioVal(newValue);
    }
  };

  const handleAlertUpdate = async() => {
      if(alertArray.length!==0){
        var request={};
        var orgListArray=[];
          orgListArray[0]= adminPayload.resellerId;
          orgListArray[1]= adminPayload.customerId;
          orgListArray[2]= adminPayload.orgId;
          request.orgIdList= orgListArray;
          request.checkedValuesNewVal= statusArray;
          request.alertArray= alertArray; 
          request.statusValue= radioVal;
          request.orgId=adminPayload.orgId;
          const response = await updateAlerts(request);
          dispatch(snackService([response?.data?.resultMessage, response?.data?.resultCode, true]))
          //dispatch(snackService(["Updated Successfully",0, true]))
          //getAllAlertList(request);
      }
      else{
        alert("please select from the listed alerts");
      }
   }
  const readAlertDetails = () => {
    getAllAlertList();
  }
  return (
<StyledPageLayout>
      <Box>
          <Grid container spacing={2}>
          <Grid item lg={12}>        
          <StyledToggleButtonGroup
                sx={{ width: "fit-content" }}
                value={radioVal}
                exclusive
                onChange={handleRadioChange}
                aria-label="theme mode"
              >
                <ToggleButton value="ADD" sx={{ width: "100%" }}>
                  {" "}
                  <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>ADD</Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton value="REMOVE" sx={{ width: "100%" }}>
                  {" "}
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {" "}
                   <Typography>REMOVE</Typography>{" "}
                  </Stack>
                </ToggleButton>
              </StyledToggleButtonGroup>
              </Grid>        
              <MouseOverPopover text={text} title={"Overspeed"} />
            <Grid item lg={12}>        
                <StyledPaperShadow >
                  <StyledAccordion
                    disableGutters
                    expanded={panel1}
                    name={"panel1"}
                    
                    
                    className="themeAccordian"
                  >

                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={(e,name) => {setPanel1(!panel1); panelChange(e,name)}}
                    >
                      Application Alert
                    </AccordionSummary>
                    <AccordionDetails sx={{ position: "relative" }}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          container
                          lg={12}
                          sx={{
                            borderBottom: `.5px solid ${alpha(
                              theme.palette.divider,
                              0.05
                            )}`,
                            px: 1,
                            py: 0.5,
                            position: "sticky",
                            top: "-15px",
                            background: theme.palette.background.paper,
                            zIndex: 999,
                          }}
                        >
                          <Grid item xs={4} sx={{ px: 2 }}>
                            <Typography variant="body2" color={"secondary"}>
                              Alert Name
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: "center" }}>
                            <Typography variant="body2" color={"secondary"}>
                              Applicable
                            </Typography>
                          </Grid>
                        </Grid>
                        {datas !== null && datas.length > 0 ? (
                          datas?.map((org, index) => (
                            org.alertType ===1 && (
                            <Grid
                              container
                              lg={12}
                              sx={{
                                borderBottom: `.5px solid ${alpha(
                                  theme.palette.divider,
                                  0.05
                                )}`,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary={org.value} />
                                </ListItem>
                              </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledSwitch
                                      color="success"
                                      defaultChecked={false}
                                      name={`${org.value}`}
                                      onChange={(e) => {handleSwitchChange(e,`${org.value}`, `${org.keyz}`, `${org.alertType}`);}}
                                      disabled={radioVal!==""?false:true}
                                    />
                                  </Grid>
                            </Grid>
                            )
                          ))
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              background: theme.palette.background.paper,
                              width: "100%",
                              textAlign: "center",
                              padding: "15px",
                            }}
                          >
                            No Alerts
                          </Typography>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                </StyledPaperShadow>

                <StyledPaperShadow >
                  <StyledAccordion
                    disableGutters
                    expanded={panel2}
                    name={"panel2"}                    
                    className="themeAccordian"
                  >

                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={(e,name) => {setPanel2(!panel2); panelChange(e,name)}}
                    >
                      Device Alert
                    </AccordionSummary>
                    <AccordionDetails sx={{ position: "relative" }}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          container
                          lg={12}
                          sx={{
                            borderBottom: `.5px solid ${alpha(
                              theme.palette.divider,
                              0.05
                            )}`,
                            px: 1,
                            py: 0.5,
                            position: "sticky",
                            top: "-15px",
                            background: theme.palette.background.paper,
                            zIndex: 999,
                          }}
                        >
                          <Grid item xs={4} sx={{ px: 2 }}>
                            <Typography variant="body2" color={"secondary"}>
                              Alert Name
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: "center" }}>
                            <Typography variant="body2" color={"secondary"}>
                              Applicable
                            </Typography>
                          </Grid>
                        </Grid>
                        {datas !== null && datas.length > 0 ? (
                          datas?.map((org, index) => (
                            org.alertType ===2 && (
                            <Grid
                              container
                              lg={12}
                              sx={{
                                borderBottom: `.5px solid ${alpha(
                                  theme.palette.divider,
                                  0.05
                                )}`,
                                px: 1,
                                py: 0.5,
                              }}
                            > 
                            <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary={org.value} />
                                </ListItem>
                              </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledSwitch
                                      color="success"
                                      defaultChecked={false}
                                      name={`${org.value}`}
                                      onChange={(e) => {handleSwitchChange(e,`${org.value}`, `${org.keyz}`, `${org.alertType}`);}}
                                      disabled={radioVal!==""?false:true}

                                    />
                                  </Grid>
                            </Grid>
                            )
                          ))
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              background: theme.palette.background.paper,
                              width: "100%",
                              textAlign: "center",
                              padding: "15px",
                            }}
                          >
                            No Alerts
                          </Typography>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                </StyledPaperShadow>

                <StyledPaperShadow >
                  <StyledAccordion
                    disableGutters
                    expanded={panel3}
                    name={"panel3"}                    
                    className="themeAccordian"
                  >

                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={(e,name) => {setPanel3(!panel3); panelChange(e,name)}}
                    >
                      System Alert
                    </AccordionSummary>
                    <AccordionDetails sx={{ position: "relative" }}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          container
                          lg={12}
                          sx={{
                            borderBottom: `.5px solid ${alpha(
                              theme.palette.divider,
                              0.05
                            )}`,
                            px: 1,
                            py: 0.5,
                            position: "sticky",
                            top: "-15px",
                            background: theme.palette.background.paper,
                            zIndex: 999,
                          }}
                        >
                          <Grid item xs={4} sx={{ px: 2 }}>
                            <Typography variant="body2" color={"secondary"}>
                              Alert Name
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: "center" }}>
                            <Typography variant="body2" color={"secondary"}>
                              Applicable
                            </Typography>
                          </Grid>
                        </Grid>
                        {datas !== null && datas.length > 0 ? (
                          datas?.map((org, index) => (
                            org.alertType ===3 && (
                            <Grid
                              container
                              lg={12}
                              sx={{
                                borderBottom: `.5px solid ${alpha(
                                  theme.palette.divider,
                                  0.05
                                )}`,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Grid item xs={4}>
                                <ListItem dense={true}>
                                  <ListItemText primary={org.value} />
                                </ListItem>
                              </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledSwitch
                                      color="success"
                                      defaultChecked={false}
                                      name={`${org.value}`}
                                      onChange={(e) => {handleSwitchChange(e,`${org.value}`, `${org.keyz}`, `${org.alertType}`);}}
                                      disabled={radioVal!==""?false:true}

                                    />
                                  </Grid>

                            </Grid>
                            )
                          ))
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              background: theme.palette.background.paper,
                              width: "100%",
                              textAlign: "center",
                              padding: "15px",
                            }}
                          >
                            No Alerts
                          </Typography>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                </StyledPaperShadow>
                <SoftButton variant="contained" color="success"  size="small" sx={{mt:1}} onClick={handleAlertUpdate}>Update</SoftButton>
                <SoftButton variant="contained" color="error"  size="small" sx={{mt:1, mx:1}} onClick={readAlertDetails}>Cancel</SoftButton>   
            </Grid>
          </Grid>
      </Box>
  
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
      </StyledPageLayout>
  )
};

export default AlertList;
