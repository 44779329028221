import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  CardActions,
  CardHeader,
  Box,
  Grid,
  CardContent,
  FormLabel,
  Backdrop,
  useTheme,
} from "@mui/material";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFormButtonsRow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledTextField,
} from "../../theme/StyledEle";
import SimServices from "../../services/simServices";
import DraggableDialog from "../shared/DailogDrag";
import { BackDropLoader } from "../shared/StyledLoader";
import {
  useAuditLogMutation,
  useSimCreateMutation,
  useSimPlanListMutation,
  useSimUpdateMutation,
} from "../../store/slice/ApiSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { PulseLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import simServices from "../../services/simServices";
function CreateSimForm({ simToEdit, onCancel }) {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const theme = useTheme();

  const [simPlanStatusList, setSimPlanStatusList] = useState([]);
  const [simProviderList, setSimProviderList] = useState([]);
  const [simPlanList, setSimPlanList] = useState([]);
  const [clickedval, setClickedval] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);

  const [auditLog] = useAuditLogMutation();

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      simNumber: "",
      simStatus: "0",
      mobileNumber: "",
      simProviderId: "0",
      simPlanId: "0",
    },
  });

  const simList = async (id) => {
    var payload = {};
    payload.providerId = id;
    const res1 = (await SimServices.getSimPlanList(payload)).data;
    setSimPlanList(res1);
  };

  useEffect(async () => {
    const res = (await SimServices.getSimProvider()).data;
    setSimProviderList(res);
    if (simToEdit) {
      if (simToEdit.clickedval === "view") {
        setClickedval(true);
      }
      const responseData = (await SimServices.editSimById(simToEdit.id)).data
        .data;
      reset({
        simNumber: responseData.simNumber,
        mobileNumber: responseData.mobileNumber,
      });
    setMobileNumber(responseData.mobileNumber);
      // const response = (await SimServices.getSimPlanStatus()).data;
      // setSimPlanStatusList(response);

      var payload = {};
      payload.providerId = responseData.simProviderId;
      const res1 = (await SimServices.getSimPlanList(payload)).data;
      setSimPlanList(res1);


    } else {
      // const response = (await SimServices.getSimPlanStatus()).data;
      // let obj = [];
      // if (response.length > 0) {
      //   obj.push(response[0]);
      //   obj.push(response[8]);
      // }
      // setSimPlanStatusList(response !== null ? obj : []);
      simList();

      auditPayload.message = "Admin > Sim > Create Sim";
      auditPayload.action = "View";
      auditPayload.featureName = "Sim";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName =userName;

      auditLog(auditPayload);
      
    }
  }, []);

  const goList = () => {
    onCancel();
  };

  //Slice service start
  //sim plan list
  const [
    getSimPlanList,
    {
      data: simPlanListData,
      isLoading: simPlanLoading,
      isSuccess: simPlanSuccess,
    },
  ] = useSimPlanListMutation();
  const [
    simUpdate,
    {
      data: simUpdateData,
      isLoading: simUpdateLoading,
      isSuccess: simUpdateSuccess,
    },
  ] = useSimUpdateMutation();
  const [
    simCreate,
    {
      data: simCreateData,
      isLoading: simCreateLoading,
      isSuccess: simCreateSuccess,
    },
  ] = useSimCreateMutation();
  const dispatch = useDispatch();
  useEffect(() => {
    getSimPlanList();
  }, []);

  useEffect(() => {
    if (simPlanSuccess) {
      if (simToEdit) {
        setSimPlanStatusList(simPlanListData);
      } else {
        const createSimStatus = simPlanListData.filter((item) => {
          return item.title === "MFG" || item.title === "INACTIVE";
        });
        setSimPlanStatusList(createSimStatus);
        reset({
          simStatus: createSimStatus[1].value,
        });
      }
    }
  }, [simPlanSuccess, simUpdateSuccess]);

  useEffect(() => {
    if (simUpdateSuccess) {
      setMessage(simUpdateData?.resultMessage);
      setStatus(simUpdateData?.resultText);
      dispatch(
        snackService([
          simUpdateData?.resultMessage,
          simUpdateData?.resultText,
          simUpdateSuccess,
        ])
      );
      onCancel();
    } else if (simCreateSuccess) {
      setMessage(simCreateData?.resultMessage);
      setStatus(simCreateData?.resultText);
      dispatch(
        snackService([
          simCreateData?.resultMessage,
          simCreateData?.resultText,
          simCreateSuccess,
        ])
      );
      onCancel();
    }
  }, [simUpdateSuccess, simCreateSuccess]);

  //Onsubmit
  const onSubmit = (data) => {
    if (!simToEdit) {
      saveSim(data); //Create Sim
    } else {
      updateSim(data); // UpdateSim
    }
  };

  //Create Sim
  const saveSim = async (obj) => {
    obj.orgId = orgId;
    obj.resellerId = resellerId;
    simCreate(obj);
  };

  // Update Sim
  const updateSim = async (obj) => {
    obj.id = simToEdit != null ? simToEdit.id : "";
    obj.orgId = orgId;
    obj.resellerId = resellerId;
    obj.status = 1;
    simUpdate(obj);
  };

  //Slice service end
  const re = /^[0-9\b]+$/;
  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
        <CardHeader
          title={
            <StyledTableTitle sx={{ display: "block" }}>
              Create Sim
            </StyledTableTitle>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Sim Number
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.simNumber && true}
                  size="small"
                  fullWidth
                  {...register("simNumber", {
                    required: {
                      value: true,
                      message: "Sim number required",
                    },
                    maxLength: {
                      value: 20,
                      message: "Sim no must be 19 to 20",
                    },
                    minLength: {
                      value: 19,
                      message: "Sim no must be 19 to 20",
                    },
                    validate: {
                      emailAvailable: async (fieldValue) => {
                        if( simToEdit === undefined ){
                        
                      var request = {};
                        request.simNumber = fieldValue;
                       
                        const simNumber =
                          await simServices.chkSimNumber(request);
                        return (
                          simNumber.data.resultMessage !==
                            "SimNumber Already Exists" || "SimNumber Already Exists"
                        );
                        }
                      },
                    },
                  })}
                  placeholder="Enter Sim No"
                  onChange={(event) =>
                    event.target.value === "" || re.test(event.target.value)
                      ? setValue("simNumber", event.target.value)
                      : setValue("simNumber", "")
                  }
                  
                  disabled={simToEdit !== undefined ?  true:false }
                  helperText={errors.simNumber && errors.simNumber.message}
                  inputProps={{ maxLength: "20" }}
                  value={watch('simNumber')}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Sim Status
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.simStatus && true}
                  {...register("simStatus", {
                    required: {
                      value: true,
                      message: "Sim status required",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || "Sim status required";
                    },
                  })}
                  onChange={(e) => {
                    setValue("simStatus", e.target.value);
                  }}
                  disabled={clickedval}
                  defaultValue={
                    simToEdit !== undefined ? simToEdit?.simStatusValue : "0"
                  }
                  size="small"
                  value={watch('simStatus')}
                >
                  <MenuItem value="0">Select</MenuItem>
                  {simPlanStatusList.length > 0 &&
                    simPlanStatusList.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>

                <FormHelperText error={errors.simStatus && true}>
                  {errors.simStatus?.message}
                </FormHelperText>
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Mobile Number
                </StyledFormControlLabel>
                <StyledTextField
                  fullWidth
                  size="small"
                  error={errors.mobileNumber && true}
                  {...register("mobileNumber", {
                    required: {
                      value: true,
                      message: "Mobile Number required",
                    },
                    maxLength: {
                      value: 13,
                      message: "Max 13 characters allowed",
                    },
                    minLength: {
                      value: 10,
                      message: "Min 10 characters allowed",
                    },
                    validate: {
                      emailAvailable: async (fieldValue) => {
                        if( mobileNumber !== fieldValue){
                      var request = {};
                        request.mobileNumber = fieldValue;
                       
                        const mobileNumber =
                          await simServices.chkMobileNumber(request);
                        return (
                          mobileNumber.data.resultMessage !==
                            "MobileNumber Already Exists" || "MobileNumber Already Exists"
                        );
                        }
                      },
                    },
                  })}
                  placeholder="Enter Mobile No"
                  onChange={(event) =>
                    event.target.value === "" || re.test(event.target.value)
                      ? setValue("mobileNumber", event.target.value)
                      : setValue("mobileNumber", "")
                  }
                  helperText={
                    errors.mobileNumber && errors.mobileNumber.message
                  }
                  disabled={clickedval}
                  inputProps={{ maxLength: "13", minLength: "10" }}
                  value={watch('mobileNumber')}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Provider Name
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.simProviderId}
                  {...register("simProviderId", {
                    required: {
                      value: true,
                      message: "Provide name required",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || "Provide name required";
                    },
                  })}
                  onChange={(e) => {
                    simList(e.target.value);
                    setValue("simProviderId", e.target.value);
                  }}
                  defaultValue={
                    simToEdit !== undefined ? simToEdit?.simProviderId : "0"
                  }
                  size="small"
                  disabled={clickedval}
                  value={watch('simProviderId')}
                >
                  <MenuItem value="0">Select</MenuItem>
                  {simProviderList.length > 0 &&
                    simProviderList.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                <FormHelperText error={errors.simProviderId}>
                  {errors.simProviderId?.message}
                </FormHelperText>
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Plan Name
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.simPlanId}
                  {...register("simPlanId", {
                    required: {
                      value: true,
                      message: "Plan name required",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || "Plan name required";
                    },
                  })}
                  onChange={(e) => {
                    setValue("simPlanId", e.target.value);
                  }}
                  size="small"
                  defaultValue={
                    simToEdit !== undefined ? simToEdit?.simPlanId : "0"
                  }
                  disabled={clickedval}
                  value={watch('simPlanId')}
                >
                  <MenuItem value="0">Select</MenuItem>
                  {simPlanList.length > 0 &&
                    simPlanList.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                <FormHelperText error={errors.simPlanId}>
                  {errors.simPlanId?.message}
                </FormHelperText>
              </StyledFormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
            <SoftButton
              size="small"
              onClick={goList}
              color="error"
              className="error"
            >
              Cancel
            </SoftButton>
            {clickedval === false && (
              <SoftButton
                size="small"
                className="success"
                color="success"
                type="submit"
              >
                {simUpdateLoading || simCreateLoading ? (
                  <PulseLoader
                    color={theme.palette.success.contrastText}
                    size={6}
                  />
                ) : simToEdit ? (
                  "Update"
                ) : (
                  "Submit"
                )}
              </SoftButton>
            )}
          </StyledFormButtonsRow>
        </CardActions>
      </StyledCardShadow>
    </Box>
  );
}

export default CreateSimForm;
