import React, { useEffect, useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ListKycFeed from "./ListKycFeeds";
import ListKycProcessedFeed from "./ListKycProcessedFeed";
import CreateKycForm from "./CreateKyc";
import VinJourneyDtails from "./VinDetails";
import { StyledPageLayout } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import { useDispatch } from "react-redux";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";

const KycLog = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let RoleId = userData?.roleId;

  const [kycToEdit, setKycToEdit] = React.useState(null);
  const [tabValue, setTabValue] = useState(RoleId !== 17?1:2);

  const handleTabChange = (event, newValue) => {
    setKycToEdit(null);
    setTabValue(newValue);
  };

  const handleCancel = () => {
    setTabValue(RoleId !== 17?1:2);
  };

  const TABS = [
    {
      label: "Create KYC Form",
      children: (
        <CreateKycForm
          kycToEdit={kycToEdit}
          onCancel={handleCancel}
          tabValue={tabValue}
        />
      ),
    },
    
   
    {
      label: "Vin Journey Details",
      children: <VinJourneyDtails tabValue={tabValue} />,
    },
    {
      label: "KYC Processing",
      children: (
        <ListKycProcessedFeed tabValue={tabValue}/>
      ),
    },
    {
      label: "KYC Feeds",
      children: <ListKycFeed tabValue={tabValue} />,
    },
    // //  {
    //   label: 'Security',
    //   children: <SecurityFeature onEditFeature={handleDevEdit}  />
    // }
  ];
  const TABS1 = [
    {
      label: "Create KYC Form",
      children: (
        <CreateKycForm
          kycToEdit={kycToEdit}
          onCancel={handleCancel}
          tabValue={tabValue}
        />
      ),
    },
    {
      label: "KYC Processing",
      children: (
        <ListKycProcessedFeed tabValue={tabValue} />
      ),
    },
    {
      label: "KYC Feeds",
      children: <ListKycFeed tabValue={tabValue} />,
    },
    // //  {
    //   label: 'Security',
    //   children: <SecurityFeature onEditFeature={handleDevEdit}  />
    // }
  ];


      //sector
      const isSuccess = useSelector((state) => state.controls.alert);
      const dispatch = useDispatch();
      useEffect(() => {
        if (isSuccess.isSuccess) {
          setTimeout(() => dispatch(snackService([null, null, null])), 6000);
        }
      }, [isSuccess.isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Log" subDiv="KYC Log" includePopup={true}></Breadcrumb>
      <MouseOverPopover
        text="Only valid KYC feeds are shown in KYC Details page."
        title={"KYC Log"}
      />
      <StyledTabs tabs={RoleId === 17?TABS:TABS1} handleChange={handleTabChange} value={tabValue} />

        {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </StyledPageLayout>
  );
};

export default KycLog;
