import React, { useMemo, useState, useEffect } from "react";
import { Box, color } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Button, Chip} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import logServices from "../../services/logService";


import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useOverSpeedMutation,
} from "../../store/slice/ApiSlice";
import { useVehicleLatestDetailMutation } from "../../store/slice/ReportSlice/ReportSlice";
const theme = createTheme({
  palette: {
    default: {
      main: '#808080', // Your custom secondary color (pink in this example)
    },
  },
});
const text =
  "This report shows the latest vehicle detail.";
  
const VehicleLatestDetailReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId; 
 

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedval, setAssignedval] = useState([]);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > latestDetail";
    auditPayload.action = "View";
    auditPayload.featureName = "latestDetail";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

 
  const columns = React.useMemo(() => [
  
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
        width:120,
      },
      {
        Header: "Region",
        accessor: "regionName",
        width:120,
      },
      {
        Header: "Dealer",
        accessor: "dealerName",
        width:120,
      },
      {
        Header: "Customer",
        accessor: "customerName",
        width:120,
      },
      {
        Header: "Fleet",
        accessor: "orgName",
        width:120,
      },
      {        
        Header: "Vehicle Status",
        accessor: "gpsStatus",
        Cell:({row}) => {
                   
          if(row.original.gpsStatus !== '-' ){
              return <Chip label={row.original.gpsStatus} size="small" color={row.original.gpsStatus === 'Moving' ? 'success' : row.original.gpsStatus === 'Charging' ? 'warning' : row.original.gpsStatus === 'Offline' ? 'default' : 'error'}/>
          }else{
              return row.original.gpsStatus
          }
        }
      },
      {
        Header: "Last Connected Time",
        accessor: "lastConnect",
        width:150,
      },
      {
        Header: "Vehicle Created Date",
        accessor: "createdDate",
        width:150,
      },
      {
        Header: "Odometer (km)",
        accessor: "odometer",
        width:100,
      },
      {
        Header: "SOC (%)",
        accessor: "stateofcharge",
        width:120,
      },
      {
        Header: "SOH (%)",
        accessor: "stateOfHealth",
        width:120,
      },
      // {
      //   Header: "Energy Consumed (Wh)",
      //   accessor: "energyconsumed",
      //   width:120,
      // },
      {
        Header: "Remaining Energy (Wh)",
        accessor: "remainingEnergy",
        width:120,
      },
      {
        Header: "Battery Voltage (V)",
        accessor: "batteryVoltage",
        width:120,
      },
      {
        Header: "Battery Current (A)",
        accessor: "batteryCurrent",
        width:120,
      },
      {
        Header: "Battery Temperature (ºC)",
        accessor: "batteryTemparature",
        width:120,
      },
      {
        Header: "Nominal Capacity (A)",
        accessor: "initialBatteryHealth",
        width:120,
      },
      {
        Header: "Residual Capacity (A)",
        accessor: "currentBatteryHealth",
        width:120,
      },
      {
        Header: "Battery Degradation (%)",
        accessor: "batteryDegradation",
        width:120,
      },
      {
        Header: "Battery Cycle Count",
        accessor: "chargeCycleCount",
        width:120,
      },
      {
        Header: "Maximum Voltage (V)",
        accessor: "maxVoltage",
        width:120,
      },
      {
        Header: "Minimum Voltage (V)",
        accessor: "minVoltage",
        width:120,
      },
      {
        Header: "Voltage Difference (mV)",
        accessor: "voltdifference",
        width:120,
      },
      {
        Header: "Running Mode",
        accessor: "mode",
        width:120,
      },
      {
        Header: "Time to Full Charge (hh:mm)",
        accessor: "timetoFullCharge",
        width:120,
      },
      {
        Header: "Remaining Distance (km)",
        accessor: "remainingDistance",
        width:120,
      },
      // {
      //   Header: "GPS Location",
      //   accessor: "gpslocation",
      //   width:150,
      // },

      
      {
        Header: "GPS Location",
        accessor: "gpslocation",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng =row.original.gpslocation;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.gpslocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.zoneName}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpslocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.gpslocation}
                >
                  {row.original.gpslocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpslocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.gpslocation}
                  key={row.original.gpslocation}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Lohum Residual Value (Wh)",
        accessor: "lohumResidualValue",
        width:120,
      },
      {
        Header: "Remaining Utilization Life (%)",
        accessor: "batteryHealthRemainingPercent",
        width:120,
      },
      {
        Header: "Remaining Utilization Life (in Months)",
        accessor: "batteryHealthRemainingMonths",
        width:120,
      },

    ],
    []
  );

  let initialState1 = {};
  var arr = [];
 
  if (roleId != 17){
    arr.push("lohumResidualValue");
    arr.push("batteryHealthRemainingPercent");
    arr.push("batteryHealthRemainingMonths");
    initialState1.hiddenColumns = arr;
  }
  
  // Get Data from New Dropdown
  const [getgridView, { data: latestDetail, isLoading, isSuccess }] =
    useVehicleLatestDetailMutation();
  let commonPayload = {};

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.status = data.latestVehicleStatusId === "0" ?"Stop":data.latestVehicleStatusId === "1"?"Moving":data.latestVehicleStatusId === "6"?"Charging":data.latestVehicleStatusId === "9"?"All":"Offline";
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    
    //Service Call
    setPayload(commonPayload);
    getgridView(commonPayload);

    auditPayload.message = "Reports > LatestDetail > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.statusName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "LatestDetail";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "VehicleLatestDetailReport/VehicleLatestDetailReportXLS.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&search=" +
        payload.search +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > LatestDetail > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "LatestDetail";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getgridView(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getgridView(payload);
      
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    payload.length = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getgridView(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getgridView(payload);
    }
  };

  
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (latestDetail.data !== null) {
        setResponseData(latestDetail.data.content);
        setTotalElements(latestDetail.data.totalElements);
        setTotalPages(latestDetail.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout sx={{ p: 0}}>
      
      <MouseOverPopover text={text} title={"LatestDetail"} />
      <Box>
        <FilterDropdown
          getData={onSubmitForm}
          isLatestVehicleData={true}
          status={false}
          showCalendar={false}
          getDataLoading={isLoading}
        />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              initialState={initialState1}
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              handleChange={handleSearch}
              includeExportButton={true}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default VehicleLatestDetailReport;