import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { loginSchema } from "../../Utils/ValidationSchemas";
import { useDispatch } from "react-redux";
import { AUTH_LOGIN } from "../../constants/actionType";
import { useNavigate } from "react-router-dom";
import loginService from "../../services/loginService.js";
import { saveToken } from "../../Utils";
import service from "../../services/service";
import userServices from "../../services/userServices";
import { useTheme,CardMedia } from "@mui/material";
import moment from "moment";
import powerBlockNesh from '../../assets/images/NeshPoeweLogo_blck.svg'

const initialState = {
  userName: "",
};
export default function SignIn1() {
  const theme = useTheme();
  const [logIn, setLogIn] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState([]);
  //let params = window.location.href;
  //var hashCode = params.split("?")[1].split("=")[1]
  
  var url = 'https://ev1.nesh.live/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    var commondata = {};
    const { target } = e;
    commondata = Object.fromEntries(new FormData(target));

    var request = {};

    request.userName = commondata.userName;
    request.path = url;

    const responseData = (await userServices.getForgetPassword(request)).data;
    setResponseData(responseData);
  };

  const handleChange = (e) => {
    setLogIn({
      ...logIn,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <h>
        To reset your password, enter your user name. Password reset details
        will be sent to your registered Email.
      </h>
      <Box
        sx={{
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "1px solid white",
          marginBottom: 8,
          //boxShadow: theme.palette.shadow.secondary,
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mt: 1 }}>
          Forgot Password
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: 250, mb: 3 }}
        >
          <TextField
            size="small"
            margin="normal"
            id="userName"
            label="UserName"
            type="text"
            // value={logIn.userName}
            onChange={handleChange}
            name="userName"
            autoComplete="userName"
          />
          <Button
            type="submit"
            size="small"
            variant="contained"
            sx={{ mt: 3, mb: 2, background: " #828282", color: "#fff" }}
          >
            Submit
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              navigate("/login");
            }}
            sx={{ mt: 3, mb: 2, ml: 2, background: " #828282", color: "#fff" }}
          >
            Cancel
          </Button>
          <Grid container></Grid>
        </Box>
      </Box>
      <Typography variant="caption" sx={{ mt: 3, zIndex:99, display:'flex', alignItems:'center'}}>
        Copyrights @ {moment().format('YYYY')}, All rights reserved nesh.live
      </Typography>
      <Typography variant="caption" sx={{ mt: 0.5, zIndex:99 }}>
        {" "}
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Container>
  );
}
