import { useTheme } from '@mui/material';
import { Marker, Polygon } from '@react-google-maps/api';
import zoneMarker from '../../assets/images/geoZoneMarker.png'
import React from 'react'

function ZonePolygon(props) {
    let theme = useTheme();
    const { listZone, multipleMarker, hoverZone } = props;
    const polygons = listZone !== null && listZone.length && listZone.filter((item) => item.zoneType === 'Polygons');
    let PolygonOptions = {
        strokeColor:theme.palette.warning.main,
        fillColor:theme.palette.warning.main,
    }
  return (
    polygons !== null && polygons !== false && polygons.length > 0 && polygons?.map((listZone) => {
        let coords =  JSON.parse(listZone.cordinates);
        return(
            <>
                <Marker 
                position={coords[0]}
                animation={hoverZone === listZone.id ? 1 : 0}
                icon={zoneMarker}
                onClick={() => multipleMarker(listZone)}
                clickable
                //onClick={() => singleMarker(createMarkerData)}
                /> 
                <Polygon 
                paths={coords}
                
                options={{
                    ...PolygonOptions,
                }}/>
            </>
        )
    })    
  )
}

export default ZonePolygon