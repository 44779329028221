import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { React, useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { StyledFormLabel } from "./StyledEle";

const RadioInput = ({
  name,
  handleRadioClick,
  label,
  options = [],
  defaultVal,
  callbackfnStatus = false,
  requiredStatus = true,
}) => {
  const theme = useTheme();
  const [defaultValue, setDefaultValue] = useState(defaultVal);

  const asterisk = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });

  const handleRadioClk = (e) => {
    setDefaultValue(e.target.value);
    handleRadioClick(e.target.value, name);
  };

  return (
    <InputWrapper>
      <FormControl>
        <ThemeProvider theme={asterisk}>
          {requiredStatus === true && (
            <StyledFormLabel required>{label}</StyledFormLabel>
          )}
          {requiredStatus === false && (
            <StyledFormLabel>{label}</StyledFormLabel>
          )}
        </ThemeProvider>
        <RadioGroup
          onChange={handleRadioClk}
          row
          defaultValue={defaultValue}
          name={name}
        >
          {options.map((option, index) => {
            return (
              <FormControlLabel
                sx={{ fontSize: theme.text.fontSize }}
                key={index}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </InputWrapper>
  );
};
export default RadioInput;
