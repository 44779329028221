import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FEATURE_DETAIL } from "../../constants/featureFormFields";
import TextInput from "../shared/TextInput";
import DraggableDialog from "../shared/DailogDrag";
import RadioInput from "../shared/RadioInput";
import SelectInput from "../shared/SelectInput";
import DatePicker from "../shared/DatePicker";
import FeatureService from "../../services/featureService";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import AlertDialogSlide from "../shared/validationDialog.js";
import { Card, CardHeader, Divider, CardContent } from "@mui/material";
import { StyedFormTitle, StyledFormButtonsRow } from "../../theme/StyledEle.js";
import { BackDropLoader } from "../shared/StyledLoader";

const RESTRICTED_ID = 1;
export default function FormPropsTextFields({ featureToEdit, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [feature, setFeature] = useState(null);
  const [reference, setReference] = useState([]);
  const [selectedExpertArr, setSelectedExpertArr] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [assignedobj, setAssignedobj] = useState({});
  const [statusEdit, setStatusEdit] = useState(false);
  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("");
  const [org, setOrg] = useState(null);
  const [linkGroup, setLinkGroup] = useState([]);
  const [linkOrder, setLinkOrder] = useState([]);
  const [circularOpen, setCircularOpen] = React.useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalOrgType = 2;
  var globalResellerId = 1;

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;

  useEffect(async () => {
    if (featureToEdit) {
      setStatusEdit(true);
      var clonedExpertArr = [];
      var dataEdit = {};
      dataEdit.id = featureToEdit;
      FeatureService.editOrgById(dataEdit)
        .then((res1) => {
          setFeature(res1.data.data);
          if ("" != res1.data.data.buttonoperation) {
            var selectedExt = res1.data.data.buttonoperation;
            var sltdExt = [];
            if ("" !== selectedExt && null != selectedExt) {
              sltdExt = selectedExt.toString().split(",");
              for (var i = 0; i < sltdExt.length; i++) {
                clonedExpertArr.push(sltdExt[i]);
              }
              setSelectedExpertArr(clonedExpertArr);
            }
          }

          var request = {};
          request.resellerId = globalResellerId;
          request.orgId = globalOrgId;
          request.page = 0;
          request.size = 100;
          request.searchText = "";

          FeatureService.getGroupList(request)
            .then((res1) => {
              setGroupList(res1.data);
            })
            .catch((err) => {});

          FeatureService.getReferenceButton(request)
            .then((res) => {
              setReference(res.data.data.content);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    } else {
      setFeature(null);
      var request = {};

      request.resellerId = globalResellerId;
      request.orgId = globalOrgId;
      request.page = 0;
      request.size = 100;
      request.searchText = "";
      FeatureService.getGroupList(request)
        .then((res1) => {
          setGroupList(res1.data);
        })
        .catch((err) => {});
      FeatureService.getReferenceButton(request)
        .then((res) => {
          setReference(res.data.data.content);
        })
        .catch((err) => {});
    }
    const response = await FeatureService.getLinkGroup({});
    setLinkGroup(response.data);
    var request = {};
    request.groupId = assignedobj.selectedId;
    const responseData = await FeatureService.getLinkOrder(request);
    setLinkOrder(responseData.data);
  }, [assignedobj.selectedId]);

  const theme = useTheme();
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    m: 1,
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Box
        sx={{
          marginY: "20px",
          "& .MuiOutlinedInput-root": { height: "25px" },
          "& .MuiFormControlLabel-label": { fontSize: theme.text.fontSize },
          "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
        }}
      >
        <fieldset style={{ border: "1px solid black" }}>
          <legend style={{ fontWeight: 600 }}> {title} </legend>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    );
  };

  const textInputCallBackFn = (val, name) => {
    callBackFn1(val, name);
  };
  const callBackFn1 = async (val, name) => {
    var obj = { ...assignedobj };

    setStatusEdit(false);
    if (name === "linkfeatureId") {
      obj.linkfeatureId = val;
    } else if (name === "featuretitle") {
      obj.featuretitle = val;
    } else if (name === "featurelink") {
      obj.featurelink = val;
    } else if (name === "linkgroup") {
      obj.linkgroup = val;
    } else if (name === "linkorder") {
      obj.linkorder = val;
    } else if (name === "mobile") {
      obj.mobile = val;
    }

    setAssignedobj(obj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { target } = e;

    var commondata = {};
    commondata = Object.fromEntries(new FormData(target));
    if (!featureToEdit) {
      if (commondata.linkfeatureId === "") {
        setTextData("Please enter Feature id");
        setError(true);
      } else if (commondata.featuretitle === "") {
        setTextData("Please enter Feature Title");
        setError(true);
      } else if (commondata.featurelink === "") {
        setTextData("Please enter Feature Link");
        setError(true);
      } else if (commondata.linkgroup === "") {
        setTextData("Please select Link Group");
        setError(true);
      } else if (commondata.linkorder === "") {
        setTextData("Please enter Link Order");
        setError(true);
      } else {
        saveDeviceDetails(e, Object.fromEntries(new FormData(target)));
        setCircularOpen(true);
      }
    } else {
      e.preventDefault();
      const { target } = e;
      if (commondata.linkfeatureId === "") {
        setTextData("Please enter Feature id");
        setError(true);
      } else if (commondata.featuretitle === "") {
        setTextData("Please enter Feature Title");
        setError(true);
      } else if (commondata.featurelink === "") {
        setTextData("Please enter Feature Link");
        setError(true);
      } else if (commondata.linkgroup === "") {
        setTextData("Please select Link Group");
        setError(true);
      } else if (commondata.linkorder === "") {
        setTextData("Please enter Link Order");
        setError(true);
      } else {
        updateDeviceDetails(e, Object.fromEntries(new FormData(target)));
        setCircularOpen(true);
      }
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const saveDeviceDetails = async (e, obj) => {
    let clonedExpertArr = [...selectedExpertArr];
    var arryStr;
    for (var i = 0; i < clonedExpertArr.length; i++) {
      if (i === 0) {
        arryStr = clonedExpertArr[i];
      } else {
        arryStr = arryStr + "," + clonedExpertArr[i];
      }
    }
    obj.buttonoperation = arryStr;
    const responseData = await FeatureService.saveFeatureDetails(obj);
    setCircularOpen(false);
    var obj1 = {};
    if (responseData.data.resultText === "Success") {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Saved Successfully";
    } else {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Not Saved Properly";
    }
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const updateDeviceDetails = async (e, obj) => {
    e.preventDefault();
    let clonedExpertArr = [...selectedExpertArr];
    var arryStr;
    for (var i = 0; i < clonedExpertArr.length; i++) {
      if (i === 0) {
        arryStr = clonedExpertArr[i];
      } else {
        arryStr = arryStr + "," + clonedExpertArr[i];
      }
    }
    obj.buttonoperation = arryStr;
    const responseData = await FeatureService.updateFeatureDetails(obj);
    setCircularOpen(false);
    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const callBackFn = async (val) => {
    setStatusEdit(false);
    var obj = {};
    obj.selectedId = val;
    setAssignedobj(obj);
  };

  function switchCaseVal(param) {
    switch (param) {
      case "linkgroup":
        if (feature != null) {
          if (statusEdit === true) {
            return (feature["linkgroup"] = feature["linkgroup"]);
          } else {
            return (feature["linkgroup"] =
              assignedobj.selectedId === undefined
                ? ""
                : assignedobj.selectedId);
          }
        }
        return assignedobj.selectedId === undefined
          ? ""
          : assignedobj.selectedId;
      case "linkfeatureId":
        if (feature != null) {
          if (statusEdit === true) {
            return (feature["linkfeatureId"] = feature["linkfeatureId"]);
          } else {
            return (feature["linkfeatureId"] =
              assignedobj.linkfeatureId === undefined
                ? ""
                : assignedobj.linkfeatureId);
          }
        }
        return assignedobj.linkfeatureId === undefined
          ? ""
          : assignedobj.linkfeatureId;
      default:
        if (feature != null) {
          return feature[param];
        } else {
          return "";
        }
    }
  }

  const DisplayCustomSelect = ({
    fieldName,
    label,
    options,
    callbackfnStatus,
  }) => {
    var commonList = [];
    //commonList=[...field.options]
    if (fieldName === "linkgroup" && groupList.length > 0) {
      commonList = [];
      commonList = [...groupList];
    }
    return (
      <SelectInput
        name={fieldName}
        label={label}
        options={options}
        defaultValue={
          feature !== null
            ? switchCaseVal(fieldName) == ""
              ? feature[fieldName]
              : switchCaseVal(fieldName)
            : switchCaseVal(fieldName) === ""
            ? options !== null
              ? 0
              : 0
            : switchCaseVal(fieldName)
        }
        callBackFn={callBackFn}
        callbackfnStatus={
          callbackfnStatus === undefined
            ? false
            : callbackfnStatus === "true"
            ? true
            : false
        }
      />
    );
  };

  const InputWrapper = ({ children }) => {
    return (
      <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
        {children}
      </Grid>
    );
  };

  const FormFields = ({ fields }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <TextInput
                  label={field.label}
                  name={field.name}
                  defaultValue={feature !== null ? feature[field.name] : ""}
                />
              </Grid>
            );
          } else if (field.type === "select") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {
                  <SelectInput
                    name={field.name}
                    label={field.label}
                    options={field.options}
                    defaultValue={
                      feature !== null
                        ? feature[field.name]
                        : field.options[0].value
                    }
                  />
                }
              </Grid>
              // <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              // {
              //  <SelectInput name={field.name} label={field.label} options={field.options} defaultValue={feature!==null?(feature[field.name]):(field.options[0].value)}  />
              //   }
              // </Grid>
            );
          } else if (field.type === "radio") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <RadioInput
                  name={field.name}
                  options={field.options}
                  defaultVal={feature !== null ? feature[field.name] : "1"}
                />
              </Grid>
            );
          } else if ((field.type = "date")) {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <DatePicker
                  name={field.name}
                  label={field.label}
                  defaultValue={
                    feature === null
                      ? Date()
                      : moment(feature[field.name], "DD/MM/YYYY")
                  }
                />
              </Grid>
            );
          }
        })}
      </>
    );
  };

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };
  const handleSelectedExperts = (e) => {
    let clonedExpertArr = [...selectedExpertArr];
    if (e.target.checked === true) {
      clonedExpertArr.push(e.target.value);
      setSelectedExpertArr(clonedExpertArr);
    } else {
      let filtered = clonedExpertArr.filter((expert) => {
        return expert !== e.target.value;
      });
      setSelectedExpertArr(filtered);
    }
    e.preventDefault();
  };
  const handleClear = () => {
    setError(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} defaultValue={feature}>
        <Card sx={{ my: 2 }} elevation={0}>
          <CardHeader title={<StyedFormTitle>Feature Detail</StyedFormTitle>} />
          <Divider />
          <CardContent>
            <Box>
              {/* 
          component='form'
          <fieldset style={{ border: "1px solid black" }}>
            <legend style={{ fontWeight: 600 }}> Feature Detail </legend> */}
              {feature === null && (
                <Grid container spacing={2}>
                  <input type="hidden" name="buttonoperation" />
                  <Grid
                    key="1"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature ID (Ex: FeaTracking)"
                      name="linkfeatureId"
                    />
                  </Grid>
                  <Grid
                    key="2"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature Title (Ex: Tracking)"
                      name="featuretitle"
                    />
                  </Grid>
                  <Grid
                    key="3"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature Link (Ex: /Tracking)"
                      name="featurelink"
                    />
                  </Grid>
                  <Grid
                    key="4"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature Icon (Ex: Tracking)"
                      name="featureIcon"
                    />
                  </Grid>
                  <Grid
                    key="5"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <DisplayCustomSelect
                      fieldName={"linkgroup"}
                      label={"Link Group"}
                      callbackfnStatus={"true"}
                      options={linkGroup}
                    ></DisplayCustomSelect>
                  </Grid>
                  <Grid
                    key="6"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Link Order"
                      name="linkorder"
                      value={linkOrder}
                    />
                  </Grid>
                  <Grid
                    key="7"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <SelectInput
                      name="status"
                      label="Status"
                      options={FEATURE_DETAIL[5].options}
                      defaultValue="0"
                    />
                  </Grid>
                </Grid>
              )}
              {feature !== null && (
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    name="buttonoperation"
                    value={feature !== null ? feature["buttonoperation"] : ""}
                  />
                  <Grid
                    key="1"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature ID *(Ex: FeaTracking)"
                      name="linkfeatureId"
                      defaultValue={
                        feature !== null ? feature["linkfeatureId"] : ""
                      }
                    />
                  </Grid>
                  <Grid
                    key="2"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature Title *(Ex: Tracking)"
                      name="featuretitle"
                      defaultValue={
                        feature !== null ? feature["featuretitle"] : ""
                      }
                    />
                  </Grid>
                  <Grid
                    key="3"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature Link (Ex: /Tracking)"
                      name="featurelink"
                      defaultValue={
                        feature !== null ? feature["featurelink"] : ""
                      }
                    />
                  </Grid>
                  <Grid
                    key="4"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Feature Icon (Ex: Tracking)"
                      name="featureIcon"
                      defaultValue={
                        feature !== null ? feature["featureIcon"] : ""
                      }
                    />
                  </Grid>
                  <Grid
                    key="4"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <DisplayCustomSelect
                      fieldName="linkgroup"
                      label="Link Group"
                      callbackfnStatus={"true"}
                      options={linkGroup}
                    ></DisplayCustomSelect>
                  </Grid>
                  <Grid
                    key="5"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextInput
                      label="Link Order "
                      name="linkorder"
                      defaultValue={
                        feature !== null ? feature["linkorder"] : ""
                      }
                    />
                  </Grid>
                  <Grid
                    key="6"
                    item
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <SelectInput
                      name="status"
                      label="Status"
                      options={FEATURE_DETAIL[5].options}
                      defaultValue={
                        feature !== null
                          ? feature["status"]
                          : FEATURE_DETAIL[5].options[0].value
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container sx={{ marginTop: "1%" }}>
                {
                  reference.length > 0 &&
                    reference.map((element) => (
                      <Grid item key={element.code} xs={2}>
                        <Checkbox
                          checked={
                            selectedExpertArr.length > 0
                              ? selectedExpertArr.indexOf(element.code) > -1
                                ? true
                                : false
                              : false
                          }
                          name={"checkbox" + element.code}
                          onChange={(e) => handleSelectedExperts(e)}
                          value={element.code || ""}
                        />
                        {element.value}
                      </Grid>
                    ))
                  //  {/* <SelectInput name="linkgroup" label="Link Group" options={FEATURE_DETAIL[3].options}  />              */}
                  //  {/* <SelectInput name="linkgroup" label="Link Group" options={FEATURE_DETAIL[3].options}  defaultValue={feature!==null?(feature['linkgroup']):(FEATURE_DETAIL[3].options[0].value)}  />              */}
                }
              </Grid>
              {/*   </fieldset>
        </Box>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <input
            type="hidden"
            name="id"
            value={feature != null ? feature.id : ""}
          />
          <ButtonAlign variant="contained" sx={buttonStyle} type="submit">
            {featureToEdit ? "Update" : "Submit"}
          </ButtonAlign>
          <ButtonAlign
            variant="contained"
            sx={buttonStyle}
            onClick={handleCancelClick}
          >
            Cancel
          </ButtonAlign>
        </Grid> */}
              {/* </fieldset> */}
            </Box>
          </CardContent>
        </Card>
        <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
          <input
            type="hidden"
            name="id"
            value={feature != null ? feature.id : ""}
          />
          <Button color="error" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button size="small" variant="contained" type="submit">
            {featureToEdit ? "Update" : "Submit"}
          </Button>
        </StyledFormButtonsRow>
        {circularOpen && <BackDropLoader />}
      </form>
      {
        <AlertDialogSlide
          open1={error}
          text={textData}
          handleClear={handleClear}
        />
      }

      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
