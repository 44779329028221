import React, { useState, useEffect } from "react";
import { FileUploadBox, StyledToggleButtonGroup } from "../../theme/StyledEle";
import { CardMedia, Fab, Grid, ToggleButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { SoftButton } from "../../theme/StyledEle";
import userService from "../../services/userService";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";


function DocumentUpload() {

  let userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(async () => {
    var request={};
    request.userId=userData.id;
    request.userName=userData.userName;
    const responseData = (await userService.loadUserDocument(request)).data.data;
    
    setAadharF(responseData.aadharFront);
    setAadharB(responseData.aadharBack);
    setLicenseF(responseData.licenseFront);
    setLicenseB(responseData.licenseBack);
  }, []);

    const [option, setOption] = useState("0");
    const [aadharF, setAadharF] = useState(null);
    const [aadharB, setAadharB] = useState(null);
    const [licenseF, setLicenseF] = useState(null);
    const [licenseB, setLicenseB] = useState(null);

    const [aadharF64, setAadharF64] = useState(null);
    const [aadharB64, setAadharB64] = useState(null);
    const [licenseF64, setLicenseF64] = useState(null);
    const [licenseB64, setLicenseB64] = useState(null);

    const dispatch = useDispatch();

    const handleSelectChange = (event, newValue) => {
        setOption(newValue);
      };

      const conver2base64 = (file, id) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (id === 1) {
            setAadharF64(reader.result.toString());
          }
          if (id === 2) {
            setAadharB64(reader.result.toString());
          }
          if (id === 3) {
            setLicenseF64(reader.result.toString());
          }
          if (id === 4) {
            setLicenseB64(reader.result.toString());
          }
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      };

      const handleImageUploadadadharF = (e) => {
        const id = 1;
        setAadharF(URL.createObjectURL(e.target.files[0]));
        conver2base64(e.target.files[0], id);
      };
    
      const handleImageUploadadadharB = (e) => {
        const id = 2;
        setAadharB(URL.createObjectURL(e.target.files[0]));
        conver2base64(e.target.files[0], id);
      };
    
      const handleImageUploadlicenseF = (e) => {
        const id = 3;
        setLicenseF(URL.createObjectURL(e.target.files[0]));
        conver2base64(e.target.files[0], id);
      };
    
      const handleImageUploadlicenseB = (e) => {
        const id = 4;
        setLicenseB(URL.createObjectURL(e.target.files[0]));
        conver2base64(e.target.files[0], id);
      };


      const saveDocuments = async () => {

        var request ={};
        request.id = userData.id;
        request.licenseFrontBase64 = licenseF64;
        request.licenseBackBase64 = licenseB64;
        request.aadharFrontBase64 = aadharF64;
        request.aadharBackBase64 = aadharB64;
        request.licenseFrontS3=(licenseF64==="" || licenseF64===null) ?licenseF:"";
        request.licenseBackS3=licenseB64==="" || licenseB64===null ?licenseB:"";
        request.aadharFrontS3=aadharF64==="" || aadharF64===null ?aadharF:"";
        request.aadharBackS3=aadharB64==="" || aadharB64===null ?aadharB:"";
        const responseData = (await userService.saveUserDocument(request));

        dispatch(
          snackService([
            responseData?.data?.resultMessage,
            responseData?.data?.resultText,
            true,
          ])
        );
      };
    

 
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{mb:4}}>Document Upload</Typography>
         <StyledToggleButtonGroup
                row
                name="documentUpload"
                value={option}
                onChange={handleSelectChange}
                exclusive
                fullWidth
                >
                <ToggleButton
                    value="0"
                >Aadhar Upload</ToggleButton>
                <ToggleButton
                    value="1"
                >License Upload</ToggleButton>
                </StyledToggleButtonGroup>


                <Grid container spacing={2} sx={{ mt: 0 ,width:"100%"}}>
                {option === "0"?
            
               
                        <>
              <Grid item lg={6}>
                <Typography variant="h8" gutterBottom >Aadhar Front</Typography>
                <FileUploadBox>
                 {aadharF !== null && aadharF !=="" &&(
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setAadharF(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                    onChange={(e) => handleImageUploadadadharF(e)}
                  />
                  <CardMedia
                    component="img"
                    src={
                      (aadharF === null || aadharF ==="")
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : aadharF
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h8" gutterBottom >Aadhar Back</Typography>
                <FileUploadBox>
                  {aadharB !== null && aadharB !=="" &&(
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setAadharB(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                    onChange={(e) => handleImageUploadadadharB(e)}
                   
                  />
                  <CardMedia
                    component="img"
                    src={
                      (aadharB === null || aadharB ==="")
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : aadharB
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              </>
              :
              <>
              <Grid item lg={6}>
              <Typography variant="h8" gutterBottom >License Front</Typography>
                <FileUploadBox>
                  {licenseF !== null && licenseF !=="" &&(
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setLicenseF(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                 
                    onChange={(e) => handleImageUploadlicenseF(e)}
                  
                  />
                  <CardMedia
                    component="img"
                    src={
                      (licenseF === null || licenseF === "")
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : licenseF
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h8" gutterBottom>License Back</Typography>
                <FileUploadBox>
                  { licenseB !== null && licenseB !=="" &&(
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setLicenseB(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                   
                    onChange={(e) => handleImageUploadlicenseB(e)}
                    
                  />
                  <CardMedia
                    component="img"
                    src={
                      (licenseB === null || licenseB === "")
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : licenseB
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              </>
}

{(aadharF !== '' &&   aadharF !== null) || (aadharB !== '' &&   aadharB !== null) || (licenseF !== '' &&   licenseF !== null) ||  (licenseB !== '' &&   licenseB !== null) ? 

                <SoftButton
                  size="small"
                  variant="contained"
                  type="button"
                  className="success"
                  sx={{ marginBottom: "25px" ,position:"absolute",bottom:0}}
                  onClick={saveDocuments}
                >
                  Upload
                </SoftButton> : ''
                
}
            </Grid>

            
    </>
  );
}

export default DocumentUpload;
