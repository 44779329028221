import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import CreateFeature from "./CreateFeature";
import ListFeature from "./ListFeature";
//import SecurityFeature from './SecurityFeature'
import { Breadcrumb } from "../shared/Breadcrumbs";
import { StyledPageLayout } from "../../theme/StyledEle";

const Feature = () => {
  const [featureToEdit, setFeatureToEdit] = React.useState(null);
  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tempcommonFilter, setTempcommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  //const [createSim, setCreateSim] = React.useState("Create Sim");
  const [tabShow, setTabShow] = React.useState("1");
  const [menuUser, setMenuUser] = useState();

  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "feature"
              ) {
                setMenuUser(
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                );
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                }
                setCommonFilter(dataStr);
                setTempcommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }

    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("8");
      tabshowval = "8";
      displaydefaulttab = 1;
    }
    return displaydefaulttab;
  };

  const [tabValue, setTabValue] = useState(() => callfun());
  const handleTabChange = (event, newValue) => {
    setFeatureToEdit(null);
    setTabValue(newValue);
  };

  const handleDevEdit = (featureEditedId) => {
    var dataStr = { ...tempcommonFilter };
    var displaydefaulttab = 2;
    if (tabShow === "6") {
      setTabShow("5");
      displaydefaulttab = 0;
    } else if (tabShow === "5") {
      displaydefaulttab = 0;
    } else if (tabShow === "4") {
      displaydefaulttab = 1;
    } else if (tabShow === "1") {
      displaydefaulttab = 0;
    } else if (tabShow === "2") {
      displaydefaulttab = 0;
    } else if (tabShow === "3") {
      displaydefaulttab = 1;
    } else if (tabShow === "8") {
      setTabShow("5");
      displaydefaulttab = 0;
    }
    setTabValue(displaydefaulttab);
    setFeatureToEdit(featureEditedId);
  };

  const handleCancel = () => {
    var dataStr = { ...tempcommonFilter };
    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 5;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("8");
      tabshowval = "8";
      displaydefaulttab = 2;
    }
    setTabValue(displaydefaulttab);
  };

  const TABS = [
    {
      label: "Create Feature",
      children: (
        <CreateFeature featureToEdit={featureToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Feature List",
      children: (
        <ListFeature onEditFeature={handleDevEdit} menuUser={menuUser} />
      ),
    },
    //  {
    //   label: 'Security',
    //   children: <SecurityFeature onEditFeature={handleDevEdit}  />
    // }
  ];

  const TABS2 = [
    {
      label: "Feature List",
      children: (
        <ListFeature onEditFeature={handleDevEdit} menuUser={menuUser} />
      ),
    },
    //  {
    //   label: 'Security',
    //   children: <SecurityFeature onEditFeature={handleDevEdit}  />
    // }
  ];

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="Feature"></Breadcrumb>
      {tabShow === "1" && (
        <StyledTabs
          tabs={TABS}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "2" && (
        <StyledTabs
          tabs={TABS2}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
    </StyledPageLayout>
  );
};

export default Feature;
