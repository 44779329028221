import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  CardHeader,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Collapse,
} from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import vehicleServices from "../../services/vehicleServices";
import uploadcsv from "../../assets/images/uploadcsv.png";
import {
  FileUploadBox,
  StyledCardShadow,
  StyledPaperShadow,
  StyledTableTitle,
} from "../../theme/StyledEle";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import { ActionMockData } from "./constants";
import VinDealerMap from "../../xls/vehicle/VinDealerMapTemplate.xls";
import VinImeiMap from "../../xls/vehicle/VinIMEIMapTemplate.xls";
import logServices from "../../services/logService";
import StyledSnackBar from "../../Utils/StyledSnackBar";

function VinUpload({ onCancel }) {
  const [file, setFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(0);
  const [template, setTemplate] = useState(1);
  const [cols, setCols] = useState(null);
  const [validate, setValidate] = useState(false);
  const globalJson2 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globaldisplayview = globalJson.displayView;
  }

  const vinImeiColumns = useMemo(() => [
    {
      Header: "VIN",
      accessor: "vinNumber",
    },
    {
      Header: "IMEI",
      accessor: "imei",
    },
  ]);

  const vinDealerColumns = useMemo(() => [
    {
      Header: "DEALER",
      accessor: "dealer",
    },
    {
      Header: "VIN",
      accessor: "vin",
    },
  ]);
  //SetCols
  const setColumns = (columnId) => {
    if (columnId === 1) {
      // Activate
      setCols(vinImeiColumns);
    } else if (columnId === 2) {
      //device status
      setCols(vinDealerColumns);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    const response = await vehicleServices.uploadVehicleList(payload);
    var obj1 = {};

    obj1.dialogButtonText = "Upload Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Uploaded Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(response.data.resultText);
    setOpen(true);
    if (responseData.status === 200) {
      setOpen(false);
      setTimeout(() => onCancel(), 2000);
    }
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    const responseData = await vehicleServices.validateVehicleList(payload);
    if (
      responseData.data !== "invalidtemplate" &&
      responseData.data !== "excesscount" &&
      Object.keys(responseData.data).length > 0
    ) {
      setResponseData(responseData.data.result);
      setDataTable(true);
      if (responseData.data.isValidateSuccess == true) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    } else {
      setResponseData([]);
    }

    var obj1 = {};

    obj1.dialogText =
      responseData.data === "excesscount"
        ? "Please upload valid count"
        : responseData.data === "invalidtemplate"
        ? "Please Upload Valid Template"
        : "Validated Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(
      responseData.data !== "excesscount" &&
        responseData.data !== "invalidtemplate"
        ? "Success"
        : "Failed"
    );
    setOpen(true);

    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  };

  const FileRef = useRef();
  const handleOnChange = (e) => {
    setFile([e.target.files[0]]);
    setIsFilePicked(true);
  };
  const handleSelectChange = (actionId) => {
    setOption(actionId);
    setTemplate(actionId);
    setColumns(actionId);
    setFile([]);
    setIsFilePicked(false);
    setResponseData([]);
    setValidate(false);
    setDataTable(false);
  };

  function temp() {
    switch (template) {
      case 1:
        return (
          <Button
            href={VinImeiMap}
            variant="contained"
            size="small"
            component={Link}
          >
            Download VIN IMEI Template
          </Button>
        );
        break;
      case 2:
        return (
          <Button
            href={VinDealerMap}
            variant="contained"
            size="small"
            component={Link}
          >
            Download VIN DEALER Template
          </Button>
        );
        break;
    }
  }

  useEffect(() => {
    temp();
  }, [template]);

  useEffect(async () => {
    var request = {};
    request.message = "Admin > Vehicle > VehicleUpload";
    request.action = "View";
    request.featureName = "Vehicle";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;

    const responseData = await logServices.saveAuditLogDetails(request);
  }, []);
  return (
    <>
      <StyledPaperShadow>
        <Stack direction="row">
          <SingleDropdown
            label={"Action"}
            listItems={{ content: ActionMockData }}
            handleChange={handleSelectChange}
            value={option}
            isSearch={false}
            showAll={false}
          />
        </Stack>
      </StyledPaperShadow>
      <Collapse in={option !== 0 ? true : false}>
        <StyledCardShadow sx={{ my: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Upload List</StyledTableTitle>}
            action={temp()}
          />
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ py: 0 }}>
              <FileUploadBox>
                <input
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleOnChange}
                  className="fileupload"
                  ref={FileRef}
                />
                <CardMedia
                  component="img"
                  src={uploadcsv}
                  sx={{ width: "60px", height: "60px", my: 2 }}
                />
                <Typography sx={{ ml: 2 }} variant="h6" color="primary">
                  Select a XLS file to upload
                </Typography>

                {isFilePicked && (
                  <Chip
                    sx={{ mt: 1 }}
                    label={FileRef.current.files[0].name}
                    color="warning"
                  />
                )}
              </FileUploadBox>
              <Stack
                direction="row"
                sx={{ mt: 2 }}
                spacing={2}
                justifyContent="center"
              >
                {file?.length > 0 && (
                  <Button onClick={handleValidate} variant="contained">
                    Validate
                  </Button>
                )}
                {validate && (
                  <Button
                    sx={{ m: 2, mt: 5 }}
                    type="submit"
                    variant="contained"
                  >
                    Upload
                  </Button>
                )}
              </Stack>
            </CardContent>
          </form>
        </StyledCardShadow>
      </Collapse>
      <Box>
        {dataTable === true && file?.length > 0 && (
          <StyledCardShadow>
            <CardContent>
              <DataTable
                includeSearchBox={false}
                columns={cols}
                data={responseData}
                styles={{ maxWidth: "50vw" }}
              />
            </CardContent>
          </StyledCardShadow>
        )}
      </Box>
      {open && (
        <StyledSnackBar
          open={open}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
}

export default VinUpload;
