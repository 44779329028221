import React, { useState, useMemo,useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Typography, Link, FormHelperText, CardHeader, Stack, Tooltip, CardContent, CardMedia, Chip } from "@mui/material";
import { Paper } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { styled } from "@mui/material/styles";
import DraggableDialog from "../shared/DailogDrag";
import SimTemplateDataUpload from './SimTemplateDataUpload.xls';
import SimTemplateUpdateToVendor from './SimTemplateUpdateToVendor.xls';
import SimOnBoardServices from "../../services/simOnBoardService";

import { FileUploadBox, StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { FaFileDownload } from "react-icons/fa";
import uploadcsv from '../../assets/images/uploadcsv.png'
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


function SimUpload({ onCancel }) {


  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [file, setFile] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [columnState, setColumnState] = useState(false);
  const [validate, setValidate] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'Sim Status',
      accessor:'sim'
    },
    {
      Header: 'Vendor',
      accessor:'vendor'
    }
  ]);

  const columns1 = useMemo(() => [
    {
      Header: 'Sim',
      accessor:'sim'
    },
    {
      Header: 'Status',
      accessor:'simStatus'
    }
  ]);


  const ButtonAlign = styled(Button)(() => ({
    textTransform: "none",
    backgroundColor: "#374850",
    color: "white",
    fontSize: "12px",
    marginRight: "5px",
    padding: "10px 16px",
    "&:hover": {
      backgroundColor: "orange",
    },
  }));


  useEffect(async () => {
    auditPayload.message = "Admin > Sim > Import Sim Details";
    auditPayload.action = "View";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    const responseData = await SimOnBoardServices.processSim(
      payload
  );
    var obj1 = {};

    obj1.dialogButtonText = "Upload Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText =
    responseData.data[0].result === "Success" ||  responseData.data[0].result === "success"
        ? "Uploaded Successfully"
        : "Upload Failed";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    // onCancel();
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }

    auditPayload.message = "Admin > Sim > Import Sim Details > Upload";
    auditPayload.action = "Upload";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    const responseData = await SimOnBoardServices.validateSim( payload );
    // let result = JSON.parse(responseData);
    setResponseData(responseData.data.result);
    var result = responseData.data.result;
    if(Object.entries(result)[0][1].simStatus !== undefined){
      setColumnState(true);
    }
    if (responseData.data.isValidateSuccess === true) {
      setValidate(true);
    } else {
      setValidate(false);
    }
    // console.log(Object.keys);
    
    setDataTable(true);

    auditPayload.message = "Admin > Sim > Import Sim Details > Validate";
    auditPayload.action = "Validate";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
   
  };

  const handleTemplateClick = (value) =>{

    if(value === 1 ){
      auditPayload.message = "Admin > Sim > Import Sim Details > Sim Data Upload";
    }else if(value === 2){
      auditPayload.message = "Admin > Sim > Import Sim Details > Move To Vendor";
    }

    auditPayload.action = "Download";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }

  const handleOnChange = (e) => {

    // console.log(e.target.files[0]);
    setFile(null);
    setFile([e.target.files[0]]);
    setIsFilePicked(true)
  };
  const FileRef = useRef();

// const handleValidate = async (e) => {
//     e.preventDefault();
//     const payload = { file: file[0] };

//     const responseData = await vehicleServices.validateVehicleList(payload);
//     setResponseData(responseData.data.result);
//     setDataTable(true);
//   };

  // return (
  //   <>
  //     <Paper elevation={5} display="flex" justifyContent="left" sx={{ p: 5 }}>
  //       <form onSubmit={handleSubmit}>
  //       <FormHelperText>Upload a list</FormHelperText>
  //         <Link
  //           href={SimTemplateDataUpload}
  //           download
  //           display="flex"
  //           justifyContent="right"
  //           style={{ color: "green" }}
  //         >
  //           Click here to download the template for SIM data Upload
  //         </Link>
  //         <Link
  //           href={SimTemplateUpdateToVendor}
  //           download
  //           display="flex"
  //           justifyContent="right"
  //           style={{ color: "green" }}
  //           sx={{marginTop:2}}
  //         >
  //           Click here to download the template for move to vendor Upload
  //         </Link>

  //         <Grid container>
  //           <Grid item xs={6}>
  //             <Box
  //               display="flex"
  //               justifyContent="center"
  //               sx={{ p: 3, border: "1px dashed grey", marginTop: "20px" }}
  //             >
  //               <input
  //                 type="file"
  //                 accept="application/vnd.ms-excel"
  //                 onChange={handleOnChange}
  //               />
  //             </Box>
  //           </Grid>
  //           <Grid item xs={6}>
  //             <ButtonAlign
  //               sx={{ m: 2, mt: 5 }}
  //               onClick={handleUpload}
  //               variant="contained"
  //             >
  //               Validate
  //             </ButtonAlign>
  //             {dataTable === true && (
  //               <ButtonAlign
  //                 sx={{ m: 2, mt: 5 }}
  //                 type="submit"
  //                 variant="contained"
  //               >
  //                 Upload
  //               </ButtonAlign>
  //             )}
  //           </Grid>
  //         </Grid>
  //       </form>
  //     </Paper>
  //     <Box>
  //       {dataTable === true && (
  //         <DataTable
  //           includeSearchBox={false}
  //           columns={columns}
  //           data={responseData}
  //           styles={{ maxWidth: "50vw" }}
  //         />
  //       )}
  //     </Box>
  //     {dataStatusConfirm !== false && (
  //       <DraggableDialog
  //         open={open}
  //         dialogButtonText={dialogContentConfirm.dialogButtonText}
  //         handleSbmtClose={handleSbmtClose}
  //         submitButtonText={dialogContentConfirm.submitButtonText}
  //         dialogText={dialogContentConfirm.dialogText}
  //         closeButtonText={dialogContentConfirm.closeButtonText}
  //         handleClearCls={handleClearCls}
  //       />
  //     )}
  //     {dataStatus !== false && (
  //       <DraggableDialog
  //         open={open}
  //         dialogButtonText={dialogContent.dialogButtonText}
  //         handleSbmtClose={handleSbmtClose}
  //         submitButtonText={dialogContent.submitButtonText}
  //         dialogText={dialogContent.dialogText}
  //         closeButtonText={dialogContent.closeButtonText}
  //         handleClearCls={handleClearCls}
  //       />
  //     )}
  //   </>
  // );

  return (
    <>
      <StyledCardShadow>
        <CardHeader
          title={<StyledTableTitle>Upload List</StyledTableTitle>}
          action={
          <Stack direction='row' spacing={2}>
            <Tooltip title='Click here to download the template for SIM data Upload' arrow placement="left"><Button size="small" variant="contained" startIcon={<FaFileDownload/>} component={Link} href={SimTemplateDataUpload} onClick={() => handleTemplateClick(1)}>SIM data Upload</Button></Tooltip>
            <Tooltip title='Click here to download the template for move to vendor Upload' arrow placement="left"><Button size="small" variant="contained" startIcon={<FaFileDownload/>} component={Link} href={SimTemplateUpdateToVendor} onClick={() => handleTemplateClick(2)} >move to vendor</Button></Tooltip>
          </Stack>}
        />
        <form onSubmit={handleSubmit}>

              <CardContent sx={{ py: 0 }}>
                <FileUploadBox>
                <input
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleOnChange}
                  className="fileupload"
                  ref={FileRef}
                />
                <CardMedia
                  component='img'
                  src={uploadcsv}
                  sx={{width:'60px', height:'60px', my:2, objectFit:'contain'}}
                  />
                <Typography sx={{ml:2}} variant="h6" color='primary'>Select a XLS file to upload</Typography>
                {isFilePicked && <Chip sx={{ml:1}} label={FileRef.current?.files[0]?.name} color="warning"/>}
                </FileUploadBox>
                <Stack direction='row' sx={{mt:2}} spacing={2} justifyContent='center'>
                  <Button variant="contained" onClick={handleUpload}> Validate </Button>
                  {validate === true && dataTable === true && <Button variant="contained" color="success" type="submit"> Upload </Button> }
              </Stack>
                
              </CardContent>
        </form>
      </StyledCardShadow>
      <Box>
        {dataTable === true && (
          <StyledCardShadow>
          <CardContent  sx={{ maxWidth: "50vw" }}>
          <DataTable
            includeSearchBox={false}
            columns={columnState === true ? columns1 :columns}
            data={responseData}
            includePagination={false}
            includeExportButton={false}
          />
          </CardContent>
          </StyledCardShadow>
            )}
      </Box>
      
        
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </>
  );
}

export default SimUpload;
