import { dashboardApi } from "../ApiSlice";

const TestRideSlice = dashboardApi.injectEndpoints({
    endpoints:builder => ({
        getState: builder.mutation({
            query: () => ({
            url: "/testDrive/getStateList",
            method: "POST",
            }),
        }),
        getCity: builder.mutation({
            query: (body) => ({
            url: "/testDrive/getCityList",
            method: "POST",
            body:body
            }),
        }),
        getDealerList: builder.mutation({
            query: (body) => ({
            url: "testDrive/getDealerListByCity",
            method: "POST",
            body:body
            }),
        }),
        saveTestDrive: builder.mutation({
            query: (body) => ({
            url: "testDrive/saveTestDriveSlot",
            method: "POST",
            body:body
            }),
        }),
        shareLocation:builder.mutation({
            query: (body) => ({
               url: "publictrack/Tracking",
               method: "POST",
               body: body,
            })
         })
    }),

   //testDrive/saveTestDriveSlot
})

export const  { useGetStateMutation, useGetCityMutation, useGetDealerListMutation, useSaveTestDriveMutation, useShareLocationMutation } =  TestRideSlice