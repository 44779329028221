import { Icon, SvgIcon, createTheme, darkScrollbar } from "@mui/material";
import { alpha } from '@material-ui/core/styles/colorManipulator';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
const colors = {
  white: "#FFFFFF",
  primary: "#001a2c",
  dark: "#0a1128",
  light: "#336fcc",
  black: "#000",
  secondary: "#6E759F",
  grey: "#9e9e9e",
  dividerBg: "#F6F8FB",
  blue: "#0077D6",
  green: "#76ff03",
  gray: "rgba(0, 0, 0, 0.12)",
  secondaryGrey: "#9e9e9e",
  disabled: "#cccccc",
  lightBlue: "rgb(40, 150, 235)",
  whitesmoke: "#f5f5f5",
  skyBlue: "#B7E9F7",
  lightGray: "hsl(199, 18%, 76%)",
  darkGray: "red",
  sideBarDark:'#01013a',
  headerDark:'#252526',
  shadows: {
    cardSm:
      "0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)",
    sidebar:
      "2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)",
    paper:
      "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
    cardShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
    dropdown:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
};

export function themeCreator({themeMode, themesNameList, fontFamily, themesNameList2, treeView,themePallete}) {
  //const themeMode = themeMode;
  const default_theme = createTheme({
    palette: {
      mode: themeMode,
      // primary: {
      //   dark: themesNameList !== null ? themesNameList[0]?.primary.dark !== null ? themesNameList[0]?.primary.dark : '#000' : themesNameList2[0]?.primary.dark,
      //   light: themesNameList !== null ? themesNameList[0]?.primary.light !== null ? themesNameList[0]?.primary.light : '#000' : themesNameList2[0]?.primary.dark,
      //   main: themesNameList !== null ? themesNameList[0]?.primary.main !== null ? themesNameList[0]?.primary.main : '#000' : themesNameList2[0]?.primary.dark,
      //   contrastText:'#fff'
      // },
      // secondary: {
      //   dark: themesNameList !== null ? themesNameList[0]?.secondary.dark !== null ? themesNameList[0]?.secondary.dark : '#000' : themesNameList2[0]?.secondary.dark,
      //   light: themesNameList !== null ? themesNameList[0]?.secondary.light !== null ? themesNameList[0]?.secondary.light : '#000' : themesNameList2[0]?.secondary.dark,
      //   main: themesNameList !== null ? themesNameList[0]?.secondary.main !== null ? themesNameList[0]?.secondary.main : '#000' : themesNameList2[0]?.secondary.dark,
      //   contrastText: "#fff",
      // },
      primary: {
        dark: themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.primary?.dark !== null ? themePallete[0]?.primary?.dark : "#01013a" : '#01013a',
        light:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.primary?.light !== null ? themePallete[0]?.primary?.light : '#00406c' : "#00406c",
        main:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.primary?.main !== null ? themePallete[0]?.primary?.main : '#03045e' : "#03045e",
        contrastText:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.primary?.contrastText !== null ? themePallete[0]?.primary?.contrastText : '#fff' :'#fff'
      },
      secondary: {
        dark: themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.secondary?.dark !== null ? themePallete[0]?.secondary?.dark : '#ff6d00' :"#ff6d00",
        light:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.secondary?.light !== null ? themePallete[0]?.secondary?.light : '#ff9e00' :"#ff9e00",
        main: themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.secondary?.main !== null ? themePallete[0]?.secondary?.main : '#ff8500' :"#ff8500",
        contrastText: themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.secondary?.contrastText !== null ? themePallete[0]?.secondary?.contrastText : '#fff' :"#fff",
      },
      success:{
          dark: "#25a244",
          light:"#ffc971",
          main: "#27ae60",
          contrastText: "#fff",
      },
      background: {
        paper: themeMode === "light" ? "#fff" : "#252526",
        default: themeMode === "light" ? "#f6f6f6" : "#1E1E1E",
      },
      divider: themeMode === "light" ? "rgba(0,0,0,0.12)" : "#bddcfc21",
      text:{
        primary: themeMode === "light" ? '#28282b' : '#fff',
        secondary:themeMode === "light" ? '#6c6c6c' : '#fff',
      }
    },
    // treeView:{
    //   primary700:themesNameList === null && themesNameList[0]?.treeview?.PRIMARY_700 : themesNameList2[0]?.treeView?.primary700,
    //   primary600:themesNameList === null && themesNameList[0]?.treeview?.PRIMARY_600 : themesNameList2[0]?.treeView?.primary600,
    //   primary500:themesNameList === null && themesNameList[0]?.treeview?.PRIMARY_500 : themesNameList2[0]?.treeView?.primary500,
    //   primary400:themesNameList === null && themesNameList[0]?.treeview?.PRIMARY_400 : themesNameList2[0]?.treeView?.primary400,
    //   primary300:themesNameList === null && themesNameList[0]?.treeview?.PRIMARY_300 : themesNameList2[0]?.treeView?.primary300,
    //   primary200:themesNameList === null && themesNameList[0]?.treeview?.PRIMARY_200 : themesNameList2[0]?.treeView?.primary200,
    //   treeViewSet:treeView
    // },
    treeView:{
      primary700:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.treeView?.primary700 !== null ? themePallete[0]?.treeView?.primary700 : '#02034a' : "#02034a",
      primary600:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.treeView?.primary600 !== null ? themePallete[0]?.treeView?.primary600 : '#04057c' : "#04057c",
      primary500:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.treeView?.primary500 !== null ? themePallete[0]?.treeView?.primary500 : '#0607ad' : "#0607ad",
      primary400:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.treeView?.primary400 !== null ? themePallete[0]?.treeView?.primary400 : '#0709de' : "#0709de",
      primary300:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.treeView?.primary300 !== null ? themePallete[0]?.treeView?.primary300 : '#2123f8' : "#2123f8",
      primary200:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.treeView?.primary200 !== null ? themePallete[0]?.treeView?.primary200 : '#5254f9' : "#5254f9",
      treeViewSet:treeView
    },
    text: {
      highlightColor: colors.blue,
      fontSize: {
        xl: "12.5px",
        lg: "11.5px",
        md: "10px",
        sm: "7px",
        xs: "7px",
      },
      fontWeight: {
        s: 600,
        xs: 500,
        
      },
      color: {
        secondary: "#6e6d6d",
        contrastText: "#fff",
        gray: colors.gray,
      },
      contrastText: "#fff",
      lineHeight: 2.5,
    },
    table: {
      header: {
        text: {
        },
        backgroundColor: colors.primary,
      },
      dataRowHighlightColor: colors.gray,
    },
    sidebar: {
      width: "220px",
      color:  '#fff',
      background:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.sidebar?.dark !== null ? themePallete[0]?.sidebar?.dark : colors.sideBarDark :colors.sideBarDark,
      padding: "0px 12px",
    },
    header:{
      background:themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.header?.dark !== null ? themePallete[0]?.header?.dark : colors.headerDark :colors.headerDark,
    },
    selected:{
      background:'#333333'
    },
    subheader: {
      fontSize: {
        xl: "40px",
        l: "30px",
        m: "20px",
      },
    },
    borderRadius: {
      primary: "20px",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        
        lg: 1200,
        xl: 1536,
      }
    },
    typography: {
      fontFamily: `${themePallete !==undefined && themePallete !==null && themePallete && themePallete.length > 0 ? themePallete[0]?.fontFamily !== null ? themePallete[0]?.fontFamily : fontFamily :fontFamily} ,sans-serif`,
      h1: {
        fontWeight: 600,
      },
      h2: {
        fontWeight: 600,
      },
      h3: {
        fontWeight: 600,
      },
      body1: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    //transitions
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 600,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
      }},
     // base components
     components: {
      MuiDrawer:{
        styleOverrides: {
          root: ({ theme, ownerState  }) => ({
            transition:'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s !important',
          })
        }
      },
      MuiModal:{
        styleOverrides: {
          root: ({ theme, ownerState  }) => ({
              zIndex:'12002 !important',
              '& .MuiDrawer-paper':{
                background:'transparent'
              }
          })
        }
      },
      MuiSnackbar :{
        styleOverrides: {
          root: ({ theme, ownerState  }) => ({
              zIndex:'13002 !important',
          })
        }
      },
      MuiMenu:{
        styleOverrides: {
          root: ({ theme, ownerState  }) => ({
            '& .MuiMenu-list':{
              maxHeight:'350px'
            }
          })
        }
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme, ownerState  }) => ({
            textTransform:'capitalize',
            boxShadow:'none !important',
            ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              background:theme.palette.mode==='light'? theme.palette.primary.main : theme.palette.primary.light,
              color:theme.palette.mode==='light'? theme.palette.primary.contrastText : theme.palette.primary.light
            }),
            [theme.breakpoints.down("xl")]: {
              fontSize:'.7rem'
            },
          }),
        },
      },
      MuiRadio:{
        styleOverrides: {
          root: ({ theme, ownerState  }) => ({
             //Radio Button
             '&.muiThemeRadio-root':{
              [theme.breakpoints.down("xl")]: {
                padding:'2px'
              },
            }
          })
        }
      },
      //SVG icon
      MuiSvgIcon:{
        styleOverrides: {
          root: ({ theme, ownerState  }) => ({
            [theme.breakpoints.down("xl")]: {
              fontSize:'16px'
            },
          })
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: ({theme}) => ({
              [theme.breakpoints.down('xl')]:{
                  minHeight:'auto'
              },
            '& .MuiTab-root':{
              [theme.breakpoints.down('xl')]:{
                minHeight:'35px',
                fontSize:'.9rem',
                fontWeight:700,
          },
            }
          }),
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ theme  }) => ({
            '&.Mui-checked':{
              color:theme.palette.secondary.main,
            }
          }),
        },
      },
      MuiPopperUnstyled:{
        styleOverrides: {
          root: ({ theme  }) => ({
            zIndex:2500,
          }),
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: ({ theme  }) => ({
            textTransform:'capitalize',
            padding:'5px 8px !important',
            '&.Mui-selected':{
              background:`${alpha(theme.palette.secondary.light,.32)} !important`,
              color:`${theme.palette.secondary.main} !important`,
            },
            [theme.breakpoints.down("xl")]: {
              padding:"7px",
              fontSize:'.6rem'
            },
          }),
        },
      },
      //Typography
      MuiTypography:{
        styleOverrides: {
          root: ({ownerState, theme}) => ({
            //color:theme.palette.text.primary,
            ...ownerState.variant === 'body1' && {
              [theme.breakpoints.down("xl")]: {
                fontSize: ".75rem",
                fontWeight:500
              },
            },
            ...ownerState.variant === 'body2' && {
              [theme.breakpoints.down("xl")]: {
                fontSize: ".6rem"
              },
            },
            ...ownerState.variant === 'subtitle1' && {
              [theme.breakpoints.down("xl")]: {
                fontSize: ".8rem"
              },
            },
            ...ownerState.variant === 'h6' && {
              [theme.breakpoints.down("xl")]: {
                fontSize: "1rem"
              },
            },
          })
        }
      },
      MuiFormControlLabel:{
        styleOverrides: {
          root: ({ theme}) => ({
            [theme.breakpoints.down('xl')]:{
              'label':{
                fontSize:'.8rem'
              },
            }, 
          })
        }
      },
      MuiFormControl:{
        styleOverrides: {
          root: ({ theme}) => ({
            [theme.breakpoints.down('xl')]:{
              '& .MuiFormHelperText-root':{
                fontSize:'.6rem',
                top:'-18px'
              },
            }, 
          })
        }
      },
      MuiToolbar:{
        styleOverrides: {
          root: ({ theme}) => ({
            minHeight:'56px !important',
            [theme.breakpoints.down('xl')]:{
                minHeight:'44px !important'
            }
          })}
      },

      //MuiList
      MuiList:{
        styleOverrides: {
          root: ({ theme}) => ({
              '& .small-menuItem':{
                  padding:'5px 15px',
                  '& .MuiListItemAvatar-root':{
                    minWidth:'0px',
                    marginRight:"10px"
                  }
              }
          })}
      },
      //MuiPaper
      MuiPaper:{
        styleOverrides: {
          root: ({ theme}) => ({
              background:theme.palette.background.paper,
                '&.MuiMenu-paper':{
                    borderRadius:'15px',
                    boxShadow:`0px 16px 40px ${alpha(theme.palette.common.black, .05)}`,
                    border:`.5px solid ${theme.palette.divider}`,
                  '& .MuiMenuItem-root':{
                    fontWeight:400,
                  }
                }
          })}
      },
      MuiPickersPopper:{
        styleOverrides: {
          root: ({ theme}) => ({
              zIndex:12006
          })}
      },
      //Base Css
      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          body: {
            ...themeParam.palette.mode === 'dark' ? darkScrollbar() : null,

            // rs date range picker
            '.rs-stack':{
                flexDirection:'column'
            },
            '& .addTimerPicker':{
              height:'318px',
                '& .rs-calendar':{
                  height:'318px',
                }
            },
            '& .rs-picker-daterange-calendar-group':{
                fontFamily:themeParam.typography.fontFamily,

                '& .rs-calendar':{

                  //Start Date
                  '& .rs-calendar-table-cell-selected-start':{
                    '& .rs-calendar-table-cell-content':{
                      position:'relative',
                      overflow:'visible',
                      '&:before':{
                          content:"'s'",
                          position:'absolute',
                          top:'-5px',
                          left:'-2px',
                          width:'14px',
                          height:'14px',
                          fontSize:'11px',
                          background:themeParam.palette.info.main,
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          borderRadius:'5px',
                      }
                    } 
                  },

                  '& .rs-calendar-table-cell-selected-end':{
                    '& .rs-calendar-table-cell-content':{
                      position:'relative',
                      overflow:'visible',
                      '&:after':{
                          content:"'E'",
                          position:'absolute',
                          top:'-5px',
                          right:'-2px',
                          width:'14px',
                          height:'14px',
                          fontSize:'11px',
                          background:themeParam.palette.info.main,
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          borderRadius:'5px',
                      }
                    }
                  },

                   '& .rs-calendar-btn-close':{
                      display:'none'
                   },
                   '& .rs-calendar-header':{
                      color:themeParam.palette.text.primary,
                      '& button':{
                        color:themeParam.palette.text.primary,
                        fontWeight:500,
                        fontSize:'12.5px',
                        '&.rs-calendar-header-error':{
                          color:themeParam.palette.error.main,
                            '&:hover':{
                                background:alpha(themeParam.palette.error.main, .2)
                            }
                        }
                      }
                   },
                   '& .rs-calendar-table-header-cell':{
                      fontWeight:500,
                   },
                   '& .rs-calendar-table-cell .rs-calendar-table-cell-content':{
                      '& span':{
                        color:themeParam.palette.text.primary,
                      },
                   },
                   '& .rs-calendar-table-cell-disabled':{
                    '& .rs-calendar-table-cell-content span':{
                      color:`${themeParam.palette.text.disabled} !important`,
                    },
                  },
                   '& .rs-calendar-time-dropdown':{
                    height:'100%',
                    maxHeight:'225px',
                  },
                  '& .rs-calendar-time-dropdown-cell-active':{
                    background:themeParam.palette.secondary.main,
                  },
                  '& .rs-calendar-header-time-toolbar':{
                      width:'100%',
                      left:'0px',
                      bottom:'0px',
                      position:'absolute',
                      borderTop:`.5px solid #ddd`,
                      '& button':{
                        width:'97%',
                        display:'flex',
                        fontSize:'14px',
                        fontWeight:500,
                        padding:'8px 5px',
                        color:themeParam.palette.text.primary,
                        '&:before':{
                          position:'absoute', 
                          fontSize:'11.5px',
                          marginRight:'15px',
                          color:themeParam.palette.text.secondary
                        },
                        '&:after':{
                          content:'""',
                          position:'absolute',
                          fontFamily: 'FontAwesome',
                          fontWeight: 'normal',
                          right:'0px',
                          fontSize:'18px',
                          marginRight:'15px',
                          color:themeParam.palette.text.secondary,
                          width:'25px',
                          height:'25px',
                          //background:'url(https://uxwing.com/wp-content/themes/uxwing/download/time-and-date/clock-icon.png)'
                        },
                      },
                  },
                  '&:first-child':{
                      '& .rs-calendar-header-time-toolbar button':{
                        '&:before':{
                          content:"'Start Time'",
                        }
                      }
                  },
                  '&:last-child':{
                    '& .rs-calendar-header-time-toolbar':{
                      'button':{
                        '&:before':{
                          content:"'End Time'",
                        }
                      }
                    }
                }
                }
            },
            '& .rs-picker-toolbar':{
              padding:'5px',
              alignItems:'baseline !important',
              justifyContent:'space-between !important',
              color:themeParam.palette.info.main,
              '& .customtext':{
                fontSize:'12px',
                marginBottom:'0px',
              }
            },
            '& .daterangepicker':{
               padding: '0px',
              borderRadius:'5px',
              borderWidth:0,
              boxShadow:'0px 0px 2px #ddd',
              background:themeParam.palette.background.paper,
              boxShadow:'0px 0px 12px rgba(0,0,0,.2)',
              fontFamily:themeParam.typography.fontFamily,
              '&:before, &:after':{
                borderWidth:0,
                boxShadow:'0px 0px 0px #ddd',
                borderColor:themeParam.palette.background.paper,
              },
              '& .drp-calendar':{
                maxWidth:'fit-content',
                padding:'10px !important',
                marginTop:'20px',
                '& .calendar-table':{
                  //borderBottom:`.5px solid ${themeParam.palette.divider}`
                },
                '&.left':{
                    '& .calendar-time':{
                        '&::before':{
                          content:"'Start Time'",
                          position:'absolute',
                          left:'0px',
                          top:'-10px',
                          width:'100%',
                          textAlign:'left',
                          fontSize:'12px',
                          fontWeight:500,
                        }
                    }
                },
                '&.right':{
                  '& .calendar-time':{
                      '&::before':{
                        content:"'End Time'",
                        position:'absolute',
                        left:'0px',
                        top:'-10px',
                        width:'100%',
                        textAlign:'left',
                        fontSize:'12px',
                        fontWeight:500,
                      }
                  }
              }
              },
               '& .calendar-table':{
                //background:themeParam.palette.background.default,
                //borderWidth:0,
                '& td':{
                  background:themeParam.palette.background.paper,
                  minWidth:'25px',
                  width:'25px',
                  height:'25px',
                  fontWeight:500,
                 }
               },
              'td.active':{
                background:`${themeParam.palette.secondary.dark} !important`,
                color:themeParam.palette.primary.contrastText
               },
              '.in-range':
               {
                background:`${alpha(themeParam.palette.grey[400], .08)} !important`,
                color:themeParam.palette.secondary.main
               },
                '.calendar-time':{
                    display:'grid',
                    gridTemplateColumns:'1fr auto 1fr auto 1fr',
                    gridColumnGap:'10px',
                    paddingTop:'20px',
                  '& .hourselect, .minuteselect, .secondselect':{
                      width:'100%',
                  },
                  '& .hourselect, .minuteselect, .ampmselect, .secondselect':{
                    borderWidth:0,
                    background:themeParam.palette.background.paper,
                    borderRadius:'5px',
                    border:`.5px solid ${themeParam.palette.divider}`
                  }
               },
              '& .ranges':{
                  margin:'0px 0px 0px 0px',
                  '& ul':{
                      width:"110px",
                    '& li':{
                      borderRadius:'0px',
                      fontSize:'12px',
                      fontWeight:500,
                      padding:'6px 8px',
                      '&.active':{
                        background:alpha(themeParam.palette.secondary.dark, 1),
                    }
                    }
                  }
               },
               '& .drp-buttons':{
                    padding:'0px',
                    '& .drp-selected':{
                        position:'absolute',
                        top:'10px',
                        left:'57%',
                        transform:'translateX(-50%)',

                    },  
                    '& div:first-child':{
                      textAlign:'left',
                      float:'left',
                      padding:'16px',
                      //borderBottom:`.5px solid ${themeParam.palette.divider}`,
                      '& .customtext':{
                        fontSize:'12px',
                        marginBottom:'0px',
                        fontWeight:500,
                        color:themeParam.palette.primary.main
                    }
                  }
                },
              '& .applyBtn':{
                  background: alpha(themeParam.palette.success.main, 1),
                  color:themeParam.palette.success.contrastText,
                  boxShadow:'none',
                  margin:'6px 6px'
               },
              '& .cancelBtn':{
                  background:themeParam.palette.grey[100],
                  boxShadow:'none !important'
               }, 
              //  '& td.available:hover, .daterangepicker th.available:hover':{
              //     background:`${themeParam.palette.mode === 'light' ? alpha(themeParam.palette.grey[700], .5) : alpha(themeParam.palette.grey[700], .5)} !important`
              //  },
              
              //  '.prev span, .next span':{
              //   borderStyle:'solid',
              //   borderColor:`${themeParam.palette.text.primary} !important`
              //  },
              //  '.cancelBtn, .applyBtn':{
              //     padding:'10px 5px !important',
              //     borderRadius:'5px',
              //  },
              
            },
            '.ant-picker':{
              position:'absolute',
              bottom:'0px',
              zIndex:-1,
              opacity:0,
            },
            '& .ant-picker-month-panel':{
                '& .ant-picker-body .ant-picker-content':{
                    height:'150px'
                }
            },
            '& .ant-picker-panel-container':{
              borderRadius:'10px',
              boxShadow:`0px 0px 10px rgba(0,0,0,.1)`
            },
          //  '.ant-picker-content':{
          //     height:'auto !important',
          //   '.ant-picker-cell':{
          //     padding:'8px 0px'
          //    },
          //  },
           '.rs-calendar-table-cell-selected .rs-calendar-table-cell-content':{
              //background:'red',
              overflow:'hidden'
           },

           '.rs-calendar-table-cell-selected':{
              '& .rs-calendar-table-cell-content':{
                background:alpha(themeParam.palette.secondary.main, .8),
                '& span':{
                  color:`${themeParam.palette.secondary.contrastText} !important`,
                }
              }
           },
           '.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content':{
            background:{...themeParam.palette.primary.main},
            },
            '.rs-calendar-table-cell-in-range:before':{
              background:alpha(themeParam.palette.secondary.main, .2)
            },
            '.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content':{
              background:themeParam.palette.secondary.dark,
            },
            '.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content':{
              background:alpha(themeParam.palette.secondary.main, .2),
              color:themeParam.palette.secondary.main
            },
            '.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content':{
              boxShadow:`inset 0 0 0 1px ${themeParam.palette.secondary.main}`
            },
            '.rs-btn-primary':{
                background:themeParam.palette.success.main,
                '&:hover':{
                  background:themeParam.palette.success.dark,
                }
            },
            '.rs-calendar-table-cell-day':{
                fontWeight:500
            }
          }
        }),
      },
    },
  });
  
    return default_theme
}

