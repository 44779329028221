import Service from "./service";

const roleSettingServices = {
  getGridViewForFeature: function (resource) {
    return Service.get('/RoleSettings/gridRoleView',resource)
  },
  saveFeatureDetails: function (data) {
    return Service.post('/feature/saveMenu',data,null)
  },
  deleteDeviceById: (deviceId) => {
    return Service.post('/device/deleteDevice',deviceId, null)
  },
  editOrgById: (featureData) =>{
    return Service.post('/feature/getMenu',featureData,null)
  },
  updateRoleSettingsDetails: function (data) {
    return Service.get('/roleSettings/getEditRoleFeature',data)
  },
  getReference: function (resource) {
    return Service.post('/reference/listReference',resource,null)
  },
  getFeaturListAll: function (resource){
    return Service.post('RoleSettings/getAllFeature',resource,null);
  },
  getEditRoleFeature: function (resource){
    return Service.get('RoleSettings/getEditRoleFeature',resource);
  },
  getSaveRoleFeature: function (resource){
    return Service.post('RoleSettings/saveFeature',resource,null);
  }     
  
};

export default roleSettingServices;