import Service from "./service";

const dealerServices = {
  getGridViewForKYCFeed: function (resource) {
    return Service.post("/Dealer/getKYCFeed", resource, null);
  },
  saveDealerDetails: function (data) {
    return Service.post("/Dealer/createDealerKYC", data, null);
  },
  getGridViewForProcessedFeed: function (resource) {
    return Service.post("/Dealer/getKYCProcessedFeed", resource, null);
  },
  validateDealerList: function (data) {
    return Service.post(
      "/Dealer/ValidateDealerExcel",
      data,
      null,

      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  uploadDealerList: function (data) {
    return Service.post(
      "/Dealer/dealerProcessExcel",
      data,
      null,

      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  editDealerByGstIn: function (gstIn) {
    return Service.post("Dealer/getKYCProcessedObject", { gstIn });
  },
  updateKYCApproval: function (data) {
    return Service.post("/Dealer/updateDealerKYCDetails", data, null);
  },
};

export default dealerServices;
