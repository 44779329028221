const ORGANIZATION_DETAIL = [
  {
    type: "select",
    label: "Customer",
    name: "orgId",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "select",
        value: 0,
      },
    ],
  },
  {
    type: "select",
    label: "Fleet",
    name: "vehicleId",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "select",
        value: 0,
      },
    ],
  },
];

const PERSONAL_DETAILS = [
  {
    type: "text",
    label: "Name",
    name: "name",
  },
  {
    type: "text",
    label: "Mobile",
    name: "mobile",
  },
  {
    type: "text",
    label: "Email ID",
    name: "emailId",
  },
  {
    type: "text",
    label: "Address",
    name: "address",
  },
  {
    type: "text",
    label: "License Number",
    name: "licenseNumber",
  },
  {
    type: "text",
    label: "Emergency Contact Number",
    name: "emergencyContact",
  },
  {
    type: "text",
    label: "Languages Known",
    name: "languagesKnown",
  },
  {
    type: "date",
    label: "Date Of Birth",
    name: "dob",
  },
  {
    type: "select",
    label: "Blood Group",
    name: "bloodGroup",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "select",
        value: 0,
      },
      {
        id: 2,
        title: "O+",
        value: 1,
      },
      {
        id: 3,
        title: "O-",
        value: 2,
      },
      {
        id: 4,
        title: "A+",
        value: 3,
      },
      {
        id: 5,
        title: "A-",
        value: 4,
      },
      {
        id: 6,
        title: "B+",
        value: 5,
      },
      {
        id: 7,
        title: "B-",
        value: 6,
      },
      {
        id: 8,
        title: "AB+",
        value: 7,
      },
      {
        id: 1,
        title: "AB-",
        value: 8,
      },
    ],
  },
  {
    type: "radio",
    name: "gender",
    label: "Gender",
    options: [
      {
        id: 1,
        label: "male",
        value: 1,
      },
      {
        id: 2,
        label: "female",
        value: 2,
      },
    ],
  },
  {
    type: "radio",
    name: "martialStatus",
    label: "Martial Status",
    options: [
      {
        id: 1,
        label: "Yes",
        value: 1,
      },
      {
        id: 2,
        label: "No",
        value: 2,
      },
    ],
  },
];

const EMPLOYMENT_DETAILS = [
  {
    type: "date",
    label: "Joining Date",
    name: "joiningDate",
  },
  {
    type: "select",
    label: "Rest Day",
    name: "restDays",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Sunday",
        value: 0,
      },
      {
        id: 2,
        title: "Monday",
        value: 1,
      },
      {
        id: 2,
        title: "Tueday",
        value: 2,
      },
      {
        id: 2,
        title: "Wednesday",
        value: 3,
      },
      {
        id: 2,
        title: "Thursday",
        value: 4,
      },
      {
        id: 2,
        title: "Friday",
        value: 5,
      },
      {
        id: 2,
        title: "Saturday",
        value: 6,
      },
    ],
  },
  {
    type: "select",
    label: "Current Status",
    name: "status",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Active",
        value: 1,
      },
      {
        id: 2,
        title: "Inactive",
        value: 0,
      },
    ],
  },
  {
    type: "text",
    label: "Working Hours",
    name: "workingHours",
  },
  {
    type: "radio",
    name: "bgv",
    label: "backgroundVerification",
    options: [
      {
        id: 1,
        label: "Yes",
        value: 1,
      },
      {
        id: 2,
        label: "No",
        value: 0,
      },
    ],
  },
  {
    type: "text",
    label: "Remarks",
    name: "remarks",
  },
];
module.exports = {
  ORGANIZATION_DETAIL,
  PERSONAL_DETAILS,
  EMPLOYMENT_DETAILS,
};
