import { StyledPageLayout,StyledTab,StyledTabBox } from "../../theme/StyledEle";
import React, { useEffect, useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import RawFeed from "./FeedLog";
import RejectedRawFeed from "./RejectedRawFeed";
import StagingFeed from "./StagingFeed";
import RejectedStagingFeed from "./RejectedStagingFeed";
import DeviceRawFeed from "./DeviceRawFeed";
import { Breadcrumb } from "../shared/Breadcrumbs";
import CanFeedDropDown from "./CanFeedDropdown";
import FeedLogDropdown from "./FeedLogDropdown";
import { useFeedLogListMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { FeedLogTabs } from "../../Utils/Constans";
import { useSelector } from "react-redux";
import { Tabs, Box} from "@mui/material";
import PropTypes from "prop-types";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const FeedLog = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

   //Hide & Show Tabs
   const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);

  const [tabValue, setTabValue] = useState(4);
  const [value, setValue] = React.useState(47);

  const handleTabChange = (event, newValue) => {
    if (newValue !== null) {
      setTabValue(newValue);
    }
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
     }


  const [getFeedList, { data: feedLogListData, isLoading }] =
    useFeedLogListMutation();
  const [auditLog] = useAuditLogMutation();


  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [payload, setPayload] = useState(null);

  let commonPayload = {};

  const getData = (data) => {
  

    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderdir = "desc";
    commonPayload.search = "";
    commonPayload.imei = data.imei;
    commonPayload.orgId = data.fleetId;
    commonPayload.regionId = data.regionId;
    commonPayload.resellerId = resellerId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.sortId = 0;

    commonPayload.page = 0;
    commonPayload.size = 25;

    //Service Call
    setPayload(commonPayload);
    if (value === 47 && data?.vehicleId !== undefined) {
      commonPayload.url = 4;
      getFeedList(commonPayload);
    } else if (value === 46 && data?.vehicleId !== undefined) {
      commonPayload.url = 3;
      getFeedList(commonPayload);
    } else if (value === 45 && data?.vehicleId !== undefined) {
      commonPayload.url = 2;
      getFeedList(commonPayload);
    } else if (value === 44 && data?.vehicleId !== undefined) {
      commonPayload.url = 0;
      getFeedList(commonPayload);
    } else if (value === 43 && data?.vehicleId !== undefined) {
      commonPayload.url = 1;
      getFeedList(commonPayload);
    }
    auditPayload.message = "Logs > Feed Log > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "User Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  React.useEffect(() => {
    
    if (value === 47) {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.url = 4;
        getFeedList(newobj);
      }
    } else if (value === 46) {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.url = 3;
        getFeedList(newobj);
      }
    } else if (value === 45) {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.url = 2;
        getFeedList(newobj);
      }
    } else if (value === 44) {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.url = 0;
        getFeedList(newobj);
      }
    } else if (value === 43) {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.url = 1;
        getFeedList(newobj);
      }
    }

    auditPayload.message = "Logs > Feed Log";
    auditPayload.action = "View";
    auditPayload.featureName = "Logs";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, [value]);



  const TABS = [
    {
      label: "Rejected Raw Feed",
      children: <RejectedRawFeed data={feedLogListData} payload={payload} isLoading={isLoading}/>,
    },
    {
      label: "Device Raw Feed",
      children: (
        <DeviceRawFeed
          data={feedLogListData}
          payload={payload}
          isLoadingOut={isLoading}
        />
      ),
    },
    {
      label: "Rejected Staging Feed",
      children: (
        <RejectedStagingFeed data={feedLogListData} payload={payload} isLoadingOut={isLoading}/>
      ),
    },
    {
      label: "Staging Feed",
      children: <StagingFeed data={feedLogListData} payload={payload} isLoadingOut={isLoading}/>,
    },
    {
      label: "Raw Feed",
      children: <RawFeed data={feedLogListData} payload={payload} isLoadingOut={isLoading}/>,
    },
  ];


  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Logs" subDiv="Feed Log" includePopup={true}></Breadcrumb>
      <FeedLogDropdown
        getData={getData}
        isShowAll={false}
        tabValues={value}
        allowDays={1}
        allowedMonth={3}
      />
      {/* <StyledTabs
        tabs={TABS}
        handleChange={handleTabChange}
        value={tabValue}
        className="feedTabs"
      /> */}

<StyledTabBox>

<Tabs 
value={value}
onChange={handleChange}
indicatorColor="secondary"
>
  {
    FeedLogTabs?.map((item) => {
      if(tabsOpt?.includes(item.value)){
        return( <StyledTab value={item.value} label={item.label}/> )
      } 
    })
  }

</Tabs>

  {/* <Tabs
    value={value}
    onChange={handleChange}
    textColor="secondary"
    indicatorColor="secondary"
    aria-label="secondary tabs example"
  >
    <StyledTab value={43} label="Device Raw Feed" />
    <StyledTab value={44} label="Rejected Raw Feed" />
    <StyledTab value={45} label="Rejected Staging Feed" /> 
    <StyledTab value={46} label="Staging Feed" /> 
    <StyledTab value={47} label="Raw Feed" /> 
  </Tabs> */}
</StyledTabBox>
            <CustomTabPanel value={value} index={47}>
            <RawFeed data={feedLogListData} payload={payload} isLoadingOut={isLoading}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={46}>
            <StagingFeed data={feedLogListData} payload={payload} isLoadingOut={isLoading}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={45}>
            <RejectedStagingFeed data={feedLogListData} payload={payload} isLoadingOut={isLoading}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={44}>
            <RejectedRawFeed data={feedLogListData} payload={payload} isLoading={isLoading}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={43}>
           <DeviceRawFeed data={feedLogListData} payload={payload} isLoadingOut={isLoading}/>            
          </CustomTabPanel>
    </StyledPageLayout>
  );
};

export default FeedLog;
