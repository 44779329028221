import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  CardHeader,
  CardContent,
  CardMedia,
  Stack,
  Chip,
  Collapse,
} from "@mui/material";
import { ActionMockData } from "./Constants";
import { DataTable } from "../shared/data_table/DataTable";
import simServices from "../../services/simServices";
import {
  FileUploadBox,
  SoftButton,
  StyledCardShadow,
  StyledPaperShadow,
  StyledTableTitle,
} from "../../theme/StyledEle";
import uploadcsv from "../../assets/images/uploadcsv.png";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import SimTemplate from "../../xls/Sim/SimTemplate.xls";
import SimActivationTemplate from "../../xls/Sim/SimActivationTemplate.xls";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useUploadSimMutation, useValidateSimMutation } from "../../store/slice/UploadSlice";
import ScaleLoader from "react-spinners/ScaleLoader";
import { RiSearchEyeLine } from "react-icons/ri";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";


function SimUpload({ onCancel }) {
  const [file, setFile] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(0);
  const [template, setTemplate] = useState(1);
  const [cols, setCols] = useState(null);
  const [validate, setValidate] = useState(false);

  const globalJson2 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalUserName = "";
  var globalOrgName = "";

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globaldisplayview = globalJson.displayView;
    globalOrgName = globalJson.orgName;
    globalUserName = globalJson.userName;
  }

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId, 
    orgName: globalOrgName, 
    userName: globalUserName,
  };


  const simColumns = useMemo(() => [
    {
      Header: "sim",
      accessor: "sim",
    },
    {
      Header: "mobile",
      accessor: "mobile",
    },
  ]);

  const simActivationColumns = useMemo(() => [
    {
      Header: "sim",
      accessor: "sim",
    },
    {
      Header: "mobile",
      accessor: "mobile",
    },
  ]);

  //SetCols
  const setColumns = (columnId) => {
    if (columnId === 1) {
      // Activate
      setCols(simColumns);
    } else if (columnId === 2) {
      //device status
      setCols(simActivationColumns);
    }
  };


  
  const [validateSim, {data:validateSimData, isLoading:validateSimLoading, isSuccess:validateSimSuccess}] = useValidateSimMutation();
  const [uploadSim, {data:uploadSimData, isLoading:uploadSimLoading, isSuccess:uploadSimSuccess}] = useUploadSimMutation();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    let formData = new FormData();
    formData.append(`file`, file[0]);

    //const response = await simServices.processSim(payload);
    const response = await uploadSim(formData);
    // dispatch(snackService([response.data ? 'Validate Successfully' : 'Sims Already Exists' , responseData.data.isValidateSuccess? 0 : 1 , true]))
    //dispatch(snackService([null, null, null]))
    var obj1 = {};

    obj1.dialogButtonText = "Upload Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Uploaded Successfully";

    //setDataStatus(true);
    //setDialogContent(obj1);
    //setDataStatusConfirm(response.data.resultText);
    setOpen(true);
    if (responseData.status === 200) {
      setOpen(false);
      setTimeout(() => onCancel(), 2000);
    }

    auditPayload.message = "Admin > Sim > Import Sim Details > Upload";
    auditPayload.action = "Upload";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;
    auditLog(auditPayload);
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    let formData = new FormData();
    formData.append(`file`, file[0]);
    //const responseData = await simServices.uploadSim(payload);
    const responseData = await validateSim(formData);
    dispatch(snackService([responseData.data.isValidateSuccess ? 'Validate Successfully' : 'Sims Already Exists' , responseData.data.isValidateSuccess? 0 : 1 , true]))
    if (
      responseData.data !== "invalidtemplate" &&
      responseData.data !== "excesscount" &&
      Object.keys(responseData.data).length > 0
    ) {
      setResponseData(responseData.data.result);
      setDataTable(true);
      if (responseData.data.isValidateSuccess == true) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    } else {
      setResponseData([]);
    }

    var obj1 = {};

    obj1.dialogText =
      responseData.data === "excesscount"
        ? "Please upload valid count"
        : responseData.data === "invalidtemplate"
        ? "Please Upload Valid Template"
        : "Validated Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(
      responseData.data !== "excesscount" &&
        responseData.data !== "invalidtemplate"
        ? "Success"
        : "Failed"
    );
    setOpen(true);

    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
    auditPayload.message = "Admin > Sim > Import Sim Details > Validate";
    auditPayload.action = "Validate";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  };


 
  const [isFilePicked, setIsFilePicked] = useState(false);
  const FileRef = useRef();
  const handleOnChange = (e) => {
    setFile([e.target.files[0]]);
    setIsFilePicked(true);
  };
  const handleSelectChange = (actionId) => {
    setOption(actionId);
    setTemplate(actionId);
    setColumns(actionId);
    setFile([]);
    setIsFilePicked(false);
    setResponseData([]);
    setValidate(false);
    setDataTable(false);

      auditPayload.message = "Admin > Sim > Import Sim Details > Action";
      auditPayload.action = "Select";
      auditPayload.featureName = "Sim";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = globalUserName;
  
      auditLog(auditPayload);
  
  };

  const handleTemplateClick = () =>{
    auditPayload.message = "Admin > Sim > Import Sim Details > Download Template";
    auditPayload.action = "Download";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  }

  function temp() {
    switch (template) {
      case 1:
        return (
          <Button
            href={SimTemplate}
            variant="contained"
            size="small"
            component={Link}
            onClick={handleTemplateClick}
          >
            Download Sim Data Template
          </Button>
        );
        break;
      case 2:
        return (
          <Button
            href={SimActivationTemplate}
            variant="contained"
            size="small"
            component={Link}
            onClick={handleTemplateClick}
          >
            Download Sim Activation Data Template
          </Button>
        );
        break;
    }
  }

  useEffect(() => {
    temp();
  }, [template]);

  useEffect(async () => {
    auditPayload.message = "Admin > Sim > Import Sim Details";
    auditPayload.action = "View";
    auditPayload.featureName = "Sim";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  }, []);


  
  
  return (
    <>
      <StyledPaperShadow>
        <Stack direction="row">
          <SingleDropdown
            label={"Action"}
            listItems={{ content: ActionMockData }}
            handleChange={handleSelectChange}
            value={option}
            isSearch={false}
            showAll={false}
          />
        </Stack>
      </StyledPaperShadow>
      <Collapse in={option !== 0 ? true : false}>
        <StyledCardShadow sx={{ my: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Upload List</StyledTableTitle>}
            action={temp()}
          />
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ py: 0 }}>
              <FileUploadBox>
                <input
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleOnChange}
                  className="fileupload"
                  ref={FileRef}
                />
                <CardMedia
                  component="img"
                  src={uploadcsv}
                  sx={{ width: "60px", height: "60px", my: 2 }}
                />
                <Typography sx={{ mt: 2 }} variant="h6" color="primary">
                  Select a XLS file to upload
                </Typography>
                {isFilePicked && (
                  <Chip
                    sx={{ ml: 1 }}
                    label={FileRef.current?.files[0]?.name}
                    color="warning"
                  />
                )}
              </FileUploadBox>
              <Stack
                direction="row"
                sx={{ mt: 2 }}
                spacing={2}
                justifyContent="center"
              >
                <SoftButton variant="contained" onClick={handleValidate} disabled={validateSimLoading} className={`${validateSimLoading ? 'wait' : ''} info`} endIcon={<RiSearchEyeLine />}>
                  {
                    validateSimLoading ? <ScaleLoader height={12} color='#fff' /> : 'Validate'
                  }
                </SoftButton>
                {validate === true && dataTable === true && (
                  <SoftButton variant="contained" color="success" type="submit" endIcon={<IoCloudUploadOutline />} className={`${uploadSimLoading ? 'wait' : ''} success`}>
                    {uploadSimLoading ? <ScaleLoader height={12} color='#fff' /> : 'upload'}
                  </SoftButton>
                )}
              </Stack>
            </CardContent>
          </form>
        </StyledCardShadow>
      </Collapse>
      <Box>
        {dataTable === true && file?.length > 0 && (
          <StyledCardShadow>
            <CardContent>
              <DataTable
                includeSearchBox={false}
                columns={cols}
                data={responseData}
                styles={{ maxWidth: "50vw" }}
              />
            </CardContent>
          </StyledCardShadow>
        )}
      </Box>
      {/* {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )} */}
      
    </>
  );
}

export default SimUpload;
