import { FormControl, Grid, ListSubheader, MenuItem, Select } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyledDropDown, StyledFormControlLabel, StyledTextField } from '../theme/StyledEle'
import { useForm } from 'react-hook-form'
import { useGetDropdownListMutation, useGetVehicleDropdownListMutation } from '../store/slice/ApiSlice';
import { allGridColumnsFieldsSelector } from '@mui/x-data-grid';
import Dropdown from './Dropdown';






function NewDropdown(props) {
    const { issueShow, region, dealer, customer, fleet, vehicle, searchText, search,regionList, dealerList, customerList, fleetList, vehicleList, onSearch, handleChange} = props;
    const userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData?.resellerId;
    let orgId = userData?.orgId;
    let RoleId = userData?.roleId;
    let userOrgType = userData?.userOrgType;

    const { register, watch, setValue, getValues, handleSubmit, } = useForm({
        mode:'all',
        defaultValues:{
            region:0,
            dealer:0,
            customer:0,
            fleet:0,
            vehicle:0,
        }
    });



  return (
    <>
    {
        issueShow && 
        <Grid item lg={6}>
            <Dropdown label={'Region'} listItems={regionList !== undefined && regionList !== null ? regionList : null} all={false} registerValue={'region'} value={region} handleChange={handleChange} onSearch={onSearch} searchText={searchText}/>
        </Grid>
    }

       { issueShow && 
        <Grid item lg={6}>
        <Dropdown label={'Dealer'} listItems={dealerList !== undefined && dealerList !== null ? dealerList : null } all={false} registerValue={'dealer'} value={dealer} handleChange={handleChange} onSearch={onSearch} searchText={searchText}/>
        </Grid>
       }
        {
             issueShow && 
             <Grid item lg={6}>
            <Dropdown label={'Customer'} listItems={customerList !== undefined && customerList !== null ? customerList : null} all={false} registerValue={'customer'} value={customer} handleChange={handleChange} onSearch={onSearch} searchText={searchText} />
        </Grid>
        }
        {issueShow && 
            <Grid item lg={6}>
            <Dropdown label={'Fleet'} listItems={fleetList !== undefined && fleetList !== null ? fleetList : null} all={false} registerValue={'fleet'} value={fleet} handleChange={handleChange} onSearch={onSearch} searchText={searchText} />
            </Grid>
        }
        {issueShow && 
 <Grid item lg={6}>
 <Dropdown label={'Vehicle'} listItems={vehicleList !== undefined && vehicleList !== null ? vehicleList : null} all={false} registerValue={'vehicle'} search={true} value={vehicle} handleChange={handleChange} onSearch={onSearch} searchText={searchText}/>
</Grid>
        }
       
    </>
  )
}

export default NewDropdown