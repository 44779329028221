import React, { useMemo, useState, useEffect } from "react";
import { DataTable } from "../shared/data_table/NestedTable";
import { useGetCapturedGeoZoneDetailsMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";
import { Box, Dialog, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { StyledIconButton } from "../../theme/StyledEle";
import { TbRoute } from "react-icons/tb";
import FormDialog from "../../Utils/FormDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import GoogleMap from "./RouteViewer";
import RouteViewer from "./RouteViewer";

const SlotTableVehicle = ({ routeId }) => {
  const [
    getCapturedGeozones,
    {
      data: getCapturedGeozoneData,
      isLoading: capturedGeozoneDataLoading,
      isSuccess: capturedGeozoneDataSuccess,
    },
  ] = useGetCapturedGeoZoneDetailsMutation();

  const [responseData, setResponseData] = useState([]);
  const [routeData, setRouteData] = useState(null);
  const [popup, setPopup] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userOrgType = userData.userOrgType;
  const orgId = userData.orgId;

  const viewRoute = async(event, id) => {
    event.preventDefault();
    var request = {};
    request.tripId = id.id;
    request.vehicleId = routeId.vehicleId;
    const responseData = await getCapturedGeozones(request);
    setRouteData(responseData?.data?.data);
    setPopup(true);
  }

  //onClose
  const handleClose = () => {
    setPopup(false);
  }


  const fetchSlotDetails = async () => {
    var request = {};
    request.tripId = routeId?.id;
    request.vehicleId = routeId?.vehicleId;
    const responseData = await getCapturedGeozones(request);
    setResponseData(responseData?.data?.data);
  };

  useEffect(() => {
    fetchSlotDetails();
  }, [routeId]);

     //Export PDF & Excel
     const onChangeXcl = async () => {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          "routeTrip/getCapturedGeoZoneDetailsXLS.xls?tripId=" +
          routeId?.id +
          "&vehicleId=" +
          routeId?.vehicleId +
          "&vehicleNo=" +
          routeId?.vehicleNo +
          "&startDate=" +
          routeId?.startTime +
          "&endDate=" +
          routeId?.endTime +
          "&orgId=" +
          orgId ;
        window.open(url);
  
       //  auditPayload.message = "Reports > View Route > XLS";
       //  auditPayload.action = "View";
       //  auditPayload.featureName = "View Route";
       //  auditPayload.customerName = "All";
       //  auditPayload.fleetName = "All";
       //  auditPayload.result = "Success";
       //  auditPayload.access = "Portal";
       //  auditPayload.severity = "INFO";
       //  auditLog(auditPayload);
  
        fetch(url, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          },
        }) // FETCH BLOB FROM IT
          .then((response) => {
            response.blob();
          })
          .then((blob) => {})
          .catch((err) => {});
      } catch (error) {}
    };

    const columns = useMemo(
      () => [
        {
          Header: "S.No",
          accessor: "sequenceNo",
          width:'50'
        },
        {
          Header: "Geozone",
          accessor: "geoZoneName",
  
        },
        {
          Header: "Category",
          accessor: "zoneCategory",    
        },
        {
          Header: "Radius (m)",
          accessor: "radius",
   
        },
        {
          Header: "Entry Time",
          accessor: "entryTime",
    
        },
        {
          // Header: 
          // (
          //   <Stack direction={'row'} justifyContent={'space-between'}>
          //       <Typography>Exit Time</Typography>
          //       {
          //         userOrgType === 2 && <Tooltip arrow title='View Route'>
          //         <StyledIconButton size="small" onClick={(event) => viewRoute(event, routeId)}><TbRoute /></StyledIconButton>
          //       </Tooltip>
          //       }
                
          //   </Stack>
          // ),
          Header: "Exit Time",
          accessor: "exitTime",
          
        },
        // {
        //   Header: "Entry Odometer (km)",
        //   accessor: "entryOdometer",
        // },
        // {
        //   Header: "Exit Odometer (km)",
        //   accessor: "exitOdometer",
        // },
        {
          Header: "Planned Duration",
          accessor: "plannedDuration",
        },
        {
          Header: "Actual Duration",
          accessor: "duration",
    
        },
        {
          Header: "Duration Difference",
          accessor: "durationDeviation",    
        },
        // {
        //   Header: "Inter Zone Distance Travelled (km)",
        //   accessor: "interZoneDistanceTravelled",
        // },
        // {
        //   Header: "Intra Zone Distance Travelled",
        //   accessor: "intraZoneDistanceTravelled",
        // },
        // {
        //   Header: "Entry Energy (kWh)",
        //   accessor: "entryEnergy",
        // },
        // {
        //   Header: "Exit Energy (kWh)",
        //   accessor: "exitEnergy",
        // },
        // {
        //   Header: "Energy Consumed (kWh)",
        //   accessor: "energyConsumed",
        // },
        // {
        //   Header: "Efficiency (km/kWh)",
        //   accessor: "energyEfficiency",
        // },
        {
          Header: "Entry SOC (%)",
          accessor: "entrySoc",
        },
        {
          Header: "Exit SOC (%)",
          accessor: "exitSoc",
        },
        {
          Header: "Charging Mins",
          accessor: "chargingMins",
        },
        // {
        //   Header: "Maximum Speed (km/h)",
        //   accessor: "maxSpeed",
        // },
        // {
        //   Header: "Average Speed (km/h)",
        //   accessor: "avgSpeed",
        // },
      ],
      []
    );



  return (
    <>
      <DataTable
      columns={columns}
      data={responseData}
      styles={{ maxWidth: "calc(100% - 65% )" }}
      numberOfStickyColumns={1}
      onChangeXcl={onChangeXcl}
      includeExportButton={false}
      />
      <Dialog open={popup} onClose={handleClose} maxWidth={'xl'} fullWidth
        sx={{'& .MuiPaper-root':{height:'100%'}}}
      >
          <Box sx={{height:'100%', display:'flex', flexDirection:'column', }}>
              <Box sx={{ px: 2, py: 1 }}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                    <Typography>View Route</Typography>
                    <IconButton color="error" onClick={handleClose} size="small">
                      <IoMdClose />
                    </IconButton>
                </Stack>
              </Box>
              <Box sx={{height:'100%', flexGrow:1}}> 
                  <RouteViewer routeData={routeData}/>
              </Box>
          </Box>
      </Dialog>
    </>
  );
};

export default SlotTableVehicle;
