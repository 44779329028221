import { Icon, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const StyledTooltip = ({ header, title }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Typography>{header}</Typography>
      <Tooltip title={title} arrow>
        <Icon
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
        </Icon>
      </Tooltip>
    </Stack>
  );
};

export default StyledTooltip;
