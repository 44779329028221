import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import success from '../../assets/images/successs.json'
import error from '../../assets/images/error.json'
import Lottie from 'react-lottie-player'
function Status() {
  return (
    <Box sx={{width:'555px', height:'100vh', margin:'auto', background:'#f4f8ff', position:'relative', px:3, display:'flex', justifyContent:'center', flexDirection:'column'}}>
        <Card sx={{boxShadow:'0px 0px 2px rgba(0,0,0,0.1) !important', background:'transparent'}}>
            <CardContent>
                    <Lottie
                    loop
                    animationData={success}
                    play
                    style={{ width: 150, height: 150, margin:'auto' }}
                    />
                    <Typography sx={{textAlign:'center'}} variant='h5'>Successfully Booked</Typography>
            </CardContent>
        </Card>
    </Box>
  )
}

export default Status