import React, { useState } from 'react'
import { StyledCardShadow, StyledPageLayout, StyledPaperShadow, StyledSidebarListBtn } from '../../theme/StyledEle'
import { Breadcrumb } from '../../components/shared/Breadcrumbs'
import { Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material'
import { RiLayout2Fill } from 'react-icons/ri'
import { useTheme } from '@mui/material'
import { SiteIdentity, Test } from './SettingsWidget'
import { HeaderStyle } from './PageCustomTheme'
import { HiOutlinePaintBrush } from 'react-icons/hi2'
import { HiOutlineViewGridAdd } from 'react-icons/hi'
import { MdOutlineWebAsset } from 'react-icons/md'
import { RiCarWashingLine } from 'react-icons/ri'
import VehicleSettings from './VehicleSettings'
import CreateTheme from './CreateTheme'
function ThemeEditor() {
    const theme = useTheme();

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData?.resellerId;
    let orgId = userData?.orgId;
    let userId = userData?.id;
    let orgName = userData?.orgName;
    let userName = userData?.userName;
    let userOrgType = userData?.userOrgType;

    const settingsTap = [];

    if (userOrgType === 2) {
        settingsTap.push({
            id: 1,
            name: 'Site identity',
            icon: <MdOutlineWebAsset />,
        });
    } else if (userOrgType === 8) {
        settingsTap.push(
            {
                id: 2,
                name: 'Vehicle Upload',
                icon: <RiCarWashingLine />,
            },
            {
                id: 3,
                name: 'Create Theme',
                icon: <HiOutlineViewGridAdd />,
            }
        );
    }

    const [taps, setTaps] = useState(userOrgType === 8 ? 2 : 1);
    const handleTap = (id) => {
        setTaps(id);
    }

    const isSuperAdmin = JSON.parse(localStorage.getItem("userData"))?.orgId;
  return (
    <StyledPageLayout maxWidth='lg' sx={{mx:'auto', position:'relative'}}>
        {/* <Breadcrumb mainDiv='Settings' subDiv='Custom Themes'></Breadcrumb> */}
        <Grid container spacing={2} sx={{position:'relative'}}>
            <Grid item lg={4}>
                <StyledPaperShadow sx={{position:'sticky', top:'20px'}}>
                    <List sx={{width:'100%'}}>
                    <Typography variant='body2' gutterBottom sx={{color:theme.palette.text.disabled}}>Controls</Typography>
                        {
                            settingsTap.map((tap, index) => {
                                    return (
                                        <StyledSidebarListBtn 
                                    className='settings' 
                                    key={index} 
                                    onClick={(e) => handleTap(tap.id)} 
                                    selected={tap.id === taps && true}
                                    >
                                        <ListItemIcon>
                                            {tap.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={tap.name} />
                                    </StyledSidebarListBtn >
                                    )

                            })
                        }
                    </List>
                </StyledPaperShadow>
            </Grid>
            <Grid item lg={8}>
                <StyledPaperShadow sx={{px:3, py:3}}>
                    { taps === 1 && userOrgType === 2 && <SiteIdentity/>}
                    { taps === 2 && (userOrgType === 8) &&<VehicleSettings/>}
                    { taps === 3 && (userOrgType === 8)&&<CreateTheme/>}
                </StyledPaperShadow>
            </Grid>
        </Grid>
    </StyledPageLayout>
  )
}

export default ThemeEditor