import React, { useState, useMemo, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Typography, Link, CardHeader, Tooltip, CardContent, Stack, CardMedia, Chip } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import dealerServices from "../../services/dealerService";
import Test from "./Test.xls";
import { FileUploadBox, StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { FaFileDownload } from "react-icons/fa";
import uploadcsv from '../../assets/images/uploadcsv.png'
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";

function DealerUpload({ onCancel }) {

  const dispatch = useDispatch();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [file, setFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  const [open, setOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  
  const columns = useMemo(() => [
    {
      Header: "GST IN",
      accessor: "gstin",
    },
    {
      Header: "Trade Name",
      accessor: "tradeName",
    },
    {
      Header: "Leagal Name",
      accessor: "legalName",
    },
    {
      Header: "Mobile No",
      accessor: "mobileNo",
    },
   
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Pin Code",
      accessor: "pinCode",
    },
    {
      Header: "City",
      accessor: "city",
    }
    
  ]);

  useEffect(() => {
    auditPayload.message = "Admin > Dealer > Import Dealer Form";
    auditPayload.action = "View";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    const response = await dealerServices.uploadDealerList(payload);

    dispatch(snackService([response?.data === "Success" && Object.keys(response?.data)?.length > 0?"Uploaded Successfully":"Upload failed", Object.keys(response?.data)?.length > 0 ?response?.data:1, true]));
    if (response?.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
    auditPayload.message = "Admin > Dealer > Import Dealer Form > Upload";
    auditPayload.action = "Upload";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };

    const responseData = await dealerServices.validateDealerList(payload);
    if (
      responseData?.data !== "invalidtemplate" &&
      responseData?.data !== "excesscount" &&
      Object.keys(responseData?.data)?.length > 0
    ) {
      setResponseData(responseData?.data.result);
      setDataTable(true);
      if (responseData?.data?.isValidateExcel === true) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    } else {
      setResponseData([]);
    }

    var obj1 = {};

    obj1.dialogText =
      responseData?.data === "excesscount"
        ? "Please upload valid count"
        : responseData?.data === "invalidtemplate"
        ? "Please Upload Valid Template"
        : "Validated Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(
      responseData?.data !== "excesscount" &&
        responseData?.data !== "invalidtemplate"
        ? "Success"
        : "Failed"
    );
    setOpen(true);

    if (responseData?.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }

    auditPayload.message = "Admin > Dealer > Import Dealer Form > Validate";
    auditPayload.action = "Validate";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);    
  };
  
  const FileRef = useRef();
  const handleOnChange = (e) => {
  let fileType = e.target.files[0].name.split('.').pop();
  if(fileType === 'xls'){
    setFile([...file, e.target.files[0]]);
    setIsFilePicked(true);
  }else{
    var obj1 = {};
    obj1.dialogText = 'Please Upload a validate template';
    setDialogContent(obj1);
    setDataStatusConfirm("Failed");
    setOpen(true);
    setTimeout(() => {
        setOpen(false);
      }, 2000);
    
  }

  };

  const handleTemplateClick = () =>{
    auditPayload.message = "Admin > Dealer > Import Dealer Form > Download Template";
    auditPayload.action = "Download";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }

  return (
    <>
      <StyledCardShadow>
        
          <CardHeader
            title={<StyledTableTitle>Upload List</StyledTableTitle>}
            action={<Tooltip title='Click here to download the template for Dealer Creation data upload' arrow placement="left"><Button size="small" variant="contained" startIcon={<FaFileDownload/>} component={Link} href={Test} onClick={handleTemplateClick}>Download Templete</Button></Tooltip>}           
          />

          <form onSubmit={handleSubmit}>
          <CardContent>
              <FileUploadBox>
                <input
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleOnChange}
                  className="fileupload"
                  ref={FileRef}
                />
                <CardMedia
                  component='img'
                  src={uploadcsv}
                  sx={{ width: "60px", height: "60px", my: 2 }}
                  
                />
                <Typography sx={{ml:2}} variant="h6" color='primary'>Select a XLS file to upload</Typography>

                  {isFilePicked && <Chip sx={{ml:1}} label={FileRef.current.files[0].name} color="warning"/>}
              </FileUploadBox>
              <Stack direction='row' sx={{mt:2}} spacing={2} justifyContent='center'>
                  <Button variant="outlined" onClick={handleValidate}> Validate </Button>
                  {validate === true && dataTable === true && (<Button variant="contained" color="success" type="submit"> Upload </Button> )}
              </Stack>
          </CardContent>
          </form>
      </StyledCardShadow>
      <Box>
      {dataTable === true && file?.length > 0 && (
          <StyledCardShadow>
            <CardContent>
              <DataTable
                includeSearchBox={false}
                includeExportButton={false}
                includePagination={false}
                columns={columns}
                data={responseData}
                styles={{ maxWidth: "50vw" }}
              />
            </CardContent>
          </StyledCardShadow>
        )}
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
}

export default DealerUpload;
