import { Box, Collapse, Drawer, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SingleDropdown from '../NewDropdown/SingleDropdown'
import { SoftButton, StyledFiterButton, StyledIconButton, StyledPaperShadow } from '../../theme/StyledEle'
import { HiOutlineFilter } from 'react-icons/hi'
import { FiPlus } from 'react-icons/fi'
import TicketList from './NewTicketBase/TicketList'
import { useCreateTicketMutation, useTicketListMutation, useUpdateTikectMutation, useTicketFilterListMutation } from '../../store/slice/Helpdesk/HelpDeskSlice'
import { useAuditLogMutation } from '../../store/slice/ApiSlice'
import DrawerModel from '../../Utils/DrawerModel'
import CreateTicket from './NewTicketBase/CreateTicket'
import { useLocation, useNavigate } from 'react-router-dom'
import FormDialog from '../../Utils/FormDialog'
import EditTicket from './NewTicketBase/EditTicket'
import SimplePaper from './Filter'
import { async } from 'q'
import AntdDateRange from '../../Utils/AntdDateRange'
import CoreUIDatePicker from '../../Utils/CoreUIDatePicker'
import BootsrapDateRange from '../../Utils/BootsrapDateRange'

function NewViewTicket() {
  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;
  let roleId = userData.roleId;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [ticketList, setTicketList] = useState([]);
  const [payload, setPayload] = useState(null);
  const [filterReset, setFilterReset] = useState(false);
  //Slice Mution
  const [ getTicketList,  {data:TicketListData, isLoading:TicketListLoading, isSuccess:TicketListSuccess}] = useTicketFilterListMutation();
  const [updateTickets, {data:updateTicketData, isLoading:updateTicketLoading, isSuccess:updateTicketSuccess}] = useUpdateTikectMutation();
  const [ createTicketNew, {data:createTicketData, isLoading:createLoading,  isSuccess:createTicketSuccess} ] = useCreateTicketMutation();

  let ticketListPayload = {
      'orgId':orgId,
      "orgType": orgType,
  }

  let statusArry = [];
  let assignArry = [];
  let categoryArry = [];
  let serviceArry = [];
  let typeArry = [];
  let customerArry = [];

  const GetTicket = async (status, assignee) => {
    setNewPage(0);
    setRowsPerPage(25);
    ticketListPayload.page = 0;
    ticketListPayload.size = 25;
    ticketListPayload.statusList = statusArry;
    ticketListPayload.categoryList = categoryArry;
    ticketListPayload.severityList = serviceArry;
    ticketListPayload.typeList = typeArry;
    ticketListPayload.customerList = customerArry;
    ticketListPayload.assignedList = assignArry;
    ticketListPayload.searchText = "";

    //added
    ticketListPayload.resellerId = resellerId;
    ticketListPayload.userId = userId;
    ticketListPayload.roleId = roleId;

    setPayload(ticketListPayload);
    const GetTicketResponse = await getTicketList(ticketListPayload);
    if (
      GetTicketResponse.data !== undefined &&
      GetTicketResponse.data !== null
    ) {
      if (GetTicketResponse.data.content !== null) {
        setTicketList(GetTicketResponse.data.content);
        setTotalElements(GetTicketResponse.data.totalElements);
        setTotalPages(GetTicketResponse.data.totalPages);
      } else {
        setTicketList([]);
      }
    } else {
      setTicketList([]);
    }

  };

  //Onload Get Ticket List
  useEffect(() => {
    GetTicket();
    auditPayload.message = "Help Desk > ViewTicket > "+bugType;
    auditPayload.action = "View";
    auditPayload.featureName = "Help Desk";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload); 
  }, []);

  

  const [ticketId, setTicketId] = useState(null);

  //OnEdit
  const editTicket = (id) => {
    navigate("#edit");
    setTicketId(id);

    auditPayload.message = "Help Desk > ViewTicket > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Help Desk";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  const [bugType, setBugType] = useState("All Bugs");
  const [ticketType, setTicketType] = useState({
    content: [
      {
        key: "All Bugs",
        value: "All Bugs",
      },
      {
        key: "All Open",
        value: "All Open",
      },
      {
        key: "All Closed",
        value: "All Closed",
      },
      {
        key: "My Open",
        value: "My Open",
      },
      {
        key: "My Closed",
        value: "My Closed",
      },
      {
        key: "My Assigned",
        value: "My Assigned",
      },
    ],
  });

  const getBugType = (bugValue) => {
    setBugType(bugValue);
    dropdownFilter(bugValue);

    auditPayload.message = "Help Desk > ViewTicket > "+bugValue;
    auditPayload.action = "Submit";
    auditPayload.featureName = "Help Desk";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload); 
  };

  const dropdownFilter = (type) => {
    if (type === "All Open") {
      statusArry.push("Open", "Reopen", "In Progress", "Clarification");
    } else if (type === "All Bugs") {
      statusArry.push();
    } else if (type === "All Closed") {
      statusArry.push("Closed");
    } else if (type === "My Open") {
      statusArry.push("Open", "Reopen");
      assignArry.push(userId);
    } else if (type === "My Closed") {
      statusArry.push("Closed");
      assignArry.push(userId);
    } else if (type === "My Assigned") {
      statusArry.push("Open", "In Progress", "Reopen");
      assignArry.push(userId);
    }
    setFilterReset(!filterReset);
    GetTicket();
  };

  //Filter Box
  const [filterOpen, setFilterOpen] = React.useState(false);
  const getSearchFilter = (data) => {
    serviceArry = [...data.serevityList];
    assignArry = [...data.AssignList];
    categoryArry = [...data.categoryList];
    typeArry = [...data.typeList];
    customerArry = [...data.customerList];
    statusArry = [...data.statusList];
    GetTicket();
  };

  const pageChange = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;

    const GetTicketResponse = await getTicketList(payload);
    if (
      GetTicketResponse.data !== undefined &&
      GetTicketResponse.data !== null
    ) {
      if (GetTicketResponse.data.content !== null) {
        setTicketList(GetTicketResponse.data.content);
        setTotalElements(GetTicketResponse.data.totalElements);
        setTotalPages(GetTicketResponse.data.totalPages);
      } else {
        setTicketList([]);
      }
    } else {
      setTicketList([]);
    }

  };


  const GetTicketViaPayload = async (payload) => {
    const GetTicketResponse = await getTicketList(payload);
    if(GetTicketResponse.data !== undefined && GetTicketResponse.data !== null){
        if(GetTicketResponse.data.content !== null){
          setTicketList(GetTicketResponse.data.content);
          setTotalElements(GetTicketResponse.data.totalElements);
          setTotalPages(GetTicketResponse.data.totalPages);
        }else{
          setTicketList([]);
        }
    }else{
      setTicketList([]);
    }
  }


  //Search ticket
  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetTicketViaPayload(payload);
    } else if (payload.searchText.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetTicketViaPayload(payload);
    }
  };


  //Update Ticket
  useEffect(() => {
    if(updateTicketSuccess || createTicketSuccess){
      if(updateTicketData?.resultCode === 0 || createTicketData?.resultCode === 0 ){
        payload.page = 0;
        payload.rowsPerPage = 25;
        setNewPage(0);
        setRowsPerPage(25);
        GetTicketViaPayload(payload);
      }
    }
  },[updateTicketSuccess, createTicketSuccess])


  //XLS
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "helpdesk/getServiceRequestXLS?statusList=" +
        payload.statusList +
        "&categoryList=" +
        payload.categoryList +
        "&severityList=" +
        payload.severityList +
        "&typeList=" +
        payload.typeList +
        "&customerList=" +
        payload.customerList +
        "&assignedList=" +
        payload.assignedList + 
        "&orgId=" + orgId + 
        "&orgType=" + orgType;
      window.open(url);

    auditPayload.message = "Help Desk > ViewTicket > Export >Excel";
    auditPayload.action = "Download";
    auditPayload.featureName = "Help Desk";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };



  return (
    <Box>
        <StyledPaperShadow>
          <Stack direction={'row'} spacing={2} alignItems={'end'} justifyContent={'space-between'} sx={{width:'100%'}}>
              <SingleDropdown 
                  label={'Status'}
                  isSearch={false}
                  listItems={ticketType}
                  handleChange={getBugType}
                  showAll={false}
                  value={bugType}
              />
              {/* <AntdDateRange /> */}
              {/* <CoreUIDatePicker /> */}
              {/* <BootsrapDateRange /> */}
              <Box>
                  <Stack direction={'row'} spacing={1}>
                      <Tooltip title={'Filter'} arrow>
                        <StyledIconButton color='info' className='info-soft' size='small' onClick={() => setFilterOpen(!filterOpen)}>
                            <HiOutlineFilter />
                        </StyledIconButton>
                      </Tooltip>
                      <SoftButton variant='contained' color='success' size='small' className='success-soft' startIcon={<FiPlus />} onClick={() => navigate('#create')}>
                          Create Ticket
                      </SoftButton>
                  </Stack>
              </Box>
          </Stack>
        </StyledPaperShadow>

        <Box sx={{mt:2}}>
          <Collapse in={filterOpen}>
              <SimplePaper searchFilter={getSearchFilter} filterReset={filterReset}/>
          </Collapse>
        </Box>
       

        {/* Grid Start */}
        <StyledPaperShadow sx={{mt:2}}>
            <TicketList isLoading={TicketListLoading} data={ticketList} editTicket={editTicket} totalElements={totalElements}
              totalPages={totalPages} newPage={newPage} rowsPerPage={rowsPerPage} pageChange={pageChange}  handleSearch={handleSearch} onChangeXcl={onChangeXcl}/>
        </StyledPaperShadow>
        
        {/* Create Ticket */}
        <DrawerModel position={'right'} width={'35vw'} title={'Create Ticket'}>
              <CreateTicket createTicketNew={createTicketNew} createLoading={createLoading}/>
        </DrawerModel>
        
        {/* Edit Ticket */}
        <FormDialog title={'Edit Ticket'} maxWidth={'90%'} size={false}>
            <EditTicket ticketId={ticketId} updateTickets={updateTickets} updateTicketLoading={updateTicketLoading}/>
        </FormDialog>
    </Box>
  );
}

export default NewViewTicket;
