import React, {  useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Link,
  Fab,
  Icon,
  alpha,
  useTheme,
  Button
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import { MdOutlineCheck, MdOutlineEmail, MdOutlineVerified } from "react-icons/md";
import { SoftButton, StyledFormButtonsRow } from "../theme/StyledEle";
import { AiOutlineMobile } from "react-icons/ai";
import { color } from "@mui/system";
import {useAuditLogMutation} from "../store/slice/ApiSlice";



export default function VerificationDialog(props) {

  const navigate = useNavigate();
  const theme = useTheme();

    let userData = JSON.parse(localStorage.getItem("userData"));
    let emailVerificationStatus = userData.emailVerificationStatus;
    let mobileVerificationStatus = userData.mobileVerificationStatus;
    let mobileNumber = userData.mobile;
    let email = userData.email;
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let roleId = userData.roleId;
    let userOrgType = userData.userOrgType;

    const [auditLog] = useAuditLogMutation();
    let auditPayload = {
     //Audit Common Payload
     orgId: orgId,
     userId: userId, 
     orgName: orgName, 
     userName: userName,
  }; 

   
 
  const [open, setOpen] = useState(true);

  const handleCloseDialog = () => {
    setOpen(false);
    localStorage.setItem("skip", true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("skip", true);
  };

  const handleLink = () =>{
    navigate('/profileSettings');
    setOpen(false);
    localStorage.setItem("skip", true);

    auditPayload.message = "Verification Dialog > Mobile/Email";
    auditPayload.action = "Select";
    auditPayload.featureName = "Verification Dialog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);


  }

  


  return (
    <>
    {(emailVerificationStatus === 0 || mobileVerificationStatus === 0) && (

    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="md"
      sx={{mt:-50}}

    >
        {/* <DialogTitle sx={{ m: 0, p: 1 }}>
    </DialogTitle> */}
      <Box >
        <DialogContent sx={{background:theme.palette.grey[100]}}>
          <Icon 
          sx={{ display:'flex', 
          transition:'.5s',
          width:'55px',
          height:'55px',
          margin:'auto',
          alignItems:'center',
          justifyContent:'center',
          background:alpha(theme.palette.secondary.main, .1),
          color:alpha(theme.palette.secondary.main, 1),
          fontSize:'32px',
          borderRadius:'50%',
          }}  >
            <MdOutlineVerified/>
          </Icon>
          <Typography variant="h6" sx={{textAlign:'center'}}>Verification Alert!</Typography>
        {/* {emailVerificationStatus === 0 && (
        <Typography
         color="primary"
         gutterBottom
         sx={{ textAlign: "center" }}
         >
           <Link   
            onClick={handleLink}
            >
              Verify your email address({email}) 
              <EditIcon style={{ fontSize: "15px" , marginLeft:'10px'}} />
              </Link>
        </Typography>

        )}
        {mobileVerificationStatus === 0 && (
          <Typography
            color="primary"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            <Link   
            onClick={handleLink}
            >
              Verify your mobile number({mobileNumber})
              <EditIcon style={{ fontSize: "15px" ,  marginLeft:'10px' }} />
              </Link>
          </Typography>
        )} */}

<StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
          <SoftButton
            size='small'
            color="success"
            variant="contained"
            className={emailVerificationStatus === 0 ? 'success'  :''}
            onClick={handleLink}
            disabled={emailVerificationStatus ===0?false:true}
            startIcon={emailVerificationStatus ===0? <MdOutlineEmail /> : <MdOutlineCheck /> }
          >
            verify email
          </SoftButton>


          <SoftButton
            size='small'
            className={mobileVerificationStatus === 0 ? 'info'  :''}
            color="info"
            onClick={handleLink}
            startIcon={mobileVerificationStatus ===0? <AiOutlineMobile /> : <MdOutlineCheck /> }
            disabled={mobileVerificationStatus ===0?false:true}
          >
            verify Mobile
          </SoftButton>
        </StyledFormButtonsRow>
        <Button variant="outlined" size="small" sx={{mt:1, border:`.5px solid ${theme.palette.divider}`, color:theme.palette.grey[900]}} fullWidth onClick={handleCloseDialog}>Remind me Later</Button>
        </DialogContent>
      </Box>
    </Dialog>
    )}
    </>
  );
}
