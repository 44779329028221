import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import InnerTab from '../../Utils/InnerTab';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

function DashboardPopUp({data}) {

  let [ChargeDetailsurl, setChargeDetailsurl] = useState(null);
  let [TripDetailsurl, setTripDetailsurl] = useState(null);
  let location = useLocation();


    useEffect(() => {
        var vehicleno = "";
        let startTime = "";
        let endTime  = "";
        let tripEndTime = "";
        let tripStartTime = "";

        startTime = moment(data.startTime).format("YYYY-DD-MM HH:mm:ss");
        var starttimets = moment(startTime).valueOf();
        endTime = moment(data.endTime).format("YYYY-DD-MM HH:mm:ss");
        var endtimets = moment(endTime).valueOf();


        tripStartTime = moment(data.tripStartTime).format("YYYY-DD-MM HH:mm:ss");
        var tripStartTimets = moment(tripStartTime).valueOf();
        tripEndTime = moment(data.tripEndTime).format("YYYY-DD-MM HH:mm:ss");
        var tripEndTimets = moment(tripEndTime).valueOf();
        if (data.vehicleno != null) {
            vehicleno = data.vehicleno;
        }

        setChargeDetailsurl (
            "https://analyticsevnt.nesh.live:3000/d/adhl79lheyxhca/charging-analytics?orgId=1&from="+
            starttimets+
            "&to="+
            endtimets+
            "&var-vehicle_no=" +
            vehicleno +
            "&var-Start_date="+
            startTime +
            "&var-End_date="+
            endTime +
            "&kiosk"
        );

        setTripDetailsurl (
            "https://analyticsevnt.nesh.live:3000/d/adhl79lheyxhcb/trip-analytics?orgId=1&from="+
            tripStartTimets+
            "&to="+
            tripEndTimets+
            "&var-vehicle_no=" +
            vehicleno +
            "&var-Start_date="+
            tripStartTime +
            "&var-End_date="+
            tripEndTime +
            "&kiosk"
        );
    },[]);

    //Tabs
    const tabList = [
        {
            id:1,
            name: location.pathname === "/ChargingReport"?'Battery Details':'Trip Details',
            icon:'',
            show:true,
        }
    ]
    const [tabValue, setTabValue] = useState(1);
    const getTabValue = (value) => {
        setTabValue(value);
    }

  return (
        <Box sx={{height:'100%', maxHeight:'75vh', overflowY:'auto'}}>
        <Box>
            <InnerTab tabList={tabList} getTabValue={getTabValue}/>
        </Box>
               <Box sx={{ height: "100vh" }}>
               <iframe
                 id="Contentframe"
                 className="iframe"
                 width="100%"
                 height="98%"
                 src={location.pathname == "/ChargingReport"? ChargeDetailsurl: TripDetailsurl}
                 name="Right"
               ></iframe>
             </Box>

            
        </Box>
  )
}

export default DashboardPopUp;