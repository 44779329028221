import { Box, Paper, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import CreateDealerForm from "./CreateDealer";
import DealerUpload from "./DealerImport";
import ListDealer from "./DealerLog";
import ListDealerProcessing from "./DealerProceesing";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { StyledPageLayout } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import FormDialog from "../../Utils/FormDialog";
import { useLocation, useNavigate } from "react-router-dom";
import ViewDealer from "./ViewDealer";
import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";

const Dealer = () => {
  const [dealerToEdit, setDealerToEdit] = useState(null);
  const [dealerData, setDealerData] = useState(null);
  const [tabValue, setTabValue] = useState(3);
  const [tabName, setTabName] = useState("Create Dealer Form");
  const navigate = useNavigate();
  const location = useLocation();


useEffect(() =>{
  navigate('#processing');
},[])


  const handleTabChange = (event, newValue) => {
    setTabName("Create Dealer Form");
    setDealerToEdit(null);
    setTabValue(newValue);
    if(newValue === 3){
      navigate('#processing');
    }else{
      navigate(location.pathname);
    }
  };

  const handleDealerEdit = (dealerEditedId) => {
    setTabValue(1);
    setDealerToEdit(dealerEditedId);
    setTabName("Edit Dealer Form");
  };

  const HandleViewDealer = (dealerData, role) => {
    navigate('#view');
    setDealerData(dealerData);
  }

  const handleCancel = () => {
    setTabValue(3);
    setTabName("Create Dealer Form");
  };

  const TABS = [
    {
      label: "Import Dealer Form",
      children: <DealerUpload onCancel={handleCancel} />,
    },
    {
      label: tabName,
      children: (
        <CreateDealerForm dealerToEdit={dealerToEdit} onCancel={handleCancel} dealerToView={dealerData}/>
      ),
    },
    {
      label: "Dealer KYC Request Log",
      children: <ListDealer onEditDealer={handleDealerEdit} />,
    },
    {
      label: "Dealer KYC Processing",
      children: <ListDealerProcessing onEditDealer={handleDealerEdit} onViewDealer={HandleViewDealer}/>,
    },
   
  ];

  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);


  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="Dealer" includePopup={true}></Breadcrumb>
      <MouseOverPopover text='Only valid KYC feeds are shown in Dealer KYC Processing
          page.' title={"Dealer KYC Log"} />
        <StyledTabs
          tabs={TABS}
          handleChange={handleTabChange}
          value={tabValue}
        />
        <FormDialog title={`View Dealer - ${dealerData?.legalName}`} size={'md'}>
            <ViewDealer data={dealerData}/>
        </FormDialog>
        {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </StyledPageLayout>
  );
};

export default Dealer;
