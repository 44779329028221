import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MouseOverPopover from "../shared/PopOver";
import exporticon from '../../assets/images/exporticon.png';

const text =
  "Trip Range Report gets the travel details of Two-Wheeler info in the range of 100 to 0 % battery level based on date selection.";

const TripRangeReport = () => {
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    await fetchRangeDetails();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      {
        Header: "Time",

        columns: [
          {
            Header: "Start",
            accessor: "startDate",
          },
          {
            Header: "End",
            accessor: "endDate",
          },
        ],
      },
      {
        Header: "Location",

        columns: [
          {
            Header: "Start",
            accessor: "startLoc",
          },
          {
            Header: "End",
            accessor: "endLoc",
          },
        ],
      },
      {
        Header: "Battery",

        columns: [
          {
            Header: "Start",
            accessor: "startBattery",
          },
          {
            Header: "End",
            accessor: "endBattery",
          },
        ],
      },
      {
        Header: "Distance Traveled",
        accessor: "distTravelled",
      },
      {
        Header: "Avg Distance Traveled",
        accessor: "avgDistTravelled",
      },
      {
        Header: "Avg Speed",
        accessor: "avgSpeed",
      },
      {
        Header: "Riding Mins",
        accessor: "movmins",
      },
      {
        Header: "Idle Mins",
        accessor: "idlemins",
      },
      {
        Header: "Stop Mins",
        accessor: "stopmins",
      },
    ],
    []
  );
  const fetchRangeDetails = async () => {
    var request = {};

    request.orgId = 0;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-09-26 00:00:00";
    request.endDate = "2022-09-26 23:59:59";
    request.sortBy = "desc";
    request.page = newPage;
    request.size = rowsPerPage;

    const responseData = (await ReportServices.tripRangeReport(request)).data;
    setResponseData(responseData.data.content);
  };
  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    var request = {};

    request.orgId = 0;
    request.page = newPage;
    request.size = rowsPerPage;

    const responseData = (await ReportServices.tripRangeReport(request)).data
      .data;
    setResponseData([]);
    setResponseData(responseData.content);
    setTotalElements(5);
    setTotalPages(0);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };


  const onChangeXcl=async ()=>{
   
    var request = {};
    request.orgId = 1;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-09-26 00:00:00";
    request.endDate = "2022-09-26 23:59:59";
    request.sortBy = "desc";

    request.page = 0;
    request.size = 25;
   
  
    try {
      var url=process.env.REACT_APP_API_BASE_URL+"RangeTripReport/rangeTripXLS.xls?orgId="+request.orgId+"&regionId="+request.regionId+"&reselId="+request.reselId+"&customerId="+request.customerId+"&vehId="+request.vehId+"&startDate="+request.startDate+"&endDate="+request.endDate+"&sortBy="+request.sortBy+"&page="+request.page+"&size="+request.size+" ";

     //console.log("url:::"+url);
     
     window.open(url); 
         
    } catch (error) {}  
  }
  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb mainDiv="Reports" subDiv="Trip Range Report" includePopup={true}></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>
          <MouseOverPopover text={text} title={"Trip Range Report"} />
          <Typography sx={{ fontSize: "12px" }}>
            <b>Note</b>:Trip Range Report gets the travel details of Two-Wheeler
            info in the range of 100 to 0 % battery level based on date
            selection.
          </Typography>

          <Divider sx={{ borderBottomWidth: 2, mt: 1 }} />
          <Paper sx={{ backgroundColor: "#eeeeee", marginTop: 2 }}>
            <ReportDropDown />
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button>
          </Paper>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
            }}
          >
              <img alt="excel" style={{marginLeft: 850,width: 25, marginTop: 10, marginBottom: 10}} onClick={onChangeXcl} title="Export Excel"  src={exporticon} /> 

            <DataTable
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={callbackChangePage}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default TripRangeReport;
