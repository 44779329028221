import React from 'react'
import { FormIconStack, StyledFilterFormControl, StyledFormControlLabel, StyledInputBase } from '../../theme/StyledEle'
import { Customer, LotNumber } from '../../constants/DualIcons';
import { InputBase, SvgIcon, useTheme } from '@mui/material';

function SingleInput(props) {
    const { label, listItems, handleChange, value, isSearch, showAll, customAllValue ='-1' } = props;
    const theme = useTheme();
    const handleValue = (event) => {
        handleChange(event.target.value);
    }
  return (
    <StyledFilterFormControl>
        <StyledFormControlLabel>{label}</StyledFormControlLabel>
        <FormIconStack direction={'row'} spacing={.5} alignItems={'center'}>
        <SvgIcon sx={{fontSize:'20px', color:theme.palette.mode === 'light' ?  theme.palette.secondary.main : theme.palette.grey[300]}}>
          {
            label === "Lot Number" && <LotNumber />
          } 
        </SvgIcon>
        <StyledInputBase 
            placeholder={`Enter ${label}`}
            onChange={handleValue}
        />
        </FormIconStack>
    </StyledFilterFormControl>
  )
}

export default SingleInput