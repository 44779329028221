import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardMedia, Chip, Collapse, Divider, Grid, Icon, Paper, Stack, Tab, Tabs, Tooltip, Typography, alpha, styled, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { SoftButton, StyledFormControlLabel, StyledIconButton } from '../../theme/StyledEle';
import { GoDotFill } from 'react-icons/go';
import { BsArrowDownCircle } from 'react-icons/bs';
import { GrMapLocation } from 'react-icons/gr';
import { FiBell, FiGitPullRequest } from 'react-icons/fi';
import { TbMap2 } from 'react-icons/tb';
import moment from 'moment';
import { MdOutlineAddRoad } from "react-icons/md";

function TimelineNewItem(props) {
    const { item, length, index } = props;
    let theme = useTheme();
    const StyledAccord = styled(Box)(({theme}) => ({
        boxShadow:'none',
        '& .MuiAccordionSummary-root':{
            minHeight:'50px',
            alignItems:'baseline',
            background:alpha(theme.palette.info.main, .1),
            borderTop:`.5px solid ${theme.palette.grey[200]}`,
            marginBottom:'15px',
            borderRadius:'10px',
            '& .MuiAccordionSummary-content':{
                margin:'0px !important',
                alignItems:'baseline',
                flexDirection:'column'
            },
            '&.between_btn':{
                position:'relative',
                background:'#fff',
                minHeight:'auto',
                borderTop:`0px solid ${theme.palette.grey[200]}`,
                alignItems:'center',
                margin:'5px 0px 5px 0px',
                background:alpha(theme.palette.info.light, .1),
                '& .MuiAccordionSummary-content':{
                    margin:'0px !important',
                    alignItems:'center',
                    flexDirection:'column',
                    padding:'5px',
                },
                '& .between_icon':{
                    position:'absolute',
                    display:'none',
                    left:'-15px',
                    background:theme.palette.success.main,
                    padding:'5px',
                    borderRadius:'50px',
                    color:theme.palette.primary.contrastText
                }
            },
          
            '&.with_in':{
                    position:'relative',
                '&:before':{
                    position:'absolute',
                    top:'50%',
                    transform:'translateY(-40%)',
                    left:'-24px',
                    width:'15px',
                    height:'15px',
                    content:'""',
                    background:theme.palette.info.main,
                    borderRadius:'25px'
                }
            }
        },
        '& .between_add_btn':{
            position:'absolute',
            top:'-15px',
            left:'-13px',
            background:theme.palette.success.main,
            color:theme.palette.success.contrastText,
            fontSize:'14px'
        }
    }))

    const StyledAccordDetails = styled(Collapse)(({theme}) => ({
        '& .MuiCard-root':{
            boxShadow:'0px 2px 5px rgba(0,0,0,0)',
            border:`.5px solid ${theme.palette.divider}`
        },
        '& .MuiGrid-root':{
            margin:'0px !important',
            width:'100%'
        }
    }))

    const [open, setOpen] = useState(false);
    const [currentTab, setCurretnTab] = useState(1);
    const [trailsOpen, setTrailsOpen] = useState(false);
    const [betweenOpen, setBetweenOpen] = useState(true);
    let geoLatlng = item?.geoZoneLatLong.split(',');

    // Changle Tab
    const changeTab = (tabValue) => {
        setCurretnTab(tabValue);
    }


    //Trias List
    const TrailsItem = (props) => {
        const { trails = null } = props;
        return (
            trails !== null && trails !== undefined && trails.length > 0 ? trails.map((item, index) => {
                return (
                    <Stack direction={'row'} alignItems={'center'} sx={{my:.8}}>
                        <Icon color={item.lastGpsStatus === 0 ? 'error' : 'success'}><GoDotFill /></Icon>
                        <Paper 
                        sx={{width:'200px', 
                        p:1, 
                        filter:'drop-shadow(0px 0px 3px rgba(0,0,0,.1))',
                        ml:2,
                        position:'relative',
                        zIndex:2,
                        '&::before':{
                            width:'10px',
                            height:'10px',
                            background:theme.palette.background.paper,
                            position:'absolute',
                            left:'-8px',
                            top:'50%',
                            content:"''",
                            transform:'rotate(45deg) translateY(-50%)',
                            zIndex:1,
                        }
                        }} elevation={0}>
                            <Stack direction={'column'}>
                                <Typography variant='body2'>{item.lastGpsStatus === 0 ? 'Stop' : 'Moving'}</Typography>
                                <Typography variant='caption'>{item.startTime}</Typography>
                            </Stack>
                        </Paper>
                    </Stack>
                )
            }) : 
            <Paper
                sx={{
                    p:1,
                    filter:'drop-shadow(0px 0px 3px rgba(0,0,0,.1))',
                    ml:'35px',
                    width:'fit-content',
                    mt:1
                }}
                elevation={0}
            >
                <Typography>No Data found</Typography>
            </Paper>
        )
    }

    //Zone Location
    const ZoneLocation = ({lat, lng}) => {
        let mapUrl = `https://maps.googleapis.com/maps/api/staticmap?size=300x200&zoom=6&markers=label:G|color:red|${lat},${lng}&key=AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA`
        return(
            <Box sx={{height:'250px', overflow:'hidden', mt:1}}>
                <CardMedia component={'img'} src={mapUrl} sx={{width:'50%'}}/>
            </Box>
        )
    }




    
    // Zone Bettween Summary
    const [ViewSummary, setViewSummary] = useState(null);

    let expandBetweenSummary = {
        width:'100% !important',
        height:'fit-content !mportant',
        //position:'absolute !important',
        zIndex:ViewSummary && 9999
    }
    
    const ZoneBetweenHandle = (index) => {
        if(ViewSummary === index){
            setViewSummary(null)
        }else{
            setViewSummary(index)
        }
    }

        //Grid Item
        const GridItem = ({label, value}) => {
            return (
                <Box sx={{marginLeft:ViewSummary !== null && '10px'}}>
                    <Stack>
                        <StyledFormControlLabel sx={{textAlign: ViewSummary !== null && 'left'}}>{label}</StyledFormControlLabel>
                        <Typography sx={{textAlign: ViewSummary !== null && 'left'}} >{value}</Typography>
                    </Stack>
                </Box>
            )
        }
    

    const ZoneBetween = ({betweenData = null, index}) => {
        console.log('betweenData', betweenData);
        return(
            <Grid container spacing={1}>
            <Grid item lg={2}>
                <GridItem label={'Start Time'} value={item.startTimeZoneBetween !== null ? moment(item.startTimeZoneBetween).format('HH:mm:ss') : '-'}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'End Time'} value={item.endTimeZoneBetween !== null ? moment(item.endTimeZoneBetween).format('HH:mm:ss')  : '-'}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Start Odometer'} value={item.startCanOdometerZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'End Odometer'} value={item.endCanOdometerZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Start SOC(%)'} value={item.startSOCZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'End SOC(%)'} value={item.endSOCZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Distance'} value={item.canDistanceTraveledZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Distance Tavelled'} value={item.canDistanceTraveledZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Max Speed'} value={item.maxCanSpeedZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Avg Speed'} value={item.avgCanSpeedZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Moving'} value={item.moveMinsZoneBetween}/>
            </Grid>
            <Grid item lg={2}>
                <GridItem label={'Stop'} value={item.stopMinsZoneBetween}/>
            </Grid>
        </Grid> 
        )
    }

    const ZoneWithIn = ({item}) => {
        return(
                            <Grid container spacing={1}>
                                <Grid item lg={2}>
                                    <GridItem label={'Entry Time'} value={item.entryTime !== null ? item.entryTime : '-'}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Exit Time'} value={item.exitTime !== null ? item.exitTime : '-'}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Radius'} value={item.radius}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Zone Catagory'} value={item.zoneCategory}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Start SOC(%)'} value={item.startSOCZoneIn}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'End SOC(%)'} value={item.endSOCZoneIn}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Distance'} value={item.mapDistance}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Distance Tavelled'} value={item.canDistanceTraveledZoneIn}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Max Speed'} value={item.maxCanSpeedZoneIn}/>
                                </Grid>
                                <Grid item lg={2}>
                                    <GridItem label={'Avg Speed'} value={item.avgCanSpeedZoneIn}/>
                                </Grid>
                            </Grid> 
        )
    }


  return (
    <TimelineItem sx={{minHeight:'50px'}}>
            <TimelineOppositeContent sx={{flex:.5, transform:'translateY(-32px)'}}>
                {
                     0 !== index && <Collapse in={betweenOpen}>
                     <Box sx={{py:1}}>
                          <Typography color={'success'} sx={{p:.5, background:theme.palette.success.main, width:'fit-content', borderRadius:'10px 10px 0px 0px', color:theme.palette.success.contrastText}}>{item.zoneNameInBetween}</Typography>  
                          <Box sx={{border:`.5px solid ${theme.palette.success.main}`, p:1, background:alpha(theme.palette.success.main, .08), borderRadius:'0px 10px 10px 10px'}}>
                              <ZoneBetween />
                          </Box>
                          
                     </Box>
                  </Collapse>
                }
                
            </TimelineOppositeContent>
        <TimelineSeparator>
            {/* <TimelineDot sx={{margin:'0px'}} color="info"/> */}
            {
                length -1  !== index && <TimelineConnector sx={{background:alpha(theme.palette.info.main, .2)}}/>
            }
        </TimelineSeparator>
        <TimelineContent sx={{py:0, position:'relative'}}>
                <StyledAccord>
                    {
                        0 !== index &&
                        <Box>
                            <Tooltip title={item.zoneNameInBetween} arrow placement='right'>
                            <StyledIconButton className='between_add_btn' size='small'  onClick={() => setBetweenOpen(!betweenOpen)}>
                                <FiGitPullRequest />
                            </StyledIconButton>
                            </Tooltip>
                            {/* <Chip label={item.zoneNameInBetween} color='info'/> */}
                        {/* <AccordionSummary className='between_btn' onClick={() => setBetweenOpen(!betweenOpen)}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                                <Box>
                                    <Typography variant='body1' color={'info'}>{item.zoneNameInBetween}</Typography>
                                </Box>
                                <Box className='between_icon'>
                                    <Icon sx={{display:'flex', fontSize:'14px', transform:`${open ? 'rotate(180deg)': 'rotate(0deg)'}`, transition:'.8s all'}}>
                                        <BsArrowDownCircle />
                                    </Icon>
                                </Box>
                            </Stack>
                        </AccordionSummary> */}
                        
                     </Box>
                    }
                    <AccordionSummary className='with_in' onClick={() => setOpen(!open)} sx={{background:open ? `${alpha(theme.palette.grey[100], 1)} !important` : 'transparent'}}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                            <Box>
                                <Typography variant='body1'>{item.geoZoneName}</Typography>
                                <Typography variant='caption'>{item.startTimeZoneIn}</Typography>
                            </Box>
                            <Box>
                                <Icon sx={{display:'flex', fontSize:'14px', transform:`${open ? 'rotate(180deg)': 'rotate(0deg)'}`, transition:'.8s all'}}>
                                    <BsArrowDownCircle />
                                </Icon>
                            </Box>
                        </Stack>
                    </AccordionSummary>
                    <StyledAccordDetails in={open}>
                        <Card sx={{pb:1, my:1, pt:0}} elevation={0}>
                            <Tabs value={currentTab} onChange={(event, newValue) => changeTab(newValue)} sx={{minHeight:'auto'}}>
                                <Tab label='Zone Summary' value={1} sx={{minHeight:'auto', textTransform:'capitalize', padding:'10px 10px'}}/>
                                {/* <Tab label='Between Summary' value={2} sx={{minHeight:'auto', textTransform:'capitalize', padding:'10px 10px'}}/> */}
                                {/* <Tab label='Map View' value={3} sx={{minHeight:'auto', textTransform:'capitalize', padding:'10px 10px'}}/> */}
                                <Tab label='Alerts' value={4} sx={{minHeight:'auto', textTransform:'capitalize', padding:'10px 10px'}}/>
                            </Tabs>
                            <Divider />
                            <Box sx={{transform: currentTab === 5 ? 'translateX(-34px)' : ''}}>
                            {
                                currentTab === 1 && <ZoneWithIn item={item}/>
                            }
                            {
                                currentTab === 2 && <ZoneBetween />
                            }
                            {
                                currentTab === 3 && <ZoneLocation lat={parseFloat(geoLatlng[0])} lng={parseFloat(geoLatlng[0])}/>
                            }
                        </Box>
                        </Card>
                        {/* <Stack direction={'row'} spacing={1.5} sx={{mt:1}}>
                            <SoftButton variant='contained' className='success-soft' size='small' onClick={() => changeTab(1)}>Show Trails</SoftButton>
                            <SoftButton variant='contained' className='warning-soft' size='small' onClick={() => changeTab(2)} >Driver Behaivour</SoftButton>
                            <SoftButton variant='contained' className='info-soft' size='small' onClick={() => changeTab(3)}>Zone Location</SoftButton>
                        </Stack> */}
                        
                    </StyledAccordDetails>
                    
                </StyledAccord>
        </TimelineContent>
    </TimelineItem>
  )
}

export default TimelineNewItem