import { Box, Divider, FormLabel, Grid, IconButton, Skeleton, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import React from 'react'
import { StyledFormControl } from '../../theme/StyledEle';
import moment from 'moment';
import { BiSolidMagicWand } from "react-icons/bi";

export const Datalabel = (props) => {
    const { label,value, loading, parseIng = false} = props;
    return(
        <>
            <Grid item lg={3} sx={{borderWidth:'.5px 0px .5px .5px', borderStyle:'solid', borderColor:'#dedede'}}>{loading ? <Skeleton variant='text'/> : <Typography variant='body2'>{label}</Typography> }</Grid>
            <Grid item lg={7} sx={{borderWidth:'.5px .5px .5px .5px', borderStyle:'solid', borderColor:'#dedede'}}>{loading ? <Skeleton variant='text'/> : <Typography>{value} {parseIng && <IconButton size='small' color='warning' hint={'Parseing'}><BiSolidMagicWand    /></IconButton>}</Typography>}</Grid>
            {/* <Grid item lg={2}>
            {parseIng && <Typography variant='caption'>Parseing Data</Typography>}
            </Grid> */}
        </> 
    )
}

function FeedLogModel(props) {
    const { data=undefined, loading} = props;
    let theme = useTheme();
  
  return (
    <Box sx={{height:'450px', overflowY:'scroll'}}>
        <Box sx={{background:'#eaeaea', px:1, py:1.2, mb:2, position:'sticky', top:'0px', boxShadow:'0px 5px 5px rgba(0,0,0,.02)', zIndex:9999}}>
            <Grid container spacing={1} sx={{px:2}}>
                <Grid item lg={3}><Typography color={'warning.main'}>Key</Typography></Grid>
                <Grid item lg={7}><Typography color={'warning.main'}>Value</Typography></Grid>
            </Grid>
        </Box>
        <Grid container spacing={1} sx={{px:2}}>
                {/* <Datalabel label={'Date & Time'} value={moment(data?.data[0]?.value).format('YYYY-MM-DD HH:mm:ss')}/> */}
                 {/*<Datalabel label={'Header'} value={data?.data[0]?.value} />
                <Datalabel label={'IMEI'} value={data?.data[1]?.value} />
                <Grid item lg={3} sx={{borderWidth:'.5px 0px .5px .5px', borderStyle:'solid', borderColor:'#dedede'}} ><Typography variant='body2'>Latitude & Longitude</Typography></Grid>
                <Grid item lg={7} sx={{borderWidth:'.5px .5px .5px .5px', borderStyle:'solid', borderColor:'#dedede'}} ><Typography component={'a'} target={'_blank'} sx={{textDecoration:'underline'}} href={`https://www.google.com/maps/search/?api=1&query=${data?.data[3].value},${data?.data[4].value}`} >{data?.data[3].value}, {data?.data[4].value}</Typography></Grid> */}
                {
                    data !== undefined && data.data.map((item, index) => {
                        return(
                            <Datalabel 
                            label={
                                item?.key === 'TimeStamp' ? 'Date & Time'  : item?.key
                            } 
                            value={
                                item?.key === 'Date' ? moment(item?.value).format('DD-MM-YYYY') : item?.value
                            } 
                            parseIng={false} 
                            loading={loading}/>
                        )
                    })
                }
                
            </Grid>
            <Box sx={{height:'24px'}}>

                </Box>
    </Box>
  )
}

export default FeedLogModel