import { Alert, Box, Collapse, Divider, Fab, FormLabel, Grid, Icon, Pagination, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StyledFormControl, StyledTimeline } from '../../theme/StyledEle';
import { useGetVehicleMutation } from '../../store/slice/ApiSlice';
import deviceServices from '../../services/deviceService';
import InnerTab from '../../Utils/InnerTab';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { useGetVehicleActivityMutation } from '../../store/slice/AdminSlice/AdminSlice';
import { MdOutlineCheck, MdAdd, MdClose  } from "react-icons/md";
import moment from 'moment';
import { ScaleLoader, ScaleLoder } from 'react-spinners'

function ViewVehicle({data}) {
    let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
    const theme = useTheme();
    const [ getvehicleDet, { data: getvehicleData, isLoading: vehicleDetLoading, isSuccess: vehicleDetSuccess }] = useGetVehicleMutation();
    const [ getVehicleActivity, { data: activityData, isLoading: activityLoading, isSuccess: activitySuccess }] = useGetVehicleActivityMutation();
    const [vehicledata, setVehicleData] = useState(null);
    const [vehicleActivity, setVehicleActivity] = useState(null);
    const [plan, setPlan] = useState(null);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(5);
    let payload = {
        "resellerId": resellerId,
        "userid": userId,
        "orgId": orgId,
        "orgType": 2,
        "type": "Edit",
        "id": data?.id,
        "sessionOrgType": 2,
        "createdBy":userId
    }
    let activityPayload = {
        "vehicleId": data?.id,
        "page": page,
        "size": length,
        "searchText": "",
        "type": "",
        "vehicleHistoryId": 0,
        "lastDate": "",
        "orgId": orgId
    }

    const getActivity = async () => {
        const activity = await getVehicleActivity(activityPayload);
        setVehicleActivity(activity.data.dataObj);
    }

    useEffect(async() => {
        if(data !== null && data !== undefined){
            //get Sim
            const planLists = (await deviceServices.getlistofPlansList(payload)).data;
            setPlan(planLists.filter(item => item.value === data.listofPlans)[0].title);
            const vehicle = await getvehicleDet({id:data.id});
            setVehicleData(vehicle.data.data);
            getActivity();
        }
    },[])

    //Tabs
    const tabList = [
        {
            id:1,
            name:'Details',
            icon:'',
            show:true,
        },
        {
            id:2,
            name:'Activity',
            icon:'',
            show:true,
        }
    ]
    const [tabValue, setTabValue] = useState(1);
    const getTabValue = (value) => {
        setTabValue(value);
    }


    //Call  
    const pageChange = (event, value) => {
        setPage(value - 1);
        let page = value;
        activityPayload.page = page - 1;
        getActivity();
    }

      //Render HTML
  const renderHTML = (rawHTML) => React.createElement("p", {
    dangerouslySetInnerHTML: { __html: rawHTML },
  });

  return (
        vehicledata !== null && vehicledata !== undefined && 
        <Box sx={{height:'100%', maxHeight:'75vh', overflowY:'auto'}}>
        <Box>
            <InnerTab tabList={tabList} getTabValue={getTabValue}/>
        </Box>
            {
                tabValue === 1 && 
                <Box sx={{p:2, background:theme.palette.background.paper}}>
            <Grid container spacing={2}>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>VIN Number</FormLabel>
                <Typography>{vehicledata.number !== undefined && vehicledata.number !== '' ? vehicledata.number : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Vehicle No Plate</FormLabel>
                <Typography>{vehicledata.vehiclePlateNumber !== null && vehicledata.vehiclePlateNumber !== '' ? vehicledata.vehiclePlateNumber : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Vehicle Model</FormLabel>
                <Typography>{vehicledata.model !== null && vehicledata.model !== '' ? vehicledata.model : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Vehicle Type</FormLabel>
                <Typography>{vehicledata.vehicleType !== null && vehicledata.vehicleType !== '' ? vehicledata.vehicleType : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Vehicle Color</FormLabel>
                <Typography>{data.vehicleColor !== null && data.vehicleColor !== '' ? data.vehicleColor : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Vehicle Name</FormLabel>
                <Typography>{vehicledata.vehicleName !== null && vehicledata.vehicleName !== '' ? vehicledata.vehicleName : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Motor No</FormLabel>
                <Typography>{vehicledata.vehicleMotorNo !== null && vehicledata.vehicleMotorNo !== '' ? vehicledata.vehicleMotorNo : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Battery No</FormLabel>
                <Typography>{vehicledata.vehicleBatteryNo !== null && vehicledata.vehicleBatteryNo !== '' ? vehicledata.vehicleBatteryNo : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Controller No</FormLabel>
                <Typography>{vehicledata.vehicleControllerNo !== null && vehicledata.vehicleControllerNo !== '' ? vehicledata.vehicleControllerNo : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Charger No</FormLabel>
                <Typography>{vehicledata.vehicleChargerNo !== null && vehicledata.vehicleChargerNo !== '' ? vehicledata.vehicleChargerNo : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Display No</FormLabel>
                <Typography>{vehicledata.vehicleDisplayNo !== null && vehicledata.vehicleDisplayNo !== '' ? vehicledata.vehicleDisplayNo : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Display Board No</FormLabel>
                <Typography>{vehicledata.vehicleDisplayBoardNo !== null && vehicledata.vehicleDisplayBoardNo !== '' ? vehicledata.vehicleDisplayBoardNo : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Manufacture Date</FormLabel>
                <Typography>{vehicledata.manufacturedDate !== null && vehicledata.manufacturedDate !== '' ? moment(vehicledata.manufacturedDate).format("DD/MM/YYYY"): '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Dispatch Date</FormLabel>
                <Typography>{vehicledata.dispatchDate !== null && vehicledata.dispatchDate !== '' ? moment(vehicledata.dispatchDate).format("DD/MM/YYYY") : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Registration Date</FormLabel>
                <Typography>{vehicledata.registrationDate !== null && vehicledata.registrationDate !== '' ? moment(vehicledata.registrationDate).format("DD/MM/YYYY") : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        {resellerId === 109882 && 
        <>
        <Grid item lg={4}>
        <StyledFormControl>
                <FormLabel>Battery Serial Number</FormLabel>
                <Typography>{data.batterySerialNumber !== null && data.batterySerialNumber !== '' ? data.batterySerialNumber : '-'}</Typography>
            </StyledFormControl>
            </Grid>
              <Grid item lg={4}>
              <StyledFormControl>
                <FormLabel>Display BLE MAC ID</FormLabel>
                <Typography>{data.bleMacId !== null && data.bleMacId !== '' ? data.bleMacId : '-'}</Typography>
            </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
              <StyledFormControl>
                <FormLabel>Display Cluster MAC ID</FormLabel>
                <Typography>{data.clusterMACID !== null && data.clusterMACID !== '' ? data.clusterMACID : '-'}</Typography>
            </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Cluster Firmware Version</FormLabel>
                <Typography>{data.clusterFirmwareVersion !== null && data.clusterFirmwareVersion !== '' ? data.clusterFirmwareVersion : '-'}</Typography>
            </StyledFormControl> 
            </Grid>
            <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Bms Firmware Version</FormLabel>
                <Typography>{data.bmsfirmwareVersion !== null && data.bmsfirmwareVersion !== '' ? data.bmsfirmwareVersion : '-'}</Typography>
            </StyledFormControl> 
            </Grid>
            <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Bms Hardware Version</FormLabel>
                <Typography>{data.bmshardwareVersion !== null && data.bmshardwareVersion !== '' ? data.bmshardwareVersion : '-'}</Typography>
            </StyledFormControl> 
            </Grid>
            </>
            }
    </Grid>
    <Divider>Device Details</Divider>
    <Grid container spacing={2}>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Device IMEI</FormLabel>
                <Typography>{vehicledata.imei !== null && vehicledata.imei !== '' ? vehicledata.imei : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Plan</FormLabel>
                <Typography>{plan !== null && plan !== '' ? plan : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Plan Activation Date</FormLabel>
                <Typography>{vehicledata.licenseActivationDate !== null && vehicledata.licenseActivationDate !== '' ? moment(vehicledata.licenseActivationDate).format("DD/MM/YYYY") : '-'}</Typography>
            </StyledFormControl>
        </Grid>
    </Grid>
                </Box>
            }
            {
                tabValue === 2 && 
                <Box sx={{background:theme.palette.background.paper}}>
                    <Box sx={{background:theme.palette.background.paper, px:2, py:1, position:'sticky', top:'0px', zIndex:9, borderBottom:`.5px solid ${theme.palette.divider}`}}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Typography variant='body2' color={'text.secondary'}>VIN - <Typography sx={{fontWeight:600}} component={'span'}>{vehicledata.number}</Typography></Typography>
                            <Pagination count={activityData !== undefined ? Math.round(activityData?.count / length) === 0 ?  1 : Math.round(activityData?.count / length)  : 0} variant='outlined' shape='rounded' page={page + 1} sx={{position:'sticky', top:'0px'}} size='small' onChange={pageChange}/>
                        </Stack>
                    </Box>
                    <Box>
                        <Collapse in={activityLoading}>
                            <Alert severity='success' icon={false} variant='filled' sx={{borderRadius:'0px', justifyContent:'center'}}>
                                <ScaleLoader color="#fff" height={15}/>
                            </Alert>
                        </Collapse>
                    </Box>
                    <StyledTimeline>
                        {
                           vehicleActivity !== null && vehicleActivity.length > 0 ? vehicleActivity.map((item) => {
                            return(
                                <>
                                {/* <Box sx={{px:3, py:1, background:theme.palette.grey[100]}}>
                                    <Typography variant='subtitle1'>{moment(item.key).format('MMMM-YYYY')}</Typography>
                                </Box> */}
                                <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant='body2'>{moment(item.key).format('Do-MMM-YYYY')}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                        <Stack sx={{mt:2}}>
                                            {
                                                item?.obj?.map((item) => {
                                                        let color = (item.action === "Create" || item.action === "create") ? theme.palette.success.main : item.action === "Update" ? theme.palette.info.main : theme.palette.error.main
                                                        let icon = (item.action === "Create" || item.action === "create") ? <MdAdd /> : item.action === "Update" ? <MdOutlineCheck />
                                                        : <MdClose />
                                                    return(
                                                        <Stack direction={'column'}>
                                                            <Icon sx={{background:color}}>{icon}</Icon>
                                                            <Stack direction={'column'}>
                                                                <Typography variant='body1' gutterBottom>{item.action}</Typography>
                                                                <Typography variant='caption'>{item.createdDate}</Typography>
                                                            </Stack>
                                                            <Typography variant='body2'>{renderHTML(item.description)}</Typography>
                                                            <Divider sx={{my:1}}/>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Stack>
                                        
                                </TimelineContent>
                                </TimelineItem>
                                </>
                            )
                           }) : <Box sx={{px:3, py:1, background:theme.palette.grey[100]}}>
                                    <Typography variant='subtitle1'>No Activity</Typography>
                                </Box>
                        }
                    </StyledTimeline>
                </Box>
            }
        </Box>
  )
}

export default ViewVehicle