import React, { useState, useEffect, useMemo } from "react";
import { useForm, useFormState } from "react-hook-form";
import {
  MenuItem,
  Button,
  FormHelperText,
  CardActions,
  CardHeader,
  Box,
  Grid,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Stack,
  Collapse,
  Divider,
  useTheme,
} from "@mui/material";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFilterSearch,
  StyledFormButtonsRow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledPaperShadow,
  StyledTableTitle,
  StyledTextField,
} from "../../theme/StyledEle";
import vehicleServices from "../../services/vehicleServices";
import logServices from "../../services/logService";

import DraggableDialog from "../shared/DailogDrag";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, DatePicker } from "@mui/x-date-pickers";
import dropdownServices from "../../services/dropdownServices";
import DeviceService from "../../services/deviceService";
import moment from "moment";
import CommonSelect from "./CommonSelect";
import { BackDropLoader } from "../shared/StyledLoader";
import SearchIcon from "@mui/icons-material/Search";
import {
  useAuditLogMutation,
  useCreateVehicleMutation,
  useGetVehicleMutation,
  useVehicleUpdateMutation,
} from "../../store/slice/ApiSlice";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";

function CreateVehicleForm({ vehToEdit, onCancel }) {
  
  const [modelList, setModelList] = useState([]);
  const [deviceImeiJSON, setDeviceImeiJSON] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [selDeviceId, setSelDeviceId] = useState("");
  const [selSimId, setSelSimId] = useState("");
  const [selDeviceImeiId, setSelDeviceImeiId] = useState("");
  const [selDeviceImei, setSelDeviceImei] = useState("");
  const [selLicenceKey, setSelLicenceKey] = useState("0");
  const [selOldLicenceKey, setSelOldLicenceKey] = useState("0");
  const [selOldOrgId, setSelOldOrgId] = useState("0");
  const [deviceImeJSON, setDeviceImeJSON] = useState([]);
  const [loadStatus, setLoadStatus] = useState(true);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [hiddenImeiNo, setHiddenImeiNo] = useState("");
  const [displayval, setDisplayval] = useState("");
  const [seltdResellerId, setSeltdResellerId] = useState("0");
  // const [commonDropDownSelData, setCommonDropDownSelData] = useState({});
  const [circularOpen, setCircularOpen] = React.useState(false);
  const [manDate, setManDate] = useState();
  const [manDate1, setManDate1] = useState();
  const [fnstatus, setFnstatus] = useState(false);

  const [dealers, setDealers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [dealer, setDealer] = useState(0);
  const [customer, setCustomer] = useState(0);
  const [fleet, setFleet] = useState(0);
  const [org, setOrg] = useState(0);
  const [vehicleId, setVehicleId] = useState(0);
  const [auditLog] = useAuditLogMutation();

  const [dealerName, setDealerName] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [fleetName, setFleetName] = useState(null);
  const [resellerName, setResellerName] = useState(null);
  const [selectedVehicleTyre, setSelectedVehicleTyre] = useState(null);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [vehicleColorList, setVehicleColorList] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [clickedval, setClickedval] = useState(false);
  
  const theme = useTheme();
  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let orgLogo = userData.orgLogo;
  let userId = userData.id;
  let parentOrgLogo = userData.parentOrgLogo;
  let roleId = userData.roleId;
  let userOrgType = userData.userOrgType;
  let orgName = userData.orgName;
  let userName = userData.userName;

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const radioButton = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const vehColor = [
    {
      id: 1,
      title: "Matte Black",
      value: "Matte Black",
    },
    {
      id: 2,
      title: "Deep Wine",
      value: "Deep Wine",
    },
    {
      id: 3,
      title: "Stardust",
      value: "Stardust",
    },
    {
      id: 4,
      title: "Midnight Black",
      value: "Midnight Black",
    },
    {
      id: 5,
      title: "Matte White",
      value: "Matte White",
    },
    {
      id: 6,
      title: "Yellow",
      value: "Yellow",
    },
    {
      id: 7,
      title: "Jet Black",
      value: "Jet Black",
    },
    {
      id: 8,
      title: "Matte Gold",
      value: "Matte Gold",
    },
    {
      id: 9,
      title: "Satin Blue",
      value: "Satin Blue",
    },
    {
      id: 10,
      title: "Sparkle Black",
      value: "Sparkle Black",
    },
    {
      id: 11,
      title: "Pearl White",
      value: "Pearl White",
    },
  ];
  const vehColor3w = [
    {
      id: 1,
      title: "Blue",
      value: "Blue",
    },
    {
      id: 2,
      title: "White",
      value: "White",
    },
    {
      id: 3,
      title: "Sky Blue",
      value: "Sky Blue",
    },
    {
      id: 4,
      title: "Black",
      value: "Black",
    },
    {
      id: 5,
      title: "Green",
      value: "Green",
    },
    {
      id: 6,
      title: "Yellow",
      value: "Yellow",
    },

  ];
  const vehCategory= [
    {
      id: 1,
      title: "Retro",
      value: "Retro",
    },
    {
      id: 2,
      title: "New",
      value: "New",
    },
    
  ];

  const vehReason= [
    {
      id: 1,
      title: "Breakdown",
      value: 1,
    },
    {
      id: 2,
      title: "Accident",
      value: 2,
    },
    
  ];

  const vehicleTyre = [
    {
      id: 1,
      title: "4x2",
      value: "1",
    },
    {
      id: 2,
      title: "4x4",
      value: "2",
    },
    {
      id: 3,
      title: "6x2",
      value: "3",
    },
    {
      id: 4,
      title: "6x4",
      value: "4",
    },
    {
      id: 5,
      title: "2",
      value: "5",
    },
    {
      id: 6,
      title: "3",
      value: "6",
    },
    {
      id: 7,
      title: "4",
      value: "7",
    },
  ];

  const financierList= [
    {
      id: 1,
      title: "ECOFY",
      value: "ECOFY",
    },
    {
      id: 2,
      title: "Financier1",
      value: "Financier1",
    },
    
  ];
  
  var selectedResellerId = null;

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues:{
      vehicleType:'0',
      model:'1',
      listofPlans:'0'
    }
  });

  const { isDirty, touchedFields, dirtyFields } = useFormState({control})

  //Slice service start



  const [
    vehicleCreate,
    {
      data: vehicleCreateData,
      isLoading: vehicleCreateLoading,
      isSuccess: vehicleCreateSuccess,
    },
  ] = useCreateVehicleMutation();
  const [
    vehicleupdate,
    {
      data: vehicleUpdateData,
      isLoading: vehicleUpdateLoading,
      isSuccess: vehicleUpdateSuccess,
    },
  ] = useVehicleUpdateMutation();
  
  const [
    getvehicleDet,
    {
      data: getvehicleData,
      isLoading: vehicleDetLoading,
      isSuccess: vehicleDetSuccess,
    },
  ] = useGetVehicleMutation();


  useEffect(async () => {
    var deviceId = "";
    var deviceImei = "";
    var simId = "";
    var licenceKey = "";
    getOrganization();
    getDealer();

    const formData = new FormData();
    formData.append("resellerId", resellerId);
    formData.append("orgType", userOrgType);

    const { data: vehicle_type } = await vehicleServices.getVehicleType(
      formData
    );

    setVehicleTypeList(vehicle_type.data);


    const formData1 = new FormData();
    formData1.append("resellerId", resellerId);

    const { data: vehicleColor } = await vehicleServices.getVehicleColor(
      formData1
    );

    setVehicleColorList(vehicleColor.data);


    const resellerPayload = {
      value: "deviceImei",
      orgType: "2",
      resellerId: userData != null ? userData.resellerId : "-1",
      dealerId: 0,
      roleId: userData != null ? userData.roleId : "-1",
      id: userData != null ? userData.orgId : "-1",
      searchText: "",
    };
    const { data: resellerResponseNew } =
      await dropdownServices.getVehicleIMEIlistByLimit(resellerPayload);
    var jsn = {};
    jsn.key = deviceId;
    jsn.value = deviceImei;
    jsn.refVal = simId;
    jsn.refVal1 = licenceKey;
    if (deviceId !== "") {
      resellerResponseNew.data.push(jsn);
    }
    setSelDeviceId(deviceId);
    setSelSimId(simId);
    setDeviceImeiJSON(resellerResponseNew.data);

    setHiddenImeiNo(selDeviceImei);
    if (selDeviceImei === "") {
      setHiddenImeiNo(
        resellerResponseNew.data.length > 0
          ? resellerResponseNew.data[0].value
          : "-1"
      );
    }
    selectedResellerId =
      resellerResponseNew.data.length > 0
        ? resellerResponseNew.data[0].key
        : "-1";
    setSeltdResellerId(
      resellerResponseNew.data.length > 0
        ? resellerResponseNew.data[0].key
        : "-1"
    );
    setDisplayval("");
    setDisplayval(fnset(selDeviceImei));
    var payload = {};
    payload.resellerId = resellerId;

    const { data: model_list } = await vehicleServices.getVehicleModel(payload);
    setModelList(model_list.data);
    setFnstatus(!fnstatus);
  }, []);




  useEffect(async () => {
    auditPayload.message = "Admin > Vehicle > Create Vehicle";
    auditPayload.action = "View";
    auditPayload.featureName = "Vehicle";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);



// Edit and Create
  
useEffect(async () => {
  if(modelList.length !== 0 && vehicleTypeList !== 0)
    if (!vehToEdit) {
      var payload1 = {};
      payload1.resellerId = userData != null ? resellerId : "-1";
      payload1.userid = userData != null ? userData.id : "-1";
      payload1.orgId = userData != null ? userData.orgId : "-1";
      payload1.orgType = userData != null ? userData.userOrgType : "-1";
      payload1.type = "Create";
     
      payload1.sessionOrgType = userData != null ? userData.userOrgType : "-1";
      payload1.createdBy = userData != null ? userData.id : "-1";
      if (2 !== userData.userOrgType) {
        payload1.orgType = 2;
        payload1.sessionOrgType = 2;
        payload1.subresellerOrgId = 2;
        payload1.orgId = userData != null ? userData.resellerId : "-1";
      }
      const planLists = (await DeviceService.getlistofPlansList(payload1)).data;
      setPlanList(planLists);

      reset({
        listofPlans:planLists[0].value,
        model:modelList[0].value,
        vehicleType:vehicleTypeList[0].value,
      })

    } else {
      var payload1 = {};
      payload1.resellerId = userData != null ? userData.resellerId : "-1";
      payload1.userid = userData != null ? userData.id : "-1";
      payload1.orgId = userData != null ? userData.orgId : "-1";
      payload1.orgType = userData != null ? userData.userOrgType : "-1";
      payload1.type = "Edit";
      payload1.id = vehToEdit.id;
      payload1.sessionOrgType = userData != null ? userData.userOrgType : "-1";
      payload1.createdBy = userData != null ? userData.id : "-1";
      if (2 !== userData.userOrgType) {
        payload1.orgType = 2;
        payload1.sessionOrgType = 2;
        payload1.subresellerOrgId = 2;
        payload1.orgId = userData != null ? userData.resellerId : "-1";
      }
      const planLists = (await DeviceService.getlistofPlansList(payload1)).data;
      setPlanList(planLists);

      // reset({
      //   //listofPlans:planLists[0].value,
      //   //model:getvehicleData.data.model,
      //   //vehicleType:vehicleTypeList[0].value,
      // })

      var reqpayload = {
        id: vehToEdit.id,
      };
      
      getvehicleDet(reqpayload);
      if (vehToEdit.clickedval === "view") {
        setClickedval(true);
      }
    }
  },[modelList,vehicleTypeList]);

  const getOrganization = async () => {
    const resellerPayload = {
      value: "reseller",
      orgType: "2",
      resellerId: resellerId,
      dealerId: 0,
      roleId: roleId,
      id: resellerId,
      searchText: "",
    };
    const { data: resellerResponseNew } = await dropdownServices.getResellers(
      resellerPayload
    );
    const { content: orgnListJSON } = resellerResponseNew.data;
    setOrg(orgnListJSON.length > 0 ? orgnListJSON[0].key : 0);
    setResellerName(orgnListJSON.length > 0 ? orgnListJSON[0].value : "");
  };

  const getDealer = async () => {
    const dealerListPayload = {
      value: "dealer",
      resellerId: resellerId,
      searchText: "",
      regionId: -1,
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;
    setDealers(dealerList);
    setDealer(dealerList.length > 0 ? dealerList[0].key : 0);
    setDealerName(dealerList.length > 0 ? dealerList[0].value : "");
    getCustomer(dealerList.length > 0 ? dealerList[0].key : 0);
  };

  const getCustomer = async (dealerId) => {
    const customersListPayloadCreate = {
      value: "unAssignedCustomer",
      resellerId: resellerId,
      dealerId: 0,
      searchText: "",
      roleId:roleId,
      paramValue:"vehicle",
      orgType:userOrgType
    };
    const customersListPayload = {
    value: "customer",
    resellerId: resellerId,
    dealerId: dealerId,
    searchText: "",
    paramValue: "vehicle",
  };
  if(!vehToEdit){
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayloadCreate
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(customersList);
    setCustomer(customersList.length > 0 ? customersList[0].key : 0);
    setCustomerName(customersList.length > 0 ? customersList[0].value : "");
    getFleet(customersList.length > 0 ? customersList[0].key : 0);
  }else{
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(customersList);
    setCustomer(customersList.length > 0 ? customersList[0].key : 0);
    setCustomerName(customersList.length > 0 ? customersList[0].value : "");
    getFleet(customersList.length > 0 ? customersList[0].key : 0);
  }
   
   
  };
  const getFleet = async (customerId) => {
    const fleetListPayloadCreate = {
      value: "VINassigned",
      customerId: customerId,
      dealerId: 0,
      searchText: "",

    };
    const fleetListPayload = {
    value: "fleet",
        customerId: customerId,
        dealerId: 0,
        searchText: "",
    };
    if(!vehToEdit){
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayloadCreate
      );
      const { content: fleetListResponse } = fleetListResp.data;
      setFleets(fleetListResponse);
      setFleet(fleetListResponse.length > 0 ? fleetListResponse[0].key : 0);
      setFleetName(
        fleetListResponse.length > 0 ? fleetListResponse[0].value : ""
      );
    }else{
    const { data: fleetListResp } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetListResponse } = fleetListResp.data;
    setFleets(fleetListResponse);
    setFleet(fleetListResponse.length > 0 ? fleetListResponse[0].key : 0);
    setFleetName(
      fleetListResponse.length > 0 ? fleetListResponse[0].value : ""
    );
    }
  };
  const getDealerName = (props) => {
    setDealerName(props.children);
  };
  const getCustomerName = (props) => {
    setCustomerName(props.children);
  };
  const getFleetName = (props) => {
    setFleetName(props.children);
  };
  const callbackLoadFn = async (val, imei) => {
    setLoadStatus(false);
    setSelDeviceImei(imei);
    setSelDeviceId(val);
    setSelDeviceImeiId(val);
  };
  const onSubmit = (data) => {
    if (!vehToEdit) {
      createVehicle(data);
      setCircularOpen(true);
    } else {
      updateVehicle(data);
      setCircularOpen(true);
    }
  };

  const createVehicle = async (obj) => {
    obj.number = "string";
    obj.customerName = customerName;
    obj.customerId = customer;
    obj.fleetName = fleetName;
    obj.resellerName = resellerName;
    obj.dealerName = dealerName;
    // obj.regionId = commonDropDownSelData.regionId;
    obj.dealerId = dealer;
    obj.orgId = fleet === undefined ? userData.orgId : fleet;

    obj.oldLicenceKey = obj.licenceKey;
    obj.oldOrgId = obj.orgId;
    obj.resellerId = userData != null ? userData.resellerId : "-1";
    if (userData.userOrgType === 5) {
      obj.dealerId = userData != null ? userData.orgId : "-1";
      obj.resellerId = userData != null ? userData.resellerId : "-1";
    }
    obj.orgType = userData != null ? userData.userOrgType : "-1";
    if (loadStatus === true) {
      obj.imeiNo = obj.hiddenImeiNo;
    } else {
      obj.imeiNo = selDeviceImei;
    }
    obj.deviceId = selDeviceImeiId === "" ? obj.deviceImei : selDeviceImeiId;

    for (var i = 0; i < deviceImeiJSON.length; i++) {
      if (obj.deviceId === deviceImeiJSON[i].key) {
        obj.simId = deviceImeiJSON[i].refVal;
        obj.licenceKey = deviceImeiJSON[i].refVal1;
        obj.imei = deviceImeiJSON[i].value;
        break;
      }
    }
    obj.manufacturedDate =
      obj.manufacturedDate.split("/")[2] +
      "-" +
      obj.manufacturedDate.split("/")[1] +
      "-" +
      obj.manufacturedDate.split("/")[0];
    obj.dispatchDate =
      obj.dispatchDate.split("/")[2] +
      "-" +
      obj.dispatchDate.split("/")[1] +
      "-" +
      obj.dispatchDate.split("/")[0];
    obj.registrationDate =
      obj.registrationDate.split("/")[2] +
      "-" +
      obj.registrationDate.split("/")[1] +
      "-" +
      obj.registrationDate.split("/")[0];
    obj.licenseActivationDate =
      obj.licenseActivationDate.split("/")[2] +
      "-" +
      obj.licenseActivationDate.split("/")[1] +
      "-" +
      obj.licenseActivationDate.split("/")[0];

    obj.equipmentMaxLimit = 1;
    obj.equipmentMinLimit = 1;
    obj.createdBy = userData != null ? userData.id : "-1";
    vehicleCreate(obj);
  };

  let description = "";
  const [oldValues, setOldValue] = useState(getValues())
  const getValue = (fields, newValue) => {
    description += '<p>Vehicle updated details:<ul>'
    let findTouched = fields.map((item, index) => {
        if(oldValues[item[0]] !== newValue[item[0]]){
          description += `<ol>${index + 1}) ${item[0]}: ${oldValues[item[0]] !== undefined && oldValues[item[0]] !== '' ? oldValues[item[0]] : '-'} => ${newValue[item[0]]}</ol>`
        }},
       
  )
   description += `<ul>have been updated By ${userName}</p>`
}

  
  const updateVehicle = async (obj) => {
    let FieldArry = Object.entries(touchedFields);
    getValue(FieldArry, obj);

    obj.number = "string";
    obj.id = vehicleId;
    obj.customerId = customer;
    obj.licenceKey = selLicenceKey;
    obj.orgId = fleet;
    // obj.resellerId = obj.organizationId
    obj.resellerId = userData != null ? userData.resellerId : "-1";
    obj.orgType = userData != null ? userData.userOrgType : "-1";
    obj.oldLicenceKey = selOldLicenceKey;
    obj.oldOrgId = selOldOrgId;
    obj.description = description;
    //obj.customerId = 1;
    //obj.dealerId = 1;
    //obj.licenseKey = 1;
    //obj.simId = 1;
    obj.equipmentMaxLimit = 1;
    obj.equipmentMinLimit = 1;
    obj.createdBy = userData != null ? userData.id : "-1";
    obj.manufacturedDate =
      obj.manufacturedDate.split("/")[2] +
      "-" +
      obj.manufacturedDate.split("/")[1] +
      "-" +
      obj.manufacturedDate.split("/")[0];
    obj.dispatchDate =
      obj.dispatchDate.split("/")[2] +
      "-" +
      obj.dispatchDate.split("/")[1] +
      "-" +
      obj.dispatchDate.split("/")[0];
    obj.registrationDate =
      obj.registrationDate.split("/")[2] +
      "-" +
      obj.registrationDate.split("/")[1] +
      "-" +
      obj.registrationDate.split("/")[0];
    obj.licenseActivationDate =
      obj.licenseActivationDate.split("/")[2] +
      "-" +
      obj.licenseActivationDate.split("/")[1] +
      "-" +
      obj.licenseActivationDate.split("/")[0];
    obj.customerName = customerName;
    obj.fleetName = fleetName;
    obj.resellerName = resellerName;
    obj.dealerName = dealerName;
    // obj.regionId = commonDropDownSelData.regionId;
    obj.dealerId = dealer;

    if (userData.userOrgType === 5) {
      obj.dealerId = userData != null ? userData.orgId : "-1";
    }
    //obj.dealerId = 1;
    //obj.licenseKey = 1;
    //obj.simId = 1;
    obj.imeiNo = selDeviceImei;
    obj.deviceId = selDeviceImeiId === "" ? obj.deviceImei : selDeviceImeiId;
    obj.deviceImei = selDeviceImei;
    obj.imei = selDeviceImei;
    for (var i = 0; i < deviceImeiJSON.length; i++) {
      if (obj.deviceId === deviceImeiJSON[i].key) {
        obj.simId = deviceImeiJSON[i].refVal;
        obj.licenceKey = deviceImeiJSON[i].refVal1;
        obj.imei = deviceImeiJSON[i].value;
        break;
      }
    }
    // const responseData = await vehicleServices.updateVehicle(obj);
     vehicleupdate(obj);
     setCircularOpen(false);

  };
  const handleCancelClick = () => {
    onCancel();
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  // datepikcer state
  const [date, setDate] = useState();

  const [disDate, setDisDate] = useState();
  const [regDate, setRegDate] = useState();
  const handleManDateChange = (newValue) => {
    setManDate(newValue);
    setValue("manufacturedDate", moment(newValue).format("DD/MM/yyyy"));
  };
  const handleDisDateChange = (newValue) => {
    setDisDate(newValue);
    setValue("dispatchDate", moment(newValue).format("DD/MM/yyyy"));
  };
  const handleRegDateChange = (newValue) => {
    setRegDate(newValue);
    setValue("registrationDate", moment(newValue).format("DD/MM/yyyy"));
  };
  const handleDateChange = (newValue) => {
    setDate(newValue);
    setValue("licenseActivationDate", moment(newValue).format("DD/MM/yyyy"));
  };

  const [defaultValue, setDefaultValue] = useState(1);

  const fnset = (val) => {
    return <input type="hidden" name="hiddenImeiNo" value={val}></input>;
  };
  const handleSearch = async (searchText) => {
    const resellerPayload = {
      value: "deviceImei",
      orgType: "2",
      resellerId: userData != null ? userData.resellerId : "-1",
      dealerId: 0,
      roleId: userData != null ? userData.roleId : "-1",
      id: userData != null ? userData.orgId : "-1",
      searchText: searchText,
    };
    const { data: resellerResponseNew } =
      await dropdownServices.getVehicleIMEIlistByLimit(resellerPayload);
    setDeviceImeiJSON(resellerResponseNew.data);
  };

  const handleSearchDropdown = async (searchText, name) => {
    if (name === "dealer") {
      const dealerListPayload = {
        value: "dealer",
        resellerId: resellerId,
        searchText: searchText,
        regionId: -1,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;
      setDealers(dealerList);
    } else if (name === "customer") {
      const customersListPayload = {
        value: "customer",
        resellerId: resellerId,
        dealerId: dealer,
        searchText: searchText,
        paramValue: "vehicle",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
    } else if (name === "fleet") {
      const fleetListPayload = {
        value: "fleet",
        customerId: customer,
        dealerId: 0,
        searchText: searchText,
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      setFleets(fleetListResponse);
    }
  };

  useEffect(async () => {

    if (vehicleUpdateSuccess) {
      dispatch(
        snackService([
          vehicleUpdateData?.resultMessage,
          vehicleUpdateData?.resultText,
          vehicleUpdateSuccess,
        ])
      );
      onCancel();
    } else if (vehicleCreateSuccess) {
      dispatch(
        snackService([
          vehicleCreateData?.resultMessage,
          vehicleCreateData?.resultText,
          vehicleCreateSuccess,
        ])
      );
      onCancel();
    } else if (vehicleDetSuccess) {
    
      var deviceId = "";
      var deviceImei = "";
      var simId = "";
      var licenceKey = "";
      setDealer(getvehicleData?.data?.dealerId.toString());
      setDealerName(getvehicleData?.data?.dealerName);
      const customersListPayload = {
        value: "customer",
        resellerId: resellerId,
        dealerId: getvehicleData?.data?.dealerId,
        searchText: "",
        paramValue: "vehicle",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
      setCustomer(getvehicleData?.data?.customerId.toString());
      setCustomerName(getvehicleData.data?.customerName);
      const fleetListPayload = {
        value: "fleet",
        customerId: getvehicleData?.data?.customerId,
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      setFleets(fleetListResponse);
      setFleet(getvehicleData?.data?.orgId.toString());
      setFleetName(getvehicleData?.data?.fleetName);

      var vehicledata = {};
      vehicledata.manufacturedDate =
        getvehicleData.data.manufacturedDate !== null
          ? getvehicleData.data.manufacturedDate.split(" ")[0].split("-")[2] +
            "/" +
            getvehicleData.data.manufacturedDate.split(" ")[0].split("-")[1] +
            "/" +
            getvehicleData.data.manufacturedDate.split(" ")[0].split("-")[0]
          : "";

      vehicledata.dispatchDate =
        getvehicleData.data.dispatchDate !== null
          ? getvehicleData.data.dispatchDate
              .split(" ")[0]
              .split("-")[2] +
            "/" +
            getvehicleData.data.dispatchDate
              .split(" ")[0]
              .split("-")[1] +
            "/" +
            getvehicleData.data.dispatchDate.split(" ")[0].split("-")[0]
          : "";

      vehicledata.licenseActivationDate =
        getvehicleData.data.licenseActivationDate !== null
          ? getvehicleData.data.licenseActivationDate
              .split(" ")[0]
              .split("-")[2] +
            "/" +
            getvehicleData.data.licenseActivationDate
              .split(" ")[0]
              .split("-")[1] +
            "/" +
            getvehicleData.data.licenseActivationDate
              .split(" ")[0]
              .split("-")[0]
          : "";

      vehicledata.registrationDate =
        getvehicleData.data.registrationDate !== null
          ? getvehicleData.data.registrationDate.split(" ")[0].split("-")[2] +
            "/" +
            getvehicleData.data.registrationDate.split(" ")[0].split("-")[1] +
            "/" +
            getvehicleData.data.registrationDate.split(" ")[0].split("-")[0]
          : "";

        let updateObj = {
          chassisNo: getvehicleData.data.chassisNo,
          vehiclePlateNumber: getvehicleData.data.number,
          vehicleName: getvehicleData.data.vehicleName,
          motorNo: getvehicleData.data.vehicleMotorNo,
          batteryNo: getvehicleData.data.vehicleBatteryNo,
          controllerNo: getvehicleData.data.vehicleControllerNo,
          chargerNo: getvehicleData.data.vehicleChargerNo,
          displayNo: getvehicleData.data.vehicleDisplayNo,
          displayBoardNo: getvehicleData.data.vehicleDisplayBoardNo,
          manufacturedDate: vehicledata.manufacturedDate,
          dispatchDate: vehicledata.dispatchDate,
          licenseActivationDate: vehicledata.licenseActivationDate,
          registrationDate: vehicledata.registrationDate,
          deviceImei: getvehicleData.data.imei,
          bleMacId: vehToEdit !== null? vehToEdit.bleMacId : "",
          listofPlans:getvehicleData.data.listofPlans,
          model:getvehicleData.data.model,
          vehicleType:getvehicleData.data.vehicleType,
          bmsfirmwareVersion: vehToEdit !== null? vehToEdit.bmsfirmwareVersion : "",
          bmshardwareVersion: vehToEdit !== null? vehToEdit.bmshardwareVersion : "",
          clusterFirmwareVersion: vehToEdit !== null? vehToEdit.clusterFirmwareVersion : "",
          clusterMACID: vehToEdit !== null? vehToEdit.clusterMACID : "",
          batterySerialNumber: vehToEdit !== null? vehToEdit.batterySerialNumber : "",
        }
      setOldValue(updateObj)
      reset(updateObj);

      //vehToEdit.manufacturedDate=responseData.manufacturedDate;
      setManDate(moment(vehicledata.manufacturedDate, "DD-MM-YYYY").toISOString());
      setValue("manufacturedDate", vehicledata.manufacturedDate);
      setDisDate(moment(vehicledata.dispatchDate, "DD-MM-YYYY").toISOString());
      setValue("dispatchDate", vehicledata.dispatchDate);
      setDate(moment(vehicledata.licenseActivationDate, "DD-MM-YYYY").toISOString());
      setValue("licenseActivationDate", vehicledata.licenseActivationDate);
      setRegDate(moment(vehicledata.registrationDate, "DD-MM-YYYY").toISOString());
      setValue("registrationDate", vehicledata.registrationDate);

      deviceId = getvehicleData.data.deviceId;
      deviceImei = getvehicleData.data.imei;
      simId = getvehicleData.data.simId;
      licenceKey = getvehicleData.data.licenceKey;
      // vehicleId=getvehicleData.data.id;
      setVehicleId(getvehicleData.data.id);
      setSelDeviceImei(getvehicleData.data.imei);
      setSelSimId(getvehicleData.data.simId);
      setSelLicenceKey(getvehicleData.data.licenceKey);
      setSelOldLicenceKey(getvehicleData.data.licenceKey);
      setSelOldOrgId(getvehicleData.data.orgId);
      setSelDeviceImeiId(getvehicleData.data.deviceId);
      setSelectedVehicleTyre(getvehicleData.data.vehTyre);
      var arry = [];
      arry.key = deviceId;
      arry.value = deviceImei;
      setDeviceImeiJSON(arry);
  }
  }, [vehicleCreateSuccess, vehicleUpdateSuccess, vehicleDetSuccess]);



  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledCardShadow>
          <Collapse
            in={true}
            sx={{
              border: `.5px solid ${theme.palette.divider}`,
              borderRadius: "10px",
            }}
          >
            <Box sx={{ px: 2, py: 1, my: 0.5 }}>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"end"}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack
                  direction={"row"}
                  alignItems={"end"}
                  spacing={1.5}
                  sx={{ Width: "100%" }}
                >
                  <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                    <StyledFormControlLabel required>
                      Dealer
                    </StyledFormControlLabel>
                    <StyledDropDown
                      size="small"
                      placeholder=""
                      value={dealer}
                      onChange={(event, newPlan) => {
                        setDealer(event.target.value);
                        getCustomer(event.target.value);
                        getDealerName(newPlan.props);
                      }}
                      disabled={vehToEdit !== null ? false : true}
                    >
                      <StyledFilterSearch
                        size="small"
                        name="dealer"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleSearchDropdown(e.target.value, e.target.name)
                        }
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      {dealers.length > 0 &&
                        dealers.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                  </StyledFormControl>
                  <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                    <StyledFormControlLabel required>
                      Customer
                    </StyledFormControlLabel>
                    <StyledDropDown
                      size="small"
                      placeholder=""
                      value={customer}
                      onChange={(event, newTestPlan) => {
                        setCustomer(event.target.value);
                        getFleet(event.target.value);
                        getCustomerName(newTestPlan.props);
                      }}
                      disabled={vehToEdit !== null ? false : true}
                     
                    >
                      <StyledFilterSearch
                        size="small"
                        name="customer"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleSearchDropdown(e.target.value, e.target.name)
                        }
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      {customers.length > 0 &&
                        customers.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                  </StyledFormControl>
                  <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                    <StyledFormControlLabel required>
                      Fleet
                    </StyledFormControlLabel>
                    <StyledDropDown
                      size="small"
                      placeholder=""
                      value={fleet}
                      onChange={(event, newTestPlan) => {
                        setFleet(event.target.value);
                        getFleetName(newTestPlan.props);
                      }}
                      disabled={vehToEdit !== null ? false : true}
                    >
                      <StyledFilterSearch
                        size="small"
                        name="fleet"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleSearchDropdown(e.target.value, e.target.name)
                        }
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      {fleets.length > 0 &&
                        fleets.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                  </StyledFormControl>
                </Stack>
              </Stack>
            </Box>
          </Collapse>
        </StyledCardShadow>
        <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Vehicle Details</StyledTableTitle>}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Vehicle Vin No 
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.chassisNo && true}
                    size="small"
                    fullWidth
                    {...register("chassisNo", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                      validate: {
                        emailAvailable: async (fieldValue) => {
                          const formData = new FormData();
                          formData.append("vinno", fieldValue);
                          formData.append("vehicleId", vehicleId);

                          const chkvinno =
                            await vehicleServices.checkVinNoExists(formData);

                          return (
                            chkvinno.data !== true ||
                            "Vehicle Vin No Already Exists"
                          );
                        },
                      },
                    })}
                    onChange={(e) => setValue("chassisNo", e.target.value)}
                    helperText={errors.chassisNo && errors.chassisNo.message}
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControlLabel required>
                  Vehicle No Plate
                </StyledFormControlLabel>
                <StyledFormControl fullWidth>
                  <StyledTextField
                    error={errors.vehiclePlateNumber && true}
                    size="small"
                    fullWidth
                    {...register("vehiclePlateNumber", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                      validate: {
                        emailAvailable: async (fieldValue) => {
                          const formData = new FormData();
                          formData.append("vehicleName", fieldValue);
                          formData.append("vehicleId", vehicleId);
                          formData.append(
                            "orgId",
                            vehToEdit !== null ? vehToEdit.orgId : orgId
                          );

                          const vehname = await vehicleServices.chkFleetName(
                            formData
                          );
                          return (
                            vehname.data !== true || "Vehicle No Already Exists"
                          );
                        },
                      },
                    })}
                    onChange={(e) =>
                      setValue("vehiclePlateNumber", e.target.value)
                    }
                    helperText={
                      errors.vehiclePlateNumber &&
                      errors.vehiclePlateNumber.message
                    }
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Vehicle Model
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.model && true}
                    {...register("model", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("model", e.target.value);
                    }}
                    defaultValue={ vehToEdit !== null ? vehToEdit?.model : "0"}
                    // value={getValues('model')}
                    size="small"
                    disabled = {clickedval}
                    value={watch('model')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {modelList.length > 0 &&
                      modelList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>

                  {errors.model && (
                    <FormHelperText>{errors.model.message}</FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth error={errors.vehicleType}>
                  <StyledFormControlLabel required>
                    Vehicle Type
                  </StyledFormControlLabel>
                  <StyledDropDown
                    {...register("vehicleType", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("vehicleType", e.target.value);
                    }}
                    defaultValue={
                      vehToEdit !== null ? vehToEdit?.type : "0"
                    }
                    
                    size="small"
                    disabled = {clickedval}
                    value={watch('vehicleType')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {vehicleTypeList.length > 0 &&
                      vehicleTypeList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.vehicleType && (
                    <FormHelperText>
                      {errors.vehicleType.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              {(resellerId === 109882 || resellerId === 109978 ) &&
              <Grid item lg={4}>
                <StyledFormControl fullWidth error={errors.color && true}>
                  <StyledFormControlLabel required>
                    Vehicle Color
                  </StyledFormControlLabel>
                  <StyledDropDown
                    {...register("color", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("color", e.target.value);
                    }}
                    defaultValue={
                      vehToEdit !== null ? vehToEdit?.vehicleColor : "0"
                    }
                    size="small"
                    disabled = {clickedval}
                  >
                    <MenuItem value="0">Select</MenuItem>
                      {vehicleColorList.length > 0 &&
                      vehicleColorList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>

                  {errors.color && (
                    <FormHelperText>{errors.color.message}</FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              }
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Vehicle Name
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.vehicleName && true}
                    {...register("vehicleName", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("vehicleName", e.target.value)}
                    helperText={
                      errors.vehicleName && errors.vehicleName.message
                    }
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Motor No
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.motorNo && true}
                    {...register("motorNo", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("motorNo", e.target.value)}
                    helperText={errors.motorNo && errors.motorNo.message}
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Battery No
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.batteryNo && true}
                    {...register("batteryNo", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("batteryNo", e.target.value)}
                    helperText={errors.batteryNo && errors.batteryNo.message}
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Controller No
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.controllerNo && true}
                    {...register("controllerNo", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("controllerNo", e.target.value)}
                    helperText={
                      errors.controllerNo && errors.controllerNo.message
                    }
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Charger No
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.chargerNo && true}
                    {...register("chargerNo", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("chargerNo", e.target.value)}
                    helperText={errors.chargerNo && errors.chargerNo.message}
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Display No
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.displayNo && true}
                    {...register("displayNo", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("displayNo", e.target.value)}
                    helperText={errors.displayNo && errors.displayNo.message}
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Display Board No
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.displayBoardNo && true}
                    {...register("displayBoardNo", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("displayBoardNo", e.target.value)}
                    helperText={
                      errors.displayBoardNo && errors.displayBoardNo.message
                    }
                    inputProps={{ maxLength: "25" }}
                    disabled = {clickedval}
                  />
                </StyledFormControl>
              </Grid>
            {(resellerId === 109882 && vehToEdit !== null) ?
            <>
        <Grid item lg={4}>
              
              <StyledFormControl fullWidth >
                <StyledFormControlLabel>
                Battery Serial Number
                </StyledFormControlLabel>
                <StyledTextField
                  fullWidth
                  size="small"
                  error={errors.batterySerialNumber && false}
                  {...register("batterySerialNumber", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("batterySerialNumber", e.target.value)}
                  helperText={errors.batterySerialNumber && errors.batterySerialNumber.message}
                  disabled = {true}
                />
              </StyledFormControl>
            </Grid>
              <Grid item lg={4}>
              
                <StyledFormControl fullWidth >
                  <StyledFormControlLabel>
                  Display BLE MAC ID
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.bleMacId && false}
                    {...register("bleMacId", {
                      required: {
                        value: false,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("bleMacId", e.target.value)}
                    helperText={errors.bleMacId && errors.bleMacId.message}
                    disabled = {true}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
              
              <StyledFormControl fullWidth >
                <StyledFormControlLabel>
                Display Cluster MAC ID
                </StyledFormControlLabel>
                <StyledTextField
                  fullWidth
                  size="small"
                  error={errors.clusterMACID && false}
                  {...register("clusterMACID", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("clusterMACID", e.target.value)}
                  helperText={errors.clusterMACID && errors.clusterMACID.message}
                  disabled = {true}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              
              <StyledFormControl fullWidth >
                <StyledFormControlLabel>
                Cluster Firmware Version
                </StyledFormControlLabel>
                <StyledTextField
                  fullWidth
                  size="small"
                  error={errors.clusterFirmwareVersion && false}
                  {...register("clusterFirmwareVersion", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("clusterFirmwareVersion", e.target.value)}
                  helperText={errors.clusterFirmwareVersion && errors.clusterFirmwareVersion.message}
                  disabled = {true}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              
              <StyledFormControl fullWidth >
                <StyledFormControlLabel>
                Bms Firmware Version
                </StyledFormControlLabel>
                <StyledTextField
                  fullWidth
                  size="small"
                  error={errors.bmsfirmwareVersion && false}
                  {...register("bmsfirmwareVersion", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("bmsfirmwareVersion", e.target.value)}
                  helperText={errors.bmsfirmwareVersion && errors.bmsfirmwareVersion.message}
                  disabled = {true}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
              
              <StyledFormControl fullWidth >
                <StyledFormControlLabel>
                Bms Hardware Version
                </StyledFormControlLabel>
                <StyledTextField
                  fullWidth
                  size="small"
                  error={errors.bmshardwareVersion && false}
                  {...register("bmshardwareVersion", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("bmshardwareVersion", e.target.value)}
                  helperText={errors.bmshardwareVersion && errors.bmshardwareVersion.message}
                  disabled = {true}
                />
              </StyledFormControl>
            </Grid>
            </>
              :
              (resellerId === 110224 && vehToEdit !== null)?
              <>
              <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>
                  Vehicle Category
                </StyledFormControlLabel>
                <StyledDropDown
                  {...register("vehicleCategory", {
                    required: {
                      value: false,
                      message: "Required Field",
                    },
                  })}
                  onChange={(e) => {
                    setValue("vehicleCategory", e.target.value);
                  }}
                  defaultValue={
                    vehToEdit !== null && vehToEdit?.vehicleCategory !== null ?  vehToEdit?.vehicleCategory :  "null"
                  }
                  size="small"
                  disabled = {clickedval}
                >
                  <MenuItem value="null">Select</MenuItem>
                  {vehCategory.length > 0 &&
                    vehCategory.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
              </StyledFormControl>
            </Grid>
             <Grid item lg={4}>
             <StyledFormControl fullWidth>
               <StyledFormControlLabel>
                Offline Reasons
               </StyledFormControlLabel>
               <StyledDropDown
                 {...register("offlineReasons", {
                   required: {
                     value: false,
                     message: "Required Field",
                   },
                 })}
                 onChange={(e) => {
                   setValue("offlineReasons", e.target.value);
                 }}
                 defaultValue={
                   vehToEdit !== null && vehToEdit?.offlineReasons !== null ?  vehToEdit?.offlineReasons :  "0"
                 }
                 size="small"
                 disabled = {clickedval}
               >
                 <MenuItem value="0">None</MenuItem>
                 {vehReason.length > 0 &&
                   vehReason.map((option, index) => {
                     return (
                       <MenuItem key={index} value={option.value}>
                         {option.title}
                       </MenuItem>
                     );
                   })}
               </StyledDropDown>
             </StyledFormControl>
           </Grid>
           </>
              :
           (resellerId === 109951 || resellerId === 109936) &&
               <Grid item lg={4}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>
                  Financier
                </StyledFormControlLabel>
                <StyledDropDown
                  {...register("financier", {
                    required: {
                      value: false,
                      message: "Required Field",
                    },
                  })}
                  onChange={(e) => {
                    setValue("financier", e.target.value);
                  }}
                  defaultValue={
                    vehToEdit !== null && vehToEdit?.financier !== null ?  vehToEdit?.financier :  "null"
                  }
                  size="small"
                  disabled = {clickedval}
                >
                  <MenuItem value="null">Select</MenuItem>
                  {financierList.length > 0 &&
                    financierList.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
              </StyledFormControl>
            </Grid>
        }
            </Grid>

            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Manufacture Date
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={manDate}
                      onChange={handleManDateChange}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          size="small"
                          {...params}
                          error={errors.manufacturedDate && true}
                          {...register("manufacturedDate")}
                          helperText={
                            errors.manufacturedDate &&
                            errors.manufacturedDate.message
                          }
                          disabled = {clickedval}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Dispatch Date 
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={disDate}
                      onChange={handleDisDateChange}
                      disabled = { clickedval}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          size="small"
                          {...params}
                          error={errors.dispatchDate && true}
                          {...register("dispatchDate")}
                          helperText={
                            errors.dispatchDate && errors.dispatchDate.message
                          }
                          disabled = {clickedval}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Registration Date
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={regDate}
                      onChange={handleRegDateChange}
                      disabled = { clickedval}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          size="small"
                          {...params}
                          error={errors.registrationDate && true}
                          {...register("registrationDate")}
                          helperText={
                            errors.registrationDate &&
                            errors.registrationDate.message
                          }
                          disabled = {clickedval}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </StyledFormControl>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCardShadow>
        <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
          <CardHeader
            title={
              <StyledTableTitle>Device & License Key Details</StyledTableTitle>
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                {vehToEdit === null ? (
                  <StyledFormControl fullWidth error={errors.deviceImei}>
                    <StyledFormControlLabel required>
                      Device IMEI
                    </StyledFormControlLabel>
                    <StyledDropDown
                      {...register("deviceImei", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                        validate: (fieldValue) => {
                          return fieldValue !== "0" || " Required Field";
                        },
                      })}
                      onChange={(e) => {
                        setValue("deviceImei", e.target.value);
                      }}
                      defaultValue={
                        vehToEdit !== null
                          ? vehToEdit?.deviceId === undefined
                            ? "0"
                            : vehToEdit?.deviceId
                          : "0"
                      }
                      size="small"
                      disabled = {clickedval}
                    >
                      {" "}
                      <StyledFilterSearch
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      <MenuItem value="0">Select</MenuItem>
                      {deviceImeiJSON.length > 0 ? (
                        deviceImeiJSON.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value="0">NoDataFound</MenuItem>
                      )}
                    </StyledDropDown>
                    {errors.deviceImei && (
                      <FormHelperText>
                        {errors.deviceImei.message}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                ) : (
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Device IMEI
                    </StyledFormControlLabel>
                    <StyledTextField
                      error={errors.deviceImei && true}
                      size="small"
                      fullWidth
                      {...register("deviceImei", {
                        required: {
                          value: true,
                          message: "Required Field ",
                        },
                      })}
                      onChange={(e) => setValue("deviceImei", e.target.value)}
                      helperText={
                        errors.deviceImei && errors.deviceImei.message
                      }
                      disabled={true}
                    />
                  </StyledFormControl>
                )}
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth error={errors.listofPlans}>
                  <StyledFormControlLabel required>Plan</StyledFormControlLabel>
                  <StyledDropDown
                    {...register("listofPlans", {
                      required: {
                        value: vehToEdit !== null ? false:true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("listofPlans", e.target.value);
                    }}
                    defaultValue={
                      vehToEdit !== null ? vehToEdit.listofPlans : "0"
                    }
                    size="small"
                    disabled = {vehToEdit !== null ? true : clickedval}
                    value={watch('listofPlans')}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {planList.length > 0 &&
                      planList.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.listofPlans && (
                    <FormHelperText>
                      {errors.listofPlans.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Plan Activation Date
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={date}
                      onChange={handleDateChange}
                      disabled = {vehToEdit !== null ? true : clickedval}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          size="small"
                          {...params}
                          error={errors.licenseActivationDate && true}
                          {...register("licenseActivationDate")}
                          helperText={
                            errors.licenseActivationDate &&
                            errors.licenseActivationDate.message
                          }
                          disabled = {vehToEdit !== null ? true : clickedval}
                          
                        />
                      )}
                    />
                  </LocalizationProvider>
                </StyledFormControl>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCardShadow>
        <CardActions>
          <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
            <SoftButton size="small" onClick={handleCancelClick}  className="error"  color="error">
              Cancel
            </SoftButton>
          {!clickedval &&  <SoftButton size="small" className="success"  color="success" type="submit">
              {vehToEdit ? "Update" : "Submit"}
            </SoftButton> }
          </StyledFormButtonsRow>
        </CardActions>
       {circularOpen && <BackDropLoader />}
      </form>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          handleSbmtClose={handleSbmtClose}
          dialogText={dialogContent.dialogText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          handleSbmtClose={handleSbmtClose}
          dialogText={dialogContent.dialogText}
          handleClearCls={handleClearCls}
        />
      )}
    </Box>
  );
}

export default CreateVehicleForm;
