import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  Box,
  Stack,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import helpDeskServices from "../../services/helpDeskServices";
import dropdownServices from "../../services/dropdownServices";
import { ExpandMore } from "@mui/icons-material";
import { SoftButton, StyledAccordion, StyledFormControlLabel } from "../../theme/StyledEle";
import { IoSearchOutline } from "react-icons/io5";
import { MdKeyboardDoubleArrowDown } from 'react-icons/md' 
import { useTheme } from "@mui/material";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function SimplePaper({ cancel, fncallback, searchFilter, filterReset }) {
  const [respData, setRespData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [type, setType] = useState([]);
  const [servity, setServity] = useState([]);
  const [status, setStatus] = useState([]);
  const [AssingedTo, setAssignedTo] = useState([]);
  const [categorySearch, setCategorySearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [servitySearch, setServitySearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [customerSerach, setCustomerSearch] = useState("");
  const [assignedSearch, setAssignedSearch] = useState("");
  const [gridListData, setGridListData] = useState([]);
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [selectedSerevityArr, setSelectedSerevityArr] = useState([]);
  const [selectedStatusArr, setSelectedStatusArr] = useState([]);
  const [selectedTypeArr, setSelectedTypeArr] = useState([]);
  const [selectedCustomerArr, setSelectedCustomerArr] = useState([]);
  const [selectedAssignArr, setSelectedAssignArr] = useState([]);

  var globalOrgId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalOrgType = 1;
  var globaluserId = "";
  var globalsubOrgId = 0;
  var globalresellerId = 0;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globalRoleId = globalJson.roleId;
  globaluserId = globalJson.id;
  globalsubOrgId = 2;
  globalresellerId = globalJson.resellerId;

  const theme = useTheme();

  const StyledBox = styled(Box)(() => ({
    maxHeight: "100%",
    overflow: "auto",
    paddingLeft: 20,
    scrollbarWidth: "thin",
    height: "180px",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }));

  useEffect(async () => {
    const responseData = (await helpDeskServices.listcategory({})).data.data;
    setRespData(responseData);
    setSelectedCategory(responseData.category);
    setType(responseData.type);
    setServity(responseData.servity);
    setStatus(responseData.status);
    const customerListPayload = {
      value: "customer",
      resellerId: globalresellerId,
      dealerId: 0,
      searchText: "",
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customerListPayload
    );
    const { content: customerList } = customerListResponse.data;
    setCustomers(customerList);

    var request = {};
    request.orgId = globalJson.orgId;
    request.orgType = globalJson.userOrgType;
    request.subOrgType =
      globalJson.globalsubOrgType != null ? globalJson.globalsubOrgType : 0;

    try {
      const responseDataOrgUer = (await helpDeskServices.listOrgUsers(request))
        .data;
      setAssignedTo(responseDataOrgUer.dataresult);
    } catch (error) {}
  }, []);
  const displayedCategory = useMemo(
    () =>
      selectedCategory.filter(({ value }) =>
        containsText(value, categorySearch)
      ),
    [categorySearch]
  );
  const displayedType = useMemo(
    () => type.filter(({ value }) => containsText(value, typeSearch)),
    [typeSearch]
  );
  const displayedServity = useMemo(
    () => servity.filter(({ value }) => containsText(value, servitySearch)),
    [servitySearch]
  );
  const displayedStatus = useMemo(
    () => status.filter(({ value }) => containsText(value, statusSearch)),
    [statusSearch]
  );
  const displayedCustomer = useMemo(
    () => customers.filter(({ value }) => containsText(value, customerSerach)),
    [customerSerach]
  );
  const displayedAssigned = useMemo(
    () =>
      AssingedTo.filter(({ username }) =>
        containsText(username, assignedSearch)
      ),
    [assignedSearch]
  );
  const listticketdet = async () => {
    var request = {};
    request.categoryList = selectedCategoryArr;
    request.serevityList = selectedSerevityArr;
    request.statusList = selectedStatusArr;
    request.typeList = selectedTypeArr;
    request.customerList = selectedCustomerArr;
    request.AssignList = selectedAssignArr;
    //fncallback(request);
    searchFilter(request);
  };

  const resetFilter = () => {
    setSelectedCategoryArr([]);
    setSelectedSerevityArr([]);
    setSelectedStatusArr([]);
    setSelectedTypeArr([]);
    setSelectedCustomerArr([]);
    setSelectedAssignArr([]);
  }

  useEffect(() => {
    resetFilter();
  },[filterReset])

  const filterFunctionCategory = (e) => {
    let arrayval = [...selectedCategoryArr];
    if (e.target.checked === true) {
      arrayval.push(e.target.value);
      //array.push(e.target.value);
      setSelectedCategoryArr(arrayval);
    } else {
      let filtered = arrayval.filter((index) => {
        return index !== e.target.value;
      });
      setSelectedCategoryArr(filtered);
    }
  };
  const filterFunctionSerevity = (e) => {
    let arrayval = [...selectedSerevityArr];
    if (e.target.checked === true) {
      arrayval.push(e.target.value);
      //array.push(e.target.value);
      setSelectedSerevityArr(arrayval);
    } else {
      let filtered = arrayval.filter((index) => {
        return index !== e.target.value;
      });
      setSelectedSerevityArr(filtered);
    }
  };
  const filterFunctionStatus = (e) => {
    let arrayval = [...selectedStatusArr];
    if (e.target.checked === true) {
      arrayval.push(e.target.value);
      //array.push(e.target.value);
      setSelectedStatusArr(arrayval);
    } else {
      let filtered = arrayval.filter((index) => {
        return index !== e.target.value;
      });
      setSelectedStatusArr(filtered);
    }
  };
  const filterFunctionType = (e) => {
    let arrayval = [...selectedTypeArr];
    if (e.target.checked === true) {
      arrayval.push(e.target.value);
      //array.push(e.target.value);
      setSelectedTypeArr(arrayval);
    } else {
      let filtered = arrayval.filter((index) => {

        return index !== e.target.value;
      });
      setSelectedTypeArr(filtered);
    }
  };
  const filterFunctionCustomer = (e) => {
    let arrayval = [...selectedCustomerArr];

    if (e.target.checked === true) {
      arrayval.push(e.target.value);
      //array.push(e.target.value);
      setSelectedCustomerArr(arrayval);
    } else {
      let filtered = arrayval.filter((index) => {
        return index !== e.target.value;
      });
      setSelectedCustomerArr(filtered);
    }
  };
  const filterFunctionAssign = (e) => {
    let arrayval = [...selectedAssignArr];
    if (e.target.checked === true) {
      arrayval.push(e.target.value);
      //array.push(e.target.value);
      setSelectedAssignArr(arrayval);
    } else {
      let filtered = arrayval.filter((index) => {
        return index !== e.target.value;
      });
      setSelectedAssignArr(filtered);
    }
  };


  const [allExpand, setAllExpand] = useState(true);

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Grid container lg={12} columnGap={1}>
          <Grid item lg={1.5} md={2} sm={6} sx={{background:theme.palette.background.paper}}>
            <StyledAccordion expanded={allExpand} className="fixedHeigth"  onChange={(event, expanded) => setAllExpand(expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Category
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ px: 1 }}>
              {/* <TextField
                size="small"
                id="standard-bare"
                placeholder="Search Category"
                onChange={(e) => setCategorySearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoSearchOutline />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
                fullWidth
              /> */}
              <List dense={true}>
                {displayedCategory.length > 0
                  ? displayedCategory.map((category) => (
                      <ListItemButton disableGutters sx={{ p: 0 }} dense={true}>
                        <ListItemIcon>
                          <Checkbox
                            size="small"
                            name="category"
                            value={category.value}
                            checked={
                              selectedCategoryArr.length > 0
                                ? selectedCategoryArr.indexOf(category.value) > -1
                                  ? true
                                  : false
                                : false
                            }
                            onChange={(e) => filterFunctionCategory(e)}
                          />
                        </ListItemIcon>
                        <ListItemText>{category.value}</ListItemText>
                      </ListItemButton>
                    ))
                  : selectedCategory.map((category) => (
                      <ListItemButton disableGutters sx={{ p: 0 }}>
                        <ListItemIcon>
                          <Checkbox
                            size="small"
                            name="category"
                            value={category.value}
                            checked={
                              selectedCategoryArr.length > 0
                                ? selectedCategoryArr.indexOf(category.value) > -1
                                  ? true
                                  : false
                                : false
                            }
                            onChange={(e) => filterFunctionCategory(e)}
                          />
                        </ListItemIcon>
                        <ListItemText>{category.value}</ListItemText>
                      </ListItemButton>
                    ))}
              </List>
            </Box>
          </AccordionDetails>
            </StyledAccordion>
          </Grid>
          <Grid item lg={1.5}  md={2} sm={6} sx={{background:theme.palette.background.paper}}>
              {/* Sevirity */}
              <StyledAccordion expanded={allExpand} className="fixedHeigth"  onChange={(event, expanded) => setAllExpand(expanded)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Severity
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ px: 1 }}>
                    {/* <TextField
                      size="small"
                      id="standard-bare"
                      placeholder="Search Category"
                      onChange={(e) => setServitySearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IoSearchOutline />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                      fullWidth
                    /> */}
                    <List dense={true}>
                      {displayedServity.length > 0
                        ? displayedServity.map((servity) => (
                            <ListItemButton disableGutters sx={{ p: 0 }}>
                              <ListItemIcon>
                                <Checkbox
                                  size="small"
                                  value={servity.value}
                                  checked={
                                    selectedSerevityArr.length > 0
                                      ? selectedSerevityArr.indexOf(servity.value) > -1
                                        ? true
                                        : false
                                      : false
                                  }
                                  onChange={(e) => filterFunctionSerevity(e)}
                                />
                              </ListItemIcon>
                              <ListItemText>{servity.value}</ListItemText>
                            </ListItemButton>
                          ))
                        : servity.map((servity) => (
                            <ListItemButton disableGutters sx={{ p: 0 }}>
                              <ListItemIcon>
                                <Checkbox
                                  size="small"
                                  value={servity.value}
                                  checked={
                                    selectedSerevityArr.length > 0
                                      ? selectedSerevityArr.indexOf(servity.value) > -1
                                        ? true
                                        : false
                                      : false
                                  }
                                  onChange={(e) => filterFunctionSerevity(e)}
                                />
                              </ListItemIcon>
                              <ListItemText>{servity.value}</ListItemText>
                            </ListItemButton>
                          ))}
                    </List>
                  </Box>
                </AccordionDetails>
              </StyledAccordion>
          </Grid>
          <Grid item lg={1.5}  md={2} sm={6} sx={{background:theme.palette.background.paper,}}>
              {/* Status */}
              <StyledAccordion expanded={allExpand} className="fixedHeigth" onChange={(event, expanded) => setAllExpand(expanded)}>
              <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              >
              Status
              </AccordionSummary>
              <AccordionDetails sx={{overflowY:'scroll !important'}}>
              <Box sx={{ px: 1,height:'100%'}}>
              {/* <TextField
              size="small"
              id="standard-bare"
              placeholder="Search Status"
              onChange={(e) => setStatusSearch(e.target.value)}
              InputProps={{
              startAdornment: (
              <InputAdornment position="start">
              <IoSearchOutline />
              </InputAdornment>
              ),
              }}
              onKeyDown={(e) => {
              if (e.key !== "Escape") {
              e.stopPropagation();
              }
              }}
              fullWidth
              /> */}
              <List dense={true}>
              {displayedStatus.length > 0
              ? displayedStatus.map((status) => (
              <ListItemButton disableGutters sx={{ p: 0 }}>
                <ListItemIcon>
                  <Checkbox
                    size="small"
                    value={status.value}
                    checked={
                      selectedStatusArr.length > 0
                        ? selectedStatusArr.indexOf(status.value) > -1
                          ? true
                          : false
                        : false
                    }
                    onChange={(e) => filterFunctionStatus(e)}
                  />
                </ListItemIcon>
                <ListItemText>{status.value}</ListItemText>
              </ListItemButton>
              ))
              : status.map((status) => (
              <ListItemButton disableGutters sx={{ p: 0 }}>
                <ListItemIcon>
                  <Checkbox
                    size="small"
                    value={status.value}
                    checked={
                      selectedStatusArr.length > 0
                        ? selectedStatusArr.indexOf(status.value) > -1
                          ? true
                          : false
                        : false
                    }
                    onChange={(e) => filterFunctionStatus(e)}
                  />
                </ListItemIcon>
                <ListItemText>{status.value}</ListItemText>
              </ListItemButton>
              ))}
              </List>
              </Box>
              </AccordionDetails>
              </StyledAccordion>
          </Grid>
          <Grid item lg={1.5}  md={2} sm={6} sx={{background:theme.palette.background.paper}}>
                  
      {/* Type */}
      <StyledAccordion expanded={allExpand} className="fixedHeigth" onChange={(event, expanded) => setAllExpand(expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Type
        </AccordionSummary>
        <AccordionDetails sx={{height:'100%', overflowY:'scroll !important'}}>
          <Box sx={{ px: 1 }}>
            {/* <TextField
              size="small"
              id="standard-bare"
              placeholder="Search Status"
              onChange={(e) => setTypeSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IoSearchOutline />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
              fullWidth
            /> */}
            <List dense={true}>
              {displayedType.length > 0
                ? displayedType.map((type) => (
                    <ListItemButton disableGutters sx={{ p: 0 }}>
                      <ListItemIcon>
                        <Checkbox
                          size="small"
                          value={type.value}
                          checked={
                            selectedTypeArr.length > 0
                              ? selectedTypeArr.indexOf(type.value) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionType(e)}
                        />
                      </ListItemIcon>
                      <ListItemText>{type.value}</ListItemText>
                    </ListItemButton>
                  ))
                : type.map((type) => (
                    <ListItemButton disableGutters sx={{ p: 0 }}>
                      <ListItemIcon>
                        <Checkbox
                          size="small"
                          value={type.value}
                          checked={
                            selectedTypeArr.length > 0
                              ? selectedTypeArr.indexOf(type.value) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionType(e)}
                        />
                      </ListItemIcon>
                      <ListItemText>{type.value}</ListItemText>
                    </ListItemButton>
                  ))}
            </List>
          </Box>
        </AccordionDetails>
      </StyledAccordion>
          </Grid>
          <Grid item lg={1.5}  md={2} sm={6} sx={{background:theme.palette.background.paper}}>
                  {/* Customer*/}
      <StyledAccordion expanded={allExpand} className="fixedHeigth"  onChange={(event, expanded) => setAllExpand(expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Customers
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ px: 1 }}>
            {/* <TextField
              size="small"
              id="standard-bare"
              placeholder="Search Customer"
              onChange={(e) => setCustomerSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IoSearchOutline />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
              fullWidth
            /> */}
            <List dense={true}>
              {displayedCustomer.length > 0
                ? displayedCustomer.map((customer) => (
                    <ListItemButton disableGutters sx={{ p: 0 }}>
                      <ListItemIcon>
                        <Checkbox
                          size="small"
                          value={customer.key}
                          checked={
                            selectedCustomerArr.length > 0
                              ? selectedCustomerArr.indexOf(customer.key) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionCustomer(e)}
                        />
                      </ListItemIcon>
                      <ListItemText>{customer.value}</ListItemText>
                    </ListItemButton>
                  ))
                : customers.map((customer) => (
                    <ListItemButton disableGutters sx={{ p: 0 }}>
                      <ListItemIcon>
                        <Checkbox
                          size="small"
                          value={customer.key}
                          checked={
                            selectedCustomerArr.length > 0
                              ? selectedCustomerArr.indexOf(customer.key) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionCustomer(e)}
                        />
                      </ListItemIcon>
                      <ListItemText>{customer.value}</ListItemText>
                    </ListItemButton>
                  ))}
            </List>
          </Box>
        </AccordionDetails>
      </StyledAccordion>
          </Grid>
          <Grid item lg={2}  md={2} sm={6} sx={{background:theme.palette.background.paper}}>
              {/* Assign to */}
      <StyledAccordion expanded={allExpand} className="fixedHeigth"  onChange={(event, expanded) => setAllExpand(expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Assign To
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ p: 1 }}>
            {/* <TextField
              size="small"
              id="standard-bare"
              placeholder="Search Assignee"
              onChange={(e) => setAssignedSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IoSearchOutline />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
              fullWidth
            /> */}
            <List dense={true}>
              {displayedAssigned.length > 0
                ? displayedAssigned.map((assign) => (
                    <ListItemButton disableGutters sx={{ p: 0 }}>
                      <ListItemIcon>
                        <Checkbox
                          size="small"
                          value={assign.userid}
                          checked={
                            selectedAssignArr.length > 0
                              ? selectedAssignArr.indexOf(assign.userid) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionAssign(e)}
                        />
                      </ListItemIcon>
                      <ListItemText>{assign.username}</ListItemText>
                    </ListItemButton>
                  ))
                : AssingedTo.map((assign) => (
                    <ListItemButton disableGutters sx={{ p: 0 }}>
                      <ListItemIcon>
                        <Checkbox
                          size="small"
                          value={assign.userid}
                          checked={
                            selectedAssignArr.length > 0
                              ? selectedAssignArr.indexOf(assign.userid) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionAssign(e)}
                        />
                      </ListItemIcon>
                      <ListItemText>{assign.username}</ListItemText>
                    </ListItemButton>
                  ))}
            </List>
          </Box>
        </AccordionDetails>
      </StyledAccordion>
          </Grid>
      </Grid>

          {/* Button */}
              <Stack direction={'row'} spacing={2} justifyContent={'flex-start'} sx={{px:0, py:1}}>
                  <SoftButton variant="contained" color="success" className="success" size="small"  onClick={listticketdet} >Search</SoftButton>
                  <SoftButton variant="contained" color="error" className="error" size="small" onClick={resetFilter} >Reset</SoftButton>
              </Stack>
 

      




  

    

      {/* <Paper
        sx={{ position: "sticky", bottom: "0px", px: 1, py: 2 }}
        elevation={0}
      >
        <Stack direction="row" spacing={2}>
          <Button variant="contained" size="small" onClick={listticketdet}>
            Go
          </Button>
          <Button variant="outlined" onClick={handleClear}>
            Reset
          </Button>
        </Stack>
      </Paper> */}

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
        sx={{ display: "none" }}
      >
        <Grid item>
          <Card sx={{ width: "auto", maxWidth: 180, height: 230 }}>
            <CardContent>
              <TextField
                size="small"
                id="standard-bare"
                variant="standard"
                placeholder="Assigned To"
                sx={{ display: "flex", textAlign: "left" }}
                onChange={(e) => setAssignedSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon width="50%" />
                    </InputAdornment>
                  ),
                  style: {
                    width: "15ch",
                    fontSize: "14px",
                  },
                }}
              />
            </CardContent>

            <StyledBox>
              {displayedAssigned.length > 0 &&
                displayedAssigned.map((assign) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          value={assign.userid}
                          checked={
                            selectedAssignArr.length > 0
                              ? selectedAssignArr.indexOf(assign.userid) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionAssign(e)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 15,
                              borderRadius: 20,
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: "12px" }}>
                          {assign.username}
                        </Typography>
                      }
                    />
                  </FormGroup>
                ))}
              {displayedAssigned.length === 0 &&
                AssingedTo.map((assign) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          value={assign.userid}
                          checked={
                            selectedAssignArr.length > 0
                              ? selectedAssignArr.indexOf(assign.userid) > -1
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) => filterFunctionAssign(e)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 15,
                              borderRadius: 20,
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: "12px" }}>
                          {assign.username}
                        </Typography>
                      }
                    />
                  </FormGroup>
                ))}
            </StyledBox>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
