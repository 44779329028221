import React, { useEffect, useState } from "react";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import {
  useGetDropdownListMutation,
} from "../../store/slice/ApiSlice";
import { useLayoutEffect } from "react";

function RolesettinsDropdown(props) {
    const {
        getData,
      } = props;
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;

 //Is show all
 const [allOption, setAllOption] = useState(true);

 // All service -------------------------------------------------------------------
 const [
   getDealerList,
   { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
 ] = useGetDropdownListMutation();
 const [
   getCustomerList,
   {
     data: customerdata,
     isLoading: customerLoading,
     isSuccess: customerSuccess,
   },
 ] = useGetDropdownListMutation();
 const [
   getFleetList,
   { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
 ] = useGetDropdownListMutation();
 const [getOrgnizatioList, { data: organizationListData,isSuccess: organizationListSuccess  }] =
 useGetDropdownListMutation();
const [getOrgList, { data: orgListData, isSuccess: orgListSuccess }] =
 useGetDropdownListMutation();

 const [
    getVendorList,
    { data: vendordata, isLoading: vendorLoading, isSuccess: vendorSuccess },
  ] = useGetDropdownListMutation();
  const [
    getRoleList,
    { data: roledata, isLoading: roleLoading, isSuccess: roleSuccess },
  ] = useGetDropdownListMutation();
  const [
    getSystemList,
    { data: systemdata, isLoading: systemLoading, isSuccess: systemSuccess },
  ] = useGetDropdownListMutation();
  //All States ---------------------------------------------------------------------
 
  const [dealer, setDealer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fleet, setFleet] = useState(null);
  const [orgType, setOrgType] = useState("2");
  const [orgnization, setOrgnization] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [role, setRole] = useState(null);
  const [system, setSystem] = useState(null);

 

  // All State API Responce List
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [OrganizationList, setOrganizationList] = useState(null);
  const [OrgTypeList, setOrgTypeList] = useState(null);
  const [vendorList, setVendorList] = useState(null);
  const [roleList, setRoleList] = useState(null);
  const [systemList, setSystemList] = useState(null);

    //Onload set
    const [load, setLoad] = useState(false);

  let orgPaylad = {
    value: "reseller",
    orgType: "2",
    resellerId: 0,
    dealerId: 0,
    roleId: 1,
    id: 0,
    searchText: "",
  };
  //Login Implemented
  //Based Login Implemented
  const RoleDropdown = () => {
    switch (userOrgType) {
      case 8:
        getOrgnizatioList(orgPaylad);;
    }
  };
  let CommonPayload = {
    resellerId: resellerId,
  };
  let vendorPayload = {
    id: orgId,
    value: "vendor",
    orgType: userOrgType,
    searchText: "",
  };


  let roleListPayload = {
    value: "role",
    fleetId: orgId,
    dealerId: 0,
    searchText: "",
    orgType: orgType,
  };

  let systemListPayload = {
    value: "system",
    resellerId: orgId,
    dealerId: 0,
    searchText: "",
  };
  useEffect(() => {
    if (allOption) {
        setOrgnization("-1");
      }
    RoleDropdown();
    getVendorList(vendorPayload);
    getRoleList(roleListPayload);
    getSystemList(systemListPayload);
  }, []);

   //Load Orgnization ------------------------------------------------------------------

  useEffect(() => {
   
    if (orgListSuccess && userOrgType === 8) {
      setOrganizationList(orgListData?.data)
    }
  }, [orgListSuccess]);

  //Load OrgType------------------------------------------------------------------

  useEffect(() => {
   
    if (organizationListSuccess) {
        const typeList = organizationListData?.data?.content?.filter((id) => id.key !== "4");
      setOrgTypeList({ content:typeList });
       
    }
  }, [organizationListSuccess]);

  useEffect(() => {
   
    if (roleSuccess) {
        setRoleList(roledata?.data)
        setRole(roledata?.data?.content[0]?.key)
    }
  }, [roleSuccess]);

  //Handle Get Dealer ------------------------------------------------------------
  const getDealer = (orgId) => {
    setOrgnization(orgId);
    if (orgId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.resellerId = orgId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);

    } else {
      setDealer("-1");
      setDealerList(null);
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);

    }
  };

  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
    }
  }, [dealerSuccess]);

  const selectDealer = (orgId) =>{
    setDealer(orgId);
  }
 //Handle Get Customer -----------------------------------------------------------
 const getCustomer = (orgId) => {
     setOrgnization(orgId);
    if (orgId !== "-1") {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = 0;
      CommonPayload.searchText = "";
      CommonPayload.resellerId = orgId;
      getCustomerList(CommonPayload);
     
    } else {
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);
    }
  };

  useLayoutEffect(() => {
    if (customerSuccess) {
        setCustomerList(customerdata?.data);
      }   
  }, [customerSuccess]);
 //Handle Get Fleet -------------------------------------------------------------
 const getFleet = (customerId) => {
    setCustomer(customerId);
    if (customerId !== "-1") {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      CommonPayload.customerId = customerId;
      CommonPayload.searchText = "";
      CommonPayload.resellerId = orgnization;
      getFleetList(CommonPayload);
    } else {
      setFleet("-1");
      setFleetList(null);
    }
  };
  useLayoutEffect(() => {
    if (fleetSuccess) {
      setFleetList(fleetdata?.data);
    }
  }, [fleetSuccess]);


  //Handle fleet Select
  const selectFleet = (fleetId) => {
    setFleet(fleetId);
  };

   //vendor success
   useLayoutEffect(() => {
    if (vendorSuccess) {
      if (vendordata.data !== null) {
      setVendorList(vendordata?.data);
      }
    }
  }, [vendorSuccess]);

  const selectVendor = (vendor) =>{
    setVendor(vendor)
  }

   //system success
   useLayoutEffect(() => {
    if (systemSuccess) {
      if (systemdata.data !== null) {
        setSystemList(systemdata?.data);
      }
    }
  }, [systemSuccess]);

  const selectSystem = (sys) =>{
    setSystem(sys)
  }

  const selectRole= (role) =>{
    setRole(role)
  }
  //Onsubmit Data
  const onSubmit = () => {
    let formdata = {

      dealer: dealer,
      customer: customer,
      fleet: fleet,
      orgType: orgType,
      orgnization: orgnization,
      role:role,
      vendor:vendor,
      orgId:orgType === "1"?fleet:orgType === "2"?orgnization:orgType === "3"?customer:orgType === "5"?dealer:orgType === "7"?vendor:system

    };
    getData(formdata);
  };
  useEffect(() => {
    if (load) {
      onSubmit();
    }
  }, [load]);


  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
   CommonPayload.resellerId = orgnization;
    if (orgnization !== "-1") {
      getDealerList(CommonPayload);
    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.resellerId = orgnization;
    CommonPayload.dealerId = 0;
    if (orgnization !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };
  const ResellerSearch = (searchText) => {
    CommonPayload.value = "reseller";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.resellerId = 0;
    CommonPayload.orgType = "2";
    CommonPayload.roleId = 1;
    CommonPayload.id = 0;
    if (orgType !== "-1") {
      getOrgList(CommonPayload);
    }
  };
  //search
  const dropDownSearch = (searchText, name) => {
    if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    } else if (name === "Organization") {
      ResellerSearch(searchText);
    }

  };

 //Supre Admin Dropdowns
 const [orgTypeSShow, setOrgTypeSShow] = useState(true); //Super Admin Only
 const [orgSShow, setOrgSShow] = useState(true); //Super Admin Only
 const [dealerShow, setDealerShow] = useState(false);
 const [customerShow, setCustomerShow] = useState(false);
 const [fleetShow, setFleetShow] = useState(false);
 const [systemShow, setSystemShow] = useState(false);
 const [vendorShow, setVendorShow] = useState(false);
 const [roleShow, setRoleShow] = useState(true);




 //onload
 useEffect(() => {
    if (userOrgType === 8) {
      //Org List
      let payload = {
        value: "orgType",
        resellerId: 0,
        dealerId: 0,
        searchText: "",
      };
      getOrgnizatioList(payload);
      let orgPaylad = {
        value: "reseller",
        orgType: "2",
        resellerId: 0,
        dealerId: 0,
        roleId: 1,
        id: 0,
        searchText: "",
      };
      getOrgList(orgPaylad);
    }
    
  }, []);

//Organzation
  //Org type ---------------------------------------------------------------------
  const haldeOrganizationType = (orgTypeId) => {
    setOrgType(orgTypeId);
    setOrgnization("-1")
    setDealer("-1");
    setDealerList(null);
    setCustomer("-1");
    setCustomerList(null);
    setFleet("-1");
    setFleetList(null);
    setVendor("-1")
    setSystem("-1");
    //If Fleet Select
    if (orgTypeId === "1") {
      setCustomerShow(true);
      setFleetShow(true);
      setDealerShow(false);
      setOrgSShow(true);
      setSystemShow(false);
      setVendorShow(false);
      roleListPayload.orgType=orgTypeId;
      getRoleList(roleListPayload)
    } else if (orgTypeId === "3") {
      setDealerShow(false);
      setCustomerShow(true);
      setFleetShow(false);
      setOrgSShow(true);
      setSystemShow(false);
      setVendorShow(false);
      roleListPayload.orgType=orgTypeId;
      getRoleList(roleListPayload)
    } else if (orgTypeId === "5") {
      setCustomerShow(false);
      setFleetShow(false);
      setDealerShow(true);
      setOrgSShow(true);
      setSystemShow(false);
      setVendorShow(false);
      roleListPayload.orgType=orgTypeId;
      getRoleList(roleListPayload)
    } else if (orgTypeId === "2") {
      setDealerShow(false);
      setCustomerShow(false);
      setFleetShow(false);
      setOrgSShow(true);
      setSystemShow(false);
      setVendorShow(false);
      roleListPayload.orgType=orgTypeId;
      getRoleList(roleListPayload)
    } else if ( orgTypeId === "7") {
      setDealerShow(false);
      setCustomerShow(false);
      setFleetShow(false);
      setVendorShow(true);
      setOrgSShow(false);
      setSystemShow(false);
      roleListPayload.orgType=orgTypeId;
      getRoleList(roleListPayload)
    } else if (orgTypeId === "8") {
      setDealerShow(false);
      setCustomerShow(false);
      setFleetShow(false);
      setSystemShow(true);
      setOrgSShow(false);
      setVendorShow(false);
      roleListPayload.orgType=orgTypeId;
      getRoleList(roleListPayload)
    }else {
      setDealerShow(false);
      setCustomerShow(false);
      setFleetShow(false);
    }

  };
  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
      {orgTypeSShow && (
        <SingleDropdown
          label={"Organization Type"}
          listItems={OrgTypeList}
          handleChange={haldeOrganizationType}
          value={orgType}
          isSearch={false}
          showAll={false}
        />
      )}

      {orgSShow && (
        <SingleDropdown
          label={"Organization"}
          listItems={OrganizationList}
          handleChange={orgType === "5"?getDealer:getCustomer}
          value={orgnization}
          isSearch={dropDownSearch}
          showAll={allOption}
        />
      )}

      {dealerShow && (
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerList}
          handleChange={selectDealer}
          value={dealer}
          isSearch={dropDownSearch}
          showAll={allOption}
        />
      )}

      {customerShow && (
        <SingleDropdown
          label={"Customer"}
          listItems={customerList}
          handleChange={getFleet}
          value={customer}
          isSearch={dropDownSearch}
          showAll={ allOption}
        />
      )}

      {fleetShow && (
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetList}
          handleChange={selectFleet}
          value={fleet}
          isSearch={dropDownSearch}
          showAll={allOption}
        />
      )}
 
      {vendorShow && (
        <SingleDropdown
          label={"Vendor"}
          listItems={vendorList}
          handleChange={selectVendor}
          value={vendor}
          isSearch={false}
          showAll={ allOption}
        />
      )} 
      {systemShow && (
        <SingleDropdown
          label={"System"}
          listItems={systemList}
          handleChange={selectSystem}
          value={system}
          isSearch={false}
          showAll={ allOption}
        />
      )} 
       {roleShow && (
        <SingleDropdown
          label={"Role Name"}
          listItems={roleList}
          handleChange={selectRole}
          value={role}
          isSearch={false}
          showAll={ false}
        />
      )} 

      <StyledFiterButton
        type="button"
        variant="contained"
        size="small"
        color="success"
        onClick={onSubmit}
        sx={{ marginTop: "25px" }}
      >
        Submit
      </StyledFiterButton>
    </StyledPaperShadow>
  );

}

export default RolesettinsDropdown;