import Service from "./service";

const driverService = {
  getGridViewForDriver: function (resource) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/driver/listDriver", resource, null);
  },
  saveDriverDetails: function (data) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/driver/createDriver", data, null);
  },
  deleteDriverById: (id) => {
    //console.log('i am here...')
    return Service.post("/driver/deleteDriver", id, null);
  },
  editDriverById: (id) => {
    return Service.post("/driver/getDriver", { id });
  },
  updateDriverDetails: function (data) {
    return Service.post("/driver/updateDriver", data, null);
  },
};

export default driverService;
