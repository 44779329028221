import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import {
  CardContent,
  useTheme,
} from "@mui/material";
import {
  StyledCardShadow, StyledPageLayout,
} from "../../theme/StyledEle";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import {
  useAuditLogMutation,
  useLicenseReportMutation
} from "../../store/slice/ApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { Breadcrumb } from "../shared/Breadcrumbs";

const LicenseReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [commonFilter, setCommonFilter] = useState([]);
  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const columns = useMemo(
    () => [
    
      
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "SIM NO",
        accessor: "simNumber",
      },
      {
        Header: "Device Lot No",
        accessor: "lotNumber",
      },
      {
        Header: "License Key",
        accessor: "licenseKey",
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
      },
      {
        Header: "Dispatch Date",
        accessor: "dispatchDate",
      },
      {
        Header: "License Assigned Date",
        accessor: "createdDate",
      },
      {
        Header: "License Activation Date",
        accessor: "activationDate",
      },
      {
        Header: "License Expiry Date",
        accessor: "expiryDate",
      }
    ],
    []
  );


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };
  

  const [
    getLicenseReportList,
    {
      data: licenseData,
      isLoading: licenseLoading,
      isSuccess: licenseListSuccess,
    },
  ] = useLicenseReportMutation();


  const [payload, setPayload] = useState(null);
  let commonPayload = {};
  //get Data
  const getData = (data) => {
    
    commonPayload.resellerId = resellerId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.dateOnOrbefore = data.createDate;
    commonPayload.lotNumber = data.lotNummber;
    commonPayload.search = "";
    commonPayload.action = data.actionId;
    commonPayload.size = 25;
    commonPayload.page = 0;
    setPayload(commonPayload);
    getLicenseReportList(commonPayload);
    
  };

  useEffect(() => {
    if (licenseListSuccess) {
      setResponseData([]);
      if (licenseData.data !== null) {
        setResponseData(licenseData.data.content);
        setTotalElements(licenseData.data.totalElements);
        setTotalPages(licenseData.data.totalPages);
       
      }
    }
  }, [licenseListSuccess]);


  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getLicenseReportList(payload);
    } else if (payload.search.length === 0) {
      getLicenseReportList(payload);
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getLicenseReportList(payload);
  };

    //get row data from data table
    const getRow = (selectRow, rowId) => {
      setSelectRowId(rowId);
      setSeletedRows(selectRow);
    };


    const onChangeXcl = async () => {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          "LicenseReport/licenseReportXLS.xls?resellerId=" +
          payload.resellerId +
          "&dateOnOrbefore=" +
          payload.dateOnOrbefore +
          "&startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&lotNumber=" +
          payload.lotNumber +
          "&action=" +
          payload.action +
          "&search=" +
          payload.search +
          " ";
        window.open(url);
    
        auditPayload.message = "Admin > License Report > List > Export > XLS";
        auditPayload.action = "Download";
        auditPayload.featureName = "License Report";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.userName = userName;
  
        auditLog(auditPayload);
  
        fetch(url, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          },
        }) // FETCH BLOB FROM IT
          .then((response) => {
            response.blob();
          })
          .then((blob) => {
            if (undefined !== blob) {
              var _url = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              var link = document.body.appendChild(a);
              link.download = "VehicleList";
              link.href = _url;
              link.method = "post";
              link.click();
              window.URL.revokeObjectURL(_url);
              a.removeAttribute("download");
              a.removeAttribute("post");
              a.removeAttribute("href");
              document.body.removeChild(a);
            } else {
              //alert("unAuthorized error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {}
    };
  

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="License"
        includePopup={false}
      ></Breadcrumb>
      <Box>
        <AdminDropdown
          getData={getData}
          createdate={true}
          allowDays={1}
          allowedMonth={3}
          actionShow={true}
        />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              onChangeXcl={onChangeXcl}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={2}
              page={newPage}
              includeSearchBox={true}
              handleChange={handleSearch}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              commonFilter={commonFilter}
              isLoading={licenseLoading}
              selectRow={getRow}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
   </StyledPageLayout>
  );
};

export default LicenseReport;
