import React from "react";
import ReportServices from "../../services/reportServices";
import { Box, alpha, useTheme } from "@mui/material";
import { SoftButton } from "../../theme/StyledEle";

const GetLocation = (props) => {
    const theme = useTheme();
    const{row,columnName} = props;

const refAddres = React.useRef(row);
var refLink = React.useRef(row);
var getAddressBtn = React.useRef(row);
var toggleAddressBtn = React.useRef(row);
const getAddress = async (e) => {
  if (refAddres.current.innerText === "") {
    var request = {};
    request.latLng = e.target.value;
    // rowid = e.target.id;
    const responseData = (
      await ReportServices.getGeocodeAddress(request)
    ).data;
    refAddres.current.innerText = responseData;
    if (responseData !== "") {
      refLink.current.style.display = "none";
      refAddres.current.style.display = "block";
      toggleAddressBtn.current.style.display = "block";
      getAddressBtn.current.style.display = "none";
      toggleAddressBtn.current.innerText = "LatLng";
    }
  }
};

//Address toggle
const ToggleAdderss = () => {
  if (refAddres.current.style.display === "block") {
    refAddres.current.style.display = "none";
    refLink.current.style.display = "block";
    toggleAddressBtn.current.innerText = "Address";
  } else {
    refAddres.current.style.display = "block";
    refLink.current.style.display = "none";
    toggleAddressBtn.current.innerText = "LatLng";
  }
};
return (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box
      className="addresslink"
      id={columnName}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <SoftButton
        onClick={(e) => getAddress(e)}
        value={columnName}
        ref={getAddressBtn}
        sx={{background:alpha(theme.palette.primary.main,0.1) , borderRadius: "5px",color:alpha(theme.palette.primary.main, 1)}}
        size="small"
      >
        Address
      </SoftButton>
      <SoftButton
        onClick={(e) => ToggleAdderss(e)}
        ref={toggleAddressBtn}
        sx={{ display: "none", background:alpha(theme.palette.primary.main,0.1) , borderRadius: "5px",color:alpha(theme.palette.primary.main, 1)}}
        size="small"
      >
        LatLng
      </SoftButton>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${columnName}`}
        target="_blank"
        className="latlink"
        ref={refLink}
        id={columnName}
      >
        {columnName}
      </a>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${columnName}`}
        target="_blank"
        className="address"
        ref={refAddres}
        id={columnName}
        key={columnName}
      ></a>
    </Box>
  </Box>
);
}


export default GetLocation;