import { dashboardApi } from "../ApiSlice";

const AlertSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        viewAlerts: builder.mutation({
            query: (body) => ({
              url: `/Alerts/getReportList`,
              method: "post",
              body: body,
            }),
          }),
        unassignUserList: builder.mutation({
          query: (body) => ({
            url: `Alerts/unassignedUserList`,
            method: "post",
            body: body,
          }),
        }),
        assignUserList: builder.mutation({
          query: (body) => ({
            url: `Alerts/getUserListForVehicle`,
            method: "post",
            body: body,
          }),
        }),
        saveUser: builder.mutation({
          query: (body) => ({
            url: `Alerts/assignUserAlerts`,
            method: "post",
            body: body,
          }),
        }),
        assignUserCall: builder.mutation({
          query: (body) => ({
            url: `Alerts/assignUserAlerts`,
            method: "post",
            body: body,
          }),
        }),

        //Assign Alerts
        getAssignAlertsList: builder.mutation({
          query: (body) => ({
            url: `Alerts/getAlertForOrgNew`, 
            method: "post",
            body: body,
          }),
        }),
        getAssignAlertDetails: builder.mutation({
          query: (body) => ({
            url: `Alerts/getFleetAlertDetails`,
            method: "post",
            body: body,
          }),
        }),
      //Alerts/getDynamicAlerts
      getDymicAlerts: builder.mutation({
        query: (body) => ({
          url: `Alerts/getDynamicAlerts`,
          method: "post",
          body: body,
        }),
      }),
      //Alerts/editAlertSettings
      saveAlerts: builder.mutation({
        query: (body) => ({
          url: `Alerts/editAlertSettings`,
          method: "post",
          body: body,
        }),
      }),
      viewLohumAlerts: builder.mutation({
        query: (body) => ({
          url: `LohumAlertsReport/getLohumAlerts`,
          method: "post",
          body: body,
        }),
      }),
      
    })
})
export const { useViewAlertsMutation, useUnassignUserListMutation, useAssignUserListMutation, useSaveUserMutation, useGetAssignAlertsListMutation, useGetAssignAlertDetailsMutation, useGetDymicAlertsMutation, useSaveAlertsMutation, useAssignUserCallMutation ,useViewLohumAlertsMutation  } = AlertSlice;