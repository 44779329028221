import { useTheme } from "styled-components";
import { alpha, styled } from "@mui/system";
import {
  TableHead,
  TableCell,
  TablePagination,
  TableRow,
  TableContainer,
} from "@mui/material";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight:'calc(100vh - 45vh)',
    '&::-webkit-scrollbar':{
      backgroundColor:theme.palette.divider,
      borderRadius:'50px',
      cursor:'pointer',
      height:'8px',
      width:'8px'
    },
    '& .nestedBody':{
      '& .MuiTableCell-body':{
        background:theme.palette.background.paper
      }
    },
    '&::-webkit-scrollbar-thumb':{
      backgroundColor:theme.palette.grey[400],
      cursor:'pointer',
      height:'8px',
      width:'8px',
      borderRadius:'50px',
    },
    '&.playBackTable':{
      height:'calc(250px - 70px)',
      overflowY:'scroll',
      '&::-webkit-scrollbar':{
        backgroundColor:theme.palette.divider,
        borderRadius:'50px',
        cursor:'pointer',
        width:'8px'
      },
      '&::-webkit-scrollbar-thumb':{
        backgroundColor:theme.palette.primary.main,
        cursor:'pointer',
        width:'2px',
        borderRadius:'50px',
      },
        '& .playBackBody':{
          
        }
    }
}));
export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  width: "auto",
  position: "sticky",
  top: 0,
  zIndex: 33,
  width: "fit-content",
  border: "0px solid darkgrey",
  borderBottom: "none !important",
  '&.nestedHeader':{
    '& .MuiTableCell-root':{
      background:theme.palette.primary.contrastColor,
    }
    
  }
}));

export const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.background.default,
  textAlign: "left !important",
  padding: "8px",
  fontSize: "14px !important",
  paddingLeft: "10px",
  marginBottom:'2px',
  textWrap:'nowrap',
  //flex:'none !important',
  border:`.25px solid ${theme.palette.divider}`,
  '& .MuiTypography-root':{
    fontWeight:600,
    textOverflow:'ellipsis',
    overflow:'hidden',
  },
  [theme.breakpoints.down('xl')]:{
    padding: "2px",
    fontWeight:700,
  },
  
  // boxShadow:'0px 2px 2px #ddd',
  // borderBottom: "none !important",
  "&:last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    //textAlign: "right !important",
  },
  "&:nth-of-child": {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  "& div": {
    lineHeight: "15.2px",
    width: "100%",
  },
  "& div p": {
    width: "max-content",
    [theme.breakpoints.down('xl')]:{
      fontSize:'.65rem !important',
      fontWeight:`700 !important`,
    },
  },
  "& div .sortIcon": {
    opacity: 0,
    pointerEvents: "none",
  },
  "&:hover div .sortIcon": {
    opacity: 1,
    pointerEvents: "all",
  },
  "&[data-sticky-td]":{
    //width:'!important',
    opacity:1,
    minWidth: '150px',
    opacity: 1,
    minWidth: "150px",
    opacity: 1,
    maxWidth: "100%",
    position:'sticky !important',
    zIndex:3333,
    left:'0px !important'
  },
  '&.playBackHeaderCell':{
    //background:'transparent !important',
    borderBottom:"0px solid",
    fontSize: "12px !important",
    '&:last-child':{
      textAlign: "center !important",
    }
  },
  '&:hover':{
    '& .resizer':{
      opacity:.6
    }
  },
  '& .resizer':{
        display: 'inline-block',
        background: theme.palette.divider,
        width: 'auto',
        height: 'auto',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateX(50%) translateY(-50%)',
        zIndex: 99,
        padding:'3px',
        fontSize:'16px',
        borderRadius:'50%',
        touchAction:'none',
        opacity:0,
        '&.isResizing': {
          background: theme.palette.divider,
          opacity:.8,
        }
      }
}));

export const BodyTableCell = styled(TableCell)(({ theme }) => ({
  textWrap:"nowrap !important",
  fontWeight:500,
  textAlign: "left",
  //flex:'none !important',
  fontSize: "14px !important",
  lineHeight: "1.6",
  padding: "2px 5px",
  textWrap:'break-word',
  textOverflow:'ellipsis',
  wordBreak: 'break-all',
  overflow:'hidden',
  //borderColor: theme.palette.divider,
  border:`.25px solid ${theme.palette.divider}`,
  color:`${theme.palette.text.primary} !important`,
  display:'flex',
  alignItems:'baseline',
  justifyContent:'center',
  flexDirection:'column',
  '& .MuiTypography-root':{
    textOverflow:'ellipsis',
    overflow:'hidden',
  },
  "&:last-child": {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderRight:`.5px solid ${theme.palette.divider}`,
  },
  "&:nth-of-child": {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderLeft:`.5px solid ${theme.palette.divider}`,
  },
  '& a':{
    color:theme.palette.text.primary,
    textDecoration:'none'
  },
  '& a:hover':{
    color:theme.palette.primary.main,
    textDecoration:'underline'
  },

  "&[data-sticky-td]":{
    //width:'1 !important',
    opacity:1,
    minWidth: '150px',
    opacity: 1,
    minWidth: "150px",
    opacity: 1,
    maxWidth: "100%",
    background:theme.palette.background.paper,
    position:'sticky !important',
    left:'0px !important',
    zIndex:3333,
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderLeft:`.5px solid ${theme.palette.divider}`,
  },
  '&.playBackBodyCell':{
    fontSize:'12px !important',
    '&:last-child':{
      textAlign:'center !important'
    },
    '& .MuiChip-root':{
      fontSize:'10px !important'
    }
  },
  [theme.breakpoints.down('xl')]:{
    fontSize:'.8rem !important',
    padding:"2px 5px"
  },
 '&.nestedBodyCell':{
    background:theme.palette.primary.main,
 }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  
  // "&:nth-child(even)":{
  //   backgroundColor: theme.palette.action.hover,
  // },
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
  cursor:'pointer',
  margin:'5px 0px',
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
    '& [data-sticky-td]':{
      backgroundColor: theme.palette.grey[100],

    }
  },
}));

export const MuiTablePagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiToolbar-root": {
    minHeight: "0px ! important",
    backgroundColor: theme.palette.mode === 'light' ? '#f2f2f2' : theme.palette.background.default,
    borderRadius:'5px',
  },
  '& p':{
    margin:0
  },
  '& .MuiInputBase-root':{
    margin:0
  },
  '& .MuiToolbar-root':{
    gap:10,
    background:theme.palette.background.default,
    minHeight:'auto !important'
  }
}));
