import React, {useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { StyledCardShadow,
 
    StyledTableTitle,
    StyledFormControl,
    StyledFormControlLabel,
  
    StyledPageLayout,
    StyledCommonListItem,
    SoftButton,
    StyledPaperShadow,
    StyledTextField,
    StyledTimeLineChipLeft} from "../../theme/StyledEle";

  import {
    Grid,
    ListItemButton,
    ListItemIcon,
    List,
    ListItemText,
    Paper,
    Autocomplete,
    Stack,
    CardHeader,
    CardContent,
    CardActions,
    Chip,
    Box,
    Card,
    Checkbox,
    Divider,
    InputBase,
    useTheme,
    Button

  } from "@mui/material";
  import { ScaleLoader } from "react-spinners";
  import { Breadcrumb } from "../shared/Breadcrumbs";
  import { MdAssignmentAdd, MdEditSquare } from "react-icons/md";
  import { FaExchangeAlt } from "react-icons/fa";
 
  import { useNavigate } from "react-router-dom";
  import { useVerifyVehicleMutation, useGetDealerVehicleListMutation } from "../../store/slice/ReportSlice/ReportSlice";
  import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";


  
export default function EOLProcess() {

    const userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let userName = userData.userName;


    const [settings, setSettings] = useState(1);
    //const [checked, setChecked] = React.useState([]);
    const [checked, setChecked] = useState(null);
    const [payload, setPayload] = useState(null);
    const [vehicles, setVehicles] = React.useState([]);
    const [loadStatus, setLoadStatus] = useState("");


      let vehiclePayload = {
        userId:userId,
        userName:userName,
        orgId:orgId,
        page:1,
        size:30,
      };

      let verifyPayload = {
        resellerId: resellerId,
        userId:userId,
        onHoldMessage:"",
        remarks:"",
        
      };

          
      const [
        getVehicleList,
        { data: vehicleData, isLoading: vehLoading, isSuccess: vehSuccess },
      ] = useGetDealerVehicleListMutation();
      const [
        verifyVehicle,
        { data: verifyData, isLoading: verifyLoading, isSuccess: verifySuccess },
      ] = useVerifyVehicleMutation();

      useEffect(() => {
        setSettings(1);
        vehiclePayload.kycStatus = 0;
        vehiclePayload.customerType=1;
        setPayload(vehiclePayload);
        getVehicleList(vehiclePayload);     
        // if(vehicleData!=undefined){
        //     setVehicles(vehicleData.vehicle);
        // }else{
        //     setVehicles([]);
        // }
       
      }, []);

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
      }
      
      function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
      }
      
      function union(a, b) {
        return [...a, ...not(b, a)];
      }



    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSuccess = useSelector((state) => state.controls.alert);

    React.useEffect(() => {
      if (isSuccess.isSuccess) {
        setTimeout(() => dispatch(snackService([null, null, null])), 6000);
      }
    }, [isSuccess.isSuccess]);
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        watch,
      } = useForm({
        mode: "all",
        defaultValues:{
          assignedTo:'-1',
          orgType:'-1',
          category:'-1',
        }
      });

    const handleSettingsTab = (value) => {
        setSettings(value);
        var tabName ="";
        if(value === 1){
           tabName = "Un Assigned";
           navigate('#unassigned');
           vehiclePayload.kycStatus=0;
           vehiclePayload.customerType=1;
           getVehicleList(vehiclePayload);
          //  setVehicles(vehicleData.vehicle);
        }else if(value === 2){
          tabName = "QC OnHold";
          navigate('#qconhold');
          vehiclePayload.kycStatus=0;
          vehiclePayload.customerType=2;
          getVehicleList(vehiclePayload);
          //setVehicles(vehicleData.vehicle);
        }else if(value === 3){
          tabName = "QC Verified";
          navigate('#qcverified');
          vehiclePayload.kycStatus=1;
          vehiclePayload.customerType=3;
          getVehicleList(vehiclePayload);
        }
        setPayload(vehiclePayload);
        
    }

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
          setChecked(not(checked, items));
        } else {
          setChecked(union(checked, items));
        }
      };

    const theme = useTheme();

        const Usertag = ({option, index}) => {
            return(
                <Chip
                variant="outlined"
                label={option.name}
                size="small"
                color="primary"
              />
            )
          }


          const handleToggle = (value) => () => {
            /*
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
            if (currentIndex === -1) {
              newChecked.push(value);
            } else {
              newChecked.splice(currentIndex, 1);
            }*/
              if(checked!==value){
                setChecked(value);
              }else{
                setChecked(null);
              }
            
          };

          const waitTime = 1000;
          let timer;

          const detectTyping = (value) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                searchVehicles(value);
            }, waitTime);
        }

        const searchVehicles = async (value) => {
           payload.searchText=value;
            getVehicleList(payload);
        }

        const resetVehicles = () => {
          setChecked(null);
          };

         

          const handleType = (type) =>{ 
            verifyPayload.submitType = type;
            setLoadStatus(type);
            assignVehicle();
          }
          const assignVehicle = async () => {
            /*
            var vehicleCheck = [];
            vehicleCheck = [...checked];
        
            var vehicleList = "";
            for (var i = 0; i < vehicleCheck.length; i++) {
              if (i === 0) {
                vehicleList = vehicleCheck[i].id;
              } else {
                vehicleList = vehicleList + "," + vehicleCheck[i].id;
              }
            }
              */
            
            
              if(checked!==null){
               verifyPayload.id=checked;
               setChecked(null);
               const responseData = await verifyVehicle(verifyPayload);
               if (responseData.data.status === "success") {
                    setChecked(null);
                 dispatch(snackService([responseData.data.message, 0, true]));
                } else {
                    setChecked(null);
                  dispatch(snackService(["Update Failed", 1, true]));
                }  
  
              }else{
                  setChecked(null);
                dispatch(snackService(["please Select the Vehicle", 1, true]));
               }
            
         

          };

    return (
        <StyledPageLayout>
        <Grid container spacing={1}>
            <Grid item xl={2} lg={3}>
                <Paper elevation={0} sx={{px:1}}>
                <List>
                    <StyledCommonListItem dense={true} >
                        <ListItemButton selected={settings === 1} onClick={() => handleSettingsTab(1)}>
                            <ListItemIcon>
                                <MdAssignmentAdd />
                            </ListItemIcon>
                            <ListItemText>Un Assigned</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                    <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 2} onClick={() => handleSettingsTab(2)} >
                            <ListItemIcon>
                                <FaExchangeAlt />
                            </ListItemIcon>
                            <ListItemText>QC OnHold</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                   { /*
                    <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 3} onClick={() => handleSettingsTab(3)} >
                            <ListItemIcon>
                                <MdEditSquare />
                            </ListItemIcon>
                            <ListItemText>QC Verified</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                   */}
                   
                </List>
                </Paper>
            </Grid>
            <Grid item lg={5}>
                {
                  settings === 1 && 
                 
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <Breadcrumb
                        mainDiv="EOL PROCESS"
                        subDiv="Unassigned"
                      ></Breadcrumb>
                      }
                    />
                    <CardContent sx={{ py: 0 }}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                         <Grid item lg={9}>
                            <StyledFormControl fullWidth>                              
          <Box sx={{ my: 2 }}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item lg={9}>
                <Card elevation={0} sx={{ border: `0.5px solid ${theme.palette.divider}`}}>
                    {
                      <>
                      <CardHeader
                      /*
                        avatar={
                          <Checkbox
                          onClick={handleToggleAll(vehicles)}
                          title="select all"
                          checked={
                          numberOfChecked(vehicles) === vehicles?.length && vehicles?.length !== 0
                          }
                          indeterminate={
                          numberOfChecked(vehicles) !== vehicles?.length &&
                          numberOfChecked(vehicles) !== 0
                          }
                          disabled={vehicles?.length === 0}
                          inputProps={{
                          "aria-label": "all items selected",
                          }}
                          />
                        }*/
                        title={"Vehicles"}
                      //  subheader={`${numberOfChecked(vehicles)}/${vehicles?.length} selected`}
                      />
                       <Divider />
                        <Box sx={{px:2, py:1, background:theme.palette.grey[100]}}>
                            <StyledPaperShadow sx={{px:1, py:.5}}>
                              <InputBase placeholder="Search Vehicle" sx={{width:'100%'}} onKeyUp={(event) => detectTyping(event.target.value, 'unassign')}/>
                            </StyledPaperShadow>
                        </Box>
                        <CardContent
                          sx={{
                          height: "300px",
                          overflowY: "scroll",
                          p: 0,
                          paddingBottom: "0px !important",
                          }}
                        >
                          {
                            vehLoading &&
                            <Box>
                              <ScaleLoader color={theme.palette.secondary.main}/>
                            </Box>
                          }
                        
                        {vehicleData!==undefined && vehicleData.vehicle.length > 0 ? (
                        <List dense role="list" style={{ borderRadius: "5px" }}>
                        {vehicleData.vehicle.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value}-label`;
                        return (
                        <ListItemButton
                        key={index}
                        role="listitem"
                        onClick={handleToggle(value.id)}
                        value={value.id}
                       // selected={checked.indexOf(value) !== -1 && true}
                       selected={value.id === checked}
                        dense
                        >
                        <Checkbox
                        //checked={checked.indexOf(value) !== -1}
                        checked={value.id === checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                        'aria-labelledby': labelId,
                        }}
                        /> 
                        <ListItemText primary={value.name} />
                        </ListItemButton>
                        );
                        })}
                        </List>
                        ) : (
                        <ListItemButton disabled>No Vehicles</ListItemButton>
                        )}
                        </CardContent>
                      </>
                    }
                </Card>
              </Grid>
            </Grid>
          </Box>  
                            </StyledFormControl>
                          </Grid>
                        </Grid>
                    </CardContent>
                    <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ my: 2, mx: 2 }}
                >
                  <SoftButton
                    variant="contained"
                    className="error"
                    color="error"
                    size="small"
                    onClick={resetVehicles}
                  >
                    Reset
                  </SoftButton>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={()=>{handleType("QC_OnHold")}}
                    // disabled={rightChecked.length > 0 ? false : true}
                  >
                    {verifyLoading  && loadStatus === "QC_OnHold"? (
                      <ScaleLoader
                        color={theme.palette.primary.contrastText}
                        height={14}
                      />
                    ) : (
                      "QC OnHold"
                    )}
                    
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={()=>{handleType("QC_Verified")}}
                    // disabled={rightChecked.length > 0 ? false : true}
                  >
                    {verifyLoading && loadStatus === "QC_Verified"? (
                      <ScaleLoader
                        color={theme.palette.primary.contrastText}
                        height={14}
                      />
                    ) : (
                      "QC Verified"
                    )}
                   
                  </Button>
                </Stack>
                  </StyledCardShadow>
                  
                }

{
                  settings === 2 && 
                  
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <Breadcrumb
                        mainDiv="EOL PROCESS"
                        subDiv="QC OnHold"
                      ></Breadcrumb>
                        
                      }
                      
                    />
                    <CardContent sx={{ py: 0 }}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                         <Grid item lg={9}>
                            <StyledFormControl fullWidth>                              
          <Box sx={{ my: 2 }}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item lg={9}>
                <Card elevation={0} sx={{ border: `0.5px solid ${theme.palette.divider}`}}>
                    {
                      <>
                      <CardHeader
                      /*
                        avatar={
                          <Checkbox
                          onClick={handleToggleAll(vehicles)}
                          title="select all"
                          checked={
                          numberOfChecked(vehicles) === vehicles?.length && vehicles?.length !== 0
                          }
                          indeterminate={
                          numberOfChecked(vehicles) !== vehicles?.length &&
                          numberOfChecked(vehicles) !== 0
                          }
                          disabled={vehicles?.length === 0}
                          inputProps={{
                          "aria-label": "all items selected",
                          }}
                          />
                        }
                          */
                        title={"Vehicles"}
                       // subheader={`${numberOfChecked(vehicles)}/${vehicles?.length} selected`}
                      />
                       <Divider />
                        <Box sx={{px:2, py:1, background:theme.palette.grey[100]}}>
                            <StyledPaperShadow sx={{px:1, py:.5}}>
                              <InputBase placeholder="Search Vehicle" sx={{width:'100%'}} onKeyUp={(event) => detectTyping(event.target.value)}/>
                            </StyledPaperShadow>
                        </Box>
                        <CardContent
                          sx={{
                          height: "300px",
                          overflowY: "scroll",
                          p: 0,
                          paddingBottom: "0px !important",
                          }}
                        >
                          
                          {vehLoading &&
                            <Box>
                              <ScaleLoader color={theme.palette.secondary.main}/>
                            </Box>
                          }
                        
                        {vehicleData!==undefined && vehicleData.vehicle.length > 0 ? (
                        <List dense role="list" style={{ borderRadius: "5px" }}>
                        {vehicleData.vehicle.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value}-label`;
                        return (
                        <ListItemButton
                        key={index}
                        role="listitem"
                        onClick={handleToggle(value.id)}
                        value={value.id}
                        //selected={checked.indexOf(value) !== -1 && true}
                        selected={value.id === checked}
                        dense
                        >
                       <Checkbox
                        //checked={checked.indexOf(value) !== -1}
                        checked={value.id === checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                        'aria-labelledby': labelId,
                        }}
                        /> 
                        <ListItemText primary={value.name} />
                        </ListItemButton>
                        );
                        })}
                        </List>
                        ) : (
                        <ListItemButton disabled>No Vehicles</ListItemButton>
                        )}
                        </CardContent>
                      </>
                    }
                </Card>
              </Grid>
            </Grid>
          </Box>  
                            </StyledFormControl>
                          </Grid>
                        </Grid>
                    </CardContent>
                    <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ my: 2, mx: 2 }}
                >
                  <SoftButton
                    variant="contained"
                    className="error"
                    color="error"
                    size="small"
                    onClick={resetVehicles}
                  >
                    Reset
                  </SoftButton>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={()=>{handleType("QC_Verified")}}
                    // disabled={rightChecked.length > 0 ? false : true}
                  >
                    {verifyLoading ? (
                      <ScaleLoader
                        color={theme.palette.primary.contrastText}
                        height={14}
                      />
                    ) : (
                      "QC Verified"
                    )
                  }
                  </Button>
                </Stack>
                  </StyledCardShadow>
                 
                }

{/*
                  settings === 3 && 
                  
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <Breadcrumb
                        mainDiv="EOL PROCESS"
                        subDiv="QC Verified"
                      ></Breadcrumb>
                        
                      }
                      
                    />
                    <CardContent sx={{ py: 0 }}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                         <Grid item lg={9}>
                            <StyledFormControl fullWidth>                              
          <Box sx={{ my: 2 }}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item lg={9}>
                <Card elevation={0} sx={{ border: `0.5px solid ${theme.palette.divider}`}}>
                    {
                      <>
                      <CardHeader
                        title={"Vehicles"}                     
                      />
                       <Divider />

                        <CardContent
                          sx={{
                          height: "300px",
                          overflowY: "scroll",
                          p: 0,
                          paddingBottom: "0px !important",
                          }}
                        >
                          
                          {vehLoading &&
                            <Box>
                              <ScaleLoader color={theme.palette.secondary.main}/>
                            </Box>
                          }
                        
                        {vehicleData!==undefined && vehicleData.vehicle.length > 0 ? (
                        <List dense role="list" style={{ borderRadius: "5px" }}>
                        {vehicleData.vehicle.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value}-label`;
                        return (
                        <ListItemButton
                        key={index}
                        role="listitem"
                        onClick={handleToggle(value)}
                        value={value.id}
                        //selected={checked.indexOf(value) !== -1 && true}
                        selected={value.id === checked}
                        dense
                        >
                        <ListItemText primary={value.name} />
                        </ListItemButton>
                        );
                        })}
                        </List>
                        ) : (
                        <ListItemButton disabled>No Vehicles</ListItemButton>
                        )}
                        </CardContent>
                      </>
                    }
                </Card>
              </Grid>
            </Grid>
          </Box>  
                            </StyledFormControl>
                          </Grid>
                        </Grid>
                    </CardContent>
                  </StyledCardShadow>
                 
                */}
                
                 </Grid>
            </Grid>
            {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
            </StyledPageLayout>
    )
}