import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  ButtonGroup,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  ORGANIZATION_DETAIL,
  PERSONAL_DETAILS,
  EMPLOYMENT_DETAILS,
} from "../../constants/driverFormFields";
import TextInput from "../shared/TextInput";
import DraggableDialog from "../shared/DailogDrag";
import RadioInput from "../shared/RadioInput";
import SelectInput from "../shared/SelectInput";
import DatePicker from "../shared/DatePicker";
import DriverService from "../../services/driverService";
import moment from "moment";
import AlertDialogSlide from "../shared/validationDialog";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import {
  StyedFormTitle,
  StyledCardShadow,
  StyledFormButtonsRow,
  StyledStackTableRow,
  StyledTableTitle,
} from "../../theme/StyledEle";

//const RESTRICTED_ID = DOMAIN_DETAIL[0].options[1].value
const RESTRICTED_ID = 1;

export default function FormPropsTextFields({ driverToEdit, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [driver, setDriver] = useState(null);

  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("");
  const [assignedobj, setAssignedobj] = useState({});
  const [org, setOrg] = useState(null);
  const [statusEdit, setStatusEdit] = useState(false);

  const [dob, setDob] = useState(null);

  useEffect(async () => {
    if (driverToEdit) {
      setStatusEdit(true);
      const responseData = (await DriverService.editDriverById(driverToEdit))
        .data.data;
      setDriver(responseData);
      setAssignedobj(responseData);
    } else {
      setDriver(null);
    }
  }, [setDriver]);

  const theme = useTheme();
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    m: 1,
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Box>
        <Grid container spacing={3}>
          {children}
        </Grid>
      </Box>
    );
  };
  function switchCaseVal(param) {
    switch (param) {
      case "name":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["name"] = driver["name"]);
          } else {
            return (driver["name"] =
              assignedobj.name === undefined ? "" : assignedobj.name);
          }
        }
        return assignedobj.name === undefined ? "" : assignedobj.name;
      case "languagesKnown":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["languagesKnown"] = driver["languagesKnown"]);
          } else {
            return (driver["languagesKnown"] =
              assignedobj.languagesKnown === undefined
                ? ""
                : assignedobj.languagesKnown);
          }
        }
        return assignedobj.languagesKnown === undefined
          ? ""
          : assignedobj.languagesKnown;
      case "address":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["address"] = driver["address"]);
          } else {
            return (driver["address"] =
              assignedobj.address === undefined ? "" : assignedobj.address);
          }
        }
        return assignedobj.address === undefined ? "" : assignedobj.address;
      case "emailId":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["emailId"] = driver["emailId"]);
          } else {
            return (driver["emailId"] =
              assignedobj.emailId === undefined ? "" : assignedobj.emailId);
          }
        }
        return assignedobj.emailId === undefined ? "" : assignedobj.emailId;
      case "licenseNumber":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["licenseNumber"] = driver["licenseNumber"]);
          } else {
            return (driver["licenseNumber"] =
              assignedobj.licenseNumber === undefined
                ? ""
                : assignedobj.licenseNumber);
          }
        }
        return assignedobj.licenseNumber === undefined
          ? ""
          : assignedobj.licenseNumber;
      case "mobile":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["mobile"] = driver["mobile"]);
          } else {
            return (driver["mobile"] =
              assignedobj.mobile === undefined ? "" : assignedobj.mobile);
          }
        }
        return assignedobj.mobile === undefined ? "" : assignedobj.mobile;
      case "emergencyContact":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["emergencyContact"] = driver["emergencyContact"]);
          } else {
            return (driver["emergencyContact"] =
              assignedobj.emergencyContact === undefined
                ? ""
                : assignedobj.emergencyContact);
          }
        }
        return assignedobj.emergencyContact === undefined
          ? ""
          : assignedobj.emergencyContact;
      case "bloodGroup":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["bloodGroup"] = driver["bloodGroup"]);
          } else {
            return (driver["bloodGroup"] =
              assignedobj.bloodGroup === undefined
                ? ""
                : assignedobj.bloodGroup);
          }
        }
        return assignedobj.bloodGroup === undefined
          ? ""
          : assignedobj.bloodGroup;
      case "workingHours":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["workingHours"] = driver["workingHours"]);
          } else {
            return (driver["workingHours"] =
              assignedobj.workingHours === undefined
                ? ""
                : assignedobj.workingHours);
          }
        }
        return assignedobj.workingHours === undefined
          ? ""
          : assignedobj.workingHours;
      case "remarks":
        if (driver != null) {
          if (statusEdit === true) {
            return (driver["remarks"] = driver["remarks"]);
          } else {
            return (driver["remarks"] =
              assignedobj.remarks === undefined ? "" : assignedobj.remarks);
          }
        }
        return assignedobj.remarks === undefined ? "" : assignedobj.remarks;
      default:
        if (driver != null) {
          return driver[param];
        } else {
          return "";
        }
    }
  }
  const textInputCallBackFn = (val, name) => {
    callBackFn1(val, name);
  };
  const callBackFn1 = async (val, name) => {
    var obj = { ...assignedobj };

    setStatusEdit(false);
    if (name === "name") {
      obj.name = val;
    } else if (name === "mobile") {
      obj.mobile = val;
    } else if (name === "emailId") {
      obj.emailId = val;
    } else if (name === "emergencyContact") {
      obj.emergencyContact = val;
    } else if (name === "licenseNumber") {
      obj.licenseNumber = val;
    } else if (name === "address") {
      obj.address = val;
    } else if (name === "languagesKnown") {
      obj.languagesKnown = val;
    } else if (name === "bloodGroup") {
      obj.bloodGroup = val;
    } else if (name === "workingHours") {
      obj.workingHours = val;
    } else if (name === "remarks") {
      obj.remarks = val;
    }

    setAssignedobj(obj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;

    var commondata = {};
    commondata = Object.fromEntries(new FormData(target));
    if (!driverToEdit) {
      if (commondata.orgId === "-1") {
        setTextData("Please enter Customer");
        setError(true);
      } else if (!commondata.name.match(/^[a-zA-Z]+$/)) {
        setTextData("Please enter Name");
        setError(true);
      } else if (!commondata.mobile.match(/^\d+$/)) {
        setTextData("Please enter MobileNumber");
        setError(true);
      } else if (!commondata.mobile.match("[0-9]{10}")) {
        setTextData("Please enter 10 digit MobileNumber");
        setError(true);
      } else if (
        !commondata.emailId.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
      ) {
        setTextData("Please enter valid Email Id");
        setError(true);
      } else if (!commondata.emergencyContact.match("[0-9]{10}")) {
        setTextData("Please enter emergency contact");
        setError(true);
      } else if (
        !commondata.licenseNumber.match(
          "^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$"
        )
      ) {
        setTextData("Please enter valid licenseNumber");
        setError(true);
      } else if (commondata.address === "") {
        setTextData("Please enter Address");
        setError(true);
      } else if (commondata.languagesKnown === "") {
        setTextData("Please fill Language field ");
        setError(true);
      } else if (commondata.bloodGroup === "0") {
        setTextData("Please select bloodGroup ");
        setError(true);
      } else if (commondata.dob === "") {
        setTextData("Please fill DateOfBirth ");
        setError(true);
      } else if (commondata.workingHours === "") {
        setTextData("Please fill Working Hours ");
        setError(true);
      } else if (commondata.remarks === "") {
        setTextData("Please fill Remarks ");
        setError(true);
      } else {
        saveDriverDetails(e, Object.fromEntries(new FormData(target)));
      }
    } else {
      e.preventDefault();
      const { target } = e;
      if (commondata.orgId === "-1") {
        setTextData("Please enter Customer");
        setError(true);
      } else if (!commondata.name.match(/^[a-zA-Z]+$/)) {
        setTextData("Please enter Name");
        setError(true);
      } else if (!commondata.mobile.match(/^\d+$/)) {
        setTextData("Please enter MobileNumber");
        setError(true);
      } else if (!commondata.mobile.match("[0-9]{10}")) {
        setTextData("Please enter 10 digit MobileNumber");
        setError(true);
      } else if (
        !commondata.emailId.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
      ) {
        setTextData("Please enter valid Email Id");
        setError(true);
      } else if (!commondata.emergencyContact.match("[0-9]{10}")) {
        setTextData("Please enter emergency contact");
        setError(true);
      } else if (
        !commondata.licenseNumber.match(
          "^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$"
        )
      ) {
        setTextData("Please enter valid licenseNumber");
        setError(true);
      } else if (commondata.address === "") {
        setTextData("Please enter Address");
        setError(true);
      } else if (commondata.languagesKnown === "") {
        setTextData("Please fill Language field ");
        setError(true);
      } else if (commondata.bloodGroup === "0") {
        setTextData("Please select bloodGroup ");
        setError(true);
      } else if (commondata.dob === "") {
        setTextData("Please fill DateOfBirth ");
        setError(true);
      } else if (commondata.workingHours === "") {
        setTextData("Please fill Working Hours ");
        setError(true);
      } else if (commondata.remarks === "") {
        setTextData("Please fill Remarks ");
        setError(true);
      } else {
        updateDriverDetails(e, Object.fromEntries(new FormData(target)));
      }
    }
  };

  const handleDateChange = (date) => {
    setDob(date);
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const saveDriverDetails = async (e, obj) => {
    const responseData = await DriverService.saveDriverDetails(obj);
    var obj1 = {};

    obj1.dialogButtonText = "Saved Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Data Saved Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const updateDriverDetails = async (e, obj) => {
    e.preventDefault();

    const responseData = await DriverService.updateDriverDetails(obj);

    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const InputWrapper = ({ children }) => {
    return (
      <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
        {children}
      </Grid>
    );
  };
  const FormFields = ({ fields }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <InputWrapper key={index}>
                <TextInput
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                  label={field.label}
                  name={field.name}
                />
              </InputWrapper>
              // <Grid
              //   key={index}
              //   item
              //   md={6}
              //   sx={{ display: "flex", justifyContent: "center" }}
              // >
              //   <TextInput
              //     label={field.label}
              //     name={field.name}
              //     defaultValue={driver !== null ? driver[field.name] : ""}
              //   />
              // </Grid>
            );
          } else if (field.type === "select") {
            return (
              <InputWrapper key={index}>
                <SelectInput
                  name={field.name}
                  label={field.label}
                  options={field.options}
                  disabled={field.disabled}
                  defaultValue={
                    org !== null
                      ? switchCaseVal(field.name) == ""
                        ? org[field.name]
                        : switchCaseVal(field.name)
                      : switchCaseVal(field.name) === ""
                      ? field.options !== null
                        ? field.options[0].value
                        : 0
                      : switchCaseVal(field.name)
                  }
                  callBackFn={callBackFn1}
                  callbackfnStatus={
                    field.callbackfnStatus === undefined
                      ? false
                      : field.callbackfnStatus === "true"
                      ? true
                      : false
                  }
                />
              </InputWrapper>
              // <Grid
              //   key={index}
              //   item
              //   md={6}
              //   sx={{ display: "flex", justifyContent: "center" }}
              // >
              //   {
              //     <SelectInput
              //       name={field.name}
              //       label={field.label}
              //       options={field.options}
              //       defaultValue={
              //         driver !== null
              //           ? driver[field.name]
              //           : field.options[0].value
              //       }
              //     />
              //   }
              // </Grid>
            );
          } else if (field.type === "radio") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <RadioInput
                  name={field.name}
                  label={field.label}
                  options={field.options}
                  defaultVal={driver !== null ? driver[field.name] : "1"}
                />
              </Grid>
            );
          } else if ((field.type = "date")) {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <DatePicker
                  name={field.name}
                  label={field.label}
                  onChange={handleDateChange}
                  value={
                    driver === null
                      ? dob
                      : moment(driver[field.name], "DD/MM/YYYY")
                  }
                />
              </Grid>
            );
          }
        })}
      </>
    );
  };

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  const handleClear = () => {
    setError(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} defaultValue={driver}>
        <StyledCardShadow sx={{ mb: 2 }} elevation={0}>
          <CardHeader
            title={<StyledTableTitle>Organization Details</StyledTableTitle>}
          />
          <CardContent>
            <FormLayout>
              <FormFields fields={ORGANIZATION_DETAIL} />
            </FormLayout>
          </CardContent>
        </StyledCardShadow>
        <StyledCardShadow sx={{ my: 2 }} elevation={0}>
          <CardHeader
            title={<StyledTableTitle>Personal Details</StyledTableTitle>}
          />

          <CardContent>
            <FormLayout>
              <FormFields fields={PERSONAL_DETAILS} />
            </FormLayout>
          </CardContent>
        </StyledCardShadow>
        <StyledCardShadow sx={{ my: 2 }} elevation={0}>
          <CardHeader
            title={<StyledTableTitle>Employment Details</StyledTableTitle>}
          />

          <CardContent>
            <FormLayout>
              <FormFields fields={EMPLOYMENT_DETAILS} />
            </FormLayout>
          </CardContent>
        </StyledCardShadow>

        <input
          type="hidden"
          name="id"
          value={driver != null ? driver.id : ""}
        />
        <input type="hidden" name="orgId" value={0} />
        <input type="hidden" name="resellerId" value={0} />
        <StyledFormButtonsRow direction="row">
          <Button onClick={handleCancelClick} color="error">
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small">
            {driverToEdit ? "Update" : "Save"}
          </Button>
        </StyledFormButtonsRow>
      </form>

      {
        <AlertDialogSlide
          open1={error}
          text={textData}
          handleClear={handleClear}
        />
      }
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
