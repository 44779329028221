import { dashboardApi } from "../ApiSlice";

const OrgSettingSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
       
      
        // Get all alerts
        getAlertList: builder.mutation({
          query: (body) => ({
            url: `OrgRegisteration/getAllAlerts`, 
            method: "post",
            body: body,
          }),
        }),

         // Get all alert details
         getAlertStatusList: builder.mutation({
          query: (body) => ({
            url: `OrgRegisteration/getAlertsForOrgNew`, 
            method: "post",
            body: body,
          }),
        }),

        // Get all alert details
        getAllAlertList: builder.mutation({
          query: (body) => ({
            url: `OrgRegisteration/getAlertsForOrg`, 
            method: "post",
            body: body,
          }),
        }),



         //update Harsh Acceleration and Brake
        updateHarshSettings: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/updateHarshSettings",
          method: "POST",
          body: body,
        }),
      }),


       //update GeoZoneCount
       updateGeoZoneCount: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/updateGeoZoneCount",
          method: "POST",
          body: body,
        }),
      }),

       //update Health Inspector
       updateHealthInspector: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/updateHealthInspector",
          method: "POST",
          body: body,
        }),
      }),

       //update Vehicle Display
       updateVehicleDisplay: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/updateVehicleDisplay",
          method: "POST",
          body: body,
        }),
      }),


       //update Alerts
       updateAlerts: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/updateAlerts",
          method: "POST",
          body: body,
        }),
      }),

      //read vehicle display
      readVehicleDisplay: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/ReadVehicleDisplay",
          method: "POST",
          body: body,
        }),
      }),

       //read Harsh settings
       readHarshSettings: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/readHarshSettings",
          method: "POST",
          body: body,
        }),
      }),

       //read vehicle display
       readHealthInspectorStatus: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/readHealthInspectorStatus",
          method: "POST",
          body: body,
        }),
      }),

       //read vehicle display
       readGeoZoneCount: builder.mutation({
        query: (body) => ({
          url: "/OrgRegisteration/readGeoZoneCount",
          method: "POST",
          body: body,
        }),
      }),
    
    })
})
export const { useGetAlertListMutation, useUpdateHarshSettingsMutation, useUpdateGeoZoneCountMutation, useGetAlertStatusListMutation, useGetAllAlertListMutation, useUpdateAlertsMutation, useUpdateHealthInspectorMutation, useUpdateVehicleDisplayMutation, useReadVehicleDisplayMutation, useReadHealthInspectorStatusMutation, useReadGeoZoneCountMutation, useReadHarshSettingsMutation } = OrgSettingSlice;