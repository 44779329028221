export const listUserData = [
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: '1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '13',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '14',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    },
    {
      value1: 'Fleet',
      value2: 'KA53HA9912',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: ['Edit','Delete'],
      value9: 'some value three',
      value10: 'some value one 1',
      value11: '12324'
    }
  ]