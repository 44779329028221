import { Empty } from 'antd'
import React from 'react'

function NoDataFound(props) {
    const { description='No data found!', image=Empty.PRESENTED_IMAGE_SIMPLE, children=null } = props;
  return (
    <Empty 
        description={description}
        image={image}
    />
  )
}

export default NoDataFound