import React, { useEffect, useMemo, useState } from "react";
import { Box, CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import OTADropdown from "../OTA/OTADropdown";

import {
  useAuditLogMutation,
  useDeviceOtaListMutation,
} from "../../store/slice/ApiSlice";

const DeviceOTALog = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let logoName = userData.orgLogo;

  const [auditLog] = useAuditLogMutation();

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const columns = useMemo(
    () => [
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "Command",
        accessor: "data",
      },
      {
        Header: "Command Type",
        accessor: "commandType",
      },
      {
        Header: "Queued Date",
        accessor: "queuedDate",
      },
      {
        Header: "Initiated Date",
        accessor: "initiatedDate",
      },
      {
        Header: "Responded Message",
        accessor: "respondedMessage",
      },
      {
        Header: "Responded Date",
        accessor: "respondedDate",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      // {
      //   Header: "Complete Percent(%)",
      //   accessor: "completePercent",
      // },
    ],
    []
  );

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);
  // Get Data from New OTA Dropdown
  const [getOtaList, { data: otaListData, isLoading, isSuccess }] =
    useDeviceOtaListMutation();
  let commonPayload = {};
  // Onload and Submit
  const getData = (data) => {
    commonPayload.imei = data.imei;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.customerId = data.customer;
    commonPayload.resellerId = resellerId;
    commonPayload.fleetId = data.fleet;
    commonPayload.otaFilter = data.commandType;
    commonPayload.otaStatus = data.otaStatus;
    commonPayload.uniImei = 0;
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.search = "";
    commonPayload.vehicleLevel = 1;
    commonPayload.dealerId = data.dealer;
    commonPayload.regionId = data.region;
    commonPayload.logoName = logoName;
    //Service Call
    setPayload(commonPayload);
    getOtaList(commonPayload);

    auditPayload.message = "OTA > Device-OTALog > OnSubmit";
    auditPayload.action = "View";
    auditPayload.featureName = "OTALog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getOtaList(payload);
    } else if (payload.search.length === 0) {
      getOtaList(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.start = newPage;
    payload.length = rowsPerPage;
    getOtaList(payload);
  };
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (otaListData.data !== null) {
        setResponseData(otaListData.data.content);

        setTotalElements(otaListData.data.totalElements);
        setTotalPages(otaListData.data.totalPages);
      }
    }
  }, [isSuccess]);
  // const callbackLoadFn = async (
  //   selectedResellerId,
  //   selectedDealerId,
  //   selectedRegionId,
  //   selectedCustomerId,
  //   selectedFleetId,
  //   selectedImei,
  //   selectedStatus,
  //   globalLogoName
  // ) => {
  //   var request = {};
  //   // const fromDate =format(addDays(new Date()), "yyyy-MM-dd") + " 00:00:00";
  //   // const toDate = format(addDays(new Date()), "yyyy-MM-dd") + " 23:59:59";
  //   const fromDate = moment().format("yyyy-MM-DD 00:00:00");
  //   const toDate = moment().format("yyyy-MM-DD 23:59:59");
  //   request.imei = selectedImei;
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.customerId = selectedCustomerId;
  //   request.resellerId = selectedResellerId;
  //   request.fleetId = selectedFleetId;
  //   request.otaFilter = 0;
  //   request.otaStatus = selectedStatus;
  //   request.uniImei = 0;
  //   request.start = newPage;
  //   request.length = rowsPerPage;
  //   request.search = "";
  //   request.vehicleLevel = 1;
  //   request.dealerId = selectedDealerId;
  //   request.regionId = selectedRegionId;
  //   request.logoName = globalLogoName;
  //   setPageStatus(request);

  //   const responseData = (await OTAServices.getOTALogList(request)).data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);

  //   var request = {};
  //   request.message = "OTA > OTALog";
  //   request.action = "View";
  //   request.featureName = "OTALog";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName = globalUserName;

  //   const responseData1 = await logServices.saveAuditLogDetails(request);
  // };

  // const handleSubmit = async (
  //   resellerId,
  //   regionId,
  //   dealerId,
  //   customerId,
  //   fleetId,
  //   imei,
  //   otaStatus,
  //   fromDate,
  //   toDate,
  //   globalLogoName
  // ) => {
  //   var request = {};
  //   request.imei = imei;
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.customerId = customerId;
  //   request.resellerId = resellerId;
  //   request.fleetId = fleetId;
  //   request.otaFilter = "";
  //   request.otaStatus = otaStatus;
  //   request.uniImei = 0;
  //   request.start = newPage;
  //   request.length = rowsPerPage;
  //   request.search = "";
  //   request.vehicleLevel = 1;
  //   request.dealerId = dealerId;
  //   request.regionId = regionId;
  //   request.logoName = globalLogoName;
  //   setPageStatus(request);

  //   const responseData = (await OTAServices.getOTALogList(request)).data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);

  //   var request = {};
  //   request.message = "OTA > OTALog > OnSubmit";
  //   request.action = "View";
  //   request.featureName = "OTALog";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName = globalUserName;

  //   const responseData1 = await logServices.saveAuditLogDetails(request);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   setLoading("");
  //   var request = {};

  //   request.imei = pageStatus.imei;
  //   request.startDate = pageStatus.startDate;
  //   request.endDate = pageStatus.endDate;
  //   request.customerId = pageStatus.customerId;
  //   request.resellerId = pageStatus.resellerId;
  //   request.fleetId = pageStatus.fleetId;
  //   request.otaFilter = pageStatus.otaFilter;
  //   request.otaStatus = pageStatus.otaStatus;
  //   request.uniImei = pageStatus.uniImei;
  //   request.start = newPage;
  //   request.length = rowsPerPage;
  //   request.search = "";
  //   request.vehicleLevel = pageStatus.vehicleLevel;
  //   request.dealerId = pageStatus.dealerId;
  //   request.regionId = pageStatus.regionId;
  //   request.logoName = pageStatus.logoName;

  //   try {
  //     const responseData = (await OTAServices.getOTALogList(request)).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  return (
    <Box>
      {/* <CommonDropDown handleSmt={handleSubmit} callbackLoadFn={callbackLoadFn}/> */}
      <OTADropdown
        getData={getData}
        imeiDrop={true}
        otaDrop={true}
        onLoadData={true}
      />
      <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
        <CardHeader title={<StyledTableTitle>OTA Log</StyledTableTitle>} />
        <CardContent>
          <DataTable
            includeSearchBox={true}
            includeExportButton={false}
            columns={columns}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            data={responseData}
            isLoading={isLoading}
            page={newPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            handleChange={handleSearch}
            rowsPerPage={rowsPerPage}
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};
export default DeviceOTALog;
