const REQUEST_DETAIL = [
  {
    type: "select",
    label: "Category",
    name: "categoryval",
    options: [
      {
        id: 0,
        title: "Select",
        value: 0,
      },
    ],
  },
  {
    type: "select",
    label: "Type",
    name: "typeval",
    options: [
      {
        id: 0,
        title: "Select",
        value: 0,
      },
    ],
  },
  {
    type: "select",
    label: "Status",
    name: "statusval",
    options: [
      {
        id: 0,
        title: "Select",
        value: 0,
      },
    ],
  },
  {
    type: "select",
    label: "Servity",
    name: "servityval",
    options: [
      {
        id: 0,
        title: "Select",
        value: 0,
      },
    ],
  },
];

const DETAIL = [
  {
    type: "autocomplete",
    label: "Vehicle",
    name: "searchVehicle",
  },

  // {
  //     type: 'text',
  //     label: 'Title',
  //     name: 'Title'
  //   },
];

const REPORTER_DETAIL = [
  {
    type: "text",
    label: "Organization",
    name: "Organization",
  },
  {
    type: "text",
    label: "Name",
    name: "Name",
  },
  {
    type: "text",
    label: "Email",
    name: "Email",
  },
  {
    type: "text",
    label: "Mobile No",
    name: "Mobile No",
  },
];

module.exports = {
  REQUEST_DETAIL,
  DETAIL,
  REPORTER_DETAIL,
};
