import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { StyledPaper, StyledPaperShadow, StyledTab, StyledTabBox, StyledTabsGroup } from "../../theme/StyledEle";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function StyledTabs({ tabs = [], handleChange, value }) {
  const theme = useTheme();
  const tabIndicatorStyles = {
    background: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.secondary.main,
    height: "2px",
  };

  return (
    <Box>
      <StyledTabBox>
        <StyledTabsGroup
          TabIndicatorProps={{ style: tabIndicatorStyles }}
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
        >
          {tabs.map((tab, index) => {
            return <StyledTab  label={tab.label} key={index} />;
          })}
        </StyledTabsGroup>
      </StyledTabBox>
      {tabs.map((tab, index) => {
        return (
          <TabPanel value={value} key={index} index={index} className='tabsPanel'>
            {tab.children}
          </TabPanel>
        );
      })}
    </Box>
  );
}
