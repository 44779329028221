import Service from "./service";

const menuListService = {
/*
  getMenuList: function (resource, param) {
    return Service.post('/RoleSettings/gridViewTestData',resource, param)
*/
   getMenuList: function (resource,param) {
    //return Service.post('/RoleSettings/gridViewTestData',resource,param)
    return Service.post('/login/userMenu',resource,param)
  }  
};

export default menuListService;