import React, { useState, useMemo, useEffect } from "react";
import { Box } from "@mui/system";
import { CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { Breadcrumb } from "../shared/Breadcrumbs";
import CommonDropDown from "./LogDropDown";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import LogDropdown from "../LogDropdown/LogDropdown";
import { useVinLogListMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const VinLog = () => {
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let RoleId = userData.roleId;

  const text =
    "This screen shows the VIN assignment to devices with IMEI in the VTS system.";

  const columns = useMemo(
    () => [
      {
        Header: " Mapped Date",
        accessor: "mappedDate",
      },
      {
        Header: "Sent Date",
        accessor: "createdDate",
      },
      {
        Header: "VTS Portal Received Date",
        accessor: "receivedDate",
      },
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "VIN",
        accessor: "vin",
      },
      {
        Header: "Status",
        accessor: "processingType",
      },
      {
        Header: "Vehicle Type",
        accessor: "vehicleType",
      },
      {
        Header: "Vehicle Model",
        accessor: "vehicleModel",
      },
      {
        Header: "Comments",
        accessor: "description",
      },
    ],
    []
  );

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  // const fetchVinLogDataDetails = async () => {
  //   var request = {};

  //   request.page = 0;
  //   request.size = 5;
  //   request.searchText = "";
  //   request.columnName = "IMEI";
  //   request.orderdir = "desc";
  //   // request.startDate = "2023-02-01 00:00:00";
  //   // request.endDate = "2023-03-02 11:44:15";
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.imei = "";
  //   request.searchBy = "Date";
  //   request.status = "All";
  //   request.vin = "";

  //   const responseData = (await LogServices.VinLog(request)).data;
  //   setResponseData(responseData.data);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.recordsFiltered);
  //   setTotalPages(responseData.recordsTotal);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   var request = {};
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = "";
  //   request.columnName = "IMEI";
  //   request.orderdir = "desc";
  //   request.startDate = "2023-02-01 00:00:00";
  //   request.endDate = "2023-02-28 11:44:15";
  //   request.imei = "";
  //   request.searchBy = formData;
  //   request.status = status;
  //   request.vin = "";

  //   const responseData = (await LogServices.VinLog(request)).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.recordsFiltered);
  //   setTotalPages(responseData.recordsTotal);
  // };

  const [vinLogList, { data: vinLogData, isLoading, isSuccess }] =
    useVinLogListMutation();
  let commonPayload = {
    columnName: "CREATED_DATE_TIME",
    orderdir: "desc",
  };
  const [payload, setPayload] = useState(null);
  const getData = (data) => {

    commonPayload.searchBy = data.searchbyId;
    commonPayload.status = data.statusId;
    commonPayload.startDate = data.fromData;
    commonPayload.endDate = data.toDate;
    commonPayload.imei = data.iemiId;
    commonPayload.vin = data.vinId;
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.searchText = "";
    commonPayload.resellerId = resellerId;
    commonPayload.resellerOrgId = resellerId;
     commonPayload.type = data.typeId
 
    setPayload(commonPayload);
    vinLogList(commonPayload);
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      vinLogList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      vinLogList(payload);
    }
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      vinLogList(payload);
    } else if (payload.searchText.length === 0) {
      vinLogList(payload);
    }
  };

  // const handleSubmit = async (formData) => {
  //   // setFormData(formData.searchBy);
  //   // setStatus(formData.status);
  //   // setFromDate(formData.startDate);
  //   // setToDate(formData.endDate);
  //   var request = {};
  //   request.page = 0;
  //   request.size = 5;
  //   request.searchText = "";
  //   request.columnName = "IMEI";
  //   request.orderdir = "desc";
  //   request.startDate = payload.fromData;
  //   request.endDate = payload.toDate;
  //   request.imei = "";
  //   request.searchBy = payload.searchbyId;

  //   request.status = payload.status;
  //   request.vin = "";

  //   const responseData = (await LogServices.VinLog(request)).data;
  //   // setResponseData(responseData.data);
  //   // setLoading(responseData.resultText);
  //   // setTotalElements(responseData.recordsFiltered);
  //   // setTotalPages(responseData.recordsTotal);
  // };

  const onChangeXcl = async () => {
    try {

      var url =
        process.env.REACT_APP_API_BASE_URL +
        "vehicle/VinLogDataExcel?searchBy=" +
        payload.searchBy +
        "&status=" +
        payload.status +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&imei=" +
        payload.imei +
        "&vin=" +
        payload.vin +
        "&resellerId=" +
        payload.resellerId +
        "&searchText=" +
        payload.searchText +
        "&resellerOrgId=" +
        payload.resellerOrgId;
      window.open(url);

      auditPayload.message = "Reports > VinLog > Export > Excel";
      auditPayload.action = "View";
      auditPayload.featureName = "VinLog";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };


  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (vinLogData.data !== null && vinLogData.data.length > 0) {
        setResponseData(vinLogData.data);
        setTotalElements(vinLogData.recordsFiltered);
        setTotalPages(vinLogData.recordsTotal);
      }
    }

    auditPayload.message = "Reports > VinLog";
    auditPayload.action = "View";
    auditPayload.featureName = "VinLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
 
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Logs" subDiv="VIN Log" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"VIN Log"} />
      <Box>
        {/* <VinLogDropdown onSubmit={handleSubmit} /> */}
        <LogDropdown getData={getData} allowDays={1}
        allowedMonth={3}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader title={<StyledTableTitle>VIN Log</StyledTableTitle>} />

          <CardContent sx={{ py: 0 }}>
            <DataTable
              handleChange={handleSearch}
              isLoading={isLoading}
              includeSearchBox={true}
              includePagination={true}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              onChangeXcl={onChangeXcl}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default VinLog;
