import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Typography,
  Tooltip,
  Toolbar,
  CardMedia,
  Stack,
  Box,
  Divider,
  ListItem,
  ListItemText,
  FormControlLabel,
  useTheme,
  Tabs,
  Tab,
  Switch,
  alpha,
  Button,
} from "@mui/material";
import { useSticky } from "react-table-sticky";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useBlockLayout,
  usePagination,
  useRowSelect,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
} from "react-table";
import { makeStyles } from "@mui/styles";
import { TbArrowsMoveHorizontal, TbEdit } from "react-icons/tb";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

import SearchBox from "../SearchBox";
import Checkbox from "@mui/material/Checkbox";
//import { visuallyHidden } from "@mui/utils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
//import SortIcon from "@mui/icons-material/Sort";
import SplitButton from "../ExportButton";
import "./addstyle.css";
import PropTypes from "prop-types";
//import { SELECTION_COLUMN } from "antd/lib/table/hooks/useSelection";
import {
  StyledTableHead,
  HeaderTableCell,
  BodyTableCell,
  MuiTablePagination,
  StyledTableRow,
  StyledTableContainer,
} from "./StyledEle";
// import { styled } from "@mui/system";
import nodatafound from "../../../assets/images/nodata.png";
import {
  Exportoptions,
  FilterMenu,
  StyledExportButtons,
  StyledPaperShadow,
  StyledSearchBarFilter,
  StyledSwitch,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../theme/StyledEle";
import { TbTableAlias } from "react-icons/tb";
import { FormControl } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { TableFiters } from "./TableWidget";
import Scrollbars from "react-custom-scrollbars-2";
import TablePaginationActions from "./TablePaginationActions";
import ScaleLoader from "react-spinners/ScaleLoader";
import { FaShareSquare } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import { cyan, deepPurple, indigo, pink, yellow } from "@mui/material/colors";

export const DataTable = ({
  isLoading,
  includeSearchBox = false,
  includeExportButton = true,
  includePagination = true,
  handleChange,
  handleExportClick,
  columns,
  data,
  initialState,
  styles='100%',
  numberOfStickyColumns,
  onEdit,
  onDelete,
  onCheckbox,
  callbackChangePage,
  page = 0,
  rowsPerPage = 5,
  totalElements,
  totalPages = 1,
  callbck,
  commonFilter = {
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
    resendActivation: false,
  },
  onChangeXcl,
  onChangePdf,
  loading,
  specificPage,
  onView,
  checkbox = false,
  selectRow,
  onResendActivation,
  renderSubRowCompoent
}) => {
  const numberOfRows = 5;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0]?.accessor);

  const [isSorted, setIsSorted] = useState(true);
  const [columncell, setColumn] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    //setPage(newPage)
    page = newPage;
    callbackChangePage(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(+event.target.value);
    //setPage(page);
    callbackChangePage(page, +event.target.value);
    //page = page;
  };

  if (numberOfStickyColumns) {
    for (let i = 0; i < numberOfStickyColumns; i++) {
      columns[i].sticky = "left";
    }
  }

  //Default column
  const defaultColumn = useMemo(() => ({
    minWidth: 30,
    width: 150,
    maxWidth: 800,
  }));

  const useStyles = makeStyles({
    table: {
      "[data-sticky-last-left-td]": {
        boxShadow: "2px 0px 3px #ccc",
      },
      "[data-sticky-td]": {
        position: "sticky",
        background: "#fff",
        width: "fit-content !important",
      },
      "[data-sticky-last-left-td]": {
        boxShadow: "2px 0px 3px #ccc",
      },
      overflowX: "scroll",
    },
    //selectDropdown: { color: "#fff", backgroundColor: "#121858" },
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    allColumns,
    getToggleHideAllColumnsProps,
    prepareRow,
    pageCount,
    nextPage,
    selectedFlatRows,
    state: { selectedRowIds, expanded },
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState,
      useBlockLayout,
      defaultColumn,
    },
    
    useSortBy,
    useExpanded,
    //usePagination,
    useResizeColumns,
    useFlexLayout,
    //Create Check box
    useRowSelect,
    (hooks) => {
      checkbox &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: () => <Typography>#</Typography>,
            Cell: ({ row }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ]);
    },
   
  );

  //Expnd

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <Checkbox
            color="secondary"
            ref={resolvedRef}
            {...rest}
            size="small"
          />
        </>
      );
    }
  );

  useEffect(() => {
    {
      checkbox && selectRow(selectedFlatRows, selectedRowIds);
    }
  }, [selectedRowIds]);

  const handleClick = (cell, event, e) => {
    if (event.toLowerCase() === "view") {
      if (commonFilter.view === true) {
        onView(cell.row.original);
      }
    }

    if (event === "resendActivation") {
      if (commonFilter.resendActivation === true) {
        onResendActivation(cell.row.original);
      }
    }

    if (event.toLowerCase() === "edit") {
      if (commonFilter.edit === true) {
        onEdit(cell.row.original);
      }
    } else if (event.toLowerCase() === "delete") {
      if (commonFilter.delete === true) {
        onDelete(cell.row.original);
      }
    } else if (event.toLowerCase() === "checkbox") {
      if (e.target.checked === true) {
        onCheckbox(cell.row.original);
      }
    }
  };
  /*
    const firstPageRows = rows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    */
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickFIlter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const [tableTab, setTaleTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTaleTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Box sx={{ width: "100%", background:'#f6f6f6'}}>
      <Box sx={{mb:1,display:"flex",justifyContent:"start"}}>
              {data.length > 0  && includeExportButton && (
          <Exportoptions onChangeXcl={onChangeXcl} onChangePdf={onChangePdf} />
        )}
        </Box>
      <StyledTableContainer sx={{overflow:'hidden', position:'relative', width:'100%',  maxHeight:'max-content !important'}}>
        <Table
          {...getTableProps()}
          sx={{ overflowX: "scroll", borderCollapse: "separate" ,maxWidth:styles, paddingLeft:'80px'}}
        >
          <StyledTableHead className="nestedHeader" sx={{position:'sticky', top:'0px'}}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeaderTableCell
                    {...column.getHeaderProps()}
                    sx={{background:`${indigo[500]} !important`, '& .MuiTypography-root':{color: `${theme.palette.primary.contrastText} !important`}}}
                  >
                    <Typography>{column.render("Header")}</Typography>
                    {
                      column.canResize && (
                          <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                          >
                            <TbArrowsMoveHorizontal />
                          </div>
                      )
                    }
                      {/* </span> */}
                  
                  </HeaderTableCell>
                ))}
              </TableRow>
            ))}
          </StyledTableHead>

          <TableBody {...getTableBodyProps()} className="nestedBody">
            {isLoading ? (
              <BodyTableCell colSpan={9} sx={{ textAlign: "center" }} className="nestedBodyCell">
                <Box
                  sx={{
                    height: "100px",
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ScaleLoader color={theme.palette.secondary.main} />
                </Box>
              </BodyTableCell>
            ) : rows.length > 0 && data.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <Fragment  key={row.id + 1}>
                  <StyledTableRow sx={{display:'flex'}} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === "Action") {
                        return (
                          <BodyTableCell {...cell.getCellProps()}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              sx={{ textAlign: "center", alignItems: "center" }}
                            >
                              {cell.value !== null &&
                                cell.value.map((v, index) => {
                                  return (
                                    <Tooltip
                                      title={
                                        v.toLowerCase() === "view"
                                          ? "View"
                                          : v.toLowerCase() === "edit"
                                          ? "Edit"
                                          : v === "resendActivation"
                                          ? "Resend Activation"
                                          : "Delete"
                                      }
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        sx={{
                                          display:
                                            v.toLowerCase() === "view" &&
                                            commonFilter.view === true
                                              ? "flex"
                                              : v.toLowerCase() === "edit" &&
                                                commonFilter.edit === true
                                              ? "flex"
                                              : v.toLowerCase() === "delete" &&
                                                commonFilter.delete === true
                                              ? "flex"
                                              : v === "resendActivation" &&
                                                commonFilter.resendActivation ===
                                                  true
                                              ? "flex"
                                              : "none",
                                          background:
                                            v.toLowerCase() === "view"
                                              ? alpha(
                                                  theme.palette.primary.main,
                                                  0.1
                                                )
                                              : v.toLowerCase() === "edit"
                                              ? alpha(
                                                  theme.palette.info.main,
                                                  0.1
                                                )
                                              : v === "resendActivation"
                                              ? alpha(
                                                  theme.palette.secondary.main,
                                                  0.1
                                                )
                                              : alpha(
                                                  theme.palette.error.main,
                                                  0.1
                                                ),
                                          color:
                                            v.toLowerCase() === "view"
                                              ? alpha(
                                                  theme.palette.primary.main,
                                                  1
                                                )
                                              : v.toLowerCase() === "edit"
                                              ? alpha(
                                                  theme.palette.info.main,
                                                  1
                                                )
                                              : v === "resendActivation"
                                              ? alpha(
                                                  theme.palette.secondary.main,
                                                  1
                                                )
                                              : alpha(
                                                  theme.palette.error.main,
                                                  1
                                                ),
                                          borderRadius: "5px",
                                        }}
                                        onClick={(e) => handleClick(cell, v, e)}
                                      >
                                        {v.toLowerCase() === "edit" &&
                                          commonFilter.edit === true && (
                                            <TbEdit />
                                          )}
                                        {v.toLowerCase() === "view" &&
                                          commonFilter.view === true && (
                                            <AiOutlineEye />
                                          )}
                                        {v.toLowerCase() === "delete" &&
                                          commonFilter.delete === true && (
                                            <AiOutlineDelete />
                                          )}
                                        {v === "resendActivation" &&
                                          commonFilter.resendActivation ===
                                            true && <BiMailSend />}
                                      </IconButton>
                                    </Tooltip>
                                  );
                                })}
                            </Stack>
                          </BodyTableCell>
                        );
                      } else if (cell.column.Header === "") {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{ textAlign: "center", display: "flex" }}
                          >
                            {cell.value === "checkBox" && (
                              <Checkbox
                                onChange={(e) =>
                                  handleClick(cell, cell.value, e)
                                }
                                style={{ fontSize: "15px" }}
                              />
                            )}
                          </BodyTableCell>
                        );
                      } else if (
                        cell.column.Header === "Active/InActive" &&
                        specificPage === "EV Fault Code"
                      ) {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{ textAlign: "center" }}
                          >
                            {cell.value === "InActive" ? (
                              <FiberManualRecordIcon />
                            ) : (
                              <FiberManualRecordIcon style={{ color: "red" }} />
                            )}
                          </BodyTableCell>
                        );
                      } else {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{
                              textAlign: "left",
                              color:
                                specificPage === "EV Fault Code"
                                  ? "red" : "black",
                                  background:cell.row.original.zoneType === 'between' ? `${cyan[100]} !important` : cell.row.original.zoneType === 'within' ? `${yellow[100]} !important` : '',
                                  paddingLeft: cell.row.original.zoneType === 'between' ? '15px' : '10px',
                            }}
                          >
                            {cell.render("Cell")}
                          </BodyTableCell>
                        );
                      }
                    })}
                  </StyledTableRow>
                  {
                  row.isExpanded ? (
                    <StyledTableRow sx={{display:'flex'}} {...row.getRowProps()}>
                        <BodyTableCell colSpan={visibleColumns.length} sx={{ textAlign: "center", background:'transparent !important', borderStyle:'none'}}>
                            {/* {renderSubRowCompoent({row})} */}
                        </BodyTableCell>
                    </StyledTableRow>
                  ) : null}
                  </Fragment>
                );
              })
            ) : (
              <TableRow>
                <BodyTableCell colSpan={9} sx={{ textAlign: "center" }}>
                  {/* <CardMedia
                    component="img"
                    src={nodatafound}
                    sx={{
                      width: { xl: "180px", lg: "120px", md: "80px" },
                      margin: "auto",
                      my: 2,
                    }}
                  /> */}
                  <Typography>No Data Found</Typography>
                </BodyTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
};
DataTable.propTypes = {
  loading: PropTypes.bool,
};
