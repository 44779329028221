import React from 'react'
import { StyledPageLayout } from '../../theme/StyledEle'
import MainMap from './MainMap'

function Main() {
  return (
    <StyledPageLayout sx={{p:0, height: "100%", width:"100%"}} className='mapContainer'>
        <MainMap />
    </StyledPageLayout>
  )
}

export default Main
