export const ThemeColorPalate = [
    {
        id:1,
        name:'default',
        ThemeName: 'Default',
        primary:{
            dark: "#020646",
            light: "#353b90",
            main: "#030A74",
            contrastText:'#fff'
        },
        secondary: {
            dark: "#802400",
            light: "#FF9A73",
            main: "#FF4700",
            contrastText:'#fff'
        },
        sidebar:{
            dark: "#023e7d",
            light: "#0077b6",
            main: "#0466c8",
            contrastText:'#fff'
        },
        header:{
            dark: "#0077b6",
            light: "#0077b6",
            main: "#0466c8",
            contrastText:'#fff'
        },
        treeView:{
            primary700:'#1C2282',
            primary600:'#292F89',
            primary500:'#353B90',
            primary400:'#424797',
            primary300:'#4F549E',
            primary200:'#ccd0ff',
        }
    },

    {
        name:'Avera Theme',
        ThemeName: 'Greener',
        primary:{
                dark: "#2c7336",
                light: "#7ccc87",
                main: "#3fa34d",
                contrastText:'#fff'
            },
            secondary: {
                dark: "#014a7d",
                light: "#6ac1fe",
                main: "#01497c",
                contrastText:'#fff'
            },
            sidebar:{
                dark: "#2c7336",
                light: "#7ccc87",
                main: "#3fa34d",
                contrastText:'#fff'
            },
            header:{
                dark: "#023e7d",
                light: "#0077b6",
                main: "#0466c8",
                contrastText:'#fff'
            },
            treeView:{
                primary700:'#32813d',
                primary600:'#40a64e',
                primary500:'#59bf68',
                primary400:'#7ecd89',
                primary300:'#a3dbab',
                primary200:'#edf8ee',
            }
    },
    {
        name:'theme2',
        ThemeName: 'sunset',
        primary:{
                dark: "#dc3a40",
                light: "#ed989b",
                main: "#e56b6f",
                contrastText:'#fff'
            },
            secondary: {
                dark: "#45648b",
                light: "#abbed5",
                main: "#355070",
                contrastText:'#fff'
            },
            sidebar:{
                dark: "#dc3a40",
                light: "#ed989b",
                main: "#e56b6f",
                contrastText:'#fff'
            },
            header:{
                dark: "#023e7d",
                light: "#0077b6",
                main: "#0466c8",
                contrastText:'#fff'
            },
            treeView:{
                primary700:'#981b1f',
                primary600:'#c32228',
                primary500:'#dd3c41',
                primary400:'#e4676b',
                primary300:'#ec9395',
                primary200:'#fbe9ea',
            }
    },
    {
        name:'theme3',
        ThemeName: 'Voilet',
        primary:{
                dark: "#7300cb",
                light: "#c77eff",
                main: "#a100f2",
                contrastText:'#fff'
            },
            secondary: {
                dark: "#31499e",
                light: "#a5b2e3",
                main: "#354fab",
                contrastText:'#fff'
            },
            sidebar:{
                dark: "#7300cb",
                light: "#c77eff",
                main: "#a100f2",
                contrastText:'#fff'
            },
            header:{
                dark: "#023e7d",
                light: "#0077b6",
                main: "#0466c8",
                contrastText:'#fff'
            },
            treeView:{
                primary700:'#7700b3',
                primary600:'#9900e6',
                primary500:'#b21aff',
                primary400:'#c34dff',
                primary300:'#d480ff',
                primary200:'#f6e5ff',
            }
    },
    {
        name:'theme4',
        ThemeName: 'Vintage',
        primary:{
                dark: "#a26c49",
                light: "#d2b09a",
                main: "#ad744e",
                contrastText:'#fff'
            },
            secondary: {
                dark: "#ff8732",
                light: "#ffc69d",
                main: "#ffa86b",
                contrastText:'#fff'
            },
            sidebar:{
                dark: "#a26c49",
                light: "#d2b09a",
                main: "#ad744e",
                contrastText:'#fff'
            },
            header:{
                dark: "#023e7d",
                light: "#0077b6",
                main: "#0466c8",
                contrastText:'#fff'
            },
            treeView:{
                primary700:'#ad744e',
                primary600:'#9e6a47',
                primary500:'#b88461',
                primary400:'#c89f84',
                primary300:'#d7baa7',
                primary200:'#f7f1ed',
            }
    },
]