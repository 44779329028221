import { Avatar, Card, ListItem, ListItemAvatar, ListItemText, Radio, Typography, useTheme } from '@mui/material'
import React from 'react'
import dealerAvator from '../../assets/images/dealerAvator.png'
function DelaerList({data, getId, id, index}) {
    const theme = useTheme();
  return (
   <Card elevation={0} sx={{my:.8, boxShadow:'0px 3px 4px #0000000f', borderRadius:'15px', border:`.5px solid ${theme.palette.divider}` }} key={index}>
         <ListItem
         dense={true}
         onClick={() => getId(data?.TRADE_NAME, data?.ADDRESS)}
            secondaryAction={
                <Radio
                    key={index}
                    color='success'
                    edge="end"
                    onChange={() => getId(data?.TRADE_NAME, data?.ADDRESS)}
                    checked={id !== null && id?.name === data?.TRADE_NAME}
                    inputProps={{ 'aria-labelledby': data?.TRADE_NAME }}
                />
            }
    >
        <ListItemAvatar>
            <Avatar src={dealerAvator}/>
        </ListItemAvatar>
        <ListItemText
            primary={<Typography variant='body1'>{data?.TRADE_NAME}</Typography>}
            secondary={<Typography variant='body2' sx={{fontSize:'.7rem !important'}}>{data?.ADDRESS}</Typography>}
        />
    </ListItem>
   </Card>
  )
}

export default DelaerList