import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import {
  Grid,
  Button,
  CardContent,
  Stack,
  MenuItem,
  FormHelperText,
  useTheme,
} from "@mui/material";
import DraggableDialog from "../shared/DailogDrag";
import dealerServices from "../../services/dealerService";
import organizationServices from "../../services/organizationServices";
import dropdownServices from "../../services/dropdownServices";

import {
  StyledCardShadow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTextField,
  StyledDropDown,
  SoftButton,
} from "../../theme/StyledEle";
import { useCreateDealerMutation, useUpdateDealerMutation } from "../../store/slice/AdminSlice/AdminSlice";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import {useAuditLogMutation} from "../../store/slice/ApiSlice";


const globalJson1 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;
var globalDealerId = 0;
if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
) {
} else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
  globalDealerId = globalJson.dealerId;
}
let url = window.location.origin;

export default function CreateDealerForm({ dealerToEdit, onCancel, dealerToView }) {
  const re = /^[0-9\b]+$/;
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [dealer, setDealer] = useState(null);
  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("");
  const [statusEdit, setStatusEdit] = useState(false);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  const [auditLog] = useAuditLogMutation();

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors,isValid },
  } = useForm({
    mode: "all",
  });

  useEffect(async () => {


    if (dealerToEdit || dealerToView) {
      setStatusEdit(true);
      const responseData = (
        await dealerServices.editDealerByGstIn(dealerToEdit.gstIn)
      ).data;
      setDealer(responseData);
      reset({
        address: responseData.address,
        panNo: responseData.panNo,
        dealerId: responseData.dealerId,
        aadharNo: responseData.aadharNo,
        mobileNo: responseData.mobileNo,
        legalName: responseData.legalName,
        tradeName: responseData.tradeName,
        pinCode: responseData.pinCode,
        email: responseData.email,
        gstIn: responseData.gstIn,
      
          //status: responseData.status,
        
       
      });
      setStatusOptions([
        { title: "Approved", value: "0" },
        { title: "Rejected", value: "1" },
      ]);
      const regionListPayload = {
        value: "region",
        searchText: "",
      };
      const { data: regionResponseBody } = await dropdownServices.getResellers(
        regionListPayload
      );
      const { content: regionList } = regionResponseBody.data;
      setRegions(regionList);
      const { data: countrydetails } = await organizationServices.loadCountry();
      setCountries(countrydetails);

      var request1 = {};
      request1.countryId = responseData.country;

      const { data: statedetails } =
        await organizationServices.loadStateByCountry(request1);
      setStates(statedetails);

      var request = {};
      request.id = responseData.state;
      const { data: cityDetails } = await organizationServices.loadCity(
        request
      );
      setCities(cityDetails);

      auditPayload.message = "Admin > Dealer > Edit Dealer";
      auditPayload.action = "View";
      auditPayload.featureName = "Dealer";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
      
    } else {
      if (dealerToEdit === null) {

        const regionListPayload = {
          value: "region",
          searchText: "",
        };
        const { data: regionResponseBody } = await dropdownServices.getResellers(
          regionListPayload
        );
        const { content: regionList } = regionResponseBody.data;
        setRegions(regionList);
        setStates([]);
        setCities([]);
      }


      const { data: countrydetails } = await organizationServices.loadCountry();
      setCountries(countrydetails);

      auditPayload.message = "Admin > Dealer > Create Dealer";
      auditPayload.action = "View";
      auditPayload.featureName = "Dealer";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    }



  }, [setDealer]);



  //Theme
  const theme = useTheme();
  const dispatch = useDispatch();

  //Slice Start
  const [ createDealer, {data:createDealerData, isLoading:createDealerLoading, isSuccess:createDealerSuccess} ] = useCreateDealerMutation();
  const [ updateDealer, {data:updateDealerData, isLoading:updateDealerLoading, isSuccess:updateDealerSuccess} ] = useUpdateDealerMutation();

  const saveDealerDetails = async (data) => {
    var request = {};
    request.country = data.country;
    request.address = data.address;
    request.city = data.city;
    request.panNo = data.panNo;
    request.dealerId = data.dealerId;
    request.mobileNo = data.mobileNo;
    request.aadharNo = data.aadharNo;
    request.gstIn = data.gstIn;
    request.legalName = data.legalName;
    request.tradeName = data.tradeName;
    request.pinCode = data.pinCode;
    request.state = data.state;
    request.email = data.email;
    request.resellerId = resellerId;
    request.region = data.region;

    //const responseData = await dealerServices.saveDealerDetails(request);
    const responseData = await createDealer(request);
    dispatch(snackService([responseData.data.resultMessage, responseData.data.resultCode, true]));
    if(responseData.data.resultCode === 0){
      setTimeout(() => onCancel(), 2000);
    }
  
    auditPayload.message = "Admin > Dealer > Create Dealer > Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);


  };

  const updateKYCApproval = async (obj) => {
    obj.GST = dealerToEdit.gstIn;
    obj.resellerId = resellerId;
    obj.path = url;
    const responseData = await updateDealer(obj);
    dispatch(snackService([responseData.data.resultMessage, responseData.data.resultCode, true]));
    if(responseData.data.resultCode === 0){
      setTimeout(() => onCancel(), 2000);
    }
    //const responseData = await dealerServices.updateKYCApproval(obj);

    auditPayload.message = "Admin > Dealer > Edit Dealer > Update";
    auditPayload.action = "Update";
    auditPayload.featureName = "Dealer";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    if (!dealerToEdit) {
      saveDealerDetails(data);
    } else {
      updateKYCApproval(data);
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };



  const handleCountrySelect = async (countryId) => {
    var request = {};
    request.countryId = countryId;
    const { data: statedetails } =
      await organizationServices.loadStateByCountry(request);
    setStates(statedetails);
  };
  const handleStateSelect = async (stateId) => {
    var request = {};
    request.id = stateId;
    const { data: cityDetails } = await organizationServices.loadCity(request);
    setCities(cityDetails);
  };




  return (
    <Box>
      <StyledCardShadow elevation={0}>
        <CardContent component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Legal Name
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.legalName && true}
                  size="small"
                  fullWidth
                  {...register("legalName", {
                    required: {
                      value: true,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("legalName", e.target.value)}
                  helperText={errors.legalName && errors.legalName.message}
                  disabled={dealerToView !== null ? true : false}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Address
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.address && true}
                  size="small"
                  fullWidth
                  {...register("address", {
                    required: {
                      value: true,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("address", e.target.value)}
                  helperText={errors.address && errors.address.message}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginTop: "0px" }}>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Country
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.mfgSiteCode}
                  {...register("country", {
                    required: {
                      value:  dealerToEdit !== null ? false:true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || " Required Field";
                    },
                  })}
                  onChange={(e) => {
                    setValue("country", e.target.value);
                    handleCountrySelect(e.target.value);
                  }}
                  size="small"
                  defaultValue={
                    dealerToEdit !== null ? dealerToEdit.country : "0"
                  }
                >
                  <MenuItem value="0">Select</MenuItem>
                  {countries.length > 0 &&
                    countries.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                {errors.country && (
                  <FormHelperText error={errors.country}>
                    {errors.country.message}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  State/Province
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.state}
                  {...register("state", {
                    required: {
                      value: dealerToEdit !== null ? false:true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || " Required Field";
                    },
                  })}
                  onChange={(e) => {
                    setValue("state", e.target.value);
                    handleStateSelect(e.target.value);
                  }}
                  size="small"
                  defaultValue={
                    dealerToEdit !== null ? dealerToEdit.state : "0"
                  }
                >
                  <MenuItem value="0">Select</MenuItem>
                  {states.length > 0 &&
                    states.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                {errors.state && (
                  <FormHelperText error={errors.state}>
                    {errors.state.message}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginTop: "0px" }}>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>City</StyledFormControlLabel>
                <StyledDropDown
                  error={errors.city}
                  {...register("city", {
                    required: {
                      value: dealerToEdit !== null ? false:true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || " Required Field";
                    },
                  })}
                  onChange={(e) => {
                    setValue("city", e.target.value);
                  }}
                  size="small"
                  defaultValue={dealerToEdit !== null ? dealerToEdit.city : "0"}
                >
                  <MenuItem value="0">Select</MenuItem>
                  {cities.length > 0 &&
                    cities.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                {errors.city && (
                  <FormHelperText error={errors.city}>
                    {errors.city.message}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>Region</StyledFormControlLabel>
                <StyledDropDown
                  error={errors.region}
                  {...register("region", {
                    required: {
                      value: dealerToEdit !== null ? false:true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return fieldValue !== "0" || " Required Field";
                    },
                  })}
                  onChange={(e) => {
                    setValue("region", e.target.value);
                  }}
                  size="small"
                  defaultValue={
                    dealerToEdit !== null ? dealerToEdit.region : "0"
                  }
                >
                  <MenuItem value="0">Select</MenuItem>
                  {regions.length > 0 &&
                    regions.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.value}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
                {errors.region && (
                  <FormHelperText error={errors.region}>
                    {errors.region.message}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Pincode
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.pinCode && true}
                  size="small"
                  fullWidth
                  {...register("pinCode", {
                    required: {
                      value: true,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(event) =>
                    event.target.value === "" || re.test(event.target.value)
                      ? setValue("pinCode", event.target.value)
                      : setValue("pinCode", "")
                  }
                  helperText={errors.pinCode && errors.pinCode.message}
                  inputProps={{ maxLength: "6" }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>Aadhar No</StyledFormControlLabel>
                <StyledTextField
                  error={errors.aadharNo && true}
                  size="small"
                  fullWidth
                  {...register("aadharNo", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("aadharNo", e.target.value)}
                  helperText={errors.aadharNo && errors.aadharNo.message}
                  inputProps={{ maxLength: "12"}}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel>PAN No</StyledFormControlLabel>
                <StyledTextField
                  error={errors.panNo && true}
                  size="small"
                  fullWidth
                  {...register("panNo", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("panNo", e.target.value)}
                  helperText={errors.panNo && errors.panNo.message}
                  inputProps={{ maxLength: "10"}}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Trade Name
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.tradeName && true}
                  size="small"
                  fullWidth
                  {...register("tradeName", {
                    required: {
                      value: true,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("tradeName", e.target.value)}
                  helperText={errors.tradeName && errors.tradeName.message}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>Mobile</StyledFormControlLabel>
                <StyledTextField
                  error={errors.mobileNo && true}
                  size="small"
                  fullWidth
                  {...register("mobileNo", {
                    required: {
                      value: true,
                      message: "Required Field ",
                    },
                    minLength: {
                      value: 10,
                      message: "Invalid",
                    },
                    maxLength: {
                      value: 10,
                      message: "Invalid",
                    },
                  })}
                  onChange={(event) =>
                    event.target.value === "" || re.test(event.target.value)
                      ? setValue("mobileNo", event.target.value)
                      : setValue("mobileNo", "")
                  }
                  helperText={errors.mobileNo && errors.mobileNo.message}
                  inputProps={{ maxLength: "10" }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>
                  Email ID
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.email && true}
                  size="small"
                  fullWidth
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("email", e.target.value)}
                  helperText={errors.email && errors.email.message}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel required>GST IN</StyledFormControlLabel>
                <StyledTextField
                  error={errors.gstIn && true}
                  size="small"
                  fullWidth
                  {...register("gstIn", {
                    required: {
                      value: true,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("gstIn", e.target.value)}
                  helperText={errors.gstIn && errors.gstIn.message}
                  inputProps={{ maxLength: "15"}}
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6}>
              <StyledFormControl fullWidth>
                <StyledFormControlLabel >
                  Dealer Id
                </StyledFormControlLabel>
                <StyledTextField
                  error={errors.dealerId && true}
                  size="small"
                  fullWidth
                  {...register("dealerId", {
                    required: {
                      value: false,
                      message: "Required Field ",
                    },
                  })}
                  onChange={(e) => setValue("dealerId", e.target.value)}
                  helperText={errors.dealerId && errors.dealerId.message}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
          {dealerToEdit != null && (
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item lg={6}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Status
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.status}
                    {...register("status", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "-1" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("status", e.target.value);
                    }}
                    defaultValue={
                      dealerToEdit !== null
                        ? dealerToEdit.status === "Pending"
                          ? "-1"
                          : dealerToEdit.status === "Approved"
                          ? "0"
                          : "1"
                        : "-1"
                    }
                    size="small"
                    disabled={dealerToEdit.status === "Pending" ? false : true}
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {statusOptions.length > 0 &&
                      statusOptions.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  {errors.status && (
                    <FormHelperText error={errors.status}>
                      {errors.status.message}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
            </Grid>
          )}
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={2}
            sx={{ mt: 2 }}
          >
            <SoftButton
              variant="contained"
              color="error"
              className="error"
              size="small"
              onClick={handleCancelClick}
            >
              Cancel
            </SoftButton>

            {dealerToEdit !== null && dealerToEdit.status === "Pending" ? (
              <SoftButton
                variant="contained"
                color="success"
                className="success"
                size="small"
                type="submit"
                sx={{cursor:updateDealerLoading ? 'wait':'pointer'}}
              >
               {
                    updateDealerLoading ? <ScaleLoader color={theme.palette.primary.contrastText} height={12}/> : 'Update'
                  }
                
              </SoftButton>
            ) : (
              dealerToEdit === null && (
                <SoftButton
                  variant="contained"
                  color="success"
                  className="success"
                  type="submit"
                  size="small"
                  sx={{cursor:createDealerLoading ? 'wait':'pointer'}}
                >
                  {
                    createDealerLoading ? <ScaleLoader color={theme.palette.primary.contrastText} height={12}/> : 'Submit'
                  }
                </SoftButton>
              )
            )}
          </Stack>
        </CardContent>
      </StyledCardShadow>

      {/* </form> */}
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          handleSbmtClose={handleSbmtClose}
          dialogText={dialogContent.dialogText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          handleSbmtClose={handleSbmtClose}
          dialogText={dialogContent.dialogText}
          handleClearCls={handleClearCls}
        />
      )}
    </Box>
  );
}
