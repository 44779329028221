import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FormIconStack, StyledFilterFormControl, StyledFiterButton, StyledFormControl, StyledFormControlLabel, StyledPaperShadow, StyledTextField } from '../../theme/StyledEle'
import { useGetDropdownListMutation, useGetVehicleDropdownListMutation } from '../../store/slice/ApiSlice';
import { parseJSON } from 'date-fns';
import { useCallback } from 'react';
import { Backdrop, Box, ClickAwayListener, Divider, Icon, InputBase, ListItem, ListItemButton, Menu, MenuItem, SvgIcon, useTheme } from '@mui/material';
import { StyledMapDatePiker } from '../NewTracking/Treeview/TreeviewStyledEle';
import { DateRangePicker } from 'rsuite';
import { HiOutlineCalendar } from 'react-icons/hi';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { DatePicker as AntMonthPicker, Space } from 'antd';
import { Calender } from '../../constants/DualIcons';
import SingleDropdown from '../NewDropdown/SingleDropdown';
import { consentData, customerAssinData, kycLogFeedStatusData, kycLogProcessingStatusData, kycLogSearchByData, kycLogStatusData, kycStatusData } from '../AdminDropdown/MockData';
import DatepickerDropdown from '../AdminDropdown/DatepickerDropdown';
import SingleInput from '../NewDropdown/SingleInput';

function KycLogDropdown(props) {

    //user data form local storage
    const userData = JSON.parse(localStorage.getItem('userData'));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let RoleId = userData.roleId;
    let userOrgType = userData.userOrgType;
    const theme = useTheme();

    //Form Props
    const { getData, isShowAll=true, monthPicker=false, allowDays, last7=true, thisMonth=false, currentMonth=false, showCalendar=true, onload=true, value=0, tabValue  } = props; 

    //State for Dropdowns
    const [regionList, setRegionList] = useState(null);
    const [dealerList, setDealerList] = useState(null);
    const [customerList, setCustomerList] = useState(null);
    const [fleetList, setFleetList] = useState(null);
    const [vehicleList, setVehicleList] = useState(null);
   
    //Date Range Picker & Month Picker
    const [showMonthPicker, setShowMonthPicker] = useState(monthPicker);

    //Select from date & To date
    let [label, setLabel] = useState('Today');
    const [showToday, setShowToday] = useState(false);
    const [selectedPriod, setSelectedPriod] = useState(1); // Only for vehicle summary report
    const [openMonthPicker, setOpenMonthPicker] = useState(false);
    
    //All Single State
    const [region, setRegion] = useState(null);
    const [dealer, setDealer] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [fleet, setFleet] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [vehicleVin, setVehicleVin] = useState(null);
    const [allOption, setAllOption] = useState(isShowAll);
    const [status, setStatus] = useState(tabValue === 2 ? 'pending' : tabValue === 3 ? 'Valid' : 'All');
    const [searchBy, setSearchBy] = useState('All');
    const [searchType, setSearchType] = useState(0);
    const [consent, setConsent] = useState('0');
    const [customerAssigned, setCustomerAssigned] = useState('0');
    const [kycStatus, setKycStatus] = useState('Active');
    const [load, setLoad] = useState(false);
    const [vin, setVin] = useState('-1'); // Vin Log
    const [invoiceNo, setInviceNo] = useState('');


    //
    const [selectedVehicle, setSelectedVehicle]= useState(null);
    const [searchText, setSearchText] = useState({value:null, name:null})
    const [search, setSearch] = useState(false);
    //Get Region List
    const [getRegionList, {data:regionData, isLoading:regionLoading, isSuccess:regionSuccess}] = useGetDropdownListMutation();
    const [getDealerList, {data:dealerdata, isLoading:dealerLoading, isSuccess:dealerSuccess}] = useGetDropdownListMutation();
    const [getCustomerList, {data:customerdata, isLoading:customerLoading, isSuccess:customerSuccess}] = useGetDropdownListMutation();
    const [getFleetList, {data:fleetdata, isLoading:fleetLoading, isSuccess:fleetSuccess}] = useGetDropdownListMutation();
    const [getVehicleList, {data:vehicledata, isLoading:vehicleLoading, isSuccess:vehicleSuccess}] = useGetVehicleDropdownListMutation();
    const [getVehicleSearch, {data:searchVehicles, isLoading:searchVehicleLoading, isSuccess:searchVehicleSuccess}] = useGetVehicleDropdownListMutation();

    let CommonPayload = {
        "resellerId":resellerId,
    }

     //Treeview Work based this logins
  /* 
  +----+-------------------------+--------+------+
  | ID | ROLE_DESC               | STATUS | TYPE |
  +----+-------------------------+--------+------+
  |  6 | Organization Admin      | Active |    2 |
  |  9 | Customer Admin          | Active |    3 |
  |  20 | Dealer Admin          | Active |    3 |
  */
    
    //Region List Loaded
    let regionPayload = {
        "value":"region",
        "searchText":""
    }
    //Dealor Login Payload
    let DealorPayload = {
        "resellerId": resellerId, // Set Login Dealer ID
        "value": "customerss",
        "dealerId": orgId,
        "searchText": ""
    }
    //Customer Login Payload
    let CustomerPayload = {
        "resellerId": resellerId, // Set Login Customer ID
        "value": "fleet",
        "dealerId": "0",
        "customerId":orgId,
        "searchText": ""
    }
    //Fleet Payload
    let FleetPayload = {
        "value": "orgId", 
        "paramValue": "113849", // Set Login Fleet ID
        "searchText": ""
    }

    //Based Login Implemented
    const reportsOnload = () => {
        switch(RoleId){
            case 6:
                getRegionList(regionPayload);
            break;
            case 17:
                getRegionList(regionPayload);
            break;
            case 20:
                getRegionList(regionPayload);
                getCustomer(orgId);
                break;
            case 9:
                getFleet(orgId);
                setCustomer(orgId);
        }
    }
    
    useEffect(() => {
        reportsOnload();
    },[])

    useEffect(() => {
        if(regionSuccess){
            setRegionList(regionData?.data);
            if(!allOption && selectedVehicle === null){
                getDealer(regionData?.data.content[0].key);
                setLoad(true);
            }else if(search && selectedVehicle !== null){
                getDealer(selectedVehicle[0].regionId);
            }
        }
        if(allOption && selectedVehicle === null){
            setRegion('-1');
        }
    },[regionSuccess])

    //getDealer
    const getDealer = (regionId) => {
        setRegion(regionId);
       
        if(regionId !== '-1'){
            CommonPayload.value = 'dealer';
            CommonPayload.regionId = regionId;
            CommonPayload.dealerId = '0';
            CommonPayload.searchText = '';
            getDealerList(CommonPayload);
            if(allOption && selectedVehicle === null){
                setDealer('-1');
                setCustomer('-1');
                setFleet('-1');
                setVehicle('-1');
                setDealerList(null);
                setCustomerList(null);
                setFleetList(null);
                setVehicleList(null);
            }
        }else{
           setDealer('-1');
           setCustomer('-1');
           setFleet('-1');
           setVehicle('-1');
           setDealerList(null);
           setCustomerList(null);
           setFleetList(null);
           setVehicleList(null);
           setSelectedVehicle(null);
        }
    }
    useLayoutEffect(() => {
        if(dealerSuccess){
            setDealerList(dealerdata?.data);
            if(!allOption && selectedVehicle === null){
                getCustomer(dealerdata?.data.content[0]?.key);
            }else if(search && selectedVehicle !== null ){
                getCustomer(selectedVehicle[0]?.dealerId);
            }
        }
        if(allOption && selectedVehicle === null){
            setDealer('-1');
        }
    },[dealerSuccess])
    
    //getCustomer
    const getCustomer = (dealerId) => {
        setDealer(dealerId);
        if(dealerId !== '-1'){
            CommonPayload.value = 'customer';
            CommonPayload.dealerId = dealerId;
            CommonPayload.searchText = '';
            getCustomerList(CommonPayload);
            if(allOption && selectedVehicle === null){
                setCustomer('-1');
                setFleet('-1');
                setVehicle('-1');
                setCustomerList(null);
                setFleetList(null);
                setVehicleList(null);
            }
        }else{
           setCustomer('-1');
           setFleet('-1');
           setVehicle('-1');
           setCustomerList(null);
           setFleetList(null);
           setVehicleList(null);
           setSelectedVehicle(null);
        }
    }
    useLayoutEffect(() => {
        if(customerSuccess){
            setCustomerList(customerdata?.data);
            if(!allOption && selectedVehicle === null){
                getFleet(customerdata?.data?.content[0]?.key);
            }else if(search && selectedVehicle !== null ){
                getFleet(selectedVehicle[0]?.customerId);
            }
            }
            if(allOption && selectedVehicle === null){
                setCustomer('-1');  
            }
    },[customerSuccess])

    
    //getFleet
    const getFleet = (customerId) => {
        setCustomer(customerId);
        if(customerId !== '-1'){
            CommonPayload.value = 'fleet';
            CommonPayload.dealerId = '0';
            CommonPayload.customerId = customerId;
            CommonPayload.searchText = '';
            getFleetList(CommonPayload);
            if(allOption && selectedVehicle === null ){
                setFleet('-1');
                setVehicle('-1');
                setFleetList(null);
                setVehicleList(null);
            }
        }else{
            setFleet('-1');
            setVehicle('-1');
            setFleetList(null);
            setVehicleList(null);
            setSelectedVehicle(null);
        }
    }
    
    useLayoutEffect(() => {
        if(fleetSuccess){
            setFleetList(fleetdata?.data);
            if(!allOption && selectedVehicle === null){
                getVehicle(fleetdata?.data?.content[0]?.key)
            }else if(search && selectedVehicle !== null ){
                getVehicle(selectedVehicle[0]?.fleetId);
            }
            if(RoleId === 9){
                getVehicle(fleetdata?.data?.content[0]?.key);
            }}
        if(allOption && selectedVehicle === null){
            setFleet('-1');
        }
    },[fleetSuccess]);

   
    //Get Vehicle
    const getVehicle = (fleetId) => {
        setFleet(fleetId);
        if(fleetId !== '-1'){
            let vehilePayload = {
                "value":"orgId",
                "paramValue":fleetId,
                "searchText":""
            }
            getVehicleList(vehilePayload);
            // if(allOption && selectedVehicle === null){
            //     setVehicle('-1');
            //     setVehicleList(null);
            // }
        }else{
            setVehicle('-1');
            setVehicleList(null);
            setSelectedVehicle(null);
        }
    }
    useLayoutEffect(() => {
        if(vehicleSuccess){
            setVehicleList(vehicledata?.data);
            if(search){
                setSearch(false);
            }
            // if(selectedVehicle !== null){ 
            //     setVehicle(selectedVehicle[0].key);
            //     setVehicleList(vehicledata?.data);
            // }

            if(!allOption && selectedVehicle === null){
                selectVehicle(vehicledata?.data?.content[0]?.key);
                setVehicleVin(vehicledata?.data?.content[0]?.value);
                setLoad(true);
            }
            if(fleet !== undefined && RoleId === 9 && fleet !== null){
                setLoad(true);
            }
        }
        if(allOption && selectedVehicle === null){
            setVehicle('-1');
            setLoad(true);
        }
        if(RoleId !== 9){
            setLoad(true);
        }
        
    },[vehicleSuccess]);


    //Select Vehicle
    const selectVehicle = (vehicleId, data) => {
        //setSelectedVehicle(null);
        if(vehicleId !== undefined){
            const getVehicleVin = vehicledata?.data?.content.filter((item) => item.key === vehicleId);
            setVehicleVin(getVehicleVin[0].value);
        }
        setVehicle(vehicleId);
        if(search && vehicleId !== '-1' && vehicleId !== undefined){
            const SelctedVehicleData = searchVehicles?.data?.content.filter((item) => item.key === vehicleId);
            if(!allOption && search){
                setRegion(SelctedVehicleData[0]?.regionId);
                getDealer(SelctedVehicleData[0]?.regionId);
            }
            if(allOption && search){
                setRegion(SelctedVehicleData[0]?.regionId);
                getDealer(SelctedVehicleData[0]?.regionId);
                setVehicle(SelctedVehicleData[0]?.key)
            }
            setSelectedVehicle(SelctedVehicleData);
            //getVehicle(SelctedVehicleData[0].fleetId);
            setSearchText({value:null});
        }
        if(vehicleId === '-1' && selectedVehicle !== null){
            setSelectedVehicle(null);
            getVehicle(fleet);
            setVehicle('-1');
        }
       
    }

    useEffect(() => {
        if(!search){
            setSelectedVehicle(null);
        }
    },[search]);


    //-----------------------------------------------------------------------------------------------------------------
    // vehicleSearch
   
    const vehicleSearch = (searchText) => {
        let vehicleSeatchPayload = {
            "resellerId":resellerId,
            "searchText":searchText,
            "page":'0',
            "size":'10',
            "orgType":userOrgType,
            "dealerId":orgId,
            "vehicleAllFilter":true,
            "imeiSearch":""
        }
        if(searchText !== ''){
            getVehicleSearch(vehicleSeatchPayload)
        }else{
            setSelectedVehicle(null);
            setVehicleList(vehicledata?.data);
        }
    }
    useLayoutEffect(() => {
        if(searchVehicleSuccess){
            if(searchVehicles.data !== null){
                setVehicleList(searchVehicles.data);
            }
        }
    },[searchVehicleSuccess]);

    const regionSearch = (searchText) => {
        CommonPayload.value = 'region';
        CommonPayload.searchText = searchText;
        CommonPayload.dealerId = '-1';
        getRegionList(CommonPayload);
    }
    const dealerSearch = (searchText) => {
        CommonPayload.value = 'dealer';
        CommonPayload.searchText = searchText;
        CommonPayload.regionId = region;
        if(region !== '-1'){
            getDealerList(CommonPayload);
        }
    }
    const customerSearch = (searchText) => {
        CommonPayload.value = 'customer';
        CommonPayload.searchText = searchText;
        CommonPayload.dealerId = dealer;
        if(dealer !== '-1'){
            getCustomerList(CommonPayload);
        }
    }
    const FleetSearch = (searchText) => {
        CommonPayload.value = 'fleet';
        CommonPayload.dealerId = '0'
        CommonPayload.searchText = searchText;
        CommonPayload.customerId = customer;
        if(customer !== '-1'){
            getFleetList(CommonPayload);
        }
    }

    //search
    const dropDownSearch = (searchText, name) => {
            if(name === 'Vehicle'){
                setSearchText({value:searchText});
                setSearchText({name:name})
                setSearch(true);
                vehicleSearch(searchText);
            }else if(name === 'Region'){
                regionSearch(searchText);
            }else if(name === 'Dealer'){
                dealerSearch(searchText);
            }else if(name === 'Customer'){
                customerSearch(searchText);
            }else if(name === 'Fleet'){
                FleetSearch(searchText);
            }
    }




     useEffect(() => {
        if(!allOption && load && vehicle === vehicle && onload){
            getFormData();
        }
     },[vehicle])

     useEffect(() => {
        if(RoleId === 9){
            setFleet(fleetdata?.data?.content[0]?.key);
        }
     },[fleetdata, fleet]);

     useEffect(() => {
        if(searchBy === 'Date'){
            setDateShow(true);
            setVinShow(false);
            setInviocNumShow(false);
            setInviceNo('');
            setVin('-1');
            setSearchType(1);
            setActiveLabel('today');
            setFromDate(moment().format("YYYY-MM-DD 00:00:00"));
            setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
        }else if(searchBy === 'VIN'){
            setInviceNo('');
            setDateShow(false);
            setVinShow(true);
            setInviocNumShow(false);
            setSearchType(2);
            setActiveLabel(null);
            setFromDate(moment().subtract(3, 'month').format("YYYY-MM-DD 00:00:00"));
            setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
        }else if(searchBy === 'Invoice Retail Number'){
            setDateShow(false);
            setVinShow(false);
            setInviocNumShow(true);
            setVin('-1');
            setSearchType(3);
            setActiveLabel(null);
            setFromDate(moment().subtract(3, 'month').format("YYYY-MM-DD 00:00:00"));
            setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
        }else{
            setDateShow(false);
            setVinShow(false);
            setInviocNumShow(false);
            setSearchType(0);
            setFromDate(moment().subtract(3, 'month').format("YYYY-MM-DD 00:00:00"));
            setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
            setActiveLabel(null);
        }
     },[searchBy])

   
     

     
    const [regionShow, setRegionShow] = useState(false);
    const [dealerShow, setDealerShow] = useState(false);
    const [customerShow, setCustomerShow] = useState(false);
    const [fleetShow, setFleetShow] = useState(false);
    const [vehicleShow, setVehicleShow] = useState(false);
    const [kycStatusShow, setKycStatusShow] = useState(false);
    const [statusShow, setStatusShow] = useState(false);
    const [customerAShow, setCustomerAShow] = useState(false);
    const [consentShow,  setConsentShow] = useState(false);
    const [searchByShow,  setSearchByShow] = useState(false);
    const [dateShow, setDateShow] = useState(false);
    const [vinShow, setVinShow] = useState(false);
    const [inviocNumShow, setInviocNumShow] = useState(false);

   useEffect(() => {
    if(tabValue === 0){
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
    }else if(tabValue === 1){
        setKycStatusShow(true);
        setStatusShow(true);
        setCustomerAShow(true);
        setConsentShow(true);
        setSearchByShow(true);
    }else if(tabValue === 2){
        setKycStatusShow(true);
        setStatusShow(true);
        setSearchByShow(true);
    }else {
        setStatusShow(true);
        setSearchByShow(true);
    }
   },[tabValue])

   //GetDate
   const [fromDate, setFromDate] = useState(moment().subtract(3, 'month').format("YYYY-MM-DD 00:00:00"));
   const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
   const [activeLabel, setActiveLabel] = useState(null);
   const GetDate = (fromDate, toDate) => {
       setFromDate(fromDate);
       setToDate(toDate);
   };


     //getForm Data
     const getFormData = () => {
        let FormData = {
            'regionId':RoleId === 9 ? 0 : RoleId === 20 ? 0 : region,
            'dealerId':RoleId === 9 ? 0 : RoleId === 20 ? orgId : dealer,
            'customerId':RoleId === 9 ? orgId : customer,
            'fleetId':fleet,
            'vehicleId':vehicle,
            'vehicleVin':vehicleVin,
            'searchById':searchBy,
            'statusId':status,
            'consentId':consent,
            'customerAssigned':customerAssigned,
            'kycStatus':kycStatus,
            'fromDate':fromDate,
            'toDate':toDate,
            'invoiceNo':invoiceNo,
            'vinNo':vin,
            'searchType':searchType,
        }
        //Send data to report 
        getData(FormData);
     }
 
     //Onload Time get Data
     useEffect(() => {
        if(load && allOption && region !==null){
            getFormData();
        }else if(load && region !==null){
            getFormData();
        }
     },[load]);



  return (
    <StyledPaperShadow elevation={0} sx={{display:'inline-block', width:'100%'}}>
        {
            regionShow &&
            <SingleDropdown
            label={'Region'}
            listItems={regionList}
            handleChange={getDealer}
            value={region}
            isSearch={dropDownSearch}
            showAll={allOption}
        />
        }
        {
            dealerShow && 
            <SingleDropdown
                label={'Dealer'}
                listItems={dealerList}
                handleChange={getCustomer}
                value={dealer}
                isSearch={dropDownSearch}
                showAll={allOption}
            />
        }
        {
            customerShow &&
            <SingleDropdown
                label={'Customer'}
                listItems={customerList}
                handleChange={getFleet}
                value={customer}
                isSearch={dropDownSearch}
                showAll={allOption}
            />
        }
        {
            fleetShow &&
            <SingleDropdown
                label={'Fleet'}
                listItems={fleetList}
                handleChange={getVehicle}
                value={fleet}
                isSearch={dropDownSearch}
                showAll={RoleId === 9 ? !allOption : allOption}
            />
        }
        {
            vehicleShow &&
            <SingleDropdown
                label={'Vehicle'}
                listItems={vehicleList}
                onClick={() => setSearch(false)}
                handleChange={selectVehicle}
                value={selectedVehicle !== null ? selectedVehicle[0]?.key : vehicle}
                isSearch={dropDownSearch}
                searchText={searchText}
                showAll={allOption}
            />
        }

        {
            searchByShow && 
            <SingleDropdown
                label={'Search By'}
                listItems={{content:kycLogSearchByData}}
                handleChange={(searchById) => setSearchBy(searchById)}
                value={searchBy}
                isSearch={false}
                searchText={false}
                showAll={true}
                customAllValue={'All'}
            />
        }
        {
            vinShow &&
            <SingleDropdown
            label={"VIN"}
            //This Vin Log
            listItems={vin}
            handleChange={(vinNo) => setVin(vinNo)}
            value={vin}
            //isSearch={false}
            showAll={true}
            />
        }
        {
            inviocNumShow &&
            <SingleInput handleChange={(invioceNo) => setInviceNo(invioceNo)} value={invoiceNo} label={'Invoice No'}/>
        }
       
        {
            dateShow && <DatepickerDropdown getDate={GetDate} activeLabel={activeLabel} showAll={false}/>
        }
        {
            statusShow && 
            <SingleDropdown
            label={'Status'}
            listItems={{content: tabValue === 2 ? kycLogProcessingStatusData : tabValue === 3 ? kycLogFeedStatusData : kycLogStatusData }}
            handleChange={(StatusId) => setStatus(StatusId)}
            value={status}
            isSearch={false}
            searchText={false}
            showAll={true}
            customAllValue={tabValue === 2 ? 'All' : tabValue === 3 ? 'All' : 'All'}
            />
        }
        {
            consentShow && 
            <SingleDropdown
                    label={'Consent'}
                    listItems={{content:consentData}}
                    handleChange={(consentId) => setConsent(consentId)}
                    value={consent}
                    isSearch={false}
                    searchText={false}
                    showAll={true}
                    customAllValue={'0'}
                />
        }
        {
            customerAShow &&
            <SingleDropdown
                    label={'Customer Assigned'}
                    listItems={{content:customerAssinData}}
                    handleChange={(cutomerAssignId) => setCustomerAssigned(cutomerAssignId)}
                    value={customerAssigned}
                    isSearch={false}
                    searchText={false}
                    showAll={true}
                    customAllValue={'0'}
                />
        }      

        {
            kycStatusShow &&
            <SingleDropdown
            label={'KYC Status'}
            listItems={{content:kycStatusData}}
            handleChange={(kycStatusId) => setKycStatus(kycStatusId)}
            value={kycStatus}
            isSearch={false}
            searchText={false}
            showAll={true}
            customAllValue={'All'}
        />
        } 
                
            {/* Submit */}
            <StyledFiterButton
                type='button'
                variant='contained'
                size='small'
                color='success'
                onClick={getFormData}
                sx={{marginTop:'25px'}}
            >
                Submit
            </StyledFiterButton>


            {/* Date Picker Menu */}

            
    </StyledPaperShadow>
  )
}

export default KycLogDropdown