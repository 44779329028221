import { dashboardApi } from "./ApiSlice";

const UploadSlice = dashboardApi.injectEndpoints({
    endpoints: (builder) => ({
        //sim validate
        validateSim:builder.mutation({
            query:(imageFile) => {
                return {
                url:'/sim/uploadExcel',
                method:'POST',
                body:imageFile,
                }
            }
        }),

        //Sim Upload /sim/processExcel
        uploadSim:builder.mutation({
            query:(imageFile) => {
                return {
                url:'/sim/processExcel',
                method:'POST',
                body:imageFile,
                }
            }
        }),

        //Device Validate
        validateDevice:builder.mutation({
            query:(imageFile) => {
                return {
                url:'/device/validateExcel',
                method:'POST',
                body:imageFile,
                }
            }
        }),
    })
})

export const { useValidateSimMutation, useUploadSimMutation, useValidateDeviceMutation } = UploadSlice;