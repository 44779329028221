import React, { useEffect, useMemo, useState} from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader} from "@mui/material";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import KycLogDropdown from "../LogDropdown/KYCDropdown";
import { useListFeedProcessMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import FormDialog from "../../Utils/FormDialog";
import KYCEdit from "./KYCEdit";
import { useLocation, useNavigate } from "react-router-dom";


const ListKycProcessedFeed = ({ tabValue}) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [id, setId] = useState();

  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });

  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let resellerId = userData.resellerId;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date & Time",
        accessor: "createdDate",
      },
      {
        Header: "Vehicle Details",
        columns: [
          {
            Header: "VIN",
            accessor: "vin",
          },
          {
            Header: "Vehicle Number",
            accessor: "engineNumber",
          },
          {
            Header: "Vehicle Model",
            accessor: "vehicleModel",
          },
        ],
      },
      {
        Header: "Customer Details",
        columns: [
          {
            Header: "Name",
            accessor: "customerName",
          },
          {
            Header: "Mobile No",
            accessor: "customerMobile1",
          },
          {
            Header: "Email",
            accessor: "customerEmail",
          },
          {
            Header: "Retail Invoice No",
            accessor: "retailInvoiceNumber",
          },
          {
            Header: "License",
            accessor: "licenseNumber",
          },
          {
            Header: "Aadhar",
            accessor: "aadharNumber",
          },
        ],
      },
      {
        Header: "Dealer Details",
        columns: [
          {
            Header: "Code",
            accessor: "dealerCode",
          },
          {
            Header: "Name",
            accessor: "dealerName",
          },
          {
            Header: "Email",
            accessor: "dealerContactName",
          },
          {
            Header: "Mobile",
            accessor: "dealerContactNumber",
          },
          {
            Header: "GST No",
            accessor: "gstInNew",
          },
        ],
      },
      {
        Header: "KYC Mode",
        accessor: "kycType",
      },
      {
        Header: "Valid KYC Count",
        accessor: "recordCount",
      },
      {
        Header: "Ticket",
        accessor: "createTicket",
      },
      {
        Header: "Last KYC Rejected Time",
        accessor: "lastKycRejectedTime",
      },
      {
        Header: "KYC Approved Time",
        accessor: "kycApprovedTime",
      },

      {
        Header: "Active Status",
        accessor: "state",
      },
      {
        Header: "KYC Status",
        accessor: "kycStatus",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const [ getListFeedPro, {data:kycFeedData, isLoading, isSuccess} ] = useListFeedProcessMutation();
  let commonPayload = { }
  const [payload, setPayload] = useState(null);
  const getData = (data) => {
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.sortBy = 'desc';
    commonPayload.selectedType = data.searchType !== undefined?data.searchType:data.selectedType;
    commonPayload.vinVal = 0;
    commonPayload.state = data.statusId !== undefined?data.statusId:data.state;
    commonPayload.kycStatus = data.kycStatus;
    commonPayload.retailVal = data.invoiceNo !== undefined?data.invoiceNo:data.retailVal;
    commonPayload.search = '';
    commonPayload.startDate = data.fromDate !== undefined?data.fromDate:data.startDate;
    commonPayload.endDate = data.toDate !== undefined?data.toDate:data.endDate;
    commonPayload.resellerId = resellerId;
    setPayload(commonPayload);
    getListFeedPro(commonPayload);
    getActionBtn();
  }

  const getActionBtn = () => {
    if ("null" !== JSON.parse(localStorage.getItem("menuList")) && null !== JSON.parse(localStorage.getItem("menuList"))) {
    if (JSON.parse(localStorage.getItem("menuList")) !== null) {
      for ( let i = 0; i < JSON.parse(localStorage.getItem("menuList")).length; i++) 
      {
        if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) 
        {
          for (
            let j = 0;
            j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
            j++
          ) {
            if (
              JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
              "KycLog"
            ) {
              var dataStr = {};
              dataStr.create = false;
              dataStr.edit = false;
              dataStr.delete = false;
              dataStr.view = false;
              dataStr.recentActivation = false;
              dataStr.importfunction = false;
              dataStr.excel = false;
              dataStr.pdf = false;
              var strval;
              strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                j
              ].button.split(",");
              var edit = false;
              for (var k = 0; k < strval.length; k++) {
                if (parseInt(strval[k]) === 1) {
                  dataStr.create = true;
                }
                if (parseInt(strval[k]) === 2) {
                  dataStr.view = true;
                }
                if (parseInt(strval[k]) === 4) {
                  dataStr.edit = true;
                }
                if (parseInt(strval[k]) === 3) {
                  dataStr.delete = true;
                }
                if (parseInt(strval[k]) === 18) {
                  dataStr.recentActivation = true;
                }
                if (parseInt(strval[k]) === 9) {
                  dataStr.importfunction = true;
                }
                if (parseInt(strval[k]) === 5) {
                  dataStr.excel = true;
                }
                if (parseInt(strval[k]) === 6) {
                  dataStr.pdf = true;
                }
              }
              setCommonFilter(dataStr);
              break;
            }
          }
        }
      }
    } 
  }
}

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (kycFeedData.data !== null) {
        setResponseData(kycFeedData.data.content);
        setTotalElements(kycFeedData.data.totalElements);
        setTotalPages(kycFeedData.data.totalPages);
      }
    }
    auditPayload.message = "Reports > KYC Log > KYC Processing";
    auditPayload.action = "View";
    auditPayload.featureName = "KYC Processing";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, [isSuccess]);

  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getListFeedPro(payload);
    } else if (payload.search.length === 0) {
      getListFeedPro(payload);
    }
  };


  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getListFeedPro(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getListFeedPro(payload);
    }
  };
  const onEdit = (itemEdited) => {
    handleDevEdit(itemEdited);

    auditPayload.message = "Reports > KYC Log > KYC Processing > Edit";
    auditPayload.action = "Click";
    auditPayload.featureName = "KYC Processing";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };


  const handleDevEdit = async(kycEditedId) => {
    location.state={'id':kycEditedId.id}
    navigate('#edit');
    setId(kycEditedId.id);
  };


  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "KYCLog/ListKYCProcessedfeedXLS.csv?resellerId=" +
        payload.resellerId +
        "&kycStatus=" +
        payload.kycStatus+
        "&selectedType=" +
        payload.selectedType +
        "&vinVal=" +
        payload.vinVal +
        "&state=" +
        payload.state +
        "&retailVal=" +
        payload.retailVal +
        "&sortBy=" +
        payload.sortBy +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        " ";
      window.open(url);

      auditPayload.message = "Logs > KYC Log > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Logs";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;

      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => { })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  return (
    <Box>
      <KycLogDropdown getData={getData} isShowAll={false} showCalendar={false} tabValue={tabValue}/>
      <StyledCardShadow sx={{ mt: 2 }}>
        <CardHeader 
          title={<StyledTableTitle>KYC Processing</StyledTableTitle>}
        />
        <CardContent sx={{py:0}}>
        <DataTable
          isLoading={isLoading}
          includeSearchBox={true}
          handleChange={handleSearch}
          onEdit={onEdit}
          //   onView={onView}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          page={newPage}
          rowsPerPage={rowsPerPage}
          callbackChangePage={pageChange}
          totalElements={totalElements}
          totalPages={totalPages}
          commonFilter={commonFilter}
          onChangeXcl={onChangeXcl}
        />
        </CardContent>
      </StyledCardShadow>
              {/* Edit Popup */}
              <FormDialog title={'Edit KYC Form'}>
            <KYCEdit ids={id} reload={getData} payload={payload}/>
        </FormDialog>
    </Box>
  );
};

export default ListKycProcessedFeed;
