import Login from "../components/login/login";
import SidebarLayout from "../layout/SidebarLayout";
import { Outlet, Route, Routes } from "react-router-dom";
import GoogleMap from "../components/google-maps/MarkerPolyline";
import PlayBack from "../components/google-maps/index";
import OverSpeedReport from "../components/Reports/OverSpeedReport";
import GeoZone from "../components/geozone/GeoZone";
import Organization from "../components/organization/index";
import User from "../components/user/index";
import Device from "../components/device/index";
import Vehicles from "../components/vehicle";
import Driver from "../components/driver";
import AccountSettings from "../components/account/accountSettings";
import ProfileSettings from "../components/profile-settings/index";
import Sim from "../components/sim/index";
import Alerts from "../components/alerts/index";
import Feature from "../components/feature/index";
import Rolesettings from "../components/rolesettings/index";
import viewAlert from "../components/view-alerts/view";
import ServiceAlert from "../components/service-alerts";
import TravelDetailReport from "../components/Reports/TravelDetailReport";
import TravelSummaryReport from "../components/Reports/TravelSummary";
import IdleReport from "../components/Reports/IdleReport";
import GeoZoneDetail from "../components/Reports/GeoZoneDetail";
import Stoppage from "../components/Reports/Stoppage";
import TripSummary from "../components/Reports/TripSummary";
import TripReport from "../components/Reports/TripReport";
import AvlDataReport from "../components/Reports/AvlDataReport";
import SinocastelData from "../components/Reports/SinocastelData";
import TripRangeReport from "../components/Reports/TripRangeReport";
import TyrePressureReport from "../components/Reports/TyrePressureReport.js";
import EVDataReport from "../components/Reports/EVDataReport";
// import ManualCanDataReport from "../components/Reports/VehicleDataReport";
import serviceAlert from "../components/service-alerts";
import OrgSettings from "../components/orgSettings/orgSettings";
import AuditLog from "../components/Logs/AuditLog";
import UserLog from "../components/Logs/UserLog";
import SendLog from "../components/Logs/SendLog";
import FeedLog from "../components/Logs/Index";
import SignIn1 from "../components/login/forgotPassword";
import SignIn2 from "../components/login/userActivation";
import OrgSetting from "../components/orgSettings";
import OTA from "../components/OTA/OTA";
import KycLog from "../components/KYC-LOGS";
import HelpDesk from "../components/helpdesk/index";
import Dealer from "../components/dealer";
import Tasks from "../components/OTA/tasks";
import FileUpload from "../components/OTA/fileUpload";
import SimOnBoard from "../components/simOnBoard";
import DeviceOnBoard from "../components/deviceOnBoard";
import VinLog from "../components/Logs/VinLog";
import GeoFence from "../components/Reports/GeoFenceReport";
import PageNotFound from "../components/page-not-found/PageNotFound";
import FaultCode from "../components/Reports/FaultCodeReport";
import VehicleDataReport from "../components/Reports/VehicleDataReport";
import ChargingReport from "../components/Reports/ChargingReport";
import MonthlySummary from "../components/Reports/MonthlySummaryReport";
import ThemeEditor from "../settings/ThemeEditor/ThemeEditor";
import VehicleSummaryReport from "../components/Reports/VehicleSummaryReport";
import Dashboard from "../components/dashboard/Dashboard";
import LoginLatest from "../components/login/LoginLatest";
import PlaybackAnimate from "../components/Playback/Index";
import SignIn from "../components/login/login";
import Home from "../components/page-not-found/PageNotFound";
import AuthRoute from "./AuthRoute";


function RouteNew() {
  return (
        <Routes>
            <Route path='/login' exact index element={<SignIn/>} />
            <Route path='/userActivation' exact element={<SignIn2/>} />
            <Route path='/forgotPassword' exact element={<SignIn1/>} />
            
            <Route element={<AuthRoute/>}>
                <Route path='/mapviews' element={<GoogleMap/>} exact/>
                <Route path='/dashboard' element={<Dashboard/>} exact/>
                <Route path='/playback' element={<PlaybackAnimate/>} exact/>
                <Route path='/geozone' element={<GeoZone/>} exact/>
                <Route path='/overSpeed' element={<OverSpeedReport/>} exact/>
                <Route path='/travelDetail' element={<TravelDetailReport/>} exact/>
                <Route path='/travelSummary' element={<TravelSummaryReport/>} exact/>
                <Route path='/idleSummary' element={<IdleReport/>} exact/>
                <Route path='/monthlySummary' element={<MonthlySummary/>} exact/>
                <Route path='/geoZoneDetail' element={<GeoZoneDetail/>} exact/>
                <Route path='/tripSummary' element={<TripSummary/>} exact/>
                <Route path='/tripReport' element={<TripReport/>} exact/>
                <Route path='/AvlDataReport' element={<AvlDataReport/>} exact/>
                <Route path='/sinocastelData' element={<SinocastelData/>} exact/>
                <Route path='/tripRangeReport' element={<TripRangeReport/>} exact/>
                <Route path='/tyrePressureReport' element={<TyrePressureReport/>} exact/>
                <Route path='/evDataReport' element={<EVDataReport/>} exact/>
                <Route path='/VehicleData' element={<VehicleDataReport/>} exact/>
                <Route path='/GeoFenceReport' element={<GeoFence/>} exact/>
                <Route path='/FaultCode' element={<FaultCode/>} exact/>
                <Route path='/ChargingReport' element={<ChargingReport/>} exact/>
                <Route path='/VehicleSummary' element={<VehicleSummaryReport/>} exact/>
                <Route path='/organization' element={<Organization/>} exact/>
                <Route path='/user' element={<User/>} exact/>
                <Route path='/device' element={<Device/>} exact/>
            </Route>
            <Route path="*" element={<Home />} />
        </Routes>
  )
}

export default RouteNew