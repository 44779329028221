import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ButtonAlign = styled(Button)(({ theme }) => ({
  textTransform: "none",
  //backgroundColor: theme.button.background.primary,
  //color: theme.button.color.secondary,
  fontSize: "12px",
  height: "min-content",
  margin: "0px 10px 0px 10px",
  "&:hover": {
    //color: theme.button.color.secondary,
    //backgroundColor: theme.button.background.secondary,
  },
}));
