import { Autocomplete, FormLabel, InputBase, MenuItem, Select, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { StyledPaperShadow } from '../../theme/StyledEle'
import styled from 'styled-components';


export 

function Form(props) {
    const { stateValue, cityValue, stateList, cityList, handleCity, handleState  } = props

    
      const StyledAutoCom = styled(Autocomplete)(({theme}) => ({
        '& .MuiFormLabel-root':{
            left:'10px'
        }
    }))



  
    const BaseInput = styled(InputBase)(({theme}) => ({
        padding:'10px 15px',
        border:'.5px solid #ddd',
        fontWeight:400,
        borderRadius:'10px'
    }))




    const MenuProps = {
        PaperProps: {
          elevation: 0,
          sx: {
            maxHeight: '250px',
            overflowX: "hidden",
            padding: "0",
            borderRadius: "15px",
            boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
            marginTop: "10 px",
            //minWidth:'150px',
            maxWidth: "220px",
            width: "100%",
          },
        },
      };

  return (
    <Box>
        <Stack direction={'row'}>
            <Box sx={{width:'100%', px:3}}>
                <StyledPaperShadow sx={{width:'100%', boxShadow:'0px 0px 12px #1c1c1c33 !important', borderRadius:'15px !important'}}>
                    <Stack direction={'column'} sx={{width:"100%"}} spacing={2}>
                        <FormLabel sx={{ml:1}}>Select State & City</FormLabel>
                        
                        <Box sx={{width:'100%'}}>
                            <Select 
                            value={stateValue} 
                            size='small' 
                            label="State"  
                            fullWidth 
                            onChange={handleState} 
                            input={<BaseInput />}
                            MenuProps={MenuProps}
                            >
                            <MenuItem value={0}>Select State</MenuItem>
                            {
                                stateList !== null && stateList.map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                            }
                            </Select>
                        </Box>
                        <Box sx={{width:'100%'}}>
                            <Select 
                                value={cityValue} 
                                size='small' 
                                label="State"  
                                fullWidth 
                                onChange={handleCity} 
                                input={<BaseInput />}
                                MenuProps={MenuProps}
                                >
                                <MenuItem value={0}>Select City</MenuItem>
                                {
                                    cityList !== null && cityList.map((item, index) => <MenuItem value={item.key} key={index}>{item.value}</MenuItem>)
                                }
                                </Select>
                        </Box>
                    </Stack>
                </StyledPaperShadow>
            </Box>
        </Stack>
    </Box>
  )
}

export default Form