import { Accordion, Box, Button, ListItem, Paper, Radio,  styled } from "@mui/material";
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { color } from "@mui/system";

export const BasePaperTreeView = styled(Paper)(({theme}) => ({
    overflow: "hidden", 
    padding:'0px',
    background:'transparent',
    padding: '2px',
    width: 'fit-content',
    cursor: 'move',
    color: 'white',
    textAlign: 'left',
    cursor: 'move',
    boxShadow:'none',
    '& .baseAccordian ':{
        maxWidth: '400px',
        minWidth: '400px',
        width:'100%',
        boxShadow:'0px 15px 1rem rgb(0 0 0 / 27%)',
        borderRadius:'12px',
        overflow: "hidden", 
        [theme.breakpoints.down('xl')]:{
            maxWidth: '280px',
            minWidth: '280px',
            width:'100%',
            '& .MuiTypography-root':{
                fontSize:'10px'
            },
            '& .MuiAccordionSummary-root':{
                paddingTop:'8px !important',
                paddingBottom:'8px !important'
            },
            '& .MuiSvgIcon-root':{
                fontSize:'1rem'
            },
            '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':{
                margin:'1px 0px!important'
            }
        }
    },
    '& form':{
        [theme.breakpoints.down('xl')]:{
            padding:'0px 5px',
            '& .MuiButtonBase-root':{
                fontSize:'1rem'
            }
        }
    }
    
}))
export const AccordianHolder = styled(Box)(({theme}) => ({
    padding:'0px 0px',
    background:'transparent'
}))
export const SearchPaper = styled(Paper)(({theme}) => ({
    padding:'8px 5px',
    background:theme.palette.mode === 'light' ? theme.palette.grey[100] : '#464f64',
    display: "flex",
    boxShadow:'0px 2px 1.2rem rgba(0,0,0,.2)',

}))
export const StyledBaseAccordian = styled(Accordion)(({theme}) => ({
    background:'transparent',
    boxShadow:'none !important',
    '& .baseAccordianSummary':{
        minHeight:'auto !important',
        background:theme.palette.mode === 'light' ? alpha(theme.palette.primary.main,.8) : theme.palette.background.default,
        '& .MuiAccordionSummary-content.Mui-expanded':{
            margin:'12px 0px !important'
        }
    },
    '& .MuiAccordionSummary-root.Mui-expanded':{    
        minHeight:'auto !important',
        '&.MuiAccordionSummary-content.Mui-expanded':{
            margin:'12px 0px !important'
        }
    },
    '& .MuiAccordionDetails-root ':{
        padding:'0px !important',
        boxShadow:'none',
        background:theme.palette.mode === 'light' ? 'transparent' : '#182136',
        borderRadius:'15px',
    }
}))

export const StyledAccordianTrees = styled(Accordion)(({theme}) => ({
    boxShadow:'none',
    margin:'0px 0px',
    position:'relative',
    background:'transparent',
    '&.Mui-expanded':{
        margin:'0px'
    },
    '& .MuiAccordionSummary-root':{
        padding:'10px 15px',
        //borderRadius:'5px',
        color:'#fff',
        minHeight:'auto',
        position:'relative',
        '&:before':{
            position:'absolute',
            background:theme.palette.divider,
            width:'10px',
            height:'2px',
            content:'""',
            zIndex:'999',
            borderRadius:'50px',
        },
        '& .MuiAccordionSummary-content':{
            display:'flex',
            justifyContent:'space-between', 
            margin:'0px'
        },
        '.styledBoxTreeHeader':{
            display:'flex',
            alignItems:'center',
            '.treeViewTitle':{
                margin:'0px 10px'
            },
            '.actionBtn':{
                marginLeft: "auto",
                pointerEvents: "auto",
                marginTop: "-1.3%",
                color: theme.palette.primary.dark,
            }
        },
    '&.accordian1':{
        background:theme.palette.mode ==='light' ? alpha(theme.treeView.primary700,.8) : 'rgba(9,34,60,1)',
        paddingLeft:'10px',
        '&:before':{
            display:'none'
        }
    },
    '&.northEast':{
        background:theme.palette.mode ==='light' ? alpha(theme.treeView.primary600,.8) : 'rgba(13,42,71,1)',
        border:'0px solid',
        paddingLeft:'30px !important',
        '&:before':{
            left:'20px',
            top:'50%',
            transform: 'translateY(-50%)',
        }
    },
    '&.accordian2':{
        background:theme.palette.mode ==='light' ? alpha(theme.treeView.primary500,.8) : 'rgba(18,52,87,.4)',
        paddingLeft:'50px !important'  ,
        '&:before':{
            left:'35px',
            top:'50%',
            transform: 'translateY(-50%)',
        }
    },
    '&.accordian3':{
        background:theme.palette.mode ==='light' ? alpha(theme.treeView.primary400,.8) : 'rgba(22,58,93,1)',
        paddingLeft:theme.treeView.treeViewSet === '1' ? '62px !important' : '35px !important' ,
        '&:before':{
            left: theme.treeView.treeViewSet === '1' ? '52px' : '20px',
            top:'50%',
            transform: 'translateY(-50%)',
        }
    },
    '&.accordian4':{
        background:theme.palette.mode ==='light' ? alpha(theme.treeView.primary300,.8) : 'rgba(33,74,115,1)',
        paddingLeft:theme.treeView.treeViewSet === '1' ? '82px !important' : '50px !important',
        '&:before':{
            left: theme.treeView.treeViewSet === '1' ? '68px' : '40px',
            top:'50%',
            transform: 'translateY(-50%)',
        }
    },
    '&.MuiAccordionSummary-root.Mui-expanded':{
        minHeight:'auto !important',
        padding:'10px 15px',
        margin:'0px 0px !important',
        '&.MuiAccordionSummary-content':{
            margin:'0px important'
        },
        '&.MuiAccordionSummary-content.Mui-expanded':{
            margin:'0px 0px !important'
        }
    },
},
    
    '& .MuiAccordionDetails-root':{
        position:'relative',
        paddingLeft:'0px !important',
        background:'transparent',
        '&:before':{
            position:'absolute',
            background:theme.palette.divider,
            width:'2px',
            height:'100%',
            content:'""',
            zIndex:'999',
            borderRadius:'50px',
        },
        '&.accordianDetails1':{
            background:'transparent',
            '&:before':{
                left:'20px',
                top:'0px',
            }
        },
        '&.northEastDetails2':{
            background:'transparent',
            '&:before':{
                left:'35px',
                top:'0px',
            }
        },
        '&.accordianDetails2':{
            background:'transparent',
            '&:before':{
                left:'52px',
                top:'0px',
            }
        },
        '&.accordianDetails3':{
            background:'transparent',
            '&:before':{
                left: theme.treeView.treeViewSet === '1' ? '68px' : '40px',
                top:'0px',
            }
        },
        '&.accordianDetails4':{
            background:'transparent',
            '&:before':{
                left:theme.treeView.treeViewSet === '1' ? '84px' : '60px',
                top:'0px',
            }
        },
    }
}))

export const StyledAccordianRadio = styled(Radio)(({theme}) => ({
    padding:'2px',
    color:'#fff'
}));
export const StyledLoadMoreBtn = styled(Button)(({theme}) => ({
    background:theme.palette.mode ==='light' ? '#fff' : '#01162a',
    color:theme.palette.mode ==='light' ? '#1b4332' : '#1b4332',
}))
export const StyledAccordianListItem = styled(ListItem)(({theme}) => ({
    padding:'5px 20px !important',
    paddingLeft: theme.treeView.treeViewSet === '1' ? '104px !important' : '85px !important',
    background:theme.palette.mode ==='light' ? alpha(theme.treeView.primary200,.8) : '#01162a',
   '&:hover':{
        background:theme.palette.mode ==='light' ? alpha(theme.palette.primary.main,.6) : '#0b2d4c',
        color:'#fff'
   },
    '& .MuiListItemSecondaryAction-root':{
        right: '-9px',
    },
    '&.MuiListItem-root':{
            position:'relative',
        '&:before':{
            position:'absolute',
            background:theme.palette.divider,
            width:'10px',
            height:'2px',
            content:'""',
            zIndex:'999',
            borderRadius:'50px',
            left: theme.treeView.treeViewSet === '1' ? '84px' : '60px',
            top:'50%',
            transform: 'translateY(-50%)',
        },
    '& .MuiListItemSecondaryAction-root':{
        right:'5px'
    }
    }
}))