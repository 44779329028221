import {
    CardMedia,
    Dialog,
    DialogContent,
    IconButton,
    useTheme,
  } from "@mui/material";
  import React from "react";
  import { IoIosCloseCircle } from "react-icons/io";
  import { useLocation, useNavigate } from "react-router-dom";
  
  function StyledPreviewModel(props) {

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const {docName,docOriginalName,pop = false, onClose} = props;
 
    const modelClose = () => {
        onClose()
    //   navigate(location.pathname);
    };
  
    return (
      <Dialog open={pop} maxWidth={"sm"} fullWidth={true} onClose={onClose}>
                                         <IconButton

                                        onClick={modelClose}
                                        size='small' 
                                        sx={{color:'#fff', background:theme.palette.error.main, textDecoration:'none', position:'absolute',right: '5px',top:'5px'}}

                                        ><IoIosCloseCircle /></IconButton>
        <CardMedia 
        src={docName !== null?docName : 'https://cdn-icons-png.flaticon.com/512/4904/4904233.png' }
        component={'img'}
        sx={{height:'400', width:'150', objectFit:'fill'}}
        />
    {/* <Typography className='img-name'  sx={{background:theme.palette.success.main, color:theme.palette.success.contrastText, position:'absolute', width:'100%', height:'35px', bottom:'-50px', left:'0', display:'flex', alignItems:'center', justifyContent:'center', transition:'.5s', px:1, fontSize:'12px'}} variant='body2'>{docOriginalName}</Typography> */}
    
      </Dialog>
    );
  }
  
  export default StyledPreviewModel;