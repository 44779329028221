import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import {
  StyledCardShadow,
  StyledTableTitle,
} from "../../theme/StyledEle";
import {
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import { useInstrumentationListMutation } from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import MouseOverPopover from "../shared/PopOver";


const text = "Total Feeds is the sum of the counts in live and delayed feeds. Size shown is in MB (Megabytes). Note: Data will be available after 9 Am.";


const InstrumentationReport = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData.orgId;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);




  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "summarizedDate",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "Total Feeds",
        columns: [
          {
            Header: "count",
            accessor: "totalCount",
          },
          {
            Header: "size",
            accessor: "totalSize",
          }
        ],
      },
      {
        Header: "Live Feeds",
        columns: [
          {
            Header: "count",
            accessor: "totalLiveCount",
          },
          {
            Header: "size",
            accessor: "totalLiveSize",
          }
        ],
      },
      {
        Header: "Delayed Feeds",
        columns: [
          {
            Header: "count",
            accessor: "totalDelayedCount",
          },
          {
            Header: "size",
            accessor: "totalDelayedSize",
          }
        ],
      },
    ],
    []
  );

  const [
    getInstrumentationList,
    {
      data: data,
      isLoading: loading,
      isSuccess: success,
    },
  ] = useInstrumentationListMutation();


  useEffect(() => {
    if (success) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      }
    }
  }, [success]);

  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = orgId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.page = 0;
    commonPayload.size = 25;
    //Service Call
    setPayload(commonPayload);
    getInstrumentationList(commonPayload);
   
  };

      //Page Change
      const pageChange = (newPage, rowsPerPage) => {
        setNewPage(newPage);
        setRowsPerPage(rowsPerPage);
        payload.page = newPage;
        payload.size = rowsPerPage;
        getInstrumentationList(payload);
      };

  return (
    <Box>
    <MouseOverPopover text={text} title={"Instrumentation"} />
     <FilterDropdown
        getData={onSubmitForm}
        status={false}
        last7={false}
        allowDays={1}
      />
    <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
      <CardHeader title={<StyledTableTitle>Instrumentation Report</StyledTableTitle>} />
      <CardContent>
        <DataTable
          includeExportButton={false}
          columns={columns}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          data={responseData}
          rowsPerPage={rowsPerPage}
          callbackChangePage={pageChange}
          totalElements={totalElements}
          totalPages={totalPages}
          isLoading={loading}
          page={newPage}
        />
      </CardContent>
    </StyledCardShadow>
  </Box>
  );
};

export default InstrumentationReport;
